import { Edge, Node } from 'react-flow-renderer';

/**
 * Payloads
 */
export enum PrimitiveFieldType {
  STRING = 'String',
  DATE = 'Date',
  INTEGER = 'Integer',
  LONG = 'Long',
  BOOLEAN = 'Boolean',
  BIG_DECIMAL = 'BigDecimal',
  FLOAT = 'Float',
  DOUBLE = 'Double',
  BIG_INTEGER = 'BigInteger'
}

export enum PrimitiveFieldCollectionType {
  COLLECTION_STRING = 'List<String>',
  COLLECTION_DATE = 'List<Date>',
  COLLECTION_INTEGER = 'List<Integer>',
  COLLECTION_LONG = 'List<Long>',
  COLLECTION_BOOLEAN = 'List<Boolean>',
  COLLECTION_BIG_DECIMAL = 'List<BigDecimal>',
  COLLECTION_FLOAT = 'List<Float>',
  COLLECTION_DOUBLE = 'List<Double>',
  COLLECTION_BIG_INTEGER = 'List<BigInteger>'
}

export enum PrimitiveFieldMapType {
  MAP_STRING = 'Map<String>',
  MAP_DATE = 'Map<Date>',
  MAP_INTEGER = 'Map<Integer>',
  MAP_LONG = 'Map<Long>',
  MAP_BOOLEAN = 'Map<Boolean>',
  MAP_BIG_DECIMAL = 'Map<BigDecimal>',
  MAP_FLOAT = 'Map<Float>',
  MAP_DOUBLE = 'Map<Double>',
  MAP_BIG_INTEGER = 'Map<BigInteger>'
}

export enum GenericFieldType {
  OBJECT = 'Object'
}

export enum EnumFieldType {
  ENUM = 'Enum'
}

export enum GenericCollectionFieldType {
  COLLECTION_OBJECT = 'List<Object>'
}

export enum RealationType {
  ONE_TO_ONE_RELATED = 'ONE_TO_ONE_RELATED',
  ONE_TO_MANY_RELATED = 'ONE_TO_MANY_RELATED'
}

export enum AggregationType {
  COMPOSITION_EMBEDDED = 'COMPOSITION_EMBEDDED',
  COMPOSITION_DETACHED = 'COMPOSITION_DETACHED',
  AGGREGATION = 'AGGREGATION'
}

export type EntityNodeField = {
  id: string;
  name: string;
  type: string;
  uniqueId: boolean;
  sortOrder: number;
  className?: string;
  diagramId?: string;
  relationType?: RealationType;
  aggregationType?: AggregationType; // default COMPOSITION
};

export type EntityNodeData = {
  className: string;
  simpleClassName: string;
  fields: EntityNodeField[];
};

export type ExtendedElement = { diagramId?: string };

export type EntityNode = Node<EntityNodeData> & ExtendedElement;

export const entityEdgeDataClientFields = ['name', 'sourceNode', 'targetNode'];

export type EntityEdgeData = {
  relationType: RealationType;
  aggregationType: AggregationType; // default COMPOSITION
  sourceNode?: EntityNode;
  sourceField?: EntityNodeField;
  sourceHandleType?: HandleType;
  targetNode?: EntityNode;
  targetField?: EntityNodeField;
  targetHandleType?: HandleType;
  name?: string;
};

export type EntityEdgeFormData = {
  relationType: RealationType;
  aggregationType: AggregationType; // default COMPOSITION
};

export type EntityEdge = Edge<EntityEdgeData> & ExtendedElement;

export enum HandleType {
  FIELD_IN = 'fieldIn',
  FIELD_OUT = 'fieldOut',
  ENTITY_IN = 'entityIn',
  ENTITY_OUT = 'entityOut'
}

export type DomainDiagram = {
  id: string;
  nodes: EntityNode[];
  edges: EntityEdge[];
  domain: string;
  scheme: string;
  publishInfo: {
    publishedAt: string;
    publishedBy: string;
  };
};

export enum DoaminElementType {
  EDGE = 'entityEdge',
  NODE = 'entityNode'
}

export type DiagramElement = EntityEdge | EntityNode;

export type PublishDomainDiagramParams = {
  domainName: string;
  schema: string;
};

export type GenerateDomainDiagramParams = {
  domainName: string;
  schema: string;
};

export type GetDomainDiagramParams = {
  domainName: string;
  schema: string;
};
