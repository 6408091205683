import React from 'react';

import { Trans } from '@lingui/macro';
import { MenuProps } from 'antd/lib/menu';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MouseTooltip from 'react-sticky-mouse-tooltip';

import EntityEdgeName from '../entityEdge/entityEdgeName';

import * as S from './contextNavigationSc';

import useContainer from 'components/app/components/containerProvider/useContainer';
import * as C from 'components/app/components/contextNavigation/contextNavigationSc';
import EditEdgeAction from 'components/domain/actions/domainDiagramActions/editEdgeAction';
import RemoveEdgeAction from 'components/domain/actions/domainDiagramActions/removeEdgeAction';
import { AggregationType, EntityEdge, RealationType } from 'services/api/domain/domainDiagram';
import { st_domainDiagram_setContextEdge } from 'services/store/domainDiagram/domainDiagram.actions';
import {
  st_domainDiagram_getActiveEdge,
  st_domainDiagram_getContextAnchor,
  st_domainDiagram_getContextEdge,
  st_domainDiagram_getDialogIsOpen
} from 'services/store/domainDiagram/domainDiagram.selectors';

const EdgeContextTitle: React.FC<MenuProps & { edge: EntityEdge }> = ({ edge }) => (
  <S.Title>
    <EntityEdgeName edge={edge} iconBefore={['fas', 'crosshairs']} />
  </S.Title>
);

const EdgeContext: React.FC = () => {
  const contextEdge = useSelector(st_domainDiagram_getContextEdge);
  const activeEdge = useSelector(st_domainDiagram_getActiveEdge);
  const contextAnchor = useSelector(st_domainDiagram_getContextAnchor);
  const isDialogOpen = useSelector(st_domainDiagram_getDialogIsOpen);
  const dispatch = useDispatch();
  const { domainDiagramContainer } = useContainer();

  const handleClose = () => {
    if (!isDialogOpen) {
      dispatch(st_domainDiagram_setContextEdge(undefined));
    }
  };

  if (activeEdge && !contextEdge && !isDialogOpen) {
    return (
      <MouseTooltip
        visible={activeEdge !== undefined}
        offsetX={10}
        offsetY={10}
        className="edge-tooltip"
        style={{ zIndex: 1000 }}
      >
        <S.Tooltip>
          <S.Title>
            <EntityEdgeName edge={activeEdge} iconBefore={['fas', 'crosshairs']} />
          </S.Title>
          <S.Content>
            <S.Info>
              <p>
                <span>
                  <Trans>Relation</Trans>:
                </span>
                {activeEdge.data?.relationType === RealationType.ONE_TO_ONE_RELATED && 'One to one'}
                {activeEdge.data?.relationType === RealationType.ONE_TO_MANY_RELATED &&
                  'One to many'}
              </p>
              <p>
                <span>
                  <Trans>Aggregation</Trans>:
                </span>
                {activeEdge.data?.aggregationType === AggregationType.COMPOSITION_EMBEDDED &&
                  'Composition embedded'}
                {activeEdge.data?.aggregationType === AggregationType.COMPOSITION_DETACHED &&
                  'Composition detached'}
                {activeEdge.data?.aggregationType === AggregationType.AGGREGATION && 'Aggregation'}
              </p>
            </S.Info>
          </S.Content>
        </S.Tooltip>
      </MouseTooltip>
    );
  }

  return (
    <>
      <S.Context
        style={{ top: contextAnchor?.y || -1000, left: contextAnchor?.x || -1000 }}
        className="context-anchor"
      >
        {contextEdge && (
          <>
            <C.StyledMenu
              className="edge-context-navi"
              getPopupContainer={() => domainDiagramContainer}
              mode="vertical"
              //  style={{ minWidth: 250 }}
              onMouseLeave={handleClose}
            >
              <EdgeContextTitle edge={contextEdge} />
              {contextEdge && (
                <>
                  {/* <Menu.Item
                    key={`edit-edge_${contextEdge.id}`}
                    icon={<FontAwesomeIcon className="anticon" icon={['fas', 'pen']} />}
                    onClick={handleEditEdge}
                  >
                    <Trans>Edit edge</Trans>
                  </Menu.Item> */}
                  <EditEdgeAction edge={contextEdge} type="EDGE_NAV" />
                  <RemoveEdgeAction edge={contextEdge} type="EDGE_NAV" />
                </>
              )}
            </C.StyledMenu>
          </>
        )}
      </S.Context>
    </>
  );
};

export default EdgeContext;
