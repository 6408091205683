import React from 'react';

import { Redirect } from 'react-router';

import routes from '../routes/routes';

import { ViewConfig } from './view.types';

import TriggerCreatePage from 'components/trigger/pages/triggerCreatePage';
import TriggersCalendarPage from 'components/trigger/pages/triggersCalendarPage';
import TriggerSinglePage from 'components/trigger/pages/triggerSinglePage';
import TriggersListPage from 'components/trigger/pages/triggersListPage';

const schedulerViewConfig: ViewConfig = {
  routes: [
    {
      path: routes.scheduler.list,
      exact: true,
      component: <TriggersListPage />
    },
    {
      path: routes.scheduler.calendar,
      exact: true,
      component: <TriggersCalendarPage />
    },
    {
      path: routes.scheduler.single,
      exact: true,
      component: <TriggerSinglePage />
    },
    {
      path: routes.scheduler.create,
      exact: true,
      component: <TriggerCreatePage />
    },
    {
      path: routes.scheduler.root,
      exact: true,
      component: <Redirect to={routes.scheduler.list} />
    }
  ]
};

export default schedulerViewConfig;
