import { AxiosError } from 'axios';
import { QueryObserverResult } from 'react-query';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useUnautorizedReq = (queryObserver: QueryObserverResult<any, AxiosError>): boolean => {
  if (queryObserver.isError && queryObserver.error.response?.status === 403) {
    return true;
  }

  return false;
};

export default useUnautorizedReq;
