import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Divider } from 'antd';
import Modal from 'antd/lib/modal/Modal';

import TestDbConnection from 'components/domain/components/testDbConnection';
import DataItem from 'components/ui/dataItem';
import useDefaultModalProps from 'hooks/useDefaultModalProps';
import { DbImport } from 'services/api/domain/dataImport';

type CheckDbConnectionDialogProps = {
  isOpen: boolean;
  data: DbImport;
  close: () => void;
};

const CheckDbConnectionDialog: React.FC<CheckDbConnectionDialogProps> = ({
  data,
  isOpen,
  close
}) => {
  const modalProps = useDefaultModalProps();

  return (
    <Modal
      {...modalProps}
      visible={isOpen}
      title={
        <>
          <FontAwesomeIcon icon={['fas', 'database']} />
          <Trans>Test DB connection</Trans>
        </>
      }
      okButtonProps={{ hidden: true }}
      onCancel={() => close()}
      cancelText={<Trans>Close</Trans>}
    >
      <p>
        <Trans>
          Check connection for DB import <b>{data.name}</b>.
        </Trans>
      </p>
      <Divider />
      <ul>
        <li>
          <DataItem
            name={<Trans>DB url</Trans>}
            value={data.dburl}
            hasCopy
            hasCopyTitle
            breakWord
          />
        </li>
        <li>
          <DataItem name={<Trans>DB user</Trans>} value={data.dbuser} hasCopy />
        </li>
        <li>
          <DataItem name={<Trans>DB password</Trans>} value={data.dbpassword} hasCopy />
        </li>
        <li>
          <DataItem name={<Trans>Driver class</Trans>} value={data.driverclass} hasCopy />
        </li>
      </ul>
      <Divider />
      <TestDbConnection data={data} />
    </Modal>
  );
};

export default CheckDbConnectionDialog;
