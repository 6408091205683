import React from 'react';

import { useDispatch } from 'react-redux';

import * as S from './docsTooltipSc';

import Button from 'components/ui/button';
import { DocId, Hint } from 'services/api/domain/hint';
import { st_docs_openDrawer } from 'services/store/docs/docs.actions';

type DocsTooltipContentProps = {
  docId: DocId;
  data: Hint;
  setOpen: (open: boolean) => void;
};

const DocsTooltipContent: React.FC<DocsTooltipContentProps> = ({ docId, data, setOpen }) => {
  const dispatch = useDispatch();

  const handleOpenDocs = () => {
    setOpen(false);
    dispatch(st_docs_openDrawer(docId));
  };

  return (
    <S.StyledDocsTooltipContent>
      <S.DocsTooltipTitle>{data.title}</S.DocsTooltipTitle>
      <S.DocsTooltipMessage>{data.tooltip}</S.DocsTooltipMessage>
      <S.DocsTooltipButtons>
        <Button icon={['fas', 'link']} type="link" onClick={handleOpenDocs}>
          More info...
        </Button>
      </S.DocsTooltipButtons>
    </S.StyledDocsTooltipContent>
  );
};

export default DocsTooltipContent;
