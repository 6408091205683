import React, { useCallback, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { FormItem, useFormContext } from '@phoenix-systems/react-form';
import { Form, Input, Select } from 'antd';
import { AxiosError } from 'axios';
import { saveAs } from 'file-saver';
import { useMutation } from 'react-query';
import { useImmer } from 'use-immer';
import { v4 as uuid } from 'uuid';

import * as S from './jsonExportFormSc';

import * as F from 'components/_styled/formSc';
import useContainer from 'components/app/components/containerProvider/useContainer';
import DocsTooltip from 'components/docs/components/docsTooltip';
import TooltipLabel from 'components/docs/components/docsTooltip/tooltipLabel';
import EntityFieldSelector from 'components/domain/components/entityFieldSelector';
import Button from 'components/ui/button';
import ErrorInfo from 'components/ui/errorInfo';
import FormContainer from 'components/ui/formContainer';
import LoaderMessage from 'components/ui/loader/loaderMessage';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import useDomainName from 'hooks/useDomainName';
import useNotification from 'hooks/useNotification';
import useOnDomainSchemaChange from 'hooks/useOnDomainSchemaChange';
import useSchemaName from 'hooks/useSchemaName';
import {
  api_dataJsonExport,
  DataExportFileType,
  DataExportParams
} from 'services/api/domain/dataJsonImportExport';
import { DocId } from 'services/api/domain/hint';
import { getPrettyFileSize } from 'utils';

const { Option } = Select;

type JsonExportFormState = {
  fileType?: DataExportFileType;
};

type ExportFormData = {
  type?: string;
  key?: string;
  fileType: DataExportFileType;
};

const JsonExportForm: React.FC = () => {
  const schema = useSchemaName();
  const { formContainer } = useContainer();
  const domainName = useDomainName();
  const [state, setState] = useImmer<JsonExportFormState>({
    fileType: undefined
  });
  const copy = useCopyToClipboard();

  const getInitialValues = useCallback(
    () => ({
      fileType: 'zip' as DataExportFileType,
      key: undefined,
      type: undefined
    }),
    []
  );

  const [form] = Form.useForm<ExportFormData>();
  const [formState, formActions] = useFormContext(form, getInitialValues(), false);
  const [addNotification] = useNotification();

  const getSuccessMessage = () =>
    i18n._(t`Sucessfully exported data from domain "${form.getFieldValue('domainName')}.`);

  const exportMutation = useMutation<Blob, AxiosError, DataExportParams>(
    params => api_dataJsonExport(params),
    {
      onSuccess: () => {
        addNotification({
          type: 'success',
          message: getSuccessMessage()
        });
      },
      onError: () => {
        addNotification({
          type: 'error',
          message: i18n._(
            t`Failed to export data from domain "${form.getFieldValue('domainName')}".`
          )
        });
      }
    }
  );
  const { current: exportMutationRef } = useRef(exportMutation);

  const handleGeneratePgpKey = () => {
    const id = uuid();
    form.setFieldsValue({ ...form.getFieldsValue(), key: id });
  };

  const handleDownloadFile = () => {
    if (exportMutation.isSuccess && exportMutation.data && domainName) {
      const ft = form.getFieldValue('fileType');
      let fileName: string;

      if (form.getFieldValue('type') && form.getFieldValue('type') !== '') {
        fileName = `data-export-${domainName}_${schema}_${form.getFieldValue('type')}.${ft}`;
      } else {
        fileName = `data-export_${domainName}_${schema}.${ft}`;
      }
      saveAs(exportMutation.data, fileName);
    }
  };

  const handleExport = () => {
    form.validateFields().then(formData => {
      const payload = { ...formData, domainName, schema };
      exportMutation.mutate(payload as DataExportParams);
    });
  };

  const handleReset = useCallback(() => {
    exportMutationRef.reset();
    formActions.reset(getInitialValues(), false);
  }, [exportMutationRef, formActions, getInitialValues]);

  useOnDomainSchemaChange(handleReset);

  return (
    <>
      <FormContainer
        maxWidth={900}
        isStickyDisabled
        title={
          <>
            <FontAwesomeIcon icon={['fas', 'file-export']} />
            <Trans>Export JSON data</Trans>
            <DocsTooltip docId={DocId.DOMAIN_JSON_IMPEX_EXPORT} />
          </>
        }
        buttons={
          <>
            <Button
              action="reset"
              mutation={exportMutation}
              formState={formState}
              onClick={handleReset}
            />
            <Button
              action="save"
              mutation={exportMutation}
              formState={formState}
              icon={['fas', 'file-export']}
              onClick={handleExport}
            >
              <Trans>Export</Trans>
            </Button>
          </>
        }
        messages={
          <>
            {exportMutation.isLoading && (
              <LoaderMessage>
                <Trans>Export in progress. This can take up to a few minutes.</Trans>
              </LoaderMessage>
            )}
            {exportMutation.isSuccess && (
              <F.FormAlert
                message={getSuccessMessage()}
                type="success"
                showIcon
                description={
                  <F.BoxedFormContainerSummary labelWidth={50}>
                    <p>
                      <span>Domain:</span> {domainName}
                    </p>
                    <p>
                      <span>Schema:</span> {schema}
                    </p>
                    <p>
                      <span>Entity:</span>{' '}
                      {form.getFieldValue('type') !== '' && form.getFieldValue('type') !== undefined
                        ? form.getFieldValue('type')
                        : '*'}
                    </p>
                    {exportMutation.data && (
                      <S.DownloadButton
                        type="primary"
                        icon={<FontAwesomeIcon icon={['fas', 'download']} />}
                        onClick={handleDownloadFile}
                      >
                        <Trans>Download file ({getPrettyFileSize(exportMutation.data.size)})</Trans>
                      </S.DownloadButton>
                    )}
                  </F.BoxedFormContainerSummary>
                }
              />
            )}
            {exportMutation.isError && (
              <ErrorInfo
                message={<Trans>Failed to export data.</Trans>}
                error={exportMutation.error}
                isBlob
              />
            )}
          </>
        }
      >
        <F.StyledForm
          form={form}
          layout="horizontal"
          name="json-export-form"
          $labelWidth={100}
          validateTrigger="onBlur"
          onFieldsChange={formActions.onFieldsChange}
          onValuesChange={formActions.onValuesChange}
          initialValues={getInitialValues()}
          colon={false}
        >
          <FormItem
            label={
              <TooltipLabel
                docId={DocId.DOMAIN_JSON_IMPEX_EXPORT_FILETYPE}
                label={<Trans>File type</Trans>}
              />
            }
            messageVariables={{ name: i18n._('File type') }}
            name="fileType"
            rules={[{ required: true }]}
            registerField={formActions.registerField}
          >
            <Select
              getPopupContainer={() => formContainer}
              onChange={val =>
                setState(draft => {
                  draft.fileType = val as DataExportFileType;
                })
              }
            >
              <Option value="zip" key="zip">
                ZIP
              </Option>
              <Option value="pgpZip" key="pgpZip" disabled>
                PGP ZIP
              </Option>
              <Option value="json" key="json" disabled>
                JSON
              </Option>
            </Select>
          </FormItem>
          {state.fileType === 'pgpZip' && (
            <FormItem
              label={
                <TooltipLabel
                  docId={DocId.DOMAIN_JSON_IMPEX_EXPORT_PGPKEY}
                  label={<Trans>PGP key</Trans>}
                />
              }
              messageVariables={{ name: i18n._(t`API key`) }}
              name="key"
              rules={[{ required: true }]}
              registerField={formActions.registerField}
            >
              <Input
                autoCorrect="false"
                addonAfter={
                  <>
                    <Button icon={['fas', 'sync']} onClick={handleGeneratePgpKey} />
                    <Button
                      icon={['fas', 'copy']}
                      onClick={() => copy(form.getFieldValue('key'))}
                    />
                  </>
                }
                className="has-button"
              />
            </FormItem>
          )}

          <FormItem
            label={
              <TooltipLabel
                docId={DocId.DOMAIN_JSON_IMPEX_EXPORT_ENTITY}
                label={<Trans>Entity</Trans>}
              />
            }
            rules={[{ required: true }]}
            messageVariables={{ name: i18n._('Entity') }}
            name="type"
            registerField={formActions.registerField}
          >
            <EntityFieldSelector
              getPopupContainer={() => formContainer}
              treeDefaultExpandAll
              onChangePrepareValue={value => value?.value}
            />
          </FormItem>
        </F.StyledForm>
      </FormContainer>
    </>
  );
};

export default JsonExportForm;
