import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { getRoute } from '@phoenix-systems/react-router';
import { Modal } from 'antd';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import Button from 'components/ui/button';
import routes from 'config/routes/routes';
import useDefaultModalProps from 'hooks/useDefaultModalProps';
import useNotification from 'hooks/useNotification';
import { api_deleteOrgUnitByName, OrgUnit } from 'services/api/domain/orgUnit';
import history from 'services/history';

type DeleteOrgUnitDialogProps = {
  isOpen: boolean;
  data: OrgUnit;
  close: () => void;
};

const DeleteOrgUnitDialog: React.FC<DeleteOrgUnitDialogProps> = ({ data, isOpen, close }) => {
  const { username } = useParams<{ username: string }>();
  const queryClient = useQueryClient();
  const [addNotification] = useNotification();
  const modalProps = useDefaultModalProps();

  const deleteMutation = useMutation(() => api_deleteOrgUnitByName(data.name), {
    onSuccess: () => {
      addNotification({
        type: 'success',
        message: i18n._(t`Successfully deleted organisation "${data.name}".`)
      });

      queryClient.refetchQueries(['orgUnits']);

      if (username) {
        history.push(getRoute(routes.userAdmin.user.list));
      }
      close();
    },
    onError: () => {
      addNotification({
        type: 'error',
        message: i18n._(t`Failed to delete organisation "${data.name}".`)
      });
    }
  });

  const handleDelete = () => {
    deleteMutation.mutate();
  };

  return (
    <Modal
      {...modalProps}
      visible={isOpen}
      width={650}
      title={
        <>
          <FontAwesomeIcon icon={['fas', 'trash']} />
          <Trans>Delete Organisation {data.name}</Trans>
        </>
      }
      footer={
        <>
          <Button action="cancel" disabled={deleteMutation.isLoading} onClick={() => close()} />
          <Button action="delete" mutation={deleteMutation} onClick={handleDelete}>
            <Trans>Delete organisation</Trans>
          </Button>
        </>
      }
    >
      <p>
        Do you want to delete the organisation <b>{data.name}</b>?
      </p>
    </Modal>
  );
};

export default DeleteOrgUnitDialog;
