import { v4 as uuid } from 'uuid';

import { EntityHierarchyData } from './entityFieldSelector.types';

import { EntityHierarchy, EntityHierarchyField } from 'services/api/domain/hierarchy';

const walkTreeData = (
  path: string[],
  flatParsedData: EntityHierarchyData[],
  data?: EntityHierarchy,
  fieldData?: EntityHierarchyField,
  disableEntities?: boolean
): EntityHierarchyData | undefined => {
  let isSelectable = true;

  if (disableEntities && data) {
    isSelectable = false;
  }

  if (data) {
    const newPath = [
      ...path,
      data.fieldName && data.fieldName !== '' ? data.fieldName : data.value
    ];

    const nodeData: EntityHierarchyData = {
      id: uuid(),
      name: data.value,
      value: data.value,
      path: newPath,
      isField: false,
      isSelectable,
      children: []
    };

    if (data.fields) {
      data.fields.forEach(field => {
        const fieldNodeData = walkTreeData(
          newPath,
          flatParsedData,
          undefined,
          field,
          disableEntities
        );

        if (fieldNodeData) {
          nodeData.children?.push(fieldNodeData);
        }
      });
    }

    if (data.children) {
      data.children.forEach(child => {
        const entityNodeData = walkTreeData(
          newPath,
          flatParsedData,
          child,
          undefined,
          disableEntities
        );

        if (entityNodeData) {
          nodeData.children?.push(entityNodeData);
        }
      });
    }

    flatParsedData.push(nodeData);

    return nodeData;
  }

  if (fieldData) {
    const newPath = [...path, fieldData.name];

    const nodeData = {
      id: uuid(),
      name: fieldData.name,
      value: fieldData.name,
      fieldType: fieldData.type,
      path: newPath,
      isField: true
    };

    flatParsedData.push(nodeData);

    return nodeData;
  }

  return undefined;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const prepareEntityFieldData = (data: EntityHierarchy[], disableEntities?: boolean) => {
  const parsedData: EntityHierarchyData[] = [];
  const flatParsedData: EntityHierarchyData[] = [];

  data.forEach(item => {
    const nodeData = walkTreeData([], flatParsedData, item, undefined, disableEntities);

    if (nodeData) {
      parsedData.push(nodeData);
    }
  });

  return {
    data: parsedData,
    faltData: flatParsedData
  };
};
