import React, { useCallback, useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import { useLayoutConfig } from '@phoenix-systems/react-layout';
import { NavigationConfig } from '@phoenix-systems/react-navigation';
import { Select } from 'antd';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import * as S from './domainSelectorSc';

import useContainer from 'components/app/components/containerProvider/useContainer';
import * as N from 'components/app/components/mainNavigation/mainNavigationSc';
import Button from 'components/ui/button';
import routes from 'config/routes/routes';
import useDomainName from 'hooks/useDomainName';
import { useDomains } from 'services/api/domain/domain';
import history from 'services/history';
import { st_domain_setDomainName } from 'services/store/domain/domain.actions';
import {
  st_domain_getRoute,
  st_domain_getRouteParams
} from 'services/store/domain/domain.selectors';
import getDomainRoute from 'utils/getDomainRoute';

const NAVIGATION_ID = 'domains';

const { Option } = Select;

const DomainSelector: React.FC = () => {
  const domainName = useDomainName();
  const domainsQuery = useDomains({ enabled: false });
  const route = useSelector(st_domain_getRoute);
  const routeParams = useSelector(st_domain_getRouteParams);
  const { mainNavigationContainer } = useContainer();
  const layoutConfig = useLayoutConfig();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [domainNavConfig, setDomainNavConfig] = useState<NavigationConfig>();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const handleSelectDomain = useCallback(
    (value: string) => {
      const newDomain = domainsQuery.data?.filter(domain => domain.domainName === value)[0];

      if (newDomain && route) {
        const newRoute = getDomainRoute(route, value, newDomain.defaultSchema, routeParams);
        queryClient.invalidateQueries('domain');
        history.push(newRoute);
      }
    },
    [domainsQuery.data, queryClient, route, routeParams]
  );

  const handleCreateDomain = useCallback(() => {
    setDropdownOpen(false);
    dispatch(st_domain_setDomainName(undefined));
    history.push(routes.domain.create);
  }, [dispatch]);

  const handleDropdownOpenChange = (open: boolean) => {
    if (open) {
      domainsQuery.refetch();
    }
    setDropdownOpen(open);
  };

  useEffect(() => {
    if (domainsQuery.isSuccess) {
      const children: NavigationConfig[] = [];
      domainsQuery.data?.forEach(domain => {
        children.push({
          id: `select-domain_${domain.id}`,
          title: domain.domainName,
          icon: ['fas', 'globe'],
          onClick: () => handleSelectDomain(domain.domainName)
        });
      });
      children.push({
        id: 'create-domain-separator',
        type: 'separator'
      });
      children.push({
        id: 'create-domain',
        title: i18n._('Create domain'),
        icon: ['fas', 'plus-circle'],
        onClick: () => handleCreateDomain()
      });

      setDomainNavConfig({
        id: 'domain-selector-nav',
        children: [
          {
            id: 'domain-selector',
            icon: ['fas', 'globe'],
            children
          }
        ]
      });
    }
  }, [domainsQuery.data, domainsQuery.isSuccess, handleSelectDomain, handleCreateDomain]);

  return (
    <>
      <S.SelectorContainer collapsed={layoutConfig.sidebarLeft?.isCollapsed}>
        {/*  {domainsQuery.isLoading && (
          <Loader backColor={colors.lightGray} frontColor="#CCC" size={24} />
        )} */}
        {domainsQuery.isError && <p>error loading domains</p>}
        <>
          {!layoutConfig.sidebarLeft?.isCollapsed ? (
            <S.SelectInput
              listHeight={400}
              dropdownClassName="select-menu"
              getPopupContainer={() => mainNavigationContainer}
              loading={domainsQuery.isLoading}
              menuItemSelectedIcon={<FontAwesomeIcon icon={['fal', 'globe']} />}
              open={isDropdownOpen}
              placeholder={
                !domainsQuery.isLoading ? (
                  <Trans>Select domain</Trans>
                ) : (
                  <Trans>Load domains...</Trans>
                )
              }
              value={domainName}
              onChange={value => handleSelectDomain(value as string)}
              onDropdownVisibleChange={handleDropdownOpenChange}
            >
              {domainsQuery.data?.map(domain => (
                <Option key={domain.id} value={domain.domainName}>
                  {domain.domainName}
                </Option>
              ))}
              <Option className="is-button" value="CREATE" disabled>
                <Button
                  action="create"
                  type="primary"
                  onClick={handleCreateDomain}
                  icon={['fas', 'plus-circle']}
                >
                  <Trans>Create new domain</Trans>
                </Button>
              </Option>
            </S.SelectInput>
          ) : (
            <>
              {domainNavConfig && (
                <N.StyledNavigation
                  navigationId={NAVIGATION_ID}
                  collapsedWidth={layoutConfig.sidebarLeft?.collapsedWidth - 1}
                  config={domainNavConfig}
                  getPopupContainer={() => mainNavigationContainer}
                  inlineCollapsed={layoutConfig.sidebarLeft.isCollapsed}
                  mode="inline"
                  selectedKeys={['domain-selector']}
                />
              )}
            </>
          )}
        </>
      </S.SelectorContainer>
    </>
  );
};

export default DomainSelector;
