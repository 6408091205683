/* eslint-disable no-unneeded-ternary */
import React, { MouseEvent, useCallback, useEffect, useState } from 'react';

import ReactFlow, {
  addEdge,
  Controls,
  MiniMap,
  useStoreActions,
  useStoreState
} from 'react-flow-renderer';
import { useDispatch, useSelector } from 'react-redux';

import EdgeContext from '../contextNavigation/edgeContextNavigation';
import EntityEdge from '../entityEdge';
import Node from '../entityNode';

import * as S from './reactFlowDiagramSc';

import AddEntityAction from 'components/domain/actions/domainDiagramActions/createEntityAction';
import DeleteEntityAction from 'components/domain/actions/domainDiagramActions/deleteEntityAction';
import ResetViewAction from 'components/domain/actions/domainDiagramActions/resetViewAction';
import SelectAllAction from 'components/domain/actions/domainDiagramActions/selectAllAction';
import SelectEntityAction from 'components/domain/actions/domainDiagramActions/selectEntityAction';
import ShowInfoAction from 'components/domain/actions/domainDiagramActions/showInfoAction';
import ToggleFullScreenAction from 'components/domain/actions/domainDiagramActions/toggleFullscreenAction';
import { DiagramElement, EntityNode } from 'services/api/domain/domainDiagram';
import {
  st_domainDiagram_setContextNavOpen,
  st_domainDiagram_updateElements
} from 'services/store/domainDiagram/domainDiagram.actions';
import {
  st_domainDiagram_getContextNavIsOpen,
  st_domainDiagram_getDialogIsOpen,
  st_domainDiagram_getElements
} from 'services/store/domainDiagram/domainDiagram.selectors';
import { log } from 'utils';

const NODE_TYPES = {
  entityNode: Node
};

const EDGE_TYPES = {
  entityEdge: EntityEdge
};

const ReactFlowDiagram: React.FC = () => {
  const transform = useStoreState(store => store?.transform);
  const [reactflowInstance, setReactflowInstance] = useState();
  const dispatch = useDispatch();
  const elements = useSelector(st_domainDiagram_getElements);
  const setInteractive = useStoreActions(actions => actions.setInteractive);
  const isContextNavOpen = useSelector(st_domainDiagram_getContextNavIsOpen);
  const isOpenDialog = useSelector(st_domainDiagram_getDialogIsOpen);
  const [elementToRemove, setElementToRemove] = useState<EntityNode>();
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleElementsRemove = useCallback(
    elementsToRemove => {
      const elToRemove = elementsToRemove.find((el: DiagramElement) => el.type === 'entityNode');

      if (elToRemove) {
        const element = elements.find(el => el.id === elToRemove.id);

        if (element) {
          setElementToRemove(element as EntityNode);
          setDeleteDialogOpen(true);
        }
      }
    },
    [elements]
  );

  const handleConnect = useCallback(
    params => {
      const newElements = addEdge(
        { ...params, animated: true, style: { stroke: 'red' } },
        elements
      );
      dispatch(st_domainDiagram_updateElements(newElements));
    },
    [elements, dispatch]
  );

  const handleNodeDragStop = (event: MouseEvent, _node: EntityNode) => {
    const node = _node;

    if (node.position.x < 0) {
      node.position.x = 0;
    }

    if (node.position.y < 0) {
      node.position.y = 0;
    }

    const els: DiagramElement[] = elements.filter(el => el.id !== node.id);
    const newElements = [...els, node];
    dispatch(st_domainDiagram_updateElements(newElements));
  };

  //  (event: MouseEvent, node: EntityNode) =>
  const handleNodeDragStart = (event: MouseEvent, node: EntityNode) => {
    log('drag start', node);
  };

  // (event: MouseEvent, node: EntityNode) =>
  const handleNodeContextMenu = (event: MouseEvent) => {
    event.preventDefault();
  };

  const onLoad = useCallback(
    rfi => {
      if (!reactflowInstance) {
        setReactflowInstance(rfi);
      }
    },
    [reactflowInstance]
  );

  const handleViewClick = () => {
    dispatch(st_domainDiagram_setContextNavOpen(false));
  };

  useEffect(() => {
    if (isOpenDialog || isContextNavOpen) {
      setInteractive(false);
    } else {
      setInteractive(true);
    }
  }, [isContextNavOpen, isOpenDialog, setInteractive]);

  return (
    <S.Wrapper onClick={handleViewClick}>
      <S.Head>
        <AddEntityAction />
        <SelectAllAction />
        <SelectEntityAction />
        <ResetViewAction />
        <ToggleFullScreenAction />
        <ShowInfoAction />
      </S.Head>
      <ReactFlow
        defaultZoom={1}
        edgeTypes={EDGE_TYPES}
        elements={elements}
        nodeTypes={NODE_TYPES}
        snapGrid={[5, 5]}
        snapToGrid
        onConnect={handleConnect}
        // onElementClick={handleElementsClick}
        onElementsRemove={handleElementsRemove}
        onLoad={onLoad}
        onNodeContextMenu={handleNodeContextMenu}
        onNodeDragStart={handleNodeDragStart}
        onNodeDragStop={handleNodeDragStop}
        /*  onNodeMouseEnter={handleNodeMouseEnter}
        onNodeMouseLeave={handleNodeMouseLeave} */
      >
        <MiniMap />
        <Controls />
      </ReactFlow>
      <S.Info>
        <span>left: </span>
        {transform[0].toFixed(2)},<span>top: </span>
        {transform[1].toFixed(2)},<span>zoom: </span>
        {transform[2].toFixed(2)}
      </S.Info>
      <DeleteEntityAction
        closeNavigation={() => setDeleteDialogOpen(false)}
        data={elementToRemove?.data}
        dialogIsOpen={isDeleteDialogOpen}
        nodeId={elementToRemove?.id}
      />
      <EdgeContext />
    </S.Wrapper>
  );
};

export default ReactFlowDiagram;
