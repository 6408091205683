import { Alert } from 'antd';
import tw, { styled, css } from 'twin.macro';

type StyledAlertProps = {
  $asPageContent?: boolean;
};

export const StyledAlert = styled(Alert)<StyledAlertProps>(({ $asPageContent }) => [
  css`
    ${tw``};

    & .ant-alert-icon {
      ${tw`text-18 mr-12`};
    }
  `,

  $asPageContent &&
    css`
      ${tw`mx-40 mt-24`};
    `
]);

export const MessageContainer = styled.div(() => [
  css`
    ${tw`flex flex-row items-center`};
  `
]);

export const Message = styled.div(() => [
  css`
    ${tw`text-14 font-700`};
  `
]);

export const ErrorMessage = styled.div(() => [
  css`
    ${tw`text-12 font-400 mt-8`};
  `
]);

export const Actions = styled.div(() => [
  css`
    ${tw`ml-auto`};
    & button {
      ${tw`m-0 p-0 opacity-80 hover:opacity-100 text-12`};
      color: #ff4d4f;

      &:hover {
        color: #ff4d4f;
      }
    }
  `
]);
