import TextArea from 'antd/lib/input/TextArea';
import tw, { css, styled } from 'twin.macro';

export const Container = styled.div(() => [
  css`
    ${tw`flex flex-col h-full`};
  `
]);

export const FormContainer = styled.div(() => [
  css`
    ${tw`pb-24`};
  `
]);

export const ResultContainer = styled.div(() => [
  css`
    ${tw`h-full relative`};

    & .document-history-table {
      ${tw`left-0 right-0`};
    }
  `
]);

export const TableActionsLeft = styled.div(() => [
  css`
    ${tw`flex flex-row items-start relative mr-12`};
    /*     max-width: 1100rem; */

    & .parameters-form {
      ${tw`absolute left-0 right-0 top-0 bottom-0 flex flex-row items-start w-full`};

      & .ant-form-item {
        ${tw`mb-0`};
      }

      & .id-input {
        ${tw`lg:w-full mr-12`};
      }

      & .depth-input {
        width: 18rem;
      }

      .buttons {
        ${tw`flex flex-row items-center ml-12`};
        & .ant-btn {
          ${tw` ml-12`};
        }
      }
    }
  `
]);

export const JsonDiff = styled(TextArea)(() => [
  css`
    ${tw`text-10 font-mono border-none bg-light-gray-50`};
  `
]);

type DiffSummaryProps = {
  size: 'default' | 'small';
  isTable?: boolean;
};

export const DiffSummary = styled.ul<DiffSummaryProps>(({ size, isTable }) => [
  css`
    & li {
      ${tw`text-11 font-mono border-ant-border-gray border-b  py-2 mb-6`};

      &:last-of-type {
        ${tw`border-none mb-0`};
      }

      & span {
        ${tw`inline-block font-600 text-primary`};
        min-width: 4.5rem;
      }
    }
  `,

  size === 'default' &&
    css`
      & li {
        ${tw`text-13 font-mono border-ant-border-gray border-b  py-4 mb-6`};

        & span {
          min-width: 6rem;
        }
      }
    `,

  isTable &&
    css`
      max-height: 140px;
      overflow: auto;
    `
]);

export const EditorWrapper = styled(TextArea)(() => [
  css`
    ${tw`mb-12 relative`};
  `
]);
