import React from 'react';

import { Trans } from '@lingui/macro';
import { Alert, Modal } from 'antd';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import * as D from 'components/_styled/dialogSc';
import Button from 'components/ui/button';
import ErrorInfo from 'components/ui/errorInfo';
import LoaderMessage from 'components/ui/loader/loaderMessage';
import useApiKey from 'hooks/useApiKey';
import useDefaultModalProps from 'hooks/useDefaultModalProps';
import useDomainName from 'hooks/useDomainName';
import useSchemaName from 'hooks/useSchemaName';
import {
  api_deleteDomainObjects,
  DeleteDomainObjectsParams,
  DomainObjectResult
} from 'services/api/domain/object';
import { st_entityDocument_getEntity } from 'services/store/entityDocument/entityDocument.selectors';

type DeleteObjectDialogsProps = {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  data: any;
  isOpen: boolean;
  close: () => void;
};

const DeleteObjectDialog: React.FC<DeleteObjectDialogsProps> = ({ isOpen, data, close }) => {
  const queryClient = useQueryClient();
  const entity = useSelector(st_entityDocument_getEntity);
  const domainName = useDomainName();
  const schema = useSchemaName();
  const modalProps = useDefaultModalProps();

  const deleteMutation = useMutation<DomainObjectResult, AxiosError, DeleteDomainObjectsParams>(
    params => api_deleteDomainObjects(params)
  );
  const apiKey = useApiKey();

  const handleDeleteObject = () => {
    if (entity && typeof data.id === 'string') {
      deleteMutation.mutate({
        objectIds: [data.id],
        domainName,
        schema,
        apiKey,
        type: entity
      });
    }
  };

  const handleClose = () => {
    if (deleteMutation.isSuccess) {
      queryClient.refetchQueries(['queryDomainObjects']);
      queryClient.refetchQueries(['queryDomainObject']);
    }

    close();
  };

  return (
    <Modal
      {...modalProps}
      title={
        <>
          <Trans>Delete Object</Trans>
        </>
      }
      visible={isOpen}
      footer={
        <>
          <Button
            action={deleteMutation.isSuccess ? 'close' : 'cancel'}
            disabled={deleteMutation.isLoading}
            onClick={handleClose}
          />
          <Button
            action="delete"
            onClick={handleDeleteObject}
            loading={deleteMutation.isLoading}
            disabled={deleteMutation.isLoading || deleteMutation.isSuccess}
          >
            Delete Object
          </Button>
        </>
      }
    >
      <p>
        <Trans>Are you sure yout want to delete the object with id</Trans> <b>{data.id}</b>?. This
        action cannot be reverted.
      </p>
      {deleteMutation.isLoading && (
        <D.ResponseMessage>
          <LoaderMessage>
            <Trans>Deleting object {data.id}</Trans>
          </LoaderMessage>
        </D.ResponseMessage>
      )}
      {deleteMutation.isSuccess && (
        <D.ResponseMessage>
          <Alert
            type="success"
            showIcon
            message={
              <Trans>
                Successfully deleted object <b>{data.id}</b>.
              </Trans>
            }
          />
        </D.ResponseMessage>
      )}
      {deleteMutation.isError && (
        <D.ResponseMessage>
          <ErrorInfo
            error={deleteMutation.error}
            message={
              <Trans>
                Failed to delete object <b>{data.id}</b>.
              </Trans>
            }
          />
        </D.ResponseMessage>
      )}
    </Modal>
  );
};

export default DeleteObjectDialog;
