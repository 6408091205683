import {
  CaptureMetadataParams,
  CreateMetadataParams,
  CreateMetadataResponse,
  EntityMetaData,
  FindMetadtaParams,
  UpdateMetadataParams
} from './metadata.types';

import apiClient from 'services/api/apiClient';

const BASE_PATH = 'metadata';
const CAPTURE_BASE_PATH = 'capture';

/**
 * GET /api/metadata/{domain}/{scheme}/{classname}
 *
 * apiKey required
 */
export const api_getMetadata = async (params: FindMetadtaParams): Promise<EntityMetaData> => {
  const { data } = await apiClient.get(
    `/${BASE_PATH}/${params.domainName}/${params.schema}/${params.simpleClassName}`,
    { headers: { APIKEY: params.apiKey } }
  );

  return data;
};

/**
 * POST /api/capture/{domain}/{scheme}/{type}/{embedded}
 */
export const api_captureMetadata = async (
  params: CaptureMetadataParams
): Promise<EntityMetaData[]> => {
  const { data } = await apiClient.post(
    `/${CAPTURE_BASE_PATH}/${params.domainName}/${params.schema}/${params.type}/${params.embedded}`,
    params.data
  );

  return data;
};

/**
 * POST /api/metadata/{domain}/{scheme}
 *
 * apiKey required
 */
export const api_createMetadata = async (
  params: CreateMetadataParams
): Promise<CreateMetadataResponse> => {
  const { data } = await apiClient.post(
    `/${BASE_PATH}/${params.domainName}/${params.schema}`,
    params.data,
    { headers: { APIKEY: params.apiKey } }
  );

  return data;
};

/**
 * PUT /api/metadata/{domain}/{scheme}
 *
 * apiKey required
 */
export const api_updateMetadata = async (
  params: UpdateMetadataParams
): Promise<EntityMetaData[]> => {
  const { data } = await apiClient.put(
    `/${BASE_PATH}/${params.domainName}/${params.schema}`,
    params.data,
    { headers: { APIKEY: params.apiKey } }
  );

  return data;
};
