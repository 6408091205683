import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Divider, Menu } from 'antd';
import { MenuProps } from 'antd/lib/menu';
import { useDispatch, useSelector } from 'react-redux';

import useContainer from 'components/app/components/containerProvider/useContainer';
import * as C from 'components/app/components/contextNavigation/contextNavigationSc';
import AddFieldAction from 'components/domain/actions/domainDiagramActions/addFieldAction';
import DeleteEntityAction from 'components/domain/actions/domainDiagramActions/deleteEntityAction';
import EditEdgeAction from 'components/domain/actions/domainDiagramActions/editEdgeAction';
import EditEntityAction from 'components/domain/actions/domainDiagramActions/editEntityAction';
import RemoveAllEdgesAction from 'components/domain/actions/domainDiagramActions/removeAllEdgesAction';
import RemoveEdgeAction from 'components/domain/actions/domainDiagramActions/removeEdgeAction';
import RemoveFieldAction from 'components/domain/actions/domainDiagramActions/removeFieldAction';
import { EntityNodeData } from 'services/api/domain/domainDiagram';
import {
  st_domainDiagram_setActiveHandles,
  st_domainDiagram_setActiveNode,
  st_domainDiagram_setContextNavOpen
} from 'services/store/domainDiagram/domainDiagram.actions';
import { st_domainDiagram_getEdges } from 'services/store/domainDiagram/domainDiagram.selectors';

const { SubMenu, ItemGroup } = Menu;

type EntityContextNavigationProps = {
  data: EntityNodeData;
  nodeId: string;
  setOpen: (open: boolean) => void;
};

const MenuDivider: React.FC<MenuProps> = () => <Divider />;

const EntityContextNavigation: React.FC<EntityContextNavigationProps> = ({
  data,
  nodeId,
  setOpen
}) => {
  const { domainDiagramContainer } = useContainer();
  const edgesIn = useSelector(st_domainDiagram_getEdges).filter(e => e.target === nodeId);
  const edgesOut = useSelector(st_domainDiagram_getEdges).filter(e => e.source === nodeId);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(st_domainDiagram_setActiveNode(undefined));
    dispatch(st_domainDiagram_setActiveHandles([]));
    dispatch(st_domainDiagram_setContextNavOpen(false));
    setOpen(false);
  };

  return (
    <C.StyledMenu
      activeKey="never"
      getPopupContainer={() => domainDiagramContainer}
      mode="vertical"
      selectedKeys={[]}
      style={{ width: 256 }}
      onClick={handleClose}
    >
      <EditEntityAction closeNavigation={() => setOpen(false)} data={data} nodeId={nodeId} />

      <SubMenu
        key="remove-edges"
        icon={<FontAwesomeIcon className="anticon" icon={['fas', 'location-slash']} />}
        title={<Trans>Remove Edges</Trans>}
      >
        {edgesOut.length > 0 && (
          <ItemGroup title={<Trans>Edges out</Trans>}>
            {edgesOut.map(edge => (
              <RemoveEdgeAction key={edge.id} edge={edge} type="CONTEXT_NAV" />
            ))}
          </ItemGroup>
        )}
        {edgesIn.length > 0 && (
          <ItemGroup title={<Trans>Edges in</Trans>}>
            {edgesIn.map(edge => (
              <RemoveEdgeAction key={`rm_edg_in_${edge.id}`} edge={edge} type="CONTEXT_NAV" />
            ))}
          </ItemGroup>
        )}
        <MenuDivider />
        <RemoveAllEdgesAction edges={[...edgesOut, ...edgesIn]} type="CONTEXT_NAV" />
      </SubMenu>
      <SubMenu
        key="edit-edges"
        icon={<FontAwesomeIcon className="anticon" icon={['fas', 'location']} />}
        title={<Trans>Edit Edges</Trans>}
      >
        {edgesOut.length > 0 && (
          <ItemGroup title={<Trans>Edges out</Trans>}>
            {edgesOut.map(edge => (
              <EditEdgeAction key={`ed_edg_out_${edge.id}`} edge={edge} type="CONTEXT_NAV" />
            ))}
          </ItemGroup>
        )}
        {edgesIn.length > 0 && (
          <ItemGroup title={<Trans>Edges in</Trans>}>
            {edgesIn.map(edge => (
              <EditEdgeAction key={`ed_edg_in_${edge.id}`} edge={edge} type="CONTEXT_NAV" />
            ))}
          </ItemGroup>
        )}
      </SubMenu>
      <SubMenu
        key="add-field-nav"
        icon={<FontAwesomeIcon className="anticon" icon={['far', 'plus-square']} />}
        title={<Trans>Add field</Trans>}
      >
        <AddFieldAction
          key="add-field-start"
          closeNavigation={() => setOpen(false)}
          data={data}
          icon={['fas', 'arrow-from-top']}
          label={<Trans>Start</Trans>}
          nodeId={nodeId}
          position={data.fields[0]?.name === 'id' ? 1 : 0}
          type="CONTEXT_NAV"
        />
        <AddFieldAction
          key="add-field-end"
          closeNavigation={() => setOpen(false)}
          data={data}
          icon={['fas', 'arrow-from-bottom']}
          label={<Trans>End</Trans>}
          nodeId={nodeId}
          position={data.fields.length}
          type="CONTEXT_NAV"
        />
      </SubMenu>
      <SubMenu
        key="delete-field"
        icon={<FontAwesomeIcon className="anticon" icon={['far', 'minus-square']} />}
        title={<Trans>Remove field</Trans>}
      >
        {data.fields.map(field => (
          <RemoveFieldAction
            key={`rm_field_${field.id}_${field.name}`}
            closeNavigation={() => setOpen(false)}
            data={field}
            label={field.name}
            nodeId={nodeId}
            nodeData={data}
          />
        ))}
      </SubMenu>
      <DeleteEntityAction
        key="delete-entity"
        closeNavigation={() => setOpen(false)}
        data={data}
        nodeId={nodeId}
        isMenu
      />
    </C.StyledMenu>
  );
};

export default EntityContextNavigation;
