import { useEffect, useState } from 'react';

import useDomainName from './useDomainName';

import { useDomain } from 'services/api/domain/domain';

const useApiKey = (): string => {
  const domainName = useDomainName();
  const { data } = useDomain(domainName, { enabled: false });
  const [key, setKey] = useState<string>('');

  useEffect(() => {
    if (data) {
      setKey(data.apiKey);
    }
  }, [data]);

  return key;
};

export default useApiKey;
