import React from 'react';

import { Trans } from '@lingui/macro';
import { Scrollable } from '@phoenix-systems/react-layout';
import { Col, Row } from 'antd';

import * as S from '../dbImportTableSc';

import * as T from 'components/_styled/tableSc';
import DataItem from 'components/ui/dataItem';
import MonacoEditor from 'components/ui/monacoEditor';
import { DbImport } from 'services/api/domain/dataImport';

type DbImportTableOverviewProps = {
  data: DbImport;
};

const DbImportTableOverview: React.FC<DbImportTableOverviewProps> = ({ data }) => {
  if (!data) {
    return null;
  }

  return (
    <Scrollable scrollY>
      <T.SingleContentContainer>
        <Row gutter={[12, 12]}>
          <Col xs={24} md={7}>
            <ul>
              <li>
                <DataItem name={<Trans>Id</Trans>} value={data.id} hasCopy breakWord />
              </li>
              <li>
                <DataItem name={<Trans>Name</Trans>} value={data.name} hasCopy />
              </li>
            </ul>
          </Col>
          <Col xs={24} md={7}>
            <ul>
              <li>
                <DataItem name={<Trans>Entity name</Trans>} value={data.entityName} hasCopy />
              </li>
              <li>
                <DataItem
                  name={<Trans>Update/ create</Trans>}
                  value={data.updatecreate ? 'Update' : 'Create'}
                />
              </li>

              <li>
                <DataItem name={<Trans>Max rows</Trans>} value={data.maxRows?.toString()} hasCopy />
              </li>
              <li>
                <DataItem
                  name={<Trans>Batch size</Trans>}
                  value={data.batchSize?.toString()}
                  hasCopy
                />
              </li>
            </ul>
          </Col>
          <Col xs={24} md={10}>
            <DataItem
              hasCopy
              hasCopyTitle
              copyValue={data.sqlStatement}
              name={<Trans>SQL statement</Trans>}
              value={
                <S.SqlStatement>
                  <MonacoEditor
                    value={data.sqlStatement}
                    mode="SQL"
                    height="270px"
                    //      readOnly
                    showMiniMap={false}
                    showHeader={false}
                    defaultColorTheme="BRIGHT"
                  />
                </S.SqlStatement>
              }
            />
          </Col>
        </Row>
      </T.SingleContentContainer>
    </Scrollable>
  );
};

export default DbImportTableOverview;
