import { useParams } from 'react-router-dom';

import useDomainFromCache from './useDomainFromCache';

const fallbackSchema = 'DEFAULT';

const useSchemaName = (): string => {
  const domain = useDomainFromCache();
  const { schemaName } = useParams<{ schemaName: string }>();

  if (schemaName) {
    return schemaName;
  }

  if (domain) {
    return domain.defaultSchema;
  }

  return fallbackSchema;
};

export default useSchemaName;
