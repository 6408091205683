import React from 'react';

import { Scrollable } from '@phoenix-systems/react-layout';
import { useRouteMatch } from 'react-router-dom';

import * as S from './sidebarLeftSc';

import DomainSelector from 'components/app/components/domainSelector';
import MainNavigation from 'components/app/components/mainNavigation';
import MainNavigationBottom from 'components/app/components/mainNavigation/mainNavigationBottom';
import routes from 'config/routes/routes';

const SidebarLeft: React.FC = () => {
  const isDomainRoute = useRouteMatch(routes.domain.root);
  const isCrateDomain = useRouteMatch(routes.domain.create);

  return (
    <>
      <S.Container>
        <S.Header>{isDomainRoute && <DomainSelector />}</S.Header>
        <S.ScrollableMain>
          {!isCrateDomain && (
            <Scrollable scrollY>
              <MainNavigation />
            </Scrollable>
          )}
        </S.ScrollableMain>
        <S.Footer>
          <MainNavigationBottom />
        </S.Footer>
      </S.Container>
    </>
  );
};

export default SidebarLeft;
