import { AxiosError } from 'axios';
import { QueryObserverResult } from 'react-query';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useNotFound = (queryObserver: QueryObserverResult<any, AxiosError>): boolean => {
  if (queryObserver.isError && queryObserver.error.response?.status === 404) {
    return true;
  }

  if (queryObserver.isSuccess && !queryObserver.data) {
    return true;
  }

  return false;
};

export default useNotFound;
