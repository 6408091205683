import React, { useCallback } from 'react';

import { Trans } from '@lingui/macro';
import { Desktop } from '@phoenix-systems/react-layout';

import Breadcrumbs from 'components/app/components/breadcrumbs';
import DomainPage from 'components/domain/components/domainPage';
import TriggerForm from 'components/trigger/components/triggerForm';
import TriggerHeaderButtons from 'components/trigger/components/triggerHeaderButtons';
import Button from 'components/ui/button';
import ErrorInfo from 'components/ui/errorInfo';
import Exception from 'components/ui/exception';
import Page from 'components/ui/page';
import routes from 'config/routes/routes';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';
import useIsDomain from 'hooks/useIsDomain';
import useNotFound from 'hooks/useNotFound';
import useTriggerName from 'hooks/useTriggerName';
import { useTriggerByName } from 'services/api/domain/trigger';
import history from 'services/history';

const TriggerSingle: React.FC = () => {
  const isDomain = useIsDomain();
  const triggerName = useTriggerName();
  const triggerQuery = useTriggerByName(triggerName);
  const isNotFound = useNotFound(triggerQuery);
  const goto = useGoToDomainRoute();

  const title = <Trans>Edit trigger {triggerName}</Trans>;

  const getContent = useCallback(() => {
    if (isNotFound) {
      return (
        <Exception
          code={404}
          message={
            <Trans>
              Trigger with name <b>&quot;{triggerName}&quot;</b> was not found.
            </Trans>
          }
          buttons={
            <Button
              onClick={() => {
                if (isDomain) {
                  goto(routes.domain.scheduler.list);
                } else {
                  history.push(routes.scheduler.list);
                }
              }}
            >
              <Trans>Back to list</Trans>
            </Button>
          }
        />
      );
    }

    if (triggerQuery.isError) {
      return (
        <ErrorInfo
          error={triggerQuery.error}
          message={
            <Trans>
              Failed to load trigger <b>&quot;{triggerName}&quot;</b>
            </Trans>
          }
        />
      );
    }

    if (triggerQuery.data) {
      return <TriggerForm data={triggerQuery.data} mode="EDIT" />;
    }

    return null;
  }, [triggerQuery, isNotFound, triggerName, isDomain, goto]);

  if (!isDomain) {
    return (
      <Page
        noScroll={isNotFound}
        isLoading={triggerQuery.isLoading}
        title={title}
        scrollableProps={{ scrollId: 'page-scroll' }}
        content={<>{getContent()}</>}
        titleTop={
          <Desktop>
            <Breadcrumbs />
          </Desktop>
        }
        titleElements={
          <TriggerHeaderButtons hasList hasCalendar hasActions data={triggerQuery.data} />
        }
      />
    );
  }

  return (
    <DomainPage
      route={routes.domain.scheduler.single}
      routeParams={{ path: triggerName }}
      noScroll={isNotFound}
      isLoading={triggerQuery.isLoading}
      title={title}
      content={<>{getContent()}</>}
      headerButtons={
        <TriggerHeaderButtons hasActions hasList hasCalendar data={triggerQuery.data} />
      }
    />
  );
};

export default TriggerSingle;
