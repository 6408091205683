import React from 'react';

import { Trans } from '@lingui/macro';

import * as F from 'components/_styled/formSc';
import AppLayout from 'components/app/components/appLayout';
import DomainPage from 'components/domain/components/domainPage';
import MergeDomainForm from 'components/domain/components/mergeDomainForm';
import ReindexForm from 'components/domain/components/reindexForm';
import routes from 'config/routes/routes';
import { useDomains } from 'services/api/domain/domain';

const DomainAdministrationPage: React.FC = () => {
  const domainsQuery = useDomains();

  return (
    <AppLayout>
      <DomainPage
        route={routes.domain.dataManagement.domainAdministration}
        isLoading={domainsQuery.isLoading}
        title={<Trans>Domain administration</Trans>}
        content={
          <>
            {domainsQuery.data && domainsQuery.data?.length > 1 && (
              <>
                <MergeDomainForm />
                <F.Separator />
              </>
            )}
            <ReindexForm />
          </>
        }
      />
    </AppLayout>
  );
};

export default DomainAdministrationPage;
