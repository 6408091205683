import { ListQueryParams } from '@phoenix-systems/react-table/dist/types/types';
import { createSelector } from 'reselect';

import {
  EntityDocumentState,
  QueryDocumentsLayout,
  QueryDocumentsQueryMode,
  QueryDocumentsResultMode
} from './entityDocument.types';

import { SolrQueryObject } from 'services/api/domain/solrQuery';

export const entityDocumentState = (state: {
  entityDocument: EntityDocumentState;
}): EntityDocumentState => state.entityDocument;

export const st_entityDocument_getLayout = createSelector(
  [entityDocumentState],
  (entityDocument): QueryDocumentsLayout => entityDocument.layout
);

export const st_entityDocument_getQueryMode = createSelector(
  [entityDocumentState],
  (entityDocument): QueryDocumentsQueryMode => entityDocument.queryMode
);

export const st_entityDocument_getResultMode = createSelector(
  [entityDocumentState],
  (entityDocument): QueryDocumentsResultMode => entityDocument.resultMode
);

export const st_entityDocument_getQuery = createSelector(
  [entityDocumentState],
  (entityDocument): SolrQueryObject | undefined => entityDocument.query
);

export const st_entityDocument_getEntity = createSelector(
  [entityDocumentState],
  (entityDocument): string | undefined => entityDocument.entity
);

export const st_entityDocument_getQueryCollapsed = createSelector(
  [entityDocumentState],
  (entityDocument): boolean => entityDocument.queryCollapsed
);

export const st_entityDocument_getSelectedKey = createSelector(
  [entityDocumentState],
  (entityDocument): string | undefined => entityDocument.selectedKey
);

export const st_entityDocument_getListParams = createSelector(
  [entityDocumentState],
  (entityDocument): ListQueryParams => entityDocument.listParams
);
