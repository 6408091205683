import React, { useEffect, useRef, useState } from 'react';

import { Scrollable } from '@phoenix-systems/react-layout';
import { useDispatch, useSelector } from 'react-redux';

import DocsSearch from '../docsSearch';

import * as S from './docsDrawerSc';

import Breadcrumbs from 'components/app/components/breadcrumbs';
import useContainer from 'components/app/components/containerProvider/useContainer';
import Button from 'components/ui/button/button';
import Editor from 'components/ui/editor';
import Loader from 'components/ui/loader';
import { DocConfig } from 'config/docs/docs.config.types';
import { getDocConfig } from 'config/docs/docs.utils';
import { DocId, useHint } from 'services/api/domain/hint';
import history from 'services/history';
import {
  st_docs_closeDrawer,
  st_docs_drawerBack,
  st_docs_drawerForward
} from 'services/store/docs/docs.actions';
import {
  st_docs_getDrawerCurrentId,
  st_docs_getIsBackEnabled,
  st_docs_getIsDrawerOpen,
  st_docs_getIsForwardEnabled
} from 'services/store/docs/docs.selectors';

const DocsDrawer: React.FC = () => {
  const { docsContainer } = useContainer();
  const isOpen = useSelector(st_docs_getIsDrawerOpen);
  const docId = useSelector(st_docs_getDrawerCurrentId);
  const isBackEnabled = useSelector(st_docs_getIsBackEnabled);
  const isForwardEnabled = useSelector(st_docs_getIsForwardEnabled);
  const dispatch = useDispatch();
  const [docConfig, setDocDonfig] = useState<DocConfig>();
  const hintQuery = useHint(docId || DocId.DOMAIN, { enabled: false });
  const { current: hintQueryRef } = useRef(hintQuery);

  history.listen(() => {
    if (isOpen) {
      dispatch(st_docs_closeDrawer());
    }
  });

  const handleGotoDocs = () => {
    if (docConfig && docConfig.path) {
      history.push(docConfig.path);
    }
  };

  useEffect(() => {
    if (docId) {
      setDocDonfig(getDocConfig(docId));
    } else {
      setDocDonfig(undefined);
    }
  }, [docId]);

  useEffect(() => {
    if (docConfig) {
      hintQueryRef.refetch();
    }
  }, [docConfig, hintQueryRef]);

  return (
    <S.StyledDrawer
      title={
        <S.DrawerHead>
          <S.DrawerTop>
            <Breadcrumbs docsPath={docConfig?.path} />
            <DocsSearch />
          </S.DrawerTop>
          {hintQuery.data && (
            <>
              <S.DrawerTitle>
                <S.NavButton>
                  <Button
                    disabled={!isBackEnabled}
                    icon={['fal', 'angle-left']}
                    type="link"
                    onClick={() => dispatch(st_docs_drawerBack())}
                  />
                </S.NavButton>

                <div className="title">
                  <h2>{hintQuery.data.title}</h2>
                </div>
                <S.NavButton>
                  <Button
                    disabled={!isForwardEnabled}
                    icon={['fal', 'angle-right']}
                    type="link"
                    onClick={() => dispatch(st_docs_drawerForward())}
                  />
                </S.NavButton>
              </S.DrawerTitle>
            </>
          )}
        </S.DrawerHead>
      }
      visible={isOpen}
      width="75vw"
      onClose={() => dispatch(st_docs_closeDrawer())}
      getContainer={() => docsContainer}
      footer={
        <>
          <Button type="ghost" onClick={() => dispatch(st_docs_closeDrawer())}>
            Close
          </Button>
          <Button type="ghost" onClick={handleGotoDocs}>
            Go to docs
          </Button>
        </>
      }
    >
      <S.DrawerMain>
        <Scrollable scrollY>
          <S.DrawerMainInner>
            {hintQuery.isFetching ? (
              <Loader fullHeight />
            ) : (
              <Editor value={hintQuery.data?.parsedText} readonly />
            )}
          </S.DrawerMainInner>
        </Scrollable>
      </S.DrawerMain>
    </S.StyledDrawer>
  );
};

export default DocsDrawer;
