import React from 'react';

import { Redirect } from 'react-router';

import routes from '../routes/routes';

import { ViewConfig } from './view.types';

import DashboardPage from 'components/dashboard/pages/dashboardPage';

const dashboardViewConfig: ViewConfig = {
  routes: [
    {
      path: routes.dashboard.domains,
      exact: true,
      component: <DashboardPage />
    },
    {
      path: routes.dashboard.root,
      exact: true,
      component: <Redirect to={routes.dashboard.domains} />
    }
  ]
};

export default dashboardViewConfig;
