import { ListQueryParams } from '@phoenix-systems/react-table/dist/types/types';
import { produce } from 'immer';
import { assign, clone } from 'lodash';

import { EntityDocumentActionCreators } from './entityDocument.actions';
import { EntityDocumentActionTypes, EntityDocumentState } from './entityDocument.types';

const initListParams: ListQueryParams = {
  pageSize: 20,
  currentPage: 1,
  sort: undefined,
  filter: undefined,
  searchText: undefined
};

const initialState: EntityDocumentState = Object.freeze<EntityDocumentState>({
  layout: 'VERTICAL',
  queryMode: 'JSON',
  query: undefined,
  resultMode: 'JSON',
  queryCollapsed: false,
  selectedKey: undefined,
  listParams: clone(initListParams),
  entity: undefined
});

const entityDocumentReducer = (
  state: EntityDocumentState = initialState,
  action: EntityDocumentActionCreators
): EntityDocumentState =>
  produce(state, draft => {
    switch (action.type) {
      case EntityDocumentActionTypes.SET_LAYOUT:
        draft.layout = action.layout;

        break;

      case EntityDocumentActionTypes.SET_QUERY_MODE:
        draft.queryMode = action.mode;

        break;

      case EntityDocumentActionTypes.SET_QUERY:
        draft.query = action.query;

        break;

      case EntityDocumentActionTypes.SET_ENTITY:
        draft.entity = action.entity;

        break;

      case EntityDocumentActionTypes.SET_RESULT_MODE:
        draft.resultMode = action.mode;

        break;

      case EntityDocumentActionTypes.SET_QUERY_COLLAPSED:
        draft.queryCollapsed = action.collapsed;

        break;

      case EntityDocumentActionTypes.SET_SELECTED_KEY:
        draft.selectedKey = action.key;

        break;

      case EntityDocumentActionTypes.SET_LIST_PARAMS:
        if (action.params === undefined) {
          draft.listParams = initListParams;

          break;
        }

        draft.listParams = assign({}, draft.listParams, action.params);

        break;

      case EntityDocumentActionTypes.RESET:
        draft.layout = 'VERTICAL';
        draft.queryMode = 'JSON';
        draft.query = undefined;
        draft.resultMode = 'JSON';
        draft.queryCollapsed = false;
        draft.selectedKey = undefined;
        draft.listParams = clone(initListParams);
        draft.entity = undefined;

        break;

      default:
        break;
    }
  });

export default entityDocumentReducer;
