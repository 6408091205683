import { useMyself } from 'services/api/domain/user';

export const useIsSuperadmin = (): boolean => {
  const { data } = useMyself({ enabled: false });

  if (!data) {
    return false;
  }

  return data.username === 'superadmin';
};
