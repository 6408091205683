import tw, { css, styled } from 'twin.macro';

export const Wrapper = styled.div(() => [
  css`
    ${tw`border border-ant-border-gray rounded-4 overflow-hidden`}
  `
]);

export const FilesList = styled.div(() => [
  css`
    & .ant-radio-group {
      ${tw`block`};

      & .title {
        ${tw`text-14 font-600 px-12 py-6 mt-6 bg-light-gray mt-0 text-primary`};

        &.local {
          ${tw`border-b border-ant-border-gray`};

          &:last-of-type {
            ${tw`border-transparent`};
          }
        }

        &.remote {
          ${tw`border-b border-t border-ant-border-gray `};

          & span {
            ${tw`text-primary`};
          }
        }
      }

      & .ant-empty {
        ${tw`m-0`};
      }

      & .ant-radio-wrapper {
        ${tw`block px-12 py-8 border-b border-ant-border-gray flex flex-row items-center w-full`};

        .ant-radio-disabled + span {
          cursor: default;
          color: unset;
          opacity: 0.8;
        }

        & span.ant-radio + * {
          ${tw`w-full`};
        }

        &:last-of-type {
          ${tw`border-transparent`};
        }

        & .ant-radio {
          ${tw`mr-8`};
        }
      }
    }
  `
]);

export const FileItem = styled.div(() => [
  css`
    ${tw`flex flex-row items-center`};

    & .file {
      ${tw`flex items-center mr-16 float-left`};
      max-width: 2.6rem;
    }

    & .info {
      ${tw`flex flex-row w-full`};
      & p {
        ${tw`text-14`};

        &.name {
          /* ${tw`w-full`}; */
        }

        &.permissions {
          ${tw`ml-auto`};
          /*  width: 20rem; */
        }
      }
    }
  `
]);
