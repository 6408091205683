import { useSelector } from 'react-redux';

import { Language } from 'config/langauge/languages.types';
import { st_app_getLanguage } from 'services/store/app/app.selectors';

const useLanguage = (): Language => {
  const language = useSelector(st_app_getLanguage);

  if (!language) {
    return Language.EN;
  }

  return language;
};

export default useLanguage;
