import { Button } from 'antd';
import tw, { styled, css } from 'twin.macro';

export const LoginScreen = styled.div(() => [
  css`
    ${tw`h-screen overflow-hidden flex justify-center items-center bg-light-gray`};
    background: url('/img/bg.jpeg');
    background-size: cover;
    /*  background: linear-gradient(0deg, #060121, #0b0b40 74%, #20036b);  */
  `
]);

export const LoginContainer = styled.div(() => [
  css`
    ${tw`bg-white rounded shadow-lg p-30 pb-10 m-15 w-full`};
    max-width: 500px;
    border-radius: 3px;

    .ant-input {
      ${tw`font-700`};
    }

    &.shake {
      animation: shake 0.6s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
    }

    @keyframes shake {
      10%,
      90% {
        transform: translate3d(-1px, 0, 0);
      }

      20%,
      80% {
        transform: translate3d(2px, 0, 0);
      }

      30%,
      50%,
      70% {
        transform: translate3d(-4px, 0, 0);
      }

      40%,
      60% {
        transform: translate3d(4px, 0, 0);
      }
    }
  `
]);

export const Logo = styled.img(() => [
  css`
    ${tw`w-full h-auto mx-auto mb-30`};
    max-width: 300px;
  `
]);

export const LoginButton = styled(Button)(() => [
  css`
    ${tw`w-full h-34 mt-6`};
  `
]);
