import React, { useCallback, useEffect, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { getRoute } from '@phoenix-systems/react-router';
import { Table } from '@phoenix-systems/react-table';
import {
  CustomColumnType,
  ListQueryParams,
  TableRef
} from '@phoenix-systems/react-table/dist/types/types';
import Highlighter from 'react-highlight-words';
import { useDispatch, useSelector } from 'react-redux';
import { useImmer } from 'use-immer';

import UsersTableSingle from './usersTableSingle';

import * as T from 'components/_styled/tableSc';
import Loader from 'components/ui/loader';
import UserActions from 'components/userAdmin/actions/userActions';
import tableLocales from 'config/langauge/table.locales';
import routes from 'config/routes/routes';
import { User, useUsers } from 'services/api/domain/user';
import history from 'services/history';
import { st_triggersTable_setSelectedKey } from 'services/store/triggersTable/triggersTable.actions';
import {
  st_triggersTable_getParams,
  st_triggersTable_getSelectedKey
} from 'services/store/triggersTable/triggersTable.selectors';
import {
  st_usersTable_setParams,
  st_usersTable_setSelectedKey
} from 'services/store/usersTable/usersTable.actions';

type UsersTableState = {
  data: User[];
  isInitialized: boolean;
};

const UsersTable: React.FC = () => {
  const tableRef = useRef<TableRef>(null);

  const dispatch = useDispatch();
  const params = useSelector(st_triggersTable_getParams);
  const selectedKey = useSelector(st_triggersTable_getSelectedKey);
  const [state, setState] = useImmer<UsersTableState>({
    data: [],
    isInitialized: false
  });

  const usersQuery = useUsers();

  const handleSelectChange = (selectedRowKeys: React.Key[]) => {
    dispatch(st_triggersTable_setSelectedKey(selectedRowKeys[0] as string));
  };

  const handleRemoveSelectedItem = () => {
    dispatch(st_triggersTable_setSelectedKey(undefined));
  };

  const handleGotoUser = useCallback((username: string) => {
    history.push(getRoute(routes.userAdmin.user.single, username));
  }, []);

  const getColumns = useCallback(() => {
    const cls: CustomColumnType<User>[] = [
      {
        title: i18n._('Username'),
        dataIndex: 'username',
        key: 'username',
        className: 'bold',
        sorter: (a, b) => a.username.localeCompare(b.username),
        // eslint-disable-next-line react/display-name
        render: (value: string) => (
          <>
            <Highlighter
              searchWords={params?.searchText ? [params.searchText] : []}
              textToHighlight={value}
              autoEscape
            />
            <T.EditBtn
              className="edit-btn"
              type="link"
              icon={<FontAwesomeIcon icon={['fas', 'pen']} onClick={() => handleGotoUser(value)} />}
            />
          </>
        )
      },
      {
        title: i18n._('Id'),
        dataIndex: 'id',
        key: 'id',
        disabled: true,
        ellipsis: true,
        sorter: (a, b) => a.id.localeCompare(b.id)
      },
      {
        title: i18n._('Organisations'),
        dataIndex: 'orgUnits',
        key: 'orgUnits',
        disabled: false,
        render: value => value?.join(', ')
      },
      {
        title: i18n._('Groups'),
        dataIndex: 'groups',
        key: 'groups',
        disabled: false,
        render: value => value?.join(', ')
      },

      {
        title: <FontAwesomeIcon icon={['fas', 'cog']} className="setting-icon" />,
        key: 'actions',
        width: 50,
        className: 'actions',
        // eslint-disable-next-line react/display-name
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        render: (value, record) => (
          <UserActions
            mode="ICON_BUTTON"
            data={record}
            popoverPlacement="leftTop"
            className="edit-btn"
          />
        ),
        hiddenInConfig: true
      }
    ];

    return cls;
  }, [params?.searchText, handleGotoUser]);

  useEffect(() => {
    if (tableRef.current && params && !state.isInitialized) {
      tableRef.current.setQueryParams(params);
      setState(draft => {
        draft.isInitialized = true;
      });
    }
  }, [params, state.isInitialized, setState]);

  useEffect(() => {
    if (params?.searchText && params.searchText !== '') {
      const matches = usersQuery.data?.filter(
        d => d.username.search(new RegExp(params.searchText || '', 'gi')) !== -1
      );

      if (matches) {
        setState(draft => {
          draft.data = matches || [];
        });

        return;
      }
      setState(draft => {
        draft.data = usersQuery.data || [];
      });
    } else {
      setState(draft => {
        draft.data = usersQuery.data || [];
      });
    }
  }, [usersQuery.data, params?.searchText, setState]);

  return (
    <>
      <T.StyledTable className="domain">
        <div>
          <Table
            ref={tableRef}
            table={{
              dataSource: state.data,
              loading: usersQuery.isFetching,
              size: 'small',
              rowKey: 'username',
              columns: getColumns(),
              rowSelection: {
                type: 'radio',
                onChange: handleSelectChange,
                selectedRowKeys: selectedKey !== undefined ? [selectedKey] : []
              },
              onRow: (record: User) => ({
                onClick: e => {
                  const isEditBtn =
                    (e.target as HTMLElement).closest('.edit-btn') !== null ||
                    (e.target as HTMLElement).classList.contains('edit-btn') ||
                    (e.target as HTMLElement).closest('.ant-popover') !== null;

                  if (!isEditBtn) {
                    dispatch(st_usersTable_setSelectedKey(record.username));
                  }
                },
                onDoubleClick: () => {
                  handleGotoUser(record.username);
                }
              }),

              pagination: {
                pageSizeOptions: ['10', '20', '50'],
                pageSize: params?.pageSize || 20,
                current: params?.currentPage || 1,
                showSizeChanger: true
              }
            }}
            defaultTableContainerHeight="calc(100% - 440px)"
            fillHeight
            layout="single-split"
            topNavElements={{
              search: {
                value: params?.searchText,
                placeholder: i18n._('Search users...')
              },
              totalCount: {
                icon: <FontAwesomeIcon icon={['fas', 'user']} />,
                loader: <Loader size={12} />
              },
              reload: {
                onChange: () => {
                  usersQuery.refetch();
                }
              },
              resetParams: true
            }}
            onRemoveSelectedItem={handleRemoveSelectedItem}
            locales={{
              '1 item selected': i18n._('1 user selected'),
              'items selected': i18n._('users selected'),
              'Nothing selected': i18n._('No user selected'),
              ...tableLocales
            }}
            renderSingle={(selectedItems: User[]) => (
              <>
                <UsersTableSingle data={selectedItems} />
              </>
            )}
            onQueryParamsChange={(currentParams: Partial<ListQueryParams>) => {
              dispatch(st_usersTable_setParams(currentParams));
            }}
            activeFilters={{ enabled: true, placement: 'default' }}
          />
        </div>
      </T.StyledTable>
    </>
  );
};

export default UsersTable;
