import { ListQueryParams } from '@phoenix-systems/react-table/dist/types/types';

export enum FtpConnectionsTableActionTypes {
  SET_PARAMS = 'ftpConnectionsTable/SET_PARAMS',
  SET_SINGLE_VIEW = 'ftpConnectionsTable/SET_SINGLE_VIEW',
  SET_SELECTED_KEY = 'ftpConnectionsTable/SET_SELECTED_KEY',
  RESET = 'ftpConnectionsTable/RESET'
}

export type FtpConnectionsTableSingleView = 'OVERVIEW' | 'CONNECTED_TASKS';

export type FtpConnectionsTableState = Readonly<{
  selectedKey?: string;
  params: ListQueryParams;
  singleView: FtpConnectionsTableSingleView;
}>;
