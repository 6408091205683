import React, { useState } from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { useDispatch } from 'react-redux';

import DocsTooltipContent from './docsTooltipContent';
import * as S from './docsTooltipSc';

import useContainer from 'components/app/components/containerProvider/useContainer';
import ErrorInfo from 'components/ui/errorInfo';
import Loader from 'components/ui/loader';
import { DocId, useHint } from 'services/api/domain/hint';
import { st_docs_openDrawer } from 'services/store/docs/docs.actions';

type DocsTooltipProps = {
  docId: DocId;
  icon?: IconProp;
  translateY?: number;
  translateX?: number;
  trigger?: 'click' | 'hover';
  placement?:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom';
};

const DocsTooltip: React.FC<DocsTooltipProps> = ({
  docId,
  trigger,
  placement,
  icon,
  translateY,
  translateX
}) => {
  const { infoContainer } = useContainer();
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();

  const hintQuery = useHint(docId, { enabled: false });

  const handleVisibleChange = (open: boolean) => {
    if (open) {
      hintQuery.refetch();
    }
    setOpen(open);
  };

  return (
    <S.InfoPopover
      getPopupContainer={() => infoContainer}
      overlayClassName="info-box-tooltip"
      placement={placement || 'bottomLeft'}
      content={
        <>
          {hintQuery.isLoading ? (
            <Loader height={100} />
          ) : (
            <>
              {hintQuery.isError && (
                <ErrorInfo error={hintQuery.error} message={<Trans>Failed to load infos.</Trans>} />
              )}
              {hintQuery.isSuccess && (
                <DocsTooltipContent docId={docId} setOpen={setOpen} data={hintQuery.data} />
              )}
            </>
          )}
        </>
      }
      trigger={trigger || 'hover click'}
      visible={isOpen}
      onVisibleChange={handleVisibleChange}
    >
      <S.InfoButton
        className="info-btn"
        translateX={translateX}
        translateY={translateY}
        onClick={() => dispatch(st_docs_openDrawer(docId))}
      >
        <FontAwesomeIcon icon={icon || ['fal', 'question-circle']} />
      </S.InfoButton>
    </S.InfoPopover>
  );
};

export default DocsTooltip;
