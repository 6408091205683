import React, { useEffect, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { FormItem, useFormContext } from '@phoenix-systems/react-form';
import { Form } from 'antd';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { useImmer } from 'use-immer';

import EntityFieldSelector from '../entityFieldSelector';

import * as S from './metadataEditFormSc';

import * as F from 'components/_styled/formSc';
import useContainer from 'components/app/components/containerProvider/useContainer';
import DocsTooltip from 'components/docs/components/docsTooltip';
import TooltipLabel from 'components/docs/components/docsTooltip/tooltipLabel';
import Button from 'components/ui/button';
import ErrorInfo from 'components/ui/errorInfo';
import FormContainer from 'components/ui/formContainer';
import Loader from 'components/ui/loader';
import MonacoEditor from 'components/ui/monacoEditor';
import NoContent from 'components/ui/noContent';
import routes from 'config/routes/routes';
import useApiKey from 'hooks/useApiKey';
import useDomainName from 'hooks/useDomainName';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';
import useNotification from 'hooks/useNotification';
import useOnDomainSchemaChange from 'hooks/useOnDomainSchemaChange';
import useSchemaName from 'hooks/useSchemaName';
import { DocId } from 'services/api/domain/hint';
import {
  api_updateMetadata,
  EntityMetaData,
  UpdateMetadataParams,
  useMetadata
} from 'services/api/domain/metadata';

type MetadatFormParams = {
  type: string;
  data: EntityMetaData;
};

type MetadataEditFormState = {
  metadataJson?: EntityMetaData;
};

const MetadataEditForm: React.FC = () => {
  const { entityName } = useParams<{ entityName: string }>();
  const domainName = useDomainName();
  const [form] = Form.useForm<MetadatFormParams>();
  const schema = useSchemaName();
  const { formContainer } = useContainer();
  const [addNotification] = useNotification();
  const [state, setState] = useImmer<MetadataEditFormState>({
    metadataJson: undefined
  });
  const goto = useGoToDomainRoute();
  const [formState, formActions] = useFormContext(form, { type: entityName, data: undefined });
  const apiKey = useApiKey();

  const metadataQuery = useMetadata(
    { domainName, schema, simpleClassName: entityName, apiKey },
    {
      enabled: false,
      onSuccess: resData => {
        formActions.reset({ simpleClassName: entityName, data: undefined }, true);
        formActions.reset({ simpleClassName: entityName, data: resData }, true);
        setState(draft => {
          draft.metadataJson = resData;
        });
      }
    }
  );
  const { current: metadataQueryRef } = useRef(metadataQuery);

  const updateMutation = useMutation<EntityMetaData[], AxiosError, UpdateMetadataParams>(
    payload => api_updateMetadata(payload),
    {
      onSuccess: resData => {
        addNotification({
          type: 'success',
          message: i18n._(
            t`Successfully updated metadata of entity "${resData[0]?.simpleClassName}".`
          )
        });
        updateMutation.reset();
        formActions.reset({ data: resData[0], type: entityName });
      },
      onError: () => {
        addNotification({
          type: 'error',
          message: i18n._(t`Failed to updated metadata of entity "${entityName}".`)
        });
      }
    }
  );

  const handleEntityChange = (value: string) => {
    if (value && value !== '') {
      goto(routes.domain.queryData.metaDataEntity, { path: value });
    }
  };

  const handleReset = () => {
    if (entityName) {
      formActions.reset({ type: entityName });
      metadataQuery.refetch();
    }
  };

  const handleSave = () => {
    if (apiKey !== '') {
      form.validateFields().then(formData => {
        updateMutation.mutate({
          data: [formData.data],
          schema,
          domainName,
          apiKey
        });
      });
    }
  };

  useEffect(() => {
    if (entityName && entityName !== '' && apiKey !== '') {
      metadataQueryRef.refetch();
    }
  }, [entityName, metadataQueryRef, apiKey]);

  useOnDomainSchemaChange(handleReset);

  return (
    <S.Wrapper>
      <div>
        <FormContainer
          isStickyDisabled
          maxWidth={1100}
          fullHeight
          title={
            <>
              <FontAwesomeIcon icon={['fas', 'code']} />
              <Trans>Metadata</Trans>
              <DocsTooltip docId={DocId.DOMAIN_QUERY_METADATA} />
            </>
          }
          buttons={
            <>
              <Button
                action="reset"
                disabled={!state.metadataJson || !formState.hasChanged || updateMutation.isLoading}
                onClick={handleReset}
              />
              <Button
                action="save"
                disabled={!state.metadataJson || !formState.hasChanged || updateMutation.isLoading}
                loading={updateMutation.isLoading}
                onClick={handleSave}
              />
            </>
          }
          messages={
            <>
              {updateMutation.isError && (
                <ErrorInfo
                  error={updateMutation.error}
                  message={<Trans>Failed to update metadata of entity {entityName}</Trans>}
                />
              )}
            </>
          }
        >
          <F.StyledForm
            form={form}
            layout="horizontal"
            validateTrigger="onChange"
            $labelWidth={115}
            name="metadata-query-form"
            onFieldsChange={formActions.onFieldsChange}
            onValuesChange={formActions.onValuesChange}
            initialValues={{ type: entityName }}
            colon={false}
          >
            <S.FormWrapper>
              <div>
                <FormItem
                  label={
                    <TooltipLabel
                      docId={DocId.DOMAIN_JSON_IMPEX_EXPORT_ENTITY}
                      label={<Trans>Entity</Trans>}
                    />
                  }
                  messageVariables={{ name: i18n._('Entity') }}
                  name="type"
                  registerField={formActions.registerField}
                >
                  <EntityFieldSelector
                    getPopupContainer={() => formContainer}
                    treeDefaultExpandAll
                    onChangePrepareValue={value => value?.value}
                    onChange={handleEntityChange}
                  />
                </FormItem>
                <F.Separator />
              </div>
              <div className="editor">
                {!state.metadataJson && !metadataQuery.isError && !metadataQuery.isLoading && (
                  <NoContent />
                )}
                {metadataQuery.isLoading && <Loader />}
                {metadataQuery.isError && (
                  <ErrorInfo
                    error={metadataQuery.error}
                    message={<Trans>Failed to load metadata for entity {entityName}</Trans>}
                  />
                )}
                {metadataQuery.isSuccess && (
                  <FormItem
                    label={
                      <TooltipLabel
                        docId={DocId.DOMAIN_JSON_IMPEX_IMPORT}
                        label={<Trans>JSON data</Trans>}
                      />
                    }
                    messageVariables={{ name: i18n._(t`JSON data`) }}
                    name="data"
                    rules={[{ required: true }]}
                    registerField={formActions.registerField}
                  >
                    <MonacoEditor height="100%" mode="JSON" />
                  </FormItem>
                )}
              </div>
            </S.FormWrapper>
          </F.StyledForm>
        </FormContainer>
      </div>
    </S.Wrapper>
  );
};

export default MetadataEditForm;
