/* eslint-disable no-console */
export const log = (function handleLog() {
  if (window._env_.ENVIRONMENT === 'local' || window._env_.ENVIRONMENT === 'development') {
    return Function.prototype.bind.call(console.log, console);
  }

  return () => {};
})();

export const logWarn = (function handleWarn() {
  if (window._env_.ENVIRONMENT === 'local' || window._env_.ENVIRONMENT === 'development') {
    return Function.prototype.bind.call(console.warn, console);
  }

  return () => {};
})();

export const logError = (function handleError() {
  if (window._env_.ENVIRONMENT === 'local' || window._env_.ENVIRONMENT === 'development') {
    return Function.prototype.bind.call(console.error, console);
  }

  return () => {};
})();
