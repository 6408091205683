import React from 'react';

import routes from '../routes/routes';

import { ViewConfig } from './view.types';

import LoginPage from 'components/app/pages/loginPage';

const loginViewConfig: ViewConfig = {
  routes: [
    {
      path: routes.login.root,
      exact: true,
      component: <LoginPage />
    }
  ]
};

export default loginViewConfig;
