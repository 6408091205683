import tw, { css, styled } from 'twin.macro';

export const Wrapper = styled.div(() => [
  css`
    ${tw`border border-ant-border-gray rounded-4 overflow-hidden p-12`}
  `
]);

export const SelectWrapper = styled.div(() => [
  css`
    ${tw`flex flex-row`}

    & .actions {
      ${tw`border border-ant-border-gray h-32 flex items-center bg-light-gray ml-12`}
    }
  `
]);

export const Footer = styled.div(() => [
  css`
    ${tw`mt-12`}
  `
]);
