import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import * as T from 'components/_styled/tableSc';
import { Trigger } from 'services/api/domain/trigger';
import { st_triggersTable_setSingleView } from 'services/store/triggersTable/triggersTable.actions';
import { st_triggersTable_getSingleView } from 'services/store/triggersTable/triggersTable.selectors';
import { TriggersTableSingleView } from 'services/store/triggersTable/triggersTable.types';

const { TabPane } = Tabs;

type TriggerTableNavProps = {
  data: Trigger;
};

const TriggerTableNav: React.FC<TriggerTableNavProps> = () => {
  const view = useSelector(st_triggersTable_getSingleView);
  const dispatch = useDispatch();
  const handleTabChange = (value: string) => {
    dispatch(st_triggersTable_setSingleView(value as TriggersTableSingleView));
  };

  return (
    <T.SingleNav>
      <T.TabNavigation defaultActiveKey="1" onChange={handleTabChange} activeKey={view}>
        <TabPane
          tab={
            <>
              <FontAwesomeIcon icon={['fas', 'info-circle']} />
              <Trans>Overview</Trans>
            </>
          }
          key="OVERVIEW"
        />
      </T.TabNavigation>
    </T.SingleNav>
  );
};

export default TriggerTableNav;
