import tw, { css, styled } from 'twin.macro';

export const ActionFeedback = styled.div(() => [
  css`
    ${tw`mt-12`};
  `
]);

export const ImportSummary = styled.div(() => [
  css`
    ${tw`mt-12`};
  `
]);
