import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Menu } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { omit } from 'lodash';
import { useDispatch } from 'react-redux';
import { useImmer } from 'use-immer';

import * as S from '../actionsSc';

import EntityEdgeName from 'components/domain/components/domainDiagram/entityEdge/entityEdgeName';
import Button from 'components/ui/button';
import useDefaultModalProps from 'hooks/useDefaultModalProps';
import { EntityEdge } from 'services/api/domain/domainDiagram';
import {
  st_domainDiagram_removeEdge,
  st_domainDiagram_setDialogOpen
} from 'services/store/domainDiagram/domainDiagram.actions';

type RemoveEdgeActionProps = {
  edge: EntityEdge;
  type: 'CONTEXT_NAV' | 'EDGE_NAV';
};

type RemoveEdgeActionState = {
  isDialogOpen: boolean;
};

const RemoveEdgeAction: React.FC<RemoveEdgeActionProps> = props => {
  const pr = omit(props, ['edge', 'type']);
  const { edge, type } = props;
  const dispatch = useDispatch();
  const [state, setState] = useImmer<RemoveEdgeActionState>({
    isDialogOpen: false
  });
  const modalProps = useDefaultModalProps();

  const handleRemoveEdgeRequested = () => {
    setState(draft => {
      draft.isDialogOpen = true;
    });
    dispatch(st_domainDiagram_setDialogOpen(true));
  };

  const handleDialogClose = () => {
    dispatch(st_domainDiagram_setDialogOpen(false));
    setState(draft => {
      draft.isDialogOpen = false;
    });
  };

  const handleRemoveEdge = () => {
    dispatch(st_domainDiagram_removeEdge(edge.id));
    handleDialogClose();
  };

  return (
    <>
      {type === 'CONTEXT_NAV' && (
        <Menu.Item
          {...pr}
          key={`remove-edge_${edge.id}`}
          icon={<FontAwesomeIcon className="anticon" icon={['fas', 'location-slash']} />}
          onClick={handleRemoveEdgeRequested}
        >
          <EntityEdgeName edge={edge} />
        </Menu.Item>
      )}

      {type === 'EDGE_NAV' && (
        <Menu.Item
          {...pr}
          key={`remove-edge_${edge.id}`}
          icon={<FontAwesomeIcon className="anticon" icon={['fas', 'trash']} />}
          onClick={handleRemoveEdgeRequested}
        >
          <Trans>Remove edge</Trans>
        </Menu.Item>
      )}

      <Modal
        {...modalProps}
        title={<Trans>Remove edge</Trans>}
        visible={state.isDialogOpen}
        footer={
          <>
            <Button action="cancel" onClick={handleDialogClose} />
            <Button action="delete" onClick={handleRemoveEdge}>
              <Trans>Remove edge</Trans>
            </Button>
          </>
        }
      >
        <S.EdgesList>
          <p>
            <Trans>
              Do you want to remove following edge and uncast the generic types of the related
              fields?
            </Trans>
          </p>
          <ul>
            <li>
              <b>
                <FontAwesomeIcon className="anticon" icon={['fas', 'location-slash']} />
                <EntityEdgeName edge={edge} />
              </b>
            </li>
          </ul>
        </S.EdgesList>
      </Modal>
    </>
  );
};

export default RemoveEdgeAction;
