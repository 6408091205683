import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Menu } from 'antd';
import { cloneDeep, omit } from 'lodash';
import { useDispatch } from 'react-redux';
import { useImmer } from 'use-immer';

import EntityFieldFormDialog from 'components/domain/components/domainDiagram/entityFieldFormDialog';
import { EntityFieldFormData } from 'components/domain/components/domainDiagram/types';
import { EntityNodeData, EntityNodeField } from 'services/api/domain/domainDiagram';
import {
  st_domainDiagram_setDialogOpen,
  st_domainDiagram_updateNodeData
} from 'services/store/domainDiagram/domainDiagram.actions';

type EditFieldActionProps = {
  nodeData: EntityNodeData;
  nodeId: string;
  data: EntityNodeField;
  closeNavigation: () => void;
};

type EditFieldActionState = {
  isDialogOpen: boolean;
};

const EditFieldAction: React.FC<EditFieldActionProps> = props => {
  const pr = omit(props, ['nodeId', 'nodeData', 'data', 'closeNavigation']);
  const { nodeData, nodeId, data, closeNavigation } = props;
  const dispatch = useDispatch();
  const [state, setState] = useImmer<EditFieldActionState>({
    isDialogOpen: false
  });

  const handleEditFieldRequest = () => {
    closeNavigation();
    dispatch(st_domainDiagram_setDialogOpen(true));
    setState(draft => {
      draft.isDialogOpen = true;
    });
  };

  const handleDialogClose = () => {
    dispatch(st_domainDiagram_setDialogOpen(false));
    setState(draft => {
      draft.isDialogOpen = false;
    });
  };

  const handleUpdateField = (formData: EntityFieldFormData | EntityFieldFormData[]) => {
    if (!Array.isArray(formData)) {
      const newData = cloneDeep(nodeData);
      newData.fields.forEach(_field => {
        const field = _field;

        if (field.id === data.id) {
          field.className = formData.className;
          field.name = formData.name;
          field.type = formData.type;
          field.uniqueId = formData.uniqueId;
          field.aggregationType = formData.aggregationType;
          field.relationType = formData.relationType;
        }
      });

      dispatch(st_domainDiagram_updateNodeData(newData, nodeId));
      handleDialogClose();
    }
  };

  return (
    <>
      <Menu.Item
        {...pr}
        key="insert-entity"
        icon={<FontAwesomeIcon icon={['fas', 'pen']} />}
        onClick={handleEditFieldRequest}
      >
        <Trans>Edit field</Trans>
      </Menu.Item>

      <EntityFieldFormDialog
        onClose={handleDialogClose}
        mode="EDIT"
        data={data}
        isOpen={state.isDialogOpen}
        nodeData={nodeData}
        insertData={handleUpdateField}
      />
    </>
  );
};

export default EditFieldAction;
