import React, { useEffect, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { useFormContext } from '@phoenix-systems/react-form';
import { Form } from 'antd';
import { clone } from 'lodash';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { useImmer } from 'use-immer';

import * as S from './queryDocumentsSingleSc';

import * as F from 'components/_styled/formSc';
import DocsTooltip from 'components/docs/components/docsTooltip';
import Button from 'components/ui/button';
import FormContainer from 'components/ui/formContainer';
import Loader from 'components/ui/loader';
import MonacoEditor from 'components/ui/monacoEditor';
import routes from 'config/routes/routes';
import useApiKey from 'hooks/useApiKey';
import useDomainName from 'hooks/useDomainName';
import useDomainRoute from 'hooks/useDomainRoute';
import useNotification from 'hooks/useNotification';
import useSchemaName from 'hooks/useSchemaName';
import { DocId } from 'services/api/domain/hint';
import {
  api_updateDomainObjects,
  UpdateDomainObjectsParams,
  useQueryDomainObjects
} from 'services/api/domain/object';
import {
  st_entityDocument_getEntity,
  st_entityDocument_getQuery
} from 'services/store/entityDocument/entityDocument.selectors';

type QueryDocumentsSingleState = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
  isInitialized: boolean;
};

const QueryDocumentsSingle: React.FC = () => {
  const domainName = useDomainName();
  const schema = useSchemaName();
  const { objectId } = useParams<{ objectId: string }>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [form] = Form.useForm<{ data: any }>();
  const [formState, formActions] = useFormContext(form);
  const [addNotification] = useNotification();
  const solrQuery = useSelector(st_entityDocument_getQuery);
  const currentType = useSelector(st_entityDocument_getEntity);
  const [state, setState] = useImmer<QueryDocumentsSingleState>({
    data: undefined,
    isInitialized: false
  });
  const apiKey = useApiKey();
  const getDomainRoute = useDomainRoute();

  const getSinglePayload = () => {
    if (solrQuery) {
      const q = clone(solrQuery);
      q.query = `id:${objectId}`;

      return q;
    }

    return undefined;
  };

  const objectQuery = useQueryDomainObjects(
    {
      domainName,
      schema,
      type: currentType || '',
      payload: getSinglePayload(),
      apiKey
    },
    {
      enabled: false,
      onSuccess: res => {
        if (res.docs[0]) {
          setState(draft => {
            // eslint-disable-next-line prefer-destructuring
            draft.data = res.docs[0];
          });
        }

        formActions.reset({ data: res.docs[0] }, true);
      }
    }
  );
  const { current: objectQueryRef } = useRef(objectQuery);

  const updateMutation = useMutation(
    (payload: UpdateDomainObjectsParams) => api_updateDomainObjects(payload),
    {
      onSuccess: () => {
        addNotification({
          type: 'success',
          message: i18n._(t`Successfully updated object "${state.data.id}".`)
        });

        updateMutation.reset();
        objectQuery.refetch();
      },
      onError: () => {
        addNotification({
          type: 'error',
          message: i18n._(t`Failed to updated object "${state.data.id}".`)
        });
      }
    }
  );

  const handleUpdate = () => {
    form.validateFields().then(formData => {
      if (currentType) {
        updateMutation.mutate({
          domainName,
          schema,
          type: currentType,
          payload: [formData.data],
          apiKey
        });
      }
    });
  };

  useEffect(() => {
    if (objectId && objectId !== '' && apiKey !== '') {
      objectQueryRef.refetch();
    }
    setState(draft => {
      draft.isInitialized = true;
    });
  }, [objectId, apiKey, objectQueryRef, setState]);

  if (!currentType || !solrQuery) {
    return <Redirect to={getDomainRoute(routes.domain.queryData.entityDocument.root)} />;
  }

  return (
    <FormContainer
      fullHeight
      maxWidth="unset"
      isStickyDisabled
      title={
        <>
          <FontAwesomeIcon icon={['fas', 'brackets-curly']} />
          <Trans>Edit object</Trans>
          <DocsTooltip docId={DocId.DOMAIN_CAPTURE_STRUCTURE} />
        </>
      }
      buttons={
        <>
          <Button
            action="reset"
            mutation={updateMutation}
            formState={formState}
            onClick={() => objectQuery.refetch()}
          />
          <Button
            action="save"
            mutation={updateMutation}
            formState={formState}
            onClick={handleUpdate}
          />
        </>
      }
    >
      <F.StyledForm
        form={form}
        onValuesChange={formActions.onValuesChange}
        onFieldsChange={formActions.onFieldsChange}
        layout="horizontal"
        name="edit-object-form"
        initialValues={{ data: state.data }}
        colon={false}
      >
        <S.ContainerInner>
          <div className="json-editor">
            {objectQuery.isLoading && <Loader fullHeight />}
            {objectQuery.isSuccess && (
              <S.JsonFormItem
                className="json-input"
                /*  label={
                <TooltipLabel
                  docId={DocId.DOMAIN_CAPTURE_STRUCUTRE_DATA}
                  label={<Trans>JSON data to capture</Trans>}
                />
              } */
                rules={[{ required: true }]}
                messageVariables={{ name: i18n._('JSON data to capture') }}
                name="data"
                registerField={formActions.registerField}
              >
                <MonacoEditor
                  height="100%"
                  mode="JSON"
                  showMiniMap={false}
                  defaultColorTheme="BRIGHT"
                />
              </S.JsonFormItem>
            )}
          </div>
        </S.ContainerInner>
      </F.StyledForm>
    </FormContainer>
  );
};

export default QueryDocumentsSingle;
