import React, { useCallback, useEffect, useState } from 'react';

import {
  ToolbarButtonsImage,
  useEditorState,
  useIsElementByIdActive
} from '@phoenix-systems/react-slate-editor';
import { CustomNode } from '@phoenix-systems/react-slate-editor/dist/types/types';
import { Image } from 'antd';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { RenderElementProps } from 'slate-react';

import * as S from './editorImageSc';

import { api_getFile } from 'services/api/domain/file/file.queries';
import { useHintApiInfo } from 'services/api/domain/hint';
import { st_docs_getEditorMode } from 'services/store/docs/docs.selectors';

type ImageElement = CustomNode & {
  data: {
    fileInfo: string;
    legend?: string;
    alt?: string;
  };
};

const EditorImage: React.FC<RenderElementProps> = ({ attributes, element, children }) => {
  const [imgUrl, setImgUrl] = useState<string>();
  const imageElement = element as ImageElement;
  const editorMode = useSelector(st_docs_getEditorMode);
  const { toolbarMode } = useEditorState();
  const isImageActive = useIsElementByIdActive(imageElement.uid);
  const hintInfoQuery = useHintApiInfo({ enabled: false });

  const downloadFile = useCallback(async () => {
    if (hintInfoQuery.data) {
      const blob = await api_getFile({
        domainName: hintInfoQuery.data.domainName,
        schema: hintInfoQuery.data.schemaName,
        fileId: imageElement.data.fileInfo,
        apiKey: hintInfoQuery.data.apiKey
      });

      const text = await blob.text();
      setImgUrl(`data:data:image/jpg;base64,${text}`);
    }
  }, [imageElement, hintInfoQuery.data]);

  useEffect(() => {
    downloadFile();
  }, [imageElement, downloadFile]);

  if (editorMode === 'read') {
    return (
      <div className="image wrapper" {...attributes}>
        {children}
        <Image className="image img" alt={imageElement.data.alt || 'image'} src={imgUrl} />
      </div>
    );
  }

  return (
    <div {...attributes} data-uid={imageElement.uid}>
      {children}
      <S.EditableImage
        isActive={isImageActive}
        contentEditable={false}
        style={{ position: 'relative' }}
      >
        <img className="image img" alt={imageElement.data.alt || 'image'} src={imgUrl} />
        {toolbarMode === 'static' && (
          <div className={clsx('overlay', isImageActive && 'active')}>
            <div>
              <ToolbarButtonsImage isImageContext />
            </div>
          </div>
        )}
        <p contentEditable={false} className="legend">
          {imageElement.data.legend}
        </p>
      </S.EditableImage>
    </div>
  );
};

export default EditorImage;
