import { AxiosError } from 'axios';
import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';

import {
  api_getMyself,
  api_getUserByName,
  api_getUsers,
  api_getUsersByOrgUnit
} from './user.queries';
import { GetUserByNameParams, GetUserByOrgUnitParams, User } from './user.types';

export const useUsers = (
  options?: UseQueryOptions<User[], AxiosError, User[]>
): QueryObserverResult<User[], AxiosError> =>
  useQuery<User[], AxiosError>(['users'], () => api_getUsers(), options);

export const useUsersByOrgUnit = (
  params: GetUserByOrgUnitParams,
  options?: UseQueryOptions<User[], AxiosError, User[]>
): QueryObserverResult<User[], AxiosError> =>
  useQuery<User[], AxiosError>(['usersByOrgUnit'], () => api_getUsersByOrgUnit(params), options);

export const useMyself = (
  options?: UseQueryOptions<User, AxiosError, User>
): QueryObserverResult<User, AxiosError> =>
  useQuery<User, AxiosError>(['myself'], () => api_getMyself(), options);

export const useUserByName = (
  params: GetUserByNameParams,
  options?: UseQueryOptions<User, AxiosError, User>
): QueryObserverResult<User, AxiosError> =>
  useQuery<User, AxiosError>(['userByName'], () => api_getUserByName(params), options);
