import React from 'react';

import * as T from 'components/_styled/tableSc';
import DbImportLogs from 'components/domain/components/dbImportTable/dbImportLogs';
import { DbImport } from 'services/api/domain/dataImport';

type DbImportTableLogsProps = {
  data: DbImport;
};

const DbImportTableLogs: React.FC<DbImportTableLogsProps> = ({ data }) => {
  if (!data) {
    return null;
  }

  return (
    <T.SingleContentContainer fullHeight>
      <DbImportLogs data={data} />
    </T.SingleContentContainer>
  );
};

export default DbImportTableLogs;
