import React from 'react';

import { Trans } from '@lingui/macro';
import { Scrollable } from '@phoenix-systems/react-layout';
import { getRoute } from '@phoenix-systems/react-router';
import { Col, Row } from 'antd';

import * as S from '../ftpConnectionsTableSc';

import * as T from 'components/_styled/tableSc';
import BooleanIcon from 'components/ui/booleanIcon';
import DataItem from 'components/ui/dataItem';
import PasswordInfo from 'components/ui/passwordInfo';
import routes from 'config/routes/routes';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';
import { FTPConnection } from 'services/api/domain/ftpConnection';

type FtpConnectionsTableOverviewProps = {
  data?: FTPConnection;
};

const FtpConnectionsTableOverview: React.FC<FtpConnectionsTableOverviewProps> = ({ data }) => {
  const triggerRoute = getRoute(routes.domain.overview, data?.name || '');
  const goto = useGoToDomainRoute();

  const handleEditFtpConnection = () => {
    if (data?.name) {
      goto(routes.domain.connections.ftp.single, { path: data.name });
    }
  };

  if (!data) {
    return null;
  }

  return (
    <Scrollable scrollY>
      <T.SingleContentContainer>
        <Row gutter={[24, 24]}>
          <Col xs={12}>
            <ul>
              <li>
                <DataItem name={<Trans>Id</Trans>} value={data.id} hasCopy breakWord />
              </li>
              <li>
                <DataItem
                  name={<Trans>Name</Trans>}
                  value={<S.DomainLink to={triggerRoute}>{data.name}</S.DomainLink>}
                  hasCopy
                  copyValue={data.name}
                  onEdit={handleEditFtpConnection}
                />
              </li>
              <li>
                <DataItem
                  name={<Trans>Remote host</Trans>}
                  value={data.remoteHost}
                  hasCopy
                  breakWord
                />
              </li>
              <li>
                <DataItem
                  name={<Trans>Remote directory</Trans>}
                  value={data.remoteDir}
                  hasCopy
                  breakWord
                />
              </li>
            </ul>
          </Col>
          <Col xs={12}>
            <ul>
              <li>
                <DataItem name={<Trans>SFTP</Trans>} value={<BooleanIcon value={data.sftp} />} />
              </li>
              <li>
                <DataItem name={<Trans>User name</Trans>} value={data.username} hasCopy />
              </li>
              <li>
                <DataItem
                  name={<Trans>Password</Trans>}
                  value={<PasswordInfo value={data.password} />}
                  hasCopy
                />
              </li>
            </ul>
          </Col>
        </Row>
      </T.SingleContentContainer>
    </Scrollable>
  );
};

export default FtpConnectionsTableOverview;
