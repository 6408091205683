import tw, { styled, css } from 'twin.macro';

export const NotificationContainer = styled.div(() => [
  css`
    & .ant-notification {
      ${tw`mr-0`};
    }

    & .ant-notification-notice {
      ${tw`py-12 px-18`};
      width: 36rem;
    }

    & .ant-notification-notice-message {
      ${tw`font-700 text-13 leading-tight m-0 pr-0 w-full`};
      width: 26.5rem;
    }

    & .ant-notification-notice-icon {
      ${tw`text-18 m-0 block relative mr-12 h-28`};
    }

    & .ant-notification-notice-close {
      top: 1.2rem;
    }

    & .ant-notification-notice-icon-info {
      ${tw`text-blue`};
    }

    & .ant-notification-notice-message-single-line-auto-margin {
      ${tw`w-auto`};
    }

    & .ant-notification-notice-with-icon {
      ${tw`flex flex-row items-center`};
    }

    & .ant-notification-notice-description {
      ${tw`hidden`};
    }
  `
]);
