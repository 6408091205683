import { ListQueryParams } from '@phoenix-systems/react-table/dist/types/types';

import { SolrQueryObject } from 'services/api/domain/solrQuery';

export enum EntityDocumentActionTypes {
  SET_LAYOUT = 'entityDocument/SET_LAYOUT',
  SET_ENTITY = 'entityDocument/SET_ENTITY',
  SET_QUERY = 'entityDocument/SET_QUERY',
  SET_QUERY_MODE = 'entityDocument/SET_QUERY_MODE',
  SET_RESULT_MODE = 'entityDocument/SET_RESULT_MODE',
  SET_QUERY_COLLAPSED = 'entityDocument/SET_QUERY_COLLAPSED',
  SET_SELECTED_KEY = 'entityDocument/SET_SELECTED_KEY',
  SET_LIST_PARAMS = 'entityDocument/SET_LIST_PARAMS',
  RESET = 'entityDocument/RESET'
}

export type QueryDocumentsLayout = 'VERTICAL' | 'HORIZONTAL';

export type QueryDocumentsQueryMode = 'JSON' | 'FORM';

export type QueryDocumentsResultMode = 'JSON' | 'LIST';

export type EntityDocumentState = Readonly<{
  layout: QueryDocumentsLayout;
  queryMode: QueryDocumentsQueryMode;
  query?: SolrQueryObject;
  entity?: string;
  resultMode: QueryDocumentsResultMode;
  queryCollapsed: boolean;
  selectedKey?: string;
  listParams: ListQueryParams;
}>;
