const EMPTY_META_DATA = {
  application: 'TEST',
  childFieldList: {},
  composites: [],
  domain: 'DBImportTEST',
  domainIdMetaField: {
    additionalSolrTypes: [],
    composite: false,
    detached: false,
    domainId: true,
    embeddedId: false,
    exclude: false,
    excludeEmpty: false,
    fieldName: 'id',
    listRelation: false,
    relationType: 'PRIMITIVE',
    solrType: 'string_single',
    typeFullName: 'java.lang.String',
    typeSimpleName: 'String',
    uniqueId: false,
    updatable: true
  },
  embeddedIds: [],
  excludeEmpty: false,
  fullClassName: 'dbimporttest.test.<SimpleClassName>',
  historyContext: true,
  isIntegrity: true,
  notUpdatableMetaFields: [],
  objectFieldList: {},
  primitiveFieldList: {
    id: {
      additionalSolrTypes: [],
      composite: false,
      detached: false,
      domainId: true,
      embeddedId: false,
      exclude: false,
      excludeEmpty: false,
      fieldName: 'id',
      listRelation: false,
      relationType: 'PRIMITIVE',
      solrType: 'string_single',
      typeFullName: 'java.lang.String',
      typeSimpleName: 'String',
      uniqueId: false,
      updatable: true
    }
  },
  simpleClassName: '<SimpleClassName>',
  uniqueIds: []
};

export default EMPTY_META_DATA;
