import { i18n } from '@lingui/core';

const fileSelectorLocales = {
  'Click or drag file to this area to upload.': i18n._(
    'Click or drag file to this area to upload.'
  ),
  'Allowed file types': i18n._('Allowed file types'),
  'Name': i18n._('Name'),
  'Size': i18n._('Size'),
  'Type': i18n._('Type'),
  'Date': i18n._('Date')
};

export default fileSelectorLocales;
