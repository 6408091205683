import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { getRoute } from '@phoenix-systems/react-router';
import { Col, Row } from 'antd';

import * as S from './domainsTableSc';

import * as T from 'components/_styled/tableSc';
import DataItem from 'components/ui/dataItem';
import routes from 'config/routes/routes';
import { Domain } from 'services/api/domain/domain';
import history from 'services/history';

type DomainsSingleOverviewProps = {
  data?: Domain;
};

const DomainsSingleOverview: React.FC<DomainsSingleOverviewProps> = ({ data }) => {
  const domainRoute = getRoute(routes.domain.overview, data?.domainName || '');

  const handleEditDomain = () => {
    history.push(domainRoute);
  };

  if (!data) {
    return null;
  }

  return (
    <T.SingleContentContainer>
      <Row gutter={[12, 12]}>
        <Col xs={24} md={12}>
          <ul>
            <li>
              <DataItem
                name={<Trans>Domain Name</Trans>}
                value={<S.DomainLink to={domainRoute}>{data.domainName}</S.DomainLink>}
                hasCopy
                copyValue={data.domainName}
                onEdit={handleEditDomain}
              />
            </li>
            <li>
              <DataItem name={<Trans>Id</Trans>} value={data.id} hasCopy breakWord />
            </li>
            <li>
              <DataItem name={<Trans>Api Key</Trans>} value={data.apiKey} hasCopy breakWord />
            </li>
            {data.description && data.description !== '' && (
              <li>
                <DataItem name={<Trans>Description</Trans>} value={data.description} />
              </li>
            )}
            {data.uri && data.uri !== '' && (
              <li>
                <DataItem name={<Trans>Uri</Trans>} value={data.uri} hasCopy />
              </li>
            )}

            <li>
              <DataItem
                name={<Trans>Deletable</Trans>}
                value={
                  data.deletable === true ? (
                    <FontAwesomeIcon icon={['fas', 'check']} />
                  ) : (
                    <FontAwesomeIcon icon={['fas', 'times']} />
                  )
                }
              />
            </li>
          </ul>
        </Col>
        <Col xs={24} md={12}>
          <ul>
            <li>
              <DataItem name={<Trans>Default Schema</Trans>} value={data.defaultSchema} />
            </li>
            <li>
              <DataItem name={<Trans>Schemes</Trans>} value={data.schemes?.join(', ')} />
            </li>
            {data.shards !== undefined && data.shards.length > 0 && (
              <li>
                <DataItem
                  name={<Trans>Shards</Trans>}
                  value={
                    <>
                      {data.shards?.map((shard, index) => (
                        <>
                          {shard.name}
                          {index < data.shards.length - 1 && ', '}
                        </>
                      ))}
                    </>
                  }
                />
              </li>
            )}
          </ul>
        </Col>
      </Row>
    </T.SingleContentContainer>
  );
};

export default DomainsSingleOverview;
