import React, { useEffect, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { FormItem, useFormContext } from '@phoenix-systems/react-form';
import { usePageState } from '@phoenix-systems/react-layout';
import { Form, Input, InputNumber, Popover, Radio, RadioChangeEvent } from 'antd';
import { AxiosError } from 'axios';
import { assign } from 'lodash';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useImmer } from 'use-immer';

import EntityFieldSelector from '../entityFieldSelector';
import QueryDocumentsResultList from '../queryDocumentsResultList/queryDocumentsResultList';

import * as S from './queryDocumentsSc';

import * as F from 'components/_styled/formSc';
import useContainer from 'components/app/components/containerProvider/useContainer';
import TooltipLabel from 'components/docs/components/docsTooltip/tooltipLabel';
import Button from 'components/ui/button';
import ErrorInfo from 'components/ui/errorInfo';
import Loader from 'components/ui/loader';
import MonacoEditor from 'components/ui/monacoEditor';
import NoContent from 'components/ui/noContent';
import routes from 'config/routes/routes';
import useApiKey from 'hooks/useApiKey';
import useDomainName from 'hooks/useDomainName';
import useEntityName from 'hooks/useEntityName';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';
import useSchemaName from 'hooks/useSchemaName';
import { DocId } from 'services/api/domain/hint';
import {
  api_queryDomainObjects,
  DomainObjectResult,
  QueryDomainObjectsParms
} from 'services/api/domain/object';
import { useSolrQueryObject } from 'services/api/domain/solrQuery';
import history from 'services/history';
import {
  st_entityDocument_setEntity,
  st_entityDocument_setLayout,
  st_entityDocument_setQuery,
  st_entityDocument_setQueryCollapsed,
  st_entityDocument_setQueryMode,
  st_entityDocument_setResultMode
} from 'services/store/entityDocument/entityDocument.actions';
import {
  st_entityDocument_getEntity,
  st_entityDocument_getLayout,
  st_entityDocument_getQuery,
  st_entityDocument_getQueryCollapsed,
  st_entityDocument_getQueryMode,
  st_entityDocument_getResultMode
} from 'services/store/entityDocument/entityDocument.selectors';

type QueryDocumentsState = {
  isInitialized: boolean;
  startTime: number;
  duration: number;
};

const QueryDocuments: React.FC = () => {
  const entityName = useEntityName();
  const domainName = useDomainName();
  const [selectEntityForm] = Form.useForm();
  const [queryForm] = Form.useForm();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [queryFormState, queryFormActions] = useFormContext(queryForm);
  const { current: selectEntityFormRef } = useRef(selectEntityForm);
  const schema = useSchemaName();
  const { formContainer } = useContainer();
  const dispatch = useDispatch();
  const layout = useSelector(st_entityDocument_getLayout);
  const currentType = useSelector(st_entityDocument_getEntity);
  const queryMode = useSelector(st_entityDocument_getQueryMode);
  const resultMode = useSelector(st_entityDocument_getResultMode);
  const isQueryCollapsed = useSelector(st_entityDocument_getQueryCollapsed);
  const query = useSelector(st_entityDocument_getQuery);
  const { containerMeasures } = usePageState();
  const [state, setState] = useImmer<QueryDocumentsState>({
    isInitialized: false,
    startTime: new Date().getTime(),
    duration: 0
  });
  const apiKey = useApiKey();
  const isTypeRouteMatching = useRouteMatch(routes.domain.queryData.entityDocument.rootEntity);
  const goto = useGoToDomainRoute();

  const objectQuery = useMutation<DomainObjectResult, AxiosError, QueryDomainObjectsParms>(
    payload => api_queryDomainObjects(payload),
    {
      onSuccess: () => {
        const duration = state.startTime - new Date().getTime();
        setState(draft => {
          draft.duration = duration;
        });

        if (history.location.search && history.location.search !== '') {
          history.replace(history.location.pathname.split('?')[0]);
        }
      },
      onError: () => {
        /* addNotification({
          type: 'error',
          message: i18n._(t`Failed to updated doc "${data?.title}".`)
        }); */
      }
    }
  );

  const { current: objectQueryRef } = useRef(objectQuery);

  const solrQueryRequest = useSolrQueryObject(
    {
      domainName,
      schema,
      type: entityName || currentType || '',
      subQueries: 'true'
    },
    {
      onSuccess: data => {
        dispatch(st_entityDocument_setQuery(data));
        queryFormActions.reset(data);
      },
      onError: () => {
        dispatch(st_entityDocument_setQuery(undefined));
      },
      enabled: entityName !== undefined,
      refetchOnWindowFocus: false
    }
  );
  const { current: solrQueryRequestRef } = useRef(solrQueryRequest);

  const handleTypeChange = (type: string) => {
    objectQuery.reset();
    dispatch(st_entityDocument_setEntity(type));
  };

  const handleLayoutChange = (event: RadioChangeEvent) => {
    dispatch(st_entityDocument_setLayout(event.target.value));
  };

  const handleSendRequest = () => {
    if (currentType) {
      objectQuery.mutate({
        domainName,
        schema,
        payload: query,
        type: currentType,
        apiKey
      });
      setState(draft => {
        draft.startTime = new Date().getTime();
      });
    }
  };

  const handleGotoList = () => {
    goto(routes.domain.queryData.entityDocument.list);
  };

  const handleQueryModeChange = (event: RadioChangeEvent) => {
    dispatch(st_entityDocument_setQueryMode(event.target.value));

    if (event.target.value === 'FORM') {
      queryFormActions.reset(query);
    }
  };

  const handleResultModeChange = (event: RadioChangeEvent) => {
    dispatch(st_entityDocument_setResultMode(event.target.value));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleQueryValuesChange = (changedValues: any, values: unknown) => {
    const q = assign({}, query, values);
    objectQuery.reset();
    dispatch(st_entityDocument_setQuery(q));
    queryFormActions.onValuesChange(changedValues, values);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleQueryJsonChange = (data: any) => {
    objectQuery.reset();
    dispatch(st_entityDocument_setQuery(data));
  };

  const handleCollapseQuery = () => {
    dispatch(st_entityDocument_setQueryCollapsed(!isQueryCollapsed));
  };

  const handleReloadObjects = () => {
    if (currentType) {
      objectQuery.mutate({
        domainName,
        schema,
        payload: query,
        type: currentType,
        apiKey
      });
    }
  };

  useEffect(() => {
    if (!isTypeRouteMatching && currentType) {
      goto(routes.domain.queryData.entityDocument.rootEntity, { path: currentType }, true);
    }
  }, [currentType, isTypeRouteMatching, goto]);

  useEffect(() => {
    if (entityName !== undefined && entityName !== '') {
      selectEntityFormRef.setFieldsValue({ type: entityName });
      dispatch(st_entityDocument_setEntity(entityName));
    }
  }, [entityName, selectEntityFormRef, dispatch]);

  useEffect(() => {
    if (currentType && state.isInitialized) {
      solrQueryRequestRef.refetch();
    }
  }, [currentType, solrQueryRequestRef, state.isInitialized]);

  useEffect(() => {
    if (!state.isInitialized) {
      if (currentType) {
        selectEntityFormRef.setFieldsValue({ type: currentType });
      }
    }
  }, [state.isInitialized, selectEntityFormRef, currentType]);

  useEffect(() => {
    if (
      currentType &&
      currentType !== '' &&
      query &&
      !entityName &&
      !state.isInitialized &&
      apiKey !== ''
    ) {
      queryFormActions.reset({ type: currentType, data: query });

      objectQueryRef.mutate({ type: currentType, payload: query, domainName, schema, apiKey });
    }

    setState(draft => {
      draft.isInitialized = true;
      draft.startTime = new Date().getTime();
    });
  }, [
    currentType,
    objectQueryRef,
    state.isInitialized,
    domainName,
    entityName,
    query,
    queryFormActions,
    schema,
    apiKey,
    setState
  ]);

  return (
    <S.Container>
      <S.SelectEntityWrapper>
        <S.EntitySelector>
          <F.StyledForm
            form={selectEntityForm}
            layout="horizontal"
            validateTrigger="onChange"
            $labelWidth={80}
            name="select-entity-form"
            initialValues={{ type: currentType }}
            colon={false}
          >
            <FormItem
              label={
                <TooltipLabel
                  docId={DocId.DOMAIN_QUERY_ENTITY_SELECT_ENTITY}
                  label={<Trans>Entity</Trans>}
                />
              }
              messageVariables={{ name: i18n._('Entity') }}
              name="type"
            >
              <EntityFieldSelector
                treeDefaultExpandAll
                getPopupContainer={() => formContainer}
                onChangePrepareValue={nodeData => nodeData?.value}
                onChange={handleTypeChange}
              />
            </FormItem>
          </F.StyledForm>
        </S.EntitySelector>

        <S.LayoutSwitch>
          {containerMeasures.width > 1000 && (
            <>
              <FontAwesomeIcon icon={['far', 'columns']} />
              <span className="label">Layout:</span>
            </>
          )}

          <Radio.Group onChange={handleLayoutChange} value={layout}>
            <Radio value="VERTICAL">
              <Trans>vertical</Trans>
            </Radio>
            <Radio value="HORIZONTAL">
              <Trans>horizontal</Trans>
            </Radio>
          </Radio.Group>
        </S.LayoutSwitch>
      </S.SelectEntityWrapper>

      <S.StyledDivider />
      {!query && <NoContent />}
      {query && (
        <S.QueryContainer layout={layout}>
          <S.QueryContainerInner layout={layout} className="query" isCollapsed={isQueryCollapsed}>
            <S.Header>
              <S.StyledLabel className="query-label">
                <TooltipLabel
                  docId={DocId.DOMAIN_QUERY_ENTITY_QUERY}
                  label={<Trans>Query</Trans>}
                />
                {currentType && containerMeasures.width > 1100 && (
                  <S.QueryInfo>{currentType}</S.QueryInfo>
                )}
              </S.StyledLabel>
              <S.LayoutSwitch className="switch">
                {containerMeasures.width > 1000 && (
                  <>
                    <FontAwesomeIcon icon={['far', 'eye']} />
                    <span className="label">Query as:</span>
                  </>
                )}
                <Radio.Group onChange={handleQueryModeChange} value={queryMode}>
                  <Radio value="JSON">
                    <Trans>JSON</Trans>
                  </Radio>
                  <Radio value="FORM">
                    <Trans>Form</Trans>
                  </Radio>
                </Radio.Group>
              </S.LayoutSwitch>
              <S.HeaderButton
                className="collapse"
                onClick={
                  layout === 'HORIZONTAL' && isQueryCollapsed
                    ? () => dispatch(st_entityDocument_setQueryCollapsed(false))
                    : undefined
                }
              >
                <Button
                  type="link"
                  title={
                    !isQueryCollapsed
                      ? i18n._('Collapse query input')
                      : i18n._('Expand query input')
                  }
                  icon={
                    layout === 'VERTICAL'
                      ? ['far', !isQueryCollapsed ? 'arrow-from-right' : 'arrow-from-left']
                      : ['far', !isQueryCollapsed ? 'arrow-from-bottom' : 'arrow-from-top']
                  }
                  onClick={handleCollapseQuery}
                />
              </S.HeaderButton>
            </S.Header>

            {solrQueryRequest.isFetching ? (
              <Loader fullHeight />
            ) : (
              <>
                {queryMode === 'JSON' && (
                  <MonacoEditor
                    showMiniMap={layout === 'HORIZONTAL'}
                    loading={solrQueryRequest.isFetching}
                    value={query}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onChange={handleQueryJsonChange}
                    mode="JSON"
                    className="content"
                  />
                )}
                {queryMode === 'FORM' && (
                  <S.QueryForm
                    className="content"
                    onClick={
                      isQueryCollapsed
                        ? () => dispatch(st_entityDocument_setQueryCollapsed(false))
                        : undefined
                    }
                  >
                    <F.StyledForm
                      form={queryForm}
                      onFieldsChange={queryFormActions.onFieldsChange}
                      onValuesChange={handleQueryValuesChange}
                      name="query-form"
                      $labelWidth={81}
                      initialValues={query}
                      colon={false}
                    >
                      <FormItem
                        label={
                          <TooltipLabel
                            docId={DocId.DOMAIN_JSON_IMPEX_IMPORT}
                            label={<Trans>Rows</Trans>}
                          />
                        }
                        messageVariables={{ name: i18n._(t`Rows`) }}
                        name="rows"
                        rules={[{ required: true }]}
                        registerField={queryFormActions.registerField}
                      >
                        <InputNumber step={1} />
                      </FormItem>
                      <FormItem
                        label={
                          <TooltipLabel
                            docId={DocId.DOMAIN_JSON_IMPEX_IMPORT}
                            label={<Trans>Start</Trans>}
                          />
                        }
                        messageVariables={{ name: i18n._(t`Start`) }}
                        name="start"
                        rules={[{ required: true }]}
                        registerField={queryFormActions.registerField}
                      >
                        <InputNumber step={1} />
                      </FormItem>
                      <FormItem
                        label={
                          <TooltipLabel
                            docId={DocId.DOMAIN_JSON_IMPEX_IMPORT}
                            label={<Trans>Sort</Trans>}
                          />
                        }
                        messageVariables={{ name: i18n._(t`Sort`) }}
                        name="sort"
                        rules={[{ required: true }]}
                        registerField={queryFormActions.registerField}
                      >
                        <Input.TextArea rows={3} />
                      </FormItem>
                      <FormItem
                        label={
                          <TooltipLabel
                            docId={DocId.DOMAIN_JSON_IMPEX_IMPORT}
                            label={<Trans>Filters</Trans>}
                          />
                        }
                        messageVariables={{ name: i18n._(t`Filters`) }}
                        name="filters"
                        rules={[{ required: true }]}
                        registerField={queryFormActions.registerField}
                      >
                        <Input.TextArea rows={3} />
                      </FormItem>
                      <FormItem
                        label={
                          <TooltipLabel
                            docId={DocId.DOMAIN_JSON_IMPEX_IMPORT}
                            label={<Trans>Query</Trans>}
                          />
                        }
                        messageVariables={{ name: i18n._(t`Query`) }}
                        name="query"
                        rules={[{ required: true }]}
                        registerField={queryFormActions.registerField}
                      >
                        <Input.TextArea rows={3} />
                      </FormItem>
                    </F.StyledForm>
                  </S.QueryForm>
                )}
              </>
            )}
          </S.QueryContainerInner>

          <S.QueryContainerInner layout={layout} className="query-btn">
            <div>
              <Button
                type="primary"
                title={i18n._('Execute Query')}
                icon={['fas', 'sync']}
                onClick={handleSendRequest}
                disabled={!query || solrQueryRequest.isLoading}
              />
            </div>
          </S.QueryContainerInner>

          <S.QueryContainerInner
            layout={layout}
            className="response"
            isCollapsed={isQueryCollapsed}
          >
            <S.Header>
              <S.StyledLabel>
                <TooltipLabel
                  docId={DocId.DOMAIN_QUERY_ENTITY_RESULT}
                  label={<Trans>Response</Trans>}
                />
                {objectQuery.isSuccess && objectQuery.data && containerMeasures.width > 1000 && (
                  <S.QueryInfo>
                    <Popover
                      trigger="hover"
                      content={
                        <S.ResultInfo>
                          <p>
                            <span>start:</span>
                            {objectQuery.data.start}
                          </p>
                          <p>
                            <span>documents count:</span>
                            {objectQuery.data.docs.length}
                          </p>
                          <p>
                            <span>total count:</span>
                            {objectQuery.data.numFound}
                          </p>
                        </S.ResultInfo>
                      }
                    >
                      <S.ResultInfoBtn icon={<FontAwesomeIcon icon={['fas', 'info']} />}>
                        {objectQuery.data.docs.length}
                      </S.ResultInfoBtn>
                    </Popover>
                  </S.QueryInfo>
                )}
              </S.StyledLabel>
              {objectQuery.isSuccess && (
                <>
                  <S.LayoutSwitch>
                    {containerMeasures.width > 1000 && (
                      <>
                        <FontAwesomeIcon icon={['far', 'eye']} />
                        <span className="label">Result as:</span>
                      </>
                    )}
                    <Radio.Group onChange={handleResultModeChange} value={resultMode}>
                      <Radio value="JSON">
                        <Trans>JSON</Trans>
                      </Radio>
                      <Radio value="LIST">
                        <Trans>List</Trans>
                      </Radio>
                    </Radio.Group>
                  </S.LayoutSwitch>
                  <S.HeaderButton isList>
                    <Button
                      action="list"
                      type="link"
                      title={i18n._('Open list')}
                      onClick={handleGotoList}
                    >
                      <Trans>Open list</Trans>
                    </Button>
                  </S.HeaderButton>
                </>
              )}
            </S.Header>

            {objectQuery.isLoading && <Loader fullHeight />}
            {objectQuery.isError && (
              <ErrorInfo
                error={objectQuery.error}
                message={<Trans>Failed to fetch objects.</Trans>}
              />
            )}
            {objectQuery.isSuccess && (
              <>
                {resultMode === 'JSON' && (
                  <div className="json-editor">
                    <MonacoEditor
                      showMiniMap={layout === 'HORIZONTAL'}
                      loading={objectQuery.isLoading}
                      value={objectQuery.data}
                      readOnly
                      mode="JSON"
                    />
                  </div>
                )}
                {resultMode === 'LIST' && (
                  <S.ListWrapper>
                    <div>
                      <QueryDocumentsResultList
                        mode="EMBEDDED"
                        result={objectQuery.data?.docs}
                        refetchResults={handleReloadObjects}
                      />
                    </div>
                  </S.ListWrapper>
                )}
              </>
            )}
          </S.QueryContainerInner>
        </S.QueryContainer>
      )}
    </S.Container>
  );
};

export default QueryDocuments;
