import { ListQueryParams } from '@phoenix-systems/react-table/dist/types/types';

import { DomainsTableActionTypes, DomainsTableSingleView } from './domainsTable.types';

/* Action Definition */

type SetParamsAction = {
  type: DomainsTableActionTypes.SET_PARAMS;
  params?: Partial<ListQueryParams>;
};

type SetSingleViewAction = {
  type: DomainsTableActionTypes.SET_SINGLE_VIEW;
  view: DomainsTableSingleView;
};

type ResetAction = {
  type: DomainsTableActionTypes.RESET;
};

/* Union Action Types */

export type DomainsTableActionCreators = SetParamsAction | SetSingleViewAction | ResetAction;

/* Action Creators */

export const st_domainsTable_setParams = (params?: Partial<ListQueryParams>): SetParamsAction => ({
  type: DomainsTableActionTypes.SET_PARAMS,
  params
});

export const st_domainsTable_setSingleView = (
  view: DomainsTableSingleView
): SetSingleViewAction => ({
  type: DomainsTableActionTypes.SET_SINGLE_VIEW,
  view
});

export const st_domainsTable_reset = (): ResetAction => ({
  type: DomainsTableActionTypes.RESET
});
