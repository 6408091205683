import { GetTriggersParams, Trigger } from './trigger.types';

import apiClient from 'services/api/apiClient';

const BASE_PATH = 'trigger';

/**
 * GET /api/trigger
 */
export const api_getTriggerById = async (id: string): Promise<Trigger> => {
  const { data } = await apiClient.get(`/${BASE_PATH}/id/${id}`);

  return data;
};

/**
 * GET /api/trigger/name/${name}
 */
export const api_getTriggerByName = async (name: string): Promise<Trigger> => {
  const { data } = await apiClient.get(`/${BASE_PATH}/name/${name}`);

  return data;
};

/**
 * GET /api/trigger
 * GET /api/trigger/domain/{domain}
 */
export const api_getTriggers = async (params: GetTriggersParams): Promise<Trigger[]> => {
  if (!params.domainName) {
    const { data } = await apiClient.get(`/${BASE_PATH}`);

    return data;
  }

  const { data } = await apiClient.get(`/${BASE_PATH}/domain/${params.domainName}`);

  return data;
};

/**
 * DELETE /api/trigger/{id}
 */
export const api_deleteTriggerById = async (id: string): Promise<Trigger> => {
  const { data } = await apiClient.delete(`/${BASE_PATH}/${id}`);

  return data;
};

/**
 * DELETE /api/trigger/by/{name}
 */
export const api_deleteTriggerByName = async (name: string): Promise<Trigger> => {
  const { data } = await apiClient.delete(`/${BASE_PATH}/by/${name}`);

  return data;
};

/**
 * PUT /api/trigger
 */
export const api_updateTrigger = async (payload: Trigger): Promise<Trigger> => {
  const { data } = await apiClient.put(`/${BASE_PATH}`, payload);

  return data;
};

/**
 * POST /api/trigger
 */
export const api_createTrigger = async (payload: Partial<Trigger>): Promise<Trigger> => {
  const { data } = await apiClient.post(`/${BASE_PATH}`, payload);

  return data;
};
