import React from 'react';

import { Trans } from '@lingui/macro';

import * as S from './triggerHeaderButtonsSc';

import TriggerActions from 'components/trigger/actions';
import Button from 'components/ui/button';
import routes from 'config/routes/routes';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';
import useIsDomain from 'hooks/useIsDomain';
import { Trigger } from 'services/api/domain/trigger';
import history from 'services/history';

type TriggerHeaderButtonsProps = {
  hasCalendar?: boolean;
  hasList?: boolean;
  hasCreate?: boolean;
  hasActions?: boolean;
  data?: Trigger;
};

const TriggerHeaderButtons: React.FC<TriggerHeaderButtonsProps> = ({
  hasCalendar,
  hasCreate,
  hasList,
  hasActions,
  data
}) => {
  const isDomain = useIsDomain();
  const goto = useGoToDomainRoute();

  return (
    <S.Buttons>
      {hasActions && data && (
        <TriggerActions popoverPlacement="rightTop" mode="BUTTON" data={data} />
      )}
      {hasList && (
        <Button
          type="ghost"
          icon={['fas', 'list']}
          onClick={() => {
            if (isDomain) {
              goto(routes.domain.scheduler.list);
            } else {
              history.push(routes.scheduler.list);
            }
          }}
        >
          <Trans>Go to list</Trans>
        </Button>
      )}
      {hasCalendar && (
        <Button
          type="ghost"
          icon={['fas', 'calendar-alt']}
          onClick={() => {
            if (isDomain) {
              goto(routes.domain.scheduler.calendar);
            } else {
              history.push(routes.scheduler.calendar);
            }
          }}
        >
          <Trans>Go to calendar</Trans>
        </Button>
      )}
      {hasCreate && (
        <Button
          action="create"
          type="primary"
          onClick={() => {
            if (isDomain) {
              goto(routes.domain.scheduler.create);
            } else {
              history.push(routes.scheduler.create);
            }
          }}
        >
          <Trans>Create trigger</Trans>
        </Button>
      )}
    </S.Buttons>
  );
};

export default TriggerHeaderButtons;
