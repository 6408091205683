import React, { useEffect, useRef } from 'react';

import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import { Select } from 'antd';
import { SelectValue } from 'antd/lib/select';
import clsx from 'clsx';
import { CSSProperties } from 'styled-components';
import { useImmer } from 'use-immer';

import FtpConnectionForm from '../ftpConnectionForm/ftpConnectionForm';

import * as S from './ftpConnectionSelectorSc';

import FtpConnectionActions from 'components/domain/actions/ftpConnectionActions';
import Button from 'components/ui/button';
import ErrorInfo from 'components/ui/errorInfo';
import useDomainName from 'hooks/useDomainName';
import useNotFound from 'hooks/useNotFound';
import useSchemaName from 'hooks/useSchemaName';
import { FTPConnection, useFTPConnections } from 'services/api/domain/ftpConnection';

const { Option } = Select;

type FtpConnectionSelectorProps = {
  onChange?: (connection?: FTPConnection) => void;
  value?: FTPConnection;
  style?: CSSProperties;
  className?: string;
};

type FtpConnectionSelectorState = {
  isDialogOpen: boolean;
  selectedItem?: FTPConnection;
  newConnectionName?: string;
};

const FtpConnectionSelector: React.FC<FtpConnectionSelectorProps> = ({
  value,
  onChange,
  style,
  className
}) => {
  const domainName = useDomainName();
  const schemaName = useSchemaName();
  const [state, setState] = useImmer<FtpConnectionSelectorState>({
    isDialogOpen: false,
    selectedItem: undefined,
    newConnectionName: undefined
  });

  const connectionsQuery = useFTPConnections(
    { domainName, schemaName },
    {
      retry: false,
      onSuccess: res => {
        if (state.newConnectionName) {
          const conn = res.find(c => c.name === state.newConnectionName);
          setState(draft => {
            draft.selectedItem = conn;
          });

          return;
        }

        if (value) {
          const conn = res.find(c => c.name === value.name);

          setState(draft => {
            draft.selectedItem = conn;
          });
        }
      }
    }
  );
  const { current: connectionsQueryRef } = useRef(connectionsQuery);
  const isNoConnectionsFound = useNotFound(connectionsQuery);

  const handleChange = (selectValue: SelectValue) => {
    if (!selectValue) {
      if (onChange) {
        onChange(undefined);
      }

      setState(draft => {
        draft.selectedItem = undefined;
      });

      return;
    }

    const conn = connectionsQuery.data?.find(c => c.id === selectValue);

    if (onChange) {
      onChange(conn);
    }

    setState(draft => {
      draft.selectedItem = conn;
    });
  };

  const handleCreateRequest = () => {
    setState(draft => {
      draft.isDialogOpen = true;
      draft.newConnectionName = undefined;
    });
  };

  useEffect(() => {
    if (state.newConnectionName) {
      connectionsQueryRef.refetch();
    }
  }, [state.newConnectionName, connectionsQueryRef]);

  return (
    <>
      <S.Wrapper style={style} className={clsx(['fpt-connection-selector', className])}>
        <S.SelectWrapper>
          <Select
            onChange={handleChange}
            loading={connectionsQuery.isLoading}
            placeholder={i18n._('Select from existing FTP connection...')}
            allowClear
            disabled={connectionsQuery.isLoading}
            value={state.selectedItem?.id}
          >
            {connectionsQuery.data?.map(conn => (
              <Option value={conn.id} key={conn.id}>
                {conn.name}
              </Option>
            ))}
          </Select>
          {state.selectedItem && (
            <div className="actions">
              <FtpConnectionActions
                popoverPlacement="rightTop"
                data={state.selectedItem}
                mode="ICON_BUTTON"
              />
            </div>
          )}
        </S.SelectWrapper>

        <S.Footer className="footer">
          {connectionsQuery.isError && !isNoConnectionsFound && (
            <ErrorInfo
              error={connectionsQuery.error}
              message={
                <Trans>
                  Failed to load FTP connections form domain &quot;{domainName}&quot; and schema
                  &quot;
                  {schemaName}&quot;.
                </Trans>
              }
            />
          )}

          <Button action="create" className="reate-ft-connection-btn" onClick={handleCreateRequest}>
            <Trans>Create FTP connection</Trans>
          </Button>
        </S.Footer>
      </S.Wrapper>

      <FtpConnectionForm
        mode="DIALOG"
        dialogProps={{
          isOpen: state.isDialogOpen,
          close: (connectionName?: string) => {
            setState(draft => {
              draft.isDialogOpen = false;
              draft.newConnectionName = connectionName;
            });
          }
        }}
      />
    </>
  );
};

export default FtpConnectionSelector;
