import React from 'react';

import * as T from 'components/_styled/tableSc';
import MonacoEditor from 'components/ui/monacoEditor';
import { ObjectHistory } from 'services/api/domain/objectHistory';

type DocumentHistoryTableJsonDiffProps = {
  data?: ObjectHistory;
};

const DocumentHistoryTableJsonDiff: React.FC<DocumentHistoryTableJsonDiffProps> = ({ data }) => {
  if (!data) {
    return null;
  }

  return (
    <T.SingleContentContainer fullHeight>
      <MonacoEditor
        value={data.parsedOldJsonObject}
        originalValue={data.prevParsedOldJsonObj}
        mode="JSON"
        isDiffEditor
        height="100%"
        defaultFontSize="SMALL"
        defaultColorTheme="BRIGHT"
        showMiniMap={false}
        readOnly
      />
    </T.SingleContentContainer>
  );
};

export default DocumentHistoryTableJsonDiff;
