import { ListQueryParams } from '@phoenix-systems/react-table/dist/types/types';

import { UsersTableActionTypes, UsersTableSingleView } from './usersTable.types';

/* Action Definition */

type SetParamsAction = {
  type: UsersTableActionTypes.SET_PARAMS;
  params?: Partial<ListQueryParams>;
};

type SetSingleViewAction = {
  type: UsersTableActionTypes.SET_SINGLE_VIEW;
  view: UsersTableSingleView;
};

type SetSelectedKeyAction = {
  type: UsersTableActionTypes.SET_SELECTED_KEY;
  key?: string;
};

type ResetAction = {
  type: UsersTableActionTypes.RESET;
};

/* Union Action Types */

export type UsersTableActionCreators =
  | SetParamsAction
  | SetSingleViewAction
  | SetSelectedKeyAction
  | ResetAction;

/* Action Creators */

export const st_usersTable_setParams = (params?: Partial<ListQueryParams>): SetParamsAction => ({
  type: UsersTableActionTypes.SET_PARAMS,
  params
});

export const st_usersTable_setSingleView = (view: UsersTableSingleView): SetSingleViewAction => ({
  type: UsersTableActionTypes.SET_SINGLE_VIEW,
  view
});

export const st_usersTable_setSelectedKey = (key?: string): SetSelectedKeyAction => ({
  type: UsersTableActionTypes.SET_SELECTED_KEY,
  key
});

export const st_usersTable_reset = (): ResetAction => ({
  type: UsersTableActionTypes.RESET
});
