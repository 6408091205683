import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Menu } from 'antd';
import { omit } from 'lodash';
import { useDispatch } from 'react-redux';
import { useImmer } from 'use-immer';

import EntityNodeFormDialog from 'components/domain/components/domainDiagram/entityNodeFormDialog';
import { prepareNodeData } from 'components/domain/components/domainDiagram/utils';
import { EntityNodeData } from 'services/api/domain/domainDiagram';
import {
  st_domainDiagram_cleanupElements,
  st_domainDiagram_setDialogOpen,
  st_domainDiagram_updateNodeData
} from 'services/store/domainDiagram/domainDiagram.actions';

type EditEntityActionProps = {
  data: EntityNodeData;
  nodeId: string;
  closeNavigation: () => void;
};

type EditEntityActionState = {
  isDialogOpen: boolean;
};

const EditEntityAction: React.FC<EditEntityActionProps> = props => {
  const pr = omit(props, ['nodeId', 'data', 'closeNavigation']);
  const { data, nodeId, closeNavigation } = props;
  const dispatch = useDispatch();
  const [state, setState] = useImmer<EditEntityActionState>({
    isDialogOpen: false
  });

  const handleEditEntity = () => {
    closeNavigation();
    setState(draft => {
      draft.isDialogOpen = true;
    });
    dispatch(st_domainDiagram_setDialogOpen(true));
  };

  const handleDialogClose = () => {
    dispatch(st_domainDiagram_setDialogOpen(false));
    setState(draft => {
      draft.isDialogOpen = false;
    });
  };

  const handleUpdateEntity = (dt: EntityNodeData) => {
    dispatch(st_domainDiagram_updateNodeData(prepareNodeData(dt), nodeId));
    dispatch(st_domainDiagram_cleanupElements());
    handleDialogClose();
  };

  return (
    <>
      <Menu.Item
        {...pr}
        key="edit-entity"
        icon={<FontAwesomeIcon icon={['fas', 'pen']} />}
        onClick={handleEditEntity}
      >
        <Trans>Edit entity: {data.simpleClassName}</Trans>
      </Menu.Item>

      <EntityNodeFormDialog
        isOpen={state.isDialogOpen}
        insert={handleUpdateEntity}
        data={data}
        close={handleDialogClose}
        mode="EDIT"
      />
    </>
  );
};

export default EditEntityAction;
