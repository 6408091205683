import { ListQueryParams } from '@phoenix-systems/react-table/dist/types/types';

import { DbImportsTableActionTypes, DomainDbImportTableSingleView } from './dbImportsTable.types';

/* Action Definition */

type SetParamsAction = {
  type: DbImportsTableActionTypes.SET_PARAMS;
  params?: Partial<ListQueryParams>;
};

type SetSingleViewAction = {
  type: DbImportsTableActionTypes.SET_SINGLE_VIEW;
  view: DomainDbImportTableSingleView;
};

type SetActiveKeyAction = {
  type: DbImportsTableActionTypes.SET_ACTIVE_KEY;
  key?: string;
};

type ResetAction = {
  type: DbImportsTableActionTypes.RESET;
};

/* Union Action Types */

export type DbImportsTableActionCreators =
  | SetParamsAction
  | SetSingleViewAction
  | SetActiveKeyAction
  | ResetAction;

/* Action Creators */

export const st_dbImportsTable_setParams = (
  params?: Partial<ListQueryParams>
): SetParamsAction => ({
  type: DbImportsTableActionTypes.SET_PARAMS,
  params
});

export const st_dbImportsTable_setSingleView = (
  view: DomainDbImportTableSingleView
): SetSingleViewAction => ({
  type: DbImportsTableActionTypes.SET_SINGLE_VIEW,
  view
});

export const st_dbImportsTable_setActiveKey = (key?: string): SetActiveKeyAction => ({
  type: DbImportsTableActionTypes.SET_ACTIVE_KEY,
  key
});

export const st_dbImportsTable_reset = (): ResetAction => ({
  type: DbImportsTableActionTypes.RESET
});
