import React from 'react';

import { Trans } from '@lingui/macro';

import AppLayout from 'components/app/components/appLayout';
import CreateDbImportAction from 'components/domain/actions/dbImportActions/createDbImportAction';
import DbImportTable from 'components/domain/components/dbImportTable';
import DomainPage from 'components/domain/components/domainPage';
import routes from 'config/routes/routes';

const DbImportListPage: React.FC = () => (
  <AppLayout>
    <DomainPage
      route={routes.domain.dataManagement.importFromDb.list}
      className="import-table-page"
      title={<Trans>Import from DB</Trans>}
      noScroll
      schemaSelector
      content={<DbImportTable />}
      headerButtons={<CreateDbImportAction mode="BUTTON" />}
    />
  </AppLayout>
);

export default DbImportListPage;
