import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { getRoute } from '@phoenix-systems/react-router';
import { Form, Input, Modal } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { AxiosError } from 'axios';
import { clone } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import * as S from './duplicateTriggerSc';

import * as F from 'components/_styled/formSc';
import Button from 'components/ui/button';
import ErrorInfo from 'components/ui/errorInfo';
import routes from 'config/routes/routes';
import useDefaultModalProps from 'hooks/useDefaultModalProps';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';
import useIsDomain from 'hooks/useIsDomain';
import useNotification from 'hooks/useNotification';
import useTriggerName from 'hooks/useTriggerName';
import { api_createTrigger, Trigger, useTriggersCheck } from 'services/api/domain/trigger';
import history from 'services/history';
import { st_triggersTable_setSelectedKey } from 'services/store/triggersTable/triggersTable.actions';

type DuplicateTriggerDialogProps = {
  isOpen: boolean;
  data: Trigger;
  close: () => void;
};

const DuplicateTriggerDialog: React.FC<DuplicateTriggerDialogProps> = ({ data, isOpen, close }) => {
  const [addNotification] = useNotification();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const triggerName = useTriggerName();
  const isDomain = useIsDomain();
  const triggersQuery = useTriggersCheck({});
  const goto = useGoToDomainRoute();
  const modalProps = useDefaultModalProps();

  const defaultName = `${data.name}-copy`;

  const createMutation = useMutation<Trigger, AxiosError, Partial<Trigger>>(
    params => api_createTrigger(params),
    {
      onSuccess: resData => {
        addNotification({
          type: 'success',
          message: i18n._(t`Successfully created trigger "${resData.name}".`)
        });

        dispatch(st_triggersTable_setSelectedKey(resData.name));
        queryClient.refetchQueries(['triggers']);

        if (triggerName) {
          if (isDomain) {
            goto(routes.scheduler.single, { path: resData.name });
          } else {
            history.push(getRoute(routes.scheduler.single, resData.name));
          }
        }

        close();
      },
      onError: () => {
        addNotification({
          type: 'error',
          message: i18n._(t`Failed to duplicate trigger "${data.name}".`)
        });
      }
    }
  );

  const handleDuplicate = async () => {
    const formData = await form.validateFields();
    const payload: Partial<Trigger> = clone(data);
    delete payload.id;
    payload.name = formData.name;
    payload.active = false;
    createMutation.mutate(payload);
  };

  const validateName = (rule: RuleObject, value: string) =>
    new Promise((resolve, reject) => {
      if (triggersQuery.data?.find(trigger => trigger.name === value)) {
        reject(new Error(`Name "${value}" is already in use.`));
      }

      resolve(value);
    });

  return (
    <Modal
      {...modalProps}
      visible={isOpen}
      title={
        <>
          <FontAwesomeIcon icon={['fas', 'copy']} />
          <Trans>Duplicate Trigger {data.name}</Trans>
        </>
      }
      footer={
        <>
          <Button
            action={createMutation.isSuccess ? 'close' : 'cancel'}
            disabled={createMutation.isLoading}
            onClick={() => close()}
          />
          <Button action="save" mutation={createMutation} onClick={handleDuplicate}>
            <Trans>Duplicate trigger</Trans>
          </Button>
        </>
      }
    >
      <p>
        Do you want to duplicate the trigger <b>{data.name}</b>? <br />
        Please provide a valid trigger name.
      </p>
      <S.FormContainer>
        <F.StyledForm form={form} initialValues={{ name: defaultName }} colon={false}>
          <Form.Item
            name="name"
            label={i18n._('Trigger name')}
            validateFirst
            rules={[
              { required: true },
              {
                validator: validateName,
                message: i18n._('"Name" already exists. "Name" needs to be globally unique.')
              }
            ]}
            messageVariables={{ name: i18n._('Trigger name') }}
          >
            <Input defaultValue={defaultName} />
          </Form.Item>
        </F.StyledForm>
        {createMutation.isError && (
          <ErrorInfo
            error={createMutation.error}
            message={<Trans>Failed to duplicate trigger.</Trans>}
          />
        )}
      </S.FormContainer>
    </Modal>
  );
};

export default DuplicateTriggerDialog;
