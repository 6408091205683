import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { getRoute } from '@phoenix-systems/react-router';
import { Col, Row } from 'antd';

import * as S from '../usersTableSc';

import * as T from 'components/_styled/tableSc';
import DataItem from 'components/ui/dataItem';
import routes from 'config/routes/routes';
import { User } from 'services/api/domain/user';
import history from 'services/history';

type UsersTableOverviewProps = {
  data?: User;
};

const UsersTableOverview: React.FC<UsersTableOverviewProps> = ({ data }) => {
  const handleEditUser = () => {
    if (data?.username) {
      history.push(getRoute(routes.userAdmin.user.single, data.username));
    }
  };

  if (!data) {
    return null;
  }

  return (
    <T.SingleContentContainer>
      <Row gutter={[12, 12]}>
        <Col xs={12}>
          <ul>
            <li>
              <DataItem name={<Trans>Id</Trans>} value={data.id} breakWord hasCopy />
            </li>
            <li>
              <DataItem
                name={<Trans>Username</Trans>}
                value={
                  <S.StyledLink to={getRoute(routes.userAdmin.user.single, data.username)}>
                    {data.username}
                  </S.StyledLink>
                }
                hasCopy
                copyValue={data.username}
                onEdit={handleEditUser}
              />
            </li>
            <li>
              <DataItem name={<Trans>Groups</Trans>} value={data.groups?.join(', ')} />
            </li>
          </ul>
        </Col>
        <Col xs={12}>
          <ul>
            <li>
              <DataItem
                name={<Trans>Organisations</Trans>}
                value={
                  <>
                    {data.orgUnits?.map(orgUnit => (
                      <>
                        <S.StyledLink
                          key={orgUnit}
                          to={getRoute(routes.userAdmin.orgunit.single, orgUnit)}
                          style={{ marginRight: 10 }}
                        >
                          {orgUnit}
                          <FontAwesomeIcon icon={['fas', 'pen']} />
                        </S.StyledLink>
                        <br />
                      </>
                    ))}
                  </>
                }
              />
            </li>
          </ul>
        </Col>
      </Row>
    </T.SingleContentContainer>
  );
};

export default UsersTableOverview;
