import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Menu } from 'antd';
import { omit } from 'lodash';

import Button from 'components/ui/button';
import routes from 'config/routes/routes';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';
import { ActionMode } from 'index.types';
import { FTPConnection } from 'services/api/domain/ftpConnection';

const { Item } = Menu;

type EditFtpConnectionActionProps = {
  data: FTPConnection;
  mode: ActionMode;
};

const EditFtpConnectionAction: React.FC<EditFtpConnectionActionProps> = props => {
  const antProps = omit(props, ['data', 'mode']);
  const { mode, data } = props;
  const goto = useGoToDomainRoute();

  const handleEditRequest = () => {
    goto(routes.domain.connections.ftp.single, {
      path: data.name
    });
  };

  return (
    <>
      {mode === 'LIST_ITEM' && (
        <Item
          {...antProps}
          key={`edit-db-import_${data.id}`}
          icon={<FontAwesomeIcon icon={['fas', 'pen']} />}
          onClick={handleEditRequest}
        >
          <Trans>Edit FTP connection</Trans>
        </Item>
      )}
      {mode === 'BUTTON' && (
        <Button action="edit" onClick={handleEditRequest}>
          <Trans>Edit FTP connection</Trans>
        </Button>
      )}
    </>
  );
};

export default EditFtpConnectionAction;
