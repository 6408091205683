import React from 'react';

import routes from '../routes/routes';

import { ViewConfig } from './view.types';

import DocsSinglePage from 'components/docs/pages/docsSinglePage';

const docsViewConfig: ViewConfig = {
  routes: [
    {
      path: routes.docs.single,
      exact: true,
      component: <DocsSinglePage />
    },
    {
      path: routes.docs.root,
      exact: true,
      component: <DocsSinglePage />
    }
  ]
};

export default docsViewConfig;
