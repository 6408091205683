import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import { Menu } from 'antd';
import { omit } from 'lodash';
import { CSSProperties } from 'styled-components';

import Button from 'components/ui/button';
import { ButtonProps } from 'components/ui/button/button.types';
import routes from 'config/routes/routes';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';

const { Item } = Menu;

type GotoObjectHistoryActionProps = {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  data: any;
  mode: 'MENU_ITEM' | 'BUTTON' | 'ICON_BUTTON';
  buttonProps?: ButtonProps;
  style?: CSSProperties;
};

const GotoObjectHistoryAction: React.FC<GotoObjectHistoryActionProps> = props => {
  const antProps = omit(props, ['data', 'mode', 'buttonProps', 'style', 'onDelete']);
  const { data, mode, buttonProps, style } = props;

  const goto = useGoToDomainRoute();

  const handleGotoHistoryRequest = () => {
    goto(routes.domain.queryData.documentHistory, {
      query: { id: data.id, depth: 100 }
    });
  };

  return (
    <>
      {mode === 'MENU_ITEM' && (
        <Item
          {...antProps}
          style={style}
          key={`edit_object_${data.id}`}
          icon={<FontAwesomeIcon icon={['fas', 'clock']} />}
          onClick={handleGotoHistoryRequest}
        >
          <Trans>Go to object history</Trans>
        </Item>
      )}
      {mode === 'BUTTON' && (
        <Button {...buttonProps} style={style} onClick={handleGotoHistoryRequest}>
          <Trans>Go to object history</Trans>
        </Button>
      )}

      {mode === 'ICON_BUTTON' && (
        <Button
          type="link"
          {...buttonProps}
          style={style}
          onClick={handleGotoHistoryRequest}
          title={i18n._('Edit object')}
          icon={['fas', 'clock']}
        />
      )}
    </>
  );
};

export default GotoObjectHistoryAction;
