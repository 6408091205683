import { Button, Menu } from 'antd';
import tw, { styled, css } from 'twin.macro';

import { JsonEditorColorTheme } from 'services/store/jsonEditor/jsonEditor.types';

type WrapperProps = {
  width?: string | number;
  height?: string | number;
};

export const Wrapper = styled.div<WrapperProps>(({ width, height }) => [
  css`
    ${tw`w-full h-full relative border border-ant-border-gray rounded-2 overflow-hidden relative flex flex-col`};
  `,

  width !== undefined &&
    css`
      width: ${typeof width === 'number' ? `${width}px` : width};
    `,

  height !== undefined &&
    css`
      height: ${typeof height === 'number' ? `${height}px` : height};
    `
]);

type StyledEditorProps = {
  theme: JsonEditorColorTheme;
  isVisible: boolean;
};

export const StyledEditor = styled.div<StyledEditorProps>(({ theme, isVisible }) => [
  css`
    ${tw`h-full overflow-hidden -mb-20 relative opacity-0`};

    & .ace_editor {
      ${tw`w-full`};

      & .ace_variable {
        ${tw`font-600`};
      }
    }
  `,

  isVisible &&
    css`
      ${tw`opacity-100`};
    `,

  theme === 'BRIGHT' &&
    css`
      & .ace_editor {
        & .ace_gutter {
          ${tw`bg-light-gray`};
        }

        & .ace_variable {
          ${tw`text-gray-800`};
        }

        & .ace_string {
          color: green;
        }

        & .ace_numeric {
          color: blue;
        }

        & .ace_boolean {
          color: red;
        }
      }
    `,

  theme === 'DARK' &&
    css`
      & .ace_editor {
        & .ace_variable {
          color: #98dcfe;
        }

        & .ace_string {
          color: #da9274;
        }

        & .ace_numeric {
          color: #98dcb0;
        }

        & .ace_boolean {
          color: #588ec3;
        }
      }
    `
]);

export const Header = styled.div(() => [
  css`
    ${tw`flex flex-row bg-light-gray border-b border-ant-border-gray`};

    & > div {
      ${tw`flex flex-row items-center `};
    }

    & div.left {
      ${tw`ml-8`};
    }

    & div.right {
      ${tw`ml-auto`};
    }
  `
]);

export const HeaderNav = styled.div(() => [
  css`
    .ant-btn {
      ${tw`text-14 m-0 p-0 text-gray-600 hover:text-primary`};
    }
  `
]);

export const StyledConfigNav = styled(Menu)(() => [
  css`
    ${tw`bg-light-gray`};
    min-width: 20rem;

    & .ant-menu-item {
      ${tw`text-12  h-28 opacity-50`};
      line-height: 2.8rem;

      & svg {
        ${tw`text-green invisible`};
      }

      &.active {
        ${tw`opacity-100`};
        & svg {
          ${tw`visible`};
        }
      }
    }

    & .ant-menu-item-group-title {
      ${tw`text-11 font-600  h-28 uppercase text-gray-500`};

      & svg {
        ${tw`mr-8`};
      }
    }
  `
]);

type ValidationBtnProps = {
  validationType: 'error' | 'warning' | 'ok';
};
export const ValidationBtn = styled(Button)<ValidationBtnProps>(({ validationType }) => [
  css`
    ${tw`bg-red rounded-full p-0 mr-6 hover:bg-red w-16 h-16 flex items-center justify-center overflow-hidden text-12 flex-none`};

    & svg {
      ${tw`text-11 text-white p-0 m-0`};
      vertical-align: 0px;
    }
  `,

  validationType === 'error' &&
    css`
      ${tw`bg-red-500 hover:bg-red-500 `};
    `,

  validationType === 'warning' &&
    css`
      ${tw`bg-yellow-400 hover:bg-yellow-400 `};
    `,

  validationType === 'ok' &&
    css`
      ${tw`bg-green-400 hover:bg-green-400 `};
    `
]);

export const ValidationList = styled.ul(() => [
  css`
    ${tw`border border-red`};

    & li {
      ${tw`p-12 text-12`};

      & span {
        ${tw`text-primary font-600 mr-4`};
      }
    }
  `
]);

export const StyledLoader = styled.div(() => [
  css`
    ${tw`absolute top-34 left-0 bottom-0 right-0 bg-light-gray-50`};
  `
]);
