import { Button } from 'antd';
import tw, { styled, css } from 'twin.macro';

import appStyles from 'styles/appStyles';

export const HeaderContainer = styled.div(() => [
  css`
    ${tw`flex flex-row h-full text-white w-full md:pr-15 bg-white shadow-lg bg-light-gray-50 bg-primary`};
  `
]);

type LogoContainerProps = {
  width: number;
  collapsedWidth: number;
  isCollapsed: boolean;
  noAnimation: boolean;
};
export const LogoContainer = styled.div<LogoContainerProps>(
  ({ width, collapsedWidth, isCollapsed, noAnimation }) => [
    css`
      @keyframes fadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      @keyframes fadeOut {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
      @keyframes fadeInText {
        0% {
          width: 0;
        }
        100% {
          width: ${width}px;
        }
      }
      @keyframes fadeOutText {
        0% {
          width: ${width}px;
        }
        100% {
          width: 0;
        }
      }

      ${tw`h-full overflow-hidden relative flex-shrink-0`};
      // background: rgba(255, 255, 255, 0.09);
      transition: all 0.2s;

      & a {
        ${tw`inline-block absolute left-0 top-0 bottom-0 right-0`};

        & div {
          ${tw`w-full h-full relative`};

          & img.logo-only {
            ${tw`absolute`};
            min-width: ${collapsedWidth - 20}px;
            max-width: ${collapsedWidth - 20}px;
            left: 10px;
            top: 9px;
            opacity: 0;
          }

          & img.logo-text {
            ${tw`absolute`};
            transition: all 0.2s;
            left: 12px;
            top: 10px;

            min-width: ${width - 24}px;
            max-width: ${width - 24}px;
            height: auto;
            opacity: 0;
          }

          & p.version {
            ${tw`text-10 absolute left-12 bottom-10 inline-block p-0 m-0`};
            line-height: 1;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            color: rgba(255, 255, 255, 0.8);
            width: 0;
          }
        }
      }
    `,

    !isCollapsed &&
      css`
        width: ${width}px;

        & p.version {
          animation-iteration-count: 1;
          animation-name: fadeInText;
          animation-delay: ${noAnimation ? 0 : 0.15}s;
          animation-fill-mode: forwards;
          animation-duration: ${noAnimation ? 0 : 0.6}s;
          animation-timing-function: ease-in-out;
        }

        & img.logo-only {
          animation-iteration-count: 1;
          animation-name: fadeOut;
          animation-fill-mode: forwards;
          animation-duration: ${noAnimation ? 0 : 0.15}s;
        }

        & img.logo-text {
          animation-iteration-count: 1;
          animation-name: fadeIn;
          animation-delay: ${noAnimation ? 0 : 0.17}s;
          animation-fill-mode: forwards;
          animation-duration: ${noAnimation ? 0 : 0.2}s;
        }
      `,

    isCollapsed &&
      css`
        width: ${collapsedWidth}px;

        & p.version {
          animation-iteration-count: 1;
          animation-name: fadeOutText;
          animation-fill-mode: forwards;
          animation-duration: ${noAnimation ? 0 : 0.1}s;
        }

        & img.logo-text {
          animation-iteration-count: 1;
          animation-name: fadeOut;
          animation-fill-mode: forwards;
          animation-duration: ${noAnimation ? 0 : 0.2}s;
        }

        & img.logo-only {
          animation-delay: ${noAnimation ? 0 : 0.08}s;
          animation-name: fadeIn;
          animation-iteration-count: 1;
          animation-duration: ${noAnimation ? 0 : 0.8}s;
          animation-fill-mode: forwards;

          transition: all 0.2s ease-in-out;
          &:hover {
            transform: scale(1.07);
          }
        }
      `
  ]
);

export const MobileLogo = styled.div(() => [
  css`
    ${tw`h-full w-full ml-12`};

    & img {
      max-width: 200px;
    }

    & p.version {
      ${tw`text-10 absolute left-12 bottom-10 inline-block p-0 m-0`};
      line-height: 1;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      color: rgba(255, 255, 255, 0.8);
    }
  `
]);

export const HeaderLeftNavContainer = styled.div(() => [
  css`
    ${tw`h-full w-full ml-28`};
  `
]);

export const HeaderRight = styled.div(() => [
  css`
    ${tw`ml-auto flex flex-row items-center h-full `};

    & .burger-menu {
      ${tw`mr-12`};
    }
  `
]);

export const NavPopupContainer = styled.div(() => [
  css`
    & .ant-popover-content {
      margin-top: -2px;
    }

    & .ant-popover-inner-content {
      ${tw`p-0`};
    }

    & .ant-menu-item {
      ${tw`font-700 m-0 mb-0 `};
    }

    & .ant-menu-item:not(.ant-menu-item-selected) {
      ${tw`hover:bg-light-gray`};
    }

    & .ant-menu-item:not(:last-child) {
      ${tw`mb-0`};
    }

    & .ant-menu-item.lang-item:not(.ant-menu-item-selected) svg {
      ${tw`invisible`};
    }

    & .ant-menu-item-selected::after {
      ${tw`border-none`};
    }
  `
]);

export const HeaderButton = styled(Button)(() => [
  css`
    ${tw`h-full shadow-none md:h-36 md:ml-15 text-14 text-white transition duration-200 focus:text-white active:text-secondary font-700 hover:text-secondary active:text-primary rounded-none px-12 `};
    border: none;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    min-width: 7rem;

    background: rgba(255, 255, 255, 0);

    &:hover,
    &:active,
    &:focus {
      border: none;
      border-left: 1px solid rgba(255, 255, 255, 0.3);
      background: rgba(255, 255, 255, 0.1);
    }

    @media (min-width: ${appStyles.breakpoints.md}px) {
      background: rgba(255, 255, 255, 0.1);
      border: none;

      &:hover,
      &:active,
      &:focus {
        border: none;
        background: rgba(255, 255, 255, 0.2);
      }
    }
  `
]);
