import React from 'react';

import CsvImportLogs from '../csvImportLogs';

import * as T from 'components/_styled/tableSc';
import { CsvImport } from 'services/api/domain/csvImport';

type CsvImportTableLogsProps = {
  data: CsvImport;
};

const CsvImportTableLogs: React.FC<CsvImportTableLogsProps> = ({ data }) => {
  if (!data) {
    return null;
  }

  return (
    <T.SingleContentContainer fullHeight>
      <CsvImportLogs data={data} />
    </T.SingleContentContainer>
  );
};

export default CsvImportTableLogs;
