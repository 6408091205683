import { Link } from 'react-router-dom';
import tw, { css, styled } from 'twin.macro';

type StyledComponentProps = {
  active?: boolean;
};

export const StyledComponent = styled.div<StyledComponentProps>(({ active }) => [
  css`
    ${tw`bg-red`};
  `,

  active &&
    css`
      ${tw`border-secondary hover:border-secondary`};
    `
]);

export const DomainLink = styled(Link)(() => [
  css`
    ${tw`text-primary font-600 hover:text-secondary`};
  `
]);

type ActiveIconProps = {
  active?: boolean;
};

export const ActiveIcon = styled.div<ActiveIconProps>(({ active }) => [
  css`
    ${tw`w-22 h-22 mr-10 rounded-full text-white bg-red flex items-center justify-center`};

    & svg {
      ${tw`text-12 text-white`};
    }
  `,

  active &&
    css`
      ${tw`bg-green`};
    `
]);
