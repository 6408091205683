import React, { useCallback } from 'react';

import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { FileIcon, fileTypesConfig } from '@phoenix-systems/react-form';
import { AxiosError } from 'axios';
import { last } from 'lodash';
import { useMutation } from 'react-query';

import * as S from './csvFileSelectorSc';

import Button from 'components/ui/button';
import useDomainName from 'hooks/useDomainName';
import useNotification from 'hooks/useNotification';
import useSchemaName from 'hooks/useSchemaName';
import {
  api_deleteTempCsvFile,
  DeleteTempCsvFileParams,
  FileMetaData
} from 'services/api/domain/csvImport';
import { getPrettyFileSize } from 'utils';

type CsvFileItemProps = {
  data: FileMetaData;
  isTemp?: boolean;
  onAfterDelete?: () => void;
};

const CsvFileItem: React.FC<CsvFileItemProps> = ({ data, isTemp, onAfterDelete }) => {
  const domainName = useDomainName();
  const schemaName = useSchemaName();
  const [addNotification] = useNotification();

  const deleteMutation = useMutation<void, AxiosError, DeleteTempCsvFileParams>(
    params => api_deleteTempCsvFile(params),
    {
      onSuccess: () => {
        addNotification({
          type: 'success',
          message: i18n._(t`Successfully deleted file "${data.name}".`)
        });

        if (onAfterDelete) {
          onAfterDelete();
        }
      },
      onError: () => {
        addNotification({
          type: 'error',
          message: i18n._(t`Failed to deleted file "${data.name}".`)
        });
      }
    }
  );

  const getMimeType = useCallback(() => {
    const parts = data.name.split('.');
    const extension = last(parts);
    const config = fileTypesConfig.find(conf => conf.extension === extension);

    return config?.mimeType || '';
  }, [data]);

  const handleRemoveTempFile = () => {
    deleteMutation.mutate({ domainName, schemaName, fileName: data.name });
  };

  return (
    <S.FileItem className="file-item">
      <div className="file">
        <FileIcon mimeType={getMimeType()} />
      </div>
      <div className="info">
        <p className="name">
          {data.name} ({getPrettyFileSize(data.sizeInByte)})
        </p>

        {data.permissions && <p className="permissions">{data.permissions}</p>}
      </div>
      {isTemp && (
        <div className="actions">
          <Button isIconOnly action="delete" onClick={handleRemoveTempFile} />
        </div>
      )}
    </S.FileItem>
  );
};

export default CsvFileItem;
