import { ListQueryParams } from '@phoenix-systems/react-table/dist/types/types';
import { createSelector } from 'reselect';

import { DomainsTableSingleView, DomainsTableState } from './domainsTable.types';

export const domainsTableState = (state: { domainsTable: DomainsTableState }): DomainsTableState =>
  state.domainsTable;

export const st_domainsTable_getParams = createSelector(
  [domainsTableState],
  (domainsTable): ListQueryParams => domainsTable.params
);

export const st_domainsTable_getSingleView = createSelector(
  [domainsTableState],
  (domainsTable): DomainsTableSingleView => domainsTable.singleView
);
