import { Calendar } from 'antd';
import tw, { css, styled } from 'twin.macro';

export const StyledCalendar = styled(Calendar)(() => [
  css`
    /*  & .ant-btn {
      ${tw`mr-0 ml-12`};
    } */
  `
]);
