import React from 'react';

import * as S from './triggersCalendarSc';

const TriggersCalendar: React.FC = () => (
  /*  const isDomain = useIsDomain();
  const domainName = useDomainName(); */

  <S.StyledCalendar />
);
export default TriggersCalendar;
