import { SidebarLayout } from '@phoenix-systems/react-layout';
import { styled, css } from 'twin.macro';

export const StyledLayout = styled(SidebarLayout)(() => [
  css`
    & .ant-layout .ant-layout-sider {
      z-index: 0 !important;
    }

    & .ant-layout .mobile-navigation .mobile-navigation-arrow {
      right: 25px;
    }
  `
]);
