import React, { useCallback, useEffect, useRef } from 'react';

import { Trans } from '@lingui/macro';
import { useImmer } from 'use-immer';

import * as S from './testDbConnectionSc';

import * as F from 'components/_styled/formSc';
import Button from 'components/ui/button';
import ErrorInfo from 'components/ui/errorInfo';
import Loader from 'components/ui/loader';
import { DbImport, TestDBConnectionParams } from 'services/api/domain/dataImport';
import { useTestDbConnection } from 'services/api/domain/dataImport/dataImport.hooks';

type TestDbConnectionProps = {
  data?: Partial<DbImport>;
};

type TestDbConnectionState = {
  data: TestDBConnectionParams;
  isConnectionParamsComplete: boolean;
};

const TestDbConnection: React.FC<TestDbConnectionProps> = ({ data }) => {
  const [state, setState] = useImmer<TestDbConnectionState>({
    data: {
      dbPassword: '',
      dbUrl: '',
      dbUser: '',
      driverClass: ''
    },
    isConnectionParamsComplete: false
  });
  const testQuery = useTestDbConnection(state.data, { enabled: false });
  const { current: testQueryRef } = useRef(testQuery);

  const handleTestConnection = () => {
    if (state.isConnectionParamsComplete) {
      testQuery.remove();
      testQuery.refetch();
    }
  };

  const isValidDbParams = useCallback(() => {
    if (!data) {
      return false;
    }

    if (
      data.dburl &&
      data.dburl !== '' &&
      data.driverclass &&
      data.driverclass !== '' &&
      data.dbuser &&
      data.dbuser !== '' &&
      data.dbpassword &&
      data.dbpassword !== ''
    ) {
      return true;
    }

    return false;
  }, [data]);

  useEffect(() => {
    testQueryRef.remove();
  }, [data, testQueryRef]);

  useEffect(
    () => () => {
      testQueryRef.remove();
    },
    [testQueryRef]
  );

  useEffect(() => {
    const isValidParams = isValidDbParams();

    setState(draft => {
      draft.data = {
        dbPassword: data?.dbpassword || '',
        dbUrl: data?.dburl || '',
        dbUser: data?.dbuser || '',
        driverClass: data?.driverclass || ''
      };
      draft.isConnectionParamsComplete = isValidParams;
    });
  }, [data, isValidDbParams, setState]);

  return (
    <S.TestConnectionWrapper>
      <Button
        icon={['fas', 'database']}
        onClick={handleTestConnection}
        disabled={!state.isConnectionParamsComplete || testQuery.isLoading}
        loading={testQuery.isLoading}
      >
        <Trans>Test DB connection</Trans>
      </Button>
      {testQuery.isLoading && <Loader />}
      {testQuery.isSuccess && (
        <F.FormAlert
          type="success"
          showIcon
          message={<Trans>Connection to db successfully established.</Trans>}
        />
      )}
      {testQuery.isError && (
        <ErrorInfo error={testQuery.error} message={<Trans>Connection test failed.</Trans>} />
      )}
    </S.TestConnectionWrapper>
  );
};

export default TestDbConnection;
