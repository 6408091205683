import { ListQueryParams } from '@phoenix-systems/react-table/dist/types/types';
import { createSelector } from 'reselect';

import { OrgUnitsTableSingleView, OrgUnitsTableState } from './orgUnitsUnitsTable.types';

export const orgUnitsTableState = (state: {
  orgUnitsTable: OrgUnitsTableState;
}): OrgUnitsTableState => state.orgUnitsTable;

export const st_orgUnitsTable_getParams = createSelector(
  [orgUnitsTableState],
  (orgUnitsTable): ListQueryParams => orgUnitsTable.params
);

export const st_orgUnitsTable_getSingleView = createSelector(
  [orgUnitsTableState],
  (orgUnitsTable): OrgUnitsTableSingleView => orgUnitsTable.singleView
);

export const st_orgUnitsTable_getSelectedKey = createSelector(
  [orgUnitsTableState],
  (orgUnitsTable): string | undefined => orgUnitsTable.selectedKey
);
