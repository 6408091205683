import { useContext } from 'react';

import { ContainerContext, ContainerContextState } from './containerProvider';

const useContainer = (): ContainerContextState => {
  const context = useContext(ContainerContext);

  if (window._env_.ENVIRONMENT !== 'production' && !context) {
    throw new Error(
      'Could not find according context ContainerContext. Please ensure the component is wrapped in a <ContainerProvider>'
    );
  }

  return context;
};

export default useContainer;
