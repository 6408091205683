import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPlusCircle,
  faPlusSquare,
  faMinusSquare,
  faExclamationCircle,
  faGlobe,
  faEye,
  faQuestionCircle,
  faCog,
  faSync,
  faGripLines,
  faTimes,
  faCopy,
  faPen,
  faDownload,
  faTextSize,
  faPalette,
  faIndent,
  faCheck,
  faSearch,
  faColumns,
  faFile,
  faBrackets,
  faUndo,
  faClipboardCheck,
  faClock,
  faHandPointRight,
  faAngleRight,
  faChevronLeft,
  faArrowFromLeft,
  faArrowFromRight,
  faArrowFromBottom,
  faArrowFromTop,
  faArrowSquareRight
} from '@fortawesome/pro-regular-svg-icons';

const addIconsFar = (): void => {
  library.add(
    faPlusCircle,
    faPlusSquare,
    faMinusSquare,
    faExclamationCircle,
    faGlobe,
    faEye,
    faQuestionCircle,
    faCog,
    faSync,
    faGripLines,
    faTimes,
    faCopy,
    faPen,
    faDownload,
    faTextSize,
    faPalette,
    faIndent,
    faCheck,
    faSearch,
    faColumns,
    faFile,
    faBrackets,
    faUndo,
    faClipboardCheck,
    faClock,
    faHandPointRight,
    faAngleRight,
    faChevronLeft,
    faArrowFromLeft,
    faArrowFromRight,
    faArrowFromBottom,
    faArrowFromTop,
    faArrowSquareRight
  );
};

export default addIconsFar;
