import React from 'react';

import { i18n } from '@lingui/core';
import {
  BlockFormatPlugin,
  BoldPlugin,
  HighlightPlugin,
  ImagePlugin,
  ItalicPlugin,
  LinkPlugin,
  ListPlugin,
  SlatePlugin,
  SoftBreakPlugin,
  StrikethroughPlugin,
  SubscriptPlugin,
  SuperscriptPlugin,
  TextEditor,
  UnderlinePlugin,
  VideoPlugin
} from '@phoenix-systems/react-slate-editor';
import { Node } from 'slate';

import * as S from './editorSc';
import EditorImage from './plugins/image/editorImage';
import EditorImageDialog from './plugins/image/editorImageDialog';
import EditorLink from './plugins/link/editorLink';
import EditorLinkDialog from './plugins/link/editorLinkDialog';
import Toolbar from './toolbar';

import { log } from 'utils';

type EditorProps = {
  onChange?: (data: Node[]) => void;
  value?: Node[];
  readonly?: boolean;
};

const plugins: SlatePlugin[] = [
  BoldPlugin(),
  ItalicPlugin(),
  UnderlinePlugin(),
  StrikethroughPlugin(),
  SuperscriptPlugin(),
  SubscriptPlugin(),
  HighlightPlugin(),
  BlockFormatPlugin(),
  ListPlugin(),
  SoftBreakPlugin(),
  LinkPlugin({
    renderDialog: props => <EditorLinkDialog {...props} />,
    component: EditorLink
  }),
  ImagePlugin({
    renderDialog: props => <EditorImageDialog {...props} />,
    component: EditorImage
  }),
  VideoPlugin({
    // eslint-disable-next-line react/display-name
    /*  renderForm: (params: RenderImageFormProps) => <VideoForm {...params} />,
    component: EditorVideo */
  })
];

const Editor: React.FC<EditorProps> = ({ onChange, value, readonly }) => {
  if (window._env_.ENVIRONMENT === 'development' || window._env_.ENVIRONMENT === 'local') {
    log(value);
  }

  const onValueChange = (editorValue: Node[]) => {
    if (onChange) {
      onChange(editorValue);
    }
  };

  return (
    <S.Container isReadonly={readonly || false}>
      <TextEditor
        placeholder={!readonly ? i18n._('Enter text...') : undefined}
        plugins={plugins}
        toolbarMode="static"
        data={value}
        readonly={readonly}
        toolbar={<Toolbar />}
        onChange={onValueChange}
      />
    </S.Container>
  );
};

export default Editor;
