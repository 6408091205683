import React, { useEffect, useRef, useState } from 'react';

import { i18n } from '@lingui/core';
import { AutoComplete } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import * as S from './docsSearchSc';

import useContainer from 'components/app/components/containerProvider/useContainer';
import { getDocConfig } from 'config/docs/docs.utils';
import useLanguage from 'hooks/useLanguage';
import { DocId, useSearchHints } from 'services/api/domain/hint';
import history from 'services/history';
import { st_docs_openDrawer } from 'services/store/docs/docs.actions';
import { st_docs_getIsDrawerOpen } from 'services/store/docs/docs.selectors';

const { Option } = AutoComplete;

type Match = {
  id: string;
  text: string;
  title: string;
  docId: string;
};

const DocsSearch: React.FC = () => {
  const [options, setOptions] = useState<Match[]>([]);
  const [phrase, setPhrase] = useState('');
  const language = useLanguage();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const isDrawerOpen = useSelector(st_docs_getIsDrawerOpen);
  const searchQuery = useSearchHints(
    { language, phrase },
    {
      enabled: false,
      onSuccess: data => {
        const keys = Object.keys(data);
        const matches: Match[] = [];

        keys.forEach(key => {
          const match = data[key];
          let text = '';

          if (match.title?.[0]) {
            text = match.title?.[0];
          }

          if (match.tooltip_text?.[0]) {
            text = match.tooltip_text?.[0];
          }

          if (match.plainText_text?.[0]) {
            text = match.plainText_text?.[0];
          }

          if (text && text !== '' && match.title[0] && match.elementId[0]) {
            matches.push({
              id: key,
              title: match.title[0],
              docId: match.elementId[0],
              text
            });
          }
        });
        setOptions(matches);
      }
    }
  );
  const { current: searchQueryRef } = useRef(searchQuery);
  const { formContainer } = useContainer();

  const handleSearch = async (value: string) => {
    setPhrase(value);
  };

  const handleSelect = (value: string) => {
    if (isDrawerOpen) {
      dispatch(st_docs_openDrawer(value as DocId));
      setPhrase('');
    } else {
      const config = getDocConfig(value as DocId);

      if (config && config.path) {
        history.push(config.path);
      }
    }
  };

  useEffect(() => {
    if (phrase !== '' && phrase.length >= 3) {
      searchQueryRef.refetch();
    } else {
      setOptions([]);
    }
  }, [phrase, searchQueryRef]);

  useEffect(() => {
    setPhrase('');

    return () => {
      setPhrase('');
    };
  }, [language, pathname]);

  return (
    <S.SearchContainer>
      <AutoComplete
        getPopupContainer={() => formContainer}
        onSelect={handleSelect}
        onSearch={handleSearch}
        placeholder={i18n._('Search...')}
        dropdownClassName="autocomplete-result"
        listHeight={600}
        value={phrase}
      >
        {options.map(option => (
          <Option key={option.id} value={option.docId}>
            <h4>{option.title}</h4>
            {/* eslint-disable-next-line react/no-danger */}
            <p dangerouslySetInnerHTML={{ __html: option.text }} />
          </Option>
        ))}
      </AutoComplete>
    </S.SearchContainer>
  );
};

export default DocsSearch;
