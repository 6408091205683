import React from 'react';

import { Trans } from '@lingui/macro';

import JsonImportForm from '../../components/jsonImportForm/jsonImportForm';

import * as F from 'components/_styled/formSc';
import AppLayout from 'components/app/components/appLayout';
import DomainPage from 'components/domain/components/domainPage';
import JsonExportForm from 'components/domain/components/jsonExportForm';
import routes from 'config/routes/routes';

const JsonImportExportPage: React.FC = () => (
  <AppLayout>
    <DomainPage
      route={routes.domain.dataManagement.jsonImportExport}
      schemaSelector
      title={<Trans>JSON Import/Export</Trans>}
      content={
        <>
          <JsonImportForm />
          <F.Separator />
          <JsonExportForm />
        </>
      }
    />
  </AppLayout>
);

export default JsonImportExportPage;
