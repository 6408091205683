import tw, { styled, css } from 'twin.macro';

export const Label = styled.span(() => [
  css`
    & .icon.before {
      ${tw`mr-6`}
    }

    & .icon.arrow {
      ${tw`mx-8`}
    }
  `
]);
