import React from 'react';

import { Trans } from '@lingui/macro';
import { Scrollable } from '@phoenix-systems/react-layout';
import { useSelector } from 'react-redux';

import TriggerTableOverview from './singleViews/triggerTableOverview';
import TriggersTableNav from './triggersTableNav';

import * as T from 'components/_styled/tableSc';
import TriggerActions from 'components/trigger/actions';
import TriggerPlay from 'components/trigger/components/triggerPlay';
import { Trigger } from 'services/api/domain/trigger';
import { st_triggersTable_getSingleView } from 'services/store/triggersTable/triggersTable.selectors';

export type DomianTableSingleView = 'OVERVIEW' | 'STATISTIC';

type TriggersTableSingleProps = {
  data: Trigger[];
};

const TriggersTableSingle: React.FC<TriggersTableSingleProps> = ({ data }) => {
  const view = useSelector(st_triggersTable_getSingleView);

  if (!data) {
    return null;
  }

  if (data.length === 1) {
    const [trigger] = data;

    return (
      <>
        <T.SingleTitle>
          <h4>
            <Trans>{trigger.name}</Trans>
          </h4>
          <T.SingleTitleRight>
            <TriggerActions
              data={trigger}
              mode="BUTTON"
              popoverPlacement="rightTop"
              style={{ marginRight: 12 }}
            />
            <TriggerPlay
              active={trigger.active}
              data={trigger}
              style={{ marginLeft: 12 }}
              size="large"
            />
          </T.SingleTitleRight>
        </T.SingleTitle>
        <TriggersTableNav data={trigger} />
        <Scrollable scrollY>
          <T.SingleContent>
            {view === 'OVERVIEW' && <TriggerTableOverview data={trigger} />}
          </T.SingleContent>
        </Scrollable>
      </>
    );
  }

  return null;
};

export default TriggersTableSingle;
