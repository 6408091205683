import React from 'react';

import { Trans } from '@lingui/macro';

import AppLayout from 'components/app/components/appLayout';
import DomainPage from 'components/domain/components/domainPage';
import FtpConnectionsTable from 'components/domain/components/ftpConnectionsTable';
import Button from 'components/ui/button';
import routes from 'config/routes/routes';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';

const FtpConnectionsListPage: React.FC = () => {
  const goto = useGoToDomainRoute();

  return (
    <AppLayout>
      <DomainPage
        route={routes.domain.connections.ftp.list}
        noScroll
        schemaSelector
        title={<Trans>FTP Connections</Trans>}
        content={<FtpConnectionsTable />}
        headerButtons={
          <Button action="create" onClick={() => goto(routes.domain.connections.ftp.create)}>
            <Trans>Create FTP connection</Trans>
          </Button>
        }
      />
    </AppLayout>
  );
};

export default FtpConnectionsListPage;
