import { produce } from 'immer';
import { clone, merge } from 'lodash';

import { HistoryTableActionCreators } from './historyTable.actions';
import { HistoryTableActionTypes, HistoryTableState } from './historyTable.types';

const initialState: HistoryTableState = Object.freeze<HistoryTableState>({
  params: {
    pageSize: 20,
    currentPage: 1
  },
  singleView: 'OVERVIEW',
  selectedKey: undefined
});

const historyTableReducer = (
  state: HistoryTableState = initialState,
  action: HistoryTableActionCreators
): HistoryTableState =>
  produce(state, draft => {
    switch (action.type) {
      case HistoryTableActionTypes.SET_PARAMS:
        draft.params = merge(clone(draft.params), action.params);

        return draft;

      case HistoryTableActionTypes.SET_SINGLE_VIEW:
        draft.singleView = action.view;

        return draft;

      case HistoryTableActionTypes.RESET:
        draft.singleView = 'OVERVIEW';
        draft.params = {};

        return draft;

      case HistoryTableActionTypes.SET_SELECTED_KEY:
        draft.selectedKey = action.key;

        return draft;

      default:
        return draft;
    }
  });

export default historyTableReducer;
