import { Language, LanguagesConfig } from './languages.types';

const languageConfig: LanguagesConfig = {
  availableLanguages: [
    {
      id: Language.EN,
      label: 'English'
    },
    {
      id: Language.DE,
      label: 'Deutsch'
    }
  ],
  defaultLanguage: Language.EN
};

const getLanguageConfig = (): LanguagesConfig => languageConfig;

export default getLanguageConfig;
