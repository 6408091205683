import React from 'react';

import { getRoute } from '@phoenix-systems/react-router';
import { Redirect } from 'react-router-dom';

import AppLayout from 'components/app/components/appLayout';
import useDomainName from 'hooks/useDomainName';

type DomainRedirectsPageProps = {
  route: string;
};

const DomainRedirectsPage: React.FC<DomainRedirectsPageProps> = ({ route }) => {
  const domainName = useDomainName();

  return (
    <AppLayout>
      <Redirect to={getRoute(route, domainName)} />
    </AppLayout>
  );
};

export default DomainRedirectsPage;
