/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import Modal from 'antd/lib/modal/Modal';
import { AxiosError } from 'axios';
import moment from 'moment';
import { useMutation } from 'react-query';

import * as S from './executeTriggerActionsSc';

import * as F from 'components/_styled/formSc';
import ErrorInfo from 'components/ui/errorInfo';
import LoaderMessage from 'components/ui/loader/loaderMessage';
import dateFormat from 'config/dateTime';
import useDefaultModalProps from 'hooks/useDefaultModalProps';
import {
  api_startCSVImport,
  CsvImportResult,
  StartCsvImportParams
} from 'services/api/domain/csvImport';
import { api_startImportFromDb, StartDbImportParams } from 'services/api/domain/dataImport';
import { Trigger } from 'services/api/domain/trigger';

type ExecuteTriggerDialogProps = {
  isOpen: boolean;
  data: Trigger;
  close: () => void;
};

const ExecuteTriggerDialog: React.FC<ExecuteTriggerDialogProps> = ({ data, isOpen, close }) => {
  const modalProps = useDefaultModalProps();

  const startDbImportMutation = useMutation<void, AxiosError, StartDbImportParams>(params =>
    api_startImportFromDb(params)
  );

  const startCsvImportMutation = useMutation<CsvImportResult, AxiosError, StartCsvImportParams>(
    params => api_startCSVImport(params)
  );

  const handleExecuteTrigger = () => {
    switch (data.taskType) {
      case 'SQLDBImport':
        startDbImportMutation.mutate({
          domainName: data.taskDomain,
          schema: data.taskScheme,
          dbImportName: data.taskName
        });

        return;

      case 'CSVFileImport':
        startCsvImportMutation.mutate({
          domainName: data.taskDomain,
          schemaName: data.taskScheme,
          importName: data.taskName
        });

        return;

      default:
        // eslint-disable-next-line  no-useless-return
        return;
    }
  };

  return (
    <Modal
      {...modalProps}
      visible={isOpen}
      title={
        <>
          <FontAwesomeIcon icon={['fas', 'sync']} />
          <Trans>Execute trigger {data.name}</Trans>
        </>
      }
      okText={<Trans>Execute trigger</Trans>}
      okButtonProps={{
        loading: startDbImportMutation.isLoading,
        disabled: startDbImportMutation.isLoading
      }}
      onCancel={() => close()}
      onOk={handleExecuteTrigger}
    >
      <p>
        Do you want to execute the trigger <b>{data.name}</b> and the attached task{' '}
        <b>{data.taskName}</b>?.
      </p>
      <S.ActionFeedback>
        {data.taskType === 'SQLDBImport' && (
          <>
            {startDbImportMutation.isLoading && (
              <LoaderMessage>Sql DB import in progress...</LoaderMessage>
            )}
            {startDbImportMutation.isSuccess && (
              <F.FormAlert
                showIcon
                type="success"
                message={<Trans>Successfully imported sql data.</Trans>}
              />
            )}
            {startDbImportMutation.isError && (
              <ErrorInfo
                error={startDbImportMutation.error}
                message={<Trans>Failed to import sql data.</Trans>}
              />
            )}
          </>
        )}

        {data.taskType === 'CSVFileImport' && (
          <>
            {startCsvImportMutation.isLoading && (
              <LoaderMessage>CSV DB import in progress...</LoaderMessage>
            )}
            {startCsvImportMutation.isSuccess && (
              <F.FormAlert
                showIcon
                type="success"
                message={<Trans>Successfully imported csv data.</Trans>}
                description={
                  <S.ImportSummary>
                    <p>
                      <span>
                        <Trans>Timestamp</Trans>:{' '}
                        {moment(startCsvImportMutation.data?.timestamp).format(dateFormat.dateTime)}
                      </span>
                    </p>
                    <p>
                      <span>
                        <Trans>Inserted</Trans>: {startCsvImportMutation.data?.inserted}
                      </span>
                    </p>
                    <p>
                      <span>
                        <Trans>Schema Name</Trans>:
                      </span>
                    </p>
                    <p>
                      <span>
                        <Trans>Csv Import Name</Trans>:
                      </span>
                    </p>
                  </S.ImportSummary>
                }
              />
            )}
            {startCsvImportMutation.isError && (
              <ErrorInfo
                error={startCsvImportMutation.error}
                message={<Trans>Failed to import csv data.</Trans>}
              />
            )}
          </>
        )}
      </S.ActionFeedback>
    </Modal>
  );
};

export default ExecuteTriggerDialog;
