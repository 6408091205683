import React from 'react';

import { Trans } from '@lingui/macro';

import DomainPage from 'components/domain/components/domainPage';
import routes from 'config/routes/routes';

const DomainStatisticPage: React.FC = () => (
  <DomainPage
    route={routes.domain.statistics}
    content={<>Statistics/performance...</>}
    title={<Trans>Statistics/performance</Trans>}
  />
);

export default DomainStatisticPage;
