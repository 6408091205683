import { DocId } from 'services/api/domain/hint';

export enum DocsActionTypes {
  OPEN_DRAWER = 'docs/OPEN_DRAWER',
  CLOSE_DRAWER = 'docs/CLOSE_DRAWER',
  DRAWER_BACK = 'docs/DRAWER_BACK',
  DRAWER_FORWARD = 'docs/DRAWER_FORWARD',
  SET_EDITOR_MODE = 'docs/SET_EDITOR_MODE'
}

export type EditorMode = 'edit' | 'read';

export type DocsState = Readonly<{
  drawerCurrentId?: DocId;
  drawerHistoryIndex: number;
  drawerHistory: DocId[];
  isDrawerOpen: boolean;
  isBackEnabled: boolean;
  isForwardEnabled: boolean;
  editorMode: EditorMode;
}>;
