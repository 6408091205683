import tw, { css, styled } from 'twin.macro';

export const EdgesList = styled.div(() => [
  css`
    ${tw`mr-12 mt-12`}

    svg {
      ${tw`mr-12`}
    }

    ul {
      ${tw`mt-12`}
    }

    li {
      ${tw`mb-2`}
    }
  `
]);
