import { ListQueryParams } from '@phoenix-systems/react-table/dist/types/types';

import { OrgUnitsTableActionTypes, OrgUnitsTableSingleView } from './orgUnitsUnitsTable.types';

/* Action Definition */

type SetParamsAction = {
  type: OrgUnitsTableActionTypes.SET_PARAMS;
  params?: Partial<ListQueryParams>;
};

type SetSingleViewAction = {
  type: OrgUnitsTableActionTypes.SET_SINGLE_VIEW;
  view: OrgUnitsTableSingleView;
};

type SetSelectedKeyAction = {
  type: OrgUnitsTableActionTypes.SET_SELECTED_KEY;
  key?: string;
};

type ResetAction = {
  type: OrgUnitsTableActionTypes.RESET;
};

/* Union Action Types */

export type OrgUnitsTableActionCreators =
  | SetParamsAction
  | SetSingleViewAction
  | SetSelectedKeyAction
  | ResetAction;

/* Action Creators */

export const st_orgUnitsTable_setParams = (params?: Partial<ListQueryParams>): SetParamsAction => ({
  type: OrgUnitsTableActionTypes.SET_PARAMS,
  params
});

export const st_orgUnitsTable_setSingleView = (
  view: OrgUnitsTableSingleView
): SetSingleViewAction => ({
  type: OrgUnitsTableActionTypes.SET_SINGLE_VIEW,
  view
});

export const st_orgUnitsTable_setSelectedKey = (key?: string): SetSelectedKeyAction => ({
  type: OrgUnitsTableActionTypes.SET_SELECTED_KEY,
  key
});

export const st_orgUnitsTable_reset = (): ResetAction => ({
  type: OrgUnitsTableActionTypes.RESET
});
