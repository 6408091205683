import React from 'react';

import { Trans } from '@lingui/macro';

import AppLayout from 'components/app/components/appLayout';
import DocumentHistoryTable from 'components/domain/components/documentHistory';
import DomainPage from 'components/domain/components/domainPage';
import routes from 'config/routes/routes';

const DocumentHistoryPage: React.FC = () => (
  <AppLayout>
    <DomainPage
      route={routes.domain.queryData.documentHistory}
      title={<Trans>Document history</Trans>}
      schemaSelector
      content={<DocumentHistoryTable />}
      noScroll
    />
  </AppLayout>
);

export default DocumentHistoryPage;
