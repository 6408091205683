import React from 'react';

import { Trans } from '@lingui/macro';

import * as T from 'components/_styled/tableSc';
import ObjectActions from 'components/domain/actions/objectActions/objectActions';
import MonacoEditor from 'components/ui/monacoEditor';
import NoContent from 'components/ui/noContent';

type QueryDocumentsResultListSingleProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
};

const QueryDocumentsResultListSingle: React.FC<QueryDocumentsResultListSingleProps> = ({
  data
}) => {
  const [item] = data;

  if (!item) {
    return <NoContent fullHeight />;
  }

  return (
    <>
      <T.SingleTitle>
        <h4>
          <Trans>Object: {item.id}</Trans>
        </h4>
        <T.SingleTitleRight>
          <ObjectActions
            data={item}
            style={{ marginRight: 12 }}
            mode="BUTTON"
            popoverPlacement="leftTop"
          />
        </T.SingleTitleRight>
      </T.SingleTitle>
      <T.SingleContentContainer fullHeight>
        <MonacoEditor
          value={item}
          mode="JSON"
          height="100%"
          defaultColorTheme="BRIGHT"
          showMiniMap={false}
          readOnly
        />
      </T.SingleContentContainer>
    </>
  );
};

export default QueryDocumentsResultListSingle;
