import React, { useEffect, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ToolbarButtonBold,
  ToolbarButtonHighlight,
  ToolbarButtonItalic,
  ToolbarButtonLink,
  ToolbarButtonList,
  ToolbarButtonsBlockFormat,
  ToolbarButtonStrikethrough,
  ToolbarButtonSubscript,
  ToolbarButtonSuperscript,
  ToolbarButtonUnderline,
  ToolbarButtonVideo,
  ToolbarSeparator,
  useEditorActions,
  useEditorState,
  useIsBeginOfBlock,
  useIsTextSelected,
  useIsTypeActive,
  ToolbarButtonsImage
} from '@phoenix-systems/react-slate-editor';
import { Switch } from 'antd';

import * as S from './toolbarSc';

const Toolbar: React.FC = () => {
  const isTextSelected = useIsTextSelected();
  const isBeginOfBlock = useIsBeginOfBlock();
  const isLinkActive = useIsTypeActive('link');
  const isImageActive = useIsTypeActive('image');
  const isVideoActive = useIsTypeActive('video');

  const editorActions = useEditorActions();
  const { toolbarMode } = useEditorState();
  const { current: editorActionsRef } = useRef(editorActions);
  const { isDialogOpen } = useEditorState();

  const handleToolbarMode = (checked: boolean) => {
    editorActions.setToolbarMode(checked ? 'static' : 'hovering');
  };

  useEffect(() => {
    if (!isLinkActive && !isBeginOfBlock && !isTextSelected) {
      editorActionsRef.setToolbarHidden(true);
    } else {
      editorActionsRef.setToolbarHidden(false);
    }
  }, [isLinkActive, isBeginOfBlock, isTextSelected, isDialogOpen, editorActionsRef]);

  return (
    <S.StyledToolbar isHovering={toolbarMode === 'hovering'}>
      {(isTextSelected || toolbarMode === 'static') && (
        <>
          <ToolbarButtonBold />
          <ToolbarButtonItalic />
          <ToolbarButtonUnderline />
          <ToolbarButtonStrikethrough />
          <ToolbarButtonSuperscript />
          <ToolbarButtonSubscript />
          <ToolbarButtonHighlight />
          <ToolbarSeparator />
        </>
      )}

      {(((isBeginOfBlock || isTextSelected) && !isImageActive && !isVideoActive) ||
        toolbarMode === 'static') && (
        <>
          <ToolbarButtonsBlockFormat />
          <ToolbarButtonList />
        </>
      )}

      {(isLinkActive || isTextSelected || toolbarMode === 'static') && (
        <>
          <ToolbarSeparator />
        </>
      )}
      <ToolbarButtonLink />

      {((!isImageActive && !isVideoActive) || toolbarMode === 'static') && <ToolbarSeparator />}
      {((!isTextSelected && !isVideoActive) || toolbarMode === 'static') && <ToolbarButtonsImage />}
      {((!isImageActive && !isTextSelected) || toolbarMode === 'static') && <ToolbarButtonVideo />}

      {toolbarMode === 'hovering' && !isTextSelected && <ToolbarSeparator />}

      <Switch
        size="small"
        unCheckedChildren={<FontAwesomeIcon icon={['fas', 'anchor']} />}
        checkedChildren={<FontAwesomeIcon icon={['fas', 'eye-slash']} />}
        checked={toolbarMode === 'static'}
        onChange={handleToolbarMode}
      />
    </S.StyledToolbar>
  );
};

export default Toolbar;
