import { ListQueryParams } from '@phoenix-systems/react-table/dist/types/types';

export type DomainsTableSingleView = 'OVERVIEW' | 'STATISTIC' | 'USERS';

export enum DomainsTableActionTypes {
  SET_PARAMS = 'domainsTable/SET_PARAMS',
  SET_SINGLE_VIEW = 'domainsTable/SET_SINGLE_VIEW',
  RESET = 'domainsTable/RESET'
}

export type DomainsTableState = Readonly<{
  params: ListQueryParams;
  singleView: DomainsTableSingleView;
}>;
