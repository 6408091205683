import tw, { styled, css } from 'twin.macro';

type StyledComponentProps = {
  isCopy?: boolean;
  breakWord?: boolean;
};

export const StyledItem = styled.div<StyledComponentProps>(({ isCopy, breakWord }) => [
  css`
    ${tw`items-baseline relative min-h-40`};

    & .label {
      ${tw`font-600 text-12 text-primary `};
    }

    & .value {
      ${tw`pb-16 font-500`};
    }

    & .action-btn {
      ${tw`ml-6 text-gray-300 w-16 hover:text-primary `};
      transform: translateY(-0.3rem);

      & svg {
        ${tw`text-14`};
      }

      &.edit {
        ${tw`text-primary`};
      }
    }
  `,

  isCopy &&
    css`
      & .value span {
        animation: blink 150ms infinite;
      }

      @keyframes blink {
        0% {
          ${tw`bg-white`};
        }
        50% {
          ${tw`bg-light-gray-active`};
        }
        100% {
          ${tw`bg-white`};
        }
      }
    `,

  breakWord &&
    css`
      & .value span {
        word-break: break-all;
      }
    `
]);
