import React, { useCallback } from 'react';

import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { saveAs } from 'file-saver';
import { v4 as uuid } from 'uuid';

import { MonacoEditorMode } from './monacoEditor.types';

import Button from 'components/ui/button';
import useDomainName from 'hooks/useDomainName';
import useNotification from 'hooks/useNotification';
import useSchemaName from 'hooks/useSchemaName';
import { AnyObject } from 'index.types';

type MonacoEditorDownloadBtnProps = {
  data?: AnyObject | Array<AnyObject> | string;
  fileName?: string;
  type: MonacoEditorMode;
};

const MonacoEditorDownloadBtn: React.FC<MonacoEditorDownloadBtnProps> = ({
  data,
  fileName,
  type
}) => {
  const [addNotification] = useNotification();
  const domainName = useDomainName();
  const schema = useSchemaName();

  const getMimeType = useCallback(() => {
    switch (type) {
      case 'JSON':
        return 'application/json';

      case 'SQL':
        return 'application/sql';

      default:
        return 'text/plain';
    }
  }, [type]);

  const handleDownload = () => {
    if (data) {
      const name = `${domainName}_${schema}_${uuid()}.${type.toLowerCase()}`;
      const fileToSave = new Blob([JSON.stringify(data)], {
        type: getMimeType()
      });
      saveAs(fileToSave, fileName || name);
      addNotification({
        type: 'success',
        message: i18n._(t`Downloaded ${type} to ${fileName || name}.`)
      });
    }
  };

  return (
    <Button
      onClick={handleDownload}
      type="link"
      icon={['far', 'download']}
      title={i18n._(t`Download ${type}`)}
      disabled={!data}
    />
  );
};

export default MonacoEditorDownloadBtn;
