import { produce } from 'immer';
import { clone, merge } from 'lodash';

import { DomainsTableActionCreators } from './domainsTable.actions';
import { DomainsTableActionTypes, DomainsTableState } from './domainsTable.types';

const initialState: DomainsTableState = Object.freeze<DomainsTableState>({
  params: {
    pageSize: 20,
    currentPage: 1
  },
  singleView: 'OVERVIEW'
});

const domainsTableReducer = (
  state: DomainsTableState = initialState,
  action: DomainsTableActionCreators
): DomainsTableState =>
  produce(state, draft => {
    switch (action.type) {
      case DomainsTableActionTypes.SET_PARAMS:
        draft.params = merge(clone(draft.params), action.params);

        break;

      case DomainsTableActionTypes.SET_SINGLE_VIEW:
        draft.singleView = action.view;

        break;

      case DomainsTableActionTypes.RESET:
        draft.singleView = 'OVERVIEW';
        draft.params = {};

        break;

      default:
        break;
    }
  });

export default domainsTableReducer;
