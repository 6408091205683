import React from 'react';

import { Trans } from '@lingui/macro';
import { useFullScreenHandle } from 'react-full-screen';

import * as S from './toggleFullScreenActionSc';

import DiagramFullScreen from 'components/domain/components/domainDiagram/diagramFullScreen';
import Button from 'components/ui/button/button';

const ToggleFullScreenAction: React.FC = () => {
  const fcHandle = useFullScreenHandle();

  return (
    <>
      <Button icon={['fas', 'compress']} onClick={fcHandle.enter}>
        <Trans>Fullscreen</Trans>
      </Button>
      <S.StyledFullScreen handle={fcHandle}>
        {fcHandle.active && <DiagramFullScreen />}
      </S.StyledFullScreen>
    </>
  );
};

export default ToggleFullScreenAction;
