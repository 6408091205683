import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import * as T from 'components/_styled/tableSc';
import { User } from 'services/api/domain/user';
import { st_triggersTable_getSingleView } from 'services/store/triggersTable/triggersTable.selectors';
import { st_usersTable_setSingleView } from 'services/store/usersTable/usersTable.actions';
import { UsersTableSingleView } from 'services/store/usersTable/usersTable.types';

const { TabPane } = Tabs;

type UsersTableNavProps = {
  data: User;
};

const UsersTableNav: React.FC<UsersTableNavProps> = () => {
  const view = useSelector(st_triggersTable_getSingleView);
  const dispatch = useDispatch();
  const handleTabChange = (value: string) => {
    dispatch(st_usersTable_setSingleView(value as UsersTableSingleView));
  };

  return (
    <T.SingleNav>
      <T.TabNavigation defaultActiveKey="1" onChange={handleTabChange} activeKey={view}>
        <TabPane
          tab={
            <>
              <FontAwesomeIcon icon={['fas', 'info-circle']} />
              <Trans>Overview</Trans>
            </>
          }
          key="OVERVIEW"
        />
      </T.TabNavigation>
    </T.SingleNav>
  );
};

export default UsersTableNav;
