import React from 'react';

import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import AppLayout from 'components/app/components/appLayout';
import DomainPage from 'components/domain/components/domainPage';
import QueryDocumentsResultList from 'components/domain/components/queryDocumentsResultList';
import routes from 'config/routes/routes';
import useDomainRoute from 'hooks/useDomainRoute';
import {
  st_entityDocument_getEntity,
  st_entityDocument_getQuery
} from 'services/store/entityDocument/entityDocument.selectors';

const EntityDocumentResultListPage: React.FC = () => {
  const solrQuery = useSelector(st_entityDocument_getQuery);
  const currentType = useSelector(st_entityDocument_getEntity);
  const getDomainRoute = useDomainRoute();

  if (!solrQuery || !currentType) {
    return <Redirect to={getDomainRoute(routes.domain.queryData.entityDocument.root)} />;
  }

  return (
    <AppLayout>
      <DomainPage
        route={routes.domain.queryData.entityDocument.list}
        title={<Trans>Entity/document: Query Result</Trans>}
        className="entiy-document-result-list-page"
        schemaSelector={{ readonly: true }}
        noScroll
        content={<QueryDocumentsResultList mode="STANDALONE" />}
      />
    </AppLayout>
  );
};

export default EntityDocumentResultListPage;
