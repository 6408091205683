import React, { useEffect, useRef } from 'react';

import { Desktop, Mobile } from '@phoenix-systems/react-layout';
import { useNavigationActions } from '@phoenix-systems/react-navigation';
import { useSelector } from 'react-redux';

import * as S from './topNavigationSc';

import { getTopNavigationConfig } from 'config/navigation';
import { useIsSuperadmin } from 'hooks/useIsSuperAdmin';
import { st_app_getLanguage } from 'services/store/app/app.selectors';
import { st_domain_getDomainName } from 'services/store/domain/domain.selectors';

const NAVIGATION_ID = 'top';

const TopNavigation: React.FC = () => {
  const { setNavigationConfig } = useNavigationActions();
  const { current: setNavigationConfigRef } = useRef(setNavigationConfig);
  const language = useSelector(st_app_getLanguage);
  const activeDomainName = useSelector(st_domain_getDomainName);
  const isSuperadmin = useIsSuperadmin();

  useEffect(() => {
    setNavigationConfigRef(NAVIGATION_ID, getTopNavigationConfig(activeDomainName, isSuperadmin));
  }, [language, setNavigationConfigRef, activeDomainName, isSuperadmin]);

  return (
    <>
      <Desktop>
        <S.TopNavigation
          navigationId={NAVIGATION_ID}
          mode="horizontal"
          config={getTopNavigationConfig(activeDomainName, isSuperadmin)}
          overflowedIndicator={false}
        />
      </Desktop>
      <Mobile>
        <S.TopNavigation
          navigationId={NAVIGATION_ID}
          config={getTopNavigationConfig(activeDomainName, isSuperadmin)}
          inlineCollapsed={false}
          mode="inline"
        />
      </Mobile>
    </>
  );
};

export default TopNavigation;
