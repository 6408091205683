import React from 'react';

import { Trans } from '@lingui/macro';
import { Desktop } from '@phoenix-systems/react-layout';
import { Redirect, useParams } from 'react-router-dom';

import AppLayout from 'components/app/components/appLayout';
import Breadcrumbs from 'components/app/components/breadcrumbs';
import Button from 'components/ui/button';
import ErrorInfo from 'components/ui/errorInfo';
import Exception from 'components/ui/exception';
import Loader from 'components/ui/loader';
import Page from 'components/ui/page';
import OrgUnitForm from 'components/userAdmin/components/orgUnitForm';
import routes from 'config/routes/routes';
import { useIsSuperadmin } from 'hooks/useIsSuperAdmin';
import useNotFound from 'hooks/useNotFound';
import { useOrgUnitByName } from 'services/api/domain/orgUnit';
import history from 'services/history';

const OrgUnitSinglePage: React.FC = () => {
  const { orgunitName } = useParams<{ orgunitName: string }>();
  const isSuperadmin = useIsSuperadmin();
  const orgUnitQuery = useOrgUnitByName(orgunitName);
  const isNotFound = useNotFound(orgUnitQuery);

  if (!isSuperadmin) {
    return <Redirect to={routes.dashboard.root} />;
  }

  if (isNotFound) {
    <Exception
      code={404}
      message={
        <Trans>
          Organisation <b>{orgunitName}</b> was not found.
        </Trans>
      }
    />;
  }

  return (
    <AppLayout>
      <Page
        scrollableProps={{ scrollId: 'page-scroll' }}
        content={
          orgUnitQuery.isLoading ? (
            <Loader fullHeight />
          ) : (
            <>
              {orgUnitQuery.isError && (
                <ErrorInfo
                  error={orgUnitQuery.error}
                  message={<Trans>Failed to load organisation &quot;{orgunitName}&quot;.</Trans>}
                />
              )}
              {orgUnitQuery.data && <OrgUnitForm data={orgUnitQuery.data} />}
            </>
          )
        }
        title={<Trans>Organisation: {orgunitName}</Trans>}
        titleTop={
          <Desktop>
            <Breadcrumbs />
          </Desktop>
        }
        titleElements={
          <>
            <Button action="delete" style={{ marginRight: 12 }} disabled={!orgUnitQuery.data}>
              <Trans>Delete Organisation</Trans>
            </Button>
            <Button action="create" onClick={() => history.push(routes.userAdmin.orgunit.create)}>
              <Trans>Create Organisation</Trans>
            </Button>
          </>
        }
      />
    </AppLayout>
  );
};

export default OrgUnitSinglePage;
