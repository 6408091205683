import React from 'react';

import { Trans } from '@lingui/macro';
import { Scrollable } from '@phoenix-systems/react-layout';
import { useSelector } from 'react-redux';

import DomainsSingleOverview from './domainsSingleOverview';
import DomainsSingleUsers from './domainsSingleUsers';
import DomainsTableSingleNav from './domainTableSingleNav';

import * as T from 'components/_styled/tableSc';
import DomainActions from 'components/domain/actions/domainActions';
import { Domain } from 'services/api/domain/domain';
import { st_domainsTable_getSingleView } from 'services/store/domainsTable/domainsTable.selectors';

export type DomianTableSingleView = 'OVERVIEW' | 'STATISTIC';

type DomainsTableSingleProps = {
  data: Domain[];
};

const DomainsTableSingle: React.FC<DomainsTableSingleProps> = ({ data }) => {
  const view = useSelector(st_domainsTable_getSingleView);

  if (!data) {
    return null;
  }

  if (data.length === 1) {
    const [domain] = data;

    return (
      <>
        <T.SingleTitle>
          <h4>
            <Trans>Domain: {domain.domainName}</Trans>
          </h4>
          <T.SingleTitleRight>
            <DomainActions data={domain} mode="BUTTON" />
          </T.SingleTitleRight>
        </T.SingleTitle>
        <DomainsTableSingleNav data={domain} />

        <Scrollable scrollY>
          <T.SingleContent>
            {view === 'OVERVIEW' && <DomainsSingleOverview data={domain} />}
            {view === 'USERS' && <DomainsSingleUsers data={domain} />}
            {view === 'STATISTIC' && <p>statistics</p>}
          </T.SingleContent>
        </Scrollable>
      </>
    );
  }

  return null;
};

export default DomainsTableSingle;
