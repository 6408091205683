import { i18n } from '@lingui/core';
import { clone, concat } from 'lodash';

import { DocConfig } from './docs.config.types';
// eslint-disable-next-line import/no-cycle
import { getRouteFromDocId } from './docs.utils';

import { DocId } from 'services/api/domain/hint';

const DOCS_CONFIG = [
  {
    id: DocId.BIG_DATA_SERVICE,
    icon: ['fas', 'globe'],
    navLabel: i18n._('Big data service'),
    children: [
      {
        id: DocId.DOMAIN,
        icon: ['fas', 'globe'],
        navLabel: i18n._('Domain'),
        children: [
          {
            id: DocId.DOMAIN_OVERVIEW,
            icon: ['fas', 'globe'],
            navLabel: i18n._('Domain overview'),
            children: [
              {
                id: DocId.DOMAIN_DOMAIN_ID,
                navLabel: i18n._('Id')
              },
              {
                id: DocId.DOMAIN_DOMAIN_NAME,
                navLabel: i18n._('Name')
              },
              {
                id: DocId.DOMAIN_API_KEY,
                navLabel: i18n._('Api Key')
              },
              {
                id: DocId.DOMAIN_SCHEMA,
                navLabel: i18n._('Schema')
              },
              {
                id: DocId.DOMAIN_URI,
                navLabel: i18n._('Uri')
              },
              {
                id: DocId.DOMAIN_DESCRIPTION,
                navLabel: i18n._('Descripion')
              },
              {
                id: DocId.DOMAIN_SHARD,
                navLabel: i18n._('Shard')
              },
              {
                id: DocId.DOMAIN_DELETABLE,
                navLabel: i18n._('Deletable')
              },
              {
                id: DocId.DOMAIN_ADMINISTRATOR,
                navLabel: i18n._('Administrator')
              }
            ]
          },

          // DATA MANAGEMENT
          {
            id: DocId.DOMAIN_DATA_MANAGEMENT,
            icon: ['fas', 'database'],
            navLabel: i18n._('Data management'),
            children: [
              {
                id: DocId.DOMAIN_ADMINISTRATION,
                icon: ['fas', 'tools'],
                navLabel: i18n._('Domain administration'),
                children: [
                  {
                    id: DocId.DOMAIN_ADMINISTRATION_MERGE_DOMAINS,
                    navLabel: i18n._('Merge domains'),
                    icon: ['fas', 'compress-arrows-alt'],
                    children: [
                      {
                        id: DocId.DOMAIN_ADMINISTRATION_MERGE_DOMAINS_FROM,
                        navLabel: i18n._('Source domain')
                      },
                      {
                        id: DocId.DOMAIN_ADMINISTRATION_MERGE_DOMAINS_TARGET,
                        navLabel: i18n._('Target domain')
                      }
                    ]
                  },
                  {
                    id: DocId.DOMAIN_ADMINISTRATION_REINDEX_DOMAIN,
                    navLabel: i18n._('Reindex domain'),
                    icon: ['fas', 'list'],
                    children: [
                      {
                        id: DocId.DOMAIN_ADMINISTRATION_REINDEX_DOMAIN_DOMAIN,
                        navLabel: i18n._('Domain to index')
                      },
                      {
                        id: DocId.DOMAIN_ADMINISTRATION_REINDEX_DOMAIN_BATCHSIZE,
                        navLabel: i18n._('Batch size')
                      }
                    ]
                  }
                ]
              },

              {
                id: DocId.DOMAIN_JSON_IMPEX,
                icon: ['fas', 'file-archive'],
                navLabel: i18n._('Import/ Export JSON'),
                children: [
                  {
                    id: DocId.DOMAIN_JSON_IMPEX_IMPORT,
                    icon: ['fas', 'file-import'],
                    navLabel: i18n._('Import JSON'),
                    children: [
                      {
                        id: DocId.DOMAIN_JSON_IMPEX_IMPORT_FILETYPE,
                        navLabel: i18n._('File type')
                      }
                    ]
                  },
                  {
                    id: DocId.DOMAIN_JSON_IMPEX_EXPORT,
                    icon: ['fas', 'file-export'],
                    navLabel: i18n._('Export JSON'),
                    children: [
                      {
                        id: DocId.DOMAIN_JSON_IMPEX_EXPORT_FILETYPE,
                        navLabel: i18n._('File type')
                      },
                      {
                        id: DocId.DOMAIN_JSON_IMPEX_EXPORT_ENTITY,
                        navLabel: i18n._('Select Entity')
                      }
                    ]
                  }
                ]
              },

              {
                id: DocId.DOMAIN_IMPORT_FROM_DB,
                icon: ['fas', 'file-import'],
                navLabel: i18n._('Import from DB'),
                children: [
                  {
                    id: DocId.DOMAIN_IMPORT_FROM_DB_ID,
                    navLabel: i18n._('Id')
                  },
                  {
                    id: DocId.DOMAIN_IMPORT_FROM_DB_NAME,
                    navLabel: i18n._('Name')
                  },
                  {
                    id: DocId.DOMAIN_IMPORT_FROM_DB_DB_CONFIG,
                    navLabel: i18n._('DB configuration')
                  },
                  {
                    id: DocId.DOMAIN_IMPORT_FROM_DB_DB_URL,
                    navLabel: i18n._('DB url')
                  },
                  {
                    id: DocId.DOMAIN_IMPORT_FROM_DB_DB_USER,
                    navLabel: i18n._('DB user')
                  },
                  {
                    id: DocId.DOMAIN_IMPORT_FROM_DB_DB_PASSWORD,
                    navLabel: i18n._('DB password')
                  },
                  {
                    id: DocId.DOMAIN_IMPORT_FROM_DB_DRIVER_CLASS,
                    navLabel: i18n._('Driver class')
                  },
                  {
                    id: DocId.DOMAIN_IMPORT_FROM_DB_ENTITY_NAME,
                    navLabel: i18n._('Entity')
                  },
                  {
                    id: DocId.DOMAIN_IMPORT_FROM_DB_UPDATE_CREATE,
                    navLabel: i18n._('Import strategy (update / create)')
                  },
                  {
                    id: DocId.DOMAIN_IMPORT_FROM_DB_SQL_STATEMENT,
                    navLabel: i18n._('Sql statement')
                  },
                  {
                    id: DocId.DOMAIN_IMPORT_FROM_DB_BATCH_SIZE,
                    navLabel: i18n._('Batch size')
                  },
                  {
                    id: DocId.DOMAIN_IMPORT_FROM_DB_MAX_ROWS,
                    navLabel: i18n._('Max rows')
                  },
                  {
                    id: DocId.DOMAIN_IMPORT_FROM_DB_TABLE_ENTITY_MAPPER,
                    navLabel: i18n._('Table entity mapping'),
                    children: [
                      {
                        id: DocId.DOMAIN_IMPORT_FROM_DB_TABLE_ENTITY_MAPPER_FIELD,
                        navLabel: i18n._('Field')
                      },
                      {
                        id: DocId.DOMAIN_IMPORT_FROM_DB_TABLE_ENTITY_MAPPER_COLUMN,
                        navLabel: i18n._('Column')
                      }
                    ]
                  },
                  {
                    id: DocId.DOMAIN_IMPORT_FROM_DB_SCHEDULER,
                    navLabel: i18n._('Scheduler')
                  }
                ]
              },
              {
                id: DocId.DOMAIN_IMPORT_FROM_CSV,
                icon: ['fas', 'file-import'],
                navLabel: i18n._('Import from CSV'),
                children: [
                  {
                    id: DocId.DOMAIN_IMPORT_FROM_CSV_ID,
                    navLabel: i18n._('Id')
                  },
                  {
                    id: DocId.DOMAIN_IMPORT_FROM_CSV_NAME,
                    navLabel: i18n._('Name')
                  },
                  {
                    id: DocId.DOMAIN_IMPORT_FROM_CSV_UPDATE_CREATE,
                    navLabel: i18n._('Update strategy')
                  },
                  {
                    id: DocId.DOMAIN_IMPORT_FROM_CSV_ENTITY,
                    navLabel: i18n._('Entity')
                  },
                  {
                    id: DocId.DOMAIN_IMPORT_FROM_CSV_DELIMITER,
                    navLabel: i18n._('Delimiter')
                  },
                  {
                    id: DocId.DOMAIN_IMPORT_FROM_CSV_HEADER,
                    navLabel: i18n._('header')
                  },
                  {
                    id: DocId.DOMAIN_IMPORT_FROM_CSV_UPLOAD_FILE,
                    navLabel: i18n._('Upload CSV file')
                  },
                  {
                    id: DocId.DOMAIN_IMPORT_FROM_CSV_IMPORT_FTP_CONNECTION,
                    navLabel: i18n._('FTP connection')
                  },
                  {
                    id: DocId.DOMAIN_IMPORT_FROM_CSV_FILE,
                    navLabel: i18n._('CSV file')
                  },
                  {
                    id: DocId.DOMAIN_IMPORT_FROM_CSV_MAPPING,
                    navLabel: i18n._('Column entity mapping'),
                    children: [
                      {
                        id: DocId.DOMAIN_IMPORT_FROM_CSV_COLUMN_ENTITY_MAPPER_FIELD,
                        navLabel: i18n._('Field')
                      },
                      {
                        id: DocId.DOMAIN_IMPORT_FROM_CSV_COLUMN_ENTITY_MAPPER_COLUMN,
                        navLabel: i18n._('Column')
                      }
                    ]
                  },
                  {
                    id: DocId.DOMAIN_IMPORT_FROM_CSV_SCHEDULER,
                    navLabel: i18n._('Scheduler')
                  }
                ]
              },
              {
                id: DocId.DOMAIN_TEST_DATA_GENERATOR,
                icon: ['fas', 'sync'],
                navLabel: i18n._('Test data generator'),
                children: [
                  {
                    id: DocId.DOMAIN_TESTDATA_GENERATOR_ENTITY,
                    navLabel: i18n._('Entity')
                  },
                  {
                    id: DocId.DOMAIN_TESTDATA_GENERATOR_AMOUNT,
                    navLabel: i18n._('Amount')
                  }
                ]
              }
            ]
          },

          // DATA DESIGNER
          {
            id: DocId.DOMAIN_DATA_DESIGNER,
            icon: ['fas', 'project-diagram'],
            navLabel: i18n._('Data designer'),
            children: [
              {
                id: DocId.DOMAIN_DIAGRAM,
                icon: ['fas', 'project-diagram'],
                navLabel: i18n._('Domain diagram'),
                children: [
                  {
                    id: DocId.DOMAIN_DIAGRAM_ENTITY,
                    navLabel: i18n._('Entity'),
                    children: [
                      {
                        id: DocId.DOMAIN_DIAGRAM_ENTITY_FULL_CLASS_NAME,
                        navLabel: i18n._('Class name')
                      },
                      {
                        id: DocId.DOMAIN_DIAGRAM_ENTITY_SIMPLE_CLASS_NAME,
                        navLabel: i18n._('Simple class name')
                      }
                    ]
                  },
                  {
                    id: DocId.DOMAIN_DIAGRAM_FIELD,
                    navLabel: i18n._('Field'),
                    children: [
                      {
                        id: DocId.DOMAIN_DIAGRAM_FIELD_NAME,
                        navLabel: i18n._('Name')
                      },
                      {
                        id: DocId.DOMAIN_DIAGRAM_FIELD_DATA_TYPE,
                        navLabel: i18n._('Data type')
                      },
                      {
                        id: DocId.DOMAIN_DIAGRAM_FIELD_UNIQUE_ID,
                        navLabel: i18n._('Unique id')
                      }
                    ]
                  },
                  {
                    id: DocId.DOMAIN_DIAGRAM_OBJECT_RELATION,
                    navLabel: i18n._('Field'),
                    children: [
                      {
                        id: DocId.DOMAIN_DIAGRAM_FIELD_RELATION_TYPE,
                        navLabel: i18n._('Relation type')
                      },
                      {
                        id: DocId.DOMAIN_DIAGRAM_FIELD_AGGREGATION_TYPE,
                        navLabel: i18n._('Aggregation type')
                      },
                      {
                        id: DocId.DOMAIN_DIAGRAM_FIELD_MISSING_EDGE_OUT,
                        navLabel: i18n._('Missing edge out')
                      }
                    ]
                  }
                ]
              },
              {
                id: DocId.DOMAIN_CAPTURE_STRUCTURE,
                icon: ['fas', 'camera-polaroid'],
                navLabel: i18n._('Capture structure'),
                children: [
                  {
                    id: DocId.DOMAIN_CAPTURE_STRUCUTRE_TYPE,
                    navLabel: i18n._('Type')
                  },
                  {
                    id: DocId.DOMAIN_CAPTURE_STRUCUTRE_EMBEDDED,
                    navLabel: i18n._('Embedded')
                  },
                  {
                    id: DocId.DOMAIN_CAPTURE_STRUCUTRE_DATA,
                    navLabel: i18n._('Data')
                  }
                ]
              }
            ]
          },

          // QUERY DATA
          {
            id: DocId.DOMAIN_QUERY,
            icon: ['fas', 'search'],
            navLabel: i18n._('Query data'),
            children: [
              {
                id: DocId.DOMAIN_QUERY_ENTITY,
                icon: ['fas', 'search'],
                navLabel: i18n._('Entity/ document'),
                children: [
                  {
                    id: DocId.DOMAIN_QUERY_ENTITY_SELECT_ENTITY,
                    navLabel: i18n._('Entity')
                  },
                  {
                    id: DocId.DOMAIN_QUERY_ENTITY_QUERY,
                    navLabel: i18n._('Solr query object')
                  },
                  {
                    id: DocId.DOMAIN_QUERY_ENTITY_RESULT,
                    navLabel: i18n._('Solr result')
                  }
                ]
              },
              {
                id: DocId.DOMAIN_DOCUMENT_HISTORY,
                icon: ['fas', 'history'],
                navLabel: i18n._('Document history'),
                children: [
                  {
                    id: DocId.DOMAIN_DOCUMENT_HISTORY_SEARCH_ID,
                    navLabel: i18n._('Search id')
                  },
                  {
                    id: DocId.DOMAIN_DOCUMENT_HISTORY_SEARCH_DEPTH,
                    navLabel: i18n._('Search depth')
                  },
                  {
                    id: DocId.DOMAIN_DOCUMENT_HISTORY_DATE_OF_CHANGE,
                    navLabel: i18n._('Date of change')
                  },
                  {
                    id: DocId.DOMAIN_DOCUMENT_HISTORY_USER_ID,
                    navLabel: i18n._('User id')
                  },
                  {
                    id: DocId.DOMAIN_DOCUMENT_HISTORY_OBJECT_DOMAINID,
                    navLabel: i18n._('Object domainId')
                  },
                  {
                    id: DocId.DOMAIN_DOCUMENT_HISTORY_ID,
                    navLabel: i18n._('Id / domainId')
                  },
                  {
                    id: DocId.DOMAIN_DOCUMENT_HISTORY_OBJECT_TYPE,
                    navLabel: i18n._('Object type')
                  },
                  {
                    id: DocId.DOMAIN_DOCUMENT_HISTORY_CUSTOM_TYPE,
                    navLabel: i18n._('Custom type')
                  },
                  {
                    id: DocId.DOMAIN_DOCUMENT_HISTORY_DIFF,
                    navLabel: i18n._('Diff')
                  },
                  {
                    id: DocId.DOMAIN_DOCUMENT_HISTORY_OLD_JSON,
                    navLabel: i18n._('Old JSON')
                  }
                ]
              },
              {
                id: DocId.DOMAIN_QUERY_METADATA,
                icon: ['fas', 'code'],
                navLabel: i18n._('Metadata'),
                children: [
                  {
                    id: DocId.DOMAIN_METADATA_SEARCH_ENTITY,
                    navLabel: i18n._('Search entity')
                  }
                ]
              }
            ]
          },

          {
            id: DocId.DOMAIN_CONNECTIONS,
            icon: ['fas', 'sync'],
            navLabel: i18n._('Connections'),
            children: [
              {
                id: DocId.DOMAIN_CONNECTIONS_FTP,
                navLabel: i18n._('FTP Connections'),
                icon: ['fas', 'exchange'],
                children: [
                  {
                    id: DocId.DOMAIN_CONNECTIONS_FTP_ID,
                    navLabel: i18n._('Id')
                  },
                  {
                    id: DocId.DOMAIN_CONNECTIONS_FTP_NAME,
                    navLabel: i18n._('Name')
                  },
                  {
                    id: DocId.DOMAIN_CONNECTIONS_FTP_SFTP,
                    navLabel: i18n._('SFTP')
                  },
                  {
                    id: DocId.DOMAIN_CONNECTIONS_FTP_REMOTE_HOST,
                    navLabel: i18n._('Remote host')
                  },
                  {
                    id: DocId.DOMAIN_CONNECTIONS_FTP_REMOTE_DIRECTORY,
                    navLabel: i18n._('Remote directory')
                  },
                  {
                    id: DocId.DOMAIN_CONNECTIONS_FTP_USERANME,
                    navLabel: i18n._('User name')
                  },
                  {
                    id: DocId.DOMAIN_CONNECTIONS_FTP_PASSWORD,
                    navLabel: i18n._('Password')
                  }
                ]
              }
            ]
          }
        ]
      },

      {
        id: DocId.SCHEDULER,
        icon: ['fas', 'clock'],
        navLabel: i18n._('Scheduler'),
        children: [
          {
            id: DocId.SCHEDULER_TRIGGER,
            icon: ['fas', 'clock'],
            navLabel: i18n._('Trigger'),
            children: [
              {
                id: DocId.SCHEDULER_TRIGGER_ID,
                navLabel: i18n._('Id')
              },
              {
                id: DocId.SCHEDULER_TRIGGER_NAME,
                navLabel: i18n._('Name')
              },
              {
                id: DocId.SCHEDULER_TRIGGER_ACTIVE,
                navLabel: i18n._('active')
              },
              {
                id: DocId.SCHEDULER_TRIGGER_TASK_DOMAIN,
                navLabel: i18n._('Task domain')
              },
              {
                id: DocId.SCHEDULER_TRIGGER_TASK_SCHEME,
                navLabel: i18n._('Task scheme')
              },
              {
                id: DocId.SCHEDULER_TRIGGER_TASK_NAME,
                navLabel: i18n._('Task name')
              },
              {
                id: DocId.SCHEDULER_TRIGGER_TASK_TYPE,
                navLabel: i18n._('Task type')
              },
              {
                id: DocId.SCHEDULER_TRIGGER_START_TIME,
                navLabel: i18n._('Start time')
              },
              {
                id: DocId.SCHEDULER_TRIGGER_PERIODICITY,
                navLabel: i18n._('Periodicity')
              },
              {
                id: DocId.SCHEDULER_TRIGGER_TIME_VALUE,
                navLabel: i18n._('Time value')
              }
            ]
          }
        ]
      }
    ]
  }
];

const SUPERADMIN_DOCS = {
  id: DocId.USERADMIN,
  icon: ['fas', 'user-frineds'],
  navLabel: i18n._('User and Privileges'),
  children: [
    {
      id: DocId.USERADMIN_USER,
      icon: ['fas', 'user'],
      navLabel: i18n._('User and Privileges'),
      children: [
        {
          id: DocId.USERADMIN_USER,
          navLabel: i18n._('User'),
          children: [
            {
              id: DocId.USERADMIN_USER_ID,
              navLabel: i18n._('Id')
            },
            {
              id: DocId.USERADMIN_USER_USERNAME,
              navLabel: i18n._('Username')
            },
            {
              id: DocId.USERADMIN_USER_0RGANISATIONS,
              navLabel: i18n._('Organisations')
            }
          ]
        },
        {
          id: DocId.USERADMIN_ORG,
          navLabel: i18n._('Organisation'),
          children: [
            {
              id: DocId.USERADMIN_ORG_ID,
              navLabel: i18n._('Id')
            },
            {
              id: DocId.USERADMIN_ORG_NAME,
              navLabel: i18n._('Name')
            },
            {
              id: DocId.USERADMIN_ORG_DESCRIPTION,
              navLabel: i18n._('Description')
            },
            {
              id: DocId.USERADMIN_ORG_ADMINS,
              navLabel: i18n._('Administrators')
            }
          ]
        }
      ]
    }
  ]
};

const walkConfig = (_config: DocConfig, route: string, idPath: string[], objPath: DocConfig[]) => {
  const config = clone(_config);
  const newPath = `${route}/${getRouteFromDocId(config.id)}`;
  config.path = newPath;
  const newIdPath = [...idPath, config.id];
  config.idPath = newIdPath;
  const newObjPath = [...objPath, config];

  // config.objPath = newObjPath;
  if (config.children) {
    const children: DocConfig[] = [];
    config.children?.forEach(child => {
      children.push(walkConfig(clone(child), newPath, newIdPath, newObjPath));
    });
    config.children = children;
  }

  return config;
};

const getDocsConfig = (isSuperadmin?: boolean): DocConfig[] => {
  const enrichedConfig: DocConfig[] = [];
  const config = [...DOCS_CONFIG];

  if (isSuperadmin) {
    config[0].children = concat(config[0].children, SUPERADMIN_DOCS);
  }

  config.forEach(c => {
    enrichedConfig.push(walkConfig(c as DocConfig, '/docs', [], []));
  });

  return enrichedConfig;
};

export default getDocsConfig;
