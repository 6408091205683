import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Desktop } from '@phoenix-systems/react-layout';
import { PageProps } from '@phoenix-systems/react-layout/dist/types/components/page/page.types';
import { useHistory } from 'react-router-dom';

import ErrorInfo from '../errorInfo';
import { ErrorInfoProps } from '../errorInfo/errorInfo';
import Exception, { ExceptionProps } from '../exception/exception';
import Loader from '../loader';

import * as S from './pageSc';

import Breadcrumbs from 'components/app/components/breadcrumbs';

type CustomPageProps = PageProps & {
  error?: ErrorInfoProps | false;
  isLoading?: boolean;
  exception?: ExceptionProps | false;
  subTitle?: React.ReactNode;
  footerButtons?: React.ReactNode;
  titleTop?: React.ReactNode;
  titleBottom?: React.ReactNode;
};

const Page: React.FC<CustomPageProps> = props => {
  const { error, isLoading, exception, titleBottom, title, subTitle, footerButtons, titleTop } =
    props;
  const history = useHistory();

  const titleBt = subTitle ? <>{subTitle}</> : titleBottom || undefined;

  if (isLoading) {
    return <Loader size={40} fullHeight />;
  }

  if (error) {
    return <ErrorInfo {...error} />;
  }

  if (exception) {
    return (
      <S.StyledPage
        noPadding
        titleTop={
          <>
            {titleTop ? (
              <>{titleTop}</>
            ) : (
              <Desktop>
                <Breadcrumbs />
              </Desktop>
            )}
          </>
        }
        noScroll
        title={
          <>
            {history.length > 2 && (
              <S.BackButton
                type="link"
                icon={<FontAwesomeIcon icon={['far', 'chevron-left']} />}
                onClick={history.goBack}
              />
            )}
            {exception.title ? <>{exception.title}</> : <Trans>Page not found</Trans>}
          </>
        }
        content={<Exception {...exception} />}
      />
    );
  }

  return (
    <S.StyledPage
      {...props}
      titleTop={
        <>
          {titleTop ? (
            <>{titleTop}</>
          ) : (
            <Desktop>
              <Breadcrumbs />
            </Desktop>
          )}
        </>
      }
      title={
        <>
          {history.length > 2 && (
            <S.BackButton
              type="link"
              icon={<FontAwesomeIcon icon={['far', 'chevron-left']} />}
              onClick={history.goBack}
            />
          )}
          {title}
        </>
      }
      titleBottom={titleBt}
      footer={
        footerButtons ? (
          <S.FooterContainer>
            <S.FooterButtons>{footerButtons}</S.FooterButtons>
          </S.FooterContainer>
        ) : undefined
      }
    />
  );
};

export default Page;
