import { AxiosError } from 'axios';
import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';

import { api_getEntityHierarchy } from './hierarchy.queries';
import { EntityHierarchy, EntityHierarchyParams } from './hierarchy.types';

// eslint-disable-next-line import/prefer-default-export
export const useEntityHierarchy = (
  params: EntityHierarchyParams,
  options?: UseQueryOptions<EntityHierarchy[], AxiosError, EntityHierarchy[]>
): QueryObserverResult<EntityHierarchy[], AxiosError> =>
  useQuery<EntityHierarchy[], AxiosError>(
    ['entityHierarchy', params],
    () => api_getEntityHierarchy(params),
    options
  );
