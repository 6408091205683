import React from 'react';

import { Trans } from '@lingui/macro';
import { useStoreActions } from 'react-flow-renderer';
import { useSelector } from 'react-redux';

import Button from 'components/ui/button';
import { st_domainDiagram_getElements } from 'services/store/domainDiagram/domainDiagram.selectors';

const SelectAllAction: React.FC = () => {
  const elements = useSelector(st_domainDiagram_getElements);
  const setSelectedElements = useStoreActions(actions => actions.setSelectedElements);

  const handleSelectAll = () => {
    setSelectedElements(elements);
  };

  return (
    <Button icon={['fas', 'object-ungroup']} onClick={handleSelectAll}>
      <Trans>Select all</Trans>
    </Button>
  );
};

export default SelectAllAction;
