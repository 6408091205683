import tw, { styled, css } from 'twin.macro';

export const TestConnectionWrapper = styled.div(() => [
  css`
    ${tw`flex flex-row items-start`};

    & .ant-btn {
      ${tw`bg-light-gray-active`};
    }

    & .ant-alert {
      ${tw`py-4 ml-12 w-full`};
    }

    & .loader {
      ${tw`ml-12 h-32`};
    }
  `
]);
