/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef
} from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { TreeSelect } from 'antd';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import { useImmer } from 'use-immer';

import {
  EntityFieldSelectorProps,
  EntityFieldSelectorState,
  EntityFiledSelectorRef,
  EntityHierarchyData
} from './entityFieldSelector.types';
import { prepareEntityFieldData } from './entityFieldSelector.utils';
import * as S from './entityFieldSelectorSc';

import ErrorInfo from 'components/ui/errorInfo';
import useDomainName from 'hooks/useDomainName';
import useOnDomainSchemaChange from 'hooks/useOnDomainSchemaChange';
import useSchemaName from 'hooks/useSchemaName';
import { useEntityHierarchy } from 'services/api/domain/hierarchy/hierarchy.hooks';

const EntityFieldSelectorRenderer: ForwardRefRenderFunction<
  EntityFiledSelectorRef,
  EntityFieldSelectorProps
> = (
  {
    loadFields,
    getPopupContainer,
    onChange,
    value,
    className,
    disableEntities,
    treeDefaultExpandAll,
    hasPathInfo,
    placeholder,
    onChangePrepareValue,
    entity
  },
  ref
) => {
  const treeRef = useRef(null);
  const domainName = useDomainName();
  const schema = useSchemaName();
  const [state, setState] = useImmer<EntityFieldSelectorState>({
    data: [],
    flatData: [],
    selectedPath: undefined,
    selectedNode: undefined,
    isInitialized: false
  });

  const entitiesQuery = useEntityHierarchy({
    domainName,
    schema,
    fields: loadFields || false,
    entity
  });
  const { current: entitiesQueryRef } = useRef(entitiesQuery);

  const handleChange = (selectedValue: string) => {
    const path = selectedValue.split('.');
    const selectedNode = state.flatData.find(item => isEqual(item.path, path));

    setState(draft => {
      draft.selectedPath = selectedValue;
      draft.selectedNode = selectedNode;
    });

    let val: any = selectedNode;

    if (onChangePrepareValue) {
      val = onChangePrepareValue(selectedNode);
    }

    if (onChange) {
      onChange(val);
    }
  };

  const getNode = (nodeData: EntityHierarchyData) => (
    <TreeSelect.TreeNode
      key={nodeData.path.join('.')}
      value={nodeData.path.join('.')}
      selectable={nodeData.isSelectable}
      className={clsx([
        !nodeData.isSelectable && 'disabled',
        nodeData.isField ? 'field' : 'entity'
      ])}
      title={
        <>
          <FontAwesomeIcon
            icon={['far', nodeData.isField ? 'brackets' : 'file']}
            className="icon"
          />
          <span className="label">{nodeData.name}</span>
          {nodeData.isField && nodeData.fieldType && (
            <span className="type">[{nodeData.fieldType}]</span>
          )}
        </>
      }
    >
      {nodeData.children?.map(item => getNode(item))}
    </TreeSelect.TreeNode>
  );

  const getPlaceholder = useCallback(() => {
    if (entitiesQuery.isLoading) {
      return <Trans>Load entities...</Trans>;
    }

    if (entitiesQuery.isError) {
      return <Trans>Failed to load entities.</Trans>;
    }

    if (placeholder) {
      return placeholder;
    }

    if (loadFields) {
      return <Trans>Select field</Trans>;
    }

    return <Trans>Select entity ({state.flatData.length})</Trans>;
  }, [entitiesQuery, placeholder, loadFields, state.flatData.length]);

  const handleReset = () => {
    if (onChange) {
      onChange(undefined);
    }
    entitiesQuery.refetch();
  };

  useEffect(() => {
    if (entitiesQuery.data) {
      const parsedData = prepareEntityFieldData(entitiesQuery.data, disableEntities);

      setState(draft => {
        draft.data = parsedData.data;
        draft.flatData = parsedData.faltData;
      });

      return;
    }

    setState(draft => {
      draft.data = [];
      draft.flatData = [];
    });
  }, [entitiesQuery.data, disableEntities, setState]);

  useImperativeHandle(ref, () => ({
    element: treeRef.current,
    reset: handleReset
  }));

  useOnDomainSchemaChange(() => {
    entitiesQueryRef.refetch();
  });

  return (
    <>
      <TreeSelect
        ref={treeRef}
        className={className}
        loading={entitiesQuery.isLoading}
        value={value}
        disabled={!entitiesQuery.isSuccess}
        placeholder={getPlaceholder()}
        allowClear
        treeDefaultExpandAll={treeDefaultExpandAll}
        onChange={handleChange}
        getPopupContainer={getPopupContainer}
      >
        {state.data.map(item => getNode(item))}
      </TreeSelect>
      {state.selectedPath && hasPathInfo && (
        <S.SelectedInfo>
          <p className="not-bold path">
            <span>
              <Trans>Path</Trans>:
            </span>
            {state.selectedPath}
          </p>
          {state.selectedNode?.fieldType && (
            <p className="not-bold">
              <span>
                <Trans>Type</Trans>:
              </span>
              {state.selectedNode?.fieldType}
            </p>
          )}
        </S.SelectedInfo>
      )}

      {entitiesQuery.isError && (
        <S.StyledErrorInfo>
          <ErrorInfo
            error={entitiesQuery.error}
            message={<Trans>Failed to load domain entities.</Trans>}
          />
        </S.StyledErrorInfo>
      )}
    </>
  );
};

const EntityFieldSelector = forwardRef(EntityFieldSelectorRenderer);
EntityFieldSelector.displayName = 'EntityFieldSelector';

export default EntityFieldSelector;
