/* eslint-disable import/extensions */
import { i18n, LocaleData } from '@lingui/core';

import * as catalog_de from './locales/de/messages';
import * as catalog_en from './locales/en/messages';

import getLanguageConfig from 'config/langauge';

const makePlural = (val: LocaleData): LocaleData => val;

const initI18n = (): void => {
  i18n.load({ en: catalog_en.messages, de: catalog_de.messages });
  i18n.loadLocaleData('en', { plurals: makePlural });
  i18n.loadLocaleData('de', { plurals: makePlural });
  i18n.activate(getLanguageConfig().defaultLanguage);
};

export default initI18n;
