import { i18n } from '@lingui/core';
import { Node } from 'slate';

import {
  CreateHintParams,
  DocId,
  Hint,
  HintApiInfo,
  HintSearchResult,
  SearchHintParams
} from './hint.types';

import apiClient from 'services/api/apiClient';
import { log } from 'utils';

const TEXT_PLACEHOLDER = [
  {
    type: 'paragraph',
    // isPlaceholder: true,
    children: [
      {
        text: i18n._('Enter text...')
      }
    ]
  }
];

const COMMENTS_PLACEHOLDER = [
  {
    type: 'paragraph',
    // isPlaceholder: true,
    children: [
      {
        text: i18n._('Enter comment...')
      }
    ]
  }
];

const BASE_PATH = 'hint';

const parseData = (_data: Hint): Hint => {
  const data = { ..._data };

  // parse jsons
  if (data.text && data.text !== '[]') {
    try {
      data.parsedText = JSON.parse(data.text);
    } catch (err) {
      data.parsedText = TEXT_PLACEHOLDER;
    }
  } else {
    data.parsedText = TEXT_PLACEHOLDER;
  }

  if (data.comments && data.comments !== '[]') {
    try {
      data.parsedComments = JSON.parse(data.comments);
    } catch (error) {
      log(error);
      data.parsedComments = COMMENTS_PLACEHOLDER;
    }
  } else {
    data.parsedComments = COMMENTS_PLACEHOLDER;
  }

  return data;
};

/**
 * GET /api/hint/id/{id}
 */
export const api_getHint = async (id: DocId): Promise<Hint> => {
  const { data } = await apiClient.get(`/${BASE_PATH}/${encodeURIComponent(id)}`);

  if (data) {
    return parseData(data);
  }

  return data;
};

/**
 * PUT /api/hint
 */
export const api_updateHint = async (_payload: Hint): Promise<Hint> => {
  const payload = _payload;

  if (payload.parsedText) {
    payload.text = JSON.stringify(payload.parsedText);
  }

  if (payload.parsedComments) {
    payload.comments = JSON.stringify(payload.parsedComments);
  }

  const { data } = await apiClient.put(`/${BASE_PATH}`, payload);

  if (data) {
    return parseData(data);
  }

  return data;
};

/**
 * PUT /api/hint/comment/{elementid}
 */
export const api_updateHintComment = async (id: DocId, payload: Node[]): Promise<Hint> => {
  const { data } = await apiClient.put(`/${BASE_PATH}/comment/${id}`, JSON.stringify(payload));

  return data;
};

/**
 * POST /api/hint
 */
export const api_createHint = async (payload: CreateHintParams): Promise<Hint> => {
  const enrichedPayload: Hint = {
    ...payload,
    text: JSON.stringify(TEXT_PLACEHOLDER),
    comments: JSON.stringify(COMMENTS_PLACEHOLDER),
    plainText: '',
    keywords: []
  };

  const { data } = await apiClient.post(`/${BASE_PATH}`, enrichedPayload);

  return data;
};

/**
 * GET /api/hint/search/{lang}/{phrase}
 */
export const api_searchHints = async (params: SearchHintParams): Promise<HintSearchResult> => {
  const { data } = await apiClient.get(`/${BASE_PATH}/search/${params.language}/${params.phrase}`);

  return data;
};

/**
 * GET /api/hint/apiKey
 */
export const api_getHintApiInfo = async (): Promise<HintApiInfo> => {
  const { data } = await apiClient.get(`/${BASE_PATH}/apiKey`);

  return data;
};
