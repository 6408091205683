import { ListQueryParams } from '@phoenix-systems/react-table/dist/types/types';
import { createSelector } from 'reselect';

import { HistoryTableSingleView, HistoryTableState } from './historyTable.types';

export const historyTableState = (state: { historyTable: HistoryTableState }): HistoryTableState =>
  state.historyTable;

export const st_historyTable_getParams = createSelector(
  [historyTableState],
  (historyTable): ListQueryParams => historyTable.params
);

export const st_historyTable_getSingleView = createSelector(
  [historyTableState],
  (historyTable): HistoryTableSingleView => historyTable.singleView
);

export const st_historyTable_getSelectedKey = createSelector(
  [historyTableState],
  (historyTable): string | undefined => historyTable.selectedKey
);
