import { i18n } from '@lingui/core';
import { BreadcrumbConfig, NavigationConfig } from '@phoenix-systems/react-navigation';
import { getRoute } from '@phoenix-systems/react-router';
import { concat, remove } from 'lodash';
import { Dispatch } from 'redux';

import { getDocConfig, getDocIdFromPath } from 'config/docs/docs.utils';
import routes from 'config/routes/routes';
import { Domain } from 'services/api/domain/domain';
import { DocId } from 'services/api/domain/hint';
import history from 'services/history';
import store from 'services/store';
import { st_docs_openDrawer } from 'services/store/docs/docs.actions';
import getDomainRoute from 'utils/getDomainRoute';

export type BreadCrumbNavigationParams = {
  pathname: string;
  mainNavConfig: NavigationConfig;
  domains?: Domain[];
  append?: BreadcrumbConfig[];
  domainName?: string;
  schemaName?: string;
  isDocsDrawer?: boolean;
  dispatch?: Dispatch;
  isSuperadmin?: boolean;
};

const staticRoutePartsToRemove = ['list', 'single', 'new'];

const getSlug = (route?: string, schemaName?: string) => {
  if (!route) {
    return '';
  }

  const parts = route.split('/');

  remove(parts, n => staticRoutePartsToRemove.includes(n));

  if (schemaName) {
    remove(parts, n => n === schemaName);
  }

  if (parts.length === 0) {
    return '';
  }

  return parts[parts.length - 1];
};

const handleGotoDocsDrawer = (params: BreadCrumbNavigationParams, config: BreadcrumbConfig) => {
  if (params.isDocsDrawer && params.dispatch && config.data) {
    params.dispatch(st_docs_openDrawer(config.data.id));
  }
};

const walkNav = (
  navItem: NavigationConfig,
  parts: string[],
  level: number,
  anchestors: BreadcrumbConfig[],
  breadcrumbs: BreadcrumbConfig[],
  params?: BreadCrumbNavigationParams
): BreadcrumbConfig[] => {
  if (
    getSlug(navItem.route, params?.schemaName) === parts[level] ||
    navItem.id === 'main-navigation'
  ) {
    const dcId = getDocIdFromPath(navItem.id) as DocId;
    let data;

    if (dcId) {
      data = getDocConfig(dcId);
    }

    let brObj: BreadcrumbConfig = {
      breadcrumbName: navItem.title || '',
      path: navItem.route || '',
      children: anchestors,
      onClick: params?.isDocsDrawer ? config => handleGotoDocsDrawer(params, config) : undefined,
      data
    };

    if (navItem.id === 'main-navigation') {
      brObj = {
        breadcrumbName: i18n._('Big data service'),
        path: '/'
      };
    }

    let match: NavigationConfig | undefined;
    const anch: BreadcrumbConfig[] = [];

    navItem.children?.forEach(child => {
      if (getSlug(child.route, params?.schemaName) === parts[level + 1]) {
        match = child;
      } else if (!child.isDisabled) {
        const childDcId = getDocIdFromPath(child.id) as DocId;
        let childData;

        if (childDcId) {
          childData = getDocConfig(childDcId);
        }

        anch.push({
          breadcrumbName: child.title || '',
          path: child.route || '',
          onClick: params?.isDocsDrawer
            ? config => handleGotoDocsDrawer(params, config)
            : undefined,
          data: childData
        });
      }
    });

    if (match) {
      walkNav(match, parts, level + 1, anch, breadcrumbs, params);
    }

    if (level !== 0) {
      breadcrumbs.unshift(brObj);
    }
  }

  return breadcrumbs;
};

export const getBreadcrumbsConfig = (
  params: BreadCrumbNavigationParams
): BreadcrumbConfig[] | void => {
  try {
    const parts = params.pathname.replace('/', '').split('/');
    const prepend: BreadcrumbConfig[] = [];

    switch (parts[0]) {
      case 'domain': {
        const prependChildren: BreadcrumbConfig[] = [
          {
            breadcrumbName: i18n._('Dashboard'),
            path: routes.dashboard.root
          }
        ];

        if (params.isSuperadmin) {
          prependChildren.push({
            breadcrumbName: i18n._('User and Privileges'),
            path: routes.userAdmin.user.list
          });
        }

        prependChildren.push({
          breadcrumbName: i18n._('Scheduler'),
          path: routes.scheduler.list
        });

        prependChildren.push({
          breadcrumbName: i18n._('Docs'),
          path: routes.docs.root
        });

        prepend.push({
          breadcrumbName: i18n._('Domain'),
          path: routes.domain.root,
          children: prependChildren
        });

        const domainChildren: BreadcrumbConfig[] = [];
        params.domains?.forEach(domain => {
          if (domain.domainName !== parts[1]) {
            domainChildren.push({
              breadcrumbName: domain.domainName,
              path: getRoute(routes.domain.overview, domain.domainName),
              onClick: () => {
                const { route } = store.getState().domain;
                const { routeParams } = store.getState().domain;

                if (route) {
                  const newRoute = getDomainRoute(
                    route,
                    domain.domainName,
                    domain.defaultSchema,
                    routeParams
                  );
                  history.push(newRoute);
                }
              }
            });
          }
        });

        prepend.push({
          breadcrumbName: parts[1],
          path: getRoute(routes.domain.overview, parts[1]),
          children: domainChildren
        });

        parts.splice(0, 1);

        break;
      }

      case 'scheduler': {
        const rootChildren: BreadcrumbConfig[] = [
          {
            breadcrumbName: i18n._('Dashboard'),
            path: routes.dashboard.root
          }
        ];

        if (params.domainName && !params.isDocsDrawer) {
          rootChildren.push({
            breadcrumbName: i18n._(`Domain (${params.domainName})`),
            path: getRoute(routes.domain.overview, params.domainName)
          });
        }

        if (params.isSuperadmin) {
          rootChildren.push({
            breadcrumbName: i18n._('User and Privileges'),
            path: routes.userAdmin.user.list
          });
        }

        rootChildren.push({
          breadcrumbName: i18n._('Docs'),
          path: getRoute(routes.docs.root)
        });

        prepend.push({
          breadcrumbName: i18n._('Scheduler'),
          path: routes.scheduler.root,
          children: !params.isDocsDrawer ? rootChildren : undefined,
          onClick: params.isDocsDrawer ? config => handleGotoDocsDrawer(params, config) : undefined
          // data: getDocConfig(DocId.BIG_DATA_SERVICE)
        });

        break;
      }

      case 'user-admin': {
        const rootChildren: BreadcrumbConfig[] = [
          {
            breadcrumbName: i18n._('Dashboard'),
            path: routes.dashboard.root
          }
        ];

        if (params.domainName && !params.isDocsDrawer) {
          rootChildren.push({
            breadcrumbName: i18n._(`Domain (${params.domainName})`),
            path: getRoute(routes.domain.overview, params.domainName)
          });
        }

        rootChildren.push({
          breadcrumbName: i18n._('Scheduler'),
          path: getRoute(routes.docs.root)
        });

        rootChildren.push({
          breadcrumbName: i18n._('Docs'),
          path: getRoute(routes.docs.root)
        });

        prepend.push({
          breadcrumbName: i18n._('User and Privileges'),
          path: routes.userAdmin.user.list,
          children: !params.isDocsDrawer ? rootChildren : undefined,
          onClick: params.isDocsDrawer ? config => handleGotoDocsDrawer(params, config) : undefined
          // data: getDocConfig(DocId.BIG_DATA_SERVICE)
        });

        break;
      }

      case 'docs': {
        const rootChildren: BreadcrumbConfig[] = [
          {
            breadcrumbName: i18n._('Dashboard'),
            path: routes.dashboard.root
          }
        ];

        if (params.domainName && !params.isDocsDrawer) {
          if (params.domainName && !params.isDocsDrawer) {
            rootChildren.push({
              breadcrumbName: i18n._(`Domain (${params.domainName})`),
              path: getRoute(routes.domain.overview, params.domainName)
            });
          }
        }

        if (params.isSuperadmin) {
          rootChildren.push({
            breadcrumbName: i18n._('User and Privileges'),
            path: routes.userAdmin.user.list
          });
        }

        rootChildren.push({
          breadcrumbName: i18n._('Scheduler'),
          path: getRoute(routes.scheduler.list)
        });

        prepend.push({
          breadcrumbName: i18n._('Docs'),
          path: '/docs',
          children: !params.isDocsDrawer ? rootChildren : undefined,
          onClick: params.isDocsDrawer ? config => handleGotoDocsDrawer(params, config) : undefined,
          data: getDocConfig(DocId.BIG_DATA_SERVICE, params.isSuperadmin)
        });

        parts.splice(0, 1);

        break;
      }

      default:
        break;
    }

    // remove parts which are dynamic like schemaName and ignores sub routes so that its working :-)
    remove(parts, n => staticRoutePartsToRemove.includes(n));

    if (params.schemaName && parts.includes(params.schemaName)) {
      remove(parts, n => n === params.schemaName);
    }

    const breadcrumbs = walkNav(params.mainNavConfig, parts, 0, [], [], params);

    return concat(prepend || [], breadcrumbs, params.append || []);
  } catch (err) {
    // do nothing error is catched on parent
  }

  return undefined;
};

export default getBreadcrumbsConfig;
