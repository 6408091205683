import { Button, Tabs } from 'antd';
import tw, { styled, css } from 'twin.macro';

export const StyledTable = styled.div(() => [
  css`
    ${tw` relative h-full text-13`};

    & > div {
      ${tw`relative w-full h-full`};
    }

    & .ant-table-thead > tr > th {
      ${tw`font-600 bg-light-gray border-b border-ant-border-gray`};
    }

    & .ant-table-tbody > tr.ant-table-row-selected > td {
      ${tw` bg-light-gray-active-200`};
    }

    & .ant-table-tbody > tr.ant-table-row:not(.ant-table-row-selected):hover > td {
      ${tw` bg-light-gray-300`};
    }

    & .table-top-nav {
      ${tw`mb-0`};
    }

    & .table-content-separator .summary {
      ${tw`flex flex-row items-center mb-2`};

      & .selection-info {
        ${tw`mb-0`};
      }
    }

    & .ant-table.ant-table-small .ant-table-tbody > tr > td {
      ${tw` font-500 cursor-default`};

      &.bold {
        ${tw` font-700`};
      }

      &.no-wrap {
        ${tw`whitespace-nowrap`}
      }

      & .edit-btn {
        ${tw`ml-4 p-0 w-16`}
      }
    }

    & .top-nav-left {
      & .ant-input-search {
        min-width: auto;
      }
    }

    & .table-top-nav .actions-left {
      ${tw`w-full`};
    }

    & .table-top-nav .actions-right {
      ${tw`ml-12`};
    }

    & .table-top-nav .ant-input-group-addon {
      left: 0 !important;
    }

    & mark {
      ${tw`bg-yellow-200 p-0`}
    }

    & .ant-table.ant-table-small .ant-table-thead > tr > th.actions {
      ${tw`text-center`}
    }

    & .ant-table.ant-table-small .ant-table-thead > tr > th .setting-icon {
      ${tw`text-gray-400 text-16 mt-2`}
    }

    & .single-container-inner > div {
      ${tw`flex flex-col h-full`}
    }

    & .ant-pagination.ant-table-pagination.ant-table-pagination-right {
      & .ant-pagination-item {
        ${tw`font-500 hover:bg-light-gray border-ant-border-gray mr-4`}
        & a {
          ${tw`ml-0 text-gray-500`}
        }
      }

      & .ant-pagination-item-active {
        ${tw`bg-light-gray-active hover:bg-light-gray-active border-primary `};
        & a {
          ${tw`text-primary`}
        }
      }

      /*  & .ant-pagination-item-link::not(.ant-pagination-jump-next) {
        ${tw`font-500 hover:bg-light-gray border-ant-border-gray mx-4`}
        .anticon {
          transform: translateY(-2px);
        }
      } */

      & .ant-select-selection-item {
        ${tw`font-500`}
      }

      & .ant-pagination-next {
        ${tw`mr-6`}
      }

      & .ant-table-thead th.ant-table-column-has-sorters {
        ${tw`bg-light-gray-active`}
        background: red !important
      }
    }

    &.domain {
      & .table-top-nav.actions-left {
        ${tw`mr-12`}
        width: unset;
      }
    }

    & .table-scroll div:nth-child(2) {
      /*  visibility: hidden; */
    }

    &.document-history-table {
      & .table-top-nav {
        ${tw`items-start`};
      }

      & .table-top-nav.total-count {
        ${tw`items-center`};
      }
    }

    &.document-history-table {
    }
  `
]);

export const SingleTitle = styled.div(() => [
  css`
    ${tw`py-6 flex flex-row mt-4 items-center`};
    & h4 {
      ${tw`text-18 font-700 text-primary  h-32 px-6 mr-8 w-full whitespace-nowrap overflow-hidden`};
      text-overflow: ellipsis;
    }
  `
]);

export const SingleTitleRight = styled.div(() => [
  css`
    ${tw`flex flex-row items-center ml-auto`};
    & h4 {
      ${tw`text-18 font-700 text-primary  h-32 px-6 mr-8`};
    }
  `
]);

export const SingleContent = styled.div(() => [
  css`
    ${tw`h-full py-6 relative`};
  `
]);

export const SingleNav = styled.div(() => [
  css`
    ${tw``};
  `
]);

export const TabNavigation = styled(Tabs)(() => [
  css`
    ${tw`mb-0 `};

    & .ant-tabs-nav {
      ${tw`mb-0`};
    }

    & .ant-tabs-tab {
      ${tw`py-8`};
    }

    & .ant-tabs-tab .ant-tabs-tab-btn {
      ${tw`font-600`};

      & svg {
        ${tw`mr-6`};
      }
    }
  `
]);

export const ActionBtn = styled(Button)(() => [
  css`
    & .angle-down {
      ${tw`ml-8`};
    }

    /*  &.icon-btn {
      ${tw`text-gray-600 hover:text-primary`};
    } */
  `
]);

type SingleContentContainerProps = {
  fullHeight?: boolean;
};

export const SingleContentContainer = styled.div<SingleContentContainerProps>(({ fullHeight }) => [
  css`
    ${tw`pt-16 relative overflow-hidden`};
  `,
  fullHeight &&
    css`
      ${tw`h-full pt-0`};
      height: calc(100% - 64px);
    `
]);

export const EditBtn = styled(Button)(() => [
  css`
    ${tw`ml-8 text-gray-300 hover:text-primary border-none bg-transparent hover:bg-transparent`};

    & svg {
      ${tw`text-14`};
    }
  `
]);
