import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Alert, Modal } from 'antd';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import * as D from 'components/_styled/dialogSc';
import Button from 'components/ui/button';
import ErrorInfo from 'components/ui/errorInfo';
import routes from 'config/routes/routes';
import useDefaultModalProps from 'hooks/useDefaultModalProps';
import useDomainName from 'hooks/useDomainName';
import useFtpConnectionName from 'hooks/useFtpConnectionName';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';
import useSchemaName from 'hooks/useSchemaName';
import {
  api_deleteFtpConnection,
  DeleteFtpConnectionParams,
  FTPConnection
} from 'services/api/domain/ftpConnection';
import { st_ftpConnectionsTable_setSelectedKey } from 'services/store/ftpConnectionsTable/ftpConnectionsTable.actions';

type DeleteFtpConnectionDialogProps = {
  isOpen: boolean;
  data: FTPConnection;
  close: () => void;
};

const DeleteFtpConnectionDialog: React.FC<DeleteFtpConnectionDialogProps> = ({
  data,
  isOpen,
  close
}) => {
  const queryClient = useQueryClient();
  const domainName = useDomainName();
  const schemaName = useSchemaName();
  const dispatch = useDispatch();
  const ftpConnectionName = useFtpConnectionName();
  const goto = useGoToDomainRoute();
  const modalProps = useDefaultModalProps();

  const deleteMutation = useMutation<void, AxiosError, DeleteFtpConnectionParams>(
    (params: DeleteFtpConnectionParams) => api_deleteFtpConnection(params)
  );

  const handleDelete = () => {
    deleteMutation.mutate({ domainName, schemaName, id: data.id });
  };

  const handleClose = () => {
    close();

    if (deleteMutation.isSuccess) {
      dispatch(st_ftpConnectionsTable_setSelectedKey(undefined));

      if (ftpConnectionName) {
        goto(routes.domain.connections.ftp.list);

        return;
      }

      queryClient.refetchQueries(['ftpConnections']);
    }

    setTimeout(() => {
      deleteMutation.reset();
    }, 500);
  };

  return (
    <Modal
      {...modalProps}
      visible={isOpen}
      title={
        <>
          <FontAwesomeIcon icon={['fas', 'trash']} />
          <Trans>Delete FTP connection {data.name}</Trans>
        </>
      }
      footer={
        <>
          <Button
            action={deleteMutation.isSuccess ? 'close' : 'cancel'}
            disabled={deleteMutation.isLoading}
            onClick={handleClose}
          />
          {!deleteMutation.isSuccess && (
            <Button
              action="delete"
              loading={deleteMutation.isLoading}
              disabled={deleteMutation.isLoading || deleteMutation.isSuccess}
              onClick={handleDelete}
            >
              <Trans>Delete FTP connection</Trans>
            </Button>
          )}
        </>
      }
    >
      <p>
        Do you want to delete the FTP connection <b>{data.name}</b>?
      </p>

      <D.ResponseMessage>
        {deleteMutation.isError && (
          <ErrorInfo
            message={<Trans>Failed to delte FTP connection.</Trans>}
            error={deleteMutation.error}
          />
        )}
        {deleteMutation.isSuccess && (
          <Alert
            type="success"
            message={<Trans>Successfully deleted FTP connection.</Trans>}
            showIcon
          />
        )}
      </D.ResponseMessage>
    </Modal>
  );
};

export default DeleteFtpConnectionDialog;
