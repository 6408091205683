import React, { useEffect, useRef, useState } from 'react';

import { EdgeProps, getMarkerEnd, getSmoothStepPath } from 'react-flow-renderer';
import { useDispatch, useSelector } from 'react-redux';
import { CSSProperties } from 'styled-components';

import { getSelfReferencePath } from './entityEdge.utils';

import { EntityEdgeData } from 'services/api/domain/domainDiagram';
import {
  st_domainDiagram_setActiveEdge,
  st_domainDiagram_setActiveHandles,
  st_domainDiagram_setContextEdge
} from 'services/store/domainDiagram/domainDiagram.actions';
import { st_domainDiagram_getContextEdge } from 'services/store/domainDiagram/domainDiagram.selectors';
import appStyles from 'styles/appStyles';

const EntityEdge: React.FC<EdgeProps<EntityEdgeData>> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  arrowHeadType,
  markerEndId,
  source,
  target,
  sourceHandleId,
  targetHandleId
  // style
}) => {
  const pathRef = useRef(null);
  const [isHovered, setHovered] = useState(false);
  const [isActive, setActive] = useState(false);
  const dispatch = useDispatch();
  const contextEdgeId = useSelector(st_domainDiagram_getContextEdge)?.id;
  const isSelfReferencial = data?.sourceNode?.id === data?.targetNode?.id;
  const pathParams = { sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition };
  const edgePath = isSelfReferencial
    ? getSelfReferencePath(pathParams)
    : getSmoothStepPath(pathParams);

  const styleDef: CSSProperties = {
    stroke: appStyles.colors.secondary,
    strokeWidth: 1
  };

  const hoveredStyle: CSSProperties = {
    stroke: appStyles.colors.secondary,
    strokeWidth: 1,
    zIndex: 1001
  };

  const bgStyle: CSSProperties = {
    stroke: 'white',
    strokeWidth: 6,
    strokeDasharray: 0,
    fill: 'transparent',
    animation: 'none'
  };

  const bgStyleHovered: CSSProperties = {
    stroke: appStyles.colors.lightGrayActive,
    // stroke: '#ffffd4',
    strokeWidth: 6,
    strokeOpacity: 0.6,
    strokeDasharray: 0,
    fill: 'transparent',
    animation: 'none',
    zIndex: 1000
  };

  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

  // (event: React.MouseEvent<SVGPathElement>) =>
  const handleMouseEnter = () => {
    setHovered(true);

    if (sourceHandleId && targetHandleId) {
      dispatch(st_domainDiagram_setActiveHandles([sourceHandleId, targetHandleId]));
    }
    dispatch(st_domainDiagram_setActiveEdge(id));
  };

  const handleMouseOut = () => {
    setHovered(false);
    dispatch(st_domainDiagram_setActiveHandles([]));
    dispatch(st_domainDiagram_setActiveEdge(undefined));
  };

  const handleContextNavigation = (event: React.MouseEvent<SVGPathElement>) => {
    event.preventDefault();
    dispatch(st_domainDiagram_setActiveEdge(undefined));
    dispatch(
      st_domainDiagram_setContextEdge(
        { id, source, target, data },
        { x: event.clientX + 10, y: event.clientY + 10 }
      )
    );

    setActive(true);
  };

  useEffect(() => {
    if (!contextEdgeId && isActive) {
      setActive(false);
    }
  }, [contextEdgeId, isActive]);

  return (
    <>
      <path
        onMouseEnter={handleMouseEnter}
        onMouseOut={handleMouseOut}
        onContextMenu={handleContextNavigation}
        ref={pathRef}
        // className="react-flow__edge-path"
        d={edgePath}
        id={id}
        markerEnd={markerEnd}
        style={isHovered || isActive ? bgStyleHovered : bgStyle}
      />

      <path
        onMouseEnter={handleMouseEnter}
        onMouseOut={handleMouseOut}
        onContextMenu={handleContextNavigation}
        ref={pathRef}
        className="react-flow__edge-path"
        d={edgePath}
        id={id}
        markerEnd={markerEnd}
        style={isHovered || isActive ? hoveredStyle : styleDef}
      />
    </>
  );
};

export default EntityEdge;
