import { getRoute } from '@phoenix-systems/react-router';

import getQueryParamsString from './getQueryParamsString';

import { DomainRouteParams } from 'index.types';

const getDomainRoute = (
  route: string,
  domainName: string,
  schemaName?: string,
  params?: DomainRouteParams
): string => {
  let path = '';

  if (schemaName) {
    if (!params?.path) {
      path = getRoute(route, domainName, schemaName);
    } else {
      path = getRoute(route, domainName, schemaName, params.path);
    }
  } else {
    // eslint-disable-next-line no-lonely-if
    if (!params?.path) {
      path = getRoute(route, domainName);
    } else {
      path = getRoute(route, domainName, params.path);
    }
  }

  if (params?.query) {
    path += `?${getQueryParamsString(params.query)}`;
  }

  return path;
};

export default getDomainRoute;
