import React, { useEffect } from 'react';

import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import { FormItem, useFormContext, CollectionEditorDialogProps } from '@phoenix-systems/react-form';
import { Form, Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { clone, keys } from 'lodash';
import { useImmer } from 'use-immer';

import EntityFieldSelector from '../entityFieldSelector';

import * as S from './csvImportFormSc';

import * as F from 'components/_styled/formSc';
import useContainer from 'components/app/components/containerProvider/useContainer';
import TooltipLabel from 'components/docs/components/docsTooltip/tooltipLabel';
import Button from 'components/ui/button';
import useDefaultModalProps from 'hooks/useDefaultModalProps';
import { ColumnEntityMapping, CsvFileHeaderInfo } from 'services/api/domain/csvImport';
import { DocId } from 'services/api/domain/hint';

const { Option } = Select;

type CsvImportMapperDialogProps = CollectionEditorDialogProps<Partial<ColumnEntityMapping>> & {
  selectedEntity?: string;
  csvHeaderData?: CsvFileHeaderInfo;
};

type ColData = {
  colNumber: number;
  colName: string;
};

type CsvImportMapperDialogState = {
  colData: ColData[];
};

const CsvImportMapperDialog: React.FC<CsvImportMapperDialogProps> = ({
  data,
  isOpen,
  onClose,
  insertData,
  selectedEntity,
  csvHeaderData
}) => {
  const { dialogContainer } = useContainer();
  const [form] = Form.useForm<ColumnEntityMapping>();
  const [formState, formActions] = useFormContext(form, data, data !== undefined);
  const [state, setState] = useImmer<CsvImportMapperDialogState>({
    colData: []
  });
  const modalProps = useDefaultModalProps();

  const handleClose = () => {
    onClose();

    const tm = setTimeout(() => {
      formActions.reset(data, data !== undefined);
    }, 500);

    return () => {
      clearTimeout(tm);
    };
  };

  const handleInsert = () => {
    form.validateFields().then(formData => {
      const item = state.colData.find(dt => dt.colNumber === formData.columnNumber);

      if (item) {
        insertData({
          columnNumber: item.colNumber,
          columnName: item.colName,
          field: formData.field
        });
      }

      handleClose();
    });
  };

  useEffect(() => {
    const colData: ColData[] = [];

    if (csvHeaderData) {
      const ks = keys(csvHeaderData);
      ks.forEach(key => {
        colData.push({
          colName: key,
          colNumber: csvHeaderData[key]
        });
      });

      setState(draft => {
        draft.colData = colData;
      });

      return;
    }

    setState(draft => {
      draft.colData = [];
    });
  }, [csvHeaderData, setState]);

  useEffect(() => {
    if (data) {
      formActions.reset(data, true);

      return;
    }

    formActions.reset();
  }, [data, formActions]);

  return (
    <Modal
      {...modalProps}
      title={
        !data ? <Trans>Add column entity mapper</Trans> : <Trans>Edit column entity mapper</Trans>
      }
      visible={isOpen}
      footer={
        <>
          <Button onClick={handleClose} action="cancel" />
          <Button action="save" onClick={handleInsert} formState={formState} />
        </>
      }
    >
      <F.StyledForm
        form={form}
        name="column-entity-mapper-form"
        onFieldsChange={formActions.onFieldsChange}
        onValuesChange={formActions.onValuesChange}
        $labelWidth={92}
        colon={false}
        initialValues={data}
      >
        <FormItem
          name="field"
          label={
            <TooltipLabel
              docId={DocId.DOMAIN_IMPORT_FROM_CSV_COLUMN_ENTITY_MAPPER_FIELD}
              label={<Trans>Field</Trans>}
            />
          }
          messageVariables={{ name: i18n._('Field') }}
          rules={[{ required: true }]}
          registerField={formActions.registerField}
        >
          <EntityFieldSelector
            entity={selectedEntity}
            getPopupContainer={() => dialogContainer}
            loadFields
            disableEntities
            hasPathInfo
            treeDefaultExpandAll
            onChangePrepareValue={nodeData => {
              if (nodeData) {
                const p = clone(nodeData.path);
                p.splice(0, 1);

                return p.join('.');
              }

              return undefined;
            }}
          />
        </FormItem>
        <FormItem
          label={
            <TooltipLabel
              docId={DocId.DOMAIN_IMPORT_FROM_CSV_COLUMN_ENTITY_MAPPER_COLUMN}
              label={<Trans>Column</Trans>}
            />
          }
          messageVariables={{ name: i18n._('Column') }}
          name="columnNumber"
          rules={[{ required: true }]}
          registerField={formActions.registerField}
        >
          <Select
            getPopupContainer={() => dialogContainer}
            placeholder={<Trans>Select column</Trans>}
          >
            {state.colData.map(item => (
              <Option value={item.colNumber} key={item.colNumber}>
                <S.OptionValue>
                  {item.colName} <span>[{item.colNumber}]</span>
                </S.OptionValue>
              </Option>
            ))}
          </Select>
        </FormItem>
      </F.StyledForm>
    </Modal>
  );
};

export default CsvImportMapperDialog;
