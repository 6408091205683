import tw, { css, styled } from 'twin.macro';

export const TableMapperItem = styled.div(() => [
  css`
    /*  ${tw`py-8`} */

    & span {
      ${tw`text-12 text-primary inline-block`};
      min-width: 5.5rem;
    }
  `
]);

export const UploadStrategy = styled.div(() => [
  css`
    ${tw`bg-light-gray-300 border border-ant-border-gray px-12 py-8 pb-12 rounded-4 `}

    & p {
      ${tw`font-600 mb-12`}
    }

    & .ant-radio-wrapper span {
      ${tw`font-600 text-primary`}
    }
  `
]);

export const FileUpload = styled.div(() => [
  css`
    ${tw``}

    & .ant-btn {
      ${tw`bg-light-gray-active mt-12`}
    }
  `
]);

export const FilesListInfo = styled.p(() => [
  css`
    ${tw`font-500 mb-8`}

    & svg {
      ${tw`mr-6`}
    }
  `
]);

export const CsvHeaderInfo = styled.p(() => [
  css`
    ${tw`text-12`}
    max-width: 60rem;

    & p {
      ${tw`text-12 font-600 text-primary mb-12 border-b border-primary pb-6 mt-6`}
    }

    & li {
      ${tw` my-4 border-b border-ant-border-gray py-4`}
      min-width: 20rem;

      &:last-of-type {
        ${tw`border-transparent`}
      }
    }
  `
]);

export const OptionValue = styled.span(() => [
  css`
    ${tw`font-500`}

    & span {
      ${tw`font-mono text-12 text-gray-600 inline-block`}
      transform: translateY(-1px);
      opacity: 0.6 !important;
    }
  `
]);
