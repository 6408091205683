import { Divider } from 'antd';
import tw, { styled, css } from 'twin.macro';

export const NavWrapper = styled.div(() => [
  css`
    ${tw`text-16 font-700 h-full `};
    & .ant-menu-item,
    .ant-menu-submenu-title {
      ${tw`text-16 font-700`};
    }

    & .ant-menu-item.ant-menu-item-selected::after {
      ${tw`border-none`};
    }

    & .inner {
      ${tw`pb-24`};
    }
  `
]);

export const SubTitle = styled.h4(() => [
  css`
    ${tw`text-14 uppercase text-gray-300`};
  `
]);

export const MobileNavSubTitle = styled.h4(() => [
  css`
    ${tw`text-14 uppercase text-gray-500 font-700 mt-22 mb-12 px-24 py-0 leading-none tracking-wider`};

    & svg {
      ${tw`mr-12`};
    }
  `
]);

export const MobileNavDivider = styled(Divider)(() => [
  css`
    ${tw`border-gray-400`};
  `
]);
