import { applyMiddleware, compose, createStore, Middleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './rootReducers';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const middlewares: Middleware[] = [];

const enhancer =
  window._env_.ENVIRONMENT !== 'production'
    ? composeWithDevTools(applyMiddleware(...middlewares))
    : compose(applyMiddleware(...middlewares));

const store = createStore(rootReducer, enhancer);

if (window._env_.ENVIRONMENT !== 'production') {
  window.store = store;
}

export default store;
