import React, { useState } from 'react';

import { Trans } from '@lingui/macro';
import { Menu, Popover } from 'antd';
import { useStoreState, useZoomPanHelper } from 'react-flow-renderer';
import { useDispatch, useSelector } from 'react-redux';

import useContainer from 'components/app/components/containerProvider/useContainer';
import Button from 'components/ui/button';
import { DiagramElement, EntityNode, EntityNodeData } from 'services/api/domain/domainDiagram';
import {
  st_domainDiagram_setActiveNode,
  st_domainDiagram_setContextNavOpen
} from 'services/store/domainDiagram/domainDiagram.actions';
import { st_domainDiagram_getNodes } from 'services/store/domainDiagram/domainDiagram.selectors';

const SELECT_ZOOM_FACTOR = 1;

const SelectEntityAction: React.FC = () => {
  const { headerContainer } = useContainer();
  const nodes = useStoreState(store => store?.nodes);
  const [isOpen, setOpen] = useState(false);
  const nodeElements = useSelector(st_domainDiagram_getNodes);
  const { setCenter } = useZoomPanHelper();
  const dispatch = useDispatch();

  const handleVisibleChange = (visible: boolean) => {
    dispatch(st_domainDiagram_setContextNavOpen(visible));
    setOpen(visible);
  };

  const handleGotoNode = (entityNode: EntityNode) => {
    const node = nodes.find(n => n.id === entityNode.id);

    if (node && node.__rf.position && node.__rf.width && node.__rf.height) {
      dispatch(st_domainDiagram_setActiveNode(node.id));
      const x = node.__rf.position.x + node.__rf.width / 2;
      const y = node.__rf.position.y + node.__rf.height / 2;
      const zoom = SELECT_ZOOM_FACTOR;
      setCenter(x, y, zoom);
    }
    handleVisibleChange(false);
  };

  return (
    <Popover
      content={
        <Menu getPopupContainer={() => headerContainer}>
          {nodeElements.map((node: DiagramElement) => (
            <Menu.Item key={node.id} onClick={() => handleGotoNode(node as EntityNode)}>
              {(node.data as EntityNodeData)?.simpleClassName}
            </Menu.Item>
          ))}
        </Menu>
      }
      overlayStyle={{ minWidth: '20rem' }}
      getPopupContainer={() => headerContainer}
      placement="bottomLeft"
      trigger="click"
      visible={isOpen}
      onVisibleChange={handleVisibleChange}
    >
      <Button icon={['fas', 'vector-square']}>
        <Trans>Select entity</Trans>
      </Button>
    </Popover>
  );
};

export default SelectEntityAction;
