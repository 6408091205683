import React, { useCallback, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { FormItem, useFormContext } from '@phoenix-systems/react-form';
import { Form, InputNumber, Select } from 'antd';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

import ReindexResult from './reindexResult';

import * as F from 'components/_styled/formSc';
import useContainer from 'components/app/components/containerProvider/useContainer';
import DocsTooltip from 'components/docs/components/docsTooltip';
import TooltipLabel from 'components/docs/components/docsTooltip/tooltipLabel';
import Button from 'components/ui/button';
import ErrorInfo from 'components/ui/errorInfo';
import FormContainer from 'components/ui/formContainer';
import LoaderMessage from 'components/ui/loader/loaderMessage';
import useApiKey from 'hooks/useApiKey';
import useDomainFromCache from 'hooks/useDomainFromCache';
import useDomainsFromCache from 'hooks/useDomainsFromCache';
import useNotification from 'hooks/useNotification';
import useOnDomainSchemaChange from 'hooks/useOnDomainSchemaChange';
import { DocId } from 'services/api/domain/hint';
import {
  api_reindexDomain,
  ReindexDomainParams,
  RenindexResponse
} from 'services/api/domain/index';

const { Option } = Select;

const ReindexForm: React.FC = () => {
  const domains = useDomainsFromCache();
  const domain = useDomainFromCache();
  const { formContainer } = useContainer();
  const getInitialValues = useCallback(
    () => ({ domainName: domain?.domainName, batchSize: 1000 }),
    [domain]
  );
  const [form] = Form.useForm<ReindexDomainParams>();
  const [formState, formActions] = useFormContext(form, getInitialValues(), true);
  const [addNotification] = useNotification();
  const apiKey = useApiKey();

  const getSuccessMessage = () =>
    i18n._(t`Sucessfully reindexed domain "${form.getFieldValue('domainName')}".`);

  const reindexMutation = useMutation<RenindexResponse, AxiosError, ReindexDomainParams>(
    params => api_reindexDomain(params),
    {
      onSuccess: () => {
        addNotification({
          type: 'success',
          message: getSuccessMessage()
        });
      },
      onError: () => {
        addNotification({
          type: 'error',
          message: i18n._(t`Failed to reindex domain "${form.getFieldValue('domainName')}".`)
        });
      }
    }
  );
  const { current: reindexMutationRef } = useRef(reindexMutation);

  const handleReindex = () => {
    form.validateFields().then(formData => {
      reindexMutation.mutate({ ...formData, apiKey });
    });
  };

  const handleReset = useCallback(() => {
    reindexMutationRef.reset();
    formActions.reset(getInitialValues(), true);
  }, [reindexMutationRef, formActions, getInitialValues]);

  useOnDomainSchemaChange(handleReset);

  return (
    <FormContainer
      maxWidth={900}
      title={
        <>
          <FontAwesomeIcon icon={['fas', 'list']} />
          <Trans>Reindex domain</Trans>
          <DocsTooltip docId={DocId.DOMAIN_ADMINISTRATION_REINDEX_DOMAIN} />
        </>
      }
      messages={
        <>
          {reindexMutation.isLoading && (
            <LoaderMessage showAfterTime={1000}>
              <Trans>Reindex in progress. This can take up to a few minutes.</Trans>
            </LoaderMessage>
          )}
          {reindexMutation.isSuccess && reindexMutation.data && (
            <ReindexResult
              data={reindexMutation.data}
              domainName={form.getFieldValue('domainName')}
            />
          )}
          {reindexMutation.isError && (
            <ErrorInfo
              message={
                <Trans>
                  Failed to reindex domain &quot;{form.getFieldValue('domainName')}&quot;.
                </Trans>
              }
              error={reindexMutation.error}
            />
          )}
        </>
      }
      isStickyDisabled
      buttons={
        <>
          <Button
            action="reset"
            formState={formState}
            mutation={reindexMutation}
            onClick={handleReset}
          />
          <Button
            action="save"
            disabled={!formState.isValid || reindexMutation.isLoading}
            icon={['fas', 'compress-arrows-alt']}
            loading={reindexMutation.isLoading}
            onClick={handleReindex}
          >
            <Trans>Reindex</Trans>
          </Button>
        </>
      }
    >
      <F.StyledForm
        form={form}
        name="reindex-domain-form"
        layout="horizontal"
        validateTrigger="onChange"
        $labelWidth={110}
        onFieldsChange={formActions.onFieldsChange}
        onValuesChange={formActions.onValuesChange}
        initialValues={getInitialValues()}
        colon={false}
      >
        <FormItem
          label={
            <TooltipLabel
              docId={DocId.DOMAIN_ADMINISTRATION_REINDEX_DOMAIN_DOMAIN}
              label={<Trans>Domain</Trans>}
            />
          }
          messageVariables={{ name: i18n._('Domain') }}
          name="domainName"
          rules={[{ required: true }]}
          registerField={formActions.registerField}
        >
          <Select
            id="index-domainname"
            placeholder={<Trans>Select domain...</Trans>}
            getPopupContainer={() => formContainer}
          >
            {domains.map(d => (
              <Option key={d.id} value={d.domainName}>
                {d.domainName}
              </Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          label={
            <TooltipLabel
              docId={DocId.DOMAIN_ADMINISTRATION_REINDEX_DOMAIN_BATCHSIZE}
              label={<Trans>Batch size</Trans>}
            />
          }
          messageVariables={{ name: i18n._('Batch size') }}
          name="batchSize"
          rules={[
            { required: true }
            /*  {
                validator: valdiateBatchSize
              } */
          ]}
          registerField={formActions.registerField}
        >
          <InputNumber step={1} min={500} max={10000} />
        </FormItem>
      </F.StyledForm>
    </FormContainer>
  );
};

export default ReindexForm;
