import { Navigation } from '@phoenix-systems/react-navigation';
import tw, { styled, css } from 'twin.macro';

export const TopNavigation = styled(Navigation)(() => [
  css`
    ${tw`h-full bg-transparent relative`}

    &.ant-menu.ant-menu-horizontal {
      ${tw`border-none bg-transparent`}
      line-height: 5.8rem;

      & .ant-menu-item {
        ${tw` px-4 mt-4 bg-transparent mx-12 text-white text-14 font-700 border-b-4 border-transparent `}
        transform: translateY(-1px);
        height: calc(100% - 4px);

        &.disabled {
          opacity: 0.1 !important;
          pointer-events: none;
        }

        &:after {
          border-bottom: none;
        }

        &.domain {
          /*  transition: all 5s ease-in-out;
          overflow: hidden;
          width: 85px; */
          //  margin-left: 0;

          &.disabled {
            ${tw`hidden `}
          }
        }
      }

      & span a {
        ${tw`text-white `}
      }

      & .ant-menu-item-selected {
        ${tw`border-secondary-200 hover:border-secondary-200`}

        & .anticon,
        & span a {
          ${tw`text-secondary-200`}
        }
      }
    }
  `
]);
