import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Menu } from 'antd';
import { omit } from 'lodash';

import DeleteDbImportDialog from './deleteDbImportDialog';

import { DbImport } from 'services/api/domain/dataImport';

const { Item } = Menu;

type DeleteDbImportActionProps = {
  data: DbImport;
};

const DeleteDbImportAction: React.FC<DeleteDbImportActionProps> = props => {
  const antProps = omit(props, ['data']);
  const { data } = props;
  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleDeleteRequest = () => {
    setDialogOpen(true);
  };

  return (
    <>
      <Item
        {...antProps}
        key={`delete-trigger_${data.id}`}
        icon={<FontAwesomeIcon icon={['fas', 'trash']} />}
        onClick={handleDeleteRequest}
        className="delete"
      >
        <Trans>Delete DB Import</Trans>
      </Item>

      <DeleteDbImportDialog isOpen={isDialogOpen} data={data} close={() => setDialogOpen(false)} />
    </>
  );
};

export default DeleteDbImportAction;
