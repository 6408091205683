import { useEffect, useRef, useState } from 'react';

import useDomainName from './useDomainName';
import useSchemaName from './useSchemaName';

const useOnDomainSchemaChange = (callback: () => void): void => {
  const [isInitialised, setInitialised] = useState(false);
  const { current: callbackRef } = useRef(callback);
  const domainName = useDomainName();
  const schemaName = useSchemaName();

  useEffect(() => {
    if (isInitialised) {
      callbackRef();
    }
  }, [domainName, schemaName, isInitialised, callbackRef]);

  useEffect(() => {
    if (domainName && schemaName) {
      setInitialised(true);
    }
  }, [domainName, schemaName, callbackRef]);
};

export default useOnDomainSchemaChange;
