import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { getRoute } from '@phoenix-systems/react-router';
import { Menu } from 'antd';
import { omit } from 'lodash';

import routes from 'config/routes/routes';
import { User } from 'services/api/domain/user';
import history from 'services/history';

const { Item } = Menu;

type EditUserActionProps = {
  data: User;
};

const EditUserAction: React.FC<EditUserActionProps> = props => {
  const antProps = omit(props, ['data']);
  const { data } = props;

  const handleEditRequest = () => {
    history.push(getRoute(routes.userAdmin.user.single, data.username));
  };

  return (
    <Item
      {...antProps}
      key={`edit-user_${data.id}`}
      icon={<FontAwesomeIcon icon={['fas', 'pen']} />}
      onClick={handleEditRequest}
    >
      <Trans>Edit user</Trans>
    </Item>
  );
};

export default EditUserAction;
