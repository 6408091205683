import tw, { styled, css } from 'twin.macro';

export const Wrapper = styled.div(() => [
  css`
    ${tw`w-full h-full relative`};

    & iframe {
      ${tw`absolute h-full left-0 right-0 w-full`};
    }
  `
]);
