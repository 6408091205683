import React, { useCallback, useEffect, useRef } from 'react';

import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import {
  ExtendedFile,
  FileSelector,
  FileSelectorRef,
  FormItem,
  useFormContext
} from '@phoenix-systems/react-form';
import { RenderImageDialogProps } from '@phoenix-systems/react-slate-editor';
import { Form, Input } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useImmer } from 'use-immer';

import { ImageData } from '../editorImage.types';

import * as S from './editorImageDialogSc';

import * as F from 'components/_styled/formSc';
import Button from 'components/ui/button';
import Loader from 'components/ui/loader';
import fileSelectorLocales from 'config/langauge/fileSelector.locales';
import useDefaultModalProps from 'hooks/useDefaultModalProps';
import { api_getFile, api_uploadFile, FileInfo, UploadFileParams } from 'services/api/domain/file';
import { useHintApiInfo } from 'services/api/domain/hint';

export type ImageFormData = {
  file: ExtendedFile;
  legend?: string;
  alt?: string;
};

type EditorImageDialogState = {
  isUploadSuccess: boolean;
  fileId?: string;
  imageUrl?: string;
  hasImage: boolean;
};

const EditorImageDialog: React.FC<RenderImageDialogProps<ImageData>> = ({
  isDialogOpen,
  close,
  insert,
  data
}) => {
  const getInitialData = useCallback(() => {
    if (data && data.data.fileInfo) {
      return {
        alt: data.data.alt,
        legend: data.data.legend
      };
    }

    return undefined;
  }, [data]);

  const modalProps = useDefaultModalProps();
  const [form] = Form.useForm<ImageFormData>();
  const [formState, formActions] = useFormContext(form, getInitialData(), data !== undefined);
  const [state, setState] = useImmer<EditorImageDialogState>({
    isUploadSuccess: data !== undefined,
    fileId: data?.data.fileInfo,
    imageUrl: undefined,
    hasImage: data?.data.fileInfo !== undefined
  });
  const fileSelectorRef = useRef<FileSelectorRef>(null);
  const hintInfoQuery = useHintApiInfo({ enabled: false });

  const downloadFile = useCallback(async () => {
    if (state.fileId && hintInfoQuery.data) {
      const blob = await api_getFile({
        domainName: hintInfoQuery.data.domainName,
        schema: hintInfoQuery.data.schemaName,
        apiKey: hintInfoQuery.data.apiKey,
        fileId: state.fileId
      });
      const text = await blob.text();
      setState(draft => {
        draft.imageUrl = `data:data:image/jpg;base64,${text}`;
      });
    }
  }, [state, hintInfoQuery.data, setState]);

  const uploadMutation = useMutation<FileInfo, AxiosError, UploadFileParams>(
    payload => api_uploadFile(payload),
    {
      onSuccess: resData => {
        const fileId = resData.firstLanguage.languageVersionFields.SYSTEMID.values[0].value;

        if (formState.data?.file && fileId) {
          setState(draft => {
            draft.fileId = fileId;
            draft.isUploadSuccess = true;
            draft.hasImage = true;
          });
          fileSelectorRef.current?.updateFileState({
            id: formState.data.file.uid,
            status: 'success',
            percent: 100
          });
        }
      },
      onError: () => {
        setState(draft => {
          draft.fileId = undefined;
          draft.isUploadSuccess = false;
        });

        if (formState.data?.file) {
          fileSelectorRef.current?.updateFileState({
            id: formState.data.file.uid,
            status: 'error',
            errorMessage: i18n._('Failed to upload image.')
          });
        }
      }
    }
  );

  const handleInsert = () => {
    form.validateFields().then(formData => {
      if (state.fileId) {
        insert({
          data: {
            fileInfo: state.fileId,
            legend: formData.legend,
            alt: formData.alt
          }
        });
      }
    });
  };

  const handleUpload = () => {
    if (formState.data?.file && hintInfoQuery.data) {
      uploadMutation.mutate({
        file: formState.data.file as File,
        domainName: hintInfoQuery.data.domainName,
        schema: hintInfoQuery.data.schemaName,
        apiKey: hintInfoQuery.data.apiKey,
        onUploadProgress: event => {
          if (formState.data?.file?.size) {
            const percentage = Math.round((event.loaded / formState.data.file.size) * 100);
            fileSelectorRef.current?.updateFileState({
              id: formState.data.file.uid,
              percent: percentage
            });
          }
        }
      });

      fileSelectorRef.current?.updateFileState({
        id: formState.data.file.uid,
        status: 'uploading',
        percent: 0
      });
    }
  };

  const handleRemoveImage = () => {
    setState(draft => {
      draft.imageUrl = undefined;
      draft.hasImage = false;
      draft.fileId = undefined;
      draft.isUploadSuccess = false;
    });
  };

  useEffect(() => {
    if (data?.data.fileInfo) {
      setState(draft => {
        draft.fileId = data.data.fileInfo;
      });
    }
  }, [data?.data, setState]);

  useEffect(() => {
    if (state.fileId) {
      downloadFile();
      setState(draft => {
        draft.hasImage = true;
      });
    }
  }, [state.fileId, setState, downloadFile]);

  useEffect(() => {
    if (!isDialogOpen) {
      setState(draft => {
        draft.hasImage = false;
        draft.imageUrl = undefined;
        draft.fileId = undefined;
        draft.isUploadSuccess = false;
      });
    }
  }, [isDialogOpen, setState]);

  return (
    <Modal
      {...modalProps}
      visible={isDialogOpen}
      title={data ? <Trans>Edit image</Trans> : <Trans>Add Image</Trans>}
      width={850}
      footer={
        <>
          <Button action="cancel" onClick={() => close()} />

          {!state.fileId ? (
            <Button
              action="save"
              onClick={handleUpload}
              disabled={!formState.data?.file || uploadMutation.isLoading}
              loading={uploadMutation.isLoading}
              icon={['fas', 'upload']}
            >
              <Trans>Upload image</Trans>
            </Button>
          ) : (
            <Button action="insert" onClick={handleInsert} disabled={!formState.hasChanged}>
              {data ? <Trans>Update</Trans> : <Trans>Insert</Trans>}
            </Button>
          )}
        </>
      }
    >
      <S.StyledImageUpload>
        {state.fileId && (
          <S.ImageOverlay>
            <p>
              <Trans>Image</Trans>
            </p>
            <div>
              {state.imageUrl ? (
                <>
                  <img src={state.imageUrl} />
                  <Button action="delete" isIconOnly onClick={handleRemoveImage} />
                </>
              ) : (
                <Loader height={200} />
              )}
            </div>
          </S.ImageOverlay>
        )}
        <F.StyledForm
          form={form}
          onValuesChange={formActions.onValuesChange}
          onFieldsChange={formActions.onFieldsChange}
          layout="vertical"
          name="editor-image-form"
          initialValues={data?.data}
        >
          {!state.fileId && (
            <FormItem
              name="file"
              rules={[{ required: true }]}
              label={<Trans>Image</Trans>}
              messageVariables={{ name: i18n._('Image') }}
              registerField={formActions.registerField}
            >
              <FileSelector
                ref={fileSelectorRef}
                fileType="IMAGE"
                multiple={false}
                allowedFileTypes={['image/*']}
                locales={fileSelectorLocales}
              />
            </FormItem>
          )}

          <FormItem
            name="legend"
            label={<Trans>Image legend</Trans>}
            messageVariables={{ name: i18n._('Image legend') }}
            registerField={formActions.registerField}
          >
            <Input.TextArea rows={3} />
          </FormItem>
          <FormItem
            name="alt"
            label={<Trans>Alt tag</Trans>}
            messageVariables={{ name: i18n._('Image alt') }}
            registerField={formActions.registerField}
          >
            <Input />
          </FormItem>
        </F.StyledForm>
      </S.StyledImageUpload>
    </Modal>
  );
};

export default EditorImageDialog;
