import React from 'react';

import { Trans } from '@lingui/macro';

import AppLayout from 'components/app/components/appLayout';
import FtpConnectionActions from 'components/domain/actions/ftpConnectionActions';
import DomainPage from 'components/domain/components/domainPage';
import FtpConnectionForm from 'components/domain/components/ftpConnectionForm/ftpConnectionForm';
import Button from 'components/ui/button';
import routes from 'config/routes/routes';
import useDomainName from 'hooks/useDomainName';
import useFtpConnectionName from 'hooks/useFtpConnectionName';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';
import useSchemaName from 'hooks/useSchemaName';
import { useFTPConnection } from 'services/api/domain/ftpConnection';

const FtpConnectionSinglePage: React.FC = () => {
  const goto = useGoToDomainRoute();
  const domainName = useDomainName();
  const schemaName = useSchemaName();
  const ftpConnectionName = useFtpConnectionName();
  const connectionQuery = useFTPConnection({ domainName, schemaName, ftpConnectionName });

  return (
    <AppLayout>
      <DomainPage
        route={routes.domain.connections.ftp.list}
        schemaSelector={{ readonly: true }}
        title={<Trans>Edit FTP Connection</Trans>}
        content={
          <>
            {connectionQuery.isSuccess && (
              <FtpConnectionForm data={connectionQuery.data} mode="STANDALONE" />
            )}
          </>
        }
        headerButtons={
          <>
            <Button
              action="list"
              type="ghost"
              onClick={() => goto(routes.domain.connections.ftp.list)}
            >
              <Trans>Back to list</Trans>
            </Button>
            {connectionQuery.data && (
              <FtpConnectionActions
                data={connectionQuery.data}
                mode="BUTTON"
                popoverPlacement="rightTop"
              />
            )}
          </>
        }
      />
    </AppLayout>
  );
};

export default FtpConnectionSinglePage;
