import React from 'react';

import { Trans } from '@lingui/macro';

import AppLayout from 'components/app/components/appLayout';
import DomainPage from 'components/domain/components/domainPage';
import MetadataCreateForm from 'components/domain/components/metadataCreateForm';
import MetadataEditForm from 'components/domain/components/metadataEditForm';
import Button from 'components/ui/button';
import routes from 'config/routes/routes';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';

type MetadataPageProps = {
  mode: 'EDIT' | 'CREATE';
};

const MetadataPage: React.FC<MetadataPageProps> = ({ mode }) => {
  const goto = useGoToDomainRoute();

  const gotoEdit = () => {
    goto(routes.domain.queryData.metaData);
  };

  const gotoCreate = () => {
    goto(routes.domain.queryData.metaDataCreate);
  };

  return (
    <AppLayout>
      <DomainPage
        route={routes.domain.queryData.metaData}
        title={
          <>
            {mode === 'CREATE' ? (
              <Trans>Create metadata</Trans>
            ) : (
              <Trans>Search and edit metadata</Trans>
            )}
          </>
        }
        noScroll
        schemaSelector
        content={
          <>
            {mode === 'CREATE' && <MetadataCreateForm />}
            {mode === 'EDIT' && <MetadataEditForm />}
          </>
        }
        headerButtons={
          <>
            {mode === 'CREATE' ? (
              <Button onClick={gotoEdit} icon={['fas', 'search']}>
                <Trans>Search/edit metadata</Trans>
              </Button>
            ) : (
              <Button action="create" onClick={gotoCreate}>
                <Trans>Create metadata</Trans>
              </Button>
            )}
          </>
        }
      />
    </AppLayout>
  );
};

export default MetadataPage;
