import React from 'react';

import { Trans } from '@lingui/macro';
import { Scrollable } from '@phoenix-systems/react-layout';
import { Col, Row } from 'antd';

import * as T from 'components/_styled/tableSc';
import BooleanIcon from 'components/ui/booleanIcon';
import DataItem from 'components/ui/dataItem';
import routes from 'config/routes/routes';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';
import { CsvImport } from 'services/api/domain/csvImport';

type CsvImportTableOverviewProps = {
  data: CsvImport;
};

const CsvImportTableOverview: React.FC<CsvImportTableOverviewProps> = ({ data }) => {
  const goto = useGoToDomainRoute();

  const handleGotoFtpConnection = () => {
    if (data.ftpConnection?.name) {
      goto(routes.domain.connections.ftp.single, { path: data.ftpConnection.name });
    }
  };

  const handleGotoCsvImport = () => {
    if (data.name) {
      goto(routes.domain.dataManagement.importFromCsv.single, { path: data.name });
    }
  };

  if (!data) {
    return null;
  }

  return (
    <Scrollable scrollY>
      <T.SingleContentContainer>
        <Row gutter={[12, 12]}>
          <Col xs={12}>
            <ul>
              <li>
                <DataItem name={<Trans>Id</Trans>} value={data.id} hasCopy breakWord hasCopyTitle />
              </li>
              <li>
                <DataItem
                  name={<Trans>Name</Trans>}
                  value={data.name}
                  hasCopy
                  onEdit={handleGotoCsvImport}
                />
              </li>
              <li>
                <DataItem name={<Trans>Entity name</Trans>} value={data.entityName} hasCopy />
              </li>
              <li>
                <DataItem
                  name={<Trans>Update/ create</Trans>}
                  value={data.updatecreate ? 'Update' : 'Create'}
                />
              </li>
            </ul>
          </Col>
          <Col xs={12}>
            <ul>
              <li>
                <DataItem
                  name={<Trans>Ftp connection</Trans>}
                  value={data.ftpConnection?.name || <BooleanIcon value={false} />}
                  onEdit={data.ftpConnection?.name ? handleGotoFtpConnection : undefined}
                />
              </li>
              <li>
                <DataItem name={<Trans>File name</Trans>} value={data.fileName} hasCopy />
              </li>
              <li>
                <DataItem
                  name={<Trans>File header</Trans>}
                  value={<BooleanIcon value={data.header} />}
                />
              </li>
              <li>
                <DataItem
                  name={<Trans>Delimiter</Trans>}
                  value={data.delimiter}
                  hasCopy
                  hasCopyTitle
                />
              </li>
            </ul>
          </Col>
        </Row>
      </T.SingleContentContainer>
    </Scrollable>
  );
};

export default CsvImportTableOverview;
