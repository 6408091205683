import {
  DomainDiagram,
  GenerateDomainDiagramParams,
  GetDomainDiagramParams,
  PublishDomainDiagramParams
} from './domainDiagram.types';

import apiClient from 'services/api/apiClient';

const BASE_PATH = 'diagram';

/**
 * GET /api/diagram/{domain}/{scheme}/generate
 */
export const api_generateDomainDiagram = async (
  params: GenerateDomainDiagramParams
): Promise<DomainDiagram> => {
  const { data } = await apiClient.get(
    `/${BASE_PATH}/${params.domainName}/${params.schema}/generate`
  );

  return data;
};

/**
 * GET /api/diagram/{domain}/{scheme}
 */
export const api_getDomainDiagram = async (
  params: GetDomainDiagramParams
): Promise<DomainDiagram> => {
  const { data } = await apiClient.get(`/${BASE_PATH}/${params.domainName}/${params.schema}`);

  return data;
};

/**
 * PUT /api/diagram/{domain}/{scheme}
 */
export const api_updateDomainDiagram = async (payload: DomainDiagram): Promise<DomainDiagram> => {
  const { data } = await apiClient.put(
    `/${BASE_PATH}/${payload.domain}/${payload.scheme}`,
    payload
  );

  return data;
};

/**
 * POST /api/diagram/{domain}/{scheme}/resetpositions
 */
export const api_recalculateDomainDiagram = async (
  payload: DomainDiagram
): Promise<DomainDiagram> => {
  const { data } = await apiClient.post(
    `/${BASE_PATH}/${payload.domain}/${payload.scheme}/resetpositions`,
    payload
  );

  return data;
};

/**
 * PUT /api/diagram/{domain}/{scheme}/publish
 */
export const api_publishDomainDiagram = async (
  params: PublishDomainDiagramParams
): Promise<DomainDiagram> => {
  const { data } = await apiClient.put(
    `/${BASE_PATH}/${params.domainName}/${params.schema}/publish`
  );

  return data;
};
