import React, { useCallback, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { FormItem, useFormContext } from '@phoenix-systems/react-form';
import { Col, Form, Input, Radio } from 'antd';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useImmer } from 'use-immer';

import * as S from './captureStructureFormSc';

import * as F from 'components/_styled/formSc';
import DocsTooltip from 'components/docs/components/docsTooltip';
import TooltipLabel from 'components/docs/components/docsTooltip/tooltipLabel';
import Button from 'components/ui/button';
import ErrorInfo from 'components/ui/errorInfo';
import FormContainer from 'components/ui/formContainer';
import LoaderMessage from 'components/ui/loader/loaderMessage';
import MonacoEditor from 'components/ui/monacoEditor';
import routes from 'config/routes/routes';
import useApiKey from 'hooks/useApiKey';
import useDomainName from 'hooks/useDomainName';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';
import useNotification from 'hooks/useNotification';
import useOnDomainSchemaChange from 'hooks/useOnDomainSchemaChange';
import useSchemaName from 'hooks/useSchemaName';
import { DocId } from 'services/api/domain/hint';
import {
  api_captureMetadata,
  api_createMetadata,
  CaptureMetadataParams,
  CreateMetadataParams,
  CreateMetadataResponse,
  EntityMetaData
} from 'services/api/domain/metadata';

const DEFAULT_CAPTURE_VALUES = {
  embedded: 'false'
};

type CaptureStructureFormState = {
  isValuesChanged: boolean;
  captureEditorHeight: number;
};

type CaptureFormData = {
  data: EntityMetaData[];
};

const CaptureStructureForm: React.FC = () => {
  const domainName = useDomainName();
  const schema = useSchemaName();
  const [addNotification] = useNotification();
  const [state, setState] = useImmer<CaptureStructureFormState>({
    isValuesChanged: false,
    captureEditorHeight: 695
  });
  const [captureForm] = Form.useForm<CaptureMetadataParams>();
  const [captureFormState, captureFormActions] = useFormContext(captureForm);
  const [applyForm] = Form.useForm<CaptureFormData>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [applyFormState, applyFormActions] = useFormContext(applyForm);
  const goto = useGoToDomainRoute();
  const containerRef = useRef<HTMLDivElement>(null);
  const apiKey = useApiKey();

  const captureMutation = useMutation<EntityMetaData[], AxiosError, CaptureMetadataParams>(
    params => api_captureMetadata(params),
    {
      onSuccess: captureRes => {
        addNotification({
          type: 'success',
          message: i18n._(
            t`Successfully captured metadata of domain "${domainName}" into entity "${captureForm.getFieldValue(
              'type'
            )}".`
          )
        });
        setState(draft => {
          draft.isValuesChanged = false;
        });
        applyFormActions.reset({ data: captureRes }, false);
      },
      onError: () => {
        addNotification({
          type: 'error',
          message: i18n._(
            t`Failed to capture metadata of domain "${domainName}" into entity "${captureForm.getFieldValue(
              'type'
            )}".`
          )
        });

        applyFormActions.reset({ data: undefined }, false);
      }
    }
  );
  const { current: captureMutationRef } = useRef(captureMutation);

  const applyMutation = useMutation<CreateMetadataResponse, AxiosError, CreateMetadataParams>(
    params => api_createMetadata(params),
    {
      onSuccess: () => {
        addNotification({
          type: 'success',
          message: i18n._(
            t`Successfully saved/applied metadata of the entity "${captureForm.getFieldValue(
              'type'
            )}" to domain "${domainName}".`
          )
        });
      },
      onError: () => {
        addNotification({
          type: 'error',
          message: i18n._(t`Failed to apply/save metadata to domain "${domainName}".`)
        });
      }
    }
  );
  const { current: applyMutationRef } = useRef(applyMutation);

  const handleCaptureStructure = () => {
    captureForm.validateFields().then(formData => {
      const payload = {
        ...formData,
        domainName,
        schema
      };

      captureMutation.mutate(payload);
    });
  };

  const handleApply = async () => {
    if (apiKey !== '') {
      applyForm.validateFields().then(formData => {
        const payload = {
          data: formData.data,
          domainName,
          schema,
          apiKey
        };
        applyMutation.mutate(payload);
      });
    }
  };

  const handleReset = useCallback(() => {
    captureFormActions.reset();
    applyFormActions.reset({ data: [] }, false);
    captureMutationRef.reset();
    applyMutationRef.reset();
  }, [captureFormActions, applyFormActions, applyMutationRef, captureMutationRef]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleValuesChange = (changedValues: any, values: unknown) => {
    applyMutation.reset();
    setState(draft => {
      draft.isValuesChanged = true;
    });
    captureFormActions.onValuesChange(changedValues, values);
  };

  useOnDomainSchemaChange(() => {
    handleReset();
  });

  return (
    <S.StyledRow gutter={36} ref={containerRef}>
      <Col xs={12}>
        <FormContainer
          fullHeight
          maxWidth="unset"
          isStickyDisabled
          title={
            <>
              <FontAwesomeIcon icon={['fas', 'sync']} />
              <Trans>Capture JSON structure</Trans>
              <DocsTooltip docId={DocId.DOMAIN_CAPTURE_STRUCTURE} />
            </>
          }
          buttons={
            <>
              <Button
                action="reset"
                disabled={!captureFormState.isValid || !captureFormState.hasChanged}
                onClick={handleReset}
              />
              <Button
                disabled={!captureFormState.isValid}
                loading={captureMutation.isLoading}
                type="primary"
                onClick={handleCaptureStructure}
                icon={['fas', 'camera-polaroid']}
              >
                <Trans>Capture structure</Trans>
              </Button>
            </>
          }
          messages={
            <>
              {captureMutation.isLoading && (
                <LoaderMessage showAfterTime={1000}>
                  <Trans>Capture JSON data in progress. This can take some time.</Trans>
                </LoaderMessage>
              )}
              {captureMutation.isError && (
                <ErrorInfo
                  message={<Trans>Failed to capture structure</Trans>}
                  error={captureMutation.error}
                />
              )}
            </>
          }
        >
          <F.StyledForm
            form={captureForm}
            onValuesChange={handleValuesChange}
            onFieldsChange={captureFormActions.onFieldsChange}
            layout="horizontal"
            name="domain-capture-structure-form"
            initialValues={DEFAULT_CAPTURE_VALUES}
            $labelWidth={120}
            colon={false}
          >
            <S.ContainerInner>
              <div className="top-fields">
                <FormItem
                  label={
                    <TooltipLabel
                      docId={DocId.DOMAIN_CAPTURE_STRUCUTRE_TYPE}
                      label={<Trans>ClassName</Trans>}
                    />
                  }
                  messageVariables={{ name: i18n._('Type') }}
                  name="type"
                  rules={[
                    { required: true },
                    {
                      pattern: /^([A-Z][a-zA-Z_$0-9]*)$/,
                      message: i18n._(t`This is not a fully qualified 'Simple class name'`)
                    }
                  ]}
                  registerField={captureFormActions.registerField}
                >
                  <Input />
                </FormItem>
                <FormItem
                  label={
                    <TooltipLabel
                      docId={DocId.DOMAIN_CAPTURE_STRUCUTRE_EMBEDDED}
                      label={<Trans>Embedded</Trans>}
                    />
                  }
                  messageVariables={{ name: i18n._('Embedded') }}
                  name="embedded"
                  rules={[{ required: true }]}
                  registerField={captureFormActions.registerField}
                >
                  <Radio.Group>
                    <Radio value="false">
                      <Trans>false</Trans>
                    </Radio>
                    <Radio value="true">
                      <Trans>true</Trans>
                    </Radio>
                  </Radio.Group>
                </FormItem>
              </div>
              <div className="json-editor">
                <S.JsonFormItem
                  className="json-input"
                  label={
                    <TooltipLabel
                      docId={DocId.DOMAIN_CAPTURE_STRUCUTRE_DATA}
                      label={<Trans>JSON data to capture</Trans>}
                    />
                  }
                  rules={[{ required: true }]}
                  messageVariables={{ name: i18n._('JSON data to capture') }}
                  name="data"
                  registerField={captureFormActions.registerField}
                >
                  <MonacoEditor
                    height="100%"
                    mode="JSON"
                    showMiniMap={false}
                    defaultColorTheme="BRIGHT"
                  />
                </S.JsonFormItem>
              </div>
            </S.ContainerInner>
          </F.StyledForm>
        </FormContainer>
      </Col>

      <Col xs={12}>
        <FormContainer
          fullHeight
          isStickyDisabled
          maxWidth="unset"
          title={
            <>
              <FontAwesomeIcon icon={['fas', 'camera-polaroid']} />
              <Trans>Captured result (metadata)</Trans>
              <DocsTooltip docId={DocId.DOMAIN_CAPTURE_STRUCTURE} />
            </>
          }
          buttons={
            <>
              <Button
                action="save"
                disabled={
                  !captureMutation.isSuccess ||
                  applyMutation.isLoading ||
                  state.isValuesChanged ||
                  applyMutation.isSuccess
                }
                loading={applyMutation.isLoading}
                onClick={handleApply}
                icon={['fas', 'file-import']}
              >
                <Trans>Apply / save metadata</Trans>
              </Button>
              {applyMutation.isSuccess && captureForm.getFieldValue('type') && (
                <Button
                  icon={['fas', 'eye']}
                  onClick={() => {
                    const type = captureForm.getFieldValue('type');

                    if (typeof type === 'string') {
                      goto(routes.domain.queryData.metaDataEntity, { path: type });
                    }
                  }}
                >
                  <Trans>Goto metadata</Trans>
                </Button>
              )}
            </>
          }
          messages={
            <>
              {applyMutation.isError && (
                <ErrorInfo
                  error={applyMutation.error}
                  message={<Trans>Failed to apply and save metadata.</Trans>}
                />
              )}
              {applyMutation.isSuccess && (
                <F.FormAlert
                  type="success"
                  showIcon
                  message={
                    <Trans>
                      Successfully applied and saved metadata to domain &quot;{domainName}&quot;
                    </Trans>
                  }
                />
              )}
            </>
          }
        >
          <F.StyledForm
            form={applyForm}
            onValuesChange={applyFormActions.onValuesChange}
            onFieldsChange={applyFormActions.onFieldsChange}
            layout="horizontal"
            name="domain-apply-structure-form"
            initialValues={{ data: captureMutation.data }}
          >
            <S.ContainerInner>
              <div className="json-editor">
                <S.JsonFormItem
                  rules={[{ required: true }]}
                  messageVariables={{ name: i18n._('JSON data to capture') }}
                  name="data"
                  registerField={applyFormActions.registerField}
                >
                  <MonacoEditor height="100%" mode="JSON" readOnly defaultColorTheme="BRIGHT" />
                </S.JsonFormItem>
              </div>
            </S.ContainerInner>
          </F.StyledForm>
        </FormContainer>
      </Col>
    </S.StyledRow>
  );
};

export default CaptureStructureForm;
