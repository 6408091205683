import React, { useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { AuthError, useAuthActions, useAuthState } from '@phoenix-systems/react-keycloak-auth';
import { Form, Input } from 'antd';
import { useQueryClient } from 'react-query';
import { Redirect, useHistory, useRouteMatch } from 'react-router';

import * as S from './loginPageSc';

import logo from 'assets/images/logo.png';
import * as F from 'components/_styled/formSc';
import routes from 'config/routes/routes';
import apiClient from 'services/api/apiClient';

const LoginPage: React.FC = () => {
  const { isFetching, error } = useAuthState();
  const { login } = useAuthActions();
  const [form] = Form.useForm();
  const isLoginRoute = useRouteMatch(routes.login.root);
  const history = useHistory();
  const queryClient = useQueryClient();

  const handleLoginRequest = () => {
    form.validateFields().then(formData => {
      login({
        ...formData,
        apiClient,
        onSuccess: () => {
          history.push(routes.dashboard.root);
        }
      });
    });
  };

  const getAuthErrorMessage = (errors: AuthError) => {
    switch (errors) {
      case 'WRONG_CREDENTIALS':
        return <Trans>Login failed. Please check your credentials and try again.</Trans>;

      case 'AUTH_SERVER_ERROR':
        return (
          <Trans>
            Login failed due to a server error. Please try again or contact the administrator.
          </Trans>
        );
      case 'UNKNOWN':
        return <Trans>Login error. Please try again or contact the administrator.</Trans>;

      default:
        return <Trans>Login error. Please try again or contact the administrator.</Trans>;
    }
  };

  useEffect(() => {
    queryClient.removeQueries();
  }, [queryClient]);

  if (!isLoginRoute) {
    return <Redirect to={routes.login.root} />;
  }

  return (
    <S.LoginScreen>
      <S.LoginContainer className={error && !isFetching ? 'shake' : ''}>
        <S.Logo alt="logo" src={logo} />

        <F.StyledForm form={form} name="login-form" colon={false}>
          <Form.Item
            messageVariables={{ name: i18n._('Email') }}
            name="username"
            rules={[
              {
                required: true
              }
              /* {
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: i18n._(t`Invalid email address`)
              } */
            ]}
            validateFirst
          >
            <Input
              autoComplete="off"
              placeholder={i18n._(t`Email`)}
              prefix={<FontAwesomeIcon icon={['fal', 'user']} />}
              size="large"
            />
          </Form.Item>
          <Form.Item
            messageVariables={{ name: i18n._('Password') }}
            name="password"
            rules={[
              {
                required: true,
                message: `${i18n._(t`Password`)} ${i18n._(t`required`)}`
              }
            ]}
          >
            <Input.Password
              autoComplete="off"
              iconRender={visible =>
                visible ? (
                  <FontAwesomeIcon icon={['fal', 'eye-slash']} />
                ) : (
                  <FontAwesomeIcon icon={['fal', 'eye']} />
                )
              }
              placeholder={i18n._(t`Password`)}
              prefix={<FontAwesomeIcon icon={['fal', 'key']} />}
              size="large"
              visibilityToggle
            />
          </Form.Item>
          {error && !isFetching ? (
            <F.FormAlert
              className="mt-30 field"
              icon={
                <FontAwesomeIcon icon={['far', 'exclamation-circle']} style={{ top: 'unset' }} />
              }
              message={getAuthErrorMessage(error)}
              type="error"
              showIcon
            />
          ) : null}
          <Form.Item>
            <S.LoginButton htmlType="submit" type="primary" onClick={handleLoginRequest}>
              <Trans>Login</Trans>
            </S.LoginButton>
          </Form.Item>
        </F.StyledForm>
      </S.LoginContainer>
    </S.LoginScreen>
  );
};

export default LoginPage;
