import { TestDataGeneratorParams } from './testDataGenerator.types';

import { AnyObject } from 'index.types';
import apiClient from 'services/api/apiClient';

const BASE_PATH = 'testdata';

/**
 * GET /api/testdata/generate
 */
export const api_generateTestData = async (
  params: TestDataGeneratorParams
): Promise<AnyObject[]> => {
  const res = await apiClient.get(`${BASE_PATH}/generate`, {
    params: {
      domain: params.domainName,
      scheme: params.schema,
      classname: params.simpleClassName,
      amount: params.amount
    }
  });

  return res.data;
};
