import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { FormItem, useFormContext } from '@phoenix-systems/react-form';
import { Divider, Form, Input } from 'antd';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { Node } from 'slate';

import * as F from 'components/_styled/formSc';
import Button from 'components/ui/button';
import Editor from 'components/ui/editor';
import FormContainer from 'components/ui/formContainer';
import { DocConfig } from 'config/docs/docs.config.types';
import useNotification from 'hooks/useNotification';
import { api_updateHint, Hint } from 'services/api/domain/hint';

type DocsEditorFormProps = {
  data: Hint;
  config: DocConfig;
};

const DocsEditorForm: React.FC<DocsEditorFormProps> = ({ data, config }) => {
  const [form] = Form.useForm<Hint>();
  const [formState, formActions] = useFormContext(form, data, true);
  const queryClient = useQueryClient();
  const [addNotification] = useNotification();

  const updateMutation = useMutation<Hint, AxiosError, Hint>(payload => api_updateHint(payload), {
    onSuccess: resData => {
      addNotification({
        type: 'success',
        message: i18n._(t`Successfully updated doc "${resData.title}".`)
      });
      queryClient.setQueryData(['hint', config.id], resData);

      updateMutation.reset();
      formActions.reset(resData, true);
    },
    onError: () => {
      addNotification({
        type: 'error',
        message: i18n._(t`Failed to updated doc "${data?.title}".`)
      });
    }
  });

  const handleSave = async () => {
    form.validateFields().then(formData => {
      const payload = {
        ...formData,
        plainText: formData.parsedText?.map(n => Node.string(n)).join(' ') || ''
      };
      updateMutation.mutate(payload);
    });
  };

  const handleReset = () => {
    formActions.reset(data, true);
  };

  return (
    <FormContainer
      buttons={
        <>
          <Button
            action="reset"
            formState={formState}
            mutation={updateMutation}
            onClick={handleReset}
          />
          <Button
            action="save"
            onClick={handleSave}
            formState={formState}
            mutation={updateMutation}
          />
        </>
      }
      title={
        <>
          <FontAwesomeIcon icon={['fas', 'pen']} />
          <Trans>Edit documentation</Trans>
        </>
      }
    >
      <F.StyledForm
        form={form}
        layout="horizontal"
        initialValues={data}
        $labelWidth={94}
        onValuesChange={formActions.onValuesChange}
        onFieldsChange={formActions.onFieldsChange}
        name="docs-editor-form"
        colon={false}
      >
        <FormItem
          label={<Trans>Element id</Trans>}
          rules={[{ required: true }]}
          messageVariables={{ name: i18n._('Element id') }}
          name="elementId"
          registerField={formActions.registerField}
        >
          <Input readOnly />
        </FormItem>
        <FormItem
          label={<Trans>Language</Trans>}
          rules={[{ required: true }]}
          messageVariables={{ name: i18n._('Language') }}
          name="lang"
          registerField={formActions.registerField}
        >
          <Input readOnly />
        </FormItem>
        <FormItem
          label={<Trans>Title</Trans>}
          rules={[{ required: true }]}
          messageVariables={{ name: i18n._('Title') }}
          name="title"
          registerField={formActions.registerField}
        >
          <Input />
        </FormItem>
        <FormItem
          label={<Trans>Tooltip</Trans>}
          rules={[{ required: true }]}
          messageVariables={{ name: i18n._('Tooltip') }}
          name="tooltip"
          registerField={formActions.registerField}
        >
          <Input.TextArea rows={5} />
        </FormItem>

        <Divider />
        <FormItem name="parsedText" registerField={formActions.registerField}>
          <Editor />
        </FormItem>
      </F.StyledForm>
    </FormContainer>
  );
};

export default DocsEditorForm;
