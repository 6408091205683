import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FormTitleProps } from './formTitle.types';
import * as S from './formTitleSc';

import DocsTooltip from 'components/docs/components/docsTooltip';

const FormTitle: React.FC<FormTitleProps> = ({
  collapsible,
  icon,
  children,
  className,
  style,
  docId
}) => (
  <>
    {collapsible ? (
      <S.TitleButton
        collapsible={collapsible}
        onClick={() => {
          collapsible.toggleOpen();
        }}
        className={className}
        style={style}
      >
        {collapsible && <FontAwesomeIcon icon={['fas', 'angle-down']} className="toggle-icon" />}
        {icon && <FontAwesomeIcon icon={icon} />}
        {children}
        {docId && <DocsTooltip docId={docId} />}
      </S.TitleButton>
    ) : (
      <S.Title className={className} style={style}>
        {children}
      </S.Title>
    )}
  </>
);

export default FormTitle;
