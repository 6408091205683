import tw, { css, styled } from 'twin.macro';

export const Tooltip = styled.div(() => [
  css`
    ${tw`absolute shadow-lg border border-ant-border-gray rounded overflow-hidden border `}
    z-index:3000;
    min-width: 250px;
    display: block !important;

    & p {
      ${tw`font-600 text-12 mb-2`}
    }
  `
]);

export const Content = styled.div(() => [
  css`
    ${tw`bg-white font-600 px-12 py-12`}
  `
]);

export const Title = styled.div(() => [
  css`
    ${tw`bg-light-gray-active font-600 text-12 text-primary px-12 py-10 whitespace-nowrap`}
    line-height: 1;

    & svg {
      ${tw`mr-6`}

      &.arrow {
        ${tw`ml-6`}
      }
    }
  `
]);

export const Info = styled.div(() => [
  css`
    & p {
      ${tw`text-12 whitespace-nowrap `}

      & span {
        ${tw`text-primary inline-block text-11`}
        width: 76px;
      }

      &.click-info {
        ${tw`text-10 text-secondary font-600 mt-6`}
      }
    }
  `
]);

export const Context = styled.div(() => [
  css`
    ${tw`fixed shadow-lg border border-ant-border-gray rounded overflow-hidden border `}
    z-index:1000;
    top: -1000px;
    left: -1000px;

    & p {
      ${tw`font-600 text-12 mb-2`}
    }
  `
]);

export const EdgesList = styled.div(() => [
  css`
    ${tw`mr-12 mt-12`}

    svg {
      ${tw`mr-12`}
    }

    ul {
      ${tw`mt-12`}
    }

    li {
      ${tw`mb-2`}
    }
  `
]);
