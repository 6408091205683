import React from 'react';

import { Trans } from '@lingui/macro';
import { Scrollable } from '@phoenix-systems/react-layout';
import { Col, Row } from 'antd';

import * as T from 'components/_styled/tableSc';
import TestDbConnection from 'components/domain/components/testDbConnection';
import DataItem from 'components/ui/dataItem';
import PasswordInfo from 'components/ui/passwordInfo';
import { DbImport } from 'services/api/domain/dataImport';

type DbImportTableConnectionProps = {
  data: DbImport;
};

const DbImportTableConnection: React.FC<DbImportTableConnectionProps> = ({ data }) => {
  if (!data) {
    return null;
  }

  return (
    <Scrollable scrollY>
      <T.SingleContentContainer>
        <Row gutter={[12, 12]}>
          <Col xs={24} md={24}>
            <ul>
              <li>
                <DataItem
                  name={<Trans>DB url</Trans>}
                  value={data.dburl}
                  hasCopy
                  hasCopyTitle
                  breakWord
                />
              </li>
              <li>
                <DataItem name={<Trans>DB user</Trans>} value={data.dbuser} hasCopy />
              </li>
              <li>
                <DataItem
                  name={<Trans>DB password</Trans>}
                  value={<PasswordInfo value={data.dbpassword} />}
                  hasCopy
                />
              </li>
              <li>
                <DataItem name={<Trans>Driver class</Trans>} value={data.driverclass} hasCopy />
              </li>
            </ul>
          </Col>
        </Row>
        <TestDbConnection data={data} />
      </T.SingleContentContainer>
    </Scrollable>
  );
};

export default DbImportTableConnection;
