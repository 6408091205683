import React, { useEffect, useState } from 'react';

import clsx from 'clsx';
import { Collapse } from 'react-collapse';

import * as S from './collapsibleContentSc';

import Button from 'components/ui/button';

type CollapsibleContentProps = {
  header: React.ReactNode;
  className?: string;
  isOpen?: boolean;
};

const CollapsibleContent: React.FC<CollapsibleContentProps> = ({
  className,
  isOpen,
  header,
  children
}) => {
  const [isCurrentOpen, setCurrentOpen] = useState(isOpen || false);

  useEffect(() => {
    if (isOpen !== undefined) {
      setCurrentOpen(isOpen);
    }
  }, [isOpen]);

  return (
    <S.Wrapper className={clsx(['collapsible-content', isOpen && 'open', className])}>
      <S.Header className={clsx(['header', isCurrentOpen && 'open'])}>
        <Button
          icon={['far', 'angle-right']}
          className="toggle-btn"
          type="link"
          onClick={() => {
            setCurrentOpen(!isCurrentOpen);
          }}
        />
        {header}
      </S.Header>
      <S.Body>
        <Collapse isOpened={isCurrentOpen}>{children}</Collapse>
      </S.Body>
    </S.Wrapper>
  );
};

export default CollapsibleContent;
