import React, { useRef } from 'react';

import { i18n } from '@lingui/core';
import { Menu } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { CSSProperties } from 'styled-components';

import DeleteOrgUnitAction from './deleteOrgUnitAction';
import EditOrgUnitAction from './editOrgUnitAction';

import ContextNavigation from 'components/app/components/contextNavigation';
import { ContextNavigationRef } from 'components/app/components/contextNavigation/contextNavigation.types';
import Button from 'components/ui/button';
import { OrgUnit } from 'services/api/domain/orgUnit';

type OrgUnitActionsProps = {
  data: OrgUnit;
  mode: 'BUTTON' | 'ICON_BUTTON';
  popoverPlacement: TooltipPlacement;
  style?: CSSProperties;
  className?: string;
};

const { Divider } = Menu;

const OrgUnitActions: React.FC<OrgUnitActionsProps> = ({
  data,
  popoverPlacement,
  mode,
  className,
  style
}) => {
  const { username } = useParams<{ username: string }>();
  const contextNavRef = useRef<ContextNavigationRef>(null);

  return (
    <ContextNavigation
      placement={popoverPlacement}
      ref={contextNavRef}
      trigger={
        <Button
          action="actions"
          hasMenu
          isIconOnly={mode === 'ICON_BUTTON'}
          className={clsx(['org-unit-actions', className])}
          style={style}
          title={i18n._('Organisation actions')}
        />
      }
    >
      {!username && <EditOrgUnitAction data={data} />}
      <Divider />
      <DeleteOrgUnitAction mode="MENU_ITEM" data={data} />
    </ContextNavigation>
  );
};

export default OrgUnitActions;
