import { AxiosError } from 'axios';
import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';

import { api_generateDomainDiagram, api_getDomainDiagram } from './domainDiagram.queries';
import {
  DomainDiagram,
  GenerateDomainDiagramParams,
  GetDomainDiagramParams
} from './domainDiagram.types';

export const useDomainDiagram = (
  params: GetDomainDiagramParams,
  options?: UseQueryOptions<DomainDiagram, AxiosError, DomainDiagram>
): QueryObserverResult<DomainDiagram, AxiosError> =>
  useQuery<DomainDiagram, AxiosError>(
    ['domainDiagram', params],
    () => api_getDomainDiagram(params),
    options
  );

export const useGenerateDomainDiagram = (
  params: GenerateDomainDiagramParams,
  options?: UseQueryOptions<DomainDiagram, AxiosError, DomainDiagram>
): QueryObserverResult<DomainDiagram, AxiosError> =>
  useQuery<DomainDiagram, AxiosError>(
    ['domainDiagramGenerate', params],
    () => api_generateDomainDiagram(params),
    options
  );
