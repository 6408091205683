import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Menu } from 'antd';
import { omit } from 'lodash';

import DeleteUserDialog from './deleteUserDialog';

import Button from 'components/ui/button';
import { User } from 'services/api/domain/user';

const { Item } = Menu;

type DeleteUserActionProps = {
  data: User;
  mode: 'BUTTON' | 'MENU_ITEM';
};

const DeleteUserAction: React.FC<DeleteUserActionProps> = props => {
  const antProps = omit(props, ['data', 'mode']);
  const { data, mode } = props;
  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleDeleteRequest = () => {
    setDialogOpen(true);
  };

  return (
    <>
      {mode === 'MENU_ITEM' && (
        <Item
          {...antProps}
          key={`delete-trigger_${data.id}`}
          icon={<FontAwesomeIcon icon={['fas', 'trash']} />}
          onClick={handleDeleteRequest}
          className="delete"
        >
          <Trans>Delete user</Trans>
        </Item>
      )}
      {mode === 'BUTTON' && (
        <Button action="delete" onClick={handleDeleteRequest}>
          <Trans>Delete User</Trans>
        </Button>
      )}

      <DeleteUserDialog isOpen={isDialogOpen} data={data} close={() => setDialogOpen(false)} />
    </>
  );
};

export default DeleteUserAction;
