import tw, { styled, css } from 'twin.macro';

export const PageContainer = styled.div(() => [
  css`
    ${tw`flex items-center justify-center h-full`};
  `
]);

export const ContentContainer = styled.div(() => [
  css`
    ${tw`px-desktopX md:px-mobileX text-center inline-block`};

    & h2 {
      ${tw`font-700 text-primary text-32 m-0`};
    }

    & p {
      ${tw`font-700 text-black text-14`};
    }

    & a {
      ${tw`text-gray-600 text-14`};
    }
  `
]);
