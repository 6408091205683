import React, { useCallback, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import { Table } from '@phoenix-systems/react-table';
import { CustomColumnType, TableRef } from '@phoenix-systems/react-table/dist/types/types';
import moment from 'moment';

import * as S from './dbImportLogsSc';

import * as T from 'components/_styled/tableSc';
import ErrorInfo from 'components/ui/errorInfo';
import Loader from 'components/ui/loader';
import dateFormat from 'config/dateTime';
import useDomainName from 'hooks/useDomainName';
import useNotFound from 'hooks/useNotFound';
import useSchemaName from 'hooks/useSchemaName';
import { DbImport, DbImportLog } from 'services/api/domain/dataImport';
import { useDbImportResults } from 'services/api/domain/dataImport/dataImport.hooks';

type DbImportLogsProps = {
  data: DbImport;
};

export type DbImportLogsState = {
  selectedKey?: string;
};

const DbImportLogs: React.FC<DbImportLogsProps> = ({ data }) => {
  const domainName = useDomainName();
  const schema = useSchemaName();

  const resultsQuery = useDbImportResults({
    domainName,
    schema,
    dbImportName: data.name,
    rows: 100
  });
  const isNotFoundResults = useNotFound(resultsQuery);

  const tableRef = useRef<TableRef>(null);

  const getColumns = useCallback(() => {
    const columns: CustomColumnType<DbImportLog>[] = [
      {
        title: i18n._('id'),
        dataIndex: 'id',
        key: 'id',
        sorter: (a, b) => a.id.localeCompare(b.id),
        disabled: true
      },
      {
        title: i18n._('Time ago'),
        dataIndex: 'timestamp',
        key: 'timestamp',
        sorter: (a, b) => a.timestamp - b.timestamp,
        render: (value: string) => moment(value).fromNow()
      },
      {
        title: i18n._('Timestamp'),
        dataIndex: 'timestamp',
        key: 'timestamp',
        sorter: (a, b) => a.timestamp - b.timestamp,
        render: (value: string) => moment(value).format(dateFormat.dateTime)
      },
      {
        title: i18n._('Duration (ms)'),
        dataIndex: 'durationMillis',
        key: 'durationMillis',
        sorter: (a, b) => a.durationMillis - b.durationMillis
      },
      {
        title: i18n._('Inserted'),
        dataIndex: 'inserted',
        key: 'inserted',
        sorter: (a, b) => a.inserted - b.inserted
      },
      {
        title: i18n._('Refused'),
        dataIndex: 'refused',
        key: 'refused',
        sorter: (a, b) => a.refused - b.refused
      },
      {
        title: i18n._('Message'),
        dataIndex: 'message',
        key: 'message',
        render: value => <S.Message>{value}</S.Message>,
        sorter: (a, b) => a.message.localeCompare(b.message)
      },
      {
        title: i18n._('DB import name'),
        dataIndex: 'dbImportName',
        key: 'dbImportName',
        sorter: (a, b) => a.dbImportName.localeCompare(b.dbImportName),
        disabled: true
      },
      {
        title: i18n._('Domain name'),
        dataIndex: 'domainName',
        key: 'domainName',
        sorter: (a, b) => a.domainName.localeCompare(b.domainName),
        disabled: true
      },
      {
        title: i18n._('Scheme'),
        dataIndex: 'scheme',
        key: 'scheme',
        sorter: (a, b) => a.scheme.localeCompare(b.scheme),
        disabled: true
      },
      {
        title: i18n._('Entity'),
        dataIndex: 'entity',
        key: 'entity',
        sorter: (a, b) => a.entity.localeCompare(b.entity),
        disabled: true
      }
    ];

    return columns;
  }, []);

  return (
    <T.StyledTable className="table-content">
      <Table
        ref={tableRef}
        errorMessage={
          resultsQuery.isError &&
          !isNotFoundResults && (
            <ErrorInfo
              // className="table-error"
              message={
                <ErrorInfo
                  error={resultsQuery.error}
                  message={<Trans>Failed to load logs for DB import.</Trans>}
                />
              }
              error={resultsQuery.error}
            />
          )
        }
        table={{
          dataSource: resultsQuery.data,
          loading: resultsQuery.isFetching,
          size: 'small',
          rowKey: 'id',
          columns: getColumns(),
          pagination: {
            pageSizeOptions: ['20', '50', '100'],
            showSizeChanger: true
          }
        }}
        fillHeight
        layout="default"
        topNavElements={{
          search: false,
          totalCount: {
            icon: <FontAwesomeIcon icon={['fas', 'clock']} />,
            loader: <Loader size={12} />
          },
          reload: {
            onChange: () => {
              resultsQuery.refetch();
            }
          }
        }}
        activeFilters={{ placement: 'default', enabled: true }}
      />
    </T.StyledTable>
  );
};

export default DbImportLogs;
