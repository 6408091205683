import { Button } from 'antd';
import tw, { css, styled } from 'twin.macro';

export const TestButton = styled(Button)(() => [
  css`
    /*  ${tw`py-8`}

    & span {
      ${tw`text-12 text-primary inline-block`};
      min-width: 5.5rem;
    } */
  `
]);
