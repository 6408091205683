import { AxiosError } from 'axios';
import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';

import { api_getMetadata } from './metadata.queries';
import { EntityMetaData, FindMetadtaParams } from './metadata.types';

// eslint-disable-next-line import/prefer-default-export
export const useMetadata = (
  params: FindMetadtaParams,
  options?: UseQueryOptions<EntityMetaData, AxiosError, EntityMetaData>
): QueryObserverResult<EntityMetaData, AxiosError> =>
  useQuery<EntityMetaData, AxiosError>(
    ['metadata', params],
    () => api_getMetadata(params),
    options
  );
