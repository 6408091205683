import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { ButtonProps } from 'antd';
import { useImmer } from 'use-immer';

import * as S from './triggerPlaySc';

import ActivateTriggerDialog from 'components/trigger/actions/activateTriggerActions/activateTriggerDialog';
import { Trigger, TriggerDialogActivationMode } from 'services/api/domain/trigger';

type TriggerPlayProps = Omit<ButtonProps, 'onClick'> & {
  active: boolean;
  data?: Trigger;
};

type TriggerPlayState = {
  isHovered: boolean;
  isDialogOpen: boolean;
  activationMode: TriggerDialogActivationMode;
};

const TriggerPlay: React.FC<TriggerPlayProps> = ({ data, active, style, size }) => {
  const [state, setState] = useImmer<TriggerPlayState>({
    isHovered: false,
    isDialogOpen: false,
    activationMode: 'ACTIVATE'
  });

  const icon = <FontAwesomeIcon icon={['fas', active ? 'play' : 'stop']} />;
  const hoveredIcon = <FontAwesomeIcon icon={['fas', active ? 'stop' : 'play']} />;

  const handleActivation = () => {
    if (data) {
      setState(draft => {
        draft.isDialogOpen = true;
        draft.activationMode = data.active ? 'DEACTIVATE' : 'ACTIVATE';
      });
    }
  };

  return (
    <>
      <S.StyledTriggerButton
        style={style}
        size={size}
        $active={state.isHovered ? !active : active}
        onMouseOver={() =>
          setState(draft => {
            draft.isHovered = true;
          })
        }
        onMouseOut={() =>
          setState(draft => {
            draft.isHovered = false;
          })
        }
        title={active ? i18n._('Deactivate trigger') : i18n._('Activate trigger')}
        onClick={handleActivation}
        icon={state.isHovered ? hoveredIcon : icon}
      />

      {state.isDialogOpen && (
        <ActivateTriggerDialog
          mode={state.activationMode}
          data={data}
          isOpen={state.isDialogOpen}
          close={() =>
            setState(draft => {
              draft.isDialogOpen = false;
            })
          }
        />
      )}
    </>
  );
};

export default TriggerPlay;
