import { Select } from 'antd';
import tw, { styled, css } from 'twin.macro';

export const SelectorContainer = styled.div(() => [
  css`
    ${tw`relative w-full ml-12 relative`};

    & button.info-btn {
      ${tw`absolute`};
    }
  `
]);

export const SelectInput = styled(Select)(() => [
  css`
    &.ant-select {
      min-width: 160px;

      &.readonly {
        opacity: 0.5;
        pointer-events: none;
      }

      & .ant-select-selector {
        ${tw`h-32 shadow-sm`};
      }

      & .ant-select-selection-item {
        ${tw`font-600 text-primary border-primary`};
        line-height: 32px;
      }
    }
  `
]);
