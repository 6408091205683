import React from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as S from './entityEdgeSc';

import { EntityEdge } from 'services/api/domain/domainDiagram';

type EntityEdgeNameProps = {
  edge: EntityEdge;
  iconBefore?: IconProp;
};

const EntityEdgeName: React.FC<EntityEdgeNameProps> = ({ edge, iconBefore }) => (
  <S.Label>
    {iconBefore && <FontAwesomeIcon icon={iconBefore} className="icon before" />}
    {edge.data?.sourceNode?.data?.simpleClassName}.{edge.data?.sourceField?.name}
    <FontAwesomeIcon icon={['fas', 'angle-right']} className="icon arrow" />
    {edge.data?.targetNode?.data?.simpleClassName}
  </S.Label>
);

export default EntityEdgeName;
