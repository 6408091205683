import React, { useState } from 'react';

import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { useFormState } from '@phoenix-systems/react-form';
import { Modal } from 'antd';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import useContainer from 'components/app/components/containerProvider/useContainer';
import TriggerForm from 'components/trigger/components/triggerForm';
import Button from 'components/ui/button';
import ErrorInfo from 'components/ui/errorInfo';
import useDefaultModalProps from 'hooks/useDefaultModalProps';
import useNotification from 'hooks/useNotification';
import { api_createTrigger, Trigger } from 'services/api/domain/trigger';
import { log } from 'utils';

type TriggerFormDialogPros = {
  data?: Partial<Trigger>;
  isOpen: boolean;
  close: () => void;
  isTaskReadonly?: boolean;
};

const TriggerFormDialog: React.FC<TriggerFormDialogPros> = ({
  data,
  isOpen,
  close,
  isTaskReadonly
}) => {
  const { dialogContainer } = useContainer();
  const [addNotification] = useNotification();
  const { formInstance, isValid, hasChanged } = useFormState();
  const [name, setName] = useState<string>();
  const queryClient = useQueryClient();
  const modalProps = useDefaultModalProps();

  const createMutation = useMutation<Trigger, AxiosError, Partial<Trigger>>(
    params => api_createTrigger(params),
    {
      onSuccess: resData => {
        addNotification({
          type: 'success',
          message: i18n._(t`Successfully created trigger "${resData.name}".`)
        });

        queryClient.refetchQueries(['triggers']);
        close();
      },
      onError: () => {
        addNotification({
          type: 'error',
          message: i18n._(t`Failed to create trigger "${name}".`)
        });
      }
    }
  );

  const handleSave = async () => {
    let formData: Partial<Trigger> | undefined;

    if (formInstance) {
      try {
        formData = await formInstance.validateFields();
      } catch (validationError) {
        log(validationError);
      }

      if (formData) {
        setName(formData.name);
        createMutation.mutate(formData);
      }
    }
  };

  return (
    <Modal
      {...modalProps}
      visible={isOpen}
      onCancel={() => close()}
      onOk={handleSave}
      title={<Trans>Add trigger</Trans>}
      getContainer={() => dialogContainer}
      width={800}
      centered
      closable={false}
      footer={
        <>
          <Button
            action={createMutation.isSuccess ? 'close' : 'cancel'}
            disabled={createMutation.isLoading}
          />
          <Button
            action="save"
            onClick={handleSave}
            loading={createMutation.isLoading}
            disabled={createMutation.isLoading || !isValid || !hasChanged}
          >
            <Trans>Save Trigger</Trans>
          </Button>
        </>
      }
    >
      <TriggerForm isDialog isDomain data={data} isTaskReadonly={isTaskReadonly} mode="EDIT" />

      {createMutation.isError && (
        <ErrorInfo
          style={{ marginTop: 12 }}
          error={createMutation.error}
          message={<Trans>Failed to create trigger.</Trans>}
        />
      )}
    </Modal>
  );
};

export default TriggerFormDialog;
