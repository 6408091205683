import memoize from 'fast-memoize';
import { produce } from 'immer';
import { cloneDeep, assign } from 'lodash';
import { v4 as uuid } from 'uuid';

import { DomainDiagramActionCreators } from './domainDiagram.actions';
import {
  DomainDiagramActionTypes,
  DomainDiagramState,
  EntityNodeFieldType,
  EntityNodeNameSpace
} from './domainDiagram.types';

import {
  castGenericField,
  getFieldNameFromOutHandle,
  getHandleId,
  getHandleTypeFromSourceHandle,
  getHandleTypeFromTargetHandle,
  getNodeByClassName,
  getNodeById,
  isGenericType,
  uncastGenericField
} from 'components/domain/components/domainDiagram/utils';
import {
  AggregationType,
  DiagramElement,
  EntityEdge,
  EntityNode,
  EntityNodeField,
  EnumFieldType,
  GenericFieldType,
  HandleType,
  PrimitiveFieldType,
  RealationType
} from 'services/api/domain/domainDiagram';
import { logError, logWarn } from 'utils';

const initialState: DomainDiagramState = Object.freeze<DomainDiagramState>({
  elements: [],
  nodes: [],
  edges: [],
  hasChanged: false,
  contextNavIsOpen: false,
  dialogIsOpen: false,
  fieldTypes: [],
  activeHandles: [],
  activeNode: undefined
});

const getFieldTypesFunc = (nameSpaces: EntityNodeNameSpace[]) => {
  let types: EntityNodeFieldType[] = [];
  const genericObjects: EntityNodeFieldType[] = [];
  const genericLists: EntityNodeFieldType[] = [];
  const classNames: string[] = [];

  nameSpaces.forEach(nameSpace => {
    if (nameSpace.className && nameSpace.simpleClassName) {
      genericObjects.push({
        name: nameSpace.simpleClassName,
        className: nameSpace.className,
        multipleName: classNames.indexOf(nameSpace.className) !== -1,
        isRelational: true
      });

      genericLists.push({
        name: `List<${nameSpace.simpleClassName}>`,
        className: nameSpace.className,
        multipleName: classNames.indexOf(nameSpace.className) !== -1,
        isRelational: true
      });

      if (!classNames.includes(nameSpace.className)) {
        classNames.push(nameSpace.className);
      }
    }
  });

  // primitives
  Object.values(PrimitiveFieldType).forEach(val =>
    types.push({
      name: val,
      multipleName: false,
      isRelational: false
    })
  );

  // generic
  Object.values(GenericFieldType).forEach(val =>
    types.push({
      name: val,
      multipleName: false,
      isRelational: false
    })
  );
  types = [...types, ...genericObjects];

  // primitive list
  Object.values(PrimitiveFieldType).forEach(val =>
    types.push({
      name: `List<${val}>`,
      multipleName: false,
      isRelational: false
    })
  );
  types.push({
    name: 'List<Object>',
    multipleName: false,
    isRelational: false
  });
  types = [...types, ...genericLists];

  // maps
  Object.values(PrimitiveFieldType).forEach(val1 => {
    Object.values(PrimitiveFieldType).forEach(val2 => {
      types.push({ name: `Map<${val1},${val2}>`, multipleName: false, isRelational: false });
    });
  });

  // enums
  Object.values(EnumFieldType).forEach(val =>
    types.push({
      name: val,
      multipleName: false,
      isRelational: false
    })
  );

  return types;
};

const getFieldTypes = memoize(getFieldTypesFunc);

const getUpdatedElementsFunc = (
  _nodesIn: EntityNode[],
  _edgesIn: EntityEdge[],
  isEdgePriorized?: boolean
) => {
  const nodesIn = cloneDeep(_nodesIn);
  const edgesIn = cloneDeep(_edgesIn);

  const edges: EntityEdge[] = [];
  const nodes: EntityNode[] = [];

  nodesIn.forEach(_node => {
    const node = assign({}, _node);
    const fields = node.data?.fields;

    fields?.forEach(field => {
      if (isGenericType(field.type, true)) {
        const targetNode = getNodeByClassName(nodesIn, field.className);

        if (targetNode) {
          const sourceHandle = getHandleId(HandleType.FIELD_OUT, node.data?.className, field.name);
          const targetHandle = getHandleId(HandleType.ENTITY_IN, targetNode.data?.className);

          if (sourceHandle && targetHandle) {
            const [foundEdge] = edgesIn.filter(
              edg => edg.sourceHandle === sourceHandle && edg.targetHandle === targetHandle
            );

            if (foundEdge?.data) {
              if ((!field.aggregationType && !field.relationType) || isEdgePriorized) {
                field.aggregationType = foundEdge.data.aggregationType;
                field.relationType = foundEdge.data.relationType;
              } else if (field.aggregationType && field.relationType) {
                foundEdge.data.aggregationType = field.aggregationType;
                foundEdge.data.relationType = field.relationType;
              }

              // uncast field if edge changed relation type
              if (isEdgePriorized) {
                if (
                  field.type.search('List<') !== -1 &&
                  foundEdge.data?.relationType === RealationType.ONE_TO_ONE_RELATED
                ) {
                  logWarn('change field type on edge relation change');
                  field.type = field.type.replace('List<', '').replace('>', '');
                  field.relationType = RealationType.ONE_TO_ONE_RELATED;
                }

                if (
                  field.type.search('List<') === -1 &&
                  foundEdge.data?.relationType === RealationType.ONE_TO_MANY_RELATED
                ) {
                  logWarn('change field type on edge relation change');
                  field.type = `List<${field.type}>`;
                  field.relationType = RealationType.ONE_TO_MANY_RELATED;
                }
              }

              edges.push(foundEdge);
            } else if (field.aggregationType && field.relationType) {
              logWarn(`edge not found in generic field ${field.className}. add edge...`);

              const newEdge: EntityEdge = {
                animated: true,
                id: `${uuid()}`,
                type: 'entityEdge',
                source: node.id,
                sourceHandle,
                target: targetNode.id,
                targetHandle,
                data: {
                  targetNode,
                  sourceNode: node,
                  relationType: field.relationType,
                  aggregationType: field.aggregationType
                }
              };

              edges.push(newEdge);
            } else {
              logWarn(
                `edge not found in generic field ${field.className}. can not add edge, aggregation or relation missing...`
              );
            }
          } else {
            // handles not found in entities -> may fix handles ?
            logWarn(' handles not found in entities');
          }
        } else {
          // target class missing -> uncast the field type into simple generic Object, List<Object>
          logWarn('target class missing, field casted back', field);
          uncastGenericField(field);
        }
      }
    });

    nodes.push(node);
  });

  edgesIn.forEach(_edge => {
    const edge = assign({}, _edge);
    const foundEdge = edges.find(e => e.id === edge.id);

    if (!foundEdge && edge.id.search(/reactflow__edge-/) !== -1) {
      edge.id = uuid();
      edges.push(edge);

      if (!edge.data) {
        edge.data = {
          aggregationType: AggregationType.AGGREGATION,
          relationType: RealationType.ONE_TO_MANY_RELATED
        };
      }

      // cast generic field
      const source = getNodeById(nodes, edge.source);
      const target = getNodeById(nodes, edge.target);

      if (source.data?.fields && target.data && edge.sourceHandle) {
        const fieldName = getFieldNameFromOutHandle(edge.sourceHandle, source.data?.className);

        if (fieldName !== '') {
          source.data?.fields.forEach(_field => {
            let field = _field;

            if (field.name === fieldName && target.data) {
              field = castGenericField(field, target.data);
            }
          });
        }
      }
    }
  });

  edges.forEach(_edge => {
    const edge = assign({}, _edge);

    if (edge.data) {
      edge.data.sourceNode = getNodeById(nodes, edge.source);
      edge.data.targetNode = getNodeById(nodes, edge.target);
      edge.data.sourceHandleType = getHandleTypeFromSourceHandle(edge) || undefined;
      edge.data.targetHandleType = getHandleTypeFromTargetHandle(edge) || undefined;

      if (edge.data?.sourceNode?.data && edge.sourceHandle) {
        const fieldName = getFieldNameFromOutHandle(
          edge.sourceHandle,
          edge.data.sourceNode.data.className
        );
        const [sourceFiled] = edge.data.sourceNode.data.fields.filter(
          field => field.name === fieldName
        );
        edge.data.sourceField = sourceFiled;
      }

      if (edge.data?.targetNode?.data && edge.targetHandle) {
        const fieldName = getFieldNameFromOutHandle(
          edge.targetHandle,
          edge.data.targetNode.data.className
        );
        const targetField = edge.data.targetNode.data.fields.find(
          field => field.name === fieldName
        );
        edge.data.targetField = targetField;
      }
    }
  });

  return { edges, nodes };
};

const getUpdatedElements = memoize(getUpdatedElementsFunc);

type ReturnType = {
  elements: DiagramElement[];
  nodes: EntityNode[];
  edges: EntityEdge[];
  fieldTypes: EntityNodeFieldType[];
};
const updateState = (
  elements: DiagramElement[],
  //  state: DomainDiagramState,
  isEdgePriorized?: boolean
): ReturnType => {
  const nameSpaces: EntityNodeNameSpace[] = [];
  const nodes: EntityNode[] = [];
  const edges: EntityEdge[] = [];

  elements.forEach(_el => {
    const el = cloneDeep(_el);

    if (el.type === 'entityNode') {
      const nameSpace: EntityNodeNameSpace = {
        simpleClassName: (el as EntityNode).data?.simpleClassName,
        className: (el as EntityNode).data?.className,
        id: el.id
      };
      nameSpaces.push(nameSpace);
      nodes.push(el as EntityNode);
    } else {
      const edge = el as EntityEdge;
      edge.id = edge.id || uuid();
      edge.type = edge.type || 'entityEdge';
      edge.animated = true;
      edges.push(edge);
    }
  });

  const updatedElements = getUpdatedElements(nodes, edges, isEdgePriorized);

  return {
    elements: [...updatedElements.nodes, ...updatedElements.edges],
    nodes: updatedElements.nodes,
    edges: updatedElements.edges,
    fieldTypes: getFieldTypes(nameSpaces)
  };
};

/**
 * -----------------------------------------------------------------------------------------------
 * DOMAIN DIAGRAM REDUCER
 *
 */

const domainDiagramReducer = (
  state: DomainDiagramState = initialState,
  action: DomainDiagramActionCreators
): DomainDiagramState =>
  produce(state, draft => {
    switch (action.type) {
      case DomainDiagramActionTypes.SET_ELEMENTS: {
        const updatedDrafts = updateState(action.elements);

        draft.hasChanged = false;
        draft.elements = updatedDrafts.elements;
        draft.fieldTypes = updatedDrafts.fieldTypes;
        draft.edges = updatedDrafts.edges;
        draft.nodes = updatedDrafts.nodes;

        break;
      }

      case DomainDiagramActionTypes.UPDATE_ELEMENTS: {
        const updatedDrafts = updateState(action.elements);

        draft.hasChanged = true;
        draft.elements = updatedDrafts.elements;
        draft.fieldTypes = updatedDrafts.fieldTypes;
        draft.edges = updatedDrafts.edges;
        draft.nodes = updatedDrafts.nodes;

        break;
      }

      case DomainDiagramActionTypes.ADD_ELEMENT: {
        const updatedDrafts = updateState([...draft.elements, action.element]);
        draft.hasChanged = true;
        draft.elements = updatedDrafts.elements;
        draft.fieldTypes = updatedDrafts.fieldTypes;
        draft.edges = updatedDrafts.edges;
        draft.nodes = updatedDrafts.nodes;

        break;
      }

      case DomainDiagramActionTypes.UPDATE_ELEMENT: {
        const updatedDrafts = updateState([
          ...draft.elements.filter(el => el.id !== action.element.id),
          action.element
        ]);
        draft.hasChanged = true;
        draft.elements = updatedDrafts.elements;
        draft.fieldTypes = updatedDrafts.fieldTypes;
        draft.edges = updatedDrafts.edges;
        draft.nodes = updatedDrafts.nodes;

        break;
      }

      case DomainDiagramActionTypes.UPDATE_NODE_DATA: {
        const nodeOrigin: DiagramElement | undefined = state.elements.find(
          el => el.id === action.id
        );

        if (!nodeOrigin) {
          logError(`Node with id "${action.id}" was not found`);

          return;
        }

        const node = cloneDeep(nodeOrigin);

        node.data = action.data;
        const otherElements = state.elements.filter(el => el.id !== action.id);
        const newElements = [...otherElements, node];

        if (action.addEdge) {
          newElements.push(action.addEdge);
        }

        const updatedDrafts = updateState(newElements);
        draft.hasChanged = true;
        draft.elements = updatedDrafts.elements;
        draft.fieldTypes = updatedDrafts.fieldTypes;
        draft.edges = updatedDrafts.edges;
        draft.nodes = updatedDrafts.nodes;

        break;
      }

      case DomainDiagramActionTypes.REMOVE_ELEMENT: {
        const updatedDrafts = updateState(draft.elements.filter(el => el.id !== action.id));
        draft.hasChanged = true;
        draft.elements = updatedDrafts.elements;
        draft.fieldTypes = updatedDrafts.fieldTypes;
        draft.edges = updatedDrafts.edges;
        draft.nodes = updatedDrafts.nodes;

        break;
      }

      case DomainDiagramActionTypes.SET_ACTIVE_HANDLES:
        draft.activeHandles = action.handleIds;

        return;

      case DomainDiagramActionTypes.SET_CONTEXTNAV_OPEN:
        draft.contextNavIsOpen = action.open;

        return;

      case DomainDiagramActionTypes.SET_DIALOG_OPEN:
        draft.dialogIsOpen = action.open;

        return;

      case DomainDiagramActionTypes.CLEANUP_ELEMENTS: {
        const elements = getUpdatedElements(draft.nodes, draft.edges);

        draft.nodes = elements.nodes;
        draft.edges = elements.edges;
        draft.elements = [...elements.nodes, ...elements.edges];

        return;
      }
      case DomainDiagramActionTypes.SET_ACTIVE_EDGE:
        if (action.id) {
          const [edge] = state.edges.filter(edg => edg.id === action.id);
          draft.activeEdge = edge;

          return;
        }

        draft.activeEdge = undefined;

        return;

      case DomainDiagramActionTypes.SET_HAS_CHANGED:
        draft.hasChanged = action.changed;

        return;

      case DomainDiagramActionTypes.SET_CONTEXT_EDGE:
        draft.contextEdge = action.edgeData;
        draft.contextAnchor = action.anchor;

        return;

      case DomainDiagramActionTypes.SET_ACTIVE_NODE:
        draft.activeNode = action.nodeId;

        return;

      case DomainDiagramActionTypes.UPDATE_EDGE_DATA: {
        const originEdge: DiagramElement | undefined = state.elements.find(
          el => el.id === action.edgeId
        );

        if (!originEdge) {
          logError(`Edge with id "${action.edgeId}" was not found`);

          return;
        }

        const edge = cloneDeep(originEdge);
        edge.data = action.edgeData;
        const otherElements = state.elements.filter(el => el.id !== action.edgeId);
        const newElements = [...otherElements, edge];

        const updatedDrafts = updateState(newElements, true);
        draft.hasChanged = true;
        draft.elements = updatedDrafts.elements;
        draft.fieldTypes = updatedDrafts.fieldTypes;
        draft.edges = updatedDrafts.edges;
        draft.nodes = updatedDrafts.nodes;

        break;
      }

      case DomainDiagramActionTypes.RESET:
        draft.elements = [];
        draft.nodes = [];
        draft.edges = [];
        draft.hasChanged = false;
        draft.contextNavIsOpen = false;
        draft.dialogIsOpen = false;
        draft.fieldTypes = [];
        draft.activeHandles = [];
        draft.activeNode = undefined;

        break;

      case DomainDiagramActionTypes.REMOVE_EDGE: {
        const originEdge = draft.edges.find(edg => edg.id === action.id);

        if (!originEdge) {
          break;
        }

        const edge = cloneDeep(originEdge);

        if (edge && edge.data?.sourceHandleType === HandleType.FIELD_OUT) {
          if (edge.sourceHandle && edge.data.sourceNode?.data?.className) {
            const fieldName = getFieldNameFromOutHandle(
              edge.sourceHandle,
              edge.data.sourceNode?.data?.className
            );

            if (edge.data.sourceNode?.data?.fields) {
              const newFields: EntityNodeField[] = [];
              edge.data.sourceNode?.data?.fields.forEach(_field => {
                let field = assign({}, _field);

                if (field.name === fieldName) {
                  field = uncastGenericField(field);
                  field.aggregationType = AggregationType.AGGREGATION;
                }
                newFields.push(field);
              });

              edge.data.sourceNode.data.fields = newFields;

              // update element
              if (edge.data?.sourceNode) {
                const updatedDrafts = updateState([
                  ...draft.elements.filter(el => el.id !== edge.data?.sourceNode?.id),
                  edge.data.sourceNode
                ]);
                draft.hasChanged = true;
                draft.elements = updatedDrafts.elements;
                draft.fieldTypes = updatedDrafts.fieldTypes;
                draft.edges = updatedDrafts.edges;
                draft.nodes = updatedDrafts.nodes;
              }
            }
          }
        }

        if (edge && edge.data?.sourceHandleType === HandleType.ENTITY_OUT) {
          // remove element
          const updatedDrafts = updateState(draft.elements.filter(el => el.id !== action.id));
          draft.hasChanged = true;
          draft.elements = updatedDrafts.elements;
          draft.fieldTypes = updatedDrafts.fieldTypes;
          draft.edges = updatedDrafts.edges;
          draft.nodes = updatedDrafts.nodes;
        }

        break;
      }

      default:
        break;
    }
  });

export default domainDiagramReducer;
