import React from 'react';

import AppLayout from 'components/app/components/appLayout';
import DocsPage from 'components/docs/components/docsPage';
import routes from 'config/routes/routes';

const DocsSinglePage: React.FC = () => (
  <AppLayout>
    <DocsPage route={routes.docs.single} />
  </AppLayout>
);

export default DocsSinglePage;
