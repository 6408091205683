import { ListQueryParams } from '@phoenix-systems/react-table/dist/types/types';

export enum TriggersTableActionTypes {
  SET_PARAMS = 'triggersTable/SET_PARAMS',
  SET_SINGLE_VIEW = 'triggersTable/SET_SINGLE_VIEW',
  SET_SELECTED_KEY = 'triggersTable/SET_SELECTED_KEY',
  RESET = 'triggersTable/RESET'
}

export type TriggersTableSingleView = 'OVERVIEW';

export type TriggersTableState = Readonly<{
  selectedKey?: string;
  params: ListQueryParams;
  singleView: TriggersTableSingleView;
}>;
