/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import {
  CollectionEditor,
  FileSelector,
  FormItem,
  useFormContext,
  ExtendedFile,
  FileSelectorRef,
  CollectionEditorDataItem
} from '@phoenix-systems/react-form';
import { Alert, Divider, Form, Input, Popover, Radio } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { RuleObject } from 'antd/lib/form';
import { AxiosError } from 'axios';
import { Collapse } from 'react-collapse';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useImmer } from 'use-immer';

import CsvFileSelector from '../csvFileSelector';
import { CsvFileSelectorRef } from '../csvFileSelector/csvFileSelector';
import EntityFieldSelector from '../entityFieldSelector';
import FtpConnectionSelector from '../ftpConnectionSelector';

import * as S from './csvImportFormSc';
import CsvImportMapperDialog from './csvImportMapperDialog';

import * as F from 'components/_styled/formSc';
import useContainer from 'components/app/components/containerProvider/useContainer';
import DocsTooltip from 'components/docs/components/docsTooltip';
import TooltipLabel from 'components/docs/components/docsTooltip/tooltipLabel';
import TriggerAdder from 'components/trigger/components/triggerAdder';
import Button from 'components/ui/button';
import ErrorInfo from 'components/ui/errorInfo';
import FormContainer from 'components/ui/formContainer';
import FormTitle from 'components/ui/formTitle';
import LoaderMessage from 'components/ui/loader/loaderMessage';
import NoContent from 'components/ui/noContent';
import collectionEditorLocales from 'config/langauge/collectionEditor.locales';
import fileSelectorLocales from 'config/langauge/fileSelector.locales';
import routes from 'config/routes/routes';
import useCsvImportName from 'hooks/useCsvImportName';
import useDomainName from 'hooks/useDomainName';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';
import useNotification from 'hooks/useNotification';
import useSchemaName from 'hooks/useSchemaName';
import {
  api_createCsvImport,
  api_updateCsvImport,
  api_uploadCsvFile,
  ColumnEntityMapping,
  CreateCsvImportParams,
  CsvImport,
  FileMetaData,
  UpdateCsvImportParams,
  UploadCSVFileParams,
  useCsvFileHeaderInfo
} from 'services/api/domain/csvImport';
import { FTPConnection } from 'services/api/domain/ftpConnection';
import { DocId } from 'services/api/domain/hint';
import { Trigger } from 'services/api/domain/trigger';
import {
  st_dbImportsTable_setActiveKey,
  st_dbImportsTable_setParams
} from 'services/store/dbImportsTable/dbImportsTable.actions';

type CsvImportFormProps = {
  data?: CsvImport;
};

type CollapseKey =
  | 'isUploadOpen'
  | 'isFtpOpen'
  | 'isFilesListOpen'
  | 'isMapperOpen'
  | 'isTriggersOpen';

type CollapseConfig = { [key in CollapseKey]: boolean };

type CsvImportFormState = {
  existingImports?: CsvImport[];
  csvFile?: ExtendedFile;
  isCsvFilUploaded?: boolean;
  selectedCsvFile?: FileMetaData;
  isHeaderDataLoaded: boolean;
  ftpConnection?: FTPConnection;
  collapse: CollapseConfig;
};

const DEFAULT_DATA: Partial<CsvImport> = {
  updatecreate: false,
  delimiter: ',',
  header: true
};

const CsvImportForm: React.FC<CsvImportFormProps> = ({ data }) => {
  const domainName = useDomainName();
  const schemaName = useSchemaName();
  const csvImportName = useCsvImportName();
  const [form] = Form.useForm<CsvImport>();
  const [formState, formActions] = useFormContext(form, data || DEFAULT_DATA, data !== undefined);
  const [addNotification] = useNotification();
  const dispatch = useDispatch();
  const goto = useGoToDomainRoute();
  const fileSelectorRef = useRef<FileSelectorRef>(null);
  const csvFileSelectorRef = useRef<CsvFileSelectorRef>(null);
  const { formContainer } = useContainer();

  const [state, setState] = useImmer<CsvImportFormState>({
    existingImports: undefined,
    isHeaderDataLoaded: false,
    collapse: {
      isUploadOpen: data === undefined,
      isFtpOpen: true,
      isFilesListOpen: true,
      isMapperOpen: data !== undefined,
      isTriggersOpen: data !== undefined
    }
  });

  const toggleOpen = (key: CollapseKey) => {
    setState(draft => {
      draft.collapse[key] = !draft.collapse[key];
    });
  };

  const csvHeaderQuery = useCsvFileHeaderInfo(
    {
      domainName,
      schemaName,
      importName: data?.name || ''
    },
    {
      enabled: false,
      onSuccess: () => {
        setState(draft => {
          draft.isHeaderDataLoaded = true;
        });
      },
      onError: () => {
        setState(draft => {
          draft.isHeaderDataLoaded = false;
        });
      }
    }
  );
  const { current: csvHeaderQueryRef } = useRef(csvHeaderQuery);

  const uploadCsvMutation = useMutation(
    (params: UploadCSVFileParams) => api_uploadCsvFile(params),
    {
      onError: () => {
        addNotification({
          type: 'error',
          message: i18n._('Failed to upload CSV file.')
        });
      },
      onSuccess: resData => {
        if (state.csvFile) {
          setState(draft => {
            draft.isCsvFilUploaded = true;
          });

          fileSelectorRef.current?.updateFileState({
            id: state.csvFile.uid,
            percent: 100,
            status: 'done',
            uploadResponse: resData
          });
        }
        addNotification({
          type: 'success',
          message: i18n._('Successfully uploaded csv file.')
        });
      }
    }
  );

  const updateMutation = useMutation<CsvImport, AxiosError, UpdateCsvImportParams>(
    params => api_updateCsvImport(params),
    {
      onError: () => {
        addNotification({
          type: 'error',
          message: i18n._(t`Failed to update CSV import "${data?.name}".`)
        });
      },
      onSuccess: resData => {
        addNotification({
          type: 'success',
          message: i18n._(t`Successfully updated CSV import "${resData?.name}".`)
        });

        if (resData.name !== csvImportName) {
          goto(routes.domain.dataManagement.importFromCsv.single, { path: resData.name });

          return;
        }

        csvHeaderQuery.refetch();
        updateMutation.reset();
        formActions.reset(resData, true);
      }
    }
  );

  const createMutation = useMutation<CsvImport, AxiosError, CreateCsvImportParams>(
    params => api_createCsvImport(params),
    {
      onSuccess: resData => {
        addNotification({
          type: 'success',
          message: i18n._(t`Successfully created CSV import "${resData.name}".`)
        });

        dispatch(st_dbImportsTable_setParams(undefined));
        dispatch(st_dbImportsTable_setActiveKey(resData.name));

        goto(routes.domain.dataManagement.importFromCsv.single, { path: resData.name });
      },
      onError: () => {
        addNotification({
          type: 'error',
          message: i18n._(t`Failed to create CSV import "${form.getFieldValue('name')}".`)
        });
      }
    }
  );

  const handlePrepareTriggerData = () => {
    const formData = form.getFieldsValue();
    const triggerData: Partial<Trigger> = {
      taskDomain: domainName,
      taskScheme: schemaName,
      taskName: formData.name,
      taskType: 'CSVFileImport',
      name: `${formData.name}Trigger`,
      active: false,
      periodicity: 'NONE'
    };

    return triggerData;
  };

  const handleSave = () => {
    form.validateFields().then(formData => {
      if (data) {
        updateMutation.mutate({
          payload: formData,
          domainName,
          schemaName
        });

        return;
      }

      createMutation.mutate({
        payload: formData,
        domainName,
        schemaName
      });
    });
  };

  const handleUploadProgress = (event: ProgressEvent) => {
    if (state.csvFile && !state.isCsvFilUploaded) {
      const percentage = Math.round((event.loaded / state.csvFile.size) * 100);
      fileSelectorRef.current?.updateFileState({
        id: state.csvFile.uid,
        percent: percentage
      });
    }
  };

  const handleUploadFile = () => {
    if (state.csvFile) {
      setState(draft => {
        draft.isCsvFilUploaded = false;
      });
      fileSelectorRef.current?.updateFileState({
        id: state.csvFile.uid,
        status: 'uploading',
        percent: 0
      });

      uploadCsvMutation.mutate({
        schemaName,
        domainName,
        file: state.csvFile,
        onUploadProgress: handleUploadProgress
      });
    }
  };

  const validateName = (rule: RuleObject, value: string) =>
    new Promise((resolve, reject) => {
      if (
        state.existingImports?.find(dbImport => dbImport.name === value && dbImport.id !== data?.id)
      ) {
        reject(new Error(`Name "${value}" is already in use in different task.`));
      }

      resolve(value);
    });

  const handleReset = useCallback(() => {
    updateMutation.reset();
    formActions.reset(data, data !== undefined);
  }, [formActions, data, updateMutation]);

  useEffect(() => {
    csvFileSelectorRef.current?.refetch('LOCAL');
  }, [state.isCsvFilUploaded, csvFileSelectorRef]);

  useEffect(() => {
    if (data && !state.isHeaderDataLoaded) {
      csvHeaderQueryRef.refetch();
    }
  }, [data, csvHeaderQueryRef, state.isHeaderDataLoaded]);

  return (
    <FormContainer
      buttons={
        <>
          <Button
            action="reset"
            disabled={updateMutation.isLoading || !formState.hasChanged}
            onClick={handleReset}
          />
          <Button
            action="save"
            disabled={
              updateMutation.isLoading ||
              createMutation.isLoading ||
              !formState.isValid ||
              !formState.hasChanged
            }
            loading={updateMutation.isLoading}
            onClick={handleSave}
          />
        </>
      }
      title={
        data ? (
          <>
            <FontAwesomeIcon icon={['fas', 'file-import']} />
            <Trans>Edit CSV Import</Trans>
            <DocsTooltip docId={DocId.DOMAIN_IMPORT_FROM_CSV} />
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={['fas', 'file-import']} />
            <Trans>Create CSV Import</Trans>
            <DocsTooltip docId={DocId.DOMAIN_IMPORT_FROM_CSV} />
          </>
        )
      }
      messages={
        <>
          {updateMutation.isError && (
            <ErrorInfo
              message={
                <Trans>
                  Failed to updated CSV import &quot;{form.getFieldValue('name')}&quot;.
                </Trans>
              }
              error={updateMutation.error}
            />
          )}
          {createMutation.isError && (
            <ErrorInfo
              message={
                <Trans>Failed to create CSV import &quot;{form.getFieldValue('name')}&quot;.</Trans>
              }
              error={createMutation.error}
            />
          )}
        </>
      }
    >
      <F.StyledForm
        form={form}
        initialValues={data || DEFAULT_DATA}
        layout="horizontal"
        $labelWidth={142}
        onValuesChange={formActions.onValuesChange}
        onFieldsChange={formActions.onFieldsChange}
        scrollToFirstError
        name="csv-import-form"
        noValidate
        autoComplete="off"
        colon={false}
      >
        {data?.id && (
          <FormItem
            label={
              <TooltipLabel docId={DocId.DOMAIN_IMPORT_FROM_CSV_ID} label={<Trans>Id</Trans>} />
            }
            messageVariables={{ name: i18n._(t`Id`) }}
            name="id"
            rules={[{ required: true }]}
            registerField={formActions.registerField}
          >
            <Input readOnly />
          </FormItem>
        )}

        <FormItem
          label={
            <TooltipLabel docId={DocId.DOMAIN_IMPORT_FROM_CSV_NAME} label={<Trans>Name</Trans>} />
          }
          messageVariables={{ name: i18n._(t`Name`) }}
          name="name"
          validateFirst
          rules={[
            { required: true },
            {
              pattern: /^[A-Za-z0-9\-_]+$/,
              message: i18n._("Only allowed alphanumeric characters including '-' and '_'.")
            },
            {
              validator: validateName,
              message: i18n._(
                t`Another CSV import with the same name is already in use. The CSV import name must be unique.`
              )
            }
          ]}
          registerField={formActions.registerField}
        >
          <Input />
        </FormItem>

        <FormItem
          label={
            <TooltipLabel
              docId={DocId.DOMAIN_IMPORT_FROM_DB_UPDATE_CREATE}
              label={<Trans>Import strategy</Trans>}
            />
          }
          messageVariables={{ name: i18n._('Import strategy') }}
          name="updatecreate"
          rules={[{ required: true }]}
          registerField={formActions.registerField}
        >
          <Radio.Group>
            {/* eslint-disable-next-line react/jsx-boolean-value */}
            <Radio value={true}>
              <Trans>Update</Trans>
            </Radio>
            <Radio value={false}>
              <Trans>Create</Trans>
            </Radio>
          </Radio.Group>
        </FormItem>

        <FormItem
          label={
            <TooltipLabel
              docId={DocId.DOMAIN_IMPORT_FROM_DB_ENTITY_NAME}
              label={<Trans>Entity</Trans>}
            />
          }
          messageVariables={{ name: i18n._(t`Entity name`) }}
          name="entityName"
          rules={[{ required: true }]}
          registerField={formActions.registerField}
        >
          <EntityFieldSelector
            treeDefaultExpandAll
            getPopupContainer={() => formContainer}
            onChangePrepareValue={nodeData => nodeData?.value}
          />
        </FormItem>
        <FormItem
          label={
            <TooltipLabel
              docId={DocId.DOMAIN_IMPORT_FROM_CSV_DELIMITER}
              label={<Trans>Delimiter</Trans>}
            />
          }
          messageVariables={{ name: i18n._('Delimiter') }}
          name="delimiter"
          validateFirst
          rules={[
            { required: true },
            {
              pattern: /^[^\s]{1,1}$/,
              message: i18n._('Needs to be exactly one character. Default is ","')
            }
          ]}
          registerField={formActions.registerField}
        >
          <Input />
        </FormItem>

        <FormItem
          label={
            <TooltipLabel
              docId={DocId.DOMAIN_IMPORT_FROM_CSV_HEADER}
              label={<Trans>File header</Trans>}
            />
          }
          messageVariables={{ name: i18n._('File header') }}
          name="header"
          rules={[{ required: true }]}
          valuePropName="checked"
          registerField={formActions.registerField}
        >
          <Checkbox />
        </FormItem>

        <Divider />

        <FormTitle
          icon={['fas', 'file-upload']}
          docId={DocId.DOMAIN_IMPORT_FROM_CSV_UPLOAD_FILE}
          collapsible={{
            isOpen: state.collapse.isUploadOpen,
            toggleOpen: () => toggleOpen('isUploadOpen')
          }}
        >
          <Trans>CSV file upload</Trans>
        </FormTitle>
        <Collapse isOpened={state.collapse.isUploadOpen}>
          <S.FileUpload>
            <FormItem
              label={
                <TooltipLabel
                  docId={DocId.DOMAIN_IMPORT_FROM_CSV_UPLOAD_FILE}
                  label={<Trans>Upload File</Trans>}
                />
              }
              messageVariables={{ name: i18n._('Upload File') }}
              // not connected no name="file"
              validateFirst
              rules={[{ required: true }]}
              // not connected do not register...  registerField={formActions.registerField}
            >
              <FileSelector
                className="import-csv"
                fileType="DOCUMENT"
                multiple={false}
                allowedFileTypes={['csv']}
                locales={fileSelectorLocales}
                onChange={file => {
                  if (Array.isArray(file) === false) {
                    setState(draft => {
                      if (file && !Array.isArray(file)) {
                        draft.csvFile = file;
                      } else {
                        draft.csvFile = undefined;
                      }
                    });
                  }
                }}
                ref={fileSelectorRef}
                value={state.csvFile}
              />
              {!state.isCsvFilUploaded ? (
                <Button
                  action="save"
                  disabled={!state.csvFile || state.isCsvFilUploaded || uploadCsvMutation.isLoading}
                  loading={uploadCsvMutation.isLoading}
                  icon={['fas', 'file-upload']}
                  onClick={handleUploadFile}
                >
                  <Trans>Upload file</Trans>
                </Button>
              ) : (
                <Button
                  action="reset"
                  onClick={() =>
                    setState(draft => {
                      draft.csvFile = undefined;
                      draft.isCsvFilUploaded = false;
                    })
                  }
                />
              )}
            </FormItem>
          </S.FileUpload>
        </Collapse>

        <Divider />

        <FormTitle
          icon={['fas', 'file-upload']}
          docId={DocId.DOMAIN_IMPORT_FROM_CSV_IMPORT_FTP_CONNECTION}
          collapsible={{
            isOpen: state.collapse.isFtpOpen,
            toggleOpen: () => toggleOpen('isFtpOpen')
          }}
        >
          <Trans>Import from FTP</Trans>
        </FormTitle>

        <Collapse isOpened={state.collapse.isFtpOpen}>
          <FormItem
            label={
              <TooltipLabel
                docId={DocId.DOMAIN_IMPORT_FROM_CSV_IMPORT_FTP_CONNECTION}
                label={<Trans>FTP connection</Trans>}
              />
            }
            name="ftpConnection"
            messageVariables={{ name: i18n._('FTP connection') }}
            registerField={formActions.registerField}
          >
            <FtpConnectionSelector />
          </FormItem>
        </Collapse>

        <Divider />

        <FormTitle
          icon={['fas', 'folder-open']}
          docId={DocId.DOMAIN_IMPORT_FROM_CSV_FILE}
          collapsible={{
            isOpen: state.collapse.isFilesListOpen,
            toggleOpen: () => toggleOpen('isFilesListOpen')
          }}
        >
          <Trans>Select CSV file</Trans>
        </FormTitle>

        <Collapse isOpened={state.collapse.isFilesListOpen}>
          <FormItem
            label={
              <TooltipLabel
                docId={DocId.DOMAIN_IMPORT_FROM_CSV_FILE}
                label={<Trans>CSV file</Trans>}
              />
            }
            name="fileName"
            messageVariables={{ name: i18n._('CSV file') }}
            rules={[{ required: true }]}
            registerField={formActions.registerField}
          >
            <CsvFileSelector
              ref={csvFileSelectorRef}
              ftpConnection={formState.data?.ftpConnection}
            />
          </FormItem>

          <Divider />
          <>
            {csvHeaderQuery.isLoading && (
              <LoaderMessage showAfterTime={500}>
                <Trans>Loading CSV file meta data...</Trans>
              </LoaderMessage>
            )}
            {csvHeaderQuery.isSuccess && (
              <Alert
                type="success"
                showIcon
                message={
                  <>
                    <Trans>Successfully loaded CSV file meta data.</Trans>
                    <Popover
                      trigger="click"
                      content={
                        <S.CsvHeaderInfo>
                          <p>
                            <Trans>Found columns ({Object.keys(csvHeaderQuery.data).length})</Trans>
                          </p>
                          <ul>
                            {Object.keys(csvHeaderQuery.data).map(key => (
                              <li key={key}>{key}</li>
                            ))}
                          </ul>
                        </S.CsvHeaderInfo>
                      }
                    >
                      <Button type="link" icon={['fas', 'info-circle']}>
                        <Trans>More info</Trans>
                      </Button>
                    </Popover>
                  </>
                }
              />
            )}
          </>
        </Collapse>

        <Divider />

        <FormTitle
          icon={['fas', 'project-diagram']}
          docId={DocId.DOMAIN_IMPORT_FROM_CSV_MAPPING}
          collapsible={{
            isOpen: state.collapse.isMapperOpen,
            toggleOpen: () => toggleOpen('isMapperOpen')
          }}
        >
          <Trans>Column entity mapping</Trans>
        </FormTitle>
        <Collapse isOpened={state.collapse.isMapperOpen}>
          {!formState.data?.entityName && (
            <F.FormAlert
              type="info"
              showIcon
              margin="0 0 16px 0"
              message={
                <Trans>
                  Please select first a valid entity from the list under &quot;Config / entity&quot;
                  to provide the according field informations for for the mapper.
                </Trans>
              }
            />
          )}

          <FormItem
            label={
              <TooltipLabel
                docId={DocId.DOMAIN_IMPORT_FROM_CSV_MAPPING}
                label={<Trans>Entity mapping</Trans>}
              />
            }
            name="columnEntityMapping"
            registerField={formActions.registerField}
          >
            <CollectionEditor
              renderDialog={props => (
                <CsvImportMapperDialog
                  {...props}
                  selectedEntity={formState.data?.entityName}
                  csvHeaderData={csvHeaderQuery.data}
                />
              )}
              actions={{ sort: { disabled: true } }}
              className={!formState.data?.entityName ? 'disabled' : undefined}
              locales={{ ...collectionEditorLocales, 'Add item': i18n._('Add mapper') }}
              renderItem={(item: CollectionEditorDataItem<Partial<ColumnEntityMapping>>) => (
                <S.TableMapperItem>
                  <p>
                    <span>
                      <Trans>Field</Trans>:
                    </span>
                    {item.value.field}
                  </p>
                  <p>
                    <span>
                      <Trans>Column</Trans>:
                    </span>
                    {item.value.columnName} <span>[{item.value.columnNumber}]</span>
                  </p>
                </S.TableMapperItem>
              )}
              empty={<NoContent height={85} />}
            />
          </FormItem>
        </Collapse>

        <Divider />

        <FormTitle
          icon={['fas', 'clock']}
          docId={DocId.DOMAIN_IMPORT_FROM_CSV_SCHEDULER}
          collapsible={{
            isOpen: state.collapse.isTriggersOpen,
            toggleOpen: () => toggleOpen('isTriggersOpen')
          }}
        >
          <Trans>Scheduler</Trans>
        </FormTitle>
        <Collapse isOpened={state.collapse.isTriggersOpen}>
          <FormItem>
            <TriggerAdder
              getInitialData={handlePrepareTriggerData}
              taskName={formState.data?.name}
              isCreateTaskReadonly
            />
          </FormItem>
        </Collapse>
      </F.StyledForm>
    </FormContainer>
  );
};

export default CsvImportForm;
