import { DomainDiagramActionTypes } from './domainDiagram.types';

import {
  DiagramElement,
  EntityEdge,
  EntityEdgeData,
  EntityNodeData
} from 'services/api/domain/domainDiagram';

// Action Definition
type SetElementsAction = {
  type: DomainDiagramActionTypes.SET_ELEMENTS;
  elements: DiagramElement[];
};

type UpdateElementsAction = {
  type: DomainDiagramActionTypes.UPDATE_ELEMENTS;
  elements: DiagramElement[];
};

type AddElementAction = {
  type: DomainDiagramActionTypes.ADD_ELEMENT;
  element: DiagramElement;
};

type UpdateElementAction = {
  type: DomainDiagramActionTypes.UPDATE_ELEMENT;
  element: DiagramElement;
};

type RemoveElementAction = {
  type: DomainDiagramActionTypes.REMOVE_ELEMENT;
  id: string;
};

type UpdateNodeDataAction = {
  type: DomainDiagramActionTypes.UPDATE_NODE_DATA;
  data: EntityNodeData;
  id: string;
  addEdge?: EntityEdge;
};

type SetContextNavOpenAction = {
  type: DomainDiagramActionTypes.SET_CONTEXTNAV_OPEN;
  open: boolean;
};

type SetDialogOpenAction = {
  type: DomainDiagramActionTypes.SET_DIALOG_OPEN;
  open: boolean;
};

type CleanupElementsAction = {
  type: DomainDiagramActionTypes.CLEANUP_ELEMENTS;
};

type ResetAction = {
  type: DomainDiagramActionTypes.RESET;
};

type SetActiveEdgeAction = {
  type: DomainDiagramActionTypes.SET_ACTIVE_EDGE;
  id?: string;
};

type SetContextEdgeAction = {
  type: DomainDiagramActionTypes.SET_CONTEXT_EDGE;
  edgeData?: EntityEdge;
  anchor?: { x: number; y: number };
};

type SetChangedAction = {
  type: DomainDiagramActionTypes.SET_HAS_CHANGED;
  changed: boolean;
};

export type RemoveEdgeAction = {
  type: DomainDiagramActionTypes.REMOVE_EDGE;
  id: string;
};

export type UpdateEdgeAction = {
  type: DomainDiagramActionTypes.UPDATE_EDGE;
  edge: EntityEdge;
};

export type SetActiveHandlesAction = {
  type: DomainDiagramActionTypes.SET_ACTIVE_HANDLES;
  handleIds: string[];
};

export type SetActiveNodeAction = {
  type: DomainDiagramActionTypes.SET_ACTIVE_NODE;
  nodeId?: string;
};

export type UpdateEdgeDataAction = {
  type: DomainDiagramActionTypes.UPDATE_EDGE_DATA;
  edgeData: EntityEdgeData;
  edgeId: string;
};

// Union Action Types
export type DomainDiagramActionCreators =
  | SetElementsAction
  | UpdateElementsAction
  | AddElementAction
  | UpdateElementAction
  | UpdateNodeDataAction
  | RemoveElementAction
  | SetContextNavOpenAction
  | CleanupElementsAction
  | SetDialogOpenAction
  | ResetAction
  | SetActiveEdgeAction
  | SetChangedAction
  | SetContextEdgeAction
  | SetActiveHandlesAction
  | SetActiveNodeAction
  | RemoveEdgeAction
  | UpdateEdgeDataAction;

// Action Creators -------------------------------------------------------------------------------

export const st_domainDiagram_setElements = (elements: DiagramElement[]): SetElementsAction => ({
  type: DomainDiagramActionTypes.SET_ELEMENTS,
  elements
});

export const st_domainDiagram_updateElements = (
  elements: DiagramElement[]
): UpdateElementsAction => ({ type: DomainDiagramActionTypes.UPDATE_ELEMENTS, elements });

export const st_domainDiagram_addElement = (element: DiagramElement): AddElementAction => ({
  type: DomainDiagramActionTypes.ADD_ELEMENT,
  element
});

export const st_domainDiagram_updateElement = (element: DiagramElement): UpdateElementAction => ({
  type: DomainDiagramActionTypes.UPDATE_ELEMENT,
  element
});

export const st_domainDiagram_removeElement = (id: string): RemoveElementAction => ({
  type: DomainDiagramActionTypes.REMOVE_ELEMENT,
  id
});

export const st_domainDiagram_updateNodeData = (
  data: EntityNodeData,
  id: string,
  addEdge?: EntityEdge
): UpdateNodeDataAction => ({ type: DomainDiagramActionTypes.UPDATE_NODE_DATA, data, id, addEdge });

export const st_domainDiagram_setContextNavOpen = (open: boolean): SetContextNavOpenAction => ({
  type: DomainDiagramActionTypes.SET_CONTEXTNAV_OPEN,
  open
});

export const st_domainDiagram_setDialogOpen = (open: boolean): SetDialogOpenAction => ({
  type: DomainDiagramActionTypes.SET_DIALOG_OPEN,
  open
});

export const st_domainDiagram_reset = (): ResetAction => ({ type: DomainDiagramActionTypes.RESET });

export const st_domainDiagram_cleanupElements = (): CleanupElementsAction => ({
  type: DomainDiagramActionTypes.CLEANUP_ELEMENTS
});

export const st_domainDiagram_removeEdge = (id: string): RemoveEdgeAction => ({
  type: DomainDiagramActionTypes.REMOVE_EDGE,
  id
});

export const st_domainDiagram_updateEdge = (edge: EntityEdge): UpdateEdgeAction => ({
  type: DomainDiagramActionTypes.UPDATE_EDGE,
  edge
});

export const st_domainDiagram_setActiveEdge = (id?: string): SetActiveEdgeAction => ({
  type: DomainDiagramActionTypes.SET_ACTIVE_EDGE,
  id
});

export const st_domainDiagram_setChanged = (changed: boolean): SetChangedAction => ({
  type: DomainDiagramActionTypes.SET_HAS_CHANGED,
  changed
});

export const st_domainDiagram_setContextEdge = (
  edgeData?: EntityEdge,
  anchor?: { x: number; y: number }
): SetContextEdgeAction => ({
  type: DomainDiagramActionTypes.SET_CONTEXT_EDGE,
  edgeData,
  anchor
});

export const st_domainDiagram_setActiveHandles = (handleIds: string[]): SetActiveHandlesAction => ({
  type: DomainDiagramActionTypes.SET_ACTIVE_HANDLES,
  handleIds
});

export const st_domainDiagram_setActiveNode = (nodeId?: string): SetActiveNodeAction => ({
  type: DomainDiagramActionTypes.SET_ACTIVE_NODE,
  nodeId
});

export const st_domainDiagram_updateEdgeData = (
  edgeData: EntityEdgeData,
  edgeId: string
): UpdateEdgeDataAction => ({
  type: DomainDiagramActionTypes.UPDATE_EDGE_DATA,
  edgeData,
  edgeId
});
