import { createSelector } from 'reselect';

import { DomainState } from './domain.types';

import { DomainRouteParams } from 'index.types';

export const domainState = (state: { domain: DomainState }): DomainState => state.domain;

export const st_domain_getDomainName = createSelector(
  [domainState],
  (domain): string | undefined => domain.domainName
);

export const st_domain_getRoute = createSelector(
  [domainState],
  (domain): string | undefined => domain.route
);

export const st_domain_getRouteParams = createSelector(
  [domainState],
  (domain): DomainRouteParams | undefined => domain.routeParams
);
