import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import * as T from 'components/_styled/tableSc';
import { ObjectHistory } from 'services/api/domain/objectHistory';
import { st_historyTable_setSingleView } from 'services/store/historyTable/historyTable.actions';
import { st_historyTable_getSingleView } from 'services/store/historyTable/historyTable.selectors';
import { HistoryTableSingleView } from 'services/store/historyTable/historyTable.types';

const { TabPane } = Tabs;

type DocumentHistoryTableSingleNavProps = {
  data: ObjectHistory;
};

const DocumentHistoryTableSingleNav: React.FC<DocumentHistoryTableSingleNavProps> = () => {
  const view = useSelector(st_historyTable_getSingleView);
  const dispatch = useDispatch();

  const handleTabChange = (value: string) => {
    dispatch(st_historyTable_setSingleView(value as HistoryTableSingleView));
  };

  return (
    <T.SingleNav>
      <T.TabNavigation defaultActiveKey="1" onChange={handleTabChange} activeKey={view}>
        <TabPane
          tab={
            <>
              <FontAwesomeIcon icon={['fas', 'info-circle']} />
              <Trans>Overview</Trans>
            </>
          }
          key="OVERVIEW"
        />
        <TabPane
          tab={
            <>
              <FontAwesomeIcon icon={['fas', 'code']} />
              <Trans>JSON diff</Trans>
            </>
          }
          key="JSON_DIFF"
        />
      </T.TabNavigation>
    </T.SingleNav>
  );
};

export default DocumentHistoryTableSingleNav;
