import { omit } from 'lodash';

import {
  CreateCsvImportParams,
  CsvFileHeaderInfo,
  CsvImport,
  CsvImportResult,
  DeleteCsvImportParams,
  DeleteTempCsvFileParams,
  FileMetaData,
  GetCsvFileHeaderParams,
  GetCSVImportByNameParams,
  GetCsvImportResultsParams,
  GetCSVImportsParams,
  GetFileListViaFTPParams,
  GetTempCSVFilesParams,
  StartCsvImportParams,
  UpdateCsvImportParams,
  UploadCSVFileParams
} from './csvImport.types';

import apiClient from 'services/api/apiClient';

const BASE_PATH = 'csv';

/**
 * GET /api/csv/{domain}/{scheme}/byName/{name}
 *
 */
export const api_getCsvImportByName = async (
  params: GetCSVImportByNameParams
): Promise<CsvImport> => {
  const res = await apiClient.get(
    `/${BASE_PATH}/${params.domainName}/${params.schemaName}/byName/${params.csvImportName}`
  );

  let { data } = res;

  if (data.ftpConnection && JSON.stringify(data.ftpConnection) === '{}') {
    data = omit(data, 'ftpConnection');
  }

  return data;
};

/**
 * GET /api/csv/{domain}/{scheme}
 *
 */
export const api_getCsvImports = async (params: GetCSVImportsParams): Promise<CsvImport[]> => {
  const { data } = await apiClient.get(`/${BASE_PATH}/${params.domainName}/${params.schemaName}`);

  return data;
};

/**
 * POST /api/csv/{domain}/{scheme}/upload  multipart/form-data
 *
 */
export const api_uploadCsvFile = async (params: UploadCSVFileParams): Promise<FileMetaData> => {
  const payload = new FormData();
  payload.append('file', params.file);

  const { data } = await apiClient.post(
    `/${BASE_PATH}/${params.domainName}/${params.schemaName}/upload`,
    payload,
    {
      onUploadProgress: params.onUploadProgress,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );

  return data;
};

/**
 * GET /api/csv/{domain}/{scheme}/tempcsvfiles
 *
 */
export const api_getTempCsvFiles = async (
  params: GetTempCSVFilesParams
): Promise<FileMetaData[]> => {
  const { data } = await apiClient.get(
    `/${BASE_PATH}/${params.domainName}/${params.schemaName}/tempcsvfiles`
  );

  return data;
};

/**
 * GET /api/csv/{domain}/{scheme}/{ftpconnectionname}/listFtpDir
 *
 */
export const api_getFileListViaFTP = async (
  params: GetFileListViaFTPParams
): Promise<FileMetaData[]> => {
  const { data } = await apiClient.get(
    `/${BASE_PATH}/${params.domainName}/${params.schemaName}/${params.ftpConnectionName}/listFtpDir`
  );

  return data;
};

/**
 * POST /api/csv/{domain}/{scheme}
 */
export const api_createCsvImport = async (params: CreateCsvImportParams): Promise<CsvImport> => {
  const { data } = await apiClient.post(
    `/${BASE_PATH}/${params.domainName}/${params.schemaName}`,
    params.payload
  );

  return data;
};

/**
 * PUT /api/csv/{domain}/{scheme}
 */
export const api_updateCsvImport = async (params: UpdateCsvImportParams): Promise<CsvImport> => {
  const { data } = await apiClient.put(
    `/${BASE_PATH}/${params.domainName}/${params.schemaName}`,
    params.payload
  );

  return data;
};

/**
 * DELETE /api/csv/{domain}/{scheme}/csvFile/{filename}
 */
export const api_deleteTempCsvFile = async (params: DeleteTempCsvFileParams): Promise<void> => {
  const { data } = await apiClient.delete(
    `/${BASE_PATH}/${params.domainName}/${params.schemaName}/csvFile/${encodeURIComponent(
      params.fileName
    )}`
  );

  return data;
};

/**
 * GET /api/csv/{domain}/{scheme}/{importName}/csvheaders
 */
export const api_getCsvFileHeaderInfo = async (
  params: GetCsvFileHeaderParams
): Promise<CsvFileHeaderInfo> => {
  const { data } = await apiClient.get(
    `/${BASE_PATH}/${params.domainName}/${params.schemaName}/${params.importName}/csvheaders`
  );

  return data;
};

/**
 * GET /api/csv/{domain}/{scheme}/results/{csvImportName}/{rows}
 */
export const api_getCsvImportResults = async (
  params: GetCsvImportResultsParams
): Promise<CsvImportResult[]> => {
  const { data } = await apiClient.get(
    `/${BASE_PATH}/${params.domainName}/${params.schemaName}/results/${encodeURIComponent(
      params.importName
    )}/${params.rows}`
  );

  return data;
};

/**
 * DELETE /api/csv/{domain}/{scheme}/{id}
 */
export const api_deleteCsvImport = async (params: DeleteCsvImportParams): Promise<void> => {
  const { data } = await apiClient.delete(
    `/${BASE_PATH}/${params.domainName}/${params.schemaName}/${params.id}`
  );

  return data;
};

/**
 * GET /api/csv/{domain}/{scheme}/start/{name}
 */
export const api_startCSVImport = async (
  params: StartCsvImportParams
): Promise<CsvImportResult> => {
  const { data } = await apiClient.get(
    `/${BASE_PATH}/${params.domainName}/${params.schemaName}/start/${params.importName}`
  );

  return data;
};
