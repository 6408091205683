import { combineReducers } from 'redux';

import appReducer from './app/app.reducer';
import { AppState } from './app/app.types';
import csvImportsTableReducer from './csvImportsTable/csvImportsTable.reducer';
import { CsvImportsTableState } from './csvImportsTable/csvImportsTable.types';
import dbImportsTableReducer from './dbImportsTable/dbImportsTable.reducer';
import { DbImportsTableState } from './dbImportsTable/dbImportsTable.types';
import docsReducer from './docs/docs.reducer';
import { DocsState } from './docs/docs.types';
import domainReducer from './domain/domain.reducer';
import { DomainState } from './domain/domain.types';
import domainDiagramReducer from './domainDiagram/domainDiagram.reducer';
import { DomainDiagramState } from './domainDiagram/domainDiagram.types';
import domainsTableReducer from './domainsTable/domainsTable.reducer';
import { DomainsTableState } from './domainsTable/domainsTable.types';
import entityDocumentReducer from './entityDocument/entityDocument.reducer';
import { EntityDocumentState } from './entityDocument/entityDocument.types';
import ftpConnectionsTableReducer from './ftpConnectionsTable/ftpConnectionsTable.reducer';
import { FtpConnectionsTableState } from './ftpConnectionsTable/ftpConnectionsTable.types';
import historyTableReducer from './historyTable/historyTable.reducer';
import { HistoryTableState } from './historyTable/historyTable.types';
import jsonEditorReducer from './jsonEditor/jsonEditor.reducer';
import { JsonEditorState } from './jsonEditor/jsonEditor.types';
import orgUnitsTableReducer from './orgUnitsTable/orgUnitsTable.reducer';
import { OrgUnitsTableState } from './orgUnitsTable/orgUnitsUnitsTable.types';
import triggersTableReducer from './triggersTable/triggersTable.reducer';
import { TriggersTableState } from './triggersTable/triggersTable.types';
import usersTableReducer from './usersTable/usersTable.reducer';
import { UsersTableState } from './usersTable/usersTable.types';

export type ApplicationState = {
  app: AppState;
  domain: DomainState;
  domainDiagram: DomainDiagramState;
  docs: DocsState;
  domainsTable: DomainsTableState;
  jsonEditor: JsonEditorState;
  dbImportsTable: DbImportsTableState;
  triggersTable: TriggersTableState;
  usersTable: UsersTableState;
  orgUnitsTable: OrgUnitsTableState;
  historyTable: HistoryTableState;
  entityDocument: EntityDocumentState;
  csvImportsTable: CsvImportsTableState;
  ftpConnectionsTable: FtpConnectionsTableState;
};

const rootReducer = combineReducers<ApplicationState>({
  app: appReducer,
  domain: domainReducer,
  domainDiagram: domainDiagramReducer,
  docs: docsReducer,
  domainsTable: domainsTableReducer,
  jsonEditor: jsonEditorReducer,
  dbImportsTable: dbImportsTableReducer,
  triggersTable: triggersTableReducer,
  usersTable: usersTableReducer,
  orgUnitsTable: orgUnitsTableReducer,
  historyTable: historyTableReducer,
  entityDocument: entityDocumentReducer,
  csvImportsTable: csvImportsTableReducer,
  ftpConnectionsTable: ftpConnectionsTableReducer
});

export default rootReducer;
