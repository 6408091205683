import React from 'react';

import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';

import DbImportTableNav from './dbImportTableNav';
import DbImportTableConnection from './singleViews/dbImportTableConnection';
import DbImportTableLogs from './singleViews/dbImportTableLogs';
import DbImportTableMappers from './singleViews/dbImportTableMappers';
import DbImportTableOverview from './singleViews/dbImportTableOverview';
import DbImportTableScheduler from './singleViews/dbImportTableScheduler';

import * as T from 'components/_styled/tableSc';
import DbImportActions from 'components/domain/actions/dbImportActions';
import { DbImport } from 'services/api/domain/dataImport';
import { st_dbImportsTable_getSingleView } from 'services/store/dbImportsTable/dbImportsTable.selectors';

type DbImportTableSingleProps = {
  data?: DbImport;
};

const DbImportTableSingle: React.FC<DbImportTableSingleProps> = ({ data }) => {
  const view = useSelector(st_dbImportsTable_getSingleView);

  if (!data) {
    return null;
  }

  return (
    <>
      <T.SingleTitle>
        <h4>
          <Trans>DB Import: {data.name}</Trans>
        </h4>
        <T.SingleTitleRight>
          <DbImportActions data={data} mode="BUTTON" popoverPlacement="leftTop" />
        </T.SingleTitleRight>
      </T.SingleTitle>
      <DbImportTableNav data={data} />

      <T.SingleContent>
        {view === 'OVERVIEW' && <DbImportTableOverview data={data} />}
        {view === 'CONNECTION' && <DbImportTableConnection data={data} />}
        {view === 'MAPPERS' && <DbImportTableMappers data={data} />}
        {view === 'SCHEDULERS' && <DbImportTableScheduler data={data} />}
        {view === 'LOGS' && <DbImportTableLogs data={data} />}
      </T.SingleContent>
    </>
  );
};

export default DbImportTableSingle;
