import React from 'react';

import { Navigation } from '@phoenix-systems/react-navigation';
import { useDispatch, useSelector } from 'react-redux';

import { getLanguageNavigationConfig } from 'config/navigation';
import { st_app_getLanguage } from 'services/store/app/app.selectors';

const NAVIGATION_ID = 'language';

const LanguageNavigation: React.FC = () => {
  const dispatch = useDispatch();
  const language = useSelector(st_app_getLanguage);
  const activeKey = `lang-${language}`;

  return (
    <Navigation
      navigationId={NAVIGATION_ID}
      config={getLanguageNavigationConfig(dispatch)}
      inlineCollapsed={false}
      mode="inline"
      selectedKeys={activeKey ? [activeKey] : []}
    />
  );
};

export default LanguageNavigation;
