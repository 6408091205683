import React from 'react';

import * as S from './splashScreenSc';

const SplashScreen: React.FC = () => (
  <S.SplashScreenContainer>
    {/*  <S.SplashScreenCenter>
        <S.SplashScreenLogo src={logo} alt="logo" />
      </S.SplashScreenCenter> */}
  </S.SplashScreenContainer>
);

export default SplashScreen;
