import { DocsActionTypes, EditorMode } from './docs.types';

import { DocId } from 'services/api/domain/hint';

// Action Definition
type OpenDrawerAction = {
  type: DocsActionTypes.OPEN_DRAWER;
  docId: DocId;
};

type CloseDrawerAction = {
  type: DocsActionTypes.CLOSE_DRAWER;
};

type DrawerBackAction = {
  type: DocsActionTypes.DRAWER_BACK;
};

type DrawerForwardAction = {
  type: DocsActionTypes.DRAWER_FORWARD;
};

type SetEditorModeAction = {
  type: DocsActionTypes.SET_EDITOR_MODE;
  mode: EditorMode;
};

// Union Action Types
export type DocsActionCreators =
  | OpenDrawerAction
  | CloseDrawerAction
  | DrawerBackAction
  | DrawerForwardAction
  | SetEditorModeAction;

// Action Creators -------------------------------------------------------------------------------

export const st_docs_openDrawer = (docId: DocId): OpenDrawerAction => ({
  type: DocsActionTypes.OPEN_DRAWER,
  docId
});

export const st_docs_closeDrawer = (): CloseDrawerAction => ({
  type: DocsActionTypes.CLOSE_DRAWER
});

export const st_docs_drawerBack = (): DrawerBackAction => ({ type: DocsActionTypes.DRAWER_BACK });

export const st_docs_drawerForward = (): DrawerForwardAction => ({
  type: DocsActionTypes.DRAWER_FORWARD
});

export const st_docs_setMode = (mode: EditorMode): SetEditorModeAction => ({
  type: DocsActionTypes.SET_EDITOR_MODE,
  mode
});
