import React, { useEffect, useState } from 'react';

import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { FormItem, useFormContext } from '@phoenix-systems/react-form';
import { Divider, Form, Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { SelectValue } from 'antd/lib/select';
import { v4 as uuid } from 'uuid';

import * as S from './entityEdgeFormDialogSc';

import * as F from 'components/_styled/formSc';
import useContainer from 'components/app/components/containerProvider/useContainer';
import DocsTooltip from 'components/docs/components/docsTooltip';
import Button from 'components/ui/button';
import useDefaultModalProps from 'hooks/useDefaultModalProps';
import {
  AggregationType,
  EntityEdge,
  EntityEdgeFormData,
  RealationType
} from 'services/api/domain/domainDiagram';
import { DocId } from 'services/api/domain/hint';

type EntityEdgeFormDialogProps = {
  data: EntityEdgeFormData;
  edge: EntityEdge;
  showInfo: boolean;
  isOpen: boolean;
  insert: (data: EntityEdgeFormData) => void;
  close: () => void;
};

const EntityEdgeFormDialog: React.FC<EntityEdgeFormDialogProps> = ({
  showInfo,
  edge,
  data,
  isOpen,
  insert,
  close
}) => {
  const [form] = Form.useForm<EntityEdgeFormData>();
  const [formState, formActions] = useFormContext<EntityEdgeFormData>(
    form,
    data,
    data !== undefined
  );
  const [hasRelationTypeChanged, setRelationTypeChanged] = useState(false);
  const { dialogContainer } = useContainer();
  const modalProps = useDefaultModalProps();

  const handleRelationTypeChange = (value: SelectValue) => {
    if (value !== edge.data?.relationType) {
      setRelationTypeChanged(true);
    } else {
      setRelationTypeChanged(false);
    }
  };

  const handleInsert = () => {
    form.validateFields().then(formData => {
      insert(formData);
    });
  };

  const handleClose = () => {
    close();
    formActions.reset();
  };

  useEffect(() => {
    form.setFieldsValue({
      relationType: edge.data?.relationType,
      aggregationType: edge.data?.aggregationType
    });
    setRelationTypeChanged(false);
  }, [edge, form]);

  return (
    <Modal
      {...modalProps}
      title={<Trans>Edit Edge</Trans>}
      visible={isOpen}
      footer={
        <>
          <Button onClick={handleClose} action="cancel" />
          <Button action="save" onClick={handleInsert} formState={formState} />
        </>
      }
    >
      <F.StyledForm
        form={form}
        layout="horizontal"
        name={`edit-edge-form-${edge.id}-${uuid()}`}
        $labelWidth={130}
        initialValues={data}
        onValuesChange={formActions.onValuesChange}
        onFieldsChange={formActions.onFieldsChange}
        colon={false}
      >
        {showInfo && (
          <>
            <S.EdgeInfo>
              <p>
                <span>
                  <Trans>Source class</Trans>:
                </span>
                {edge.data?.sourceNode?.data?.className}
              </p>
              {edge.data?.sourceField && (
                <p>
                  <span>
                    <Trans>Source field</Trans>:
                  </span>
                  {edge.data.sourceField.name}
                </p>
              )}
              <p>
                <span>
                  <Trans>Target class</Trans>:
                </span>
                {edge.data?.targetNode?.data?.className}
              </p>
              {edge.data?.targetField && (
                <p>
                  <span>
                    <Trans>Target field</Trans>:
                  </span>
                  {edge.data.targetField.name}
                </p>
              )}
            </S.EdgeInfo>
            <Divider />
          </>
        )}
        <FormItem
          label={
            <>
              <Trans>Relation type</Trans>
              <DocsTooltip docId={DocId.DOMAIN_DIAGRAM_FIELD_RELATION_TYPE} />
            </>
          }
          name="relationType"
          rules={[{ required: true }]}
        >
          <Select onChange={handleRelationTypeChange} getPopupContainer={() => dialogContainer}>
            <Select.Option value={RealationType.ONE_TO_ONE_RELATED}>One to one</Select.Option>
            <Select.Option value={RealationType.ONE_TO_MANY_RELATED}>One to many</Select.Option>
          </Select>
        </FormItem>
        <FormItem
          label={
            <>
              <Trans>Aggregation</Trans>
              <DocsTooltip docId={DocId.DOMAIN_DIAGRAM_FIELD_AGGREGATION_TYPE} />
            </>
          }
          name="aggregationType"
        >
          <Select getPopupContainer={() => dialogContainer}>
            <Select.Option value={AggregationType.COMPOSITION_EMBEDDED}>
              Composition embedded
            </Select.Option>
            <Select.Option value={AggregationType.COMPOSITION_DETACHED}>
              Composition detached
            </Select.Option>
            <Select.Option value={AggregationType.AGGREGATION}>Aggregation</Select.Option>
          </Select>
        </FormItem>
        {hasRelationTypeChanged && edge.data?.sourceField && (
          <F.FormAlert
            message={i18n._(
              t`Are you sure you want to change the realtion type? The data type of the source field "${edge.data.sourceField.name}" will be changed to the according data type.`
            )}
            type="warning"
            showIcon
          />
        )}
      </F.StyledForm>
    </Modal>
  );
};

export default EntityEdgeFormDialog;
