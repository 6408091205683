import React, { useEffect, useState } from 'react';

import { notification } from 'antd';
import { useLocation } from 'react-router-dom';

import { getUrlFragment } from 'utils';

const PushNotifications: React.FC = ({ children }) => {
  const [lastRootPath, setLastRootPath] = useState<string>();
  const location = useLocation();

  useEffect(() => {
    const rootPath = getUrlFragment(0);

    if (lastRootPath !== rootPath) {
      notification.destroy();
      setLastRootPath(rootPath);
    }
  }, [location, lastRootPath]);

  return <>{children}</>;
};

export default PushNotifications;
