import { DataExportParams, DataImportParams } from './dataJsonImportExport.types';

import apiClient from 'services/api/apiClient';
import { log } from 'utils';

const BASE_PATH = 'data';

/**
 * GET /api/data/{domain}/{scheme}/export/{type}
 */
export const api_dataJsonExport = async (params: DataExportParams): Promise<Blob> => {
  const res = await apiClient.get(
    `${BASE_PATH}/${params.domainName}/${params.schema}/export/${params.type}`,
    { responseType: 'blob' }
  );

  if (res.status === 200) {
    return res.data;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  throw new Error((res as any).response?.data);
};

/**
 * MULTIPART
 * POST /api/data/{domain}/{scheme}/import
 */
export const api_dataJsonImport = async (params: DataImportParams): Promise<string> => {
  const res = await apiClient.uploadFile({
    uri: `${BASE_PATH}/${params.domainName}/${params.schema}/import`,
    file: params.file,
    // TODO -> add progress to upload
    onUploadProgress: progress => log(progress)
  });

  if (res.status === 200 && typeof res.data === 'string') {
    return res.data;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  throw new Error((res as any).response?.data);
};
