import React from 'react';

import { Trans } from '@lingui/macro';

import AppLayout from 'components/app/components/appLayout';
import DomainForm from 'components/domain/components/domainForm';
import Page from 'components/ui/page';

const DomainCreatePage: React.FC = () => (
  <AppLayout>
    <Page
      scrollableProps={{ scrollId: 'page-scroll' }}
      content={<DomainForm />}
      title={<Trans>Create new domain</Trans>}
    />
  </AppLayout>
);

export default DomainCreatePage;
