/* eslint-disable  react/no-array-index-key */
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

import { Trans } from '@lingui/macro';
import { Radio, RadioChangeEvent } from 'antd';
import { last } from 'lodash';

import CsvFileItem from './csvFileItem';
import * as S from './csvFileSelectorSc';

import ErrorInfo from 'components/ui/errorInfo';
import Loader from 'components/ui/loader';
import NoContent from 'components/ui/noContent';
import useDomainName from 'hooks/useDomainName';
import useNotFound from 'hooks/useNotFound';
import useSchemaName from 'hooks/useSchemaName';
import { useFileListViaFTP, useTempCsvFiles } from 'services/api/domain/csvImport';
import { FTPConnection } from 'services/api/domain/ftpConnection';

type CsvFileSelectorProps = {
  value?: string;
  onChange?: (value?: string) => void;
  ftpConnection?: FTPConnection;
};

type CsvRefetchStrategy = 'LOCAL' | 'REMOTE';

export type CsvFileSelectorRef = {
  element: HTMLDivElement | null;
  refetch: (strategy?: CsvRefetchStrategy) => void;
  // input: HTMLInputElement
};

const CsvFileSelectorRenderer: React.ForwardRefRenderFunction<
  CsvFileSelectorRef,
  CsvFileSelectorProps
> = ({ value, onChange, ftpConnection }, ref) => {
  const domainName = useDomainName();
  const schemaName = useSchemaName();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const tempCsvQuery = useTempCsvFiles({ domainName, schemaName });

  const remoteFilesQuery = useFileListViaFTP(
    { domainName, schemaName, ftpConnectionName: ftpConnection?.name || '' },
    { enabled: false }
  );
  const { current: remoteFilesQueryRef } = useRef(remoteFilesQuery);
  const isNoRemoteFilesFound = useNotFound(remoteFilesQuery);

  const handleRefetch = (strategy?: CsvRefetchStrategy) => {
    switch (strategy) {
      case 'LOCAL':
        tempCsvQuery.refetch();

        return;
      case 'REMOTE':
        remoteFilesQuery.refetch();

        return;
      default:
        tempCsvQuery.refetch();
        remoteFilesQuery.refetch();
    }
  };

  const handleChange = (e: RadioChangeEvent) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  const isCsvFile = (fileName: string) => {
    const parts = fileName.split('.');

    if (last(parts) === 'csv') {
      return true;
    }

    return false;
  };

  useEffect(() => {}, []);

  useEffect(() => {
    if (ftpConnection) {
      remoteFilesQueryRef.refetch();
    }
  }, [ftpConnection, remoteFilesQueryRef]);

  useEffect(() => {}, [value]);

  useImperativeHandle(ref, () => ({
    element: wrapperRef.current,
    // input:
    refetch: handleRefetch
  }));

  return (
    <S.Wrapper ref={wrapperRef}>
      <S.FilesList>
        <Radio.Group value={value} onChange={handleChange}>
          <div className="title local">
            <Trans>Local files on server (temp directory)</Trans>
          </div>
          {tempCsvQuery.isLoading && <Loader height={48} size={28} />}

          {tempCsvQuery.isError && (
            <ErrorInfo
              error={tempCsvQuery.error}
              message={<Trans>Failed to load csv files.</Trans>}
            />
          )}
          {tempCsvQuery.data?.length === 0 && <NoContent height={100} />}
          {tempCsvQuery.data?.map((file, index) => (
            <Radio key={`${index}_${file}`} value={file.name}>
              <CsvFileItem data={file} isTemp onAfterDelete={() => handleRefetch('LOCAL')} />
            </Radio>
          ))}

          <div className="title remote">
            <Trans>Remote files via FTP</Trans>
            {ftpConnection && (
              <>
                : <span>{ftpConnection.remoteHost}</span>
              </>
            )}
          </div>
          {ftpConnection ? (
            <>
              {remoteFilesQuery.isLoading && <Loader height={48} size={28} />}
              {remoteFilesQuery.isError && !isNoRemoteFilesFound && (
                <ErrorInfo
                  error={remoteFilesQuery.error}
                  message={<Trans>Failed to load remote files</Trans>}
                />
              )}
              {remoteFilesQuery.isError && isNoRemoteFilesFound && <NoContent height={85} />}
              {remoteFilesQuery.data
                ?.filter(fileInfo => fileInfo.name !== '..' && fileInfo.name !== '.')
                .map((file, index) => (
                  <Radio
                    key={`${index}_${file.name}`}
                    value={file.name}
                    disabled={!isCsvFile(file.name)}
                  >
                    <CsvFileItem data={file} />
                  </Radio>
                ))}
            </>
          ) : (
            <NoContent height={85} />
          )}
          {/* {tempCsvQuery.data?.length === 0 && <NoContent height={100} />} */}
        </Radio.Group>
      </S.FilesList>
    </S.Wrapper>
  );
};

const CsvFileSelector = forwardRef(CsvFileSelectorRenderer);
CsvFileSelector.displayName = 'CsvFileSelector';

export default CsvFileSelector;
