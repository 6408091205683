import apiClient from './apiClient';

import { Language } from 'config/langauge/languages.types';

const setApiLanguageHeaderInterceptor = (language: Language): void => {
  apiClient.getAxiosInstance().interceptors.request.use(config => {
    if (config.headers) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Language = language;
    } else {
      // eslint-disable-next-line no-param-reassign
      config.headers = { Language: language };
    }

    return config;
  });
};

export default setApiLanguageHeaderInterceptor;
