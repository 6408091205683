import React, { useCallback, useEffect, useRef } from 'react';

import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { AuthRestricted } from '@phoenix-systems/react-keycloak-auth';
import { Desktop } from '@phoenix-systems/react-layout';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useImmer } from 'use-immer';

import DocsEditorForm from '../docsEditorForm';
import DocsModeSwitch from '../docsModeSwitch';
import DocsSearch from '../docsSearch';

import * as S from './docsPageSc';

import Breadcrumbs from 'components/app/components/breadcrumbs';
import Button from 'components/ui/button';
import Editor from 'components/ui/editor';
import Loader from 'components/ui/loader';
import Page from 'components/ui/page';
import AuthRole from 'config/auth/authRole.config';
import { DocConfig } from 'config/docs/docs.config.types';
import { getDocConfig } from 'config/docs/docs.utils';
import useLanguage from 'hooks/useLanguage';
import useNotFound from 'hooks/useNotFound';
import useNotification from 'hooks/useNotification';
import { DomainRouteParams } from 'index.types';
import { api_createHint, CreateHintParams, DocId, Hint, useHint } from 'services/api/domain/hint';
import { st_docs_getEditorMode } from 'services/store/docs/docs.selectors';

type DocsPageProps = {
  route: string;
  routeParams?: DomainRouteParams;
  // content: JSX.Element;
};

type DocsPageState = {
  docId?: DocId;
  docConfig?: DocConfig;
};

const DocsPage: React.FC<DocsPageProps> = () => {
  const pathParams = useParams<{ 0: string }>();
  const [state, setState] = useImmer<DocsPageState>({
    docId: undefined,
    docConfig: undefined
  });
  const hintQuery = useHint(state.docId || DocId.DOMAIN, { enabled: false, retry: false });
  const { current: hintQueryRef } = useRef(hintQuery);
  const isNotFound = useNotFound(hintQuery);
  const editorMode = useSelector(st_docs_getEditorMode);
  const language = useLanguage();
  const [addNotification] = useNotification();

  const createHintMutation = useMutation<Hint, AxiosError, CreateHintParams>(
    (payload: CreateHintParams) => api_createHint(payload),
    {
      onSuccess: () => {
        addNotification({
          type: 'success',
          message: i18n._(t`Successfully created doc with id "${state.docId}".`)
        });
        createHintMutation.reset();
        hintQuery.refetch();
      },
      onError: () => {
        addNotification({
          type: 'error',
          message: i18n._(t`Failed to created doc with id "${state.docId}".`)
        });
      }
    }
  );

  const getTitle = useCallback(() => {
    if (hintQuery.isLoading) {
      return <Trans>Loading documentation</Trans>;
    }

    if (hintQuery.isError) {
      if (isNotFound) {
        return <Trans>Documentation not found</Trans>;
      }

      return <Trans>Documentation error</Trans>;
    }

    if (hintQuery.isSuccess && hintQuery.data) {
      return <>{hintQuery.data.title}</>;
    }

    return undefined;
  }, [hintQuery, isNotFound]);

  const handleCreateDoc = () => {
    if (state.docId && state.docConfig && language) {
      const payload: CreateHintParams = {
        elementId: state.docId,
        title: state.docConfig?.navLabel,
        lang: language,
        tooltip: `Tooltip for "${state.docId}"...`
      };

      createHintMutation.mutate(payload);
    }
  };

  useEffect(() => {
    if (pathParams?.[0]) {
      const p = pathParams[0].split('/');
      const currentpath = p[p.length - 1].toUpperCase().replaceAll('-', '_');
      setState(draft => {
        draft.docId = currentpath as DocId;
        draft.docConfig = getDocConfig(currentpath as DocId);
      });
    } else {
      setState(draft => {
        draft.docId = DocId.BIG_DATA_SERVICE;
        draft.docConfig = getDocConfig(DocId.BIG_DATA_SERVICE);
      });
    }
  }, [pathParams, setState]);

  useEffect(() => {
    if (state.docId) {
      hintQueryRef.refetch();
    }
  }, [state.docId, hintQueryRef]);

  return (
    <>
      <Page
        error={
          hintQuery.isError &&
          !isNotFound && {
            message: <Trans>Failed to load documentation</Trans>,
            error: hintQuery.error
          }
        }
        isLoading={hintQuery.isLoading}
        exception={
          isNotFound && {
            code: 404,
            message: <Trans>Documentation was not found.</Trans>,
            buttons: (
              <AuthRestricted rolesAllowed={[AuthRole.SUPERADMIN]}>
                <Button action="create" onClick={handleCreateDoc}>
                  <Trans>Create doc</Trans>
                </Button>
              </AuthRestricted>
            )
          }
        }
        noProvider
        content={
          <>
            {hintQuery.isLoading && <Loader fullHeight />}
            {hintQuery.data && (
              <>
                {editorMode === 'read' && <Editor value={hintQuery.data.parsedText} readonly />}
                {editorMode === 'edit' && state.docConfig && (
                  <DocsEditorForm data={hintQuery.data} config={state.docConfig} />
                )}
              </>
            )}
          </>
        }
        titleTop={
          <Desktop>
            <Breadcrumbs />
            <S.DocsTop>
              <DocsSearch />
              <AuthRestricted rolesAllowed={[AuthRole.SUPERADMIN]}>
                <DocsModeSwitch />
              </AuthRestricted>
            </S.DocsTop>
          </Desktop>
        }
        scrollableProps={{ scrollId: 'page-scroll' }}
        title={getTitle()}
      />
    </>
  );
};

export default DocsPage;
