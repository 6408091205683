import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import { Menu } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import clsx from 'clsx';
import { CSSProperties } from 'styled-components';

import CheckDbConnectionAction from './checkDbConnectionAction';
import CreateDbImportAction from './createDbImportAction';
import DeleteDbImportAction from './deleteDbImportAction';
import EditDbImportAction from './editDbImportAction';

import ContextNavigation from 'components/app/components/contextNavigation';
import ActivateTriggerActions from 'components/trigger/actions/activateTriggerActions';
import DeleteTriggerAction from 'components/trigger/actions/deleteTriggerAction';
import DuplicateTriggerAction from 'components/trigger/actions/duplicateTriggerAction';
import EditTriggerAction from 'components/trigger/actions/editTriggerAction';
import ExecuteTriggerAction from 'components/trigger/actions/executeTriggerAction';
import Button from 'components/ui/button';
import useDbImportName from 'hooks/useDbImportName';
import useDomainName from 'hooks/useDomainName';
import { ActionMode } from 'index.types';
import { DbImport } from 'services/api/domain/dataImport';
import { useTriggers } from 'services/api/domain/trigger';

type DbImportActionsProps = {
  data: DbImport;
  style?: CSSProperties;
  className?: string;
  mode: ActionMode;
  popoverPlacement: TooltipPlacement;
};

const { Divider, SubMenu } = Menu;

const DbImportActions: React.FC<DbImportActionsProps> = ({
  data,
  style,
  mode,
  popoverPlacement,
  className
}) => {
  const dbImportName = useDbImportName();
  const domainName = useDomainName();

  const triggeresQuery = useTriggers({ domainName }, { enabled: false });
  const attachedTriggers = triggeresQuery.data?.filter(t => t.taskName === data.name);

  return (
    <ContextNavigation
      placement={popoverPlacement}
      trigger={
        <Button
          isIconOnly={mode === 'ICON_BUTTON'}
          hasMenu
          action="actions"
          className={clsx(['trigger-actions', className])}
          style={style}
          title={i18n._('Trigger actions')}
        />
      }
    >
      {dbImportName ? (
        <CreateDbImportAction mode="LIST_ITEM" />
      ) : (
        <EditDbImportAction data={data} mode="LIST_ITEM" />
      )}

      {!dbImportName && (
        <>
          <Divider />
          <CheckDbConnectionAction data={data} />
        </>
      )}

      {attachedTriggers && attachedTriggers.length > 0 && (
        <>
          <Divider />
          <SubMenu
            title={<Trans>Triggers</Trans>}
            icon={<FontAwesomeIcon icon={['fas', 'clock']} />}
            key="triggers"
          >
            {attachedTriggers.map(trigger => (
              <SubMenu
                key={`trigger-${trigger.id}`}
                title={trigger.name}
                icon={<FontAwesomeIcon icon={['fas', 'clock']} />}
              >
                <EditTriggerAction data={trigger} />
                <DuplicateTriggerAction data={trigger} />
                <Divider />
                <ExecuteTriggerAction data={trigger} />
                <Divider />
                <ActivateTriggerActions data={trigger} />
                <Divider />
                <DeleteTriggerAction data={trigger} />
              </SubMenu>
            ))}
          </SubMenu>
        </>
      )}

      <Divider />
      <DeleteDbImportAction data={data} />
    </ContextNavigation>
  );
};

export default DbImportActions;
