import tw, { css, styled } from 'twin.macro';

export const TestConnection = styled.div(() => [
  css`
    ${tw`border-t border-ant-border-gray pt-18`};
  `
]);

export const MapperInfo = styled.ul(() => [
  css`
    ${tw` border border-ant-border-gray rounded-4 overflow-hidden`}

    & li {
      ${tw`p-12 border-b border-ant-border-gray flex flex-row`}

      &.head {
        ${tw`bg-light-gray`}

        p {
          ${tw`font-600 font-sans text-13`}
        }
      }

      &:last-of-type {
        ${tw`border-none`}
      }
    }

    & p {
      &:first-of-type {
        width: 50%;
      }

      ${tw`font-400 font-mono text-13`}

      &.tabed {
        ${tw`pl-24`}
      }

      & span {
        ${tw`font-400 font-mono text-12`}
      }
    }
  `
]);

export const SqlStatement = styled.div(() => [
  css`
    ${tw` mt-12`};
  `
]);
