import React from 'react';

import { Trans } from '@lingui/macro';
import { Alert } from 'antd';
import { AxiosError } from 'axios';
import { UseMutationResult } from 'react-query';

import ErrorInfo from 'components/ui/errorInfo';
import LoaderMessage from 'components/ui/loader/loaderMessage';
import { CheckFtPConnectionParams } from 'services/api/domain/ftpConnection';

type TestFtpConnectionResponseProps = {
  query: UseMutationResult<void, AxiosError, CheckFtPConnectionParams>;
};

const TestFtpConnectionResponse: React.FC<TestFtpConnectionResponseProps> = ({ query }) => (
  <>
    {query.isLoading && (
      <LoaderMessage>
        <Trans>Checking ftp connection.</Trans>
      </LoaderMessage>
    )}
    {query.isError && (
      <ErrorInfo message={<Trans>Failed to to connect to FTP server.</Trans>} error={query.error} />
    )}
    {query.isSuccess && (
      <Alert
        type="success"
        showIcon
        message={<Trans>Successfully established FTP connection.</Trans>}
      />
    )}
  </>
);

export default TestFtpConnectionResponse;
