import React from 'react';

import { Trans } from '@lingui/macro';

import AppLayout from 'components/app/components/appLayout';
import CsvImportForm from 'components/domain/components/csvImportForm';
import DomainPage from 'components/domain/components/domainPage';
import Button from 'components/ui/button';
import routes from 'config/routes/routes';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';

const CsvImportCreatePage: React.FC = () => {
  const goto = useGoToDomainRoute();

  const handleGotoList = () => {
    goto(routes.domain.dataManagement.importFromCsv.list);
  };

  return (
    <AppLayout>
      <DomainPage
        route={routes.domain.dataManagement.importFromCsv.create}
        title={<Trans>Create CSV Import</Trans>}
        schemaSelector={{ readonly: true }}
        headerButtons={
          <Button action="list" type="ghost" onClick={handleGotoList}>
            <Trans>Back to list</Trans>
          </Button>
        }
        content={<CsvImportForm />}
      />
    </AppLayout>
  );
};

export default CsvImportCreatePage;
