import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import { Menu } from 'antd';
import { omit } from 'lodash';
import { CSSProperties } from 'styled-components';

import Button from 'components/ui/button';
import { ButtonProps } from 'components/ui/button/button.types';
import routes from 'config/routes/routes';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';

const { Item } = Menu;

type EditObjectActionProps = {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  data: any;
  mode: 'MENU_ITEM' | 'BUTTON' | 'ICON_BUTTON';
  buttonProps?: ButtonProps;
  style?: CSSProperties;
};

const EditObjectAction: React.FC<EditObjectActionProps> = props => {
  const antProps = omit(props, ['data', 'mode', 'buttonProps', 'style']);
  const { data, mode, buttonProps, style } = props;
  const goto = useGoToDomainRoute();

  const handleEditObjectRequest = () => {
    goto(routes.domain.queryData.entityDocument.single, {
      path: data.id
    });
  };

  return (
    <>
      {mode === 'MENU_ITEM' && (
        <Item
          {...antProps}
          style={style}
          key={`edit_object_${data.id}`}
          icon={<FontAwesomeIcon icon={['fas', 'pen']} />}
          onClick={handleEditObjectRequest}
        >
          <Trans>Edit object</Trans>
        </Item>
      )}
      {mode === 'BUTTON' && (
        <Button action="edit" {...buttonProps} style={style} onClick={handleEditObjectRequest}>
          <Trans>Edit object</Trans>
        </Button>
      )}

      {mode === 'ICON_BUTTON' && (
        <Button
          action="edit"
          isIconOnly
          type="link"
          {...buttonProps}
          style={style}
          onClick={handleEditObjectRequest}
          title={i18n._('Edit object')}
        />
      )}
    </>
  );
};

export default EditObjectAction;
