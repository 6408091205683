import { Node } from 'slate';

import { Language } from 'config/langauge/languages.types';

export enum DocRubric {
  BIG_DATA_SERVICE = 'BIG_DATA_SERVICE',

  DOMAIN = 'DOMAIN',

  DOMAIN_DATA_MANAGEMENT = 'DOMAIN_DATA_MANAGEMENT',
  DOMAIN_ADMINISTRATION = 'DOMAIN_ADMINISTRATION',
  DOMAIN_IMPORT_FROM_DB = 'DOMAIN_IMPORT_FROM_DB',
  DOMAIN_EXPORT = 'DOMAIN_EXPORT',
  DOMAIN_DATA_DESIGNER = 'DOMAIN_DATA_DESIGNER',
  DOMAIN_ENTITY_DOCUMENT_DIAGRAM = 'DOMAIN_ENTITY_DOCUMENT_DIAGRAM',
  DOMAIN_CAPTURE_STRUCTURE = 'DOMAIN_CAPTURE_STRUCTURE'
}

export enum DocId {
  BIG_DATA_SERVICE = 'BIG_DATA_SERVICE',

  DOMAIN = 'DOMAIN',

  // domain overview
  DOMAIN_OVERVIEW = 'DOMAIN_OVERVIEW',

  DOMAIN_DOMAIN_ID = 'DOMAIN_DOMAIN_ID',
  DOMAIN_DOMAIN_NAME = 'DOMAIN_DOMAIN_NAME',
  DOMAIN_API_KEY = 'DOMAIN_API_KEY',
  DOMAIN_SCHEMA = 'DOMAIN_SCHEMA',
  DOMAIN_URI = 'DOMAIN_URI',
  DOMAIN_DESCRIPTION = 'DOMAIN_DESCRIPTION',
  DOMAIN_CLIENT_CONNECTIONS = 'DOMAIN_CLIENT_CONNECTIONS',
  DOMAIN_ADMIN_USER = 'DOMAIN_ADMIN_USER',
  DOMAIN_SHARD = 'DOMAIN_SHARDS',
  DOMAIN_DELETABLE = 'DOMAIN_DELETABLE',
  DOMAIN_ADMINISTRATOR = 'DOMAIN_ADMINISTRATOR',

  // domain data management
  DOMAIN_DATA_MANAGEMENT = 'DOMAIN_DATA_MANAGEMENT',

  DOMAIN_EXPORT = 'DOMAIN_EXPORT',
  DOMAIN_DATA_DESIGNER = 'DOMAIN_DATA_DESIGNER',
  DOMAIN_CAPTURE_STRUCTURE = 'DOMAIN_CAPTURE_STRUCTURE',

  USERS_AND_PRIVILEGES = 'USERS_AND_PRIVILEGES',
  USERS_AND_PRIVILEGES_EMAIL = 'USERS_AND_PRIVILEGES_EMAIL',
  USERS_AND_PRIVILEGES_ROLE = 'USERS_AND_PRIVILEGES_ROLE',

  // domain import from db
  DOMAIN_IMPORT_FROM_DB = 'DOMAIN_IMPORT_FROM_DB',
  DOMAIN_IMPORT_FROM_DB_ID = 'DOMAIN_IMPORT_FROM_DB_ID',
  DOMAIN_IMPORT_FROM_DB_NAME = 'DOMAIN_IMPORT_FROM_DB_NAME',
  DOMAIN_IMPORT_FROM_DB_DB_CONFIG = 'DOMAIN_IMPORT_FROM_DB_DB_CONFIG',
  DOMAIN_IMPORT_FROM_DB_DB_URL = 'DOMAIN_IMPORT_FROM_DB_DB_URL',
  DOMAIN_IMPORT_FROM_DB_DB_USER = 'DOMAIN_IMPORT_FROM_DB_DB_USER',
  DOMAIN_IMPORT_FROM_DB_DB_PASSWORD = 'DOMAIN_IMPORT_FROM_DB_DB_PASSWORD',
  DOMAIN_IMPORT_FROM_DB_DRIVER_CLASS = 'DOMAIN_IMPORT_FROM_DB_DRIVER_CLASS',
  DOMAIN_IMPORT_FROM_DB_ENTITY_NAME = 'DOMAIN_IMPORT_FROM_DB_ENTITY_NAME',
  DOMAIN_IMPORT_FROM_DB_UPDATE_CREATE = 'DOMAIN_IMPORT_FROM_DB_UPDATE_CREATE',
  DOMAIN_IMPORT_FROM_DB_SQL_STATEMENT = 'DOMAIN_IMPORT_FROM_DB_SQL_STATEMENT',
  DOMAIN_IMPORT_FROM_DB_BATCH_SIZE = 'DOMAIN_IMPORT_FROM_DB_BATCH_SIZE',
  DOMAIN_IMPORT_FROM_DB_MAX_ROWS = 'DOMAIN_IMPORT_FROM_DB_MAX_ROWS',
  DOMAIN_IMPORT_FROM_DB_TABLE_ENTITY_MAPPER = 'DOMAIN_IMPORT_FROM_DB_TABLE_ENTITY_MAPPER',
  DOMAIN_IMPORT_FROM_DB_TABLE_ENTITY_MAPPER_FIELD = 'DOMAIN_IMPORT_FROM_DB_TABLE_ENTITY_MAPPER_FIELD',
  DOMAIN_IMPORT_FROM_DB_TABLE_ENTITY_MAPPER_COLUMN = 'DOMAIN_IMPORT_FROM_DB_TABLE_ENTITY_MAPPER_COLUMN',
  DOMAIN_IMPORT_FROM_DB_SCHEDULER = 'DOMAIN_IMPORT_FROM_DB_SCHEDULER',

  // domain import from csv
  DOMAIN_IMPORT_FROM_CSV = 'DOMAIN_IMPORT_FROM_CSV',
  DOMAIN_IMPORT_FROM_CSV_ID = 'DOMAIN_IMPORT_FROM_CSV_ID',
  DOMAIN_IMPORT_FROM_CSV_NAME = 'DOMAIN_IMPORT_FROM_CSV_NAME',
  DOMAIN_IMPORT_FROM_CSV_ENTITY = 'DOMAIN_IMPORT_FROM_CSV_ENTITY',
  DOMAIN_IMPORT_FROM_CSV_DELIMITER = 'DOMAIN_IMPORT_FROM_CSV_DELIMITER',
  DOMAIN_IMPORT_FROM_CSV_HEADER = 'DOMAIN_IMPORT_FROM_CSV_HEADER',
  DOMAIN_IMPORT_FROM_CSV_UPDATE_CREATE = 'DOMAIN_IMPORT_FROM_CSV_UPDATE_CREATE',
  DOMAIN_IMPORT_FROM_CSV_UPLOAD_FILE = 'DOMAIN_IMPORT_FROM_CSV_UPLOAD_FILE',
  DOMAIN_IMPORT_FROM_CSV_IMPORT_FTP_CONNECTION = 'DOMAIN_IMPORT_FROM_CSV_IMPORT_FTP_CONNECTION',
  DOMAIN_IMPORT_FROM_CSV_FILE = 'DOMAIN_IMPORT_FROM_CSV_FILE',
  DOMAIN_IMPORT_FROM_CSV_MAPPING = 'DOMAIN_IMPORT_FROM_CSV_MAPPING',
  DOMAIN_IMPORT_FROM_CSV_COLUMN_ENTITY_MAPPER_FIELD = 'DOMAIN_IMPORT_FROM_CSV_COLUMN_ENTITY_MAPPER_FIELD',
  DOMAIN_IMPORT_FROM_CSV_COLUMN_ENTITY_MAPPER_COLUMN = 'DOMAIN_IMPORT_FROM_CSV_COLUMN_ENTITY_MAPPER_COLUMN',
  DOMAIN_IMPORT_FROM_CSV_SCHEDULER = 'DOMAIN_IMPORT_FROM_CSV_SCHEDULER',

  // domain administration
  DOMAIN_ADMINISTRATION = 'DOMAIN_ADMINISTRATION',
  DOMAIN_ADMINISTRATION_MERGE_DOMAINS = 'DOMAIN_ADMINISTRATION_MERGE_DOMAINS',
  DOMAIN_ADMINISTRATION_MERGE_DOMAINS_FROM = 'DOMAIN_ADMINISTRATION_MERGE_DOMAINS_FROM',
  DOMAIN_ADMINISTRATION_MERGE_DOMAINS_TARGET = 'DOMAIN_ADMINISTRATION_MERGE_DOMAINS_TARGET',
  DOMAIN_ADMINISTRATION_REINDEX_DOMAIN = 'DOMAIN_ADMINISTRATION_REINDEX_DOMAIN',
  DOMAIN_ADMINISTRATION_REINDEX_DOMAIN_BATCHSIZE = 'DOMAIN_ADMINISTRATION_REINDEX_DOMAIN_BATCHSIZE',
  DOMAIN_ADMINISTRATION_REINDEX_DOMAIN_DOMAIN = 'DOMAIN_ADMINISTRATION_REINDEX_DOMAIN_DOMAIN',

  // domain json import/export
  DOMAIN_JSON_IMPEX = 'DOMAIN_JSON_IMPEX',
  DOMAIN_JSON_IMPEX_IMPORT = 'DOMAIN_JSON_IMPEX_IMPORT',
  DOMAIN_JSON_IMPEX_IMPORT_FILETYPE = 'DOMAIN_JSON_IMPEX_IMPORT_FILETYPE',
  DOMAIN_JSON_IMPEX_EXPORT = 'DOMAIN_JSON_IMPEX_EXPORT',
  DOMAIN_JSON_IMPEX_EXPORT_FILETYPE = 'DOMAIN_JSON_IMPEX_EXPORT_FILETYPE',
  DOMAIN_JSON_IMPEX_EXPORT_PGPKEY = 'DOMAIN_JSON_IMPEX_EXPORT_PGPKEY',
  DOMAIN_JSON_IMPEX_EXPORT_ENTITY = 'DOMAIN_JSON_IMPEX_EXPORT_ENTITY',

  // domain testdata generator
  DOMAIN_TEST_DATA_GENERATOR = 'DOMAIN_TEST_DATA_GENERATOR',
  DOMAIN_TESTDATA_GENERATOR_ENTITY = 'DOMAIN_TESTDATA_GENERATOR_ENTITY',
  DOMAIN_TESTDATA_GENERATOR_AMOUNT = 'DOMAIN_TESTDATA_GENERATOR_AMOUNT',

  // domain diagram
  DOMAIN_DIAGRAM = 'DOMAIN_DIAGRAM',
  DOMAIN_DIAGRAM_ENTITY = 'DOMAIN_DIAGRAM_ENTITY',
  DOMAIN_DIAGRAM_ENTITY_FULL_CLASS_NAME = 'DOMAIN_DIAGRAM_ENTITY_FULL_CLASS_NAME',
  DOMAIN_DIAGRAM_ENTITY_SIMPLE_CLASS_NAME = 'DOMAIN_DIAGRAM_ENTITY_SIMPLE_CLASS_NAME',
  DOMAIN_DIAGRAM_ENTITY_FIELDS = 'DOMAIN_DIAGRAM_ENTITY_FIELDS',

  DOMAIN_DIAGRAM_FIELD = 'DOMAIN_DIAGRAM_FIELD',
  DOMAIN_DIAGRAM_FIELD_MISSING_EDGE_OUT = 'DOMAIN_DIAGRAM_FIELD_MISSING_EDGE_OUT',
  DOMAIN_DIAGRAM_FIELD_NAME = 'DOMAIN_DIAGRAM_FIELD_NAME',
  DOMAIN_DIAGRAM_FIELD_DATA_TYPE = 'DOMAIN_DIAGRAM_FIELD_DATA_TYPE',
  DOMAIN_DIAGRAM_FIELD_UNIQUE_ID = 'DOMAIN_DIAGRAM_FIELD_UNIQUE_ID',
  DOMAIN_DIAGRAM_FIELD_RELATION_TYPE = 'DOMAIN_DIAGRAM_FIELD_RELATION_TYPE',
  DOMAIN_DIAGRAM_FIELD_AGGREGATION_TYPE = 'DOMAIN_DIAGRAM_FIELD_AGGREGATION_TYPE',

  DOMAIN_DIAGRAM_OBJECT_RELATION = 'DOMAIN_DIAGRAM_OBJECT_RELATION',

  // domain capture structure
  DOMAIN_CAPTURE_STRUCUTRE = 'DOMAIN_CAPTURE_STRUCUTRE',
  DOMAIN_CAPTURE_STRUCUTRE_EMBEDDED = 'DOMAIN_CAPTURE_STRUCUTRE_EMBEDDED',
  DOMAIN_CAPTURE_STRUCUTRE_TYPE = 'DOMAIN_CAPTURE_STRUCUTRE_TYPE',
  DOMAIN_CAPTURE_STRUCUTRE_DATA = 'DOMAIN_CAPTURE_STRUCUTRE_DATA',

  // domain query data
  DOMAIN_QUERY = 'DOMAIN_QUERY',

  // domain query entity/object
  DOMAIN_QUERY_ENTITY = 'DOMAIN_QUERY_ENTITY',
  DOMAIN_QUERY_ENTITY_SELECT_ENTITY = 'DOMAIN_QUERY_ENTITY_SELECT_ENTITY',
  DOMAIN_QUERY_ENTITY_QUERY = 'DOMAIN_QUERY_ENTITY_QUERY',
  DOMAIN_QUERY_ENTITY_RESULT = 'DOMAIN_QUERY_ENTITY_RESULT',

  // domain query document history
  DOMAIN_DOCUMENT_HISTORY = 'DOMAIN_DOCUMENT_HISTORY',
  DOMAIN_DOCUMENT_HISTORY_SEARCH_ID = 'DOMAIN_DOCUMENT_HISTORY_SEARCH_ID',
  DOMAIN_DOCUMENT_HISTORY_SEARCH_DEPTH = 'DOMAIN_DOCUMENT_HISTORY_SEARCH_DEPTH',
  DOMAIN_DOCUMENT_HISTORY_DATE_OF_CHANGE = 'DOMAIN_DOCUMENT_HISTORY_DATE_OF_CHANGE',
  DOMAIN_DOCUMENT_HISTORY_USER_ID = 'DOMAIN_DOCUMENT_HISTORY_USER_ID',
  DOMAIN_DOCUMENT_HISTORY_OBJECT_DOMAINID = 'DOMAIN_DOCUMENT_HISTORY_OBJECT_DOMAINID',
  DOMAIN_DOCUMENT_HISTORY_ID = 'DOMAIN_DOCUMENT_HISTORY_ID',
  DOMAIN_DOCUMENT_HISTORY_OBJECT_TYPE = 'DOMAIN_DOCUMENT_HISTORY_OBJECT_TYPE',
  DOMAIN_DOCUMENT_HISTORY_CUSTOM_TYPE = 'DOMAIN_DOCUMENT_HISTORY_CUSTOM_TYPE',
  DOMAIN_DOCUMENT_HISTORY_DIFF = 'DOMAIN_DOCUMENT_HISTORY_DIFF',
  DOMAIN_DOCUMENT_HISTORY_OLD_JSON = 'DOMAIN_DOCUMENT_HISTORY_OLD_JSON',

  // domain query metadata
  DOMAIN_QUERY_METADATA = 'DOMAIN_QUERY_METADATA',
  DOMAIN_METADATA_SEARCH_ENTITY = 'DOMAIN_METADATA_SEARCH_ENTITY',

  // scheduler
  SCHEDULER = 'SCHEDULER',
  SCHEDULER_TRIGGER = 'SCHEDULER_TRIGGER',
  SCHEDULER_TRIGGER_ID = 'SCHEDULER_TRIGGER_ID',
  SCHEDULER_TRIGGER_NAME = 'SCHEDULER_TRIGGER_NAME',
  SCHEDULER_TRIGGER_ACTIVE = 'SCHEDULER_TRIGGER_ACTIVE',
  SCHEDULER_TRIGGER_TASK_DOMAIN = 'SCHEDULER_TRIGGER_TASK_DOMAIN',
  SCHEDULER_TRIGGER_TASK_SCHEME = 'SCHEDULER_TRIGGER_TASK_SCHEME',
  SCHEDULER_TRIGGER_TASK_NAME = 'SCHEDULER_TRIGGER_TASK_NAME',
  SCHEDULER_TRIGGER_TASK_TYPE = 'SCHEDULER_TRIGGER_TASK_TYPE',
  SCHEDULER_TRIGGER_START_TIME = 'SCHEDULER_TRIGGER_START_TIME',
  SCHEDULER_TRIGGER_PERIODICITY = 'SCHEDULER_TRIGGER_PERIODICITY',
  SCHEDULER_TRIGGER_TIME_VALUE = 'SCHEDULER_TRIGGER_TIME_VALUE',

  // domain connections
  DOMAIN_CONNECTIONS = 'DOMAIN_CONNECTIONS',
  // ftp
  DOMAIN_CONNECTIONS_FTP = 'DOMAIN_CONNECTIONS_FTP',
  DOMAIN_CONNECTIONS_FTP_ID = 'DOMAIN_CONNECTIONS_FTP_ID',
  DOMAIN_CONNECTIONS_FTP_NAME = 'DOMAIN_CONNECTIONS_FTP_NAME',
  DOMAIN_CONNECTIONS_FTP_SFTP = 'DOMAIN_CONNECTIONS_FTP_SFTP',
  DOMAIN_CONNECTIONS_FTP_REMOTE_HOST = 'DOMAIN_CONNECTIONS_FTP_REMOTE_HOST',
  DOMAIN_CONNECTIONS_FTP_REMOTE_DIRECTORY = 'DOMAIN_CONNECTIONS_FTP_REMOTE_DIRECTORY',
  DOMAIN_CONNECTIONS_FTP_USERANME = 'DOMAIN_CONNECTIONS_FTP_USERANME',
  DOMAIN_CONNECTIONS_FTP_PASSWORD = 'DOMAIN_CONNECTIONS_FTP_PASSWORD',

  // doomain user privileges
  USERADMIN = 'USERADMIN',
  USERADMIN_USER = 'USERADMIN_USER',
  USERADMIN_USER_ID = 'USERADMIN_USER_ID',
  USERADMIN_USER_USERNAME = 'USERADMIN_USER_USERNAME',
  USERADMIN_USER_0RGANISATIONS = 'USERADMIN_USER_ORGANSIATIONS',

  USERADMIN_ORG = 'USERADMIN_ORG',
  USERADMIN_ORG_ID = 'USERADMIN_ORG_ID',
  USERADMIN_ORG_NAME = 'USERADMIN_ORG_NAME',
  USERADMIN_ORG_DESCRIPTION = 'USERADMIN_ORG_DESCRIPTION',
  USERADMIN_ORG_ADMINS = 'USERADMIN_ORG_ADMINS'
}

export type Hint = {
  id?: string;
  elementId: string;
  lang: Language;
  tooltip: string;
  keywords: string[];
  title: string;
  text: string;
  comments: string;
  plainText: string;
  parsedText?: Node[];
  parsedComments?: Node[];
};

export type CreateHintParams = {
  elementId: string;
  lang: Language;
  tooltip: string;
  title: string;
};

export type HintSearchResult = {
  [key: string]: {
    elementId: string[];
    title: string[];
    plainText_text?: string[];
    tooltip_text?: string[];
  };
};

export type SearchHintParams = {
  language: Language;
  phrase: string;
};

export type HintApiInfo = {
  domainName: string;
  schemaName: string;
  apiKey: string;
};
