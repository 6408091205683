import tw, { css, styled } from 'twin.macro';

export const StyledImageUpload = styled.div(() => [
  css`
    & .ant-form-item-label {
      ${tw`h-32`};
    }

    /* & .file-selector {
      & .file-content.image {
        min-width: 24em;

        & img {
          min-width: 24rem;
          min-height: unset;
          max-height: 24rem;
        }
      }
    } */
  `
]);

export const ImageOverlay = styled.div(() => [
  css`
    & > div {
      ${tw`relative border border-ant-border-gray mt-6 mb-12`};

      & img {
        ${tw`w-full h-auto`};
      }

      & .ant-btn {
        ${tw`absolute top-4 left-4`};
      }
    }
  `
]);
