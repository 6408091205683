import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Menu } from 'antd';
import { omit } from 'lodash';

import DuplicateTriggerDialog from './duplicateTriggerDialog';

import { Trigger } from 'services/api/domain/trigger';

const { Item } = Menu;

type DuplicateTriggerActionProps = {
  data: Trigger;
};

const DuplicateTriggerAction: React.FC<DuplicateTriggerActionProps> = props => {
  const antProps = omit(props, ['data']);
  const { data } = props;
  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleDeleteRequest = () => {
    setDialogOpen(true);
  };

  return (
    <>
      <Item
        {...antProps}
        key={`delete-trigger_${data.id}`}
        icon={<FontAwesomeIcon icon={['fas', 'copy']} />}
        onClick={handleDeleteRequest}
      >
        <Trans>Duplicate trigger</Trans>
      </Item>

      <DuplicateTriggerDialog
        isOpen={isDialogOpen}
        data={data}
        close={() => setDialogOpen(false)}
      />
    </>
  );
};

export default DuplicateTriggerAction;
