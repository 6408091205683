import tw, { styled, css } from 'twin.macro';

export const Container = styled.div(() => [
  css`
    ${tw`h-screen w-screen flex flex-col relative`};

    & > h4 {
      ${tw`font-700 mx-40 mt-12 mb-16`};
    }

    & > div {
      ${tw`h-full relative`};
    }
  `
]);
