import { AxiosError } from 'axios';
import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';

import {
  api_generateApiKey,
  api_getDomainById,
  api_getDomainByName,
  api_getDomains
} from './domain.queries';
import { Domain } from './domain.types';

export const useDomain = (
  domainName: string,
  options?: UseQueryOptions<Domain, AxiosError, Domain>
): QueryObserverResult<Domain, AxiosError> =>
  useQuery<Domain, AxiosError>(
    ['domain', domainName],
    () => api_getDomainByName(domainName),
    options
  );

export const useDomainById = (
  domainId: string,
  options?: UseQueryOptions<Domain, AxiosError, Domain>
): QueryObserverResult<Domain, AxiosError> =>
  useQuery<Domain, AxiosError>(
    ['domainById', domainId],
    () => api_getDomainById(domainId),
    options
  );

export const useDomains = (
  options?: UseQueryOptions<Domain[], AxiosError, Domain[]>
): QueryObserverResult<Domain[], AxiosError> =>
  useQuery<Domain[], AxiosError>(['domains'], () => api_getDomains(), options);

export const useGenerateApiKey = (
  options?: UseQueryOptions<string, AxiosError, string>
): QueryObserverResult<string, AxiosError> =>
  useQuery<string, AxiosError>(['apiKey'], () => api_generateApiKey(), options);
