import React from 'react';

import { PageProvider } from '@phoenix-systems/react-layout';

import AppLayout from 'components/app/components/appLayout';
import DomainDiagram from 'components/domain/components/domainDiagram';

const DomainDiagramPage: React.FC = () => (
  <AppLayout>
    <PageProvider>
      <DomainDiagram />
    </PageProvider>
  </AppLayout>
);

export default DomainDiagramPage;
