import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { getRoute } from '@phoenix-systems/react-router';
import { Menu, Popover } from 'antd';
import { omit } from 'lodash';
import { Link } from 'react-router-dom';
import { useImmer } from 'use-immer';

import * as S from './deleteDomainActionSc';
import DeleteDomainDialog from './deleteDomainDialog';

import Button from 'components/ui/button';
import { ButtonProps } from 'components/ui/button/button.types';
import routes from 'config/routes/routes';
import { Domain } from 'services/api/domain/domain';

const { Item } = Menu;

type DeleteDomainActionProps = {
  data: Domain;
  mode: 'MENU_ITEM' | 'BUTTON';
  buttonProps?: ButtonProps;
};

type DeleteDomainActionState = {
  isDialogOpen: boolean;
};

const DeleteDomainAction: React.FC<DeleteDomainActionProps> = props => {
  const antProps = omit(props, ['data', 'mode', 'buttonProps']);
  const { data, buttonProps, mode } = props;
  const [state, setState] = useImmer<DeleteDomainActionState>({
    isDialogOpen: false
  });

  const handleDeleteDomainRequest = () => {
    setState(draft => {
      draft.isDialogOpen = true;
    });
  };

  const button = (
    <Button
      action="delete"
      {...buttonProps}
      disabled={!data.deletable}
      onClick={handleDeleteDomainRequest}
    >
      <Trans>Delete domain</Trans>
    </Button>
  );

  return (
    <>
      {mode === 'MENU_ITEM' && (
        <Item
          {...antProps}
          key={`activate-trigger_${data.id}`}
          icon={<FontAwesomeIcon icon={['fas', 'trash']} />}
          onClick={handleDeleteDomainRequest}
          disabled={!data.deletable}
        >
          <Trans>Delete domain</Trans>
        </Item>
      )}
      {mode === 'BUTTON' && (
        <>
          {data.deletable ? (
            button
          ) : (
            <Popover
              placement="bottom"
              trigger="hover"
              content={
                <S.Alert>
                  <Trans>
                    This domain is not deletable. Enable &quot;Deletable&quot; option in the{' '}
                    <Link to={getRoute(routes.domain.overview, data.domainName)}>
                      domain configuration.
                    </Link>
                  </Trans>
                </S.Alert>
              }
            >
              {button}
            </Popover>
          )}
        </>
      )}

      <DeleteDomainDialog
        isOpen={state.isDialogOpen}
        data={data}
        close={() =>
          setState(draft => {
            draft.isDialogOpen = false;
          })
        }
      />
    </>
  );
};

export default DeleteDomainAction;
