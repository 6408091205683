import React from 'react';

import { useAuthState } from '@phoenix-systems/react-keycloak-auth';
import { Redirect } from 'react-router';

import routes from 'config/routes/routes';

const AuthProviderInner: React.FC = ({ children }) => {
  const { isAuthenticated } = useAuthState();

  if (!isAuthenticated) {
    return <Redirect to={routes.login.root} />;
  }

  return <>{children}</>;
};

export default AuthProviderInner;
