/**
 * Do not edit this file. its generically produced form js modules
 */
import { AppStylesConfig } from 'index.types';

const appStyles: AppStylesConfig = {
  htmlFontSize: 10,
  colors: {
    primary: '#12084e', // blue
    secondary: '#e73528', // orange
    black: '#22292F',
    lightGray: '#f6f7f8',
    lightGrayActive: '#dedfe8',
    antBorderGray: '#E0E0E0',
    redDelete: '#f11919',
    blueCreate: '#3873f5',
    greenSave: '#009744'
  },
  fonts: {
    sans: [
      "'Inter'",
      "'Noto Sans'",
      'sans-serif',
      "'Apple Color Emoji'",
      "'Segoe UI Emoji'",
      "'Segoe UI Symbol'",
      "'Noto Color Emoji'"
    ],
    serif: ['Georgia', 'Cambria', '"Times New Roman"', 'Times', 'serif'],
    mono: ['Menlo', 'Monaco', 'Consolas', '"Liberation Mono"', '"Courier New"', 'monospace']
  },
  breakpoints: {
    xs: 480,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600
  }
};

export default appStyles;
