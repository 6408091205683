import { useCallback, useEffect } from 'react';

import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useCopyToClipboard as useCp } from 'react-use';

import useNotification from './useNotification';

type CopyToClipboardHandler = (copyValue?: string, message?: string) => void;

const useCopyToClipboard = (): CopyToClipboardHandler => {
  const [{ error }, copyToClipboard] = useCp();
  const [addNotification] = useNotification();

  const notify = useCallback(
    (copyValue?: string, message?: string) => {
      if (copyValue) {
        copyToClipboard(copyValue);
        addNotification({
          type: 'info',
          message: message || i18n._(t`Copied value "${copyValue}" to clipboard`)
        });
      }
    },
    [copyToClipboard, addNotification]
  );

  useEffect(() => {
    if (error) {
      addNotification({
        type: 'error',
        message: i18n._(t`Failed to copy value`)
      });
    }
  }, [error, addNotification]);

  return notify;
};

export default useCopyToClipboard;
