import React from 'react';

import CsvImportSingle from './csvImportSingle';

import AppLayout from 'components/app/components/appLayout';

const CsvImportSinglePage: React.FC = () => (
  <AppLayout>
    <CsvImportSingle />
  </AppLayout>
);

export default CsvImportSinglePage;
