import { produce } from 'immer';
import { clone, merge } from 'lodash';

import { DbImportsTableActionCreators } from './dbImportsTable.actions';
import {
  DbImportsTableActionTypes,
  DbImportsTableState,
  DB_IMPORT_LIST_DEFAULT_PAGE_SIZE
} from './dbImportsTable.types';

const initialState: DbImportsTableState = Object.freeze<DbImportsTableState>({
  params: {
    pageSize: DB_IMPORT_LIST_DEFAULT_PAGE_SIZE,
    currentPage: 1,
    sort: undefined,
    filter: undefined,
    searchText: undefined
  },
  singleView: 'OVERVIEW',
  activeKey: undefined
});

const dbImportsTableReducer = (
  state: DbImportsTableState = initialState,
  action: DbImportsTableActionCreators
): DbImportsTableState =>
  produce(state, draft => {
    switch (action.type) {
      case DbImportsTableActionTypes.SET_PARAMS:
        draft.params = merge(clone(draft.params), action.params);

        break;

      case DbImportsTableActionTypes.SET_SINGLE_VIEW:
        draft.singleView = action.view;

        break;

      case DbImportsTableActionTypes.RESET:
        draft.singleView = 'OVERVIEW';
        draft.params = {};

        break;

      case DbImportsTableActionTypes.SET_ACTIVE_KEY:
        draft.activeKey = action.key;

        break;

      default:
        break;
    }
  });

export default dbImportsTableReducer;
