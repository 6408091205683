import React from 'react';

import routes from '../routes/routes';

import { ViewConfig } from './view.types';

import CaptureStructurePage from 'components/domain/pages/captureStructurePage';
import CreateDomainPage from 'components/domain/pages/createDomainPage';
import CsvImportCreatePage from 'components/domain/pages/csvImportCreatePage';
import CsvImportListPage from 'components/domain/pages/csvImportListPage';
import CsvImportSinglePage from 'components/domain/pages/csvImportSinglePage';
import DataGeneratorPage from 'components/domain/pages/dataGeneratorPage';
import DbImportCreatePage from 'components/domain/pages/dbImportCreatePage';
import DbImportListPage from 'components/domain/pages/dbImportListPage';
import DbImportSinglePage from 'components/domain/pages/dbImportSinglePage';
import DocumentHistoryPage from 'components/domain/pages/documentHistoryPage';
import DomainAdministrationPage from 'components/domain/pages/domainAdministrationPage';
import DomainDiagramPage from 'components/domain/pages/domainDiagramPage';
import DomainOverviewPage from 'components/domain/pages/domainOverviewPage';
import DomainRedirectsPage from 'components/domain/pages/domainRedirectPage';
import DomainStatistic from 'components/domain/pages/domainStatisticPage';
import DomainUserPage from 'components/domain/pages/domainUserPage';
import EntityDocumentPage from 'components/domain/pages/entityDocumentPage';
import EntityDocumentResultListPage from 'components/domain/pages/entityDocumentResultListPage';
import EntityDocumentSinglePage from 'components/domain/pages/entityDocumentSinglePage';
import FtpConnectionCreatePage from 'components/domain/pages/ftpConnectionCreatePage';
import FtpConnectionSinglePage from 'components/domain/pages/ftpConnectionSinglePage';
import FtpConnectionsListPage from 'components/domain/pages/ftpConnectionsListPage';
import JsonImportExportPage from 'components/domain/pages/jsonImportExportPage';
import MetadataPage from 'components/domain/pages/metadataPage';
import SwaggerPage from 'components/domain/pages/swaggerPage';
import TriggerCreatePage from 'components/trigger/pages/triggerCreatePage';
import TriggersCalendarPage from 'components/trigger/pages/triggersCalendarPage';
import TriggerSinglePage from 'components/trigger/pages/triggerSinglePage';
import TriggersListPage from 'components/trigger/pages/triggersListPage';

const domainViewConfig: ViewConfig = {
  routes: [
    {
      path: routes.domain.create,
      exact: true,
      component: <CreateDomainPage />
    },
    {
      path: routes.domain.overview,
      exact: true,
      component: <DomainOverviewPage />
    },
    {
      path: routes.domain.dataManagement.domainAdministration,
      exact: true,
      component: <DomainAdministrationPage />
    },
    {
      path: routes.domain.dataManagement.importFromDb.list,
      exact: true,
      component: <DbImportListPage />
    },
    {
      path: routes.domain.dataManagement.importFromDb.single,
      exact: true,
      component: <DbImportSinglePage />
    },
    {
      path: routes.domain.dataManagement.importFromDb.create,
      exact: true,
      component: <DbImportCreatePage />
    },
    {
      path: routes.domain.dataManagement.importFromCsv.list,
      exact: true,
      component: <CsvImportListPage />
    },
    {
      path: routes.domain.dataManagement.importFromCsv.single,
      exact: true,
      component: <CsvImportSinglePage />
    },
    {
      path: routes.domain.dataManagement.importFromCsv.create,
      exact: true,
      component: <CsvImportCreatePage />
    },
    {
      path: routes.domain.dataManagement.testDataGenerator,
      exact: true,
      component: <DataGeneratorPage />
    },
    {
      path: routes.domain.dataManagement.jsonImportExport,
      exact: true,
      component: <JsonImportExportPage />
    },
    {
      path: routes.domain.dataDesigner.entityDocumentDiagram,
      exact: true,
      component: <DomainDiagramPage />
    },
    {
      path: routes.domain.dataDesigner.captureStructure,
      exact: true,
      component: <CaptureStructurePage />
    },
    {
      path: routes.domain.queryData.entityDocument.root,
      exact: true,
      component: <EntityDocumentPage />
    },
    {
      path: routes.domain.queryData.entityDocument.list,
      exact: true,
      component: <EntityDocumentResultListPage />
    },
    {
      path: routes.domain.queryData.entityDocument.rootEntity,
      exact: true,
      component: <EntityDocumentPage />
    },
    {
      path: routes.domain.queryData.entityDocument.single,
      exact: true,
      component: <EntityDocumentSinglePage />
    },
    {
      path: routes.domain.queryData.documentHistory,
      exact: true,
      component: <DocumentHistoryPage />
    },
    {
      path: routes.domain.queryData.metaData,
      exact: true,
      component: <MetadataPage mode="EDIT" />
    },
    {
      path: routes.domain.queryData.metaDataCreate,
      exact: true,
      component: <MetadataPage mode="CREATE" />
    },
    {
      path: routes.domain.queryData.metaDataEntity,
      exact: true,
      component: <MetadataPage mode="EDIT" />
    },
    {
      path: routes.domain.queryData.swagger,
      exact: true,
      component: <SwaggerPage />
    },
    {
      path: routes.domain.scheduler.list,
      exact: true,
      component: <TriggersListPage />
    },
    {
      path: routes.domain.scheduler.calendar,
      exact: true,
      component: <TriggersCalendarPage />
    },
    {
      path: routes.domain.scheduler.single,
      exact: true,
      component: <TriggerSinglePage />
    },
    {
      path: routes.domain.scheduler.create,
      exact: true,
      component: <TriggerCreatePage />
    },
    {
      path: routes.domain.connections.ftp.list,
      exact: true,
      component: <FtpConnectionsListPage />
    },

    {
      path: routes.domain.connections.ftp.create,
      exact: true,
      component: <FtpConnectionCreatePage />
    },
    {
      path: routes.domain.connections.ftp.single,
      exact: true,
      component: <FtpConnectionSinglePage />
    },
    {
      path: routes.domain.userPrivileges,
      exact: true,
      component: <DomainUserPage />
    },
    {
      path: routes.domain.statistics,
      exact: true,
      component: <DomainStatistic />
    },
    {
      path: routes.domain.dataManagement.root,
      exact: true,
      component: <DomainRedirectsPage route={routes.domain.dataManagement.domainAdministration} />
    },
    {
      path: routes.domain.dataDesigner.root,
      exact: true,
      component: <DomainRedirectsPage route={routes.domain.dataDesigner.entityDocumentDiagram} />
    },
    {
      path: routes.domain.queryData.root,
      exact: true,
      component: <DomainRedirectsPage route={routes.domain.queryData.entityDocument.root} />
    },
    {
      path: routes.domain.scheduler.root,
      exact: true,
      component: <DomainRedirectsPage route={routes.domain.scheduler.list} />
    },
    {
      path: routes.domain.connections.root,
      exact: true,
      component: <DomainRedirectsPage route={routes.domain.connections.ftp.list} />
    }
  ]
};

export default domainViewConfig;
