import React from 'react';

import { Trans } from '@lingui/macro';
import { useDispatch } from 'react-redux';

import Button from 'components/ui/button/button';
import { DocId } from 'services/api/domain/hint';
import { st_docs_openDrawer } from 'services/store/docs/docs.actions';

const ShowInfoAction: React.FC = () => {
  const dispatch = useDispatch();
  const handleShowInfo = () => {
    dispatch(st_docs_openDrawer(DocId.DOMAIN_DIAGRAM));
  };

  return (
    <Button icon={['fas', 'info-circle']} onClick={handleShowInfo}>
      <Trans>Info</Trans>
    </Button>
  );
};

export default ShowInfoAction;
