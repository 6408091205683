import {
  CheckFtPConnectionParams,
  CreateFtpConnectionParams,
  DeleteFtpConnectionParams,
  FTPConnection,
  GetFtpConnectionByNameParams,
  GetFtpConnectionsParams,
  UpdateFtpConnectionParams
} from './ftpConnection.types';

import apiClient from 'services/api/apiClient';

const BASE_PATH = 'ftp';

/**
 * GET /api/ftp/{domain}/{scheme}
 *
 */
export const api_getFtpConnections = async (
  params: GetFtpConnectionsParams
): Promise<FTPConnection[]> => {
  const { data } = await apiClient.get(`/${BASE_PATH}/${params.domainName}/${params.schemaName}`);

  return data;
};

/**
 * GET /api/ftp/{domain}/{scheme}/byName/{name}
 *
 */
export const api_getFtpConnectionByName = async (
  params: GetFtpConnectionByNameParams
): Promise<FTPConnection> => {
  const { data } = await apiClient.get(
    `/${BASE_PATH}/${params.domainName}/${params.schemaName}/byName/${params.ftpConnectionName}`
  );

  return data;
};

/**
 * POST /api/ftp/{domain}/{scheme}
 *
 */
export const api_createFtpConnection = async (
  params: CreateFtpConnectionParams
): Promise<FTPConnection> => {
  const { data } = await apiClient.post(
    `/${BASE_PATH}/${params.domainName}/${params.schemaName}`,
    params.payload
  );

  return data;
};

/**
 * PUT /api/ftp/{domain}/{scheme}
 *
 */
export const api_updateFtpConnection = async (
  params: UpdateFtpConnectionParams
): Promise<FTPConnection> => {
  const { data } = await apiClient.post(
    `/${BASE_PATH}/${params.domainName}/${params.schemaName}`,
    params.payload
  );

  return data;
};

/**
 * POST /api/csv/{domain}/{scheme}/checkConnection
 *
 */
export const api_checkFtpConnection = async (params: CheckFtPConnectionParams): Promise<void> => {
  const { data } = await apiClient.post(
    `/${BASE_PATH}/${params.domainName}/${params.schemaName}/checkConnection`,
    params.payload
  );

  return data;
};

/**
 * DELETE /api/ftp/{domain}/{scheme}/{id}
 *
 */
export const api_deleteFtpConnection = async (params: DeleteFtpConnectionParams): Promise<void> => {
  const { data } = await apiClient.delete(
    `/${BASE_PATH}/${params.domainName}/${params.schemaName}/${params.id}`
  );

  return data;
};
