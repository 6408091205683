import React, { useEffect, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import {
  DateTimePicker,
  FormItem,
  useFormActions,
  useFormContext,
  DateTimeTranferObject
} from '@phoenix-systems/react-form';
import { getRoute } from '@phoenix-systems/react-router';
import { Divider, Form, Input, InputNumber, Select } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { RuleObject } from 'antd/lib/form';
import moment from 'moment';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useImmer } from 'use-immer';

import * as F from 'components/_styled/formSc';
import useContainer from 'components/app/components/containerProvider/useContainer';
import DocsTooltip from 'components/docs/components/docsTooltip';
import TooltipLabel from 'components/docs/components/docsTooltip/tooltipLabel';
import Button from 'components/ui/button';
import FormContainer from 'components/ui/formContainer';
import Loader from 'components/ui/loader';
import dateFormat from 'config/dateTime';
import routes from 'config/routes/routes';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';
import useNotification from 'hooks/useNotification';
import { Domain, useDomains } from 'services/api/domain/domain';
import { DocId } from 'services/api/domain/hint';
import {
  api_createTrigger,
  api_updateTrigger,
  Trigger,
  useTriggers
} from 'services/api/domain/trigger';
import history from 'services/history';
import {
  st_triggersTable_setParams,
  st_triggersTable_setSelectedKey
} from 'services/store/triggersTable/triggersTable.actions';

const LABEL_WIDTH = 140;
const { Option } = Select;

type TriggerFormProps = {
  isDomain?: boolean;
  data?: Trigger | Partial<Trigger>;
  isTaskReadonly?: boolean;
  isDialog?: boolean;
  mode: 'CREATE' | 'EDIT';
};

type TriggerFormState = {
  taskDomain?: Domain;
  isInitialized: boolean;
  existingTriggers: Trigger[];
  isTaskChanged: boolean;
  triggerName?: string;
};

const TriggerForm: React.FC<TriggerFormProps> = ({
  isDomain,
  data,
  isTaskReadonly,
  isDialog,
  mode
}) => {
  const formActions = useFormActions();
  const { current: formActionsRef } = useRef(formActions);
  const domainsQuery = useDomains();
  const { formContainer } = useContainer();
  const [form] = Form.useForm<Trigger>();
  const [addNotification] = useNotification();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [formContextState, formContextActions] = useFormContext(form, data);
  const goto = useGoToDomainRoute();
  const copy = useCopyToClipboard();

  const [state, setState] = useImmer<TriggerFormState>({
    isInitialized: false,
    existingTriggers: [],
    isTaskChanged: false
  });

  const triggersQuery = useTriggers(
    {},
    {
      onSuccess: triggersData => {
        setState(draft => {
          draft.existingTriggers = triggersData;
        });
      }
    }
  );
  const { current: triggersQueryRef } = useRef(triggersQuery);

  const createMutation = useMutation((params: Partial<Trigger>) => api_createTrigger(params), {
    onSuccess: resData => {
      addNotification({
        type: 'success',
        message: i18n._(t`Successfully created trigger "${resData.name}".`)
      });

      dispatch(st_triggersTable_setParams(undefined));
      dispatch(st_triggersTable_setSelectedKey(resData.name));

      if (isDomain) {
        goto(routes.domain.scheduler.list);
      } else {
        history.push(getRoute(routes.scheduler.list));
      }
    },
    onError: () => {
      addNotification({
        type: 'error',
        message: i18n._(t`Failed to create trigger "${state.triggerName}".`)
      });
    }
  });

  const updateMutation = useMutation((params: Trigger) => api_updateTrigger(params), {
    onSuccess: resData => {
      addNotification({
        type: 'success',
        message: i18n._(t`Successfully updated trigger "${resData.name}".`)
      });

      dispatch(st_triggersTable_setSelectedKey(resData.name));

      if (state.triggerName !== resData.name) {
        if (isDomain) {
          goto(routes.domain.scheduler.single, { path: resData.name });
        } else {
          history.push(getRoute(routes.scheduler.single, resData.name));
        }
      } else {
        queryClient.refetchQueries('trigger');
        updateMutation.reset();
        formContextActions.reset(resData);
      }
    },
    onError: () => {
      addNotification({
        type: 'error',
        message: i18n._(t`Failed to update trigger "${state.triggerName}".`)
      });
    }
  });

  const handleValuesChange = (changedValues: Partial<Trigger>, values: unknown) => {
    if (
      changedValues.taskDomain &&
      domainsQuery.data?.filter(d => d.domainName === changedValues.taskDomain)
    ) {
      const currentDomain = domainsQuery.data?.find(d => d.domainName === changedValues.taskDomain);
      setState(draft => {
        draft.taskDomain = currentDomain;
      });
    }

    if (data?.id) {
      const val = values as Partial<Trigger>;
      let isTaskChanged = false;

      if (
        val.taskDomain !== data.taskDomain ||
        val.taskName !== data.taskName ||
        val.taskScheme !== data.taskScheme ||
        val.taskType !== data.taskType
      ) {
        isTaskChanged = true;
      }

      setState(draft => {
        draft.isTaskChanged = isTaskChanged;
      });
    }

    if (isDialog) {
      formActions.onValuesChange(changedValues, values);
    } else {
      formContextActions.onValuesChange(changedValues, values);
    }
  };

  const validateSchemas = (rule: RuleObject, value: string) =>
    new Promise((resolve, reject) => {
      if (!state.taskDomain) {
        resolve(value);
      }

      if (!state.taskDomain?.schemes.includes(value)) {
        reject(new Error(`schema "${value}" is not known in task domain`));
      }

      resolve(value);
    });

  const validateName = (rule: RuleObject, value: string) =>
    new Promise((resolve, reject) => {
      if (
        state.existingTriggers?.find(trigger => trigger.name === value && trigger.id !== data?.id)
      ) {
        reject(new Error(`Name "${value}" is already in use in different task.`));
      }

      resolve(value);
    });

  const validateTaskName = (rule: RuleObject, value: string) =>
    new Promise((resolve, reject) => {
      if (
        state.existingTriggers?.find(trigger => trigger.name === value && trigger.id !== data?.id)
      ) {
        reject(new Error(`TaskName "${value}" is already in use in different task.`));
      }

      resolve(value);
    });

  const handleSave = () => {
    form.validateFields().then(formData => {
      setState(draft => {
        draft.triggerName = formData.name;
      });

      if (mode === 'CREATE') {
        createMutation.mutate(formData);
      }

      if (mode === 'EDIT') {
        updateMutation.mutate(formData);
      }
    });
  };

  const handleReset = () => {
    form.setFieldsValue({ ...data });
    formContextActions.reset(data);
  };

  useEffect(() => {
    if (form && !state.isInitialized) {
      if (isDialog) {
        formActionsRef.setFormInstance(form);
        formActionsRef.setInitialData(data);
      }

      setState(draft => {
        draft.isInitialized = true;
      });
    }
  }, [data, isDialog, form, formActionsRef, state.isInitialized, setState]);

  useEffect(() => {
    triggersQueryRef.refetch();
  }, [state.taskDomain, triggersQueryRef]);

  useEffect(() => {
    if (domainsQuery.data && data?.taskDomain) {
      const foundDomain = domainsQuery.data.find(d => d.domainName === data.taskDomain);

      if (foundDomain) {
        setState(draft => {
          draft.taskDomain = foundDomain;
        });
      }
    }
  }, [data?.taskDomain, domainsQuery.data, setState]);

  if (triggersQuery.isLoading) {
    return <Loader fullHeight />;
  }

  const formFields = (
    <F.StyledForm
      form={form}
      name="trigger-form"
      onValuesChange={handleValuesChange}
      onFieldsChange={isDialog ? formActions.onFieldsChange : formContextActions.onFieldsChange}
      initialValues={data}
      $labelWidth={LABEL_WIDTH}
      colon={false}
    >
      {data?.id && (
        <FormItem
          label={<TooltipLabel docId={DocId.SCHEDULER_TRIGGER_ID} label={<Trans>Id</Trans>} />}
          messageVariables={{ name: i18n._(t`Id`) }}
          name="id"
          rules={[{ required: true }]}
          registerField={isDialog ? undefined : formContextActions.registerField}
        >
          <Input
            readOnly
            addonAfter={<Button icon={['fas', 'copy']} onClick={() => copy(data.id)} />}
          />
        </FormItem>
      )}
      <FormItem
        label={<TooltipLabel docId={DocId.SCHEDULER_TRIGGER_NAME} label={<Trans>Name</Trans>} />}
        messageVariables={{ name: i18n._(t`Name`) }}
        name="name"
        validateFirst
        rules={[
          { required: true },
          {
            pattern: /^[A-Za-z0-9\-_]+$/,
            message: i18n._("Only allowed alphanumeric characters including '-' and '_'.")
          },
          {
            validator: validateName,
            message: i18n._('"Name" already exists. "Name" needs to be globally unique.')
          }
        ]}
        registerField={isDialog ? undefined : formContextActions.registerField}
      >
        <Input />
      </FormItem>
      <FormItem
        label={
          <TooltipLabel docId={DocId.SCHEDULER_TRIGGER_ACTIVE} label={<Trans>Active</Trans>} />
        }
        messageVariables={{ name: i18n._(t`Active`) }}
        name="active"
        valuePropName="checked"
        registerField={isDialog ? undefined : formContextActions.registerField}
      >
        <Checkbox defaultChecked />
      </FormItem>
      <Divider />

      <F.Title>
        <FontAwesomeIcon icon={['far', 'clipboard-check']} />
        <Trans>Task</Trans>
      </F.Title>
      <FormItem
        label={
          <TooltipLabel
            docId={DocId.SCHEDULER_TRIGGER_TASK_DOMAIN}
            label={<Trans>Task domain</Trans>}
          />
        }
        messageVariables={{ name: i18n._(t`Task domain`) }}
        name="taskDomain"
        rules={[{ required: true }]}
        registerField={isDialog ? undefined : formContextActions.registerField}
      >
        <Select
          getPopupContainer={() => formContainer}
          open={isDomain || isTaskReadonly ? false : undefined}
        >
          {domainsQuery.data?.map(d => (
            <Option value={d.domainName} key={d.domainName}>
              {d.domainName}
            </Option>
          ))}
        </Select>
      </FormItem>
      <FormItem
        label={
          <TooltipLabel
            docId={DocId.SCHEDULER_TRIGGER_TASK_SCHEME}
            label={<Trans>Task scheme</Trans>}
          />
        }
        messageVariables={{ name: i18n._(t`Task scheme`) }}
        name="taskScheme"
        validateFirst
        rules={[
          { required: true },
          {
            validator: validateSchemas,
            message: i18n._('Unknown schema in task domain.')
          }
        ]}
        registerField={isDialog ? undefined : formContextActions.registerField}
      >
        <Select getPopupContainer={() => formContainer} open={isTaskReadonly ? false : undefined}>
          {state.taskDomain?.schemes.map(scheme => (
            <Option value={scheme} key={scheme}>
              {scheme}
            </Option>
          ))}
        </Select>
      </FormItem>
      <FormItem
        label={
          <TooltipLabel
            docId={DocId.SCHEDULER_TRIGGER_TASK_NAME}
            label={<Trans>Task name</Trans>}
          />
        }
        messageVariables={{ name: i18n._(t`Task name`) }}
        name="taskName"
        validateFirst
        rules={[
          { required: true },
          {
            pattern: /^[A-Za-z0-9\-_]+$/,
            message: i18n._("Only allowed alphanumeric characters including '-' and '_'.")
          },
          {
            validator: validateTaskName,
            message: i18n._('"Task name" already exists. "Task name" needs to be globally unique.')
          }
        ]}
        registerField={isDialog ? undefined : formContextActions.registerField}
      >
        <Input readOnly={isTaskReadonly} />
      </FormItem>
      <FormItem
        label={
          <TooltipLabel
            docId={DocId.SCHEDULER_TRIGGER_TASK_TYPE}
            label={<Trans>Task type</Trans>}
          />
        }
        messageVariables={{ name: i18n._(t`Task type`) }}
        name="taskType"
        rules={[{ required: true }]}
      >
        <Select
          placeholder={<Trans>Select task type</Trans>}
          getPopupContainer={() => formContainer}
          open={isTaskReadonly ? false : undefined}
        >
          <Option key="none" value="NONE">
            <Trans>None</Trans>
          </Option>
          <Option key="SQLDBImport" value="SQLDBImport">
            <Trans>SQL db import</Trans>
          </Option>
        </Select>
      </FormItem>

      {state.isTaskChanged && (
        <F.FormAlert
          type="info"
          showIcon
          message={
            <Trans>
              Please note: changing the task properties disconnects potentially the trigger form the
              connected job.
            </Trans>
          }
          left={LABEL_WIDTH}
        />
      )}
      <Divider />

      <F.Title>
        <FontAwesomeIcon icon={['far', 'clock']} />
        <Trans>Time schedule</Trans>
      </F.Title>

      <FormItem
        name="startTime"
        label={
          <TooltipLabel
            docId={DocId.SCHEDULER_TRIGGER_START_TIME}
            label={<Trans>Start time</Trans>}
          />
        }
        registerField={isDialog ? undefined : formContextActions.registerField}
      >
        <DateTimePicker
          mode="DATE_TIME"
          getPopupContainer={() => formContainer}
          parseValue={val => {
            const parts = val.split(' ');

            return {
              date: parts[0] ? moment(parts[0], 'YYYY-MM-DD') : undefined,
              time: parts[1] ? moment(parts[1], 'HH:mm:ss') : undefined
            };
          }}
          parseMoment={(params: DateTimeTranferObject) => {
            if (!params.date) {
              return undefined;
            }

            return `${params.date.format('YYYY-MM-DD')} ${
              params.time ? params.time.format(dateFormat.time) : ''
            }`;
          }}
          datePickerProps={{
            format: 'DD.MM.YYYY',
            placeholder: i18n._('Enter date (DD.MM.YYYY)')
          }}
          timePickerProps={{
            format: 'HH:mm:ss',
            placeholder: i18n._('Enter time (HH:mm:ss)')
          }}
        />
      </FormItem>

      <FormItem
        label={
          <TooltipLabel
            docId={DocId.SCHEDULER_TRIGGER_PERIODICITY}
            label={<Trans>Periodicity</Trans>}
          />
        }
        rules={[{ required: true }]}
        messageVariables={{ name: i18n._(t`Periodicity`) }}
        name="periodicity"
        registerField={isDialog ? undefined : formContextActions.registerField}
      >
        <Select
          placeholder={<Trans>Select periodicity</Trans>}
          getPopupContainer={() => formContainer}
          allowClear
        >
          <Option key="none" value="NONE">
            <Trans>None</Trans>
          </Option>
          <Option key="monthly" value="MONTHLY">
            <Trans>Monthly</Trans>
          </Option>
          <Option key="weekly" value="WEEKLY">
            <Trans>Weekly</Trans>
          </Option>
          <Option key="daily" value="DAILY">
            <Trans>Daily</Trans>
          </Option>
          <Option key="hourly" value="HOURLY">
            <Trans>Hourly</Trans>
          </Option>
          <Option key="every-minute" value="EVERY_MINUTE">
            <Trans>Every minute</Trans>
          </Option>
          <Option key="every-second" value="EVERY_SECOND">
            <Trans>Every second</Trans>
          </Option>
        </Select>
      </FormItem>
      <FormItem
        label={
          <TooltipLabel
            docId={DocId.SCHEDULER_TRIGGER_TIME_VALUE}
            label={<Trans>Time value</Trans>}
          />
        }
        messageVariables={{ name: i18n._(t`Time value`) }}
        name="timeValue"
        registerField={isDialog ? undefined : formContextActions.registerField}
      >
        <InputNumber min={0} step={1} />
      </FormItem>
    </F.StyledForm>
  );

  if (isDialog) {
    return formFields;
  }

  return (
    <FormContainer
      title={
        <>
          <FontAwesomeIcon icon={['fas', 'clock']} />
          {mode === 'CREATE' ? (
            <Trans>Create trigger</Trans>
          ) : (
            <Trans>Edit trigger {data?.name}</Trans>
          )}
          <DocsTooltip docId={DocId.SCHEDULER_TRIGGER} />
        </>
      }
      buttons={
        <>
          <Button
            action="reset"
            disabled={
              !formContextState.hasChanged || createMutation.isLoading || updateMutation.isLoading
            }
            onClick={handleReset}
          />
          <Button
            action="save"
            onClick={handleSave}
            loading={createMutation.isLoading || updateMutation.isLoading}
            disabled={!formContextState.hasChanged || !formContextState.isValid}
          />
        </>
      }
    >
      {formFields}
    </FormContainer>
  );
};

export default TriggerForm;
