import { Select } from 'antd';
import tw, { styled, css } from 'twin.macro';

type SelectorContainerProps = {
  collapsed?: boolean;
};
export const SelectorContainer = styled.div<SelectorContainerProps>(({ collapsed }) => [
  !collapsed
    ? css`
        ${tw`relative m-12 h-32`};
        width: calc(100% - 24px);
      `
    : css`
        ${tw`mx-0 `};
      `
]);

export const SelectInput = styled(Select)(() => [
  css`
    &.ant-select {
      width: 100% !important;

      & .ant-select-selector {
        ${tw` bg-primary h-32`};
      }

      & .ant-select-arrow {
        ${tw` text-white`};
      }

      & .ant-select-selection-item {
        ${tw`font-600 text-white`};
        line-height: 32px;
      }
    }
  `
]);

export const StyledDsLoader = styled.div(() => [
  css`
    ${tw`flex flex-row items-center justify-center h-36`};
  `
]);
