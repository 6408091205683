import tw, { styled, css } from 'twin.macro';

export const SplashScreenContainer = styled.div(() => [
  css`
    ${tw`h-screen w-screen overflow-hidden flex justify-center items-center`}
    background: url('/img/bg.jpeg');
    background-size: cover;
  `
]);

export const SplashScreenCenter = styled.div(() => [
  css`
    ${tw`p-20`};
    max-width: 400px;
  `
]);

export const SplashScreenLogo = styled.img(() => [
  css`
    ${tw`w-full h-auto`};
  `
]);
