import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuthActions } from '@phoenix-systems/react-keycloak-auth';
import { Navigation } from '@phoenix-systems/react-navigation';

import * as S from './userNavigationSc';

import { getUserNavigationConfig } from 'config/navigation';
import routes from 'config/routes/routes';
import { useMyself } from 'services/api/domain/user';
import history from 'services/history';

const UserNavigation: React.FC = () => {
  const { logout } = useAuthActions();
  const myselfQuery = useMyself({ enabled: false });

  const handleLogout = () => {
    logout({
      onSuccess: () => {
        history.push(routes.login.root);
      }
    });
  };

  return (
    <>
      {myselfQuery.data && (
        <S.UserInfo>
          <div>
            <FontAwesomeIcon icon={['fas', 'user-circle']} />
            <div>{myselfQuery.data.username}</div>
          </div>
          {myselfQuery.data.orgUnits.length && (
            <div className="org-unit">
              <FontAwesomeIcon icon={['fas', 'building']} />
              <div>
                {myselfQuery.data.orgUnits.map(orgUnit => (
                  <>
                    {orgUnit}
                    <br />
                  </>
                ))}
              </div>
            </div>
          )}
        </S.UserInfo>
      )}
      <Navigation
        navigationId="user"
        config={getUserNavigationConfig(handleLogout)}
        mode="inline"
        // selectedKeys={activeKey ? [activeKey] : []}
      />
    </>
  );
};

export default UserNavigation;
