import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Menu } from 'antd';
import { omit } from 'lodash';

import DeleteOrgUnitDialog from './deleteOrgUnitDialog';

import Button from 'components/ui/button';
import { OrgUnit } from 'services/api/domain/orgUnit';

const { Item } = Menu;

type DeleteOrgUnitActionProps = {
  data: OrgUnit;
  mode: 'BUTTON' | 'MENU_ITEM';
};

const DeleteOrgUnitAction: React.FC<DeleteOrgUnitActionProps> = props => {
  const antProps = omit(props, ['data', 'mode']);
  const { data, mode } = props;
  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleDeleteRequest = () => {
    setDialogOpen(true);
  };

  return (
    <>
      {mode === 'MENU_ITEM' && (
        <Item
          {...antProps}
          key={`delete-trigger_${data.id}`}
          icon={<FontAwesomeIcon icon={['fas', 'trash']} />}
          onClick={handleDeleteRequest}
          className="delete"
        >
          <Trans>Delete organisation</Trans>
        </Item>
      )}
      {mode === 'BUTTON' && (
        <Button action="delete" onClick={handleDeleteRequest}>
          <Trans>Delete Organisation</Trans>
        </Button>
      )}

      <DeleteOrgUnitDialog isOpen={isDialogOpen} data={data} close={() => setDialogOpen(false)} />
    </>
  );
};

export default DeleteOrgUnitAction;
