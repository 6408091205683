import { createSelector } from 'reselect';

import { DocsState, EditorMode } from './docs.types';

import { DocId } from 'services/api/domain/hint';

export const docsState = (state: { docs: DocsState }): DocsState => state.docs;

export const st_docs_getDrawerCurrentId = createSelector(
  [docsState],
  (docs): DocId | undefined => docs.drawerCurrentId
);

export const st_docs_getDrawerHistoryIndex = createSelector(
  [docsState],
  (docs): number => docs.drawerHistoryIndex
);

export const st_docs_getDrawerHistory = createSelector(
  [docsState],
  (docs): DocId[] => docs.drawerHistory
);

export const st_docs_getIsDrawerOpen = createSelector(
  [docsState],
  (docs): boolean => docs.isDrawerOpen
);

export const st_docs_getIsBackEnabled = createSelector(
  [docsState],
  (docs): boolean => docs.isBackEnabled
);

export const st_docs_getIsForwardEnabled = createSelector(
  [docsState],
  (docs): boolean => docs.isForwardEnabled
);

export const st_docs_getEditorMode = createSelector(
  [docsState],
  (docs): EditorMode => docs.editorMode
);
