import { clone, omit } from 'lodash';

import {
  CreateAndUpdateDbImport,
  DbImport,
  DbImportLog,
  DeleteDbImportParams,
  GetDBDRiverParams,
  GetDBImportByIdParams,
  GetDBImportByNameParams,
  GetDBImportResultsParams,
  GetDBImportsParams,
  ImportFromDbParams,
  StartDbImportParams,
  TestDBConnectionParams
} from './dataImport.types';

import apiClient from 'services/api/apiClient';

const BASE_PATH = 'dataimport';

/**
 * GET /api/dataimport/{domain}/{scheme}
 */
export const api_getImportsFromDb = async (params: GetDBImportsParams): Promise<DbImport[]> => {
  const res = await apiClient.get(`${BASE_PATH}/${params.domainName}/${params.schema}`);

  return res.data;
};

/**
 * GET /api/dataimport/{domain}/{scheme}/{id}
 */
export const api_getImportFromDbById = async (params: GetDBImportByIdParams): Promise<DbImport> => {
  const res = await apiClient.get(
    `${BASE_PATH}/${params.domainName}/${params.schema}/${params.id}`
  );

  return res.data;
};

/**
 * GET /api/dataimport/{domain}/{scheme}/byName/{name}
 */
export const api_getImportFromDbByName = async (
  params: GetDBImportByNameParams
): Promise<DbImport> => {
  const res = await apiClient.get(
    `${BASE_PATH}/${params.domainName}/${params.schema}/byName/${params.dbImportName}`
  );

  return res.data;
};

/**
 * POST /api/dataimport/{domain}/{scheme}
 */
export const api_createImportFromDb = async (
  params: CreateAndUpdateDbImport
): Promise<DbImport> => {
  const res = await apiClient.post(
    `${BASE_PATH}/${params.domainName}/${params.schema}`,
    params.payload
  );

  return res.data;
};

/**
 * PUT /api/dataimport/{domain}/{scheme}
 */
export const api_updateImportFromDb = async (
  params: CreateAndUpdateDbImport
): Promise<DbImport> => {
  const res = await apiClient.put(
    `${BASE_PATH}/${params.domainName}/${params.schema}`,
    params.payload
  );

  return res.data;
};

/**
 * GET /api/dataimport/{domain}/{scheme}/driver
 */
export const api_getDBDriver = async (params: GetDBDRiverParams): Promise<string[]> => {
  const res = await apiClient.get(`${BASE_PATH}/${params.domainName}/${params.schema}/driver`);

  return res.data;
};

/**
 * POST /api/dataimport/checkConnection
 */
export const api_testDBConnection = async (params: TestDBConnectionParams): Promise<void> => {
  const res = await apiClient.post(`${BASE_PATH}/checkConnection`, params);

  return res.data;
};

/**
 * DELETE /api/dataimport/{domain}/{scheme}/{id}
 */
export const api_deleteImportFromDb = async (params: DeleteDbImportParams): Promise<void> => {
  const res = await apiClient.delete(
    `${BASE_PATH}/${params.domainName}/${params.schema}/${params.id}`
  );

  return res.data;
};

/**
 * GET api/dataimport/{domain}/{scheme}/results/{dbImportName}/{rows}
 */
export const api_getImportFromDbResults = async (
  params: GetDBImportResultsParams
): Promise<DbImportLog[]> => {
  const res = await apiClient.get(
    `${BASE_PATH}/${params.domainName}/${params.schema}/results/${params.dbImportName}/${params.rows}`
  );

  return res.data;
};

/**
 * GET /api/dataimport/{domain}/{scheme}/start/{name}
 */
export const api_startImportFromDb = async (params: StartDbImportParams): Promise<void> => {
  const res = await apiClient.get(
    `${BASE_PATH}/${params.domainName}/${params.schema}/start/${params.dbImportName}`
  );

  return res.data;
};

/**
 *
 * -------------- OUTDATED ---------------------
 */
export const api_importFromDb = async (payload: ImportFromDbParams): Promise<string> => {
  const queryParams = omit(clone(payload), ['domainName', 'schema', 'dataImportInfo']);
  const res = await apiClient.post(
    `${BASE_PATH}/${payload.domainName}/${payload.schema}`,
    payload.dataImportInfo,
    {
      params: { ...queryParams }
    }
  );

  return res.data;
};
