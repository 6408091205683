import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { getRoute } from '@phoenix-systems/react-router';
import { Modal } from 'antd';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import Button from 'components/ui/button';
import routes from 'config/routes/routes';
import useDefaultModalProps from 'hooks/useDefaultModalProps';
import useNotification from 'hooks/useNotification';
import { api_deleteUser, User } from 'services/api/domain/user';
import history from 'services/history';

type DeleteUserDialogProps = {
  isOpen: boolean;
  data: User;
  close: () => void;
};

const DeleteUserDialog: React.FC<DeleteUserDialogProps> = ({ data, isOpen, close }) => {
  const { username } = useParams<{ username: string }>();
  const queryClient = useQueryClient();
  const [addNotification] = useNotification();
  const modalProps = useDefaultModalProps();

  const deleteMutation = useMutation(() => api_deleteUser(data.username), {
    onSuccess: () => {
      addNotification({
        type: 'success',
        message: i18n._(t`Successfully deleted user "${data.username}".`)
      });

      queryClient.refetchQueries(['users']);

      if (username) {
        history.push(getRoute(routes.userAdmin.user.list));
      }
      close();
    },
    onError: () => {
      addNotification({
        type: 'error',
        message: i18n._(t`Failed to delete user "${data.username}".`)
      });
    }
  });

  const handleDelete = () => {
    deleteMutation.mutate();
  };

  return (
    <Modal
      {...modalProps}
      visible={isOpen}
      width={650}
      title={
        <>
          <FontAwesomeIcon icon={['fas', 'trash']} />
          <Trans>Delete User {data.username}</Trans>
        </>
      }
      footer={
        <>
          <Button action="cancel" disabled={deleteMutation.isLoading} onClick={() => close()} />
          <Button action="delete" mutation={deleteMutation} onClick={handleDelete}>
            <Trans>Delete user</Trans>
          </Button>
        </>
      }
    >
      <p>
        Do you want to delete the user <b>{data.username}</b>?
      </p>
    </Modal>
  );
};

export default DeleteUserDialog;
