import { ListQueryParams } from '@phoenix-systems/react-table/dist/types/types';
import { createSelector } from 'reselect';

import {
  FtpConnectionsTableSingleView,
  FtpConnectionsTableState
} from './ftpConnectionsTable.types';

export const ftpConnectionsTableState = (state: {
  ftpConnectionsTable: FtpConnectionsTableState;
}): FtpConnectionsTableState => state.ftpConnectionsTable;

export const st_ftpConnectionsTable_getParams = createSelector(
  [ftpConnectionsTableState],
  (ftpConnectionsTable): ListQueryParams => ftpConnectionsTable.params
);

export const st_ftpConnectionsTable_getSingleView = createSelector(
  [ftpConnectionsTableState],
  (ftpConnectionsTable): FtpConnectionsTableSingleView => ftpConnectionsTable.singleView
);

export const st_ftpConnectionsTable_getSelectedKey = createSelector(
  [ftpConnectionsTableState],
  (ftpConnectionsTable): string | undefined => ftpConnectionsTable.selectedKey
);
