import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Menu } from 'antd';
import { omit } from 'lodash';
import { useDispatch } from 'react-redux';

import Button from 'components/ui/button';
import { ButtonProps } from 'components/ui/button/button.types';
import routes from 'config/routes/routes';
import history from 'services/history';
import { st_domainDiagram_setContextNavOpen } from 'services/store/domainDiagram/domainDiagram.actions';

const { Item } = Menu;

type CreateDomainActionProps = {
  onSelect?: () => void;
  mode: 'MENU_ITEM' | 'BUTTON';
  buttonProps?: ButtonProps;
};

const CreateDomainAction: React.FC<CreateDomainActionProps> = props => {
  const antProps = omit(props, ['mode', 'buttonProps', 'onSelect']);
  const { mode, buttonProps, onSelect } = props;
  const dispatch = useDispatch();

  const handleCreateDomainRequest = () => {
    dispatch(st_domainDiagram_setContextNavOpen(false));

    history.push(routes.domain.create);

    if (onSelect) {
      onSelect();
    }
  };

  return (
    <>
      {mode === 'MENU_ITEM' && (
        <Item
          {...antProps}
          key="create-domain"
          icon={<FontAwesomeIcon icon={['fas', 'plus-circle']} />}
          onClick={handleCreateDomainRequest}
        >
          <Trans>Delete domain</Trans>
        </Item>
      )}
      {mode === 'BUTTON' && (
        <Button action="create" {...buttonProps} onClick={handleCreateDomainRequest}>
          <Trans>Create domain</Trans>
        </Button>
      )}
    </>
  );
};

export default CreateDomainAction;
