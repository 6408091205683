import tw, { css, styled } from 'twin.macro';

export const FieldSelectorContainer = styled.div(() => [
  css`
    & .ant-select-item-option-content {
      ${tw`font-700`};

      mark {
        ${tw`bg-yellow-200 mx-0 px-0`};
      }
    }
  `
]);

export const ObjectRelation = styled.div(() => [
  css`
    ${tw`rounded border border-ant-border-gray bg-light-gray p-12 mb-12`};

    & h4 {
      ${tw`text-gray-500 font-700 uppercase text-13 tracking-wide m-0	mt-4 mb-20`};
    }
  `
]);
