import React, { useEffect, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  BurgerMenuButton,
  Desktop,
  Mobile,
  useIsMobile,
  useLayoutActions,
  useLayoutConfig
} from '@phoenix-systems/react-layout';
import { Popover } from 'antd';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { useImmer } from 'use-immer';

import * as S from './headerSc';

import LogoImg from 'assets/images/logo-only.png';
import LogoImgText from 'assets/images/logo.png';
import useContainer from 'components/app/components/containerProvider/useContainer';
import LanguageNavigation from 'components/app/components/languageNavigation';
import TopNavigation from 'components/app/components/topNavigation';
import UserNavigation from 'components/app/components/userNavigation';
import useDsVersion from 'hooks/useDsVersion';
import { useMyself } from 'services/api/domain/user';
import { st_app_getLanguage } from 'services/store/app/app.selectors';

type HeaderState = {
  isLangNavOpen: boolean;
  isUserNavOpen: boolean;
  noAnimation: boolean;
  userName?: string;
};

const Header: React.FC = () => {
  const [state, setState] = useImmer<HeaderState>({
    isLangNavOpen: false,
    isUserNavOpen: false,
    noAnimation: true
  });
  const { headerContainer } = useContainer();
  const layoutConfig = useLayoutConfig();
  const lang = useSelector(st_app_getLanguage);
  const { pathname } = useLocation();
  const isMobile = useIsMobile();
  const { toggleMobileNavigation } = useLayoutActions();
  const version = useDsVersion();
  const name = `Big Data Service - ${version}`;
  const timeOut = useRef<ReturnType<typeof setTimeout>>();
  const myselfQuery = useMyself({ enabled: false });

  useEffect(() => {
    setState(draft => {
      draft.isLangNavOpen = false;
      draft.isUserNavOpen = false;
    });
  }, [lang, isMobile, pathname, setState]);

  useEffect(() => {
    if (layoutConfig.sidebarLeft) {
      const t = setTimeout(() => {
        setState(draft => {
          draft.noAnimation = false;
        });
      }, 1000);

      timeOut.current = t;
    } else {
      setState(draft => {
        draft.noAnimation = true;
      });
    }
  }, [layoutConfig.sidebarLeft, setState]);

  useEffect(
    () => () => {
      if (timeOut.current) {
        clearTimeout(timeOut.current);
      }
    },
    [timeOut]
  );

  if (!layoutConfig.sidebarLeft) {
    return null;
  }

  return (
    <S.HeaderContainer>
      <Desktop>
        <S.LogoContainer
          noAnimation={state.noAnimation}
          width={layoutConfig.sidebarLeft.width}
          collapsedWidth={layoutConfig.sidebarLeft.collapsedWidth}
          isCollapsed={layoutConfig.sidebarLeft.isCollapsed || false}
        >
          <NavLink className="inline-block" to="/">
            <div>
              <img src={LogoImgText} alt={name} className="logo-text" />
              <img src={LogoImg} alt={name} className="logo-only" />
              <p className="version">{name}</p>
            </div>
          </NavLink>
        </S.LogoContainer>
        <S.HeaderLeftNavContainer>
          <TopNavigation />
        </S.HeaderLeftNavContainer>
      </Desktop>
      <Mobile>
        <S.MobileLogo>
          <NavLink className="inline-block" to="/">
            <div>
              <img src={LogoImgText} alt={name} className="logo-text" />
              <p className="version">{name}</p>
            </div>
          </NavLink>
        </S.MobileLogo>
      </Mobile>

      {/*      <S.HeaderLeft>
        <NavLink className="inline-block" to="/">
          <Logo maxWidth={isMobile ? 200 : 300} />
        </NavLink>
        <Desktop>
          <S.HeaderLeftNavContainer>
            <TopNavigation />
          </S.HeaderLeftNavContainer>
        </Desktop>
      </S.HeaderLeft> */}
      <S.HeaderRight>
        <Desktop>
          <Popover
            content={<LanguageNavigation />}
            getPopupContainer={() => headerContainer}
            placement="bottomRight"
            trigger="click"
            visible={state.isLangNavOpen}
            onVisibleChange={open => {
              setState(draft => {
                draft.isLangNavOpen = open;
              });
            }}
          >
            <S.HeaderButton icon={<FontAwesomeIcon icon={['fas', 'globe']} type="link" />}>
              <span>{lang?.toUpperCase()}</span>
            </S.HeaderButton>
          </Popover>
          {myselfQuery.data && (
            <Popover
              content={<UserNavigation />}
              getPopupContainer={() => headerContainer}
              placement="bottomRight"
              trigger="click"
              visible={state.isUserNavOpen}
              onVisibleChange={open => {
                setState(draft => {
                  draft.isUserNavOpen = open;
                });
              }}
            >
              <S.HeaderButton icon={<FontAwesomeIcon icon={['fas', 'user']} type="link" />}>
                {myselfQuery.data.username}
              </S.HeaderButton>
            </Popover>
          )}
        </Desktop>
        <Mobile>
          <BurgerMenuButton
            color="#FFFFFF"
            duration={150}
            open={!layoutConfig.mobileNavigation?.isCollapsed}
            width={36}
            onClick={toggleMobileNavigation}
          />
        </Mobile>
      </S.HeaderRight>
    </S.HeaderContainer>
  );
};
export default Header;
