const getPrettyFileSize = (_bytes: number, _si?: boolean): string => {
  const hasSi = _si || true;
  const thresh = hasSi ? 1000 : 1024;
  let bytes = _bytes;

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }

  const units = hasSi
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  do {
    bytes /= thresh;
    u += 1;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);

  return `${bytes.toFixed(1)} ${units[u]}`;
};

export default getPrettyFileSize;
