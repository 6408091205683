import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import { Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import * as S from './docsModeSwitchSc';

import { st_docs_setMode } from 'services/store/docs/docs.actions';
import { st_docs_getEditorMode } from 'services/store/docs/docs.selectors';

const DocsModeSwitch: React.FC = () => {
  const dispatch = useDispatch();
  const editorMode = useSelector(st_docs_getEditorMode);
  const handlePreviewChange = (checked: boolean) => {
    dispatch(st_docs_setMode(checked ? 'read' : 'edit'));
  };

  return (
    <S.SwitchContainer>
      <span>
        <Trans>Edit / Preview</Trans>:
      </span>
      <Switch
        title={i18n._('View mode: edit / preview')}
        checked={editorMode === 'read'}
        onChange={handlePreviewChange}
        checkedChildren={<FontAwesomeIcon icon={['fas', 'eye']} />}
        unCheckedChildren={<FontAwesomeIcon icon={['fas', 'pen']} />}
      />
    </S.SwitchContainer>
  );
};

export default DocsModeSwitch;
