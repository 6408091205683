import { AxiosError } from 'axios';
import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';

import { api_getSolrQueryObject } from './solrQuery.queries';
import { GetSolrQueryObjectParams, SolrQueryObject } from './solrQuery.types';

// eslint-disable-next-line import/prefer-default-export
export const useSolrQueryObject = (
  params: GetSolrQueryObjectParams,
  options?: UseQueryOptions<SolrQueryObject, AxiosError, SolrQueryObject>
): QueryObserverResult<SolrQueryObject, AxiosError> =>
  useQuery<SolrQueryObject, AxiosError>(
    ['solrQueryObject', params],
    () => api_getSolrQueryObject(params),
    options
  );
