import { ListQueryParams } from '@phoenix-systems/react-table/dist/types/types';

import { HistoryTableActionTypes, HistoryTableSingleView } from './historyTable.types';

/* Action Definition */

type SetParamsAction = {
  type: HistoryTableActionTypes.SET_PARAMS;
  params?: Partial<ListQueryParams>;
};

type SetSingleViewAction = {
  type: HistoryTableActionTypes.SET_SINGLE_VIEW;
  view: HistoryTableSingleView;
};

type SetSelectedKeyAction = {
  type: HistoryTableActionTypes.SET_SELECTED_KEY;
  key?: string;
};

type ResetAction = {
  type: HistoryTableActionTypes.RESET;
};

/* Union Action Types */

export type HistoryTableActionCreators =
  | SetParamsAction
  | SetSingleViewAction
  | SetSelectedKeyAction
  | ResetAction;

/* Action Creators */

export const st_historyTable_setParams = (params?: Partial<ListQueryParams>): SetParamsAction => ({
  type: HistoryTableActionTypes.SET_PARAMS,
  params
});

export const st_historyTable_setSingleView = (
  view: HistoryTableSingleView
): SetSingleViewAction => ({
  type: HistoryTableActionTypes.SET_SINGLE_VIEW,
  view
});

export const st_historyTable_setSelectedKey = (key?: string): SetSelectedKeyAction => ({
  type: HistoryTableActionTypes.SET_SELECTED_KEY,
  key
});

export const st_historyTable_reset = (): ResetAction => ({
  type: HistoryTableActionTypes.RESET
});
