import { Link } from 'react-router-dom';
import tw, { css, styled } from 'twin.macro';

type StyledComponentProps = {
  active?: boolean;
};

export const StyledComponent = styled.div<StyledComponentProps>(({ active }) => [
  css`
    ${tw`bg-red`};
  `,

  active &&
    css`
      ${tw`border-secondary hover:border-secondary`};
    `
]);

export const DomainLink = styled(Link)(() => [
  css`
    ${tw`text-primary font-600 hover:text-secondary`};
  `
]);

export const Description = styled.p(() => [
  css`
    ${tw`text-13`};
  `
]);

export const UserItem = styled.div(() => [
  css`
    ${tw`flex flex-row border border-ant-border-gray bg-light-gray p-6 rounded`};

    & > svg {
      ${tw`text-20 mr-12`};
    }

    & > div {
      & > div {
        min-height: unset;
        & .label {
          ${tw`pb-0 text-12`};
        }

        & .value {
          ${tw`pb-6`};
        }

        & button {
          ${tw`p-0 h-20`};
        }
      }
    }
  `
]);

export const StyledLink = styled(Link)(() => [
  css`
    ${tw`text-primary hover:text-secondary`};

    & svg {
      ${tw`ml-4 inline-block text-gray-400`};
    }
  `
]);
