import React, { useCallback, useEffect, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { Table } from '@phoenix-systems/react-table';
import { CustomColumnType, TableRef } from '@phoenix-systems/react-table/dist/types/types';
import { Popover } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useImmer } from 'use-immer';

import * as S from './queryDocumentsResultListSc';
import QueryDocumentsResultListSingle from './queryDocumentsResultListSingle';

import * as T from 'components/_styled/tableSc';
import useContainer from 'components/app/components/containerProvider/useContainer';
import ObjectActions from 'components/domain/actions/objectActions/objectActions';
import Button from 'components/ui/button';
import MonacoEditor from 'components/ui/monacoEditor';
import tableLocales from 'config/langauge/table.locales';
import routes from 'config/routes/routes';
import useApiKey from 'hooks/useApiKey';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import useDomainName from 'hooks/useDomainName';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';
import useSchemaName from 'hooks/useSchemaName';
import { useQueryDomainObjects } from 'services/api/domain/object';
import {
  st_entityDocument_setListParams,
  st_entityDocument_setSelectedKey
} from 'services/store/entityDocument/entityDocument.actions';
import {
  st_entityDocument_getEntity,
  st_entityDocument_getListParams,
  st_entityDocument_getQuery,
  st_entityDocument_getQueryCollapsed,
  st_entityDocument_getSelectedKey
} from 'services/store/entityDocument/entityDocument.selectors';
import { log } from 'utils';

const MAX_COLS_EMBEDDED = 4;
const MAX_COLS_STANDALONE = 8;

type QueryDocumentsResultListProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  result?: any[];
  mode: 'STANDALONE' | 'EMBEDDED';
  refetchResults?: () => void;
};

type QueryDocumentsResultListState = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
};

const QueryDocumentsResultList: React.FC<QueryDocumentsResultListProps> = ({
  result,
  mode,
  refetchResults
}) => {
  const domainName = useDomainName();
  const schema = useSchemaName();
  const tableRef = useRef<TableRef>(null);
  const { formContainer } = useContainer();
  const listParams = useSelector(st_entityDocument_getListParams);
  const solrQuery = useSelector(st_entityDocument_getQuery);
  const currentType = useSelector(st_entityDocument_getEntity);
  const dispatch = useDispatch();
  const selectedKey = useSelector(st_entityDocument_getSelectedKey);
  const isQueryCollapsed = useSelector(st_entityDocument_getQueryCollapsed);
  const [state, setState] = useImmer<QueryDocumentsResultListState>({
    data: []
  });
  const apiKey = useApiKey();
  const goto = useGoToDomainRoute();
  const copy = useCopyToClipboard();

  const objectQuery = useQueryDomainObjects(
    {
      domainName,
      schema,
      type: currentType || '',
      payload: solrQuery,
      apiKey
    },
    {
      enabled: false,
      onSuccess: res => {
        setState(draft => {
          draft.data = res.docs;
        });
      }
    }
  );
  const { current: objectQueryRef } = useRef(objectQuery);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleEdit = (id: any) => {
    if (typeof id === 'string') {
      goto(routes.domain.queryData.entityDocument.single, { query: { id } });
    }
  };

  const handleSelectChange = (selectedRowKeys: React.Key[]) => {
    dispatch(st_entityDocument_setSelectedKey(selectedRowKeys[0] as string));
  };

  const handleAfterDelete = useCallback(() => {
    if (apiKey !== '') {
      if (mode === 'EMBEDDED' && refetchResults) {
        refetchResults();

        return;
      }

      objectQueryRef.refetch();
    }
  }, [objectQueryRef, mode, refetchResults, apiKey]);

  const getFieldInfo = useCallback(
    (dataKey: string) => {
      if (!solrQuery) {
        return null;
      }

      let key: string | undefined;
      solrQuery.fields.forEach(fl => {
        const parts = fl.split(':');

        // eslint-disable-next-line prefer-destructuring
        if (parts[0] === dataKey) {
          // eslint-disable-next-line prefer-destructuring
          key = parts[1];
        }
      });

      return (
        <S.FieldInfo>
          <p>
            <span>Key:</span>
            {dataKey}
          </p>
          <p>
            <span>Solr key:</span>
            {key}
          </p>
        </S.FieldInfo>
      );
    },
    [solrQuery]
  );

  const getSimpleValue = useCallback(
    (value: string | number, key: string) => (
      <Popover
        trigger="hover"
        getPopupContainer={() => formContainer}
        overlayClassName="entity-document"
        content={
          <>
            <S.ItemValue className="popover">{value}</S.ItemValue>
            <S.PopoverFooter>
              {getFieldInfo(key)}
              <Button
                action="copy"
                type="link"
                isIconOnly
                onClick={() => copy(value.toString())}
                title={i18n._('Copy value')}
              />
            </S.PopoverFooter>
          </>
        }
      >
        <S.ItemValue className="table">{value}</S.ItemValue>
      </Popover>
    ),
    [copy, getFieldInfo, formContainer]
  );

  const getColumns = useCallback(() => {
    const maxVisibleCols =
      mode === 'EMBEDDED' && !isQueryCollapsed ? MAX_COLS_EMBEDDED : MAX_COLS_STANDALONE;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const cols: CustomColumnType<any>[] = [];
    const indexes: string[] = [];

    if (state.data[0]) {
      if (state.data[0].id) {
        cols.push({
          title: 'id',
          dataIndex: 'id',
          key: 'id',
          render: value => getSimpleValue(value, 'id')
        });
        indexes.push('id');
      }

      if (state.data[0].contentid_string) {
        cols.push({
          title: 'contentid_string',
          dataIndex: 'contentid_string',
          key: 'contentid_string',
          disabled: true,
          render: value => getSimpleValue(value, 'contentid_string')
        });
        indexes.push('contentid_string');
      }

      const keys = Object.keys(state.data[0]);

      keys.forEach(key => {
        const isVisible = indexes.length <= maxVisibleCols;

        if (indexes.includes(key) === false) {
          indexes.push(key);

          cols.push({
            title: key,
            dataIndex: key,
            key,
            disabled: !isVisible,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            render: (value: any) => {
              if (value === null || value === undefined) {
                return null;
              }

              if (typeof value === 'string' || typeof value === 'number') {
                return getSimpleValue(value, key);
              }

              let jsonString: string | undefined;
              let formattedJsonString: string | undefined;

              try {
                formattedJsonString = JSON.stringify(value, null, '\t');
                jsonString = JSON.stringify(value);
              } catch (err) {
                log(err);

                return null;
              }

              if (jsonString !== undefined && formattedJsonString !== undefined) {
                return (
                  <Popover
                    trigger="hover"
                    getPopupContainer={() => formContainer}
                    overlayClassName="entity-document"
                    content={
                      <>
                        <S.StyledTextareaCode
                          className="code popover"
                          autoSize
                          readOnly
                          value={formattedJsonString}
                        />
                        <S.PopoverFooter>
                          {getFieldInfo(key)}
                          <Button
                            action="copy"
                            isIconOnly
                            type="link"
                            onClick={() => copy(formattedJsonString)}
                          />
                        </S.PopoverFooter>
                      </>
                    }
                  >
                    <S.StyledTextareaCode
                      className="code table"
                      autoSize
                      readOnly
                      value={jsonString}
                    />
                  </Popover>
                );
              }

              return null;
            }
          });
        }
      });
    }

    cols.push({
      title: '',
      dataIndex: '',
      key: 'actions',
      width: 32,
      hiddenInConfig: true,
      // eslint-disable-next-line react/display-name
      render: (value, record) => (
        <S.ListButtons className="actions">
          <ObjectActions
            data={record}
            mode="ICON_BUTTON"
            popoverPlacement="leftTop"
            onDelete={handleAfterDelete}
          />
        </S.ListButtons>
      )
    });

    return cols;
  }, [
    state.data,
    isQueryCollapsed,
    handleAfterDelete,
    formContainer,
    getFieldInfo,
    getSimpleValue,
    copy,
    mode
  ]);

  useEffect(() => {
    if (apiKey !== '') {
      if (result) {
        setState(draft => {
          draft.data = result;
        });

        return;
      }

      objectQueryRef.refetch();
    }
  }, [result, objectQueryRef, apiKey, setState]);

  const table = (
    <Table
      ref={tableRef}
      table={{
        dataSource: state.data,
        loading: objectQuery.isLoading || false,
        size: 'small',
        rowKey: 'id',
        columns: getColumns(),
        pagination: {
          pageSizeOptions: ['20', '50', '100'],
          pageSize: listParams.pageSize,
          showSizeChanger: true,
          current: listParams.currentPage
        },
        rowSelection: {
          type: 'radio',
          onChange: handleSelectChange,
          selectedRowKeys: selectedKey ? [selectedKey] : []
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onRow: (record: any) => ({
          onClick: e => {
            const isAction = (e.target as HTMLElement).closest('.actions') !== null;

            if (!isAction) {
              dispatch(st_entityDocument_setSelectedKey(record.id));
            }
          },
          onDoubleClick: () => {
            handleEdit(record.id);
          }
        })
      }}
      fillHeight
      layout={mode === 'EMBEDDED' ? 'default' : 'single-split'}
      defaultTableContainerHeight={mode === 'STANDALONE' ? '55%' : undefined}
      topNavElements={{
        search: false,
        reload: {
          onChange: () => {
            dispatch(st_entityDocument_setListParams(undefined));
            objectQuery.refetch();
          }
        },
        totalCount: {
          icon: <FontAwesomeIcon icon={['fas', 'brackets-curly']} />
        },
        actionsLeft:
          mode === 'STANDALONE' ? (
            <Popover
              trigger="hover"
              placement="bottomLeft"
              content={
                <>
                  <MonacoEditor
                    height="50rem"
                    width="70rem"
                    mode="JSON"
                    value={solrQuery}
                    showMiniMap={false}
                  />
                </>
              }
            >
              <Button icon={['fas', 'question-square']}>Query</Button>
            </Popover>
          ) : undefined
      }}
      onQueryParamsChange={params => {
        dispatch(st_entityDocument_setListParams(params));
      }}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderSingle={(selectedItems: any[]) => (
        <QueryDocumentsResultListSingle data={selectedItems} />
      )}
      locales={{
        '1 item selected': i18n._('1 object selected'),
        'items selected': i18n._('Objects selected'),
        'Nothing selected': i18n._('object selected'),
        ...tableLocales
      }}
    />
  );

  return (
    <T.StyledTable className="entity-document-result-list">
      {mode === 'EMBEDDED' && table}
      {mode === 'STANDALONE' && <div>{table}</div>}
    </T.StyledTable>
  );
};

export default QueryDocumentsResultList;
