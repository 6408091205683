import { Page } from '@phoenix-systems/react-layout';
import { Button } from 'antd';
import tw, { css, styled } from 'twin.macro';

type StyledPageProps = {
  noScroll?: boolean;
  noPadding?: boolean;
  noHeaderColor?: boolean;
};

export const StyledPage = styled(Page)<StyledPageProps>(
  ({ noScroll, noPadding, noHeaderColor }) => [
    css`
      & .ant-layout-header {
        ${tw`px-mobileX md:px-desktopX py-8 md:py-12 bg-light-gray shadow-sm`};
      }

      & .layout-title {
        ${tw`font-700 text-primary whitespace-nowrap overflow-hidden text-24`};
        text-overflow: ellipsis;
        overflow: hidden;
      }

      & .ant-layout-footer {
        ${tw`border-t border-ant-border-gray md:px-desktopX px-mobileX`};
      }

      & .layout-title-container {
        ${tw`flex flex-row items-center h-36`};
      }

      & .layout-content-inner {
        ${tw`px-mobileX md:px-desktopX md:pt-16 relative`};
      }

      & .layout-title-elements {
        ${tw`ml-auto items-center h-full pl-32 flex flex-row whitespace-nowrap`}
        transform: translateY(-8px);
      }

      &.domain-diagram-page .ant-layout-header {
        ${tw`pb-8`}
      }

      &.entiy-document-query-page .ant-layout-content {
        ${tw`h-full`}

        & .layout-content-inner {
          ${tw`h-full relative`}
        }
      }

      &.no-header-highlight .ant-layout-header {
        ${tw`bg-transparent`}
      }

      &.page-swagger {
        .ant-layout-content {
          ${tw`h-full`}

          & .layout-content-inner {
            ${tw`h-full relative p-0`}
          }
        }

        & .ant-layout-header {
          ${tw`pb-0`}
        }
      }
    `,

    noScroll &&
      css`
        ${tw`h-full`}

        & .layout-content-inner.no-scroll {
          ${tw`h-full relative`}
        }
      `,

    noPadding &&
      css`
        & .layout-content-inner {
          ${tw`p-0`}
        }
      `,

    noHeaderColor &&
      css`
        & .ant-layout-header {
          ${tw`bg-transparent`}
        }
      `
  ]
);

export const BackButton = styled(Button)(() => [
  css`
    ${tw`p-0 m-0 w-12 h-32 mr-8 opacity-60 hover:opacity-100`}
    transform: translateY(-7px);

    & svg {
      ${tw`p-0 m-0`}
    }
  `
]);

export const FooterContainer = styled.div(() => [
  css`
    ${tw`pt-12 pb-16 mt-4 flex flex-row`};
  `
]);

export const FooterButtons = styled.div(() => [
  css`
    ${tw`ml-auto flex flex-row`};

    & button {
      ${tw`ml-12`};
      min-width: 120px;
    }
  `
]);

export const FooterButtonSeparator = styled.div(() => [
  css`
    ${tw`w-1 border-l border-ant-border-gray h-full ml-24 mr-12`};
  `
]);
