import { FullScreen } from 'react-full-screen';
import tw, { css, styled } from 'twin.macro';

export const StyledFullScreen = styled(FullScreen)(() => [
  css`
    ${tw`bg-white transition-all duration-200 opacity-0`}

    &.fullscreen-enabled {
      ${tw`opacity-100`}
    }
  `
]);
