import React, { useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { FormProvider } from '@phoenix-systems/react-form';
import { useImmer } from 'use-immer';

import * as S from './triggerAdderSc';
import TriggerFormDialog from './triggerFormDialog';

import TriggerActions from 'components/trigger/actions';
import TriggerPlay from 'components/trigger/components/triggerPlay';
import TriggersTableSingleOverview from 'components/trigger/components/triggersTable/singleViews/triggerTableOverview';
import Button from 'components/ui/button';
import CollapsibleContent from 'components/ui/collapsibleContent';
import Loader from 'components/ui/loader';
import NoContent from 'components/ui/noContent';
import routes from 'config/routes/routes';
import useDomainName from 'hooks/useDomainName';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';
import useSchemaName from 'hooks/useSchemaName';
import { Trigger, useTriggers } from 'services/api/domain/trigger';

type TriggerAdderProps = {
  data?: Partial<Trigger>;
  getInitialData?: () => Partial<Trigger>;
  isCreateTaskReadonly?: boolean;
  taskName?: string;
};

type TriggerAdderState = {
  isDialogOpen: boolean;
  initCreateData?: Partial<Trigger>;
};

const TriggerAdder: React.FC<TriggerAdderProps> = ({
  data,
  taskName,
  getInitialData,
  isCreateTaskReadonly
}) => {
  const domainName = useDomainName();
  const schema = useSchemaName();
  const [state, setState] = useImmer<TriggerAdderState>({
    isDialogOpen: false,
    initCreateData: data
  });
  const goto = useGoToDomainRoute();

  const triggersQuery = useTriggers({ domainName });
  const foundTriggers = triggersQuery.data?.filter(
    item => item.taskScheme === schema && item.taskName === taskName
  );

  const handleAddTriggerRequest = () => {
    let initData: Partial<Trigger> | undefined;

    if (getInitialData) {
      initData = getInitialData();
    }

    setState(draft => {
      draft.isDialogOpen = true;
      draft.initCreateData = initData;
    });
  };

  useEffect(() => {
    setState(draft => {
      draft.initCreateData = data;
    });
  }, [data, setState]);

  return (
    <>
      <S.Wrapper>
        <S.Header>
          <FontAwesomeIcon icon={['fas', 'clock']} />
          <Trans>Attached triggers</Trans>
          <Button action="create" style={{ marginLeft: 'auto' }} onClick={handleAddTriggerRequest}>
            <Trans>Add trigger</Trans>
          </Button>
        </S.Header>
        {triggersQuery.isLoading ? (
          <Loader height={80} />
        ) : (
          <>
            {!foundTriggers || foundTriggers.length === 0 ? (
              <NoContent height={86} />
            ) : (
              <S.TriggersList>
                {foundTriggers?.map(trigger => (
                  <li key={trigger.id}>
                    <CollapsibleContent
                      header={
                        <>
                          <h4>{trigger.name}</h4>
                          <S.EditButton
                            type="link"
                            icon={<FontAwesomeIcon icon={['fas', 'pen']} />}
                            onClick={() =>
                              goto(routes.domain.scheduler.single, { path: trigger.name })
                            }
                          />
                          <div className="actions">
                            <TriggerPlay
                              active={trigger.active}
                              size="large"
                              style={{ marginRight: 12 }}
                              data={trigger}
                            />
                            <TriggerActions
                              data={trigger}
                              mode="ICON_BUTTON"
                              popoverPlacement="leftTop"
                            />
                          </div>
                        </>
                      }
                    >
                      <TriggersTableSingleOverview data={trigger} isTriggerAdder />
                    </CollapsibleContent>
                  </li>
                ))}
              </S.TriggersList>
            )}
          </>
        )}
      </S.Wrapper>

      <FormProvider>
        <TriggerFormDialog
          data={state.initCreateData}
          isOpen={state.isDialogOpen}
          close={() =>
            setState(draft => {
              draft.isDialogOpen = false;
            })
          }
          isTaskReadonly={isCreateTaskReadonly}
        />
      </FormProvider>
    </>
  );
};

export default TriggerAdder;
