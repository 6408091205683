import React, { useCallback, useEffect, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { FormItem, useFormContext } from '@phoenix-systems/react-form';
import { Table } from '@phoenix-systems/react-table';
import { CustomColumnType, TableRef } from '@phoenix-systems/react-table/dist/types/types';
import { Form, Input, InputNumber } from 'antd';
import { clone } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParam } from 'react-use';
import { useImmer } from 'use-immer';

import { getParsedHistoryDate } from './documentHistory.utils';
import DocumentHistoryJsonDiff from './documentHistoryJsonDiff';
import DocumentHistoryTableSingle from './documentHistoryTableSingle';
import * as S from './domainDocumentHistorySc';

import * as F from 'components/_styled/formSc';
import * as T from 'components/_styled/tableSc';
import TooltipLabel from 'components/docs/components/docsTooltip/tooltipLabel';
import Button from 'components/ui/button';
import Loader from 'components/ui/loader';
import tableLocales from 'config/langauge/table.locales';
import routes from 'config/routes/routes';
import useApiKey from 'hooks/useApiKey';
import useDomainName from 'hooks/useDomainName';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';
import useNotification from 'hooks/useNotification';
import useSchemaName from 'hooks/useSchemaName';
import { TableFilter } from 'index.types';
import { DocId } from 'services/api/domain/hint';
import { useObjectHistory } from 'services/api/domain/objectHistory';
import {
  ObjectHistory,
  ObjectHistoryParameters,
  ParsedObjectHistoryDiff
} from 'services/api/domain/objectHistory/objectHistory.types';
import {
  st_historyTable_setParams,
  st_historyTable_setSelectedKey
} from 'services/store/historyTable/historyTable.actions';
import {
  st_historyTable_getParams,
  st_historyTable_getSelectedKey
} from 'services/store/historyTable/historyTable.selectors';

const DEFAULT_VALUES = {
  depth: 100
};

type DocumentHistoryTableState = {
  queryParams: ObjectHistoryParameters;
  dataRequested: boolean;
  selectedKey?: string;
};

const DocumentHistoryTable: React.FC = () => {
  const domainName = useDomainName();
  const schema = useSchemaName();
  const idFromRoute = useSearchParam('id');
  const depthFromRoute = useSearchParam('depth');
  const dispatch = useDispatch();
  const tableRef = useRef<TableRef>(null);
  const params = useSelector(st_historyTable_getParams);
  const selectedKey = useSelector(st_historyTable_getSelectedKey);
  const goto = useGoToDomainRoute();
  const apiKey = useApiKey();

  const [state, setState] = useImmer<DocumentHistoryTableState>({
    queryParams: {
      ...DEFAULT_VALUES,
      domainName,
      schema,
      id: '',
      apiKey
    },
    dataRequested: false,
    selectedKey: undefined
  });

  const [form] = Form.useForm<ObjectHistoryParameters>();
  const [formState, formActions] = useFormContext(form, DEFAULT_VALUES);

  const [addNotification] = useNotification();

  const historyQuery = useObjectHistory(state.queryParams, {
    enabled: false,
    onSuccess: () => {
      setState(draft => {
        draft.dataRequested = false;
      });
    },
    onError: () => {
      addNotification({
        type: 'error',
        message: i18n._(
          t`Failed to load object history for object with id "${form.getFieldValue('id').id}".`
        )
      });
    }
  });

  const getInitialValues = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const vals: any = clone(DEFAULT_VALUES);

    if (idFromRoute) {
      vals.id = idFromRoute;
    }

    if (depthFromRoute) {
      vals.depth = depthFromRoute;
    }

    return vals;
  };

  const handleLoadHistory = () => {
    form.validateFields().then(formData => {
      goto(routes.domain.queryData.documentHistory, {
        query: { id: formData.id, depth: formData.depth }
      });
    });
  };

  const handleReset = () => {
    historyQuery.remove();
    formActions.reset();
  };

  const getUserFilters = useCallback(() => {
    const filters: TableFilter[] = [];
    historyQuery.data?.forEach(item => {
      if (!filters.find(i => i.value === item.userid)) {
        filters.push({
          text: item.userid,
          value: item.userid
        });
      }
    });

    return filters;
  }, [historyQuery.data]);

  const getObjectTypeFilter = useCallback(() => {
    const filters: TableFilter[] = [];
    historyQuery.data?.forEach(item => {
      if (!filters.find(i => i.value === item.objectType)) {
        filters.push({
          text: item.objectType,
          value: item.objectType
        });
      }
    });

    return filters;
  }, [historyQuery.data]);

  const getCustomTypeFilters = useCallback(() => {
    const filters: TableFilter[] = [];
    historyQuery.data?.forEach(item => {
      if (!filters.find(i => i.value === item.customType)) {
        filters.push({
          text: item.customType,
          value: item.customType
        });
      }
    });

    return filters;
  }, [historyQuery.data]);

  const getColumns = useCallback(() => {
    const columns: CustomColumnType<ObjectHistory>[] = [
      {
        title: i18n._('Date of change'),
        dataIndex: 'dateOfChange',
        key: 'objectType',
        sortDirections: ['descend', 'ascend'],
        ellipsis: true,
        sorter: (a, b) => a.dateOfChange.localeCompare(b.dateOfChange),
        render: (value: string) => getParsedHistoryDate(value)
      },
      {
        title: i18n._('User id'),
        dataIndex: 'userid',
        key: 'userid',
        sortDirections: ['descend', 'ascend'],
        ellipsis: true,
        sorter: (a, b) => a.userid.localeCompare(b.userid),
        filters: getUserFilters(),
        disabled: true
      },
      {
        title: i18n._('Object domainId'),
        dataIndex: 'objectDomainId',
        key: 'objectDomainId',
        sortDirections: ['descend', 'ascend'],
        ellipsis: true,
        sorter: true,
        disabled: true
      },
      {
        title: i18n._('Object Type'),
        dataIndex: 'objectType',
        key: 'objectType',
        sortDirections: ['descend', 'ascend'],
        ellipsis: true,
        sorter: true,
        filters: getObjectTypeFilter()
      },
      {
        title: i18n._('Custom Type'),
        dataIndex: 'customType',
        key: 'customType',
        sortDirections: ['descend', 'ascend'],
        ellipsis: true,
        sorter: true,
        filters: getCustomTypeFilters(),
        disabled: true
      },
      {
        title: i18n._('Diff'),
        dataIndex: 'parsedDiff',
        key: 'parsedDiff',
        sortDirections: ['descend', 'ascend'],
        ellipsis: true,
        sorter: true,
        filters: [
          {
            text: 'add',
            value: 'add'
          },
          {
            text: 'copy',
            value: 'copy'
          },
          {
            text: 'move',
            value: 'move'
          },
          {
            text: 'remove',
            value: 'remove'
          },
          {
            text: 'replace',
            value: 'replace'
          }
        ],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onFilter: (values: any, record) => {
          if (Array.isArray(values)) {
            const matches = record.parsedDiff?.filter(item => values.includes(item.op));

            if (matches && matches.length > 0) {
              return true;
            }

            return false;
          }

          const match = record.parsedDiff?.find(item => item.op === values);

          if (match) {
            return true;
          }

          return false;
        },
        // eslint-disable-next-line  react/display-name
        render: (value: ParsedObjectHistoryDiff[]) => {
          if (!value) {
            return null;
          }

          return <DocumentHistoryJsonDiff data={value} size="small" isTable />;
        },
        width: '50%',
        responsiveDisabled: {
          max: 1200
        }
      }
    ];

    return columns;
  }, [getCustomTypeFilters, getObjectTypeFilter, getUserFilters]);

  useEffect(() => {
    if (state.queryParams.id !== '' && state.dataRequested) {
      historyQuery.refetch();
    }
  }, [state.queryParams, state.dataRequested, historyQuery]);

  useEffect(() => {
    let id: string | undefined;
    let depth: number | undefined;

    if (idFromRoute) {
      id = idFromRoute;
      setState(draft => {
        draft.queryParams.id = idFromRoute;
      });
    }

    if (depthFromRoute) {
      const nr = parseInt(depthFromRoute, 10);

      if (!Number.isNaN(nr)) {
        depth = nr;
        setState(draft => {
          draft.queryParams.depth = nr;
        });
      }
    }

    if (id && depth) {
      formActions.reset({ ...DEFAULT_VALUES, id, depth }, true);
      formActions.setChanged(true);

      setState(draft => {
        draft.dataRequested = true;
      });
    }
  }, [idFromRoute, depthFromRoute, formActions, setState]);

  useEffect(() => {
    setState(draft => {
      draft.queryParams.apiKey = apiKey;
    });
  }, [apiKey, setState]);

  return (
    <>
      <T.StyledTable className="document-history-table">
        <div>
          <Table
            ref={tableRef}
            table={{
              dataSource: historyQuery.data,
              loading: historyQuery.isFetching && state.dataRequested,
              size: 'small',
              rowKey: 'id',
              columns: getColumns(),
              rowSelection: {
                type: 'radio',
                onChange: (keys: React.Key[]) => {
                  dispatch(st_historyTable_setSelectedKey(keys[0] as string));
                },
                selectedRowKeys: selectedKey ? [selectedKey] : undefined
              },
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onRow: (record: any) => ({
                onClick: () => {
                  dispatch(st_historyTable_setSelectedKey(record.id));
                }
              }),
              pagination: {
                pageSizeOptions: ['5', '10', '20', '50'],
                pageSize: params.pageSize || 20,
                current: params.currentPage || 1,
                showSizeChanger: true
                //  size: isMobile ? 'default' : undefined
              }
            }}
            fillHeight
            layout="single-split"
            activeFilters={{ enabled: true, placement: 'default' }}
            topNavElements={{
              search: false,
              actionsLeft: (
                <S.TableActionsLeft>
                  <F.StyledForm
                    name="document-history-parameters-form"
                    className="parameters-form"
                    form={form}
                    onValuesChange={formActions.onValuesChange}
                    onFieldsChange={formActions.onFieldsChange}
                    initialValues={getInitialValues()}
                    $maxWidth={2000}
                    colon={false}
                  >
                    <FormItem
                      className="id-input"
                      label={
                        <TooltipLabel
                          docId={DocId.DOMAIN_DOCUMENT_HISTORY_SEARCH_ID}
                          label={<Trans>Object id</Trans>}
                        />
                      }
                      messageVariables={{ name: i18n._('Object id') }}
                      validateFirst
                      name="id"
                      rules={[
                        { required: true },
                        {
                          pattern: /9x[0-9A-Z]{56}/,
                          message: i18n._('This is not a valid object id.')
                        }
                      ]}
                      registerField={formActions.registerField}
                    >
                      <Input />
                    </FormItem>
                    <FormItem
                      className="depth-input"
                      label={
                        <TooltipLabel
                          docId={DocId.DOMAIN_DOCUMENT_HISTORY_SEARCH_DEPTH}
                          label={<Trans>Rows</Trans>}
                        />
                      }
                      messageVariables={{ name: i18n._('Rows') }}
                      name="depth"
                      rules={[{ required: true }]}
                      registerField={formActions.registerField}
                    >
                      <InputNumber min={1} />
                    </FormItem>

                    <div className="buttons">
                      <Button
                        action="reset"
                        disabled={historyQuery.isFetching || !formState.hasChanged}
                        onClick={handleReset}
                      />
                      <Button
                        disabled={!formState.isValid || historyQuery.isFetching}
                        loading={historyQuery.isFetching}
                        type="primary"
                        onClick={handleLoadHistory}
                        icon={['fas', 'history']}
                      >
                        <Trans>Load history</Trans>
                      </Button>
                    </div>
                  </F.StyledForm>
                </S.TableActionsLeft>
              ),
              totalCount: {
                icon: <FontAwesomeIcon icon={['fas', 'history']} />,
                loader: <Loader size={12} />
              },
              reload: false,
              resetParams: true
            }}
            defaultTableContainerHeight="calc(100% - 57rem)"
            locales={{
              '1 item selected': i18n._('1 document selected'),
              'items selected': i18n._('documents selected'),
              'Nothing selected': i18n._('No document selected'),
              ...tableLocales
            }}
            renderSingle={selectedItems => <DocumentHistoryTableSingle data={selectedItems} />}
            onQueryParamsChange={qParams => {
              dispatch(st_historyTable_setParams(qParams));
            }}
          />
        </div>
      </T.StyledTable>
    </>
  );
};

export default DocumentHistoryTable;
