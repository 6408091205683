import { useIsMobile, usePageState } from '@phoenix-systems/react-layout';
import { notification } from 'antd';

import useContainer from 'components/app/components/containerProvider/useContainer';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useNotification = () => {
  const { notificationContainer } = useContainer();
  const isMobile = useIsMobile();
  const pageState = usePageState();

  const addNotification = (params: {
    type: 'success' | 'error' | 'info' | 'warn';
    message: string;
    duration?: number;
  }) => {
    notification[params.type]({
      placement: 'topRight',
      top: pageState.contentMeasures.top - 12,
      style: { marginRight: isMobile ? 12 : 40 },
      getContainer: () => notificationContainer,
      message: params.message,
      duration: params.duration
    });
  };

  return [addNotification];
};

export default useNotification;
