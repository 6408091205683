import { ReindexDomainParams, RenindexResponse } from './index.types';

import apiClient from 'services/api/apiClient';

const BASE_PATH = 'index';

/**
 * GET /api/index/reindex/{domain}
 *
 * apiKey required
 */
export const api_reindexDomain = async (params: ReindexDomainParams): Promise<RenindexResponse> => {
  const res = await apiClient.get(`${BASE_PATH}/reindex/${params.domainName}`, {
    params: { batchSize: params.batchSize },
    headers: {
      APIKEY: params.apiKey
    }
  });

  return res.data;
};

/**
 * GET /api/index/reindex
 */
/* export const api_reindexSystem = async (): Promise<RenindexResponse> => {
  const res = await apiClient.get(`${BASE_PATH}/reindex`);

  return res.data;
};
 */
