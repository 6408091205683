import tw, { css, styled } from 'twin.macro';

export const Wrapper = styled.div(() => [
  css`
    ${tw`w-full h-full flex items-center justify-center`};
  `
]);

export const Content = styled.div(() => [
  css`
    ${tw`flex flex-row `};

    padding-bottom: 132px;

    & div.left {
      ${tw`flex items-center`};

      & div {
        ${tw`text-64 text-primary font-700`};
      }
    }

    & div.right {
      ${tw`ml-24 pl-24 border-l-2 border-gray-500 items-center flex`};
      min-height: 10rem;

      & > div {
        transform: translateY(-2px);
      }
    }
  `
]);

export const Message = styled.div(() => [
  css`
    ${tw`text-16 font-600 text-gray-500 mb-12`};

    & b {
      ${tw`text-primary font-600`};
    }
  `
]);

export const Buttons = styled.div(() => [
  css`
    ${tw`flex flex-row items-center mt-18`};

    & .ant-btn {
      ${tw`mr-12`};
    }
  `
]);
