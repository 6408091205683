import React, { useCallback, useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Menu } from 'antd';
import { omit } from 'lodash';

import CheckDbConnectionDialog from './checkFtpConnectionDialog';

import { FTPConnection } from 'services/api/domain/ftpConnection';

const { Item } = Menu;

type CheckFtpConnectionActionProps = {
  data: FTPConnection;
};

const CheckFtpConnectionAction: React.FC<CheckFtpConnectionActionProps> = props => {
  const antProps = omit(props, ['data']);
  const { data } = props;
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isValidParams, setValidParams] = useState(false);

  const handleCheckRequest = () => {
    setDialogOpen(true);
  };

  const checkValidDbParams = useCallback(() => {
    if (!data) {
      return false;
    }

    if (
      data.remoteHost &&
      data.remoteDir &&
      data.sftp !== undefined &&
      data.username &&
      data.password
    ) {
      return true;
    }

    return false;
  }, [data]);

  useEffect(() => {
    const isValid = checkValidDbParams();
    setValidParams(isValid);
  }, [data, checkValidDbParams]);

  return (
    <>
      <Item
        {...antProps}
        disabled={!isValidParams}
        key={`test-db-connection_${data.id}`}
        icon={<FontAwesomeIcon icon={['fas', 'exchange']} />}
        onClick={handleCheckRequest}
      >
        <Trans>Test FTP connection</Trans>
      </Item>

      <CheckDbConnectionDialog
        isOpen={isDialogOpen}
        data={data}
        close={() => setDialogOpen(false)}
      />
    </>
  );
};

export default CheckFtpConnectionAction;
