import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Menu } from 'antd';
import { omit } from 'lodash';

import Button from 'components/ui/button';
import routes from 'config/routes/routes';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';
import { ActionMode } from 'index.types';

const { Item } = Menu;

type CreateDbImportActionProps = {
  mode: ActionMode;
};

const CreateDbImportAction: React.FC<CreateDbImportActionProps> = props => {
  const antProps = omit(props, ['data', 'onSelect', 'mode']);
  const { mode } = props;
  const goto = useGoToDomainRoute();

  const handleCreateRequest = () => {
    goto(routes.domain.dataManagement.importFromDb.create);
  };

  const label = <Trans>Create DB import</Trans>;

  return (
    <>
      {mode === 'BUTTON' && (
        <Button action="create" onClick={handleCreateRequest}>
          {label}
        </Button>
      )}
      {mode === 'LIST_ITEM' && (
        <Item
          {...antProps}
          key="create-db-import"
          icon={<FontAwesomeIcon icon={['fas', 'plus-circle']} />}
          onClick={handleCreateRequest}
        >
          {label}
        </Item>
      )}
    </>
  );
};

export default CreateDbImportAction;
