/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import Editor, { DiffEditor } from '@monaco-editor/react';
import { Menu, Popover } from 'antd';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import { useResizeDetector } from 'react-resize-detector';
import { CSSProperties } from 'styled-components';
import { useImmer } from 'use-immer';

import Loader from '../loader';

import {
  MonacoEditorColorTheme,
  MonacoEditorConfigState,
  MonacoEditorFontSize,
  MonacoEditorIndentSize,
  MonacoEditorMode
} from './monacoEditor.types';
import MonacoEditorCopyToClipBoardBtn from './monacoEditorCopyToClipBoardBtn';
import MonacoEditorDownloadBtn from './monacoEditorDownloadBtn';
import * as S from './monacoEditorSc';

import useContainer from 'components/app/components/containerProvider/useContainer';
import Button from 'components/ui/button';
import { AnyObject } from 'index.types';

// monaco
export type Monaco = typeof monaco;
const INIT_TIME = 500;

type MonacoEditorProps = {
  mode: MonacoEditorMode;
  value?: AnyObject | Array<AnyObject> | string;
  originalValue?: AnyObject | Array<AnyObject> | string;
  onChange?: (data: AnyObject | Array<AnyObject>) => void;
  width?: string | number;
  height?: string | number;
  readOnly?: boolean;
  defaultColorTheme?: MonacoEditorColorTheme;
  defaultFontSize?: MonacoEditorFontSize;
  showMiniMap?: boolean;
  loading?: boolean;
  showHeader?: boolean;
  style?: CSSProperties;
  id?: string;
  className?: string;
  isDiffEditor?: boolean;
};

type MonacoEditorState = {
  config: MonacoEditorConfigState;
  isNavOpen: boolean;
  editorHeight: number;
  wrapperHeight: number;
  isInitialized: boolean;
  editor?: Monaco;
};

// const ajv = new Ajv({ allErrors: true });

const MonacoEditor: React.FC<MonacoEditorProps> = ({
  value,
  originalValue,
  onChange,
  width,
  height,
  readOnly,
  showMiniMap,
  defaultColorTheme,
  defaultFontSize,
  loading,
  mode,
  showHeader,
  className,
  style,
  id,
  isDiffEditor
}) => {
  // const editorRef = useRef<ReactAce>(null);
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);
  const diffEditorRef = useRef<monaco.editor.IStandaloneDiffEditor | null>(null);

  const [state, setState] = useImmer<MonacoEditorState>({
    config: {
      colorTheme: 'BRIGHT',
      fontSize: 'DEFAULT',
      indentSize: 'DEFAULT',
      hasMiniMap: true
    },
    isNavOpen: false,
    editorHeight: 0,
    wrapperHeight: 0,
    isInitialized: false,
    editor: undefined
  });

  const { headerContainer } = useContainer();
  const containerObserver = useResizeDetector();
  const headerRef = useRef<HTMLDivElement>(null);

  const handleChange = (newValue: any) => {
    if (mode === 'JSON') {
      let obj: any;

      //    const serialize = ajv.addFormat(schema)

      try {
        obj = JSON.parse(newValue);
      } catch (err) {
        // do nothing
        // return;
      }

      if (obj) {
        /*  const schema: JSONSchemaType<MyData> = {
          $schema: 'http://json-schema.org/draft-07/schema#',
          type: 'object',
          properties: {
            foo: { type: 'integer' },
            bar: { type: 'string', nullable: true }
          },
          required: ['foo'],
          additionalProperties: false
        };
 */

        if (onChange && obj && !isEqual(value, obj)) {
          onChange(obj);
        }
      }
    }

    if (mode === 'SQL') {
      if (onChange && !isEqual(value, newValue)) {
        onChange(newValue);
      }
    }
  };

  const updateContainerDimensions = useCallback(() => {
    if (containerObserver.ref.current && headerRef.current) {
      const containerHeight = containerObserver.ref.current.getBoundingClientRect().height;
      const headerHeight = headerRef.current.getBoundingClientRect().height;

      if (containerHeight !== state.wrapperHeight) {
        const editorHeight = containerHeight - headerHeight;
        setState(draft => {
          draft.wrapperHeight = containerHeight;
          draft.editorHeight = editorHeight;
        });
      }
    }
  }, [containerObserver, headerRef, setState, state.wrapperHeight]);

  const getLanguage = useCallback(() => {
    switch (mode) {
      case 'JSON':
        return 'json';

      case 'SQL':
        return 'sql';

      default:
        return undefined;
    }
  }, [mode]);

  const getEditorValue = useCallback(
    (val?: AnyObject | Array<AnyObject> | string) => {
      try {
        switch (mode) {
          case 'JSON': {
            const stringJson = JSON.stringify(val, null, '\t');

            if (typeof stringJson === 'string') {
              return stringJson;
            }

            return '';
          }
          case 'SQL': {
            if (typeof val === 'string') {
              return val;
            }

            return '';
          }
          default:
            return '';
        }
      } catch {
        return '';
      }
    },
    [mode]
  );

  useEffect(() => {
    updateContainerDimensions();
  }, [containerObserver, value, updateContainerDimensions]);

  useEffect(() => {
    const t = setTimeout(() => {
      setState(draft => {
        draft.isInitialized = true;
      });
    }, INIT_TIME);

    return () => {
      clearTimeout(t);
    };
  }, [setState]);

  const getLineIndent = useCallback(() => {
    switch (state.config.indentSize) {
      case 'BIG':
        return 5;
      case 'DEFAULT':
        return 3;
      case 'SMALL':
        return 2;
      default:
        return 3;
    }
  }, [state.config.indentSize]);

  const getFontSize = useCallback(() => {
    switch (state.config.fontSize) {
      case 'BIG':
        return 15;
      case 'DEFAULT':
        return 13;
      case 'SMALL':
        return 11;
      default:
        return 13;
    }
  }, [state.config.fontSize]);

  const handleEditorDidMount = (
    editor: monaco.editor.IStandaloneCodeEditor,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    monacoInstance: Monaco
  ) => {
    editorRef.current = editor;
    /*  setState(draft => {
      draft.editor = monacoInstance;
    }); */
  };

  const handleDiffEditorDidMount = (
    editor: monaco.editor.IStandaloneDiffEditor,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    monacoInstance: Monaco
  ) => {
    diffEditorRef.current = editor;
    /* setState(draft => {
      draft.editor = monacoInstance;
    }); */
  };

  const setColorTheme = (theme: MonacoEditorColorTheme) => {
    setState(draft => {
      draft.config.colorTheme = theme;
    });
  };

  const setIndentSize = (indentSize: MonacoEditorIndentSize) => {
    setState(draft => {
      draft.config.indentSize = indentSize;
    });
  };

  const setFontSize = (fontSize: MonacoEditorFontSize) => {
    setState(draft => {
      draft.config.fontSize = fontSize;
    });
  };

  const handleOpenSearch = () => {
    if (editorRef.current) {
      editorRef.current.getAction('actions.find').run();
    }
  };

  const setHasMinimap = (visible: boolean) => {
    setState(draft => {
      draft.config.hasMiniMap = visible;
    });
  };

  useEffect(() => {
    if (showMiniMap !== undefined) {
      setState(draft => {
        draft.config.hasMiniMap = showMiniMap;
      });
    }
  }, [showMiniMap, setState]);

  useEffect(() => {
    if (defaultColorTheme !== undefined) {
      setState(draft => {
        draft.config.colorTheme = defaultColorTheme;
      });
    }
  }, [defaultColorTheme, setState]);

  useEffect(() => {
    if (defaultFontSize !== undefined) {
      setState(draft => {
        draft.config.fontSize = defaultFontSize;
      });
    }
  }, [defaultFontSize, setState]);

  return (
    <S.Wrapper
      ref={containerObserver.ref}
      height={height}
      width={width}
      className={clsx(['json-editor', className])}
      style={style}
      id={id}
    >
      <S.Header ref={headerRef}>
        {showHeader !== false && (
          <div className="right">
            <S.HeaderNav>
              <Button
                type="link"
                // onClick={handleShowSearch}
                title={i18n._('Search')}
                icon={['far', 'search']}
                onClick={handleOpenSearch}
              />
              <MonacoEditorCopyToClipBoardBtn data={value} type="JSON" />
              <MonacoEditorDownloadBtn data={value} type="JSON" />
              <Popover
                placement="bottomRight"
                getPopupContainer={() => headerContainer}
                trigger="click"
                onVisibleChange={visible =>
                  setState(draft => {
                    draft.isNavOpen = visible;
                  })
                }
                visible={state.isNavOpen}
                content={
                  <S.StyledConfigNav>
                    <Menu.ItemGroup
                      title={
                        <>
                          <FontAwesomeIcon icon={['far', 'palette']} />
                          <Trans>Color theme</Trans>
                        </>
                      }
                    >
                      <Menu.Item
                        onClick={() => setColorTheme('DARK')}
                        icon={<FontAwesomeIcon icon={['far', 'check']} />}
                        className={state.config.colorTheme === 'DARK' ? 'active' : ''}
                      >
                        <Trans>Dark</Trans>
                      </Menu.Item>
                      <Menu.Item
                        onClick={() => setColorTheme('BRIGHT')}
                        icon={<FontAwesomeIcon icon={['far', 'check']} />}
                        className={state.config.colorTheme === 'BRIGHT' ? 'active' : ''}
                      >
                        <Trans>Light</Trans>
                      </Menu.Item>
                    </Menu.ItemGroup>
                    <Menu.Divider />
                    <Menu.ItemGroup
                      title={
                        <>
                          <FontAwesomeIcon icon={['far', 'text-size']} />
                          <Trans>Font size</Trans>
                        </>
                      }
                    >
                      <Menu.Item
                        onClick={() => setFontSize('BIG')}
                        icon={<FontAwesomeIcon icon={['far', 'check']} />}
                        className={state.config.fontSize === 'BIG' ? 'active' : ''}
                      >
                        big
                      </Menu.Item>
                      <Menu.Item
                        onClick={() => setFontSize('DEFAULT')}
                        icon={<FontAwesomeIcon icon={['far', 'check']} />}
                        className={state.config.fontSize === 'DEFAULT' ? 'active' : ''}
                      >
                        default
                      </Menu.Item>
                      <Menu.Item
                        onClick={() => setFontSize('SMALL')}
                        icon={<FontAwesomeIcon icon={['far', 'check']} />}
                        className={state.config.fontSize === 'SMALL' ? 'active' : ''}
                      >
                        small
                      </Menu.Item>
                    </Menu.ItemGroup>
                    <Menu.Divider />
                    <Menu.ItemGroup
                      title={
                        <>
                          <FontAwesomeIcon icon={['far', 'indent']} />
                          <Trans>Line indent</Trans>
                        </>
                      }
                    >
                      <Menu.Item
                        onClick={() => setIndentSize('BIG')}
                        icon={<FontAwesomeIcon icon={['far', 'check']} />}
                        className={state.config.indentSize === 'BIG' ? 'active' : ''}
                      >
                        big
                      </Menu.Item>
                      <Menu.Item
                        onClick={() => setIndentSize('DEFAULT')}
                        icon={<FontAwesomeIcon icon={['far', 'check']} />}
                        className={state.config.indentSize === 'DEFAULT' ? 'active' : ''}
                      >
                        default
                      </Menu.Item>
                      <Menu.Item
                        onClick={() => setIndentSize('SMALL')}
                        icon={<FontAwesomeIcon icon={['far', 'check']} />}
                        className={state.config.indentSize === 'SMALL' ? 'active' : ''}
                      >
                        small
                      </Menu.Item>
                    </Menu.ItemGroup>
                    <Menu.ItemGroup
                      title={
                        <>
                          <FontAwesomeIcon icon={['far', 'columns']} />
                          <Trans>Show mini map</Trans>
                        </>
                      }
                    >
                      <Menu.Item
                        onClick={() => setHasMinimap(true)}
                        icon={<FontAwesomeIcon icon={['far', 'check']} />}
                        className={state.config.hasMiniMap ? 'active' : ''}
                      >
                        <Trans>Show</Trans>
                      </Menu.Item>
                      <Menu.Item
                        onClick={() => setHasMinimap(false)}
                        icon={<FontAwesomeIcon icon={['far', 'check']} />}
                        className={!state.config.hasMiniMap ? 'active' : ''}
                      >
                        <Trans>Hide</Trans>
                      </Menu.Item>
                    </Menu.ItemGroup>
                  </S.StyledConfigNav>
                }
              >
                <Button type="link" icon={['far', 'cog']} title={i18n._('Editor configs')} />
              </Popover>
            </S.HeaderNav>
          </div>
        )}
      </S.Header>

      {!state.isInitialized && (
        <S.StyledLoader>
          <Loader />
        </S.StyledLoader>
      )}
      <S.StyledEditor theme={state.config.colorTheme} isVisible={state.isInitialized}>
        {!isDiffEditor ? (
          <Editor
            height={`${state.editorHeight}px`}
            defaultLanguage={getLanguage()}
            defaultValue={getEditorValue(value)}
            value={getEditorValue(value)}
            onMount={handleEditorDidMount}
            onChange={handleChange}
            theme={state.config.colorTheme === 'DARK' ? 'vs-dark' : 'vs'}
            loading={loading}
            options={{
              readOnly,
              minimap: { enabled: state.config.hasMiniMap },
              tabSize: getLineIndent(),
              fontSize: getFontSize(),
              acceptSuggestionOnCommitCharacter: true,
              acceptSuggestionOnEnter: 'on',
              accessibilitySupport: 'auto',
              autoIndent: 'full',
              automaticLayout: true,
              codeLens: true,
              colorDecorators: true,
              contextmenu: true,
              cursorBlinking: 'blink',
              cursorSmoothCaretAnimation: false,
              cursorStyle: 'line',
              disableLayerHinting: false,
              disableMonospaceOptimizations: false,
              dragAndDrop: false,
              fixedOverflowWidgets: false,
              folding: true,
              foldingStrategy: 'auto',
              fontLigatures: false,
              formatOnPaste: true,
              formatOnType: false,
              hideCursorInOverviewRuler: false,
              //  highlightActiveIndentGuide: true,
              links: true,
              mouseWheelZoom: false,
              multiCursorMergeOverlapping: true,
              multiCursorModifier: 'alt',
              overviewRulerBorder: true,
              overviewRulerLanes: 2,
              quickSuggestions: true,
              quickSuggestionsDelay: 100,
              renderControlCharacters: false,
              renderFinalNewline: true,
              //    renderIndentGuides: true,
              renderLineHighlight: 'all',
              renderWhitespace: 'none',
              revealHorizontalRightPadding: 30,
              roundedSelection: true,
              rulers: [],
              scrollBeyondLastColumn: 5,
              scrollBeyondLastLine: true,
              selectOnLineNumbers: true,
              selectionClipboard: true,
              selectionHighlight: true,
              showFoldingControls: 'mouseover',
              smoothScrolling: false,
              suggestOnTriggerCharacters: true,
              wordBasedSuggestions: true,
              wordSeparators: '~!@#$%^&*()-=+[{]}|;:\'",.<>/?',
              wordWrap: 'off',
              wordWrapBreakAfterCharacters: '\t})]?|&,;',
              wordWrapBreakBeforeCharacters: '{([+',
              wordWrapColumn: 80,
              wrappingIndent: 'none'
            }}
          />
        ) : (
          <DiffEditor
            original={getEditorValue(originalValue)}
            modified={getEditorValue(value)}
            height={`${state.editorHeight}px`}
            language={getLanguage()}
            onMount={handleDiffEditorDidMount}
            //    defaultValue={getDefaultValue()}
            //    value={getDefaultValue()}
            //    onChange={handleChange}
            theme={state.config.colorTheme === 'DARK' ? 'vs-dark' : 'light'}
            loading={loading}
            options={{
              readOnly,
              minimap: { enabled: state.config.hasMiniMap },
              //    tabSize: getLineIndent(),
              fontSize: getFontSize(),
              acceptSuggestionOnCommitCharacter: true,
              acceptSuggestionOnEnter: 'on',
              accessibilitySupport: 'auto',
              autoIndent: 'full',
              automaticLayout: true,
              codeLens: true,
              colorDecorators: true,
              contextmenu: true,
              cursorBlinking: 'blink',
              cursorSmoothCaretAnimation: false,
              cursorStyle: 'line',
              disableLayerHinting: false,
              disableMonospaceOptimizations: false,
              dragAndDrop: false,
              fixedOverflowWidgets: false,
              folding: true,
              foldingStrategy: 'auto',
              fontLigatures: false,
              formatOnPaste: true,
              formatOnType: false,
              hideCursorInOverviewRuler: false,
              //    highlightActiveIndentGuide: true,
              links: true,
              mouseWheelZoom: false,
              multiCursorMergeOverlapping: true,
              multiCursorModifier: 'alt',
              overviewRulerBorder: true,
              overviewRulerLanes: 2,
              quickSuggestions: true,
              quickSuggestionsDelay: 100,
              renderControlCharacters: false,
              renderFinalNewline: true,
              //   renderIndentGuides: true,
              renderLineHighlight: 'all',
              renderWhitespace: 'none',
              revealHorizontalRightPadding: 30,
              roundedSelection: true,
              rulers: [],
              scrollBeyondLastColumn: 5,
              scrollBeyondLastLine: true,
              selectOnLineNumbers: true,
              selectionClipboard: true,
              selectionHighlight: true,
              showFoldingControls: 'mouseover',
              smoothScrolling: false,
              suggestOnTriggerCharacters: true,
              //   wordBasedSuggestions: true,
              wordSeparators: '~!@#$%^&*()-=+[{]}|;:\'",.<>/?',
              wordWrap: 'off',
              wordWrapBreakAfterCharacters: '\t})]?|&,;',
              wordWrapBreakBeforeCharacters: '{([+',
              wordWrapColumn: 80,
              wrappingIndent: 'none'
            }}
          />
        )}
      </S.StyledEditor>
    </S.Wrapper>
  );
};

export default MonacoEditor;
