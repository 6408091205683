import React from 'react';

import { Trans } from '@lingui/macro';

import * as S from './swaggerPageSc';

import AppLayout from 'components/app/components/appLayout';
import DomainPage from 'components/domain/components/domainPage';
import Button from 'components/ui/button';
import routes from 'config/routes/routes';
import useDomainFromCache from 'hooks/useDomainFromCache';
import { openInNewTab } from 'utils';

const SwaggerPage: React.FC = () => {
  const domain = useDomainFromCache();

  const handleGotoSwagger = () => {
    if (domain?.uri) {
      openInNewTab(domain.uri);
    }
  };

  return (
    <AppLayout>
      <DomainPage
        route={routes.domain.queryData.swagger}
        className="page-swagger"
        title={<Trans>Restfull (Swagger)</Trans>}
        content={
          <S.Wrapper>{domain?.uri && <iframe src={domain?.uri} title="swagger" />}</S.Wrapper>
        }
        headerButtons={
          <Button type="primary" icon={['fab', 'html5']} onClick={handleGotoSwagger}>
            <Trans>Open Swagger</Trans>
          </Button>
        }
        noScroll
      />
    </AppLayout>
  );
};

export default SwaggerPage;
