import tw, { styled, css } from 'twin.macro';

import { CollapsibleProps } from './formTitle.types';

type TitleButtonProps = {
  collapsible: CollapsibleProps;
};
export const TitleButton = styled.button<TitleButtonProps>(({ collapsible }) => [
  css`
    ${tw`font-600 text-16 text-primary`};

    outline: none !important;
    font-weight: 600 !important;

    & svg {
      ${tw`mr-8`};

      &.toggle-icon {
        ${tw`transition-all`};
      }
    }

    & .label = {
      ${tw`font-600 text-16 text-primary`};
    }
  `,

  collapsible?.isOpen === false &&
    css`
      & svg.toggle-icon {
        transform: rotate(-90deg);
      }
    `
]);

export const Title = styled.div(() => [
  css`
    ${tw`font-600 text-16 text-primary mb-12`};

    & svg {
      ${tw`mr-8`};
    }
  `
]);
