import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faEyeSlash,
  faEye,
  faGlobe,
  faQuestionCircle,
  faUser,
  faKey,
  faInfoCircle,
  faAngleLeft,
  faAngleRight,
  faImage
} from '@fortawesome/pro-light-svg-icons';

const addIconsFal = (): void => {
  library.add(
    faEyeSlash,
    faEye,
    faGlobe,
    faQuestionCircle,
    faUser,
    faKey,
    faInfoCircle,
    faAngleLeft,
    faAngleRight,
    faImage
  );
};

export default addIconsFal;
