import { produce } from 'immer';
import { clone, merge } from 'lodash';

import { UsersTableActionCreators } from './usersTable.actions';
import { UsersTableActionTypes, UsersTableState } from './usersTable.types';

const initialState: UsersTableState = Object.freeze<UsersTableState>({
  params: {
    pageSize: 20,
    currentPage: 1
  },
  singleView: 'OVERVIEW',
  selectedKey: undefined
});

const usersTableReducer = (
  state: UsersTableState = initialState,
  action: UsersTableActionCreators
): UsersTableState =>
  produce(state, draft => {
    switch (action.type) {
      case UsersTableActionTypes.SET_PARAMS:
        draft.params = merge(clone(draft.params), action.params);

        break;

      case UsersTableActionTypes.SET_SINGLE_VIEW:
        draft.singleView = action.view;

        break;

      case UsersTableActionTypes.RESET:
        draft.singleView = 'OVERVIEW';
        draft.params = {};

        break;

      case UsersTableActionTypes.SET_SELECTED_KEY:
        draft.selectedKey = action.key;

        break;

      default:
        break;
    }
  });

export default usersTableReducer;
