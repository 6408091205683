import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

import * as S from './booleanIconSc';

type BooleanIconProps = {
  value?: boolean;
  className?: string;
};

const BooleanIcon: React.FC<BooleanIconProps> = ({ value, className }) => {
  if (value === undefined) {
    return null;
  }

  return (
    <S.StyledIcon value={value} className={clsx(['boolean-icon', className])}>
      <FontAwesomeIcon icon={value === true ? ['fas', 'check'] : ['fas', 'times']} />
    </S.StyledIcon>
  );
};

export default BooleanIcon;
