import { produce } from 'immer';

import { AppActionCreators } from './app.actions';
import { AppState, AppActionTypes } from './app.types';

import getLanguageConfig from 'config/langauge';

const initialState = Object.freeze<AppState>({
  isSidebarInitialised: false,
  language: getLanguageConfig().defaultLanguage
});

const appReducer = (state: AppState = initialState, action: AppActionCreators): AppState =>
  produce(state, draft => {
    switch (action.type) {
      case AppActionTypes.SET_SIDEBAR_INITIALISED:
        draft.isSidebarInitialised = action.initialised;

        break;

      case AppActionTypes.SET_LANGUAGE:
        draft.language = action.language;

        break;

      case AppActionTypes.RESET:
        draft.isSidebarInitialised = false;
        draft.language = getLanguageConfig().defaultLanguage;

        break;

      default:
        break;
    }
  });

export default appReducer;
