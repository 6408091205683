import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import {
  CollectionEditor,
  CollectionEditorDataItem,
  FormItem,
  useFormContext
} from '@phoenix-systems/react-form';
import { getRoute } from '@phoenix-systems/react-router';
import { Checkbox, Form, Input, Select, Tag, Transfer } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { TransferItem } from 'antd/lib/transfer';
import { AxiosError } from 'axios';
import { assign } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import { useImmer } from 'use-immer';

import * as S from './domainFormSc';
import SchemaFormDialog from './schemaFormDialog';
import ShardFormDialog from './shardFormDialog';

import * as F from 'components/_styled/formSc';
import useContainer from 'components/app/components/containerProvider/useContainer';
import DocsTooltip from 'components/docs/components/docsTooltip';
import TooltipLabel from 'components/docs/components/docsTooltip/tooltipLabel';
import Button from 'components/ui/button';
import ErrorInfo from 'components/ui/errorInfo';
import FormContainer from 'components/ui/formContainer';
import Loader from 'components/ui/loader';
import LoaderMessage from 'components/ui/loader/loaderMessage';
import NoContent from 'components/ui/noContent';
import collectionEditorLocales from 'config/langauge/collectionEditor.locales';
import routes from 'config/routes/routes';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import useDomainFromCache from 'hooks/useDomainFromCache';
import useDomainName from 'hooks/useDomainName';
import useNotification from 'hooks/useNotification';
import {
  api_createDomain,
  api_updateDomain,
  Domain,
  Shard,
  useDomains,
  useGenerateApiKey
} from 'services/api/domain/domain';
import { DocId } from 'services/api/domain/hint';
import { useMyself, useUsers } from 'services/api/domain/user';
import history from 'services/history';
import { DEFAULT_ADMINS } from 'services/userPermissions/userPermissions.config';
import { log } from 'utils';

const { TextArea } = Input;

type DomainFormState = {
  users: TransferItem[];
};

const DomainForm: React.FC = () => {
  const [state, setState] = useImmer<DomainFormState>({
    users: []
  });

  const data = useDomainFromCache();
  const domainName = useDomainName();
  const [form] = Form.useForm<Domain>();
  const [formState, formActions] = useFormContext(form, data, true);
  const queryClient = useQueryClient();
  const { formContainer } = useContainer();
  const [domainNames, setDomainNames] = useState<string[]>([]);
  const [addNotification] = useNotification();
  const copy = useCopyToClipboard();
  const userQuery = useUsers();
  const myselfQuery = useMyself({ enabled: false });

  const keyQuery = useGenerateApiKey({
    enabled: false,
    onSuccess: key => {
      formActions.setChanged(true);
      form.setFieldsValue({ ...form.getFieldsValue(), apiKey: key });
    }
  });
  const { current: keyQueryRef } = useRef(keyQuery);

  const domainsQuery = useDomains({
    onSuccess: res => {
      const names: string[] = [];
      res.forEach(domain => {
        if (domain.id !== data?.id) {
          names.push(domain.domainName);
        }
      });
      setDomainNames(names);
    }
  });

  const updateMutation = useMutation<Domain, AxiosError, Domain>(
    payload => api_updateDomain(payload),
    {
      onSuccess: resData => {
        if (resData.domainName !== domainName) {
          history.replace(getRoute(routes.domain.overview, resData.domainName));
        } else {
          queryClient.setQueryData(['domain', domainName], resData);
        }
        queryClient.refetchQueries('domains');
        updateMutation.reset();
        formActions.reset(resData, true);

        addNotification({
          type: 'success',
          message: i18n._(t`Domain "${form.getFieldValue('domainName')}" successfully updated.`)
        });
      },
      onError: () => {
        addNotification({
          type: 'error',
          message: t`Failed to update domain "${form.getFieldValue('domainName')}".`
        });
      }
    }
  );

  const createMutation = useMutation((payload: Domain) => api_createDomain(payload), {
    onSuccess: resData => {
      queryClient.refetchQueries('domains');
      queryClient.setQueryData(['domain', resData.domainName], resData);
      updateMutation.reset();
      formActions.reset(resData);
      addNotification({
        type: 'success',
        message: i18n._(t`Domain "${form.getFieldValue('domainName')}" successfully created.`)
      });

      history.push(getRoute(routes.domain.overview, resData.domainName));
    },
    onError: () => {
      addNotification({
        type: 'error',
        message: t`Failed to create domain "${form.getFieldValue('domainName')}".`
      });
    }
  });

  const handleCopyId = () => {
    copy(form.getFieldValue('id'));
  };

  const validateDomainName = (rule: RuleObject, value: string) =>
    new Promise((resolve, reject) => {
      if (domainNames.includes(value)) {
        reject(new Error('domain name already in use'));
      } else {
        resolve(value);
      }
    });

  const handleSave = () => {
    form
      .validateFields()
      .then(formData => {
        const newData = assign({}, data, formData);

        if (newData.admins) {
          if (!newData.admins.includes('superadmin')) {
            newData.admins.push('superadmin');
          }
        } else {
          newData.admins = [...DEFAULT_ADMINS];
        }

        if (myselfQuery.data && !newData.admins.includes(myselfQuery.data.username)) {
          newData.admins.push(myselfQuery.data.username);
        }

        if (!data) {
          createMutation.mutate(newData);

          return;
        }

        updateMutation.mutate(newData);
      })
      .catch(validationErrors => {
        log(validationErrors);
      });
  };

  const isDefaultSchema = useCallback(
    (name: string) => formState.data?.defaultSchema === name,
    [formState]
  );

  const handleReset = () => {
    updateMutation.reset();
    formActions.reset();
  };

  const onSelectChange = (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
    form.setFieldsValue({ ...form.getFieldsValue(), admins: targetSelectedKeys });
  };

  useEffect(() => {
    if (!data) {
      keyQueryRef.refetch();
    }
  }, [data, keyQueryRef]);

  useEffect(() => {
    if (userQuery.data && data) {
      setState(draft => {
        draft.users = userQuery.data.map(item => ({
          key: item.username,
          title: item.username,
          disabled: DEFAULT_ADMINS.includes(item.username),
          description: item.username,
          choosen: data?.admins?.includes(item.username)
        }));
      });
    }
  }, [userQuery.data, data, setState]);

  if (domainsQuery.isLoading) {
    return <Loader fullHeight />;
  }

  return (
    <FormContainer
      title={
        data ? (
          <>
            <FontAwesomeIcon icon={['fas', 'cog']} />
            <Trans>Configuration</Trans>
            <DocsTooltip docId={DocId.DOMAIN_OVERVIEW} />
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={['fas', 'globe']} />
            <Trans>Create a new domain</Trans>
            <DocsTooltip docId={DocId.DOMAIN_DOMAIN_NAME} />
          </>
        )
      }
      messages={
        <>
          {updateMutation.isLoading && (
            <LoaderMessage showAfterTime={400}>
              <Trans>Update domain in progress...</Trans>
            </LoaderMessage>
          )}
          {updateMutation.isError && (
            <ErrorInfo
              error={updateMutation.error}
              message={<Trans>Failed to update domain.</Trans>}
            />
          )}
          {updateMutation.isSuccess && <div>is success</div>}
        </>
      }
      buttons={
        <>
          <Button
            action="reset"
            formState={formState}
            mutation={updateMutation}
            onClick={handleReset}
          />
          <Button
            action="save"
            formState={formState}
            mutation={updateMutation}
            onClick={handleSave}
          />
        </>
      }
    >
      <F.StyledForm
        $labelWidth={145}
        name="domain-form"
        form={form}
        initialValues={data ? { ...data, admins: data?.admins || [...DEFAULT_ADMINS] } : undefined}
        layout="horizontal"
        validateTrigger="onChange"
        onValuesChange={formActions.onValuesChange}
        onFieldsChange={formActions.onFieldsChange}
        colon={false}
      >
        {data && (
          <FormItem
            label={<TooltipLabel docId={DocId.DOMAIN_DOMAIN_ID} label="Id" />}
            name="id"
            rules={[{ required: true }]}
            registerField={formActions.registerField}
          >
            <Input
              addonAfter={<Button icon={['fas', 'copy']} type="link" onClick={handleCopyId} />}
              readOnly
            />
          </FormItem>
        )}
        <FormItem
          label={
            <TooltipLabel docId={DocId.DOMAIN_DOMAIN_NAME} label={<Trans>Domain name</Trans>} />
          }
          messageVariables={{ name: i18n._('Domain name') }}
          validateFirst
          name="domainName"
          rules={[
            { required: true },
            {
              pattern: /^[A-Za-z0-9\-_]+$/,
              message: i18n._("Only allowed alphanumeric characters including '-' and '_'.")
            },
            {
              validator: validateDomainName,
              message: i18n._(
                t`A domain with name "${form.getFieldValue('domainName')}" already exists.`
              )
            }
          ]}
          registerField={formActions.registerField}
        >
          <Input />
        </FormItem>

        {data && (
          <>
            <FormItem
              label={<TooltipLabel docId={DocId.DOMAIN_API_KEY} label={<Trans>Api Key</Trans>} />}
              messageVariables={{ name: i18n._('Api Key') }}
              validateFirst
              name="apiKey"
              rules={[{ required: true }]}
              registerField={formActions.registerField}
            >
              <Input
                addonAfter={
                  <>
                    <Button
                      type="link"
                      icon={['fas', 'sync']}
                      title={i18n._(t`Generate new api key`)}
                      loading={keyQuery.isLoading}
                      disabled={keyQuery.isLoading}
                      onClick={() => {
                        keyQuery.refetch();
                      }}
                    />
                    <span className="addon-divider" />
                    <Button
                      title={i18n._(t`Copy api key`)}
                      type="link"
                      icon={['fas', 'copy']}
                      onClick={() => copy(data?.id)}
                    />
                  </>
                }
              />
            </FormItem>
            <FormItem
              label={
                <TooltipLabel docId={DocId.DOMAIN_DESCRIPTION} label={<Trans>Description</Trans>} />
              }
              name="description"
              registerField={formActions.registerField}
            >
              <TextArea rows={4} autoSize />
            </FormItem>
            <FormItem
              label={
                <TooltipLabel docId={DocId.DOMAIN_DELETABLE} label={<Trans>Deletable</Trans>} />
              }
              name="deletable"
              valuePropName="checked"
              registerField={formActions.registerField}
            >
              <Checkbox />
            </FormItem>
            <FormItem
              label={<TooltipLabel docId={DocId.DOMAIN_SCHEMA} label={<Trans>Schemes</Trans>} />}
              name="schemes"
              registerField={formActions.registerField}
              messageVariables={{ name: i18n._('Schemes') }}
              rules={[{ required: true }]}
            >
              <CollectionEditor
                renderDialog={params => (
                  <SchemaFormDialog {...params} schemes={formState.data?.schemes || []} />
                )}
                locales={{ ...collectionEditorLocales, 'Add item': i18n._('Add schema') }}
                onInitData={_item => {
                  const item = _item;

                  if (isDefaultSchema(item.value)) {
                    item.actions = { edit: { disabled: true }, remove: { disabled: true } };
                  }

                  return item;
                }}
                renderItem={(item: CollectionEditorDataItem<string>) => (
                  <S.CollectionTag isDefault={isDefaultSchema(item.value)}>
                    <Tag>{item.value}</Tag>
                    {isDefaultSchema(item.value) && (
                      <span className="default-label">[default]</span>
                    )}
                  </S.CollectionTag>
                )}
                empty={<NoContent descripion={<Trans>No schemes defined</Trans>} />}
              />
            </FormItem>

            <FormItem
              label={
                <TooltipLabel docId={DocId.DOMAIN_SCHEMA} label={<Trans>Default schema</Trans>} />
              }
              rules={[{ required: true }]}
              name="defaultSchema"
              messageVariables={{ name: i18n._('Default schema') }}
              registerField={formActions.registerField}
            >
              <Select getPopupContainer={() => formContainer}>
                {formState.data?.schemes?.map((schema: string) => (
                  <Select.Option value={schema} key={schema}>
                    {schema}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
            <FormItem
              label={<TooltipLabel docId={DocId.DOMAIN_URI} label="URI" />}
              name="uri"
              rules={[
                {
                  pattern:
                    // eslint-disable-next-line no-useless-escape
                    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
                  message: i18n._('Not a valid url')
                }
              ]}
              registerField={formActions.registerField}
            >
              <Input />
            </FormItem>

            <FormItem
              label={<TooltipLabel docId={DocId.DOMAIN_SHARD} label={<Trans>Shards</Trans>} />}
              name="shards"
              registerField={formActions.registerField}
            >
              <CollectionEditor
                renderDialog={params => (
                  <ShardFormDialog {...params} shards={formState.data?.shards || []} />
                )}
                locales={{ ...collectionEditorLocales, 'Add item': i18n._('Add shard') }}
                renderItem={(item: CollectionEditorDataItem<Shard>) => (
                  <S.CollectionTag isDefault={form.getFieldValue('defaultSchema') === item.value}>
                    <Tag>{item.value.name}</Tag>
                  </S.CollectionTag>
                )}
                empty={<NoContent descripion={<Trans>No shards defined</Trans>} />}
              />
            </FormItem>

            <FormItem
              label={
                <TooltipLabel
                  docId={DocId.DOMAIN_ADMINISTRATOR}
                  label={<Trans>Administrators</Trans>}
                />
              }
              className="transfer"
              name="admins"
              registerField={formActions.registerField}
            >
              <Transfer
                dataSource={state.users}
                titles={[i18n._(t`Unauthorized Users`), i18n._(t`Authorized Users`)]}
                targetKeys={formState.data?.admins}
                //  onChange={onChange}
                onSelectChange={onSelectChange}
                // onScroll={onScroll}
                // selectedKeys={data?.admins}
                locale={{
                  itemUnit: i18n._('User'),
                  itemsUnit: i18n._('Users'),
                  selectInvert: i18n._('Invert selection'),
                  selectAll: i18n._('Select all users')
                }}
                render={item => (
                  <div>
                    <FontAwesomeIcon
                      icon={
                        formState.data?.admins &&
                        item.key &&
                        formState.data.admins.includes(item.key)
                          ? ['fas', 'user']
                          : ['fas', 'user-alt-slash']
                      }
                      className="item-icon"
                    />
                    <span>{item.title || ''}</span>
                  </div>
                )}
              />
            </FormItem>
          </>
        )}
      </F.StyledForm>
    </FormContainer>
  );
};

export default DomainForm;
