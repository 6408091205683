import React, { forwardRef, useImperativeHandle } from 'react';

import { Popover } from 'antd';
import clsx from 'clsx';
import { useImmer } from 'use-immer';

import useContainer from '../containerProvider/useContainer';

import {
  ContextNavigationProps,
  ContextNavigationRef,
  ContextNavigationState
} from './contextNavigation.types';
import * as S from './contextNavigationSc';

const ContextNavigationRenderer: React.ForwardRefRenderFunction<
  ContextNavigationRef,
  ContextNavigationProps
> = ({ placement, className, trigger, triggerHandler, children, offset, title }, ref) => {
  const [state, setState] = useImmer<ContextNavigationState>({ isOpen: false });
  const { contextNavContainer } = useContainer();

  const handleVisibleChange = (open: boolean) => {
    setState(draft => {
      draft.isOpen = open;
    });
  };

  useImperativeHandle(ref, () => ({
    open: () =>
      setState(draft => {
        draft.isOpen = true;
      }),
    close: () =>
      setState(draft => {
        draft.isOpen = false;
      })
  }));

  return (
    <>
      <Popover
        title={title}
        placement={placement}
        getPopupContainer={() => contextNavContainer}
        trigger={triggerHandler || 'click'}
        overlayClassName={clsx(['context-nav', className])}
        visible={state.isOpen}
        onVisibleChange={handleVisibleChange}
        align={{ offset: offset || undefined }}
        content={
          <S.StyledMenu
            activeKey="never"
            getPopupContainer={() => contextNavContainer}
            onClick={() =>
              setState(draft => {
                draft.isOpen = false;
              })
            }
          >
            {children}
          </S.StyledMenu>
        }
      >
        {trigger}
      </Popover>
    </>
  );
};

const ContextNavigation = forwardRef(ContextNavigationRenderer);
ContextNavigation.displayName = 'ContextNavigation';
export default ContextNavigation;
