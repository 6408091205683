import React from 'react';

import * as S from './domainDocumentHistorySc';

import { ParsedObjectHistoryDiff } from 'services/api/domain/objectHistory';

type DocumentHistoryJsonDiffProps = {
  data?: ParsedObjectHistoryDiff[];
  size: 'default' | 'small';
  isTable?: boolean;
};

const DocumentHistoryJsonDiff: React.FC<DocumentHistoryJsonDiffProps> = ({
  data,
  size,
  isTable
}) => (
  <S.DiffSummary size={size} isTable={isTable}>
    {data?.map(item => (
      <li key={`${item.op}_${item.path}_${item.value}`}>
        <p>
          <span>op.:</span>
          {item.op}
        </p>
        <p>
          <span>field:</span>
          {item.path.replace('/', '')}
        </p>
        <p>
          <span>value:</span>
          {item.value}
        </p>
      </li>
    ))}
  </S.DiffSummary>
);

export default DocumentHistoryJsonDiff;
