import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import Modal from 'antd/lib/modal/Modal';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import ErrorInfo from 'components/ui/errorInfo';
import useDefaultModalProps from 'hooks/useDefaultModalProps';
import useNotification from 'hooks/useNotification';
import {
  api_updateTrigger,
  Trigger,
  TriggerDialogActivationMode
} from 'services/api/domain/trigger';
import { st_triggersTable_setSelectedKey } from 'services/store/triggersTable/triggersTable.actions';

type ActivateTriggerDialogProps = {
  isOpen: boolean;
  data?: Trigger;
  close: () => void;
  mode: TriggerDialogActivationMode;
};

const ActivateTriggerDialog: React.FC<ActivateTriggerDialogProps> = ({
  data,
  isOpen,
  mode,
  close
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [addNotification] = useNotification();
  const modalProps = useDefaultModalProps();

  const updateMutation = useMutation<Trigger, AxiosError, Trigger>(
    payload => api_updateTrigger(payload),
    {
      onSuccess: () => {
        addNotification({
          type: 'success',
          message:
            mode === 'ACTIVATE'
              ? i18n._(t`Successfully activated trigger "${data?.name}".`)
              : i18n._(t`Successfully deactivated trigger "${data?.name}".`)
        });

        queryClient.refetchQueries(['triggers']);
        dispatch(st_triggersTable_setSelectedKey(data?.name));
        close();
      },
      onError: () => {
        addNotification({
          type: 'error',
          message:
            mode === 'ACTIVATE'
              ? i18n._(t`Failed to activate trigger "${data?.name}".`)
              : i18n._(t`Failed to deactivate trigger "${data?.name}".`)
        });
      }
    }
  );

  const handleUpdate = () => {
    if (data) {
      updateMutation.mutate({ ...data, active: mode === 'ACTIVATE' });
    }
  };

  if (!data) {
    return null;
  }

  return (
    <Modal
      {...modalProps}
      visible={isOpen}
      title={
        <>
          <FontAwesomeIcon icon={['fas', mode === 'ACTIVATE' ? 'play' : 'ban']} />
          {mode === 'ACTIVATE' ? (
            <Trans>Activate trigger {data.name}</Trans>
          ) : (
            <Trans>Deactivate trigger {data.name}</Trans>
          )}
        </>
      }
      okText={
        mode === 'ACTIVATE' ? <Trans>Activate trigger</Trans> : <Trans>Dectivate trigger</Trans>
      }
      okButtonProps={{ loading: updateMutation.isLoading, disabled: updateMutation.isLoading }}
      cancelButtonProps={{ disabled: updateMutation.isLoading }}
      onCancel={() => close()}
      onOk={handleUpdate}
    >
      <p>
        {mode === 'ACTIVATE' ? (
          <Trans>
            Do you want to activate the trigger <b>{data.name}</b> and the attached task{' '}
            <b>{data.taskName}</b>?.
          </Trans>
        ) : (
          <Trans>
            Do you want to deactivate the trigger <b>{data.name}</b> and the attached task{' '}
            <b>{data.taskName}</b>?.
          </Trans>
        )}
      </p>
      {updateMutation.error && (
        <ErrorInfo
          error={updateMutation.error}
          message={
            mode === 'ACTIVATE' ? (
              <Trans>Failed to activate trigger.</Trans>
            ) : (
              <Trans>Failed to deactivate trigger.</Trans>
            )
          }
        />
      )}
    </Modal>
  );
};

export default ActivateTriggerDialog;
