import { createSelector } from 'reselect';

import { DomainDiagramState, EntityNodeFieldType } from './domainDiagram.types';

import { DiagramElement, EntityEdge, EntityNode } from 'services/api/domain/domainDiagram';

export const domainDiagramState = (state: {
  domainDiagram: DomainDiagramState;
}): DomainDiagramState => state.domainDiagram;

export const st_domainDiagram_getElements = createSelector(
  [domainDiagramState],
  (domainDiagram): DiagramElement[] => domainDiagram.elements
);

export const st_domainDiagram_getNodes = createSelector(
  [domainDiagramState],
  (domainDiagram): EntityNode[] => domainDiagram.nodes
);

export const st_domainDiagram_getEdges = createSelector(
  [domainDiagramState],
  (domainDiagram): EntityEdge[] => domainDiagram.edges
);

export const st_domainDiagram_getHasChanged = createSelector(
  [domainDiagramState],
  (domainDiagram): boolean => domainDiagram.hasChanged
);

export const st_domainDiagram_getContextNavIsOpen = createSelector(
  [domainDiagramState],
  (domainDiagram): boolean => domainDiagram.contextNavIsOpen
);

export const st_domainDiagram_getDialogIsOpen = createSelector(
  [domainDiagramState],
  (domainDiagram): boolean => domainDiagram.dialogIsOpen
);

export const st_domainDiagram_getFieldTypes = createSelector(
  [domainDiagramState],
  (domainDiagram): EntityNodeFieldType[] => domainDiagram.fieldTypes
);

export const st_domainDiagram_getActiveEdge = createSelector(
  [domainDiagramState],
  (domainDiagram): EntityEdge | undefined => domainDiagram.activeEdge
);

export const st_domainDiagram_getContextEdge = createSelector(
  [domainDiagramState],
  (domainDiagram): EntityEdge | undefined => domainDiagram.contextEdge
);

export const st_domainDiagram_getContextAnchor = createSelector(
  [domainDiagramState],
  (domainDiagram): { x: number; y: number } | undefined => domainDiagram.contextAnchor
);

export const st_domainDiagram_getActiveHandles = createSelector(
  [domainDiagramState],
  (domainDiagram): string[] => domainDiagram.activeHandles
);

export const st_domainDiagram_getActiveNode = createSelector(
  [domainDiagramState],
  (domainDiagram): string | undefined => domainDiagram.activeNode
);
