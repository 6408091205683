import tw, { styled, css } from 'twin.macro';

export const Field = styled.li(() => [
  css`
    ${tw`text-12 h-36 font-700 m-0 p-0 py-8 px-12 border-b border-ant-border-gray relative hover:bg-light-gray transition-all duration-200`};

    &:last-of-type {
      ${tw`border-none `};
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  `
]);

export const Label = styled.div(() => [
  css`
    ${tw`flex flex-row items-center h-full `};

    & div {
      ${tw`pl-6 ml-auto flex flex-row items-center`};

      span {
        ${tw`text-gray-500 font-mono text-11 inline-block  `};
        line-height: 16px;
        transform: translateY(2px);
      }

      svg {
        ${tw`ml-6 text-gray-600`};
        margin-right: -1px;
      }

      button {
        margin: 0 !important;
        transform: translateY(-1px);

        svg {
          ${tw`text-yellow-600 text-11`};
        }
      }
    }
  `
]);
