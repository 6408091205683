import React, { useCallback, useEffect, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { Table } from '@phoenix-systems/react-table';
import { CustomColumnType, TableRef } from '@phoenix-systems/react-table/dist/types/types';
import Highlighter from 'react-highlight-words';
import { useDispatch, useSelector } from 'react-redux';
import { useImmer } from 'use-immer';

import CsvImportTableSingle from './csvImportTableSingle';

import * as T from 'components/_styled/tableSc';
import CsvImportActions from 'components/domain/actions/csvImportActions';
import BooleanIcon from 'components/ui/booleanIcon';
import Loader from 'components/ui/loader';
import tableLocales from 'config/langauge/table.locales';
import routes from 'config/routes/routes';
import useDomainName from 'hooks/useDomainName';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';
import useSchemaName from 'hooks/useSchemaName';
import { CsvImport, useCsvImports } from 'services/api/domain/csvImport';
import { DbImport } from 'services/api/domain/dataImport';
import { useTriggers } from 'services/api/domain/trigger';
import {
  st_csvImportsTable_setActiveKey,
  st_csvImportsTable_setParams
} from 'services/store/csvImportsTable/csvImportsTable.actions';
import {
  st_csvImportsTable_getActiveKey,
  st_csvImportsTable_getParams
} from 'services/store/csvImportsTable/csvImportsTable.selectors';
import { DB_IMPORT_LIST_DEFAULT_PAGE_SIZE } from 'services/store/dbImportsTable/dbImportsTable.types';

type CsvImportTableState = {
  data: CsvImport[];
  isParamsInitialized: boolean;
};

const CsvImportTable: React.FC = () => {
  const domainName = useDomainName();
  const tableRef = useRef<TableRef>(null);
  const schemaName = useSchemaName();
  const listQuery = useCsvImports({ domainName, schemaName }, { retry: false });
  const dispatch = useDispatch();
  const activeKey = useSelector(st_csvImportsTable_getActiveKey);
  const params = useSelector(st_csvImportsTable_getParams);
  const [state, setState] = useImmer<CsvImportTableState>({
    data: [],
    isParamsInitialized: false
  });
  const goto = useGoToDomainRoute();
  const triggerQuery = useTriggers({ domainName });

  const handleSelectChange = (selectedRowKeys: React.Key[]) => {
    dispatch(st_csvImportsTable_setActiveKey(selectedRowKeys[0] as string));
  };

  const handleGotoFtpConnection = useCallback(
    (name: string) => {
      goto(routes.domain.connections.ftp.single, { path: name });
    },
    [goto]
  );

  const handleRemoveSelectedItem = () => {
    dispatch(st_csvImportsTable_setActiveKey(undefined));
  };

  const handleGotoCsvImport = useCallback(
    (importName: string) => {
      dispatch(st_csvImportsTable_setActiveKey(importName));
      goto(routes.domain.dataManagement.importFromCsv.single, { path: importName });
    },
    [dispatch, goto]
  );

  const getColumns = useCallback(() => {
    const columns: CustomColumnType<CsvImport>[] = [
      {
        title: i18n._('Name'),
        dataIndex: 'name',
        key: 'name',
        className: 'bold',
        sorter: (a, b) => a.name.localeCompare(b.name),
        // eslint-disable-next-line react/display-name
        render: (value: string, record) => (
          <>
            <Highlighter
              searchWords={params.searchText ? [params.searchText] : []}
              textToHighlight={value}
              autoEscape
            />
            <T.EditBtn
              className="edit-btn"
              type="link"
              icon={
                <FontAwesomeIcon
                  icon={['fas', 'pen']}
                  onClick={() => handleGotoCsvImport(record.name)}
                />
              }
            />
          </>
        )
      },
      {
        title: i18n._('Entity name'),
        dataIndex: 'entityName',
        key: 'entityName',
        ellipsis: true,
        sorter: (a, b) => a.entityName.localeCompare(b.entityName)
      },
      {
        title: i18n._('File name'),
        dataIndex: 'fileName',
        key: 'fileName',
        ellipsis: true,
        sorter: (a, b) => a.fileName.localeCompare(b.fileName)
      },
      {
        title: i18n._('Import strategy'),
        dataIndex: 'updatecreate',
        key: 'updatecreate',
        // eslint-disable-next-line react/display-name
        render: value => (value ? 'Update' : 'Create'),
        sorter: (a, b) => a.updatecreate.toString().localeCompare(b.updatecreate.toString()),
        filters: [
          {
            text: 'Update',
            value: true
          },
          {
            text: 'Create',
            value: false
          }
        ]
      },
      {
        title: i18n._('Ftp connection'),
        dataIndex: 'ftpConnection',
        key: 'ftpConnection',
        ellipsis: true,
        render: value => {
          if (!value?.name) {
            return <BooleanIcon value={false} />;
          }

          return (
            <>
              {value.name}
              <T.EditBtn
                className="edit-btn"
                type="link"
                icon={
                  <FontAwesomeIcon
                    icon={['fas', 'pen']}
                    onClick={() => handleGotoFtpConnection(value.name)}
                  />
                }
              />
            </>
          );
        },
        sorter: (a, b) => a.ftpConnection?.name.localeCompare(b.ftpConnection?.name)
      },
      {
        title: i18n._('Mappers'),
        dataIndex: 'columnEntityMapping',
        key: 'columnEntityMapping',
        render: value => value?.length || 0,
        width: 90
      },
      {
        title: i18n._('Triggers'),
        key: 'triggers',
        render: (value, record) =>
          triggerQuery.data?.filter(t => t.taskName === record.name).length,
        width: 90
      },
      {
        title: <FontAwesomeIcon icon={['fas', 'cog']} className="setting-icon" />,
        key: 'actions',
        width: 50,
        className: 'actions',
        // eslint-disable-next-line react/display-name
        render: (value, record) => (
          <CsvImportActions
            data={record}
            popoverPlacement="leftTop"
            className="edit-btn"
            mode="ICON_BUTTON"
          />
        ),
        hiddenInConfig: true
      }
    ];

    return columns;
  }, [params, handleGotoCsvImport, triggerQuery.data, handleGotoFtpConnection]);

  useEffect(() => {
    if (typeof params.searchText === 'string' && params.searchText !== '') {
      const matches = listQuery.data?.filter(
        d => d.name.search(new RegExp(params?.searchText || '', 'gi')) !== -1
      );
      setState(draft => {
        draft.data = matches || [];
      });
    } else {
      setState(draft => {
        draft.data = listQuery.data || [];
      });
    }
  }, [params, listQuery.data, setState]);

  useEffect(() => {
    if (params && !state.isParamsInitialized && tableRef.current) {
      tableRef.current.setQueryParams(params);
      setState(draft => {
        draft.isParamsInitialized = true;
      });
    }
  }, [params, state.isParamsInitialized, tableRef, setState]);

  return (
    <T.StyledTable className="db-import">
      <div>
        <Table
          ref={tableRef}
          table={{
            dataSource: state.data,
            loading: listQuery.isLoading || triggerQuery.isLoading,
            size: 'small',
            rowKey: 'name',
            columns: getColumns(),
            rowSelection: {
              type: 'radio',
              onChange: handleSelectChange,
              selectedRowKeys: activeKey ? [activeKey] : []
            },
            onRow: (record: DbImport) => ({
              onClick: e => {
                const isEditBtn = (e.target as HTMLElement).closest('.edit-btn') !== null;

                if (!isEditBtn) {
                  dispatch(st_csvImportsTable_setActiveKey(record.name));
                }
              },
              onDoubleClick: () => {
                handleGotoCsvImport(record.name);
              }
            }),
            pagination: {
              pageSizeOptions: ['10', '20', '50'],
              pageSize: params.pagination?.pageSize || DB_IMPORT_LIST_DEFAULT_PAGE_SIZE,
              current: params.pagination?.current || 1,
              showSizeChanger: true
            }
          }}
          defaultTableContainerHeight="45%"
          fillHeight
          layout="single-split"
          topNavElements={{
            search: { defaultValue: params.searchText },
            totalCount: {
              icon: <FontAwesomeIcon icon={['fas', 'file-import']} />,
              loader: <Loader size={12} />
            },
            reload: {
              onChange: () => {
                listQuery.refetch();
              }
            },
            resetParams: true
          }}
          activeFilters={{ enabled: true, placement: 'default' }}
          onRemoveSelectedItem={handleRemoveSelectedItem}
          onQueryParamsChange={currentParams => {
            dispatch(st_csvImportsTable_setParams(currentParams));
          }}
          locales={{
            '1 item selected': i18n._('1 CSV Import selected'),
            'items selected': i18n._('CSV Imports selected'),
            'Nothing selected': i18n._('No CSV Import selected'),
            ...tableLocales
          }}
          renderSingle={(selectedItems: CsvImport[]) => (
            <>
              <CsvImportTableSingle data={selectedItems[0]} />
            </>
          )}
        />
      </div>
    </T.StyledTable>
  );
};

export default CsvImportTable;
