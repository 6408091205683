/**
 *  Login
 */
const loginBase = '/login';
const loginRoutes = {
  root: loginBase
};

/**
 *  Error
 */
const errorBase = '/error';
const errorRoutes = {
  root: errorBase,
  notFound: `${errorBase}/404`,
  serverError: `${errorBase}/500`
};

/**
 *  Domain
 */
export const domainBase = '/domain';

const domainBasePath = `${domainBase}/:domainName`;
const domainDataManagementBase = 'data-management';

const domainDataManagement = {
  root: `${domainBasePath}/${domainDataManagementBase}`,
  domainAdministration: `${domainBasePath}/${domainDataManagementBase}/domain-administration`,
  importFromDbOld: `${domainBasePath}/:${domainDataManagementBase}/import-from-db-old`,
  importFromDb: {
    root: `${domainBasePath}/${domainDataManagementBase}/import-from-db/:schemaName`,
    list: `${domainBasePath}/${domainDataManagementBase}/import-from-db/:schemaName/list`,
    create: `${domainBasePath}/${domainDataManagementBase}/import-from-db/:schemaName/new`,
    single: `${domainBasePath}/${domainDataManagementBase}/import-from-db/:schemaName/single/:dbImportName`
  },
  importFromCsv: {
    root: `${domainBasePath}/${domainDataManagementBase}/import-from-csv/:schemaName`,
    list: `${domainBasePath}/${domainDataManagementBase}/import-from-csv/:schemaName/list`,
    create: `${domainBasePath}/${domainDataManagementBase}/import-from-csv/:schemaName/new`,
    single: `${domainBasePath}/${domainDataManagementBase}/import-from-csv/:schemaName/single/:csvImportName`
  },
  jsonImportExport: `${domainBasePath}/${domainDataManagementBase}/json-import-export/:schemaName`,
  testDataGenerator: `${domainBasePath}/${domainDataManagementBase}/data-generator/:schemaName`
};

const domainDataDesignerBase = 'data-designer';
const domainDataDesigner = {
  root: `${domainBasePath}/${domainDataDesignerBase}`,
  entityDocumentDiagram: `${domainBasePath}/${domainDataDesignerBase}/entity-document-diagram/:schemaName`,
  captureStructure: `${domainBasePath}/${domainDataDesignerBase}/capture-structure/:schemaName`
};

const domainQueryDataBase = 'query-data';
const domainQueryData = {
  root: `${domainBasePath}/${domainQueryDataBase}`,
  entityDocument: {
    root: `${domainBasePath}/${domainQueryDataBase}/entity-document/:schemaName`,
    rootEntity: `${domainBasePath}/${domainQueryDataBase}/entity-document/:schemaName/:entityName`,
    list: `${domainBasePath}/${domainQueryDataBase}/entity-document/:schemaName/list`,
    single: `${domainBasePath}/${domainQueryDataBase}/entity-document/:schemaName/single/:objectId`
  },
  documentHistory: `${domainBasePath}/${domainQueryDataBase}/document-history/:schemaName`,
  metaData: `${domainBasePath}/${domainQueryDataBase}/meta-data/:schemaName`,
  metaDataEntity: `${domainBasePath}/${domainQueryDataBase}/meta-data/:schemaName/:entityName`,
  metaDataCreate: `${domainBasePath}/${domainQueryDataBase}/meta-data/new/:schemaName`,
  swagger: `${domainBasePath}/${domainQueryDataBase}/swagger`
};

const domainSchedulerBase = 'scheduler';
const domainScheduler = {
  root: `${domainBasePath}/${domainSchedulerBase}`,
  list: `${domainBasePath}/${domainSchedulerBase}/triggers`,
  calendar: `${domainBasePath}/${domainSchedulerBase}/calendar`,
  create: `${domainBasePath}/${domainSchedulerBase}/new`,
  single: `${domainBasePath}/${domainSchedulerBase}/single/:triggerName`
};

const domainConnectionsBase = 'connections';
const domainConnections = {
  root: `${domainBasePath}/${domainConnectionsBase}`,
  ftp: {
    list: `${domainBasePath}/${domainConnectionsBase}/ftp/:schemaName/list`,
    create: `${domainBasePath}/${domainConnectionsBase}/ftp/:schemaName/new`,
    single: `${domainBasePath}/${domainConnectionsBase}/ftp/:schemaName/single/:ftpConnectionName`
  }
};

const domainRoutes = {
  root: domainBase,
  create: `${domainBase}/new`,
  overview: `${domainBasePath}/overview`,
  singleRoot: `${domainBasePath}`,
  dataManagement: domainDataManagement,
  dataDesigner: domainDataDesigner,
  queryData: domainQueryData,
  scheduler: domainScheduler,
  connections: domainConnections,
  userPrivileges: `${domainBasePath}/user-privileges`,
  statistics: `${domainBasePath}/statistics`
};

/**
 *  Dashboard
 */
const schedulerBase = '/scheduler';
const schedulerRoutes = {
  root: schedulerBase,
  list: `${schedulerBase}/triggers`,
  listByDomain: `${schedulerBase}/list/:domainName`,
  calendar: `${schedulerBase}/calenadar`,
  calendarByDomain: `${schedulerBase}/calenadar/:domainName`,
  single: `${schedulerBase}/single/:triggerName`,
  create: `${schedulerBase}/new`
};

/**
 *  Edit
 */
const editBase = '/edit';
const editRoutes = {
  root: editBase
};

/**
 *  View
 */
const viewBase = '/view';
const viewRoutes = {
  root: viewBase
};

/**
 *  Tools
 */
const toolsBase = '/tools';
const toolsRoutes = {
  root: toolsBase
};

/**
 *  Dashboard
 */
const dashboardBase = '/dashboard';
const dashboardRoutes = {
  root: dashboardBase,
  domains: `${dashboardBase}/domains`,
  statistics: `${dashboardBase}/statistics`
};

/**
 *  DOCS
 */
const docsBase = '/docs';
const docsRoutes = {
  root: docsBase,
  single: `${docsBase}/*`
};

/**
 *  User Admin
 */
const userAdminBase = '/user-admin';
const userAdminRoutes = {
  root: userAdminBase,
  user: {
    list: `${userAdminBase}/user/list`,
    single: `${userAdminBase}/user/single/:username`
  },
  orgunit: {
    list: `${userAdminBase}/orgunit/list`,
    single: `${userAdminBase}/orgunit/single/:orgunitName`,
    create: `${userAdminBase}/orgunit/new`
  }
};

/**
 *  Personal
 */
/* const personalBase = '/personal';
const personalRotues = {
  root: userBase,
  settings: `${personalBase}/settings`
};
 */

const routes = {
  login: loginRoutes,
  error: errorRoutes,
  domain: domainRoutes,
  edit: editRoutes,
  view: viewRoutes,
  tools: toolsRoutes,
  dashboard: dashboardRoutes,
  docs: docsRoutes,
  userAdmin: userAdminRoutes,
  scheduler: schedulerRoutes
};

export default routes;
