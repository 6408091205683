import React from 'react';

import { Trans } from '@lingui/macro';
import { useStoreActions } from 'react-flow-renderer';
import { useDispatch, useSelector } from 'react-redux';
import { useImmer } from 'use-immer';
import { v4 as uuid } from 'uuid';

import EntityNodeFormDialog from 'components/domain/components/domainDiagram/entityNodeFormDialog';
import { EntityFormData } from 'components/domain/components/domainDiagram/types';
import { prepareNodeData } from 'components/domain/components/domainDiagram/utils';
import Button from 'components/ui/button';
import useDomainName from 'hooks/useDomainName';
import useSchemaName from 'hooks/useSchemaName';
import { DiagramElement, EntityNode } from 'services/api/domain/domainDiagram';
import {
  st_domainDiagram_addElement,
  st_domainDiagram_setDialogOpen
} from 'services/store/domainDiagram/domainDiagram.actions';
import { st_domainDiagram_getElements } from 'services/store/domainDiagram/domainDiagram.selectors';

type CreateEntityActionState = {
  isDialogOpen: boolean;
};

const CreateEntityAction: React.FC = () => {
  const [state, setState] = useImmer<CreateEntityActionState>({
    isDialogOpen: false
  });
  const domainName = useDomainName();
  const elements = useSelector(st_domainDiagram_getElements);
  const dispatch = useDispatch();
  const setSelectedElements = useStoreActions(actions => actions.setSelectedElements);
  const schema = useSchemaName();

  const handleAddEntityRequest = () => {
    dispatch(st_domainDiagram_setDialogOpen(true));
    setState(draft => {
      draft.isDialogOpen = true;
    });
  };

  const handleDialogClose = () => {
    dispatch(st_domainDiagram_setDialogOpen(false));
    setState(draft => {
      draft.isDialogOpen = false;
    });
  };

  const handleAddEntity = (formData: EntityFormData) => {
    const id = `${uuid()}`;
    const node: EntityNode = {
      id,
      type: 'entityNode',
      data: prepareNodeData(formData),
      position: { x: 30, y: 30 }
    };

    dispatch(st_domainDiagram_addElement(node));
    setSelectedElements(elements.filter((el: DiagramElement) => el.id === id));
    handleDialogClose();
  };

  return (
    <>
      <Button action="create" onClick={handleAddEntityRequest}>
        <Trans>Create entity</Trans>
      </Button>

      <EntityNodeFormDialog
        isOpen={state.isDialogOpen}
        insert={handleAddEntity}
        close={handleDialogClose}
        mode="CREATE"
        data={{
          className: `${domainName?.toLowerCase()}.${schema.toLowerCase()}.`,
          simpleClassName: '',
          fields: [
            {
              id: `${uuid()}`,
              name: 'id',
              type: 'String',
              uniqueId: true,
              sortOrder: 0
            }
          ]
        }}
      />
    </>
  );
};

export default CreateEntityAction;
