import tw, { css, styled } from 'twin.macro';

export const Wrapper = styled.div(() => [
  css`
    ${tw`absolute w-full h-full`};
    & > div {
      ${tw`relative w-full h-full`};
    }
  `
]);

export const FormWrapper = styled.div(() => [
  css`
    ${tw`flex flex-col h-full`};

    & div.editor {
      ${tw`relative`};
      height: calc(100% - 5.8rem);

      & .ant-row.ant-form-item {
        ${tw`h-full relative`};

        & .ant-form-item-control-input {
          ${tw`h-full relative block`};

          & .ant-form-item-control-input-content {
            ${tw`h-full`};
          }
        }
      }
    }
  `
]);
