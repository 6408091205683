import { produce } from 'immer';
import { clone, merge } from 'lodash';

import { TriggersTableActionCreators } from './triggersTable.actions';
import { TriggersTableActionTypes, TriggersTableState } from './triggersTable.types';

const initialState: TriggersTableState = Object.freeze<TriggersTableState>({
  params: {
    pageSize: 20,
    currentPage: 1
  },
  singleView: 'OVERVIEW',
  selectedKey: undefined
});

const triggersTableReducer = (
  state: TriggersTableState = initialState,
  action: TriggersTableActionCreators
): TriggersTableState =>
  produce(state, draft => {
    switch (action.type) {
      case TriggersTableActionTypes.SET_PARAMS:
        draft.params = merge(clone(draft.params), action.params);

        break;

      case TriggersTableActionTypes.SET_SINGLE_VIEW:
        draft.singleView = action.view;

        break;

      case TriggersTableActionTypes.RESET:
        draft.singleView = 'OVERVIEW';
        draft.params = {};

        break;

      case TriggersTableActionTypes.SET_SELECTED_KEY:
        draft.selectedKey = action.key;

        break;

      default:
        break;
    }
  });

export default triggersTableReducer;
