import tw, { styled, css } from 'twin.macro';

type StyledNoContentProps = {
  fullHeight?: boolean;
  $height: number;
  iconSize: number;
};

export const StyledNoContent = styled.div<StyledNoContentProps>(
  ({ fullHeight, iconSize, $height }) => [
    css`
      ${tw`flex items-center justify-center`};
      height: ${$height}px;

      & .ant-empty {
        ${tw`m-0 flex flex-col items-center`};

        & .ant-empty-image {
          ${tw`m-0 relative inline-block `};
          width: ${iconSize}px;
          height: ${iconSize * 0.66667}px;

          & svg {
            ${tw`w-full h-full absolute top-0`};
            width: 100%;
            height: 100%;
          }
        }

        & .ant-empty-description {
          ${tw`text-13`};
        }
      }
    `,

    fullHeight &&
      css`
        ${tw`h-full`};
      `
  ]
);
