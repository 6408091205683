import { produce } from 'immer';
import { clone, merge } from 'lodash';

import { FtpConnectionsTableActionCreators } from './ftpConnectionsTable.actions';
import {
  FtpConnectionsTableActionTypes,
  FtpConnectionsTableState
} from './ftpConnectionsTable.types';

const initialState: FtpConnectionsTableState = Object.freeze<FtpConnectionsTableState>({
  params: {
    pageSize: 20,
    currentPage: 1
  },
  singleView: 'OVERVIEW',
  selectedKey: undefined
});

const ftpConnectionsTableReducer = (
  state: FtpConnectionsTableState = initialState,
  action: FtpConnectionsTableActionCreators
): FtpConnectionsTableState =>
  produce(state, draft => {
    switch (action.type) {
      case FtpConnectionsTableActionTypes.SET_PARAMS:
        draft.params = merge(clone(draft.params), action.params);

        return draft;

      case FtpConnectionsTableActionTypes.SET_SINGLE_VIEW:
        draft.singleView = action.view;

        return draft;

      case FtpConnectionsTableActionTypes.RESET:
        draft.singleView = 'OVERVIEW';
        draft.params = {};

        return draft;

      case FtpConnectionsTableActionTypes.SET_SELECTED_KEY:
        draft.selectedKey = action.key;

        return draft;

      default:
        return draft;
    }
  });

export default ftpConnectionsTableReducer;
