import React from 'react';

import { Trans } from '@lingui/macro';
import { useParams } from 'react-router-dom';

import AppLayout from 'components/app/components/appLayout';
import DomainPage from 'components/domain/components/domainPage';
import QueryDocumentsSingle from 'components/domain/components/queryDocumentsSingle';
import routes from 'config/routes/routes';

const EntityDocumentSinglePage: React.FC = () => {
  const { objectId } = useParams<{ objectId: string }>();

  return (
    <AppLayout>
      <DomainPage
        route={routes.domain.queryData.entityDocument.single}
        routeParams={{ path: objectId }}
        title={<Trans>Entity/document: {objectId}</Trans>}
        className="entiy-document-query-page"
        schemaSelector={{
          readonly: true
        }}
        noScroll
        content={<QueryDocumentsSingle />}
      />
    </AppLayout>
  );
};

export default EntityDocumentSinglePage;
