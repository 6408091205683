import React from 'react';

import { Trans } from '@lingui/macro';

import AppLayout from 'components/app/components/appLayout';
import CreateCsvImportAction from 'components/domain/actions/csvImportActions/createCsvImportAction';
import CsvImportTable from 'components/domain/components/csvImportTable';
import DomainPage from 'components/domain/components/domainPage';
import routes from 'config/routes/routes';

const CsvImportListPage: React.FC = () => (
  <AppLayout>
    <DomainPage
      route={routes.domain.dataManagement.importFromCsv.list}
      title={<Trans>Import from CSV</Trans>}
      noScroll
      schemaSelector
      content={<CsvImportTable />}
      headerButtons={<CreateCsvImportAction mode="BUTTON" />}
    />
  </AppLayout>
);

export default CsvImportListPage;
