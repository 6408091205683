import React from 'react';

import { Trans } from '@lingui/macro';
import { Scrollable } from '@phoenix-systems/react-layout';

import * as S from '../csvImportTableSc';

import * as T from 'components/_styled/tableSc';
import { CsvImport } from 'services/api/domain/csvImport';

type CsvImportColumnMappersProps = {
  data: CsvImport;
};

const CsvImportColumnMappers: React.FC<CsvImportColumnMappersProps> = ({ data }) => {
  if (!data) {
    return null;
  }

  return (
    <Scrollable scrollY>
      <T.SingleContentContainer>
        <S.MapperInfo>
          <li key="head" className="head">
            <p>
              <Trans>Field</Trans>
            </p>
            <p>
              <Trans>Column</Trans>
            </p>
          </li>
          {data.columnEntityMapping?.map(item => (
            <li key={item.id}>
              <p>
                {data.entityName}.{item.field}
              </p>
              <p>
                {item.columnName} <span>[{item.columnNumber}]</span>
              </p>
            </li>
          ))}
        </S.MapperInfo>
      </T.SingleContentContainer>
    </Scrollable>
  );
};

export default CsvImportColumnMappers;
