import tw, { styled, css } from 'twin.macro';

export const Wrapper = styled.div(() => [
  css`
    ${tw`top-0 bottom-0 left-0 right-0 absolute`};

    & .ant-alert {
      ${tw`absolute bottom-10 left-10`};
      z-index: 1000;

      &.top {
        ${tw`absolute top-24 left-mobileX right-mobileX md:left-desktopX md:right-desktopX`};
        bottom: unset;
      }
    }
  `
]);

type PopupContainerProps = {
  open: boolean;
};
export const PopupContainer = styled.div<PopupContainerProps>(({ open }) => [
  css`
    & .ant-popover {
      ${tw`pl-0 `};

      & .ant-popover-arrow {
      }
    }

    & .ant-popver {
      margin-left: -2px;
    }

    & .ant-menu-sub {
      margin-left: -3px;
    }

    & .ant-popover.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow {
      left: -4px;
    }

    & .ant-popover .ant-popover-inner-content {
      ${tw`p-0`}
    }

    & .ant-popover .ant-popover-title {
      ${tw`font-700 text-primary py-8 text-13 border-b border-primary bg-light-gray-active`}
    }

    &
      .ant-menu-submenu-popup
      .ant-menu-vertical.ant-menu-sub
      > .ant-menu-submenu
      > .ant-menu-submenu-title {
      ${tw`h-36 my-0 text-12 font-700`}
      line-height: 36px;
    }

    &
      .ant-menu-submenu-popup
      .ant-menu-vertical.ant-menu-sub
      > .ant-menu-submenu
      > .ant-menu-submenu-title:hover {
      ${tw`hover:bg-light-gray`}
    }

    & .ant-menu-submenu-popup .ant-menu-vertical.ant-menu-sub .ant-menu-item {
      ${tw`h-36 my-0 text-12 font-700`}
      line-height: 36px;
    }

    & .ant-menu-submenu-popup .ant-menu-vertical.ant-menu-sub .ant-menu-item:hover {
      ${tw`hover:bg-light-gray`}
    }

    // button popover
    & .ant-menu-vertical {
      min-width: 200px;
      width: auto !important;
    }

    & .ant-menu-vertical > .ant-menu-item {
      ${tw`font-700 hover:bg-light-gray my-0`}
    }

    & .ant-menu-item-group-title {
      ${tw`font-700 text-10 uppercase mb-0 pb-2`}
    }

    & .ant-menu-item-group {
      ${tw`m-0`}
    }

    & .ant-divider.ant-divider-horizontal {
      ${tw`my-0`}
    }

    & .ant-menu-item-group-list .ant-menu-item {
      ${tw`pl-16`}
    }
  `,
  !open &&
    css`
      ${tw`hidden`};
    `
]);

export const StyledLoaderMessage = styled.div(() => [
  css`
    ${tw`mt-12 mx-mobileX md:mx-desktopX`};
  `
]);

export const EmptyWrapper = styled.div(() => [
  css`
    ${tw`absolute w-full flex items-center justify-center `};
    top: 6rem;
    height: calc(100% - 6rem);
    z-index: 1000;

    & > div {
      ${tw`bg-light-gray p-24 border border-ant-border-gray rounded flex flex-col items-center`};
      min-width: 30rem;
    }

    & .no-content .ant-empty-description {
      ${tw`text-black mt-6`};
    }

    & .ant-btn {
      ${tw`mt-12`};
    }
  `
]);
