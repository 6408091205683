import { i18n } from '@lingui/core';
import { NavigationConfig } from '@phoenix-systems/react-navigation';
import { Dispatch } from 'redux';

import { Language } from 'config/langauge/languages.types';
import { st_app_setLanguage } from 'services/store/app/app.actions';

export const getLanguageNavigationConfig = (dispatch: Dispatch): NavigationConfig => {
  const languageNav: NavigationConfig = {
    id: 'language-navigation',
    children: [
      {
        id: 'lang-en',
        title: 'English (EN)',
        icon: ['fas', 'check'],
        className: 'lang-item',
        onClick: () => {
          dispatch(st_app_setLanguage(Language.EN));
        }
      },
      {
        id: 'lang-de',
        title: i18n._('German (DE)'),
        icon: ['fas', 'check'],
        className: 'lang-item',
        onClick: () => {
          dispatch(st_app_setLanguage(Language.DE));
        }
      }
    ]
  };

  return languageNav;
};
