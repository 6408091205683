import React from 'react';

import { Auth, AuthProvider as KcAuthProvider } from '@phoenix-systems/react-keycloak-auth';

import SplashScreen from '../splashScreen';

import AuthProviderInner from './AuthProviderInner';

import LoginPage from 'components/app/pages/loginPage';
import AuthRole from 'config/auth/authRole.config';
import apiClient from 'services/api/apiClient';
import { getKeycloakClientConfigFromEnv } from 'utils';

const AuthProvider: React.FC = ({ children }) => (
  <KcAuthProvider>
    <Auth
      strategy={window._env_.KEYCLOAK_LOGIN_STRATEGY || 'EXTERNAL'}
      clientConfig={getKeycloakClientConfigFromEnv()}
      apiClient={apiClient}
      loadingScreen={<SplashScreen />}
      loginScreen={<LoginPage />}
      /* loginScreen={<Redirect to={routes.login.root} />} */
      rolesAllowed={[AuthRole.ADMIN, AuthRole.SUPERADMIN]}
      logger={window._env_.ENVIRONMENT === 'local'}
      autoRefreshToken={window._env_.KEYCLOAK_AUTOREFRESH_TOKEN === 'true'}
    >
      <AuthProviderInner>{children}</AuthProviderInner>
    </Auth>
  </KcAuthProvider>
);

export default AuthProvider;
