import { DomainRouteParams } from 'index.types';

export enum DomainActionTypes {
  SET_ROUTE = 'domain/SET_ROUTE',
  SET_DOMAINNAME = 'domain/SET_DOMAINNAME',
  RESET = 'domain/RESET'
}

export type DomainState = Readonly<{
  domainName?: string;
  route?: string;
  routeParams?: DomainRouteParams;
}>;
