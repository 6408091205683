const getQueryParamsString = (params: Record<string, string | number>): string => {
  const parts: string[] = [];
  const keys = Object.keys(params);
  keys.forEach(key => {
    parts.push(`${key}=${encodeURIComponent(params[key])}`);
  });

  return parts.join('&');
};

export default getQueryParamsString;
