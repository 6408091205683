import React, { forwardRef, ForwardRefRenderFunction, useCallback } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { omit } from 'lodash';

import { ButtonProps } from './button.types';
import * as S from './buttonSc';

const ButtonRenderer: ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = (props, ref) => {
  const {
    action,
    type,
    icon,
    className,
    isIconOnly,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    disabled,
    formState,
    mutation,
    loading,
    children,
    hasMenu
  } = props;
  const antProps = omit(props, [
    'action',
    'icon',
    'className',
    'children',
    'isIconOnly',
    'disabled',
    'formState',
    'mutation',
    'loading',
    'hasMenu'
  ]);

  const getButtonType = useCallback(() => {
    if (type) {
      return type;
    }

    if (isIconOnly && action) {
      return 'link';
    }

    if (
      action === 'create' ||
      action === 'delete' ||
      action === 'save' ||
      action === 'edit' ||
      action === 'insert' ||
      action === 'actions'
    ) {
      return 'primary';
    }

    return undefined;
  }, [type, action, isIconOnly]);

  const getButtonIcon = useCallback(() => {
    if (icon) {
      return <FontAwesomeIcon icon={icon} className="btn-icon" />;
    }

    switch (action) {
      case 'save':
        return <FontAwesomeIcon icon={['fas', 'save']} className="btn-icon" />;

      case 'create':
        return <FontAwesomeIcon icon={['fas', 'plus-circle']} className="btn-icon" />;

      case 'reset':
        return <FontAwesomeIcon icon={['fas', 'undo']} className="btn-icon" />;

      case 'delete':
        return <FontAwesomeIcon icon={['fas', 'trash']} className="btn-icon" />;

      case 'edit':
        return <FontAwesomeIcon icon={['fas', 'pen']} className="btn-icon" />;

      case 'copy':
        return <FontAwesomeIcon icon={['fas', 'copy']} className="btn-icon" />;

      case 'list':
        return <FontAwesomeIcon icon={['fas', 'list']} className="btn-icon" />;

      case 'link':
        return <FontAwesomeIcon icon={['fas', 'link']} className="btn-icon" />;

      case 'config':
        return <FontAwesomeIcon icon={['fas', 'cog']} className="btn-icon" />;

      case 'actions':
        return <FontAwesomeIcon icon={['fas', 'cog']} className="btn-icon" />;

      case 'insert':
        return <FontAwesomeIcon icon={['far', 'arrow-square-right']} className="btn-icon" />;

      default:
        return null;
    }
  }, [icon, action]);

  const getDisabled = useCallback(() => {
    if (disabled) {
      return disabled;
    }

    if (action && mutation && formState) {
      if (action === 'reset') {
        return !formState.hasChanged || mutation.isLoading;
      }

      if (action === 'save') {
        return !formState.hasChanged || mutation.isLoading || !formState.isValid;
      }
    }

    if (action && formState) {
      if (action === 'reset') {
        return !formState.hasChanged;
      }

      if (action === 'save') {
        return !formState.hasChanged || !formState.isValid;
      }

      if (action === 'insert') {
        return !formState.hasChanged || !formState.isValid;
      }
    }

    if (action && mutation) {
      if (action === 'reset') {
        return !mutation.isLoading;
      }

      if (action === 'save') {
        return mutation.isLoading;
      }

      if (action === 'insert') {
        return mutation.isLoading;
      }

      if (action === 'delete') {
        return mutation.isLoading;
      }
    }

    return false;
  }, [disabled, action, formState, mutation]);

  const getLoading = useCallback(() => {
    if (loading) {
      return loading;
    }

    if (action === 'save' && mutation) {
      return mutation.isLoading;
    }

    if (action === 'insert') {
      return mutation?.isLoading;
    }

    if (action === 'delete' && mutation) {
      return mutation.isLoading;
    }

    return false;
  }, [loading, action, mutation]);

  const getContent = useCallback(() => {
    const menuIcon = <FontAwesomeIcon icon={['fas', 'angle-down']} className="open-icon" />;

    if (children) {
      return (
        <>
          {children}
          {hasMenu && menuIcon}
        </>
      );
    }

    if (action && !isIconOnly) {
      let label: React.ReactNode;

      switch (action) {
        case 'save':
          label = <Trans>Save</Trans>;

          break;
        case 'reset':
          label = <Trans>Reset</Trans>;

          break;
        case 'delete':
          label = <Trans>Delete</Trans>;

          break;
        case 'create':
          label = <Trans>Create</Trans>;

          break;
        case 'close':
          label = <Trans>Close</Trans>;

          break;
        case 'cancel':
          label = <Trans>Cancel</Trans>;

          break;
        case 'edit':
          label = <Trans>Edit</Trans>;

          break;
        case 'copy':
          label = <Trans>Copy</Trans>;

          break;
        case 'list':
          label = <Trans>List</Trans>;

          break;
        case 'link':
          label = <Trans>Link</Trans>;

          break;
        case 'config':
          label = <Trans>Configuration</Trans>;

          break;

        case 'insert':
          label = <Trans>Insert</Trans>;

          break;
        case 'actions':
          label = <Trans>Actions</Trans>;

          break;
        default:
          label = <></>;

          break;
      }

      return (
        <>
          {label}
          {hasMenu && menuIcon}
        </>
      );
    }

    return null;
  }, [children, action, isIconOnly, hasMenu]);

  return (
    <S.StyledButton
      $action={action}
      ref={ref}
      type={getButtonType()}
      icon={getButtonIcon()}
      className={clsx([
        className,
        action && `${action}-btn`,
        getDisabled() && 'disabled',
        isIconOnly && 'icon-btn'
      ])}
      disabled={getDisabled()}
      loading={getLoading()}
      {...antProps}
    >
      {getContent()}
    </S.StyledButton>
  );
};

const Button = forwardRef(ButtonRenderer);
Button.displayName = 'Button';

export default Button;
