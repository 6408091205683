import React, { useCallback, useEffect } from 'react';

import { useScrollableState } from '@phoenix-systems/react-layout';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import { useWindowSize } from 'react-use';
import useMeasure from 'react-use-measure';
import { CSSProperties } from 'styled-components';
import { useImmer } from 'use-immer';

import * as S from './formContainerSc';

type FormContainerProps = {
  title?: string | React.ReactNode;
  maxWidth?: number | string;
  buttons: React.ReactNode;
  messages?: React.ReactNode;
  isStickyDisabled?: boolean;
  className?: string;
  style?: CSSProperties;
  fullHeight?: boolean;
};

type FormContainerState = {
  isButtonsVisible: boolean;
  containerWidth: number;
  isHovered: boolean;
  measures: {
    left: number;
    width: number;
    height: number;
  };
  stickyContainerHeight: number;
};

const FormContainer: React.FC<FormContainerProps> = ({
  children,
  buttons,
  title,
  maxWidth,
  messages,
  isStickyDisabled,
  className,
  style,
  fullHeight
}) => {
  const [containerRef, containerBounds] = useMeasure();
  const [buttonsContainerRef, buttonsMeasures] = useMeasure();
  const windowSize = useWindowSize();
  const scrollState = useScrollableState();

  const [state, setState] = useImmer<FormContainerState>({
    isButtonsVisible: true,
    containerWidth: 0,
    isHovered: false,
    measures: {
      width: 0,
      height: 0,
      left: 0
    },
    stickyContainerHeight: 0
  });

  const topScrollPos = scrollState.position.top;

  const setDimensions = useCallback(() => {
    const leftSpace =
      windowSize.height - containerBounds.height - containerBounds.top + scrollState.position.top;

    setState(draft => {
      if (leftSpace < -100) {
        draft.isButtonsVisible = true;
      } else {
        draft.isButtonsVisible = false;
      }
      draft.measures = {
        width: containerBounds.width,
        height: containerBounds.height,
        left: containerBounds.left
      };
    });
  }, [containerBounds, scrollState.position.top, windowSize, setState]);

  useEffect(() => {
    if (!isStickyDisabled) {
      setDimensions();
    }
  }, [topScrollPos, scrollState.isScrolling, containerBounds, isStickyDisabled, setDimensions]);

  useEffect(() => {
    if (!isStickyDisabled) {
      const currentMeasures = {
        width: containerBounds.width,
        height: containerBounds.height,
        left: containerBounds.left
      };

      if (!isEqual(currentMeasures, state.measures)) {
        setDimensions();
      }
    }
  }, [containerBounds, isStickyDisabled, setDimensions, state.measures]);

  useEffect(() => {
    if (buttonsMeasures && !isStickyDisabled) {
      setState(draft => {
        draft.stickyContainerHeight = buttonsMeasures.height + 32;
      });
    }
  }, [buttonsMeasures, isStickyDisabled, setState]);

  return (
    <S.Wrapper
      isFullHeight={fullHeight}
      className={clsx(['form-container', className])}
      style={style}
      maxWidth={maxWidth}
      ref={containerRef}
      onMouseOver={() =>
        setState(draft => {
          draft.isHovered = true;
        })
      }
      onMouseOut={() =>
        setState(draft => {
          draft.isHovered = false;
        })
      }
    >
      <S.Title>{title}</S.Title>

      <S.Content className="form-container-content">{children}</S.Content>

      <S.Footer ref={buttonsContainerRef} className="form-container-footer">
        {messages && <div className="messages">{messages}</div>}
        <div className="buttons">{buttons}</div>
      </S.Footer>
      {!isStickyDisabled && (
        <S.StickyContainer
          width={state.measures?.width || 0}
          left={state.measures?.left || 0}
          height={state.stickyContainerHeight}
          visible={state.isButtonsVisible}
        >
          <div>
            <div className="fader" />
            <S.Footer>
              {messages && <div className="messages">{messages}</div>}
              <div className="buttons">{buttons}</div>
            </S.Footer>
          </div>
        </S.StickyContainer>
      )}
    </S.Wrapper>
  );
};

export default FormContainer;
