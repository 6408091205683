import { produce } from 'immer';

import { DomainActionCreators } from './domain.actions';
import { DomainActionTypes, DomainState } from './domain.types';

const initialState: DomainState = Object.freeze<DomainState>({
  route: undefined,
  routeParams: undefined
});

const domainReducer = (
  state: DomainState = initialState,
  action: DomainActionCreators
): DomainState =>
  produce(state, draft => {
    switch (action.type) {
      case DomainActionTypes.SET_DOMAINNAME:
        draft.domainName = action.name;

        break;

      case DomainActionTypes.SET_ROUTE:
        draft.route = action.route;
        draft.routeParams = action.params;

        break;

      case DomainActionTypes.RESET:
        draft.route = undefined;
        draft.routeParams = undefined;

        break;

      default:
        break;
    }
  });

export default domainReducer;
