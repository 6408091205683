import { createSelector } from 'reselect';

import { CsvImportsTableState, CsvImportTableSingleView } from './csvImportsTable.types';

import { TableDataAggregationParams } from 'index.types';

export const csvImportsTableState = (state: {
  csvImportsTable: CsvImportsTableState;
}): CsvImportsTableState => state.csvImportsTable;

export const st_csvImportsTable_getParams = createSelector(
  [csvImportsTableState],
  (csvImportsTable): TableDataAggregationParams => csvImportsTable.params
);

export const st_csvImportsTable_getSingleView = createSelector(
  [csvImportsTableState],
  (csvImportsTable): CsvImportTableSingleView => csvImportsTable.singleView
);

export const st_csvImportsTable_getActiveKey = createSelector(
  [csvImportsTableState],
  (csvImportsTable): string | undefined => csvImportsTable.activeKey
);
