import React from 'react';

import Loader from 'components/ui/loader';
import appStyles from 'styles/appStyles';

const MainNavigationLoader: React.FC = () => (
  <Loader backColor={appStyles.colors.lightGray} frontColor="#CCC" size={32} />
);

export default MainNavigationLoader;
