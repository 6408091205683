import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { useLayoutActions, useLayoutConfig } from '@phoenix-systems/react-layout';
import { Menu } from 'antd';

import * as S from './mainNavigationSc';

const MainNavigationBottom: React.FC = () => {
  const { toggleSidebar } = useLayoutActions();
  const layoutConfig = useLayoutConfig();

  const handleToggleSidebar = () => {
    toggleSidebar('left');
  };

  return (
    <S.StyledToggleNav inlineCollapsed={layoutConfig.sidebarLeft?.isCollapsed} mode="inline">
      <Menu.Item
        key="toggle-btn"
        onClick={handleToggleSidebar}
        className="toggle-nav-item"
        title={
          !layoutConfig.sidebarLeft?.isCollapsed
            ? i18n._('Collapse sidebar')
            : i18n._('Expand sidebar')
        }
        icon={
          <FontAwesomeIcon
            icon={[
              'fas',
              !layoutConfig.sidebarLeft?.isCollapsed ? 'angle-double-left' : 'angle-double-right'
            ]}
          />
        }
      >
        {!layoutConfig.sidebarLeft?.isCollapsed
          ? i18n._('Collapse sidebar')
          : i18n._('Expand sidebar')}
      </Menu.Item>
    </S.StyledToggleNav>
  );
};

export default MainNavigationBottom;
