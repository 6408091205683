import React from 'react';

import { Trans } from '@lingui/macro';
import { Desktop } from '@phoenix-systems/react-layout';
import { Redirect, useParams } from 'react-router-dom';

import AppLayout from 'components/app/components/appLayout';
import Breadcrumbs from 'components/app/components/breadcrumbs';
import ErrorInfo from 'components/ui/errorInfo';
import Exception from 'components/ui/exception';
import Loader from 'components/ui/loader';
import Page from 'components/ui/page';
import DeleteUserAction from 'components/userAdmin/actions/deleteUserAction';
import UserForm from 'components/userAdmin/components/userForm';
import routes from 'config/routes/routes';
import { useIsSuperadmin } from 'hooks/useIsSuperAdmin';
import useNotFound from 'hooks/useNotFound';
import { useUserByName } from 'services/api/domain/user';
import { DEFAULT_ADMINS } from 'services/userPermissions/userPermissions.config';

const UserSinglePage: React.FC = () => {
  const { username } = useParams<{ username: string }>();
  const isSuperadmin = useIsSuperadmin();
  const userQuery = useUserByName({ username });
  const isNotFound = useNotFound(userQuery);

  if (!isSuperadmin) {
    return <Redirect to={routes.dashboard.root} />;
  }

  if (isNotFound) {
    <Exception
      code={404}
      message={
        <Trans>
          Organisation <b>{username}</b> was not found.
        </Trans>
      }
    />;
  }

  if (userQuery.isError) {
    return (
      <ErrorInfo
        error={userQuery.error}
        asPageContent
        message={<Trans>Failed to load user &quot;{username}&quot;.</Trans>}
      />
    );
  }

  return (
    <AppLayout>
      <Page
        scrollableProps={{ scrollId: 'page-scroll' }}
        content={
          <>
            {userQuery.isLoading && <Loader fullHeight />}
            {userQuery.data && <UserForm data={userQuery.data} />}
          </>
        }
        title={<Trans>User: {username}</Trans>}
        titleTop={
          <Desktop>
            <Breadcrumbs />
          </Desktop>
        }
        titleElements={
          <>
            {userQuery.data?.username && !DEFAULT_ADMINS.includes(userQuery.data?.username) && (
              <DeleteUserAction mode="BUTTON" data={userQuery.data} />
            )}
          </>
        }
      />
    </AppLayout>
  );
};

export default UserSinglePage;
