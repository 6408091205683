import React from 'react';

import { Trans } from '@lingui/macro';
import { useDispatch } from 'react-redux';

import AppLayout from 'components/app/components/appLayout';
import DomainPage from 'components/domain/components/domainPage';
import QueryDocuments from 'components/domain/components/queryDocuments';
import routes from 'config/routes/routes';
import useDomainName from 'hooks/useDomainName';
import history from 'services/history';
import { st_entityDocument_reset } from 'services/store/entityDocument/entityDocument.actions';
import getDomainRoute from 'utils/getDomainRoute';

const EntityDocumentPage: React.FC = () => {
  const dispatch = useDispatch();
  const domainName = useDomainName();
  /*   const location = useLocation();
  const dm = getUrlFragment(1);
  const [domain, setDomain] = useState<string>(); */

  return (
    <AppLayout>
      <DomainPage
        route={routes.domain.queryData.entityDocument.root}
        title={<Trans>Entity/document</Trans>}
        className="entiy-document-query-page"
        schemaSelector={{
          onChange: schema => {
            dispatch(st_entityDocument_reset());
            history.push(
              getDomainRoute(routes.domain.queryData.entityDocument.list, domainName, schema)
            );
          }
        }}
        noScroll
        content={<QueryDocuments />}
      />
    </AppLayout>
  );
};

export default EntityDocumentPage;
