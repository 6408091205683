import { GetSolrQueryObjectParams, SolrQueryObject } from './solrQuery.types';

import apiClient from 'services/api/apiClient';

const BASE_PATH = 'query';

/**
 * GET /api/query/{domain}/{scheme}/replacertest/{type}/{subqueries}
 */
export const api_getSolrQueryObject = async (
  params: GetSolrQueryObjectParams
): Promise<SolrQueryObject> => {
  const { data } = await apiClient.get(
    `/${BASE_PATH}/${params.domainName}/${params.schema}/replacertest/${params.type}/${params.subQueries}`
  );

  return data;
};
