import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { getRoute } from '@phoenix-systems/react-router';
import { Modal } from 'antd';
import { useMutation, useQueryClient } from 'react-query';

import Button from 'components/ui/button';
import routes from 'config/routes/routes';
import useDefaultModalProps from 'hooks/useDefaultModalProps';
import useDomainName from 'hooks/useDomainName';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';
import useNotification from 'hooks/useNotification';
import useTriggerName from 'hooks/useTriggerName';
import { api_deleteTriggerByName, Trigger } from 'services/api/domain/trigger';
import history from 'services/history';

type DeleteTriggerDialogProps = {
  isOpen: boolean;
  data: Trigger;
  close: () => void;
};

const DeleteTriggerDialog: React.FC<DeleteTriggerDialogProps> = ({ data, isOpen, close }) => {
  const queryClient = useQueryClient();
  const triggerName = useTriggerName();
  const domainName = useDomainName();
  const [addNotification] = useNotification();
  const goto = useGoToDomainRoute();
  const modalProps = useDefaultModalProps();

  const deleteMutation = useMutation((tName: string) => api_deleteTriggerByName(tName), {
    onSuccess: () => {
      addNotification({
        type: 'success',
        message: i18n._(t`Successfully deleted trigger "${data.name}".`)
      });

      if (!triggerName) {
        queryClient.refetchQueries(['triggers']);
      } else {
        // eslint-disable-next-line no-lonely-if
        if (domainName) {
          goto(routes.domain.scheduler.list);
        } else {
          history.push(getRoute(routes.scheduler.list));
        }
      }
      close();
    },
    onError: () => {
      addNotification({
        type: 'error',
        message: i18n._(t`Failed to delete trigger "${data.name}".`)
      });
    }
  });

  const handleDelete = () => {
    deleteMutation.mutate(data.name);
  };

  return (
    <Modal
      {...modalProps}
      visible={isOpen}
      title={
        <>
          <FontAwesomeIcon icon={['fas', 'trash']} />
          <Trans>Delete Trigger {data.name}</Trans>
        </>
      }
      footer={
        <>
          <Button action="cancel" disabled={deleteMutation.isLoading} onClick={() => close()} />
          <Button action="delete" mutation={deleteMutation} onClick={handleDelete}>
            <Trans>Delete trigger</Trans>
          </Button>
        </>
      }
    >
      <p>
        Do you want to delete the trigger <b>{data.name}</b>?<br /> The attached task{' '}
        <b>{data.taskName}</b> will no more be executed.
      </p>
    </Modal>
  );
};

export default DeleteTriggerDialog;
