import React from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavigationConfig } from '@phoenix-systems/react-navigation';
import { Menu } from 'antd';
import { omit } from 'lodash';

import { Domain } from 'services/api/domain/domain';
import history from 'services/history';

const { Item, SubMenu } = Menu;

type DomainActionItemProps = {
  config: NavigationConfig;
  domain: Domain;
};

const DomainActionItem: React.FC<DomainActionItemProps> = props => {
  const antProps = omit(props, ['config', 'domain']);
  const { config, domain } = props;

  const handleClick = () => {
    if (config.route) {
      history.push(config.route);
    }
  };

  if (config.isDisabled) {
    return null;
  }

  if (config.children) {
    return (
      <SubMenu
        {...antProps}
        title={config.title}
        icon={
          config.icon ? (
            <FontAwesomeIcon icon={config.icon as IconProp} className="anticon" />
          ) : null
        }
      >
        {config.children.map(item => (
          <DomainActionItem config={item} domain={domain} key={item.id} />
        ))}
      </SubMenu>
    );
  }

  return (
    <Item
      {...antProps}
      onClick={handleClick}
      icon={config.icon ? <FontAwesomeIcon icon={config.icon as IconProp} /> : null}
    >
      {config.title}
    </Item>
  );
};

export default DomainActionItem;
