import React, { useCallback, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { FormItem, useFormContext } from '@phoenix-systems/react-form';
import { Alert, Form, Input } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { RuleObject } from 'antd/lib/form';
import Modal from 'antd/lib/modal/Modal';
import { AxiosError } from 'axios';
import { clone } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import { useImmer } from 'use-immer';

import TestFtpConnectionResponse from '../testFtpConnection';

import * as S from './ftpConnectionFormSc';

import * as D from 'components/_styled/dialogSc';
import * as F from 'components/_styled/formSc';
import DocsTooltip from 'components/docs/components/docsTooltip';
import TooltipLabel from 'components/docs/components/docsTooltip/tooltipLabel';
import Button from 'components/ui/button';
import ErrorInfo from 'components/ui/errorInfo';
import FormContainer from 'components/ui/formContainer';
import routes from 'config/routes/routes';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import useDefaultModalProps from 'hooks/useDefaultModalProps';
import useDomainName from 'hooks/useDomainName';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';
import useNotification from 'hooks/useNotification';
import useSchemaName from 'hooks/useSchemaName';
import {
  api_checkFtpConnection,
  api_createFtpConnection,
  api_updateFtpConnection,
  CheckFtPConnectionParams,
  CreateFtpConnectionParams,
  FTPConnection,
  useFTPConnections
} from 'services/api/domain/ftpConnection';
import { DocId } from 'services/api/domain/hint';

const defaultData: Partial<FTPConnection> = {
  remoteDir: '/',
  sftp: false,
  name: undefined,
  remoteHost: undefined,
  username: undefined,
  password: undefined
};

type FtpConnectionFormProps = {
  data?: FTPConnection;
  mode: 'STANDALONE' | 'EMBEDDED' | 'DIALOG';
  dialogProps?: {
    close: (connectionName?: string) => void;
    isOpen: boolean;
  };
};

type FtpConnectionFormState = {
  isConnectionDataComplete: boolean;
};

const FtpConnectionForm: React.FC<FtpConnectionFormProps> = ({ data, mode, dialogProps }) => {
  const domainName = useDomainName();
  const schemaName = useSchemaName();
  const goto = useGoToDomainRoute();
  const [addNotification] = useNotification();
  const queryClient = useQueryClient();
  const copy = useCopyToClipboard();
  const connectionsQuery = useFTPConnections({ domainName, schemaName });
  const [state, setState] = useImmer<FtpConnectionFormState>({
    isConnectionDataComplete: false
  });
  const modalProps = useDefaultModalProps();

  const getInitData = useCallback(() => {
    if (data) {
      return clone(data);
    }

    return clone(defaultData);
  }, [data]);
  const [form] = Form.useForm<FTPConnection>();

  const [formState, formActions] = useFormContext(form, getInitData(), data !== undefined);

  const checkConnectionMutation = useMutation<void, AxiosError, CheckFtPConnectionParams>(params =>
    api_checkFtpConnection(params)
  );

  const createMutation = useMutation<FTPConnection, AxiosError, CreateFtpConnectionParams>(
    params => api_createFtpConnection(params),
    {
      onSuccess: res => {
        addNotification({
          type: 'success',
          message: i18n._(t`Successfully created FTP connection "${res.name}".`)
        });

        if (mode === 'STANDALONE') {
          if (res.name && mode === 'STANDALONE') {
            goto(routes.domain.connections.ftp.single, { path: res.name });
          }

          return;
        }

        if (mode === 'DIALOG') {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          if (dialogProps?.close) {
            dialogProps.close(res.name);
          }

          setTimeout(() => {
            formActions.reset(getInitData(), false);
            createMutation.reset();
          }, 500);
        }
      },
      onError: () => {
        addNotification({
          type: 'error',
          message: i18n._('Failed to created FTP connection.')
        });
      }
    }
  );

  const updateMutation = useMutation(
    (params: CreateFtpConnectionParams) => api_updateFtpConnection(params),
    {
      onSuccess: res => {
        addNotification({
          type: 'success',
          message: i18n._(t`Successfully updated FTP connection "${res.name}".`)
        });

        if (res.name !== data?.name) {
          goto(routes.domain.connections.ftp.single, { path: res.name });

          return;
        }

        queryClient.setQueryData(
          ['ftpConnection', { domainName, schemaName, name: res.name }],
          res
        );
        updateMutation.reset();
        formActions.reset(res, true);
      },
      onError: () => {
        addNotification({
          type: 'error',
          message: i18n._(t`Failed to created FTP connection "${data?.name}".`)
        });
      }
    }
  );

  const handleCheckConnection = () => {
    const formData = form.getFieldsValue();
    checkConnectionMutation.mutate({
      domainName,
      schemaName,
      payload: formData
    });
  };

  const handleSave = () => {
    form.validateFields().then(formData => {
      if (data) {
        updateMutation.mutate({
          domainName,
          schemaName,
          payload: formData
        });

        return;
      }

      createMutation.mutate({
        domainName,
        schemaName,
        payload: formData
      });
    });
  };

  const handleReset = () => {
    formActions.reset(getInitData());
    checkConnectionMutation.reset();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleValuesChange = (changedValues: any, values: any) => {
    if (
      values?.password &&
      values?.remoteDir &&
      values?.remoteHost &&
      values?.sftp !== undefined &&
      values?.username
    ) {
      setState(draft => {
        draft.isConnectionDataComplete = true;
      });
    } else {
      setState(draft => {
        draft.isConnectionDataComplete = false;
      });
    }

    formActions.onValuesChange(changedValues, values);
  };

  const validateName = (rule: RuleObject, value: string) =>
    new Promise((resolve, reject) => {
      if (value && value !== '') {
        if (connectionsQuery.isSuccess && connectionsQuery.data) {
          if (
            connectionsQuery.data.filter(c => c.name !== data?.name).find(c => c.name === value)
          ) {
            reject(new Error('name is already in use'));

            return;
          }
          resolve(value);
        } else {
          resolve(value);
        }
      } else {
        resolve(value);
      }
    });

  const handleCloseDialog = () => {
    if (dialogProps?.close) {
      dialogProps.close();
    }
  };

  useEffect(() => {
    setState(draft => {
      draft.isConnectionDataComplete = true;
    });
  }, [data, setState]);

  const testButton = (
    <S.TestButton
      type="primary"
      disabled={!state.isConnectionDataComplete}
      icon={<FontAwesomeIcon icon={['fas', 'sync']} />}
      onClick={handleCheckConnection}
    >
      <Trans>Check connection</Trans>
    </S.TestButton>
  );

  const formContent = (
    <F.StyledForm
      name="ftp-connection-form"
      onFieldsChange={formActions.onFieldsChange}
      onValuesChange={handleValuesChange}
      initialValues={getInitData()}
      $labelWidth={157}
      validateTrigger="onChange"
      form={form}
      colon={false}
    >
      {data?.id && (
        <FormItem
          label={<TooltipLabel docId={DocId.DOMAIN_CONNECTIONS_FTP_ID} label={<Trans>Id</Trans>} />}
          messageVariables={{ name: i18n._('Id') }}
          name="id"
          rules={[{ required: true }]}
          registerField={formActions.registerField}
        >
          <Input
            readOnly
            addonAfter={<Button icon={['fas', 'copy']} onClick={() => copy(data?.id)} />}
          />
        </FormItem>
      )}
      <FormItem
        label={
          <TooltipLabel docId={DocId.DOMAIN_CONNECTIONS_FTP_NAME} label={<Trans>Name</Trans>} />
        }
        messageVariables={{ name: i18n._('Name') }}
        name="name"
        rules={[
          { required: true },
          {
            pattern: /^[A-Za-z0-9\-_]+$/,
            message: i18n._("Only allowed alphanumeric characters including '-' and '_'.")
          },
          {
            validator: validateName,
            message: i18n._(
              t`FTP connection with name "${form.getFieldValue(
                'name'
              )}" is already in use. FTP connection name must be unique in same domain and schema.`
            )
          }
        ]}
        registerField={formActions.registerField}
      >
        <Input
          addonAfter={
            data ? <Button icon={['fas', 'copy']} onClick={() => copy(data.name)} /> : undefined
          }
        />
      </FormItem>
      <FormItem
        label={
          <TooltipLabel docId={DocId.DOMAIN_CONNECTIONS_FTP_SFTP} label={<Trans>SFTP</Trans>} />
        }
        messageVariables={{ name: i18n._('SFTP') }}
        name="sftp"
        rules={[{ required: true }]}
        valuePropName="checked"
        registerField={formActions.registerField}
      >
        <Checkbox />
      </FormItem>
      <FormItem
        label={
          <TooltipLabel
            docId={DocId.DOMAIN_CONNECTIONS_FTP_REMOTE_HOST}
            label={<Trans>Remote host</Trans>}
          />
        }
        messageVariables={{ name: i18n._('Remote host') }}
        name="remoteHost"
        rules={[{ required: true }]}
        registerField={formActions.registerField}
      >
        <Input />
      </FormItem>
      <FormItem
        label={
          <TooltipLabel
            docId={DocId.DOMAIN_CONNECTIONS_FTP_REMOTE_DIRECTORY}
            label={<Trans>Remote directory</Trans>}
          />
        }
        messageVariables={{ name: i18n._('Remote directory') }}
        name="remoteDir"
        rules={[{ required: true }]}
        registerField={formActions.registerField}
      >
        <Input />
      </FormItem>
      <FormItem
        label={
          <TooltipLabel
            docId={DocId.DOMAIN_CONNECTIONS_FTP_USERANME}
            label={<Trans>User name</Trans>}
          />
        }
        messageVariables={{ name: i18n._('User name') }}
        name="username"
        rules={[{ required: true }]}
        registerField={formActions.registerField}
      >
        <Input />
      </FormItem>
      <FormItem
        label={
          <TooltipLabel
            docId={DocId.DOMAIN_CONNECTIONS_FTP_PASSWORD}
            label={<Trans>Password</Trans>}
          />
        }
        messageVariables={{ name: i18n._('Password') }}
        name="password"
        rules={[{ required: true }]}
        registerField={formActions.registerField}
      >
        <Input.Password autoComplete="false" />
      </FormItem>
    </F.StyledForm>
  );

  if (mode === 'EMBEDDED') {
    return formContent;
  }

  if (mode === 'DIALOG' && dialogProps) {
    return (
      <Modal
        {...modalProps}
        title={<Trans>Create FTP connection</Trans>}
        visible={dialogProps.isOpen}
        width={800}
        footer={
          <>
            <Button onClick={handleCloseDialog}>
              action={!createMutation.isSuccess ? 'cancel' : 'close'}
            </Button>
            <Button action="reset" disabled={!formState.hasChanged} onClick={handleReset} />
            <Button
              type="primary"
              icon={['fas', 'exchange']}
              disabled={!state.isConnectionDataComplete}
              onClick={handleCheckConnection}
            >
              <Trans>Check FTP connection</Trans>
            </Button>
            {!createMutation.isSuccess && (
              <Button
                action="save"
                mutation={createMutation}
                formState={formState}
                onClick={handleSave}
              />
            )}
          </>
        }
      >
        <F.StyledForm
          form={form}
          onValuesChange={handleValuesChange}
          onFieldsChange={formActions.onFieldsChange}
          name="create-ftp-connection-form"
        >
          {formContent}
          <D.ResponseMessage>
            <TestFtpConnectionResponse query={checkConnectionMutation} />
            {createMutation.isError && (
              <ErrorInfo
                message={<Trans>Failed to to create FTP connection.</Trans>}
                error={createMutation.error}
              />
            )}
            {createMutation.isSuccess && (
              <Alert
                type="success"
                message={
                  <Trans>
                    Successfully created FTP connection &quot;{form.getFieldValue('name')}&quot;.
                  </Trans>
                }
              />
            )}
          </D.ResponseMessage>
        </F.StyledForm>
      </Modal>
    );
  }

  return (
    <FormContainer
      title={
        data ? (
          <>
            <FontAwesomeIcon icon={['fas', 'exchange']} />
            <Trans>Edit FTP Connection</Trans>
            <DocsTooltip docId={DocId.DOMAIN_CONNECTIONS_FTP} />
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={['fas', 'exchange']} />
            <Trans>Create FTP Connection</Trans>
            <DocsTooltip docId={DocId.DOMAIN_CONNECTIONS_FTP} />
          </>
        )
      }
      buttons={
        <>
          {testButton}
          <Button action="reset" onClick={handleReset} formState={formState} />
          <Button action="save" onClick={handleSave} formState={formState} />
        </>
      }
      messages={
        <>
          <TestFtpConnectionResponse query={checkConnectionMutation} />
          {createMutation.isError && (
            <ErrorInfo
              message={<Trans>Failed to to create FTP connection.</Trans>}
              error={createMutation.error}
            />
          )}
        </>
      }
    >
      {formContent}
    </FormContainer>
  );
};

export default FtpConnectionForm;
