import React, { useEffect, useState } from 'react';

import Loader from './loader';
import * as S from './loaderSc';

type LoaderMessageProps = {
  loaderSize?: number;
  showAfterTime?: number;
};

const LoaderMessage: React.FC<LoaderMessageProps> = ({ loaderSize, showAfterTime, children }) => {
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (showAfterTime !== undefined) {
      timeout = setTimeout(() => {
        setVisible(true);
      }, showAfterTime);
    } else {
      setVisible(true);
    }

    return function cleanup() {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [showAfterTime]);

  if (!isVisible) {
    return null;
  }

  return (
    <S.LoaderMessageContainer className="loader-message">
      <S.LoaderWrapper className="loader-wrapper">
        <Loader size={loaderSize || 20} />
      </S.LoaderWrapper>
      <div className="message">{children}</div>
    </S.LoaderMessageContainer>
  );
};

export default LoaderMessage;
