import tw, { styled, css } from 'twin.macro';

export const Container = styled.div(() => [
  css`
    ${tw`w-full h-full flex flex-col bg-light-gray border-r border-ant-border-gray-100`};
  `
]);

export const Header = styled.div(() => [
  css`
    ${tw`border-b border-ant-border-gray`};
  `
]);

export const ScrollableMain = styled.div(() => [
  css`
    ${tw`h-full overflow-hidden`};
  `
]);

export const Footer = styled.div(() => [
  css`
    ${tw``};
  `
]);

export const Loader = styled.div(() => [
  css`
    ${tw`w-full h-full flex items-center justify-center`};
  `
]);

type PopupContainerProps = {
  hidden?: boolean;
};
export const MainNavigationContainer = styled.div<PopupContainerProps>(({ hidden }) => [
  css`
    & .ant-menu-vertical {
      margin-left: -3px;
    }

    & .ant-menu-vertical.ant-menu-sub {
      ${tw`border border-ant-border-gray`};
    }

    & .ant-menu-vertical .ant-menu-item {
      ${tw`font-700 hover:bg-light-gray`};
    }

    & .ant-menu-vertical .ant-menu-item {
      ${tw`my-0`};
    }

    & .ant-select-dropdown {
      .ant-select-item {
        ${tw`font-600`}
      }

      .ant-select-item.ant-select-item-option.is-button button {
        ${tw`w-full my-6`}
      }

      .rc-virtual-list-holder {
        max-height: 400px;
      }
    }

    & .ant-dropdown-menu.breadcrumb-menu {
      min-width: 220px;

      & .ant-dropdown-menu-item.breadcrumb-menu-item {
        ${tw`font-600`};
      }
    }
  `,
  hidden &&
    css`
      ${tw`hidden`};
    `
]);
