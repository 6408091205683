import { AxiosError } from 'axios';
import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';

import {
  api_getCsvFileHeaderInfo,
  api_getCsvImportByName,
  api_getCsvImportResults,
  api_getCsvImports,
  api_getFileListViaFTP,
  api_getTempCsvFiles
} from './csvImport.queries';
import {
  CsvFileHeaderInfo,
  CsvImport,
  CsvImportResult,
  FileMetaData,
  GetCsvFileHeaderParams,
  GetCSVImportByNameParams,
  GetCsvImportResultsParams,
  GetCSVImportsParams,
  GetFileListViaFTPParams,
  GetTempCSVFilesParams
} from './csvImport.types';

export const useCsvImport = (
  params: GetCSVImportByNameParams,
  options?: UseQueryOptions<CsvImport, AxiosError, CsvImport>
): QueryObserverResult<CsvImport, AxiosError> =>
  useQuery<CsvImport, AxiosError>(
    ['csvImport', params],
    () => api_getCsvImportByName(params),
    options
  );

export const useCsvImports = (
  params: GetCSVImportsParams,
  options?: UseQueryOptions<CsvImport[], AxiosError, CsvImport[]>
): QueryObserverResult<CsvImport[], AxiosError> =>
  useQuery<CsvImport[], AxiosError>(
    ['csvImports', params],
    () => api_getCsvImports(params),
    options
  );

export const useTempCsvFiles = (
  params: GetTempCSVFilesParams,
  options?: UseQueryOptions<FileMetaData[], AxiosError, FileMetaData[]>
): QueryObserverResult<FileMetaData[], AxiosError> =>
  useQuery<FileMetaData[], AxiosError>(
    ['tempCsvFiles', params],
    () => api_getTempCsvFiles(params),
    options
  );

export const useFileListViaFTP = (
  params: GetFileListViaFTPParams,
  options?: UseQueryOptions<FileMetaData[], AxiosError, FileMetaData[]>
): QueryObserverResult<FileMetaData[], AxiosError> =>
  useQuery<FileMetaData[], AxiosError>(
    ['ftpFileList', params],
    () => api_getFileListViaFTP(params),
    options
  );

export const useCsvFileHeaderInfo = (
  params: GetCsvFileHeaderParams,
  options?: UseQueryOptions<CsvFileHeaderInfo, AxiosError, CsvFileHeaderInfo>
): QueryObserverResult<CsvFileHeaderInfo, AxiosError> =>
  useQuery<CsvFileHeaderInfo, AxiosError>(
    ['csvFileHeaderInfo', params],
    () => api_getCsvFileHeaderInfo(params),
    options
  );

export const useCsvImportResults = (
  params: GetCsvImportResultsParams,
  options?: UseQueryOptions<CsvImportResult[], AxiosError, CsvImportResult[]>
): QueryObserverResult<CsvImportResult[], AxiosError> =>
  useQuery<CsvImportResult[], AxiosError>(
    ['csvImportResults', params],
    () => api_getCsvImportResults(params),
    options
  );
