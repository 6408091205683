import tw, { css, styled } from 'twin.macro';

type CollectionTagProps = {
  isDefault?: boolean;
};
export const CollectionTag = styled.div<CollectionTagProps>(({ isDefault }) => [
  css`
    ${tw`flex flex-row items-baseline`}

    & .ant-tag {
      ${tw`text-14 font-500 rounded-4 px-10 py-2 bg-light-gray`}
    }
  `,

  isDefault &&
    css`
      & .ant-tag {
        ${tw`bg-light-gray-active`}
      }

      & .default-label {
        ${tw`text-12`}
      }
    `
]);
