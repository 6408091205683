import { useCallback } from 'react';

import useDomainName from './useDomainName';
import useSchemaName from './useSchemaName';

import getDomainRoute from 'utils/getDomainRoute';

type DomainRouteParams = {
  path?: string;
  query?: Record<string, string | number>;
};

export type GetDomainRouteHandler = (routeString: string, params?: DomainRouteParams) => string;

const useDomainRoute = (): GetDomainRouteHandler => {
  const domainName = useDomainName();
  const schemaName = useSchemaName();

  const buildRoute = useCallback(
    (routeString: string, params?: DomainRouteParams) => {
      if (routeString.search(':schemaName') === -1) {
        return getDomainRoute(routeString, domainName, undefined, params);
      }

      return getDomainRoute(routeString, domainName, schemaName, params);
    },
    [domainName, schemaName]
  );

  return buildRoute;
};

export default useDomainRoute;
