import React from 'react';

import DocsTooltip from './docsTooltip';

import { DocId } from 'services/api/domain/hint';

type TooltipLabelProps = {
  docId: DocId;
  label: JSX.Element | string;
};

const TooltipLabel: React.FC<TooltipLabelProps> = ({ label, docId }) => (
  <>
    {label}
    <DocsTooltip docId={docId} />
  </>
);

export default TooltipLabel;
