import React, { useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { FormItem, useFormContext } from '@phoenix-systems/react-form';
import { getRoute } from '@phoenix-systems/react-router';
import { Form, Input, Transfer } from 'antd';
import { TransferItem } from 'antd/lib/transfer';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useImmer } from 'use-immer';

import * as F from 'components/_styled/formSc';
import DocsTooltip from 'components/docs/components/docsTooltip';
import TooltipLabel from 'components/docs/components/docsTooltip/tooltipLabel';
import Button from 'components/ui/button';
import FormContainer from 'components/ui/formContainer';
import routes from 'config/routes/routes';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import useNotification from 'hooks/useNotification';
import { useDomains } from 'services/api/domain/domain';
import { DocId } from 'services/api/domain/hint';
import { useOrgUnits } from 'services/api/domain/orgUnit';
import { api_updateUser, UpdateUserParams, User } from 'services/api/domain/user';
import history from 'services/history';
import { st_triggersTable_setSelectedKey } from 'services/store/triggersTable/triggersTable.actions';

const LABEL_WIDTH = 140;

type UserFormProps = {
  data: User;
};

type UserFormState = {
  isInitialized: boolean;
  username?: string;
  orgUnits: TransferItem[];
  domains: TransferItem[];
  domainAdded: string[];
  domainRemoved: string[];
  activeDomains: string[];
};

const UserForm: React.FC<UserFormProps> = ({ data }) => {
  const domainQuery = useDomains();
  const orgUnitQuery = useOrgUnits();
  const [form] = Form.useForm<User>();
  const [addNotification] = useNotification();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [formState, formActions] = useFormContext(form, data);
  const copy = useCopyToClipboard();

  const [state, setState] = useImmer<UserFormState>({
    isInitialized: false,
    orgUnits: [],
    domains: [],
    domainAdded: [],
    domainRemoved: [],
    activeDomains: []
  });

  const updateMutation = useMutation((params: UpdateUserParams) => api_updateUser(params), {
    onSuccess: resData => {
      addNotification({
        type: 'success',
        message: i18n._(t`Successfully updated user "${resData.username}".`)
      });

      dispatch(st_triggersTable_setSelectedKey(resData.username));

      if (state.username !== resData.username) {
        history.push(getRoute(routes.userAdmin.user.single, resData.username));
      } else {
        queryClient.refetchQueries('user');
        updateMutation.reset();
        formActions.reset(resData);
      }
    },
    onError: () => {
      addNotification({
        type: 'error',
        message: i18n._(t`Failed to update user "${state.username}".`)
      });
    }
  });

  const handleReset = () => {
    form.setFieldsValue({ ...data });
    formActions.reset(data);
  };

  const handleSave = () => {
    form.validateFields().then(formData => {
      updateMutation.mutate({
        data: formData,
        initData: data,
        domainAdded: state.domainAdded,
        domainRemoved: state.domainRemoved
      });
    });
  };

  const handleOrgUnitSelectChange = (
    sourceSelectedKeys: string[],
    targetSelectedKeys: string[]
  ) => {
    form.setFieldsValue({ ...form.getFieldsValue(), orgUnits: targetSelectedKeys });
  };

  /*   const handleDomainSelectChange = (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
    form.setFieldsValue({ ...form.getFieldsValue(), domains: targetSelectedKeys });
  };

  const handleTrackDomainChanged = (
    targetKeys: string[],
    direction: TransferDirection,
    moveKeys: string[]
  ) => {
    setState(draft => {
      draft.activeDomains = targetKeys;
    });

    moveKeys.forEach(key => {
      if (direction === 'right' && !state.domainAdded.includes(key)) {
        setState(draft => {
          draft.domainRemoved = draft.domainRemoved.filter(d => d !== key);
          draft.domainAdded = [...draft.domainAdded, key];
        });
      }

      if (direction === 'left' && !state.domainRemoved.includes(key)) {
        setState(draft => {
          draft.domainRemoved = [...draft.domainRemoved, key];
          draft.domainAdded = draft.domainAdded.filter(d => d !== key);
        });
      }
    });
  }; */

  useEffect(() => {
    if (orgUnitQuery.data) {
      setState(draft => {
        draft.orgUnits = orgUnitQuery.data.map(orgUnit => ({
          key: orgUnit.name,
          title: orgUnit.name,
          description: orgUnit.name,
          choosen: data.orgUnits?.includes(orgUnit.name)
        }));
      });
    }
  }, [orgUnitQuery.data, data, setState]);

  useEffect(() => {
    if (domainQuery.data) {
      setState(draft => {
        draft.domains = domainQuery.data.map(domain => ({
          key: domain.domainName,
          title: domain.domainName,
          description: domain.domainName,
          //   disabled: DEFAULT_USERS.includes(data.username),
          choosen: data.domains?.includes(domain.domainName)
        }));
      });
    }
  }, [domainQuery.data, data, setState]);

  return (
    <FormContainer
      title={
        <>
          <FontAwesomeIcon icon={['fas', 'user']} />
          <Trans>Edit user {data?.username}</Trans>
          <DocsTooltip docId={DocId.SCHEDULER_TRIGGER} />
        </>
      }
      buttons={
        <>
          <Button
            action="reset"
            disabled={!formState.hasChanged || updateMutation.isLoading}
            onClick={handleReset}
          />
          <Button
            action="save"
            onClick={handleSave}
            loading={updateMutation.isLoading}
            disabled={!formState.hasChanged || !formState.isValid}
          />
        </>
      }
    >
      <F.StyledForm
        form={form}
        name="user-form"
        onValuesChange={formActions.onValuesChange}
        onFieldsChange={formActions.onFieldsChange}
        initialValues={data}
        $labelWidth={LABEL_WIDTH}
        colon={false}
      >
        {data?.id && (
          <FormItem
            label={<TooltipLabel docId={DocId.USERADMIN_USER_ID} label={<Trans>Id</Trans>} />}
            messageVariables={{ name: i18n._(t`Id`) }}
            name="id"
            rules={[{ required: true }]}
            registerField={formActions.registerField}
          >
            <Input
              readOnly
              addonAfter={<Button icon={['fas', 'copy']} onClick={() => copy(data.id)} />}
            />
          </FormItem>
        )}
        <FormItem
          label={
            <TooltipLabel docId={DocId.USERADMIN_USER_USERNAME} label={<Trans>Username</Trans>} />
          }
          messageVariables={{ name: i18n._(t`Username`) }}
          name="username"
          validateFirst
          rules={[{ required: true }]}
          registerField={formActions.registerField}
        >
          <Input
            readOnly
            addonAfter={<Button icon={['fas', 'copy']} onClick={() => copy(data.username)} />}
          />
        </FormItem>

        <FormItem
          label={
            <TooltipLabel
              docId={DocId.USERADMIN_USER_0RGANISATIONS}
              label={<Trans>Organisations</Trans>}
            />
          }
          className="transfer"
          name="orgUnits"
          registerField={formActions.registerField}
        >
          <Transfer
            dataSource={state.orgUnits}
            titles={[i18n._(t`Unauthorized Organisations`), i18n._(t`Authorized Organisations`)]}
            targetKeys={formState.data?.orgUnits}
            onSelectChange={handleOrgUnitSelectChange}
            locale={{
              itemUnit: i18n._('Organisation'),
              itemsUnit: i18n._('Organisations'),
              selectInvert: i18n._('Invert selection'),
              selectAll: i18n._('Select all organisations')
            }}
            render={item => (
              <div>
                <FontAwesomeIcon icon={['fas', 'building']} className="item-icon" />
                <span>{item.title || ''}</span>
              </div>
            )}
          />
        </FormItem>

        {/*  <FormItem
          label={<TooltipLabel docId={DocId.DOMAIN_ADMINISTRATOR} label={<Trans>Domains</Trans>} />}
          className="transfer"
          name="domains"
          registerField={formActions.registerField}
        >
          <Transfer
            dataSource={state.domains}
            titles={[i18n._(t`Unauthorized Domains`), i18n._(t`Authorized Domains`)]}
            targetKeys={state.activeDomains}
            onChange={handleTrackDomainChanged}
            locale={{
              itemUnit: i18n._('Domain'),
              itemsUnit: i18n._('Domains'),
              selectInvert: i18n._('Invert selection'),
              selectAll: i18n._('Select all domains')
            }}
            render={item => (
              <div>
                <FontAwesomeIcon icon={['fas', 'globe']} className="item-icon" />
                <span>{item.title || ''}</span>
              </div>
            )}
          />
        </FormItem> */}
      </F.StyledForm>
    </FormContainer>
  );
};

export default UserForm;
