import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import * as T from 'components/_styled/tableSc';
import useDomainName from 'hooks/useDomainName';
import { CsvImport } from 'services/api/domain/csvImport';
import { useTriggers } from 'services/api/domain/trigger';
import { st_csvImportsTable_setSingleView } from 'services/store/csvImportsTable/csvImportsTable.actions';
import { st_csvImportsTable_getSingleView } from 'services/store/csvImportsTable/csvImportsTable.selectors';
import { CsvImportTableSingleView } from 'services/store/csvImportsTable/csvImportsTable.types';

const { TabPane } = Tabs;

type CsvImportTableNavProps = {
  data: CsvImport;
};

const CsvImportTableNav: React.FC<CsvImportTableNavProps> = ({ data }) => {
  const domainName = useDomainName();
  const triggersQuery = useTriggers({ domainName }, { enabled: false });
  const triggers = triggersQuery.data?.filter(t => t.taskName === data?.name);

  const view = useSelector(st_csvImportsTable_getSingleView);
  const dispatch = useDispatch();

  const handleTabChange = (value: string) => {
    dispatch(st_csvImportsTable_setSingleView(value as CsvImportTableSingleView));
  };

  return (
    <T.SingleNav>
      <T.TabNavigation defaultActiveKey="1" onChange={handleTabChange} activeKey={view}>
        <TabPane
          tab={
            <>
              <FontAwesomeIcon icon={['fas', 'info-circle']} />
              <Trans>Overview</Trans>
            </>
          }
          key="OVERVIEW"
        />
        <TabPane
          tab={
            <>
              <FontAwesomeIcon icon={['fas', 'exchange']} />
              <Trans>FTP connection</Trans>
            </>
          }
          disabled={!data.ftpConnection?.name}
          key="FTP_CONNECTION"
        />
        <TabPane
          tab={
            <>
              <FontAwesomeIcon icon={['fas', 'project-diagram']} />
              <Trans>Table mappers ({data.columnEntityMapping?.length || 0})</Trans>
            </>
          }
          key="MAPPERS"
        />
        <TabPane
          tab={
            <>
              <FontAwesomeIcon icon={['fas', 'clock']} />
              <Trans>Scheduler ({triggers?.length || 0})</Trans>
            </>
          }
          key="SCHEDULERS"
        />
        <TabPane
          tab={
            <>
              <FontAwesomeIcon icon={['fas', 'history']} />
              <Trans>Logs</Trans>
            </>
          }
          key="LOGS"
        />
      </T.TabNavigation>
    </T.SingleNav>
  );
};

export default CsvImportTableNav;
