import React from 'react';

import { Trans } from '@lingui/macro';
import { Desktop } from '@phoenix-systems/react-layout';
import { Redirect, useHistory } from 'react-router';

import AppLayout from 'components/app/components/appLayout';
import Breadcrumbs from 'components/app/components/breadcrumbs';
import Button from 'components/ui/button';
import Page from 'components/ui/page';
import OrgUnitsTable from 'components/userAdmin/components/orgUnitsTable';
import routes from 'config/routes/routes';
import { useIsSuperadmin } from 'hooks/useIsSuperAdmin';

const OrgUnitListPage: React.FC = () => {
  const isSuperadmin = useIsSuperadmin();
  const history = useHistory();

  if (!isSuperadmin) {
    return <Redirect to={routes.dashboard.root} />;
  }

  return (
    <AppLayout>
      <Page
        scrollableProps={{ scrollId: 'page-scroll' }}
        content={<OrgUnitsTable />}
        title={<Trans>Organisations</Trans>}
        noScroll
        titleTop={
          <Desktop>
            <Breadcrumbs />
          </Desktop>
        }
        titleElements={
          <Button action="create" onClick={() => history.push(routes.userAdmin.orgunit.create)}>
            <Trans>Create Organisation</Trans>
          </Button>
        }
      />
    </AppLayout>
  );
};

export default OrgUnitListPage;
