import { ListQueryParams } from '@phoenix-systems/react-table/dist/types/types';

export enum OrgUnitsTableActionTypes {
  SET_PARAMS = 'triggersTable/SET_PARAMS',
  SET_SINGLE_VIEW = 'triggersTable/SET_SINGLE_VIEW',
  SET_SELECTED_KEY = 'triggersTable/SET_SELECTED_KEY',
  RESET = 'triggersTable/RESET'
}

export type OrgUnitsTableSingleView = 'OVERVIEW';

export type OrgUnitsTableState = Readonly<{
  selectedKey?: string;
  params: ListQueryParams;
  singleView: OrgUnitsTableSingleView;
}>;
