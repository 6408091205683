import React from 'react';

import { Trans } from '@lingui/macro';

import AppLayout from 'components/app/components/appLayout';
import CaptureStructureForm from 'components/domain/components/captureStrucutreForm';
import DomainPage from 'components/domain/components/domainPage';
import routes from 'config/routes/routes';

const CaptureStructurePage: React.FC = () => (
  <AppLayout>
    <DomainPage
      route={routes.domain.dataDesigner.captureStructure}
      schemaSelector
      title={<Trans>Capture JSON structure</Trans>}
      noScroll
      className="capture-structure"
      content={<CaptureStructureForm />}
    />
  </AppLayout>
);

export default CaptureStructurePage;
