import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Menu } from 'antd';
import { omit } from 'lodash';
import { useDispatch } from 'react-redux';
import { useImmer } from 'use-immer';

import EntityEdgeName from 'components/domain/components/domainDiagram/entityEdge/entityEdgeName';
import EntityEdgeFormDialog from 'components/domain/components/domainDiagram/entityEdgeFormDialog';
import { EntityEdge, EntityEdgeFormData } from 'services/api/domain/domainDiagram';
import {
  st_domainDiagram_setDialogOpen,
  st_domainDiagram_updateEdgeData
} from 'services/store/domainDiagram/domainDiagram.actions';

type EditEdgeActionProps = {
  edge: EntityEdge;
  type: 'CONTEXT_NAV' | 'EDGE_NAV';
};

type EditEdgeActionState = {
  isDialogOpen: boolean;
};

const EditEdgeAction: React.FC<EditEdgeActionProps> = props => {
  const pr = omit(props, ['edge', 'closeNavigation', 'type']);
  const { edge, type } = props;
  const dispatch = useDispatch();
  const [state, setState] = useImmer<EditEdgeActionState>({
    isDialogOpen: false
  });

  const handleDialogClose = () => {
    dispatch(st_domainDiagram_setDialogOpen(false));
    setState(draft => {
      draft.isDialogOpen = false;
    });
  };

  const handleEditEdgeRequest = () => {
    setState(draft => {
      draft.isDialogOpen = true;
    });
    dispatch(st_domainDiagram_setDialogOpen(true));
  };

  const handleUpdateEdge = (formData: EntityEdgeFormData) => {
    const edgeData = {
      ...edge.data,
      aggregationType: formData.aggregationType,
      relationType: formData.relationType
    };

    dispatch(st_domainDiagram_updateEdgeData(edgeData, edge.id));
    handleDialogClose();
  };

  return (
    <>
      {type === 'CONTEXT_NAV' && (
        <Menu.Item
          {...pr}
          key={`edit-edge_${edge.id}`}
          icon={<FontAwesomeIcon className="anticon" icon={['fas', 'location']} />}
          onClick={handleEditEdgeRequest}
        >
          <EntityEdgeName edge={edge} />
        </Menu.Item>
      )}

      {type === 'EDGE_NAV' && (
        <Menu.Item
          {...pr}
          key={`edit-edge_${edge.id}`}
          icon={<FontAwesomeIcon className="anticon" icon={['fas', 'pen']} />}
          onClick={handleEditEdgeRequest}
        >
          <Trans>Edit edge</Trans>
        </Menu.Item>
      )}

      <EntityEdgeFormDialog
        data={edge.data as EntityEdgeFormData}
        isOpen={state.isDialogOpen}
        edge={edge}
        close={handleDialogClose}
        insert={handleUpdateEdge}
        showInfo
      />
    </>
  );
};

export default EditEdgeAction;
