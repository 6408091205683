import React, { useCallback, useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { useAuthActions } from '@phoenix-systems/react-keycloak-auth';
import { AxiosError } from 'axios';
import { CSSProperties } from 'styled-components';

import * as S from './errorInfoSc';

import Button from 'components/ui/button';
import { log } from 'utils';

export type ErrorInfoProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: AxiosError<any> | null;
  message?: string | JSX.Element;
  showResError?: boolean;
  asPageContent?: boolean;
  reset?: () => void;
  isBlob?: boolean;
  style?: CSSProperties;
  className?: string;
};

const ErrorInfo: React.FC<ErrorInfoProps> = ({
  showResError,
  error,
  message,
  reset,
  asPageContent,
  isBlob,
  style,
  className
}) => {
  const { logout } = useAuthActions();
  const errorStatus = error?.response?.status;
  const [errorMsg, setErrorMsg] = useState<string>();

  const readError = useCallback(async () => {
    if (!error) {
      return;
    }

    if (isBlob && error.response?.data) {
      try {
        const blobData = await (error.response.data as Blob).text();

        if (typeof blobData === 'string') {
          setErrorMsg(blobData);
        }
      } catch (e) {
        log(e);
      }

      return;
    }

    if (error.response?.data && error.response?.data.message) {
      setErrorMsg(error.response.data.message);

      return;
    }

    if (typeof error.response?.data === 'string') {
      setErrorMsg(error.response.data);

      return;
    }

    if (error.message) {
      setErrorMsg(error.message);
    }
  }, [error, isBlob]);

  useEffect(() => {
    readError();
  }, [error, readError]);

  useEffect(() => {
    if (errorStatus === 401) {
      /*  logout({
        onSuccess: () => {
          window.location.href = window.location.origin;
        }
      }); */
    }
  }, [errorStatus, logout]);

  return (
    <S.StyledAlert
      $asPageContent={asPageContent}
      icon={<FontAwesomeIcon icon={['fas', 'exclamation-circle']} />}
      className={className}
      message={
        <S.MessageContainer>
          <S.Message>
            {message && (
              <>
                <Trans>Error</Trans>: {message}
              </>
            )}
            <S.ErrorMessage>
              {showResError !== false && errorMsg && (
                <S.ErrorMessage>[ {errorMsg} ]</S.ErrorMessage>
              )}
            </S.ErrorMessage>
          </S.Message>
          <S.Actions>
            {reset && (
              <>
                <Button icon={['fas', 'sync']} type="link" onClick={() => reset()}>
                  <Trans>Retry</Trans>
                </Button>
              </>
            )}
          </S.Actions>
        </S.MessageContainer>
      }
      type="error"
      showIcon
      style={style}
    />
  );
};

export default ErrorInfo;
