import React from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Trans } from '@lingui/macro';
import { useHistory } from 'react-router-dom';

import * as S from './exceptionSc';

import Button from 'components/ui/button';
import routes from 'config/routes/routes';
import history from 'services/history';

export type ExceptionProps = {
  title?: React.ReactNode | string;
  message?: React.ReactNode | string;
  icon?: IconProp;
  buttons?: React.ReactNode;
  code: number;
};

const Exception: React.FC<ExceptionProps> = ({ message, buttons, code }) => {
  const { goBack } = useHistory();

  return (
    <S.Wrapper>
      <S.Content>
        <div className="left">
          <div>{code}</div>
        </div>
        <div className="right">
          <div>
            {message && <S.Message>{message}</S.Message>}
            <S.Buttons>
              {history.length > 1 && (
                <Button type="primary" onClick={goBack}>
                  <Trans>Back</Trans>
                </Button>
              )}
              <Button onClick={() => history.push(routes.dashboard.domains)}>Dashboard</Button>
              {buttons}
            </S.Buttons>
          </div>
        </div>
      </S.Content>
    </S.Wrapper>
  );
};

export default Exception;
