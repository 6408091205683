import React from 'react';

import { Trans } from '@lingui/macro';
import { Desktop } from '@phoenix-systems/react-layout';

import * as P from 'components/_styled/pageSc';
import AppLayout from 'components/app/components/appLayout';
import Breadcrumbs from 'components/app/components/breadcrumbs';
import DomainPage from 'components/domain/components/domainPage';
import TriggerForm from 'components/trigger/components/triggerForm';
import TriggerHeaderButtons from 'components/trigger/components/triggerHeaderButtons';
import routes from 'config/routes/routes';
import useDomainName from 'hooks/useDomainName';
import useIsDomain from 'hooks/useIsDomain';
import { Trigger } from 'services/api/domain/trigger';

const DEFAULT_VALUES: Partial<Trigger> = {
  active: true,
  periodicity: 'NONE'
};

const TriggerCreatePage: React.FC = () => {
  const isDomain = useIsDomain();
  const domainName = useDomainName();

  if (!isDomain) {
    return (
      <AppLayout>
        <P.StyledPage
          scrollableProps={{ scrollId: 'page-scroll' }}
          title={<Trans>Create trigger</Trans>}
          content={<TriggerForm data={DEFAULT_VALUES} mode="CREATE" />}
          titleTop={
            <Desktop>
              <Breadcrumbs />
            </Desktop>
          }
          titleElements={<TriggerHeaderButtons hasCalendar hasList />}
        />
      </AppLayout>
    );
  }

  return (
    <AppLayout>
      <DomainPage
        route={routes.domain.scheduler.create}
        title={<Trans>Create trigger</Trans>}
        content={
          <TriggerForm isDomain data={{ taskDomain: domainName, active: true }} mode="CREATE" />
        }
        headerButtons={<TriggerHeaderButtons hasCalendar hasList />}
      />
    </AppLayout>
  );
};

export default TriggerCreatePage;
