import React from 'react';

import { Trans } from '@lingui/macro';
import { getRoute } from '@phoenix-systems/react-router';
import { Col, Row } from 'antd';

import * as S from '../orgUnitsTableSc';

import * as T from 'components/_styled/tableSc';
import DataItem from 'components/ui/dataItem';
import routes from 'config/routes/routes';
import { OrgUnit } from 'services/api/domain/orgUnit';
import history from 'services/history';

type OrgUnitsTableOverviewProps = {
  data?: OrgUnit;
};

const OrgUnitsTableOverview: React.FC<OrgUnitsTableOverviewProps> = ({ data }) => {
  const handleEditUser = () => {
    if (data?.name) {
      history.push(getRoute(routes.userAdmin.orgunit.single, data.name));
    }
  };

  if (!data) {
    return null;
  }

  return (
    <T.SingleContentContainer>
      <Row gutter={[12, 12]}>
        <Col xs={24}>
          <ul>
            <li>
              <DataItem name={<Trans>Id</Trans>} value={data.id} breakWord hasCopy />
            </li>
            <li>
              <DataItem
                name={<Trans>Name</Trans>}
                value={
                  <S.StyledLink to={getRoute(routes.userAdmin.user.single, data.name)}>
                    {data.name}
                  </S.StyledLink>
                }
                hasCopy
                copyValue={data.name}
                onEdit={handleEditUser}
              />
            </li>
            <li>
              <DataItem name={<Trans>Description</Trans>} value={data.description} />
            </li>
          </ul>
        </Col>
        {/*  <Col xs={12}>
          <ul>
            <li>
              <DataItem
                name={<Trans>Description</Trans>}
                value={
                  <>
                    {data.admins?.map(admin => (
                      <S.StyledLink key={admin} to={getRoute(routes.userAdmin.user.single, admin)}>
                        {admin}
                        <FontAwesomeIcon icon={['fas', 'pen']} />
                      </S.StyledLink>
                    ))}
                  </>
                }
              />
            </li>
          </ul>
        </Col> */}
      </Row>
    </T.SingleContentContainer>
  );
};

export default OrgUnitsTableOverview;
