import React, { useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { FormItem, useFormContext } from '@phoenix-systems/react-form';
import { Form } from 'antd';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useImmer } from 'use-immer';

import EMPTY_META_DATA from './medata.config';
import * as S from './metadataCreateFormSc';

import * as F from 'components/_styled/formSc';
import DocsTooltip from 'components/docs/components/docsTooltip';
import TooltipLabel from 'components/docs/components/docsTooltip/tooltipLabel';
import Button from 'components/ui/button';
import ErrorInfo from 'components/ui/errorInfo';
import FormContainer from 'components/ui/formContainer';
import MonacoEditor from 'components/ui/monacoEditor';
import routes from 'config/routes/routes';
import useApiKey from 'hooks/useApiKey';
import useDomainName from 'hooks/useDomainName';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';
import useNotification from 'hooks/useNotification';
import useOnDomainSchemaChange from 'hooks/useOnDomainSchemaChange';
import useSchemaName from 'hooks/useSchemaName';
import { DocId } from 'services/api/domain/hint';
import {
  api_createMetadata,
  CreateMetadataParams,
  CreateMetadataResponse,
  EntityMetaData
} from 'services/api/domain/metadata';

type MetadataCreateFormState = {
  simpleClassName?: string;
};

type MetadatFormParams = {
  data: EntityMetaData;
};

const MetadataCreateForm: React.FC = () => {
  const domainName = useDomainName();
  const [form] = Form.useForm<MetadatFormParams>();
  const schema = useSchemaName();
  const [addNotification] = useNotification();
  const [state, setState] = useImmer<MetadataCreateFormState>({
    simpleClassName: undefined
  });
  const [formState, formActions] = useFormContext(form, { data: {} } as Partial<MetadatFormParams>);
  const goto = useGoToDomainRoute();
  const apiKey = useApiKey();

  const createMutation = useMutation<CreateMetadataResponse, AxiosError, CreateMetadataParams>(
    payload => api_createMetadata(payload),
    {
      onSuccess: () => {
        addNotification({
          type: 'success',
          message: i18n._(t`Successfully created metadata".`)
        });

        goto(
          routes.domain.queryData.metaData,
          state.simpleClassName ? { path: state.simpleClassName } : undefined
        );
      },
      onError: () => {
        addNotification({
          type: 'error',
          message: i18n._(t`Failed to create metadata".`)
        });
      }
    }
  );
  const { current: createMutationRef } = useRef(createMutation);

  const handleReset = () => {
    createMutation.reset();
    formActions.reset({ data: {} }, false);
  };

  const handleSave = () => {
    if (apiKey !== '') {
      form.validateFields().then(formData => {
        setState(draft => {
          draft.simpleClassName = formData.data.simpleClassName;
        });

        createMutation.mutate({
          data: [formData.data],
          schema,
          domainName,
          apiKey
        });
      });
    }
  };

  useOnDomainSchemaChange(() => {
    createMutationRef.reset();
    formActions.reset({
      data: { ...EMPTY_META_DATA, domain: domainName, application: schema }
    });
  });

  return (
    <FormContainer
      isStickyDisabled
      maxWidth={1100}
      fullHeight
      title={
        <>
          <FontAwesomeIcon icon={['fas', 'code']} />
          <Trans>Add new Metadata</Trans>
          <DocsTooltip docId={DocId.DOMAIN_QUERY_METADATA} />
        </>
      }
      buttons={
        <>
          <Button
            action="reset"
            mutation={createMutation}
            formState={formState}
            onClick={handleReset}
          >
            <Trans>Reset</Trans>
          </Button>
          <Button
            action="save"
            mutation={createMutation}
            formState={formState}
            onClick={handleSave}
          >
            <Trans>Save</Trans>
          </Button>
        </>
      }
      messages={
        <>
          {createMutation.isError && (
            <ErrorInfo
              error={createMutation.error}
              message={<Trans>Failed to create metadata of entity {state.simpleClassName}</Trans>}
            />
          )}
        </>
      }
    >
      <F.StyledForm
        form={form}
        layout="horizontal"
        validateTrigger="onChange"
        $labelWidth={115}
        name="metadata-query-form"
        onFieldsChange={formActions.onFieldsChange}
        onValuesChange={formActions.onValuesChange}
        initialValues={{
          data: { ...EMPTY_META_DATA, domain: domainName, application: schema }
        }}
        colon={false}
      >
        <S.FormWrapper>
          <div className="editor">
            <FormItem
              label={
                <TooltipLabel
                  docId={DocId.DOMAIN_JSON_IMPEX_IMPORT}
                  label={<Trans>JSON data</Trans>}
                />
              }
              messageVariables={{ name: i18n._(t`JSON data`) }}
              name="data"
              rules={[{ required: true }]}
              registerField={formActions.registerField}
            >
              <MonacoEditor height="100%" mode="JSON" />
            </FormItem>
          </div>
        </S.FormWrapper>
      </F.StyledForm>
    </FormContainer>
  );
};

export default MetadataCreateForm;
