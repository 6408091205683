import React from 'react';

import { Trans } from '@lingui/macro';
import { Desktop } from '@phoenix-systems/react-layout';
import { Redirect } from 'react-router-dom';

import AppLayout from 'components/app/components/appLayout';
import Breadcrumbs from 'components/app/components/breadcrumbs';
import Page from 'components/ui/page';
import OrgUnitForm from 'components/userAdmin/components/orgUnitForm';
import routes from 'config/routes/routes';
import { useIsSuperadmin } from 'hooks/useIsSuperAdmin';

const OrgUnitCreatePage: React.FC = () => {
  const isSuperadmin = useIsSuperadmin();

  if (!isSuperadmin) {
    return <Redirect to={routes.dashboard.root} />;
  }

  return (
    <AppLayout>
      <Page
        scrollableProps={{ scrollId: 'page-scroll' }}
        content={<OrgUnitForm />}
        title={<Trans>Create Organisation</Trans>}
        titleTop={
          <Desktop>
            <Breadcrumbs />
          </Desktop>
        }
      />
    </AppLayout>
  );
};

export default OrgUnitCreatePage;
