import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { getRoute } from '@phoenix-systems/react-router';
import { Menu } from 'antd';
import { omit } from 'lodash';

import routes from 'config/routes/routes';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';
import useIsDomain from 'hooks/useIsDomain';
import { Trigger } from 'services/api/domain/trigger';
import history from 'services/history';

const { Item } = Menu;

type EditTriggerActionProps = {
  data: Trigger;
};

const EditTriggerAction: React.FC<EditTriggerActionProps> = props => {
  const antProps = omit(props, ['data']);
  const { data } = props;
  const isDomain = useIsDomain();
  const goto = useGoToDomainRoute();

  const handleEditRequest = () => {
    if (isDomain) {
      goto(routes.domain.scheduler.single, { path: data.name });
    } else {
      history.push(getRoute(routes.scheduler.single, data.name));
    }
  };

  return (
    <Item
      {...antProps}
      key={`edit-trigger_${data.id}`}
      icon={<FontAwesomeIcon icon={['fas', 'pen']} />}
      onClick={handleEditRequest}
    >
      <Trans>Edit trigger</Trans>
    </Item>
  );
};

export default EditTriggerAction;
