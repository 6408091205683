import { useCallback } from 'react';

import useDomainRoute from './useDomainRoute';

import { DomainRouteParams } from 'index.types';
import history from 'services/history';

type GetGoToDomainRouteHandler = (
  routeString: string,
  params?: DomainRouteParams,
  replace?: boolean
) => void;

const useGoToDomainRoute = (): GetGoToDomainRouteHandler => {
  const getDomainRoute = useDomainRoute();

  const goto = useCallback(
    (routeString: string, params?: DomainRouteParams, replace?: boolean) => {
      if (!replace) {
        history.push(getDomainRoute(routeString, params));

        return;
      }

      history.replace(getDomainRoute(routeString, params));
    },
    [getDomainRoute]
  );

  return goto;
};

export default useGoToDomainRoute;
