import React, { useEffect, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { FormItem, useFormContext } from '@phoenix-systems/react-form';
import { getRoute } from '@phoenix-systems/react-router';
import { Form, Input, Transfer } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { TransferDirection, TransferItem } from 'antd/lib/transfer';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { useImmer } from 'use-immer';

import * as F from 'components/_styled/formSc';
import DocsTooltip from 'components/docs/components/docsTooltip';
import TooltipLabel from 'components/docs/components/docsTooltip/tooltipLabel';
import Button from 'components/ui/button';
import FormContainer from 'components/ui/formContainer';
import routes from 'config/routes/routes';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import useNotification from 'hooks/useNotification';
import { DocId } from 'services/api/domain/hint';
import {
  api_createOrgUnit,
  api_updateOrgUnit,
  OrgUnit,
  UpdateOrgunitParams,
  useAdminsByOrgunit,
  useOrgUnits
} from 'services/api/domain/orgUnit';
import { useUsers } from 'services/api/domain/user';
import history from 'services/history';
import { st_triggersTable_setSelectedKey } from 'services/store/triggersTable/triggersTable.actions';
import { DEFAULT_ADMINS } from 'services/userPermissions/userPermissions.config';

const LABEL_WIDTH = 120;

type OrgUnitFormProps = {
  data?: OrgUnit;
};

type OrgUnitFormState = {
  isInitialized: boolean;
  name?: string;
  users: TransferItem[];
  adminsAdded: string[];
  adminsRemoved: string[];
  admins: string[];
};

const OrgUnitForm: React.FC<OrgUnitFormProps> = ({ data }) => {
  const userQuery = useUsers();
  const orgUnitsQuery = useOrgUnits();
  const [form] = Form.useForm<OrgUnit>();
  const [addNotification] = useNotification();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [formState, formActions] = useFormContext(form, data);
  const copy = useCopyToClipboard();
  const isCreate = useRouteMatch(routes.userAdmin.orgunit.create);
  const adminsQuery = useAdminsByOrgunit(data?.name || '', { enabled: false });
  const { current: adminsQueryRef } = useRef(adminsQuery);

  const [state, setState] = useImmer<OrgUnitFormState>({
    isInitialized: false,
    users: [],
    adminsAdded: [],
    adminsRemoved: [],
    admins: [...DEFAULT_ADMINS]
  });

  const createMutation = useMutation((params: OrgUnit) => api_createOrgUnit(params), {
    onSuccess: resData => {
      addNotification({
        type: 'success',
        message: i18n._(t`Successfully updated organisation "${resData.name}".`)
      });

      dispatch(st_triggersTable_setSelectedKey(resData.name));

      history.push(getRoute(routes.userAdmin.orgunit.single, resData.name));
    },
    onError: () => {
      addNotification({
        type: 'error',
        message: i18n._(t`Failed to create organisation "${state.name}".`)
      });
    }
  });

  const updateMutation = useMutation((params: UpdateOrgunitParams) => api_updateOrgUnit(params), {
    onSuccess: resData => {
      addNotification({
        type: 'success',
        message: i18n._(t`Successfully updated organisation "${resData.name}".`)
      });

      dispatch(st_triggersTable_setSelectedKey(resData.name));

      if (data?.name !== resData.name) {
        history.push(getRoute(routes.userAdmin.orgunit.single, resData.name));
      } else {
        queryClient.refetchQueries('orgUnit');
        updateMutation.reset();
        formActions.reset(resData);
      }
    },
    onError: () => {
      addNotification({
        type: 'error',
        message: i18n._(t`Failed to update organisation "${state.name}".`)
      });
    }
  });

  const handleReset = () => {
    form.setFieldsValue({ ...data });
    formActions.reset(data);
  };

  const handleSave = () => {
    form.validateFields().then(formData => {
      setState(draft => {
        draft.name = formData.name;
      });

      //  CRATE
      if (isCreate) {
        createMutation.mutate(formData);

        return;
      }

      // UPDATE
      if (data) {
        updateMutation.mutate({
          data: formData,
          initData: data,
          adminsAdded: state.adminsAdded,
          adminsRemoved: state.adminsRemoved
        });
      }
    });
  };

  const validateOrgUnitName = (rule: RuleObject, value: string) =>
    new Promise((resolve, reject) => {
      if (!orgUnitsQuery.data) {
        reject(new Error('orgunits not loaded'));

        return;
      }

      let match: OrgUnit | undefined;

      if (isCreate) {
        match = orgUnitsQuery.data.find(orgUnit => orgUnit.name === value);
      } else {
        match = orgUnitsQuery.data.find(
          orgUnit => orgUnit.name === value && formState.data?.id !== orgUnit.id
        );
      }

      if (match) {
        reject(new Error('orgunit with name already in use'));
      } else {
        resolve(value);
      }
    });

  const handleTrackAdminsChanged = (
    targetKeys: string[],
    direction: TransferDirection,
    moveKeys: string[]
  ) => {
    formActions.setChanged(true);

    setState(draft => {
      draft.admins = targetKeys;
    });

    moveKeys.forEach(key => {
      if (direction === 'right' && !state.adminsAdded.includes(key)) {
        setState(draft => {
          draft.adminsRemoved = draft.adminsRemoved.filter(a => a !== key);
          draft.adminsAdded = [...draft.adminsAdded, key];
        });
      }

      if (direction === 'left' && !state.adminsRemoved.includes(key)) {
        setState(draft => {
          draft.adminsRemoved = [...draft.adminsRemoved, key];
          draft.adminsAdded = draft.adminsAdded.filter(a => a !== key);
        });
      }
    });
  };

  useEffect(() => {
    const admins = [...DEFAULT_ADMINS];

    if (adminsQuery.data) {
      adminsQuery.data.forEach(admin => {
        admins.push(admin.username);
      });

      setState(draft => {
        draft.admins = admins;
      });
    }
  }, [adminsQuery.data, setState]);

  useEffect(() => {
    adminsQueryRef.refetch();
  }, [data?.name, adminsQueryRef]);

  useEffect(() => {
    if (userQuery.data) {
      setState(draft => {
        draft.users = userQuery.data.map(user => ({
          key: user.username,
          title: user.username,
          description: user.username,
          disabled: DEFAULT_ADMINS.includes(user.username),
          choosen: data?.admins?.includes(user.username)
        }));
      });
    }
  }, [userQuery.data, data, setState]);

  useEffect(() => {
    if (!data) {
      formActions.reset();

      return;
    }

    formActions.reset(data);
  }, [data, formActions]);

  return (
    <FormContainer
      title={
        <>
          <FontAwesomeIcon icon={['fas', 'user']} />
          {!isCreate ? (
            <Trans>Edit organisation {data?.name}</Trans>
          ) : (
            <Trans>Create organisation</Trans>
          )}
          <DocsTooltip docId={DocId.SCHEDULER_TRIGGER} />
        </>
      }
      buttons={
        <>
          <Button
            action="reset"
            disabled={!formState.hasChanged || updateMutation.isLoading || createMutation.isLoading}
            onClick={handleReset}
          />
          <Button
            action="save"
            onClick={handleSave}
            loading={updateMutation.isLoading}
            disabled={!formState.hasChanged || !formState.isValid}
          />
        </>
      }
    >
      <F.StyledForm
        form={form}
        name="orgunit-form"
        onValuesChange={formActions.onValuesChange}
        onFieldsChange={formActions.onFieldsChange}
        initialValues={data}
        $labelWidth={LABEL_WIDTH}
        colon={false}
      >
        {!isCreate && (
          <FormItem
            label={<TooltipLabel docId={DocId.USERADMIN_ORG_ID} label={<Trans>Id</Trans>} />}
            messageVariables={{ name: i18n._(t`Id`) }}
            name="id"
            rules={[{ required: true }]}
            registerField={formActions.registerField}
          >
            <Input
              readOnly
              addonAfter={<Button icon={['fas', 'copy']} onClick={() => copy(data?.id)} />}
            />
          </FormItem>
        )}
        <FormItem
          label={<TooltipLabel docId={DocId.USERADMIN_ORG_NAME} label={<Trans>Name</Trans>} />}
          messageVariables={{ name: i18n._(t`Name`) }}
          name="name"
          validateFirst
          rules={[
            { required: true },
            {
              pattern: /^[A-Za-z0-9\-_]+$/,
              message: i18n._("Only allowed alphanumeric characters including '-' and '_'.")
            },
            {
              validator: validateOrgUnitName,
              message: i18n._(
                t`An organisation with name "${form.getFieldValue('name')}" already exists.`
              )
            }
          ]}
          registerField={formActions.registerField}
        >
          <Input />
        </FormItem>
        <FormItem
          label={
            <TooltipLabel
              docId={DocId.USERADMIN_ORG_DESCRIPTION}
              label={<Trans>Description</Trans>}
            />
          }
          messageVariables={{ name: i18n._(t`Description`) }}
          name="description"
          validateFirst
          registerField={formActions.registerField}
        >
          <Input.TextArea />
        </FormItem>

        {!isCreate && (
          <FormItem
            label={
              <TooltipLabel
                docId={DocId.USERADMIN_ORG_ADMINS}
                label={<Trans>Administrators</Trans>}
              />
            }
            className="transfer"
            name="admins"
            registerField={formActions.registerField}
          >
            <Transfer
              dataSource={state.users}
              titles={[
                i18n._(t`Unauthorized Administrators`),
                i18n._(t`Authorized Administrators`)
              ]}
              targetKeys={state.admins}
              onChange={handleTrackAdminsChanged}
              locale={{
                itemUnit: i18n._('Administrator'),
                itemsUnit: i18n._('Administrators'),
                selectInvert: i18n._('Invert selection'),
                selectAll: i18n._('Select all administrators')
              }}
              render={item => (
                <div>
                  <FontAwesomeIcon
                    icon={
                      item.key && state.admins?.includes(item.key)
                        ? ['fas', 'user']
                        : ['fas', 'user-alt-slash']
                    }
                    className="item-icon"
                  />
                  <span>{item.title || ''}</span>
                </div>
              )}
            />
          </FormItem>
        )}
      </F.StyledForm>
    </FormContainer>
  );
};

export default OrgUnitForm;
