import React from 'react';

import AppLayout from 'components/app/components/appLayout';
import TriggerSingle from 'components/trigger/components/triggerSingle';

const TriggerSinglePage: React.FC = () => (
  <AppLayout>
    <TriggerSingle />
  </AppLayout>
);

export default TriggerSinglePage;
