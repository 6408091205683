import tw, { styled, css } from 'twin.macro';

import { getRem } from 'utils';

export const Wrapper = styled.div(() => [
  css`
    ${tw`absolute top-0 left-0 right-0 bottom-0 flex flex-col`};
  `
]);

export const Info = styled.div(() => [
  css`
    ${tw`inline-block absolute text-10`};
    right: 220px;
    bottom: 8px;

    & span {
      ${tw`font-700`};
    }
  `
]);

export const Head = styled.div(() => [
  css`
    ${tw`mx-mobileX md:mx-desktopX mt-8 flex flex-row border-b border-light-gray pb-12`};

    & .ant-btn {
      ${tw`mr-12`};
    }

    & .ant-select {
      ${tw`mr-12`};
    }

    & .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      ${tw`h-32`};
    }

    & .ant-select-single .ant-select-selector .ant-select-selection-item {
      line-height: 30px;
    }
  `
]);

type EdgeNavigationProps = {
  active: boolean;
};

export const EdgeNavigation = styled.div<EdgeNavigationProps>(({ active }) => [
  css`
    ${tw`absolute shadow-lg border-ant-border-gray bg-white transition-all duration-200 opacity-0`}
    width: ${getRem(200)};
    height: ${getRem(200)};
    z-index: 2000;
  `,

  active &&
    css`
      ${tw`absolute shadow-lg border-ant-border-gray bg-white opacity-100`}
      z-index: 2000;
    `
]);
