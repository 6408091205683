import { RouteConfig } from '@phoenix-systems/react-router';

import { ViewConfig } from 'config/views/view.types';
import { DocId, DocRubric } from 'services/api/domain/hint';

export const generateRoutesFromConfigs = (configs: ViewConfig[]): RouteConfig[] => {
  const rts: RouteConfig[] = [];
  configs.forEach(config => {
    config.routes.forEach(route => {
      rts.push(route);
    });
  });

  return rts;
};

export const getSlug = (route?: string): string => {
  if (!route) {
    return '';
  }

  const parts = route.split('/');

  if (parts.length === 0) {
    return '';
  }

  return parts[parts.length - 1];
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getDocRoute = (rubric: DocRubric, docId?: DocId): string =>
  /*  if (!docId) {
    return getRoute(routes.docs.rubric, getRouteFromDocId(rubric));
  } */
  '';
// return getRoute(routes.docs.single, getRouteFromDocId(rubric), getRouteFromDocId(docId));
