import React, { useCallback, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { FileSelector, FormItem, useFormContext } from '@phoenix-systems/react-form';
import { Form } from 'antd';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

import * as F from 'components/_styled/formSc';
import DocsTooltip from 'components/docs/components/docsTooltip';
import TooltipLabel from 'components/docs/components/docsTooltip/tooltipLabel';
import Button from 'components/ui/button';
import ErrorInfo from 'components/ui/errorInfo';
import FormContainer from 'components/ui/formContainer';
import LoaderMessage from 'components/ui/loader/loaderMessage';
import fileSelectorLocales from 'config/langauge/fileSelector.locales';
import useDomainName from 'hooks/useDomainName';
import useNotification from 'hooks/useNotification';
import useOnDomainSchemaChange from 'hooks/useOnDomainSchemaChange';
import useSchemaName from 'hooks/useSchemaName';
import { api_dataJsonImport, DataImportParams } from 'services/api/domain/dataJsonImportExport';
import { DocId } from 'services/api/domain/hint';

const JsonImportForm: React.FC = () => {
  const domainName = useDomainName();
  const schema = useSchemaName();

  const getInitialValues = useCallback(
    () => ({ domainName, sourceDomainName: undefined }),
    [domainName]
  );
  const [form] = Form.useForm<Partial<DataImportParams>>();
  const [formState, formActions] = useFormContext(form, getInitialValues());
  const [addNotification] = useNotification();

  const getSuccessMessage = () => i18n._(t`Sucessfully imported data into domain "${domainName}".`);

  const importMutation = useMutation<string, AxiosError, DataImportParams>(
    params => api_dataJsonImport(params),
    {
      onSuccess: () => {
        addNotification({
          type: 'success',
          message: getSuccessMessage()
        });
      },
      onError: () => {
        addNotification({
          type: 'error',
          message: i18n._(t`Failed to import data into domain "${domainName}".`)
        });
      }
    }
  );
  const { current: importMutationRef } = useRef(importMutation);

  const handleImport = () => {
    form.validateFields().then(formData => {
      const payload = { ...formData, domainName, schema };
      importMutation.mutate(payload as DataImportParams);
    });
  };

  const handleReset = useCallback(() => {
    importMutationRef.reset();
    formActions.reset();
  }, [formActions, importMutationRef]);

  useOnDomainSchemaChange(handleReset);

  return (
    <FormContainer
      maxWidth={900}
      title={
        <>
          <FontAwesomeIcon icon={['fas', 'file-import']} />
          <Trans>Import JSON data</Trans>
          <DocsTooltip docId={DocId.DOMAIN_JSON_IMPEX_IMPORT} />
        </>
      }
      buttons={
        <>
          <Button
            action="reset"
            formState={formState}
            mutation={importMutation}
            onClick={handleReset}
          />
          <Button
            action="save"
            formState={formState}
            mutation={importMutation}
            icon={['fas', 'file-import']}
            onClick={handleImport}
          >
            <Trans>Import</Trans>
          </Button>
        </>
      }
      messages={
        <>
          {importMutation.isLoading && (
            <LoaderMessage>
              <Trans>JSON import in progress. This can take up to a few minutes.</Trans>
            </LoaderMessage>
          )}
          {importMutation.isSuccess && (
            <F.FormAlert
              message={getSuccessMessage()}
              type="success"
              showIcon
              description={
                <F.BoxedFormContainerSummary labelWidth={50}>
                  <p>
                    <span>Domain:</span> {domainName}
                  </p>
                  <p>
                    <span>Schema:</span> {schema}
                  </p>
                  <p>
                    <span>
                      <Trans>Message</Trans>: {importMutation.data}
                    </span>
                  </p>
                </F.BoxedFormContainerSummary>
              }
            />
          )}
          {importMutation.isError && (
            <ErrorInfo
              message={<Trans>Failed to import data.</Trans>}
              error={importMutation.error}
            />
          )}
        </>
      }
    >
      <F.StyledForm
        form={form}
        name="json-import-form"
        layout="horizontal"
        validateTrigger="onChange"
        $labelWidth={136}
        onFieldsChange={formActions.onFieldsChange}
        onValuesChange={formActions.onValuesChange}
        initialValues={getInitialValues()}
        colon={false}
      >
        <FormItem
          label={
            <TooltipLabel
              docId={DocId.DOMAIN_JSON_IMPEX_IMPORT_FILETYPE}
              label={<Trans>File to import</Trans>}
            />
          }
          messageVariables={{ name: i18n._(t`File to import`) }}
          name="file"
          rules={[{ required: true }]}
          registerField={formActions.registerField}
        >
          <FileSelector
            className="import-json"
            fileType="DOCUMENT"
            multiple={false}
            allowedFileTypes={['zip']}
            locales={fileSelectorLocales}
          />
        </FormItem>
      </F.StyledForm>
    </FormContainer>
  );
};

export default JsonImportForm;
