import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Scrollable } from '@phoenix-systems/react-layout';

import LanguageNavigation from '../languageNavigation';
import MainNavigation from '../mainNavigation';
import TopNavigation from '../topNavigation';
import UserNavigation from '../userNavigation';

import * as S from './mobileNavigationSc';

const MobileNavigation: React.FC = () => (
  <S.NavWrapper>
    <Scrollable scrollY>
      <div className="inner">
        <S.MobileNavSubTitle>
          <FontAwesomeIcon icon={['fas', 'globe-americas']} />
          <Trans>Language</Trans>
        </S.MobileNavSubTitle>
        <LanguageNavigation />
        <S.MobileNavDivider />
        <S.MobileNavSubTitle>
          <FontAwesomeIcon icon={['fas', 'server']} />
          <Trans>Bigdata service</Trans>
        </S.MobileNavSubTitle>
        <TopNavigation />
        <S.MobileNavDivider />
        <S.MobileNavSubTitle>
          <FontAwesomeIcon icon={['fas', 'globe']} />
          <Trans>Current Domain</Trans>
        </S.MobileNavSubTitle>
        <MainNavigation />
        <S.MobileNavDivider />
        <S.MobileNavSubTitle>
          <FontAwesomeIcon icon={['fas', 'user']} />
          <Trans>User</Trans>
        </S.MobileNavSubTitle>
        <UserNavigation />
      </div>
    </Scrollable>
  </S.NavWrapper>
);

export default MobileNavigation;
