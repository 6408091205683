import React, { useCallback, useEffect, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { FormItem, useFormContext } from '@phoenix-systems/react-form';
import { Form, Select } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

import * as F from 'components/_styled/formSc';
import useContainer from 'components/app/components/containerProvider/useContainer';
import DocsTooltip from 'components/docs/components/docsTooltip';
import TooltipLabel from 'components/docs/components/docsTooltip/tooltipLabel';
import Button from 'components/ui/button';
import ErrorInfo from 'components/ui/errorInfo';
import FormContainer from 'components/ui/formContainer';
import LoaderMessage from 'components/ui/loader/loaderMessage';
import useDomainFromCache from 'hooks/useDomainFromCache';
import useDomainsFromCache from 'hooks/useDomainsFromCache';
import useNotification from 'hooks/useNotification';
import { api_mergeDomains, MergeDomainParams } from 'services/api/domain/domain';
import { DocId } from 'services/api/domain/hint';

const { Option } = Select;

const MergeDomainForm: React.FC = () => {
  const domains = useDomainsFromCache();
  const { formContainer } = useContainer();
  const domain = useDomainFromCache();
  const getInitialValues = useCallback(
    () => ({ domainName: domain?.domainName, sourceDomainName: undefined }),
    [domain]
  );
  const [form] = Form.useForm<MergeDomainParams>();
  const [formState, formActions] = useFormContext(form, getInitialValues(), false);
  const [addNotification] = useNotification();

  const getSuccessMessage = () =>
    i18n._(
      t`Sucessfully merged domain "${form.getFieldValue(
        'sourceDomainName'
      )}" into target domain "${form.getFieldValue('domainName')}".`
    );

  const mergeMutation = useMutation<string, AxiosError, MergeDomainParams>(
    params => api_mergeDomains(params),
    {
      onSuccess: () => {
        addNotification({
          type: 'success',
          message: getSuccessMessage()
        });
      },
      onError: () => {
        addNotification({
          type: 'error',
          message: i18n._(
            t`Failed to merge domain "${form.getFieldValue(
              'sourceDomainName'
            )}" into domain "${form.getFieldValue('domainName')}".`
          )
        });
        formActions.reset();
      }
    }
  );
  const { current: mergeMutationRef } = useRef(mergeMutation);

  const handleMerge = () => {
    form.validateFields().then(formData => {
      mergeMutation.mutate(formData);
    });
  };

  const handleReset = useCallback(() => {
    mergeMutationRef.reset();
    formActions.reset();
  }, [mergeMutationRef, formActions]);

  const validateDomains = (rule: RuleObject, value: string) =>
    new Promise((resolve, reject) => {
      const compareField =
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        (rule as any).field === 'sourceDomainName' ? 'domainName' : 'sourceDomainName';

      if (value === form.getFieldValue(compareField)) {
        reject(new Error('Source and target domain are the same'));
      } else {
        resolve(value);
      }
    });

  useEffect(() => {
    handleReset();
  }, [domain, handleReset]);

  return (
    <FormContainer
      maxWidth={900}
      isStickyDisabled
      title={
        <>
          <FontAwesomeIcon icon={['fas', 'compress-arrows-alt']} />
          <Trans>Merge domains</Trans>
          <DocsTooltip docId={DocId.DOMAIN_ADMINISTRATION_MERGE_DOMAINS} />
        </>
      }
      buttons={
        <>
          <Button
            action="reset"
            mutation={mergeMutation}
            formState={formState}
            onClick={handleReset}
          />
          <Button
            action="save"
            mutation={mergeMutation}
            formState={formState}
            icon={['fas', 'compress-arrows-alt']}
            onClick={handleMerge}
          >
            <Trans>Merge</Trans>
          </Button>
        </>
      }
      messages={
        mergeMutation.isLoading || mergeMutation.isSuccess || mergeMutation.isError ? (
          <>
            {mergeMutation.isLoading && (
              <LoaderMessage showAfterTime={1000}>
                <Trans>Merge in progress. This can take up to a few minutes.</Trans>
              </LoaderMessage>
            )}
            {mergeMutation.isSuccess && (
              <F.FormAlert message={getSuccessMessage()} type="success" showIcon />
            )}
            {mergeMutation.isError && (
              <ErrorInfo
                message={
                  <Trans>
                    Failed to merge domain &quot;{form.getFieldValue('sourceDomainName')}&quot; into
                    domain &quot;{form.getFieldValue('domainName')}&quot;.
                  </Trans>
                }
                error={mergeMutation.error}
              />
            )}
          </>
        ) : undefined
      }
    >
      <F.StyledForm
        name="merge-domain-form"
        form={form}
        layout="horizontal"
        validateTrigger="onChange"
        $labelWidth={144}
        onFieldsChange={formActions.onFieldsChange}
        onValuesChange={formActions.onValuesChange}
        initialValues={getInitialValues()}
        colon={false}
      >
        <FormItem
          label={
            <TooltipLabel
              docId={DocId.DOMAIN_ADMINISTRATION_MERGE_DOMAINS_FROM}
              label={<Trans>Source domain</Trans>}
            />
          }
          messageVariables={{ name: i18n._(t`Source Domain`) }}
          name="sourceDomainName"
          rules={[
            { required: true },
            {
              validator: validateDomains,
              message: i18n._('Source and target domain should not be the same.')
            }
          ]}
          registerField={formActions.registerField}
        >
          <Select
            placeholder={<Trans>Select domain</Trans>}
            getPopupContainer={() => formContainer}
          >
            {domains?.map(d => (
              <Option key={d.id} value={d.domainName}>
                {d.domainName}
              </Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          label={
            <TooltipLabel
              docId={DocId.DOMAIN_ADMINISTRATION_MERGE_DOMAINS_TARGET}
              label={<Trans>Target domain</Trans>}
            />
          }
          messageVariables={{ name: i18n._(t`Target Domain`) }}
          name="domainName"
          rules={[
            { required: true },
            {
              validator: validateDomains,
              message: i18n._('Source and target domain should not be the same.')
            }
          ]}
          validateFirst
          registerField={formActions.registerField}
        >
          <Select
            placeholder={<Trans>Select target domain</Trans>}
            getPopupContainer={() => formContainer}
          >
            {domains.map(d => (
              <Option key={d.id} value={d.domainName}>
                {d.domainName}
              </Option>
            ))}
          </Select>
        </FormItem>
      </F.StyledForm>
    </FormContainer>
  );
};

export default MergeDomainForm;
