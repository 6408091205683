import tw, { css, styled } from 'twin.macro';

export const Alert = styled.div(() => [
  css`
    ${tw`font-500 text-12`};
    max-width: 20rem;
    & a {
      ${tw`text-secondary font-600`};
    }
  `
]);
