import { Domain } from 'domain';

import {
  AddUserToDomainParams,
  GetDomainsByUserParams,
  GetUserByNameParams,
  GetUserByOrgUnitParams,
  RemoveUserFromDomainParams,
  UpdateUserParams,
  User
} from './user.types';

import apiClient from 'services/api/apiClient';

const BASE_PATH = 'user';

/**
 * GET /api/user/myself
 */
export const api_getMyself = async (): Promise<User> => {
  const { data } = await apiClient.get(`/${BASE_PATH}/myself`);

  return data;
};

/**
 * GET /api/user/{orgunit}
 */
export const api_getUserByName = async (params: GetUserByNameParams): Promise<User> => {
  const { data } = await apiClient.get(`/${BASE_PATH}/byname/${params.username}`);

  return data;
};

/**
 * GET /api/user/{naem}
 */
export const api_getUsersByOrgUnit = async (params: GetUserByOrgUnitParams): Promise<User[]> => {
  const { data } = await apiClient.get(`/${BASE_PATH}/${params.orgUnit}`);

  return data.docs || [];
};

/**
 * GET /api/user (sueradmin only)
 */
export const api_getUsers = async (): Promise<User[]> => {
  const { data } = await apiClient.get(`/${BASE_PATH}`);

  return data.docs || [];
};

/**
 * GET /api/user/withdomains/{userId}
 */
export const api_getDomainsByUser = async (params: GetDomainsByUserParams): Promise<Domain[]> => {
  const { data } = await apiClient.get(`/${BASE_PATH}/withdomains/${params.userId}`);

  return data?.docs || [];
};

/**
 * PUT /api/user/remove/{userId}/{domain}
 */
export const api_removeUserFromDomain = async (
  params: RemoveUserFromDomainParams
): Promise<void> => {
  await apiClient.put(`/${BASE_PATH}/remove/${params.userId}/${params.domainId}`);

  return;
};

/**
 * PUT /api/user/add/{userId}/{domain}
 */
export const api_addUserToDomain = async (params: AddUserToDomainParams): Promise<void> => {
  await apiClient.put(`/${BASE_PATH}/add/${params.userId}/${params.domainId}`);

  return;
};

/**
 * PUT /api/user
 */
export const api_updateUser = async (params: UpdateUserParams): Promise<User> => {
  for (let i = 0; i < params.domainAdded.length; i++) {
    // Get num of each fruit
    await apiClient.put(`/${BASE_PATH}/add/${params.data.username}/${params.domainAdded[i]}`);
  }

  for (let i = 0; i < params.domainRemoved.length; i++) {
    // Get num of each fruit
    await apiClient.put(`/${BASE_PATH}/remove/${params.data.username}/${params.domainRemoved[i]}`);
  }

  const { data } = await apiClient.put(`/${BASE_PATH}`, params.data);

  return data;
};

/**
 * DELETE /api/user/{userid}
 */
export const api_deleteUser = async (username: string): Promise<void> => {
  await apiClient.delete(`/${BASE_PATH}/${username}`);

  return;
};
