import tw, { css, styled } from 'twin.macro';

type ContainerProps = {
  isReadonly: boolean;
};

export const Container = styled.div<ContainerProps>(({ isReadonly }) => [
  css`
    max-width: 90rem;
    & .hovering-toolbar {
      ${tw`bg-gray-900 text-white rounded duration-300`};
      width: unset;
      margin-top: -12px;

      & .hovering-toolbar-arrow {
        border-top: 6px solid #111827;
      }
    }

    & .slate-editable {
      ${tw`p-6`};

      & p.paragraph {
        ${tw`text-14 font-400`};
      }

      & h1.heading-one {
        ${tw`text-32 text-primary font-700 mt-28 mb-18`};
      }

      & h2.heading-two {
        ${tw`text-24  mt-24 mb-12 font-700`};
      }

      & h3.heading-three {
        ${tw`text-16 font-700 mb-12`};
      }

      & h4.heading-four {
        ${tw`text-14 font-700 mb-8`};
      }

      & blockquote.blockquote {
        ${tw`my-16 mx-32  border-l border-ant-border-gray pl-22 mx-auto`};
      }

      & ul {
        ${tw`text-14 pb-22 ml-18 pb-22 my-0`};
      }

      & a.link {
        ${tw`text-14 text-primary hover:text-secondary underline`};
      }

      & em {
        ${tw`text-14`};
        font-style: italic;
      }

      & strong {
        ${tw`font-700`};
      }
    }
  `,

  !isReadonly &&
    css`
      ${tw`border border-ant-border-gray rounded overflow-hidden`};
    `
]);

export const StyledOverlay = styled.div(() => [
  css`
    & .ant-btn {
      ${tw`bg-transparent`};
    }
  `
]);
