import { FileInfo, GetFileParams, UploadFileParams } from './file.types';

import apiClient from 'services/api/apiClient';

const BASE_PATH = 'files';

/**
 * MULTIPART
 * POST /api/files/{domain}/{scheme}
 *
 * apiKey required
 */
export const api_uploadFile = async (params: UploadFileParams): Promise<FileInfo> => {
  const formData = new FormData();
  formData.append('file', params.file);
  const { data } = await apiClient.uploadFile({
    uri: `${BASE_PATH}/${params.domainName}/${params.schema}?APIKEY=${params.apiKey}`,
    file: params.file,
    formData,
    onUploadProgress: params.onUploadProgress
  });

  return (data as FileInfo[])[0];
};

/**
 * BLOB
 * GET /api/files/{domain}/{scheme}/download/{fileId}
 *
 * apiKey required
 */
export const api_getFile = async (params: GetFileParams): Promise<Blob> => {
  const { data } = await apiClient.get(
    `/${BASE_PATH}/${params.domainName}/${params.schema}/download/${params.fileId}?APIKEY=${params.apiKey}`,
    {
      responseType: 'blob'
    }
  );

  return data;
};
