import appStyles from 'styles/appStyles';

const getRem = (px: number, asNumber?: boolean) => {
  if (asNumber) {
    return px / appStyles.htmlFontSize;
  }

  return `${px / appStyles.htmlFontSize}rem`;
};

export default getRem;
