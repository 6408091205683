import { ListQueryParams } from '@phoenix-systems/react-table/dist/types/types';

export const DB_IMPORT_LIST_DEFAULT_PAGE_SIZE = 20;

export enum CsvImportsTableActionTypes {
  SET_PARAMS = 'csvImportsTable/SET_PARAMS',
  SET_SINGLE_VIEW = 'csvImportsTable/SET_SINGLE_VIEW',
  SET_ACTIVE_KEY = 'csvImportsTable/SET_ACTIVE_KEY',
  RESET = 'csvImportsTable/RESET'
}

export type CsvImportTableSingleView =
  | 'OVERVIEW'
  | 'FTP_CONNECTION'
  | 'MAPPERS'
  | 'SCHEDULERS'
  | 'LOGS';

export type CsvImportsTableState = Readonly<{
  params: ListQueryParams;
  singleView: CsvImportTableSingleView;
  activeKey?: string;
}>;
