import { Language } from 'config/langauge/languages.types';

export enum AppActionTypes {
  SET_SIDEBAR_INITIALISED = 'app/SET_SIDEBAR_INITIALISED',
  SET_LANGUAGE = 'language/SET_LANGUAGE',
  RESET = 'app/RESET'
}

export type AppState = Readonly<{
  language: Language;
  isSidebarInitialised: boolean;
}>;
