import React, { useEffect, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Desktop, Mobile, useLayoutConfig } from '@phoenix-systems/react-layout';
import { Navigation, useNavigationActions } from '@phoenix-systems/react-navigation';
import { OpenOnLoadConfig } from '@phoenix-systems/react-navigation/dist/types/store';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';

import MainNavigationLoader from './mainNavigationLoader';
import * as S from './mainNavigationSc';

import useContainer from 'components/app/components/containerProvider/useContainer';
import getMainNavigationConfig from 'config/navigation/mainNavigation.config';
import routes from 'config/routes/routes';
import { useIsSuperadmin } from 'hooks/useIsSuperAdmin';
import useSchemaName from 'hooks/useSchemaName';
import { Domain, useDomain } from 'services/api/domain/domain';
import { st_app_getLanguage } from 'services/store/app/app.selectors';
import { st_domain_getDomainName } from 'services/store/domain/domain.selectors';
import { getUrlFragment } from 'utils';

const NAVIGATION_ID = 'main';

const customNavItems = {
  loader: MainNavigationLoader
};

const MainNavigation: React.FC = () => {
  const layoutConfig = useLayoutConfig();
  const { mainNavigationContainer } = useContainer();
  const { pathname } = useLocation();
  const match = useRouteMatch([`${routes.dashboard.root}`, routes.domain.root, routes.docs.root]);
  const [openKeysConfig, setOpenKeysConfig] = useState<OpenOnLoadConfig>();
  const domainName = useSelector(st_domain_getDomainName);
  const domainQuery = useDomain(domainName || '', { enabled: false });
  const baseRoute = `/${getUrlFragment(0)}`;
  const { setNavigationConfig, setOpenOnLoadConfig } = useNavigationActions();
  const { current: setNavigationConfigRef } = useRef(setNavigationConfig);
  const language = useSelector(st_app_getLanguage);
  const [currentDomain, setCurrentDomain] = useState<Domain>();
  const [isTransitionDisabled, setTransitionDisabled] = useState(true);
  const schemaName = useSchemaName();
  const isSuperadmin = useIsSuperadmin();

  useEffect(() => {
    if (domainQuery.data && !isEqual(currentDomain, domainQuery.data)) {
      setCurrentDomain(domainQuery.data);
    }
  }, [domainQuery.data, currentDomain]);

  useEffect(() => {
    if (match?.path) {
      let conf: OpenOnLoadConfig | undefined;

      if (match.path === routes.dashboard.root || match.path === routes.domain.root) {
        conf = {
          levels: [1]
        };
      }

      if (match.path === routes.docs.root) {
        conf = { activeItemPath: pathname };
      }

      if (!isEqual(conf, openKeysConfig)) {
        setOpenKeysConfig(conf);
        setOpenOnLoadConfig(NAVIGATION_ID, conf);
      }

      const config = getMainNavigationConfig({
        basePath: match.path,
        domainName,
        schemaName,
        domain: currentDomain,
        language,
        isSuperadmin
      });

      setNavigationConfig(NAVIGATION_ID, config);
    }
  }, [
    match?.path,
    domainName,
    schemaName,
    setNavigationConfigRef,
    language,
    currentDomain,
    openKeysConfig,
    pathname,
    isSuperadmin,
    setNavigationConfig,
    setOpenOnLoadConfig
  ]);

  useEffect(() => {
    setTransitionDisabled(true);
    const tm = setTimeout(() => {
      setTransitionDisabled(false);
    }, 300);

    return () => {
      clearTimeout(tm);
    };
  }, [pathname]);

  return (
    <>
      <Desktop>
        <S.StyledNavigation
          className={clsx([
            'main-navigation',
            isTransitionDisabled && 'no-transition',
            baseRoute.replace('/', '')
          ])}
          navigationId={NAVIGATION_ID}
          expandIcon={
            !layoutConfig.sidebarLeft?.isCollapsed && (
              <FontAwesomeIcon icon={['fas', 'angle-down']} />
            )
          }
          collapsedWidth={layoutConfig.sidebarLeft?.collapsedWidth}
          config={getMainNavigationConfig({
            basePath: baseRoute,
            domainName,
            language
          })}
          customItems={customNavItems}
          getPopupContainer={() => mainNavigationContainer}
          inlineCollapsed={layoutConfig.sidebarLeft?.isCollapsed}
          mode="inline"
          openOnLoad={!layoutConfig.sidebarLeft?.isCollapsed ? openKeysConfig : undefined}
        />
      </Desktop>
      <Mobile>
        <Navigation
          navigationId={NAVIGATION_ID}
          config={getMainNavigationConfig({
            basePath: baseRoute,
            domainName,
            language
          })}
          inlineCollapsed={false}
          mode="inline"
        />
      </Mobile>
    </>
  );
};

export default MainNavigation;
