import React, { useEffect } from 'react';

import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { CollectionEditorDialogProps, useFormContext } from '@phoenix-systems/react-form';
import { Form, Input } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { RuleObject } from 'rc-field-form/lib/interface';

import * as F from 'components/_styled/formSc';
import Button from 'components/ui/button';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import useDefaultModalProps from 'hooks/useDefaultModalProps';
import { Shard } from 'services/api/domain/domain';

type ShardFormDialogProps = CollectionEditorDialogProps<Shard> & {
  shards: Shard[];
};

const ShardFormDialog: React.FC<ShardFormDialogProps> = ({
  data,
  isOpen,
  onClose,
  insertData,
  shards
}) => {
  const copy = useCopyToClipboard();
  const [form] = Form.useForm<Shard>();
  const [formState, formActions] = useFormContext(form, data, data !== undefined);
  const modalProps = useDefaultModalProps();

  const handleClose = () => {
    onClose();
    formActions.reset();
  };

  const handleInsert = () => {
    form.validateFields().then(formData => {
      insertData(formData);
      handleClose();
    });
  };

  const validateName = (rule: RuleObject, value: string) =>
    new Promise((resolve, reject) => {
      const shardNames: string[] = [];
      shards.forEach((shard: Shard) => {
        shardNames.push(shard.name);
      });

      if (shards.find(s => s.name === value) && value !== data?.name) {
        reject(new Error('Shard name already in use.'));
      } else {
        resolve(value);
      }
    });

  useEffect(() => {
    if (data) {
      formActions.reset(data, true);

      return;
    }

    formActions.reset({ name: undefined }, false);
  }, [data, formActions]);

  return (
    <Modal
      {...modalProps}
      title={!data ? <Trans>Add shard</Trans> : <Trans>Edit shard</Trans>}
      visible={isOpen}
      footer={
        <>
          <Button onClick={handleClose} action="cancel" />
          <Button action="insert" onClick={handleInsert} formState={formState} />
        </>
      }
    >
      <F.StyledForm
        form={form}
        name="shard-form"
        onFieldsChange={formActions.onFieldsChange}
        onValuesChange={formActions.onValuesChange}
        $labelWidth={92}
        colon={false}
        initialValues={data}
      >
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        {(data as any)?.id && (
          <Form.Item label="Id" name="id" rules={[{ required: true }]}>
            <Input
              addonAfter={
                <Button action="copy" type="link" onClick={() => copy(form.getFieldValue('id'))} />
              }
              readOnly
            />
          </Form.Item>
        )}
        <Form.Item
          label={<Trans>Name</Trans>}
          messageVariables={{ name: i18n._('Name') }}
          name="name"
          rules={[
            { required: true },
            {
              validator: validateName,
              message: i18n._(t`A shard with name "${form.getFieldValue('name')}" already exists.`)
            },
            {
              pattern: /^[A-Za-z0-9\-_]+$/,
              message: i18n._("Only allowed alphanumeric characters including '-' and '_'.")
            }
          ]}
        >
          <Input />
        </Form.Item>
      </F.StyledForm>
    </Modal>
  );
};

export default ShardFormDialog;
