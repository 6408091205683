import { i18n } from '@lingui/core';
import { NavigationConfig } from '@phoenix-systems/react-navigation';
import { IconConfig } from '@phoenix-systems/react-navigation/dist/types/store';
import memoize from 'fast-memoize';

import getDocsConfig from './docs.config';
import { DocConfig } from './docs.config.types';

import routes from 'config/routes/routes';
import { TreeSelectItem } from 'index.types';
import { DocId } from 'services/api/domain/hint';

// eslint-disable-next-line import/no-cycle

const walkNav = (config: DocConfig, navItem: NavigationConfig): NavigationConfig => {
  const navigation: NavigationConfig = {
    id: config.id.toLowerCase().replaceAll('_', '-'),
    route: `${navItem.route?.replace('/big-data-service', '')}/${config.id
      .toLowerCase()
      .replaceAll('_', '-')}`,
    title: config.navLabel,
    icon: config.icon ? (config.icon as IconConfig) : ['fas', 'info'],
    children: []
  };

  if (config.children?.length) {
    config.children.forEach(childConfig => {
      navigation.children?.push(walkNav(childConfig, navigation));
    });
  }

  return navigation;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getDocsNavigationFunc = (isSuperadmin?: boolean): NavigationConfig => {
  const navigation: NavigationConfig = {
    id: 'docs-navigaiton',
    route: routes.docs.root,
    title: i18n._('Big data service'),
    children: []
  };

  const docsConfig = getDocsConfig(isSuperadmin);

  docsConfig.forEach(config => {
    navigation.children?.push(walkNav(config, navigation));
  });

  return navigation;
};

export const getDocsNavigation = memoize(getDocsNavigationFunc);

const walkFlatNav = (config: DocConfig, items: DocConfig[]): DocConfig[] => {
  if (config.children) {
    config.children.forEach(child => {
      walkFlatNav(child, items);
    });
  }

  items.push(config);

  return items;
};

const getFlatDocsConfigFunc = (isSuperadmin?: boolean): DocConfig[] => {
  const docsConfig = getDocsConfig(isSuperadmin);
  const configs: DocConfig[] = [];
  docsConfig.forEach(item => {
    walkFlatNav(item, configs);
  });

  return configs;
};

export const getFlatDocsConfig = memoize(getFlatDocsConfigFunc);

export const getRouteFromDocId = (docId: DocId): string => docId.toLowerCase().replaceAll('_', '-');

export const getDocIdFromPath = (path: string): string => path.toUpperCase().replaceAll('-', '_');

export const getDocConfig = (id: DocId, isSuperadmin?: boolean): DocConfig | undefined => {
  const flatConfig = getFlatDocsConfig(isSuperadmin);

  return flatConfig.find(item => item.id === id);
};

const walkTreeDataForTreeSelect = (data: DocConfig): TreeSelectItem<DocId> => {
  const navItem: TreeSelectItem<DocId> = {
    value: data.id,
    title: data.navLabel,
    children: []
  };

  if (data.children) {
    data.children.forEach(child => {
      navItem.children?.push(walkTreeDataForTreeSelect(child));
    });
  }

  return navItem;
};

export const getTreeSelectDataFromDocsConfig = (isSuperadmin: boolean): TreeSelectItem<DocId>[] => {
  const config = getDocsConfig(isSuperadmin);
  const conf: TreeSelectItem<DocId>[] = [];
  config.forEach(item => {
    conf.push(walkTreeDataForTreeSelect(item));
  });

  return conf;
};
