import { Handle } from 'react-flow-renderer';
import tw, { styled, css } from 'twin.macro';

type EdgeHandleProps = {
  $active?: boolean;
  hidden?: boolean;
};
export const EdgeHandle = styled(Handle)<EdgeHandleProps>(({ hidden, $active }) => [
  css`
    &.react-flow__handle {
      ${tw`bg-gray-300 hover:bg-gray-600 transition-all duration-200 transform scale-100 `};
      margin-top: -4px;
      width: 8px;
      height: 8px;
      transform-origin: center center;
    }
  `,

  $active &&
    css`
      &.react-flow__handle {
        ${tw`bg-secondary transform scale-125`};
      }
    `,
  hidden &&
    css`
      &.react-flow__handle {
        ${tw`invisible`};
      }
    `
]);
