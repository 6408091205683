import { AxiosError } from 'axios';
import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';

import { User } from '../user';

import {
  api_getAdminsByOrgUnit,
  api_getOrgUnitById,
  api_getOrgUnitByName,
  api_getOrgUnits
} from './orgUnit.queries';
import { OrgUnit } from './orgUnit.types';

export const useOrgUnitById = (
  id: string,
  options?: UseQueryOptions<OrgUnit, AxiosError, OrgUnit>
): QueryObserverResult<OrgUnit, AxiosError> =>
  useQuery<OrgUnit, AxiosError>(['orgUnit', 'byId', id], () => api_getOrgUnitById(id), options);

export const useOrgUnitByName = (
  name: string,
  options?: UseQueryOptions<OrgUnit, AxiosError, OrgUnit>
): QueryObserverResult<OrgUnit, AxiosError> =>
  useQuery<OrgUnit, AxiosError>(
    ['orgUnit', 'byName', name],
    () => api_getOrgUnitByName(name),
    options
  );

export const useOrgUnits = (
  options?: UseQueryOptions<OrgUnit[], AxiosError<OrgUnit[]>, OrgUnit[]>
): QueryObserverResult<OrgUnit[], AxiosError> =>
  useQuery<OrgUnit[], AxiosError>(['orgUnits'], () => api_getOrgUnits(), options);

export const useAdminsByOrgunit = (
  orgunitName: string,
  options?: UseQueryOptions<User[], AxiosError<OrgUnit[]>, User[]>
): QueryObserverResult<User[], AxiosError> =>
  useQuery<User[], AxiosError>(
    ['orgUnitAdmins'],
    () => api_getAdminsByOrgUnit(orgunitName),
    options
  );
