import useDomainName from './useDomainName';

import { Domain, useDomain } from 'services/api/domain/domain';

const useDomainFromCache = (): Domain | undefined => {
  const domainName = useDomainName();
  const { data } = useDomain(domainName, { enabled: false });

  return data;
};

export default useDomainFromCache;
