import { Button } from 'antd';
import tw, { styled, css } from 'twin.macro';

type ActiveIconProps = {
  $active?: boolean;
};

export const StyledTriggerButton = styled(Button)<ActiveIconProps>(({ $active, size }) => [
  css`
    &.ant-btn {
      ${tw`w-18 h-18 rounded-full text-white bg-red flex items-center justify-center p-0 m-0 border-red`};

      & svg {
        ${tw` text-white`};

        font-size: 8px;
      }
    }
  `,

  $active &&
    css`
      &.ant-btn {
        ${tw`bg-green border-green`};

        & svg {
          transform: translateX(${size === 'large' ? '1px' : '0'});
        }
      }
    `,

  size === 'large' &&
    css`
      &.ant-btn {
        ${tw`w-24 h-24`};

        & svg {
          ${tw`text-12`};
        }
      }
    `
]);
