import React from 'react';

import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Modal } from 'antd';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import * as D from 'components/_styled/dialogSc';
import Button from 'components/ui/button';
import ErrorInfo from 'components/ui/errorInfo';
import routes from 'config/routes/routes';
import useDefaultModalProps from 'hooks/useDefaultModalProps';
import useNotification from 'hooks/useNotification';
import { api_deleteDomain, Domain } from 'services/api/domain/domain';
import history from 'services/history';
import { st_domain_setDomainName } from 'services/store/domain/domain.actions';

type DeleteDomainDialogsProps = {
  data: Domain;
  isOpen: boolean;
  close: () => void;
};

const DeleteDomainDialog: React.FC<DeleteDomainDialogsProps> = ({ isOpen, data, close }) => {
  const modalProps = useDefaultModalProps();
  const [addNotification] = useNotification();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const deleteMutation = useMutation((id: string) => api_deleteDomain(id), {
    onSuccess: () => {
      addNotification({
        type: 'success',
        message: i18n._(t`Domain "${data.domainName}" successfully deleted.`)
      });

      deleteMutation.reset();
      // queryClient.refetchQueries(['domain']);
      queryClient.refetchQueries(['domains']);
      dispatch(st_domain_setDomainName(undefined));
      history.push(routes.dashboard.root);
    },
    onError: (error: AxiosError) => {
      let msg = t`Failed to delete domain "${data.domainName}".`;

      if (error.response?.data) {
        msg += t` Reason: ${error.response.data}`;
      }
      addNotification({
        type: 'error',
        message: msg
      });
    }
  });

  const handleDeleteDomain = () => {
    deleteMutation.mutate(data.id);
  };

  return (
    <Modal
      {...modalProps}
      title={
        <>
          <Trans>Delete Domain</Trans>: {data.domainName}
        </>
      }
      visible={isOpen}
      footer={
        <>
          <Button action="cancel" disabled={deleteMutation.isLoading} onClick={() => close()} />
          <Button action="delete" mutation={deleteMutation} onClick={handleDeleteDomain}>
            Delete Domain
          </Button>
        </>
      }
    >
      <p>
        <Trans>Are you sure yout want to delete the domain</Trans> <b>{data.domainName}</b>?. This
        action cannot be reverted.
      </p>
      {deleteMutation.isError && (
        <D.ResponseMessage>
          <ErrorInfo
            error={deleteMutation.error}
            message={
              <Trans>
                Failed to delete domain <b>{data.domainName}</b>.
              </Trans>
            }
          />
        </D.ResponseMessage>
      )}
    </Modal>
  );
};

export default DeleteDomainDialog;
