import { find, omit } from 'lodash';

import { EntityFormData } from './types';

import {
  EntityEdge,
  EntityNode,
  EntityNodeData,
  EntityNodeField,
  HandleType,
  PrimitiveFieldType
} from 'services/api/domain/domainDiagram';
import { EntityNodeFieldType } from 'services/store/domainDiagram/domainDiagram.types';
import { logWarn } from 'utils';

const PRIMITIVE_TYPES = Object.values(PrimitiveFieldType);

export const isGenericType = (type?: string, checkIfCastet?: boolean): boolean => {
  if (!type) {
    return false;
  }

  const types = type.replace(/[>]|(List<)|(Map<)/gi, '').split(',');
  let isMatch = false;
  let isCastet = false;
  types.forEach(t => {
    if (!PRIMITIVE_TYPES.includes(t as PrimitiveFieldType)) {
      isMatch = true;

      if (t !== 'Object' && t !== 'List') {
        isCastet = true;
      }
    }
  });

  if (isMatch && checkIfCastet) {
    return isCastet;
  }

  return isMatch;
};

export const isListType = (type: string): boolean => type.search('List<') !== -1;

export const isFieldType = (val: string, fieldTypes: EntityNodeFieldType[]): boolean =>
  find(fieldTypes, { name: val }) !== undefined;

export const isCollection = (type: string): boolean => type.indexOf('List<') !== -1;

export const isMap = (type: string): boolean => type.indexOf('Map<') !== -1;

export const isPrimitiveType = (type: string): boolean =>
  PRIMITIVE_TYPES.includes(type as PrimitiveFieldType);

export const prepareNodeData = (_data: EntityNodeData | EntityFormData): EntityNodeData => {
  const data = omit(_data, 'id');

  if (!data.className) {
    data.className = `ch.phoenixsystems.${data.simpleClassName}`;
  }

  const newFields: EntityNodeField[] = [];
  data.fields.forEach((field, index) => {
    const fl: EntityNodeField = {
      id: field.id,
      name: field.name,
      type: field.type,
      uniqueId: field.uniqueId || false,
      sortOrder: index,
      className: field.className,
      aggregationType: field.aggregationType,
      relationType: field.relationType
    };
    newFields.push(fl);
  });
  data.fields = newFields;

  return data;
};

export const getHandleId = (
  type: HandleType,
  className?: string,
  fieldName?: string
): string | null => {
  if (!className) {
    logWarn(`className missing :: ${type} :: ${fieldName}`);

    return null;
  }

  if (type === HandleType.FIELD_IN || type === HandleType.FIELD_OUT) {
    if (fieldName) {
      return `${type}_${className}_${fieldName}`;
    }
    logWarn(`fieldName missing :: ${type} :: ${className} :: ${fieldName}`);

    return null;
  }

  return `${type}_${className}`;
};

export const getNodeById = (nodes: EntityNode[], nodeId: string): EntityNode =>
  nodes.filter(node => node.id === nodeId)[0];

export const getNodeByClassName = (nodes: EntityNode[], className?: string): EntityNode | null => {
  if (!className) {
    return null;
  }

  return nodes.filter(node => node.data?.className === className)[0];
};

export const findeEdgeBySourceHandle = (edges: EntityEdge[], sourceHandle: string): EntityEdge =>
  edges.filter(edge => edge.sourceHandle === sourceHandle)[0];

export const uncastGenericField = (_field: EntityNodeField): EntityNodeField => {
  const field = _field;

  if (!isGenericType(field.type, true)) {
    return field;
  }
  field.className = undefined;
  const listRegex = /<[a-zA-Z_$0-9]*>/g;

  if (field.type.search(listRegex) !== -1) {
    field.type = field.type.replace(listRegex, '<Object>');

    return field;
  }
  field.type = 'Object';

  return field;
};

export const castGenericField = (
  _field: EntityNodeField,
  targetData: EntityNodeData
): EntityNodeField => {
  const field = _field;

  if (isGenericType(field.type)) {
    field.className = targetData.className;
    field.type = field.type.replace('Object', targetData.simpleClassName);
  }

  return field;
};

export const getFieldNameFromOutHandle = (handleId: string, className: string): string => {
  const str = handleId.replace(`${HandleType.FIELD_OUT}_${className}_`, '');

  if (str === handleId) {
    return '';
  }

  return str;
};

export const getFieldNameFromInHandle = (handleId: string, className: string): string => {
  const str = handleId.replace(`${HandleType.FIELD_IN}_${className}_`, '');

  if (str === handleId) {
    return '';
  }

  return str;
};

export const getHandleTypeFromSourceHandle = (edge: EntityEdge): HandleType | null => {
  if (edge.data?.sourceNode?.data && edge.sourceHandle) {
    const fieldStr = getFieldNameFromOutHandle(
      edge.sourceHandle,
      edge.data?.sourceNode.data?.className
    );

    if (fieldStr === '') {
      return HandleType.ENTITY_OUT;
    }

    return HandleType.FIELD_OUT;
  }

  return null;
};

export const getHandleTypeFromTargetHandle = (edge: EntityEdge): HandleType | null => {
  if (edge.data?.targetNode?.data && edge.targetHandle) {
    const fieldStr = getFieldNameFromInHandle(
      edge.targetHandle,
      edge.data?.targetNode.data?.className
    );

    if (fieldStr === '') {
      return HandleType.ENTITY_IN;
    }

    return HandleType.FIELD_IN;
  }

  return null;
};
