import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Menu } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { omit } from 'lodash';
import { useDispatch } from 'react-redux';
import { useImmer } from 'use-immer';

import * as S from '../actionsSc';

import EntityEdgeName from 'components/domain/components/domainDiagram/entityEdge/entityEdgeName';
import Button from 'components/ui/button';
import useDefaultModalProps from 'hooks/useDefaultModalProps';
import { EntityEdge } from 'services/api/domain/domainDiagram';
import {
  st_domainDiagram_removeEdge,
  st_domainDiagram_setDialogOpen
} from 'services/store/domainDiagram/domainDiagram.actions';

type RemoveAllEdgesActionProps = {
  edges: EntityEdge[];
  type: 'CONTEXT_NAV';
};

type RemoveAllEdgesActionState = {
  isDialogOpen: boolean;
};

const RemoveAllEdgesAction: React.FC<RemoveAllEdgesActionProps> = props => {
  const pr = omit(props, ['edges', 'type']);
  const { edges, type } = props;
  const [state, setState] = useImmer<RemoveAllEdgesActionState>({
    isDialogOpen: false
  });
  const modalProps = useDefaultModalProps();

  const dispatch = useDispatch();

  const handleRemoveAllEdgesRequested = () => {
    setState(draft => {
      draft.isDialogOpen = true;
    });
    dispatch(st_domainDiagram_setDialogOpen(true));
  };

  const handleDialogClose = () => {
    dispatch(st_domainDiagram_setDialogOpen(false));
    setState(draft => {
      draft.isDialogOpen = false;
    });
  };

  const handleRemoveEdge = () => {
    edges.forEach(edge => {
      dispatch(st_domainDiagram_removeEdge(edge.id));
    });
    handleDialogClose();
  };

  return (
    <>
      {type === 'CONTEXT_NAV' && (
        <Menu.Item
          {...pr}
          key="remove-all-edges"
          icon={<FontAwesomeIcon className="anticon" icon={['fas', 'location-slash']} />}
          onClick={handleRemoveAllEdgesRequested}
        >
          <Trans>Remove all edges</Trans>
        </Menu.Item>
      )}

      <Modal
        {...modalProps}
        title={<Trans>Remove all edges</Trans>}
        visible={state.isDialogOpen}
        footer={
          <>
            <Button action="cancel" onClick={handleDialogClose} />
            <Button action="delete" onClick={handleRemoveEdge}>
              <Trans>Remove all edges</Trans>
            </Button>
          </>
        }
      >
        <p>
          <Trans>
            Do you really want to remove all edges an uncast all the generic types of the related
            fields?
          </Trans>
        </p>
        <S.EdgesList>
          <p>
            <Trans>Following edges will be removed:</Trans>
          </p>
          <ul>
            {edges.map(edge => (
              <li key={edge.id}>
                <b>
                  <FontAwesomeIcon className="anticon" icon={['fas', 'location-slash']} />
                  <EntityEdgeName edge={edge} />
                </b>
              </li>
            ))}
          </ul>
        </S.EdgesList>
      </Modal>
    </>
  );
};

export default RemoveAllEdgesAction;
