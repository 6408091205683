import React, { useCallback, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import {
  CollectionEditor,
  FormItem,
  useFormContext,
  CollectionEditorDataItem
} from '@phoenix-systems/react-form';
import { Divider, Form, Input, InputNumber, Radio, Select } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { AxiosError } from 'axios';
import { Collapse } from 'react-collapse';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useImmer } from 'use-immer';

import * as S from './dbImportFormSc';
import DbImportMapperDialog from './dbImportMapperDialog';

import * as F from 'components/_styled/formSc';
import useContainer from 'components/app/components/containerProvider/useContainer';
import DocsTooltip from 'components/docs/components/docsTooltip';
import TooltipLabel from 'components/docs/components/docsTooltip/tooltipLabel';
import EntityFieldSelector from 'components/domain/components/entityFieldSelector';
import TestDbConnection from 'components/domain/components/testDbConnection';
import TriggerAdder from 'components/trigger/components/triggerAdder';
import Button from 'components/ui/button';
import ErrorInfo from 'components/ui/errorInfo';
import FormContainer from 'components/ui/formContainer';
import FormTitle from 'components/ui/formTitle';
import MonacoEditor from 'components/ui/monacoEditor';
import NoContent from 'components/ui/noContent';
import collectionEditorLocales from 'config/langauge/collectionEditor.locales';
import routes from 'config/routes/routes';
import useDbImportName from 'hooks/useDbImportName';
import useDomainName from 'hooks/useDomainName';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';
import useNotification from 'hooks/useNotification';
import useSchemaName from 'hooks/useSchemaName';
import {
  api_createImportFromDb,
  api_updateImportFromDb,
  CreateAndUpdateDbImport,
  DbImport,
  DbImportTableMapper
} from 'services/api/domain/dataImport';
import { useDbDriver } from 'services/api/domain/dataImport/dataImport.hooks';
import { DocId } from 'services/api/domain/hint';
import { Trigger } from 'services/api/domain/trigger';
import {
  st_dbImportsTable_setActiveKey,
  st_dbImportsTable_setParams
} from 'services/store/dbImportsTable/dbImportsTable.actions';
import { log } from 'utils';

type DbImportFormProps = {
  data?: DbImport;
};

type CollapseKey = 'isDbConnectionOpen' | 'isConfigOpen' | 'isMapperOpen' | 'isTriggersOpen';

type CollapseConfig = { [key in CollapseKey]: boolean };

type DbImportFormState = {
  hasNoDriverFound: boolean;
  existingImports?: DbImport[];
  currentFormData?: Partial<DbImport>;
  collapse: CollapseConfig;
};

const DEFAULT_DATA: Partial<DbImport> = {
  updatecreate: false,
  tableEntityMapping: [],
  batchSize: 500,
  maxRows: 1000
};

const DbImportForm: React.FC<DbImportFormProps> = ({ data }) => {
  const domainName = useDomainName();
  const schema = useSchemaName();
  const { formContainer } = useContainer();
  const [form] = Form.useForm<DbImport>();
  const [formState, formActions] = useFormContext(form, data, data !== undefined);
  const [addNotification] = useNotification();
  const dbImportName = useDbImportName();
  const dispatch = useDispatch();
  const goto = useGoToDomainRoute();

  const [state, setState] = useImmer<DbImportFormState>({
    hasNoDriverFound: false,
    existingImports: undefined,
    currentFormData: data,
    collapse: {
      isDbConnectionOpen: true,
      isConfigOpen: true,
      isMapperOpen: true,
      isTriggersOpen: true
    }
  });

  const driverQuery = useDbDriver(
    { domainName: domainName || '', schema },
    {
      onSuccess: () => {
        setState(draft => {
          draft.hasNoDriverFound = false;
        });
      },
      onError: error => {
        if (error.response?.status === 404) {
          setState(draft => {
            draft.hasNoDriverFound = true;
          });
        }
      }
    }
  );

  const updateMutation = useMutation<DbImport, AxiosError, CreateAndUpdateDbImport>(
    params => api_updateImportFromDb(params),
    {
      onError: () => {
        addNotification({
          type: 'error',
          message: i18n._(t`Failed to update DB import "${data?.name}".`)
        });
      },
      onSuccess: resData => {
        addNotification({
          type: 'success',
          message: i18n._(t`Successfully updated DB import "${resData?.name}".`)
        });

        if (resData.name !== dbImportName) {
          goto(routes.domain.dataManagement.importFromDb.single, { path: resData.name });

          return;
        }

        updateMutation.reset();
        formActions.reset(resData, true);
      }
    }
  );

  const createMutation = useMutation<DbImport, AxiosError, CreateAndUpdateDbImport>(
    params => api_createImportFromDb(params),
    {
      onSuccess: resData => {
        addNotification({
          type: 'success',
          message: i18n._(t`Successfully created DB import "${resData.name}".`)
        });

        dispatch(st_dbImportsTable_setParams(undefined));
        dispatch(st_dbImportsTable_setActiveKey(resData.name));

        goto(routes.domain.dataManagement.importFromDb.single, { path: resData.name });
      },
      onError: () => {
        addNotification({
          type: 'error',
          message: i18n._(t`Failed to create DB import "${form.getFieldValue('name')}".`)
        });
      }
    }
  );

  const toggleOpen = (key: CollapseKey) => {
    setState(draft => {
      draft.collapse[key] = !draft.collapse[key];
    });
  };

  const handleSave = () => {
    form
      .validateFields()
      .then(formData => {
        if (data) {
          updateMutation.mutate({
            payload: formData as DbImport,
            domainName,
            schema
          });

          return;
        }

        createMutation.mutate({
          payload: formData as DbImport,
          domainName,
          schema
        });
      })
      .catch(validataionErrors => {
        log(validataionErrors);

        /* if (scroll) {
          const field = document.querySelector('.ant-form-item-has-error');
          const layoutScroll = document.querySelector('.layout-content-inner');

          if (field !== null && layoutScroll !== null) {
            const fieldTop = (field as HTMLDivElement).getBoundingClientRect().top;
            const layoutTop = (layoutScroll as HTMLDivElement).getBoundingClientRect().top;
            scroll.scrollTop(-(layoutTop - fieldTop));
          }
        } */
      });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleValuesChange = (changedValues: any, values: unknown) => {
    setState(draft => {
      draft.currentFormData = values as DbImport;
    });

    formActions.onValuesChange(changedValues, values);
  };

  const handlePrepareTriggerData = () => {
    const formData = form.getFieldsValue();
    const triggerData: Partial<Trigger> = {
      taskDomain: domainName,
      taskScheme: schema,
      taskName: formData.name,
      taskType: 'SQLDBImport',
      name: `${formData.name}Trigger`,
      active: false,
      periodicity: 'NONE'
    };

    return triggerData;
  };

  const validateName = (rule: RuleObject, value: string) =>
    new Promise((resolve, reject) => {
      if (
        state.existingImports?.find(dbImport => dbImport.name === value && dbImport.id !== data?.id)
      ) {
        reject(new Error(`Name "${value}" is already in use in different task.`));
      }

      resolve(value);
    });

  const handleReset = useCallback(() => {
    updateMutation.reset();
    formActions.reset(data, data !== undefined);
  }, [formActions, data, updateMutation]);

  useEffect(() => {
    if (data) {
      setState(draft => {
        draft.currentFormData = data;
      });
    }
  }, [data, setState]);

  return (
    <FormContainer
      buttons={
        <>
          <Button
            action="reset"
            mutation={updateMutation}
            formState={formState}
            onClick={handleReset}
          />
          <Button
            action="save"
            disabled={
              updateMutation.isLoading ||
              createMutation.isLoading ||
              !formState.isValid ||
              !formState.hasChanged
            }
            loading={updateMutation.isLoading}
            onClick={handleSave}
          />
        </>
      }
      title={
        data ? (
          <>
            <FontAwesomeIcon icon={['fas', 'file-import']} />
            <Trans>Edit import from DB</Trans>
            <DocsTooltip docId={DocId.DOMAIN_IMPORT_FROM_DB} />
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={['fas', 'file-import']} />
            <Trans>Create import from DB</Trans>
            <DocsTooltip docId={DocId.DOMAIN_IMPORT_FROM_DB} />
          </>
        )
      }
      messages={
        <>
          {updateMutation.isError && (
            <ErrorInfo
              message={
                <Trans>Failed to updated DB import &quot;{form.getFieldValue('name')}&quot;.</Trans>
              }
              error={updateMutation.error}
            />
          )}
          {createMutation.isError && (
            <ErrorInfo
              message={
                <Trans>Failed to create DB import &quot;{form.getFieldValue('name')}&quot;.</Trans>
              }
              error={createMutation.error}
            />
          )}
        </>
      }
    >
      <F.StyledForm
        form={form}
        initialValues={data || DEFAULT_DATA}
        layout="horizontal"
        $labelWidth={142}
        onValuesChange={handleValuesChange}
        onFieldsChange={formActions.onFieldsChange}
        scrollToFirstError
        name="db-import-form"
        colon={false}
      >
        {data?.id && (
          <FormItem
            label={
              <TooltipLabel docId={DocId.DOMAIN_IMPORT_FROM_DB_ID} label={<Trans>Id</Trans>} />
            }
            messageVariables={{ name: i18n._(t`Id`) }}
            name="id"
            rules={[{ required: true }]}
            registerField={formActions.registerField}
          >
            <Input readOnly />
          </FormItem>
        )}
        <FormItem
          label={
            <TooltipLabel docId={DocId.DOMAIN_IMPORT_FROM_DB_NAME} label={<Trans>Name</Trans>} />
          }
          messageVariables={{ name: i18n._(t`Name`) }}
          name="name"
          validateFirst
          rules={[
            { required: true },
            {
              pattern: /^[A-Za-z0-9\-_]+$/,
              message: i18n._("Only allowed alphanumeric characters including '-' and '_'.")
            },
            {
              validator: validateName,
              message: i18n._(
                t`Another DB import with the same name is already in use. The DB import name must be unique.`
              )
            }
          ]}
          registerField={formActions.registerField}
        >
          <Input />
        </FormItem>

        <Divider />

        <FormTitle
          icon={['fas', 'database']}
          docId={DocId.DOMAIN_IMPORT_FROM_DB_DB_CONFIG}
          collapsible={{
            isOpen: state.collapse.isDbConnectionOpen,
            toggleOpen: () => toggleOpen('isDbConnectionOpen')
          }}
        >
          <Trans>DB connection</Trans>
        </FormTitle>
        <Collapse isOpened={state.collapse.isDbConnectionOpen}>
          <FormItem
            label={
              <TooltipLabel
                docId={DocId.DOMAIN_IMPORT_FROM_DB_DB_URL}
                label={<Trans>DB url</Trans>}
              />
            }
            messageVariables={{ name: i18n._(t`DB URL`) }}
            name="dburl"
            rules={[{ required: true }]}
            registerField={formActions.registerField}
          >
            <Input />
          </FormItem>
          <FormItem
            label={
              <TooltipLabel
                docId={DocId.DOMAIN_IMPORT_FROM_DB_DB_USER}
                label={<Trans>DB user</Trans>}
              />
            }
            messageVariables={{ name: i18n._(t`DB user`) }}
            name="dbuser"
            rules={[{ required: true }]}
            registerField={formActions.registerField}
          >
            <Input />
          </FormItem>
          <FormItem
            id="xxx"
            label={
              <TooltipLabel
                docId={DocId.DOMAIN_IMPORT_FROM_DB_DB_PASSWORD}
                label={<Trans>DB password</Trans>}
              />
            }
            messageVariables={{ name: i18n._('DB password') }}
            name="dbpassword"
            rules={[{ required: true }]}
            registerField={formActions.registerField}
          >
            <Input.Password
              autoComplete="off"
              iconRender={visible =>
                visible ? (
                  <FontAwesomeIcon icon={['fal', 'eye-slash']} />
                ) : (
                  <FontAwesomeIcon icon={['fal', 'eye']} />
                )
              }
              visibilityToggle
            />
          </FormItem>
          <FormItem
            label={
              <TooltipLabel
                docId={DocId.DOMAIN_IMPORT_FROM_DB_DRIVER_CLASS}
                label={<Trans>Driver class</Trans>}
              />
            }
            messageVariables={{ name: i18n._(t`Driver class`) }}
            name="driverclass"
            rules={[{ required: true }]}
            help={
              <>
                {driverQuery.isLoading && <Trans>Loading driver classes...</Trans>}
                {driverQuery.isError && !state.hasNoDriverFound && (
                  <F.FormItemError>
                    <ErrorInfo
                      error={driverQuery.error}
                      message={<Trans>Failed to load driver classes.</Trans>}
                    />
                  </F.FormItemError>
                )}
              </>
            }
            registerField={formActions.registerField}
          >
            <Select
              loading={driverQuery.isLoading}
              disabled={!driverQuery.isSuccess}
              placeholder={<Trans>Select driver class...</Trans>}
            >
              {driverQuery.data?.map(driverClass => (
                <Select.Option key={driverClass} value={driverClass}>
                  {driverClass}
                </Select.Option>
              ))}
            </Select>
          </FormItem>

          <TestDbConnection data={state.currentFormData} />
        </Collapse>

        <Divider />

        <FormTitle
          icon={['fas', 'cog']}
          collapsible={{
            isOpen: state.collapse.isConfigOpen,
            toggleOpen: () => toggleOpen('isConfigOpen')
          }}
        >
          <Trans>Config</Trans>
        </FormTitle>
        <Collapse isOpened={state.collapse.isConfigOpen}>
          <FormItem
            label={
              <TooltipLabel
                docId={DocId.DOMAIN_IMPORT_FROM_DB_ENTITY_NAME}
                label={<Trans>Entity</Trans>}
              />
            }
            messageVariables={{ name: i18n._(t`Entity name`) }}
            name="entityName"
            rules={[{ required: true }]}
            registerField={formActions.registerField}
          >
            <EntityFieldSelector
              treeDefaultExpandAll
              getPopupContainer={() => formContainer}
              onChangePrepareValue={nodeData => nodeData?.value}
            />
          </FormItem>
          <FormItem
            label={
              <TooltipLabel
                docId={DocId.DOMAIN_IMPORT_FROM_DB_UPDATE_CREATE}
                label={<Trans>Import strategy</Trans>}
              />
            }
            messageVariables={{ name: i18n._(t`Import strategy`) }}
            name="updatecreate"
            rules={[{ required: true }]}
            registerField={formActions.registerField}
          >
            <Radio.Group>
              {/* eslint-disable-next-line react/jsx-boolean-value */}
              <Radio value={true}>
                <Trans>Update</Trans>
              </Radio>
              <Radio value={false}>
                <Trans>Create</Trans>
              </Radio>
            </Radio.Group>
          </FormItem>
          <FormItem
            label={
              <TooltipLabel
                docId={DocId.DOMAIN_IMPORT_FROM_DB_SQL_STATEMENT}
                label={<Trans>SQL statement</Trans>}
              />
            }
            messageVariables={{ name: i18n._(t`SQL statement`) }}
            name="sqlStatement"
            rules={[{ required: true }]}
            registerField={formActions.registerField}
          >
            <MonacoEditor
              mode="SQL"
              height="300px"
              defaultColorTheme="BRIGHT"
              showMiniMap={false}
            />
          </FormItem>
          <FormItem
            label={
              <TooltipLabel
                docId={DocId.DOMAIN_IMPORT_FROM_DB_BATCH_SIZE}
                label={<Trans>Batch size</Trans>}
              />
            }
            messageVariables={{ name: i18n._('Batch size') }}
            name="batchSize"
            rules={[{ required: true }]}
            registerField={formActions.registerField}
          >
            <InputNumber step={1} min={1} max={100000} />
          </FormItem>
          <FormItem
            label={
              <TooltipLabel
                docId={DocId.DOMAIN_IMPORT_FROM_DB_MAX_ROWS}
                label={<Trans>Max rows</Trans>}
              />
            }
            messageVariables={{ name: i18n._('Max rows') }}
            name="maxRows"
            rules={[{ required: true }]}
            registerField={formActions.registerField}
          >
            <InputNumber step={1} min={1} max={100000} />
          </FormItem>
        </Collapse>

        <Divider />

        <FormTitle
          icon={['fas', 'project-diagram']}
          collapsible={{
            isOpen: state.collapse.isMapperOpen,
            toggleOpen: () => toggleOpen('isMapperOpen')
          }}
          docId={DocId.DOMAIN_IMPORT_FROM_DB_TABLE_ENTITY_MAPPER}
        >
          <Trans>Table entity mapping</Trans>
        </FormTitle>
        <Collapse isOpened={state.collapse.isMapperOpen}>
          {!state.currentFormData?.entityName && (
            <F.FormAlert
              type="info"
              showIcon
              margin="0 0 16px 0"
              message={
                <Trans>
                  Please select first a valid entity from the list under &quot;Config / entity&quot;
                  to provide the according field informations for for the mapper.
                </Trans>
              }
            />
          )}

          <FormItem
            label={
              <TooltipLabel
                docId={DocId.DOMAIN_IMPORT_FROM_DB_TABLE_ENTITY_MAPPER}
                label={<Trans>Table mapping</Trans>}
              />
            }
            name="tableEntityMapping"
            registerField={formActions.registerField}
          >
            <CollectionEditor
              renderDialog={props => (
                <DbImportMapperDialog {...props} selectedEntity={formState.data?.entityName} />
              )}
              actions={{ sort: { disabled: true } }}
              className={!formState.data?.entityName ? 'disabled' : undefined}
              locales={{ ...collectionEditorLocales, 'Add item': i18n._('Add mapper') }}
              renderItem={(item: CollectionEditorDataItem<DbImportTableMapper>) => (
                <S.TableMapperItem>
                  <p>
                    <span>
                      <Trans>Field</Trans>:
                    </span>
                    {item.value.field}
                  </p>
                  <p>
                    <span>
                      <Trans>Column</Trans>:
                    </span>
                    {item.value.column}
                  </p>
                </S.TableMapperItem>
              )}
              empty={<NoContent height={85} />}
            />
          </FormItem>
        </Collapse>

        <Divider />

        <FormTitle
          icon={['fas', 'clock']}
          collapsible={{
            isOpen: state.collapse.isTriggersOpen,
            toggleOpen: () => toggleOpen('isTriggersOpen')
          }}
          docId={DocId.DOMAIN_IMPORT_FROM_DB_SCHEDULER}
        >
          <Trans>Scheduler</Trans>
        </FormTitle>
        <Collapse isOpened={state.collapse.isTriggersOpen}>
          <FormItem>
            <TriggerAdder
              getInitialData={handlePrepareTriggerData}
              taskName={state.currentFormData?.name}
              isCreateTaskReadonly
            />
          </FormItem>
        </Collapse>
      </F.StyledForm>
    </FormContainer>
  );
};

export default DbImportForm;
