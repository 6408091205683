import { ListQueryParams } from '@phoenix-systems/react-table/dist/types/types';

import { CsvImportsTableActionTypes, CsvImportTableSingleView } from './csvImportsTable.types';

/* Action Definition */

type SetParamsAction = {
  type: CsvImportsTableActionTypes.SET_PARAMS;
  params?: Partial<ListQueryParams>;
};

type SetSingleViewAction = {
  type: CsvImportsTableActionTypes.SET_SINGLE_VIEW;
  view: CsvImportTableSingleView;
};

type SetActiveKeyAction = {
  type: CsvImportsTableActionTypes.SET_ACTIVE_KEY;
  key?: string;
};

type ResetAction = {
  type: CsvImportsTableActionTypes.RESET;
};

/* Union Action Types */

export type CsvImportsTableActionCreators =
  | SetParamsAction
  | SetSingleViewAction
  | SetActiveKeyAction
  | ResetAction;

/* Action Creators */

export const st_csvImportsTable_setParams = (
  params?: Partial<ListQueryParams>
): SetParamsAction => ({
  type: CsvImportsTableActionTypes.SET_PARAMS,
  params
});

export const st_csvImportsTable_setSingleView = (
  view: CsvImportTableSingleView
): SetSingleViewAction => ({
  type: CsvImportsTableActionTypes.SET_SINGLE_VIEW,
  view
});

export const st_csvImportsTable_setActiveKey = (key?: string): SetActiveKeyAction => ({
  type: CsvImportsTableActionTypes.SET_ACTIVE_KEY,
  key
});

export const st_csvImportsTable_reset = (): ResetAction => ({
  type: CsvImportsTableActionTypes.RESET
});
