import { Domain, MergeDomainParams } from './domain.types';

import apiClient from 'services/api/apiClient';

const BASE_PATH = 'domain';

/**
 * GET /api/domain
 */
export const api_getDomains = async (): Promise<Domain[]> => {
  const { data } = await apiClient.get(`/${BASE_PATH}`);

  return data?.docs;
};

/**
 * GET /api/domain/{id}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const api_getDomainById = async (domainId: string): Promise<any> => {
  const { data } = await apiClient.get(`/${BASE_PATH}/${domainId}`);

  return data;
};

/**
 * GET /api/domain/by/{name}
 */
export const api_getDomainByName = async (domainName: string): Promise<Domain> => {
  const { data } = await apiClient.get(`/${BASE_PATH}/by/${domainName}`);

  return data;
};

/**
 * PUT /api/domain
 */
export const api_updateDomain = async (payload: Domain): Promise<Domain> => {
  const res = await apiClient.put(`${BASE_PATH}`, payload);

  return res.data;
};

/**
 * DELETE /api/domain/{id}
 */
export const api_deleteDomain = async (id: string): Promise<void> => {
  const res = await apiClient.delete(`${BASE_PATH}/${id}`);

  return res.data;
};

/**
 * POST /api/domain
 */
export const api_createDomain = async (payload: Domain): Promise<Domain> => {
  const res = await apiClient.post(`${BASE_PATH}`, payload);

  return res.data;
};

/**
 * GET /api/domain/merge
 */
export const api_mergeDomains = async (payload: MergeDomainParams): Promise<string> => {
  const res = await apiClient.get(`${BASE_PATH}/merge`, {
    params: payload
  });

  return res.data;
};

/**
 * GET /api/domain/genApiKey
 */
export const api_generateApiKey = async (): Promise<string> => {
  const res = await apiClient.get(`${BASE_PATH}/genApiKey`);

  return res.data;
};
