import React from 'react';

import { Trans } from '@lingui/macro';

import * as F from 'components/_styled/formSc';
import { RenindexResponse } from 'services/api/domain/index';

type ReindexResultProps = {
  data: RenindexResponse;
  domainName: string;
};

const ReindexResult: React.FC<ReindexResultProps> = ({ data, domainName }) => (
  <F.FormAlert
    message={<Trans>Sucessfully reindexed domain &quot;{domainName}&quot;.</Trans>}
    type="success"
    showIcon
    description={
      <F.BoxedFormContainerSummary labelWidth={140}>
        <p>
          <span>
            <Trans>Matched results</Trans>:
          </span>
          {data.matchedresults}
        </p>
        <p>
          <span>
            <Trans>Message</Trans>:
          </span>
          {data.message?.message}
        </p>
        <p>
          <span>
            <Trans>Message status</Trans>:
          </span>
          {data.message?.status}
        </p>
        <p>
          <span>
            <Trans>Message status code</Trans>:
          </span>
          {data.message?.statusCode}
        </p>
      </F.BoxedFormContainerSummary>
    }
  />
);

export default ReindexResult;
