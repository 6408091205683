import {
  DeleteDomainObjectsParams,
  DomainObjectResult,
  QueryDomainObjectParms,
  QueryDomainObjectsParms,
  UpdateDomainObjectsParams
} from './object.types';

import apiClient from 'services/api/apiClient';

const BASE_PATH = 'objects';

/**
 * GET /api/objects/{domain}/{scheme}/query/{type}
 *
 * apikey required
 */
export const api_queryDomainObjects = async (
  params: QueryDomainObjectsParms
): Promise<DomainObjectResult> => {
  const { data } = await apiClient.post(
    `/${BASE_PATH}/${params.domainName}/${params.schema}/query/${params.type}`,
    params.payload,
    {
      headers: {
        APIkey: params.apiKey
      }
    }
  );

  return data;
};

/**
 * GET /api/objects/{domain}/{scheme}/{type}/{id}
 *
 * apikey required
 */
export const api_queryDomainObject = async (
  params: QueryDomainObjectParms
): Promise<DomainObjectResult> => {
  const { data } = await apiClient.get(
    `/${BASE_PATH}/${params.domainName}/${params.schema}/${params.type}/${params.id}`,
    {
      headers: {
        APIKEY: params.apiKey
      }
    }
  );

  return data;
};

/**
 * PUT /api/objects/{domain}/{scheme}/{type}  TODO -> CHECK RESPONSE TYPE
 *
 * apiKey required
 */
export const api_updateDomainObjects = async (
  params: UpdateDomainObjectsParams
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any[]> => {
  const { data } = await apiClient.put(
    `/${BASE_PATH}/${params.domainName}/${params.schema}/${params.type}`,
    params.payload,
    {
      headers: {
        APIKEY: params.apiKey
      }
    }
  );

  return data;
};

/**
 * DELETE /api/objects/{domain}/{scheme}/{type}
 *
 * apiKey required
 */
export const api_deleteDomainObjects = async (
  params: DeleteDomainObjectsParams
): Promise<DomainObjectResult> => {
  const { data } = await apiClient.delete(
    `/${BASE_PATH}/${params.domainName}/${params.schema}/${params.type}`,
    {
      params: {
        id: params.objectIds.join(',')
      },
      headers: {
        APIKEY: params.apiKey
      }
    }
  );

  return data;
};
