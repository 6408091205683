import React, { useEffect, useRef } from 'react';

import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { AxiosError } from 'axios';
import { assign } from 'lodash';
import { ReactFlowProvider } from 'react-flow-renderer';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useImmer } from 'use-immer';

import * as S from './domainDiagramSc';
import ReactFlowDiagram from './reactFlowDiagram';

import AddEntityAction from 'components/domain/actions/domainDiagramActions/createEntityAction';
import DomainPage from 'components/domain/components/domainPage';
import Button from 'components/ui/button/button';
import ErrorInfo from 'components/ui/errorInfo';
import Loader from 'components/ui/loader';
import LoaderMessage from 'components/ui/loader/loaderMessage';
import NoContent from 'components/ui/noContent';
import { FooterButtonSeparator } from 'components/ui/page/pageSc';
import routes from 'config/routes/routes';
import useDomainName from 'hooks/useDomainName';
import useNotFound from 'hooks/useNotFound';
import useNotification from 'hooks/useNotification';
import useSchemaName from 'hooks/useSchemaName';
import {
  api_publishDomainDiagram,
  api_recalculateDomainDiagram,
  api_updateDomainDiagram,
  DomainDiagram as DomainDiagramType,
  PublishDomainDiagramParams,
  useDomainDiagram,
  useGenerateDomainDiagram
} from 'services/api/domain/domainDiagram';
import {
  st_domainDiagram_reset,
  st_domainDiagram_setChanged,
  st_domainDiagram_setElements
} from 'services/store/domainDiagram/domainDiagram.actions';
import {
  st_domainDiagram_getEdges,
  st_domainDiagram_getHasChanged,
  st_domainDiagram_getNodes
} from 'services/store/domainDiagram/domainDiagram.selectors';

type DomainDiagramState = {
  isDataSet: boolean;
  isEmpty: boolean;
};

const DomainDiagram: React.FC = () => {
  const domainName = useDomainName();
  const dispatch = useDispatch();
  const schema = useSchemaName();
  const [addNotification] = useNotification();
  const hasChanged = useSelector(st_domainDiagram_getHasChanged);
  const nodes = useSelector(st_domainDiagram_getNodes);
  const edges = useSelector(st_domainDiagram_getEdges);
  const [state, setState] = useImmer<DomainDiagramState>({
    isDataSet: false,
    isEmpty: false
  });

  const domainDiagramQuery = useDomainDiagram(
    { domainName, schema },
    {
      enabled: false,
      retry: false
    }
  );
  const { current: domainDiagramQueryRef } = useRef(domainDiagramQuery);
  const isDiagramNotFound = useNotFound(domainDiagramQuery);

  const generateDomainDiagramQuery = useGenerateDomainDiagram(
    { domainName, schema },
    {
      enabled: false,
      retry: false,
      onSuccess: res => {
        dispatch(st_domainDiagram_setElements([...res.edges, ...res.nodes]));
        dispatch(st_domainDiagram_setChanged(true));
        setState(draft => {
          draft.isDataSet = true;
        });
      }
    }
  );
  const { current: generateDomainDiagramQueryRef } = useRef(generateDomainDiagramQuery);
  const isGenerateDiagramNotFound = useNotFound(generateDomainDiagramQuery);

  const updateMutation = useMutation<DomainDiagramType, AxiosError, DomainDiagramType>(
    payload => api_updateDomainDiagram(payload),
    {
      onSuccess: data => {
        addNotification({
          type: 'success',
          message: i18n._('Domain diagram successfully updated.')
        });
        dispatch(st_domainDiagram_setElements([...data.edges, ...data.nodes]));
        updateMutation.reset();
      },
      onError: (error: AxiosError) => {
        if (error.response?.status !== 404) {
          addNotification({
            type: 'error',
            message: i18n._('Failed to update domain diagram.')
          });
        }
      }
    }
  );

  const recalculateMutation = useMutation<DomainDiagramType, AxiosError, DomainDiagramType>(
    payload => api_recalculateDomainDiagram(payload),
    {
      onSuccess: data => {
        addNotification({
          type: 'success',
          message: i18n._('Domain diagram successfully recalculated')
        });

        dispatch(st_domainDiagram_setElements([...data.edges, ...data.nodes]));
        dispatch(st_domainDiagram_setChanged(true));
        updateMutation.reset();
      },
      onError: () => {
        addNotification({
          type: 'error',
          message: i18n._('Failed to recalculate positions of domain diagram.')
        });
      }
    }
  );

  const publishMutation = useMutation<DomainDiagramType, AxiosError, PublishDomainDiagramParams>(
    params => api_publishDomainDiagram(params),
    {
      onSuccess: () => {
        addNotification({
          type: 'success',
          message: i18n._('Domain diagram successfully published')
        });
      },
      onError: () => {
        addNotification({
          type: 'error',
          message: i18n._('Failed to publish domain diagram.')
        });
      }
    }
  );

  const handleRecalculatePositions = () => {
    const payload = assign({}, domainDiagramQuery.data, {
      nodes,
      edges,
      domain: domainName,
      scheme: schema
    });

    recalculateMutation.mutate(payload);
  };

  const handleReset = () => {
    setState(draft => {
      draft.isDataSet = false;
    });
    updateMutation.reset();
    dispatch(st_domainDiagram_reset());
    domainDiagramQuery.refetch();
  };

  const handleSave = () => {
    const payload = assign({}, domainDiagramQuery.data, {
      nodes,
      edges,
      domain: domainName,
      scheme: schema
    });

    updateMutation.mutate(payload);
  };

  const handlePublish = () => {
    const payload = assign({}, domainDiagramQuery.data, {
      domainName,
      schema
    });

    publishMutation.mutate(payload);
  };

  useEffect(() => {
    if (domainDiagramQuery.isSuccess && !state.isDataSet) {
      setState(draft => {
        draft.isDataSet = true;
      });
      dispatch(
        st_domainDiagram_setElements([
          ...domainDiagramQuery.data.edges,
          ...domainDiagramQuery.data.nodes
        ])
      );
    }
  }, [domainDiagramQuery, state.isDataSet, setState, dispatch]);

  useEffect(() => {
    if (isDiagramNotFound) {
      generateDomainDiagramQueryRef.refetch();
    }
  }, [isDiagramNotFound, generateDomainDiagramQueryRef]);

  useEffect(() => {
    if (isGenerateDiagramNotFound) {
      setState(draft => {
        draft.isDataSet = true;
      });
      dispatch(st_domainDiagram_setElements([]));
    }
  }, [isGenerateDiagramNotFound, setState, dispatch]);

  useEffect(() => {
    setState(draft => {
      draft.isDataSet = false;
    });
    domainDiagramQueryRef.refetch();
  }, [schema, domainName, setState, domainDiagramQueryRef]);

  useEffect(() => {
    setState(draft => {
      draft.isDataSet = false;
    });

    return () => {
      setState(draft => {
        draft.isDataSet = false;
      });
    };
  }, [setState]);

  return (
    <DomainPage
      route={routes.domain.dataDesigner.entityDocumentDiagram}
      withoutProvider
      className="domain-diagram-page"
      title={<Trans>Entity/ Document diagram</Trans>}
      noScroll
      schemaSelector
      content={
        <S.Wrapper>
          {domainDiagramQuery.isLoading ? (
            <Loader fullHeight />
          ) : (
            <>
              {domainDiagramQuery.isError && !isDiagramNotFound && (
                <ErrorInfo
                  className="top"
                  message={i18n._(t`Failed to load domain diagram for domain "${domainName}".`)}
                  error={domainDiagramQuery.error}
                />
              )}
              {generateDomainDiagramQuery.isLoading && (
                <S.StyledLoaderMessage>
                  <LoaderMessage>
                    <Trans>Domain diagram was not found. try to generate diagram data.</Trans>
                  </LoaderMessage>
                </S.StyledLoaderMessage>
              )}
              {generateDomainDiagramQuery.isError && !isGenerateDiagramNotFound && (
                <S.StyledLoaderMessage>
                  <ErrorInfo
                    className="top"
                    message={i18n._(
                      t`Failed to generate domain diagram data for domain "${domainName}".`
                    )}
                    error={generateDomainDiagramQuery.error}
                  />
                </S.StyledLoaderMessage>
              )}

              {updateMutation.isError && (
                <ErrorInfo
                  error={updateMutation.error}
                  message={<Trans>Failed to update domain diagram.</Trans>}
                  asPageContent
                />
              )}
              {recalculateMutation.isError && (
                <ErrorInfo
                  error={recalculateMutation.error}
                  message={<Trans>Failed to recalculate positions.</Trans>}
                  asPageContent
                />
              )}
              {publishMutation.isError && (
                <ErrorInfo
                  error={publishMutation.error}
                  message={<Trans>Failed to publish domain diagram.</Trans>}
                  asPageContent
                />
              )}
              {state.isDataSet && (
                <ReactFlowProvider>
                  {nodes.length === 0 && (
                    <S.EmptyWrapper>
                      <div>
                        <NoContent descripion={<Trans>No entities found.</Trans>} iconSize={64} />
                        <AddEntityAction />
                      </div>
                    </S.EmptyWrapper>
                  )}
                  <ReactFlowDiagram />
                </ReactFlowProvider>
              )}
            </>
          )}
        </S.Wrapper>
      }
      footerButtons={
        state.isDataSet ? (
          <>
            <Button
              onClick={handleRecalculatePositions}
              icon={['fas', 'crosshairs']}
              disabled={
                hasChanged ||
                recalculateMutation.isLoading ||
                updateMutation.isLoading ||
                publishMutation.isLoading
              }
              loading={recalculateMutation.isLoading}
            >
              <Trans>Recalculate positions</Trans>
            </Button>
            <Button
              action="reset"
              disabled={domainDiagramQuery.isLoading || !hasChanged || publishMutation.isLoading}
              icon={['fas', 'undo']}
              onClick={handleReset}
            />
            <FooterButtonSeparator />
            <Button
              action="save"
              disabled={
                domainDiagramQuery.isLoading ||
                !hasChanged ||
                updateMutation.isLoading ||
                recalculateMutation.isLoading ||
                publishMutation.isLoading
              }
              loading={updateMutation.isLoading}
              onClick={handleSave}
            />
            <Button
              action="save"
              icon={['fas', 'eye']}
              disabled={
                domainDiagramQuery.isLoading ||
                hasChanged ||
                updateMutation.isLoading ||
                recalculateMutation.isLoading ||
                publishMutation.isLoading
              }
              loading={publishMutation.isLoading}
              onClick={handlePublish}
            >
              <Trans>Publish</Trans>
            </Button>
          </>
        ) : undefined
      }
    />
  );
};

export default DomainDiagram;
