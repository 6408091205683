import { ListQueryParams } from '@phoenix-systems/react-table/dist/types/types';

import {
  EntityDocumentActionTypes,
  QueryDocumentsResultMode,
  QueryDocumentsLayout,
  QueryDocumentsQueryMode
} from './entityDocument.types';

import { SolrQueryObject } from 'services/api/domain/solrQuery';

/* Action Definition */

type SetLayoutAction = {
  type: EntityDocumentActionTypes.SET_LAYOUT;
  layout: QueryDocumentsLayout;
};

type SetQueryModeAction = {
  type: EntityDocumentActionTypes.SET_QUERY_MODE;
  mode: QueryDocumentsQueryMode;
};

type SetResultModeAction = {
  type: EntityDocumentActionTypes.SET_RESULT_MODE;
  mode: QueryDocumentsResultMode;
};

type SetQueryAction = {
  type: EntityDocumentActionTypes.SET_QUERY;
  query?: SolrQueryObject;
};

type SetEntityAction = {
  type: EntityDocumentActionTypes.SET_ENTITY;
  entity?: string;
};

type SetQueryCollapsedAction = {
  type: EntityDocumentActionTypes.SET_QUERY_COLLAPSED;
  collapsed: boolean;
};

type SetSelectedKeyAction = {
  type: EntityDocumentActionTypes.SET_SELECTED_KEY;
  key?: string;
};

type SetListParamsAction = {
  type: EntityDocumentActionTypes.SET_LIST_PARAMS;
  params?: ListQueryParams;
};

type ResetAction = {
  type: EntityDocumentActionTypes.RESET;
};

/* Union Action Types */

export type EntityDocumentActionCreators =
  | SetLayoutAction
  | SetQueryModeAction
  | SetQueryAction
  | SetEntityAction
  | SetResultModeAction
  | SetQueryCollapsedAction
  | SetSelectedKeyAction
  | SetListParamsAction
  | ResetAction;

/* Action Creators */

export const st_entityDocument_setLayout = (layout: QueryDocumentsLayout): SetLayoutAction => ({
  type: EntityDocumentActionTypes.SET_LAYOUT,
  layout
});

export const st_entityDocument_setQueryMode = (
  mode: QueryDocumentsQueryMode
): SetQueryModeAction => ({
  type: EntityDocumentActionTypes.SET_QUERY_MODE,
  mode
});

export const st_entityDocument_setResultMode = (
  mode: QueryDocumentsResultMode
): SetResultModeAction => ({
  type: EntityDocumentActionTypes.SET_RESULT_MODE,
  mode
});

export const st_entityDocument_setQuery = (query?: SolrQueryObject): SetQueryAction => ({
  type: EntityDocumentActionTypes.SET_QUERY,
  query
});

export const st_entityDocument_setEntity = (entity?: string): SetEntityAction => ({
  type: EntityDocumentActionTypes.SET_ENTITY,
  entity
});

export const st_entityDocument_setQueryCollapsed = (
  collapsed: boolean
): SetQueryCollapsedAction => ({
  type: EntityDocumentActionTypes.SET_QUERY_COLLAPSED,
  collapsed
});

export const st_entityDocument_setSelectedKey = (key?: string): SetSelectedKeyAction => ({
  type: EntityDocumentActionTypes.SET_SELECTED_KEY,
  key
});

export const st_entityDocument_setListParams = (params?: ListQueryParams): SetListParamsAction => ({
  type: EntityDocumentActionTypes.SET_LIST_PARAMS,
  params
});

export const st_entityDocument_reset = (): ResetAction => ({
  type: EntityDocumentActionTypes.RESET
});
