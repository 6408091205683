import { AxiosError } from 'axios';
import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';

import { api_getHint, api_getHintApiInfo, api_searchHints } from './hint.queries';
import { DocId, Hint, HintApiInfo, HintSearchResult, SearchHintParams } from './hint.types';

export const useHint = (
  id: DocId,
  options?: UseQueryOptions<Hint, AxiosError, Hint>
): QueryObserverResult<Hint, AxiosError> =>
  useQuery<Hint, AxiosError>(['hint', id], () => api_getHint(id), options);

export const useSearchHints = (
  params: SearchHintParams,
  options?: UseQueryOptions<HintSearchResult, AxiosError, HintSearchResult>
): QueryObserverResult<HintSearchResult, AxiosError> =>
  useQuery<HintSearchResult, AxiosError>(
    ['searchHints', params],
    () => api_searchHints(params),
    options
  );

export const useHintApiInfo = (
  options?: UseQueryOptions<HintApiInfo, AxiosError, HintApiInfo>
): QueryObserverResult<HintApiInfo, AxiosError> =>
  useQuery<HintApiInfo, AxiosError>(['hintApiInfo'], () => api_getHintApiInfo(), options);
