/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useCallback, useEffect, useRef } from 'react';

import { i18n } from '@lingui/core';
import {
  LinkElement,
  ToolbarButtonLink,
  useEditorState,
  useIsElementByIdActive
} from '@phoenix-systems/react-slate-editor';
import { Popover } from 'antd';
import { cloneDeep } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RenderElementProps } from 'slate-react';

import { LinkFormData } from '../editorLinkDialog/editorLink.types';

import useContainer from 'components/app/components/containerProvider/useContainer';
import { getDocConfig } from 'config/docs/docs.utils';
import { DocId, useHint } from 'services/api/domain/hint';
import history from 'services/history';
import { st_docs_openDrawer } from 'services/store/docs/docs.actions';
import { st_docs_getEditorMode, st_docs_getIsDrawerOpen } from 'services/store/docs/docs.selectors';

const EditorLink: React.FC<RenderElementProps> = ({ attributes, children, element }) => {
  const linkElement = cloneDeep(element as LinkElement<LinkFormData>);
  const editorMode = useSelector(st_docs_getEditorMode);
  const isDocDrawerOpen = useSelector(st_docs_getIsDrawerOpen);
  const { toolbarMode } = useEditorState();
  const { formContainer } = useContainer();
  const isLinkActive = useIsElementByIdActive(linkElement.uid);

  /**
   * map old structure to new one
   */
  if (!linkElement.data) {
    linkElement.data = {
      type: (element as any).linkType,
      value: (element as any).uri,
      title: (element as any).title,
      target: (element as any).target
    };
  }

  const dispatch = useDispatch();

  const hintQuery = useHint(linkElement.data?.value as DocId, {
    enabled: false
  });

  const { current: hintQueryRef } = useRef(hintQuery);

  const loadCurrentLinkPage = useCallback(async () => {
    if (linkElement.data?.type === 'internal') {
      hintQueryRef.refetch();
    }
  }, [linkElement, hintQueryRef]);

  const handleGotoLink = (e: React.MouseEvent) => {
    e.preventDefault();

    if (hintQuery.data?.elementId) {
      const config = getDocConfig(hintQuery.data?.elementId as DocId);

      if (config?.path) {
        if (!isDocDrawerOpen) {
          history.push(config.path);
        } else {
          dispatch(st_docs_openDrawer(hintQuery.data?.elementId as DocId));
        }
      }
    }
  };

  useEffect(() => {
    if (linkElement.data?.type === 'internal' && linkElement.data?.value) {
      loadCurrentLinkPage();
    }
  }, [linkElement, loadCurrentLinkPage]);

  if (linkElement.data?.type === 'phone') {
    return (
      <a
        href={`tel:${linkElement.data?.value.replaceAll(' ', '')}`}
        title={linkElement.data?.title}
        className="link email"
      >
        {children}
      </a>
    );
  }

  const getContent = () => {
    switch (linkElement.data?.type) {
      case 'email':
        return (
          <a
            href={`mailto:${linkElement.data?.value}`}
            className="link email"
            title={
              editorMode === 'read'
                ? linkElement.data?.title
                : i18n._(`Email: ${linkElement.data?.value}`)
            }
            data-uid={linkElement.uid}
          >
            {children}
          </a>
        );

      case 'phone':
        return (
          <a
            href={`tel:${linkElement.data?.value.replaceAll(' ', '')}`}
            className="link email"
            title={
              editorMode === 'read'
                ? linkElement.data?.title
                : i18n._(`Phone: ${linkElement.data?.value}`)
            }
            data-uid={linkElement.uid}
          >
            {children}
          </a>
        );

      case 'external':
        return (
          <a
            className="link external"
            href={linkElement.data?.value}
            title={
              editorMode === 'read'
                ? linkElement.data?.title
                : i18n._(`External: ${linkElement.data?.value}`)
            }
            rel="noreferrer"
            target={linkElement.data?.target || '_blank'}
            data-uid={linkElement.uid}
          >
            {children}
          </a>
        );

      case 'internal':
        if (linkElement.data?.target === '_blank') {
          return (
            <a
              className="link external"
              href={linkElement.data?.value}
              title={
                editorMode === 'read'
                  ? linkElement.data?.title
                  : i18n._(`External: ${linkElement.data?.value}`)
              }
              rel="noreferrer"
              target={linkElement.data?.target}
              data-uid={linkElement.uid}
            >
              {children}
            </a>
          );
        }

        return (
          <Link
            onClick={handleGotoLink}
            className="link internal-link"
            to="/"
            {...attributes}
            title={
              editorMode === 'read'
                ? linkElement.data?.title
                : i18n._(`Internal: ${hintQuery.data?.title} (${hintQuery.data?.elementId})`)
            }
            data-uid={linkElement.uid}
          >
            {children}
          </Link>
        );

      default:
        return null;
    }
  };

  if (editorMode === 'read' || toolbarMode === 'hovering') {
    return <>{getContent()}</>;
  }

  return (
    <Popover
      //  trigger="click"
      getPopupContainer={() => formContainer}
      content={<ToolbarButtonLink />}
      overlayClassName="editor-toolbar-overlay"
      visible={isLinkActive}
    >
      {getContent()}
    </Popover>
  );
};

export default EditorLink;
