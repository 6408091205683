import React from 'react';

import { Trans } from '@lingui/macro';
import { Desktop } from '@phoenix-systems/react-layout';

import * as P from 'components/_styled/pageSc';
import AppLayout from 'components/app/components/appLayout';
import Breadcrumbs from 'components/app/components/breadcrumbs';
import DomainPage from 'components/domain/components/domainPage';
import TriggersCalendar from 'components/trigger/components/triggerCalendar';
import Button from 'components/ui/button';
import routes from 'config/routes/routes';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';
import useIsDomain from 'hooks/useIsDomain';
import history from 'services/history';

const TriggersCalendarPage: React.FC = () => {
  const isDomain = useIsDomain();
  const goto = useGoToDomainRoute();

  const title = <Trans>Triggers Calendar</Trans>;
  const headerButtons = (
    <>
      <Button
        action="list"
        type="ghost"
        onClick={() => {
          if (isDomain) {
            goto(routes.domain.scheduler.list);
          } else {
            history.push(routes.scheduler.list);
          }
        }}
      >
        <Trans>Go to list</Trans>
      </Button>
      <Button
        type="ghost"
        icon={['fas', 'calendar-alt']}
        onClick={() => {
          if (isDomain) {
            goto(routes.domain.scheduler.calendar);
          } else {
            history.push(routes.scheduler.calendar);
          }
        }}
      >
        <Trans>Go to calendar</Trans>
      </Button>
    </>
  );

  if (!isDomain) {
    return (
      <AppLayout>
        <P.StyledPage
          scrollableProps={{ scrollId: 'page-scroll' }}
          title={title}
          content={<TriggersCalendar />}
          titleElements={headerButtons}
          titleTop={
            <Desktop>
              <Breadcrumbs />
            </Desktop>
          }
        />
      </AppLayout>
    );
  }

  return (
    <AppLayout>
      <DomainPage
        route={routes.domain.scheduler.calendar}
        title={title}
        headerButtons={headerButtons}
        content={<TriggersCalendar />}
      />
    </AppLayout>
  );
};

export default TriggersCalendarPage;
