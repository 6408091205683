import React from 'react';

import clsx from 'clsx';
import { CSSProperties } from 'styled-components';

import * as S from './passordInfoSc';

type PassowordInfoProps = {
  value?: string;
  className?: string;
  style?: CSSProperties;
};

const PasswordInfo: React.FC<PassowordInfoProps> = ({ value, className, style }) => {
  if (!value) {
    return null;
  }

  return (
    <S.StyledInput
      style={style}
      className={clsx(['password-info', className])}
      value={value}
      readOnly
    />
  );
};

export default PasswordInfo;
