import tw, { css, styled } from 'twin.macro';

export const DialogContainer = styled.div(() => [
  css`
    z-index: 9000;

    & .ant-modal-title {
      ${tw`font-700 text-primary text-24 pt-2 pb-4 mb-0 flex items-center`}

      & svg {
        ${tw`mr-10 text-20`}
      }
    }

    & .ant-modal-body {
      max-height: 85vh;

      p:not(.not-bold) {
        ${tw`font-600`}

        b {
          ${tw`text-secondary font-700`}
        }
      }
    }

    & .ant-modal-footer {
      ${tw`px-24 py-16`}

      button {
        min-width: 100px;
      }
    }

    & .ant-modal.form-dialog .ant-modal-body {
      ${tw`pb-10`}
    }

    & .ant-select-tree-list {
      ${tw`font-600`}

      & .ant-select-tree-node-selected {
        ${tw`bg-light-gray-active`}
      }
    }

    & .ant-select-item {
      ${tw`font-600`}
    }

    &
      .ant-select-show-search.ant-select:not(.ant-select-customize-input)
      .ant-select-selector
      input {
      ${tw`font-600`}
    }

    & mark {
      ${tw`bg-yellow-200`}
    }

    & .ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode {
      ${tw`pb-4`}

      & .icon {
        ${tw`mr-6`}
      }

      &.entity {
        ${tw`pt-12`}
      }

      &.entity:first-of-type {
        ${tw`pt-0`}
      }

      &.entity:last-of-type {
        ${tw`pb-12`}
      }

      &.entity .label {
        ${tw`font-700 text-primary`}
      }

      &.field .label {
        ${tw`font-500 text-gray-700`}
      }

      /*  &.disabled {
        ${tw`opacity-60`}
      } */

      /*      &.entity {
        ${tw`bg-light-gray-active`}
      } */

      .type {
        ${tw`font-mono text-12 text-gray-400 inline-block ml-4`}
      }
    }
  `
]);

export const ResponseMessage = styled.div(() => [
  css`
    ${tw`mt-12`}
  `
]);
