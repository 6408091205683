import { AxiosError } from 'axios';
import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';

import { api_getFtpConnectionByName, api_getFtpConnections } from './ftpConnection.queries';
import {
  FTPConnection,
  GetFtpConnectionByNameParams,
  GetFtpConnectionsParams
} from './ftpConnection.types';

export const useFTPConnection = (
  params: GetFtpConnectionByNameParams,
  options?: UseQueryOptions<FTPConnection, AxiosError, FTPConnection>
): QueryObserverResult<FTPConnection, AxiosError> =>
  useQuery<FTPConnection, AxiosError>(
    ['ftpConnection', params],
    () => api_getFtpConnectionByName(params),
    options
  );

export const useFTPConnections = (
  params: GetFtpConnectionsParams,
  options?: UseQueryOptions<FTPConnection[], AxiosError, FTPConnection[]>
): QueryObserverResult<FTPConnection[], AxiosError> =>
  useQuery<FTPConnection[], AxiosError>(
    ['ftpConnections', params],
    () => api_getFtpConnections(params),
    options
  );
