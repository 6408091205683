import React, { useCallback } from 'react';

import { Trans } from '@lingui/macro';

import CsvImportActions from 'components/domain/actions/csvImportActions';
import CsvImportForm from 'components/domain/components/csvImportForm';
import DomainPage from 'components/domain/components/domainPage';
import Button from 'components/ui/button';
import ErrorInfo from 'components/ui/errorInfo';
import Exception from 'components/ui/exception';
import routes from 'config/routes/routes';
import useCsvImportName from 'hooks/useCsvImportName';
import useDomainName from 'hooks/useDomainName';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';
import useNotFound from 'hooks/useNotFound';
import useSchemaName from 'hooks/useSchemaName';
import { useCsvImport } from 'services/api/domain/csvImport';

const CsvImportSingle: React.FC = () => {
  const domainName = useDomainName();
  const csvImportName = useCsvImportName();
  const schemaName = useSchemaName();
  const goto = useGoToDomainRoute();

  const importQuery = useCsvImport({
    domainName,
    schemaName,
    csvImportName
  });
  const isNotFound = useNotFound(importQuery);

  const handleGotoList = () => {
    goto(routes.domain.dataManagement.importFromCsv.list);
  };

  const getContent = useCallback(() => {
    if (isNotFound) {
      return (
        <Exception
          code={404}
          message={
            <Trans>
              Db import <b>{csvImportName}</b> was not found.
            </Trans>
          }
        />
      );
    }

    if (importQuery.isError) {
      return (
        <ErrorInfo
          error={importQuery.error}
          asPageContent
          message={
            <Trans>Failed to load import from DB with name &quot;{csvImportName}&quot;.</Trans>
          }
        />
      );
    }

    if (importQuery.data) {
      return <CsvImportForm data={importQuery.data} />;
    }

    return null;
  }, [importQuery, isNotFound, csvImportName]);

  return (
    <DomainPage
      isLoading={importQuery.isLoading}
      noScroll={isNotFound}
      className="import-from-db-single"
      route={routes.domain.dataManagement.importFromCsv.single}
      routeParams={{ path: csvImportName }}
      schemaSelector={{
        readonly: true
      }}
      title={<Trans>CSV import &quot;{csvImportName}&quot;</Trans>}
      headerButtons={
        <>
          {importQuery.data && (
            <>
              <CsvImportActions mode="BUTTON" popoverPlacement="rightTop" data={importQuery.data} />
              <Button action="list" type="ghost" onClick={handleGotoList}>
                <Trans>Back to list</Trans>
              </Button>
            </>
          )}
        </>
      }
      content={<>{getContent()}</>}
    />
  );
};

export default CsvImportSingle;
