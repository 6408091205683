import React, { useState } from 'react';

import { i18n } from '@lingui/core';

import * as S from './dataItemSc';

import Button from 'components/ui/button';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { logError } from 'utils';

type DataItemProps = {
  name?: string | JSX.Element;
  value: string | JSX.Element;
  hasCopy?: boolean;
  hasCopyTitle?: boolean;
  copyValue?: string;
  breakWord?: boolean;
  isShortCopyMessage?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onEdit?: (data: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onView?: (data: any) => void;
};

const DataItem: React.FC<DataItemProps> = ({
  name,
  value,
  hasCopy,
  copyValue,
  breakWord,
  hasCopyTitle,
  isShortCopyMessage,
  onEdit,
  onView
}) => {
  const [isCopy, setCopy] = useState(false);
  const copy = useCopyToClipboard();

  const handleCopy = () => {
    let val: string | undefined;

    if (copyValue) {
      val = copyValue;
    }

    if (typeof value === 'string') {
      val = value;
    }

    if (!val) {
      logError('could not copy value, no valid value format provided.');

      return;
    }
    setCopy(true);
    copy(val, isShortCopyMessage ? i18n._('Copied value to clibboard') : undefined);

    setTimeout(() => {
      setCopy(false);
    }, 400);
  };

  return (
    <S.StyledItem className={isCopy ? 'is-copy' : undefined} breakWord={breakWord}>
      <div className="label">
        {name}
        {hasCopyTitle && value && (
          <Button
            action="copy"
            title={i18n._('Copy to clipboard')}
            type="link"
            className="action-btn copy"
            onClick={handleCopy}
            isIconOnly
          />
        )}
      </div>
      <div className="value">
        <span>{value || '-'}</span>
        {onEdit && value && (
          <Button
            action="edit"
            title={i18n._('Edit')}
            type="link"
            className="action-btn edit"
            onClick={() => onEdit(value)}
            isIconOnly
          />
        )}
        {onView && value && (
          <Button
            title={i18n._('View')}
            type="link"
            className="action-btn edit"
            icon={['fas', 'eye']}
            onClick={() => onView(value)}
          />
        )}
        {hasCopy && value && !hasCopyTitle && (
          <Button
            action="copy"
            title={i18n._('Copy to clipboard')}
            type="link"
            className="action-btn copy"
            onClick={handleCopy}
            isIconOnly
          />
        )}
      </div>
    </S.StyledItem>
  );
};

export default DataItem;
