import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Menu } from 'antd';
import { omit } from 'lodash';

import Button from 'components/ui/button';
import routes from 'config/routes/routes';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';
import { ActionMode } from 'index.types';

const { Item } = Menu;

type CreateFtpConnectionActionProps = {
  mode: ActionMode;
};

const CreateFtpConnectionAction: React.FC<CreateFtpConnectionActionProps> = props => {
  const antProps = omit(props, ['mode']);
  const { mode } = props;
  const goto = useGoToDomainRoute();

  const handleCreateRequest = () => {
    goto(routes.domain.connections.ftp.create);
  };

  const label = <Trans>Create FTP connection</Trans>;

  return (
    <>
      {mode === 'BUTTON' && <Button onClick={handleCreateRequest}>{label}</Button>}
      {mode === 'LIST_ITEM' && (
        <Item
          {...antProps}
          key="create-db-import"
          icon={<FontAwesomeIcon icon={['fas', 'plus-circle']} />}
          onClick={handleCreateRequest}
        >
          {label}
        </Item>
      )}
    </>
  );
};

export default CreateFtpConnectionAction;
