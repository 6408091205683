import React from 'react';

import { RouteConfig } from '@phoenix-systems/react-router';
import { Redirect } from 'react-router-dom';

import dashboardViewConfig from '../views/dashboardView.config';
import domainViewConfig from '../views/domainView.config';

import routes from './routes';
import { generateRoutesFromConfigs } from './routes.utils';

import docsViewConfig from 'config/views/docsView.config';
import errorViewConfig from 'config/views/errorView.config';
import loginViewConfig from 'config/views/loginView.config';
import schedulerViewConfig from 'config/views/schedulerView.config';
import userAdminViewConfig from 'config/views/userAdminView.config';

const routeConfigs = generateRoutesFromConfigs([
  loginViewConfig,
  dashboardViewConfig,
  domainViewConfig,
  userAdminViewConfig,
  errorViewConfig,
  docsViewConfig,
  schedulerViewConfig
]);

const routesConfig: RouteConfig[] = [
  ...routeConfigs,
  {
    path: '/',
    component: <Redirect to={routes.dashboard.root} />
  }
];

export default routesConfig;
