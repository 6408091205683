import React from 'react';

import { ImpulseSpinner } from 'react-spinners-kit';

import * as S from './loaderSc';

import appStyles from 'styles/appStyles';

type LoaderProps = {
  backColor?: string;
  frontColor?: string;
  size?: number;
  height?: number;
  fullHeight?: boolean;
};

const DEFAULT_SIZE = 32;
const DEFAULT_BG_COLOR = '#FFFFFF';
const DEFAULT_FRONT_COLOR = appStyles.colors.primary;

const Loader: React.FC<LoaderProps> = ({ backColor, frontColor, size, height }) => (
  <S.LoaderWrapper $height={height} fullHeight className="loader">
    <ImpulseSpinner
      backColor={backColor || DEFAULT_BG_COLOR}
      frontColor={frontColor || DEFAULT_FRONT_COLOR}
      size={size || DEFAULT_SIZE}
    />
  </S.LoaderWrapper>
);

export default Loader;
