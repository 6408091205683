import React, { useRef } from 'react';

import { i18n } from '@lingui/core';
import { Menu } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import clsx from 'clsx';
import { CSSProperties } from 'styled-components';

import ActivateTriggerActions from './activateTriggerActions';
import DeleteTriggerAction from './deleteTriggerAction';
import DuplicateTriggerAction from './duplicateTriggerAction';
import EditTriggerAction from './editTriggerAction';
import ExecuteTriggerAction from './executeTriggerAction';

import ContextNavigation from 'components/app/components/contextNavigation';
import { ContextNavigationRef } from 'components/app/components/contextNavigation/contextNavigation.types';
import Button from 'components/ui/button';
import useTriggerName from 'hooks/useTriggerName';
import { Trigger } from 'services/api/domain/trigger';

type TriggerActionProps = {
  data: Trigger;
  mode: 'BUTTON' | 'ICON_BUTTON';
  popoverPlacement: TooltipPlacement;
  style?: CSSProperties;
  className?: string;
  isEditHidden?: boolean;
};

const { Divider } = Menu;

const TriggerActions: React.FC<TriggerActionProps> = ({
  data,
  popoverPlacement,
  mode,
  className,
  style
}) => {
  const contextNavRef = useRef<ContextNavigationRef>(null);
  const triggerName = useTriggerName();

  return (
    <ContextNavigation
      placement={popoverPlacement}
      ref={contextNavRef}
      trigger={
        <Button
          action="actions"
          hasMenu
          isIconOnly={mode === 'ICON_BUTTON'}
          className={clsx(['trigger-actions', className])}
          style={style}
          title={i18n._('Trigger actions')}
        />
      }
    >
      {!triggerName && <EditTriggerAction data={data} />}
      <DuplicateTriggerAction data={data} />
      <Divider />
      <ExecuteTriggerAction data={data} />
      <Divider />
      <ActivateTriggerActions data={data} />
      <Divider />
      <DeleteTriggerAction data={data} />
    </ContextNavigation>
  );
};

export default TriggerActions;
