import React, { useEffect, useRef } from 'react';

import { useAuthState } from '@phoenix-systems/react-keycloak-auth';

import SplashScreen from '../splashScreen';

import { useHintApiInfo } from 'services/api/domain/hint';
import { useMyself } from 'services/api/domain/user';

const UserProvider: React.FC = ({ children }) => {
  const { isAuthenticated } = useAuthState();
  const myselfQuery = useMyself({ enabled: false });
  const { current: myeslefQueryRef } = useRef(myselfQuery);
  const hintInfoQuery = useHintApiInfo();
  const { current: hintInfoQueryRef } = useRef(hintInfoQuery);

  useEffect(() => {
    if (isAuthenticated) {
      myeslefQueryRef.refetch();
      hintInfoQueryRef.refetch();
    }
  }, [isAuthenticated, hintInfoQueryRef, myeslefQueryRef]);

  if (!myselfQuery.data || !hintInfoQuery.data) {
    return <SplashScreen />;
  }

  return <>{children}</>;
};

export default UserProvider;
