import React, { ReactNode } from 'react';

import { Trans } from '@lingui/macro';
import { Empty } from 'antd';
import clsx from 'clsx';
import { CSSProperties } from 'styled-components';

import * as S from './noContentSc';

const DEFAULT_HEIGHT = 70;
const DEFAULT_ICON_SIZE = 48;

type NoContentProps = {
  descripion?: ReactNode | string;
  fullHeight?: boolean;
  height?: number;
  iconSize?: number;
  style?: CSSProperties;
  className?: string;
};

const NoContent: React.FC<NoContentProps> = ({
  descripion,
  fullHeight,
  height,
  iconSize,
  style,
  className
}) => (
  <S.StyledNoContent
    className={clsx(['no-content', className])}
    style={style}
    fullHeight={fullHeight}
    $height={height || DEFAULT_HEIGHT}
    iconSize={iconSize || DEFAULT_ICON_SIZE}
  >
    <Empty
      description={descripion || <Trans>No data</Trans>}
      image={Empty.PRESENTED_IMAGE_SIMPLE}
    />
  </S.StyledNoContent>
);

export default NoContent;
