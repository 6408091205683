import React from 'react';

import { Trans } from '@lingui/macro';
import { Desktop } from '@phoenix-systems/react-layout';

import AppLayout from 'components/app/components/appLayout';
import Breadcrumbs from 'components/app/components/breadcrumbs';
import DomainPage from 'components/domain/components/domainPage';
import TriggerHeaderButtons from 'components/trigger/components/triggerHeaderButtons';
import TriggersTable from 'components/trigger/components/triggersTable';
import Page from 'components/ui/page';
import routes from 'config/routes/routes';
import useIsDomain from 'hooks/useIsDomain';

const TriggersListPage: React.FC = () => {
  const isDomain = useIsDomain();

  const title = <Trans>Triggers</Trans>;

  if (isDomain) {
    return (
      <AppLayout>
        <DomainPage
          route={routes.domain.scheduler.list}
          noScroll
          title={title}
          content={<TriggersTable />}
          headerButtons={<TriggerHeaderButtons hasCalendar hasCreate />}
        />
      </AppLayout>
    );
  }

  return (
    <AppLayout>
      <Page
        scrollableProps={{ scrollId: 'page-scroll' }}
        noScroll
        title={title}
        content={<TriggersTable />}
        titleTop={
          <Desktop>
            <Breadcrumbs />
          </Desktop>
        }
        titleElements={<TriggerHeaderButtons hasCalendar hasCreate />}
      />
    </AppLayout>
  );
};

export default TriggersListPage;
