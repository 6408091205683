import React from 'react';

import { Trans } from '@lingui/macro';
import { Scrollable } from '@phoenix-systems/react-layout';
import { Link } from 'react-router-dom';

import * as S from './errorPageSc';

import AppLayout from 'components/app/components/appLayout';

export enum ErrorPageType {
  NOT_FOUND = 'NOT_FOUND',
  SERVER_ERROR = 'SERVER_ERROR'
}

type ErrorPageProps = {
  type: ErrorPageType;
};

const ErrorPage: React.FC<ErrorPageProps> = ({ type }) => {
  const getContent = () => {
    switch (type) {
      case ErrorPageType.NOT_FOUND:
        return (
          <>
            <h2>
              <Trans>Page not found</Trans>
            </h2>
            <p>
              <Trans>The page you requested was not found.</Trans>
            </p>
          </>
        );

      case ErrorPageType.SERVER_ERROR:
        return (
          <>
            <h2>
              <Trans>Internal server error</Trans>
            </h2>
            <p>
              <Trans>
                Something went wrong with the server request. Please contact the administrator or
                try again.
              </Trans>
            </p>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <AppLayout>
      <S.PageContainer>
        <Scrollable scrollY>
          <S.ContentContainer>
            {getContent()}
            <Link to="/">
              <Trans>Back to start</Trans>
            </Link>
          </S.ContentContainer>
        </Scrollable>
      </S.PageContainer>
    </AppLayout>
  );
};

export default ErrorPage;
