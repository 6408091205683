import tw, { styled, css } from 'twin.macro';

type LoaderWrapperProps = {
  $height?: number;
  fullHeight?: boolean;
  opacity?: number;
};

export const LoaderWrapper = styled.div<LoaderWrapperProps>(({ fullHeight, $height, opacity }) => [
  css`
    ${tw`flex items-center justify-center`};
  `,

  fullHeight &&
    css`
      ${tw`h-full`};
    `,

  $height &&
    css`
      height: ${$height}px;
    `,

  opacity &&
    css`
      opacity: ${opacity};
    `
]);

export const LoaderMessageContainer = styled.div(() => [
  css`
    ${tw`bg-light-gray  rounded flex flex-row relative items-center border border-ant-border-gray p-8`};
    animation: fadeIn 400ms ease-in-out;

    & .loader-wrapper {
      ${tw`h-full flex items-center mr-16 ml-4`};
    }

    & .message {
      ${tw`font-700`};
    }
  `
]);
