import React, { useEffect } from 'react';

import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { CollectionEditorDialogProps, useFormContext } from '@phoenix-systems/react-form';
import { Form, Input } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { RuleObject } from 'rc-field-form/lib/interface';

import * as F from 'components/_styled/formSc';
import TooltipLabel from 'components/docs/components/docsTooltip/tooltipLabel';
import Button from 'components/ui/button';
import useDefaultModalProps from 'hooks/useDefaultModalProps';
import { DocId } from 'services/api/domain/hint';

type SchemaFormData = {
  name: string;
};

type SchemaFormDialogProps = CollectionEditorDialogProps<string> & {
  schemes: string[];
};

const SchemaFormDialog: React.FC<SchemaFormDialogProps> = ({
  data,
  isOpen,
  onClose,
  insertData,
  schemes
}) => {
  const [form] = Form.useForm<SchemaFormData>();
  const [formState, formActions] = useFormContext(form);
  const modalProps = useDefaultModalProps();

  const handleClose = () => {
    onClose();
    formActions.reset();
  };

  const handleInsert = () => {
    form.validateFields().then(formData => {
      insertData(formData.name);
      handleClose();
    });
  };

  useEffect(() => {
    if (data) {
      formActions.reset({ name: data }, true);

      return;
    }

    formActions.reset({ name: undefined }, false);
  }, [data, isOpen, formActions]);

  const validateName = (rule: RuleObject, value: string) =>
    new Promise((resolve, reject) => {
      if (schemes.includes(value) && value !== data) {
        reject(new Error('Schema name already in use.'));
      } else {
        resolve(value);
      }
    });

  return (
    <Modal
      {...modalProps}
      title={!data ? <Trans>Add Schema</Trans> : <Trans>Edit Schema</Trans>}
      visible={isOpen}
      footer={
        <>
          <Button onClick={handleClose} action="cancel" />
          <Button action="insert" onClick={handleInsert} formState={formState} />
        </>
      }
    >
      <F.StyledForm
        form={form}
        name="schema-form"
        onFieldsChange={formActions.onFieldsChange}
        onValuesChange={formActions.onValuesChange}
        $labelWidth={92}
        colon={false}
        //  initialValues={{ name: data }}
      >
        <Form.Item
          label={<TooltipLabel docId={DocId.DOMAIN_SCHEMA} label={<Trans>Name</Trans>} />}
          messageVariables={{ name: i18n._(t`Name`) }}
          name="name"
          rules={[
            { required: true },
            {
              pattern: /^[A-Z]+$/,
              message: i18n._('Only uppercase characters allowed.')
            },
            {
              validator: validateName,
              message: i18n._(t`A schema with name "${form.getFieldValue('name')}" already exists.`)
            }
          ]}
        >
          <Input />
        </Form.Item>
      </F.StyledForm>
    </Modal>
  );
};

export default SchemaFormDialog;
