import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { useIsMobile } from '@phoenix-systems/react-layout';
import { getRoute } from '@phoenix-systems/react-router';
import { Table } from '@phoenix-systems/react-table';
import {
  CustomColumnType,
  ListQueryParams,
  TableRef
} from '@phoenix-systems/react-table/dist/types/types';
import Highlighter from 'react-highlight-words';
import { useDispatch, useSelector } from 'react-redux';

import * as S from './domainsTableSc';
import DomainsTableSingle from './domainTableSingle';

import * as T from 'components/_styled/tableSc';
import DomainActions from 'components/domain/actions/domainActions';
import BooleanIcon from 'components/ui/booleanIcon';
import Loader from 'components/ui/loader';
import tableLocales from 'config/langauge/table.locales';
import routes from 'config/routes/routes';
import { Domain, Shard, useDomains } from 'services/api/domain/domain';
import history from 'services/history';
import { st_domain_setDomainName } from 'services/store/domain/domain.actions';
import { st_domain_getDomainName } from 'services/store/domain/domain.selectors';
import { st_domainsTable_setParams } from 'services/store/domainsTable/domainsTable.actions';
import { st_domainsTable_getParams } from 'services/store/domainsTable/domainsTable.selectors';

const DomainsTable: React.FC = () => {
  const tableRef = useRef<TableRef>(null);
  const domainsQuery = useDomains();
  const domainName = useSelector(st_domain_getDomainName);
  const dispatch = useDispatch();
  const params = useSelector(st_domainsTable_getParams);
  const [data, setData] = useState<Domain[]>();
  const [isInitialized, setInitialized] = useState(false);
  const isMobile = useIsMobile();

  const handleSelectChange = (selectedRowKeys: React.Key[]) => {
    dispatch(st_domain_setDomainName(selectedRowKeys[0] as string));
  };

  const handleRemoveSelectedItem = () => {
    dispatch(st_domain_setDomainName(undefined));
  };

  const getColumns = useCallback(() => {
    const cls: CustomColumnType<Domain>[] = [
      {
        title: i18n._('Domain Name'),
        dataIndex: 'domainName',
        key: 'domainName',
        className: 'bold',
        sorter: (a: unknown, b: unknown) =>
          (a as Domain).domainName.localeCompare((b as Domain).domainName),
        // eslint-disable-next-line react/display-name
        render: value => (
          <>
            <Highlighter
              searchWords={params.searchText ? [params.searchText] : []}
              textToHighlight={value || ''}
              autoEscape
            />
            <T.EditBtn
              className="edit-btn"
              type="link"
              icon={
                <FontAwesomeIcon
                  icon={['fas', 'pen']}
                  onClick={() => history.push(getRoute(routes.domain.overview, value))}
                />
              }
            />
          </>
        )
      },

      {
        title: i18n._('Id'),
        dataIndex: 'id',
        key: 'id',
        disabled: true,
        ellipsis: true,

        sorter: (a, b) => a.id.localeCompare(b.id)
      },
      {
        title: i18n._('Administrators'),
        dataIndex: 'admins',
        key: 'admins',
        // disabled: true,
        // eslint-disable-next-line react/display-name
        render: (value: string[]) => <>{value?.join(', ')}</>,
        ellipsis: true,
        sorter: (a, b) => {
          const uriA = a.uri || '';
          const uriB = b.uri || '';

          return uriA.localeCompare(uriB);
        },
        responsiveDisabled: {
          max: 1000
        }
      },
      {
        title: i18n._('Uri'),
        dataIndex: 'uri',
        key: 'uri',
        disabled: true,
        // eslint-disable-next-line react/display-name
        render: (value: string) => (
          <a href={value} target="_blank" rel="noreferrer">
            {value}
          </a>
        ),
        ellipsis: true,
        sorter: (a, b) => {
          const uriA = a.uri || '';
          const uriB = b.uri || '';

          return uriA.localeCompare(uriB);
        },
        responsiveDisabled: {
          max: 1000
        }
      },
      {
        title: i18n._('Schemes'),
        dataIndex: 'schemes',
        key: 'schemes',
        responsiveDisabled: {
          max: 400
        },
        // eslint-disable-next-line react/display-name
        render: (value: string[], record) => {
          const nodes: ReactNode[] = [];
          value?.forEach((schema, index) => {
            if (schema === record.defaultSchema) {
              nodes.push(
                <span key={schema}>
                  <b>{schema}</b>
                </span>
              );
            } else {
              nodes.push(<span key={schema}>{schema}</span>);
            }

            if (index < value.length - 1) {
              // eslint-disable-next-line react/no-array-index-key
              nodes.push(<span key={`sep-${index}`}>, </span>);
            }
          });

          return nodes;
        }
      },
      {
        title: i18n._('Shards'),
        dataIndex: 'shards',
        key: 'shards',
        disabled: true,
        // eslint-disable-next-line react/display-name
        render: (value: Shard[]) => {
          const values: string[] = [];
          value.forEach(shard => values.push(shard.name));

          return values.join(', ');
        }
      },
      {
        title: i18n._('Description'),
        dataIndex: 'descritpion',
        key: 'description',
        disabled: true,
        // eslint-disable-next-line react/display-name
        render: (value: string) => (
          <S.Description style={{ fontSize: 13, lineHeight: 1 }}>{value}</S.Description>
        ),
        filters: [
          { text: 'true', value: true },
          { text: 'false', value: false }
        ]
      },
      {
        title: i18n._('Deletable'),
        dataIndex: 'deletable',
        key: 'deletable',
        width: 120,
        responsiveDisabled: {
          max: 600
        },
        // eslint-disable-next-line react/display-name
        render: (value: boolean) => <BooleanIcon value={value} />,
        sorter: (a: unknown, b: unknown) =>
          (a as Domain).deletable?.toString().localeCompare((b as Domain).deletable?.toString()),
        filters: [
          { text: 'true', value: true },
          { text: 'false', value: false }
        ],
        getDisplayedFilterValue: (value: boolean) => value.toString()
      },
      {
        title: <FontAwesomeIcon icon={['fas', 'cog']} className="setting-icon" />,
        key: 'actions',
        width: 50,
        className: 'actions',
        // eslint-disable-next-line react/display-name
        render: (value, record) => <DomainActions data={record} mode="ICON_BUTTON" />,
        hiddenInConfig: true
      }
    ];

    return cls;
  }, [params.searchText]);

  useEffect(() => {
    if (tableRef.current && params && !isInitialized) {
      tableRef.current.setQueryParams(params);
      setInitialized(true);
    }
  }, [params, isInitialized]);

  useEffect(() => {
    if (params.searchText && params.searchText !== '') {
      const matches = domainsQuery.data?.filter(
        d => d.domainName.search(new RegExp(params.searchText || '', 'gi')) !== -1
      );
      setData(matches);
    } else {
      setData(domainsQuery.data);
    }
  }, [domainsQuery.data, params.searchText]);

  return (
    <T.StyledTable className="domain">
      <div>
        <Table
          ref={tableRef}
          table={{
            dataSource: data,
            loading: domainsQuery.isFetching,
            size: 'small',
            rowKey: 'domainName',
            columns: getColumns(),
            rowSelection: {
              type: 'radio',
              onChange: handleSelectChange,
              selectedRowKeys: domainName !== undefined ? [domainName] : []
            },
            onRow: (record: Domain) => ({
              onClick: e => {
                if (!isMobile) {
                  const isEditBtn = (e.target as HTMLElement).closest('.edit-btn') !== null;

                  if (!isEditBtn) {
                    dispatch(st_domain_setDomainName(record.domainName));
                  }

                  return;
                }
                dispatch(st_domain_setDomainName(record.domainName));
                history.push(getRoute(routes.domain.overview, record.domainName));
              },
              onDoubleClick: () => {
                dispatch(st_domain_setDomainName(record.domainName));
                history.push(getRoute(routes.domain.overview, record.domainName));
              }
            }),
            pagination: {
              pageSizeOptions: ['10', '20', '50'],
              pageSize: params.pageSize || 20,
              current: params.currentPage || 1,
              showSizeChanger: true,
              size: isMobile ? 'default' : undefined
            }
          }}
          defaultTableContainerHeight={!isMobile ? '55%' : undefined}
          fillHeight
          layout={!isMobile ? 'single-split' : 'default'}
          topNavElements={{
            search: {
              value: params.searchText,
              placeholder: i18n._('Search domains...')
            },
            totalCount: !isMobile
              ? {
                  icon: <FontAwesomeIcon icon={['fas', 'globe']} />,
                  loader: <Loader size={12} />
                }
              : false,
            reload: !isMobile
              ? {
                  onChange: () => {
                    domainsQuery.refetch();
                  }
                }
              : false,
            tableConfig: isMobile ? false : undefined,
            resetParams: true
          }}
          onRemoveSelectedItem={handleRemoveSelectedItem}
          locales={{
            '1 item selected': i18n._('1 domain selected'),
            'items selected': i18n._('domains selected'),
            'Nothing selected': i18n._('No domain selected'),
            ...tableLocales
          }}
          renderSingle={(selectedItems: Domain[]) => (
            <>
              <DomainsTableSingle data={selectedItems} />
            </>
          )}
          onQueryParamsChange={(currentParams: Partial<ListQueryParams>) => {
            dispatch(st_domainsTable_setParams(currentParams));
          }}
          activeFilters={{ enabled: true, placement: 'default' }}
        />
      </div>
    </T.StyledTable>
  );
};

export default DomainsTable;
