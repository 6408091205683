import tw, { styled, css } from 'twin.macro';

export const UserInfo = styled.div(() => [
  css`
    ${tw`bg-light-gray-500 border-b border-ant-border-gray`};

    & > div {
      ${tw`py-12 px-24 flex flex-row items-start text-13`};

      &.org-unit {
        ${tw`pb-12   border-t border-ant-border-gray`};

        & svg {
          ${tw`mt-2`};
        }
      }

      & svg {
        ${tw`text-16 inline-block mr-12 text-primary`};
      }
    }
  `
]);
