import RestClient from '@phoenix-systems/rest-client';
import { AxiosRequestConfig } from 'axios';

const hasLogger = false;
const apiPath = window._env_.API_URI;
const domainServiceVersion = window._env_.API_VERSION;

if (!apiPath) {
  throw new Error(
    "Initialize error on @phoenix-systems/rest-client: there's no api host in current env, pleace check 'window._env_.API_URI' variable"
  );
}

if (!domainServiceVersion) {
  throw new Error(
    'Initialize error: Missing environment param: "API_VERSION". pleace check \'window._env_.API_VERSION\' variable'
  );
}

const apiClient = new RestClient({
  baseUrl: apiPath,
  logger: window._env_.ENVIRONMENT === 'local' && hasLogger
});

apiClient
  .getAxiosInstance()
  .interceptors.request.use((_config: AxiosRequestConfig): AxiosRequestConfig => {
    const config = _config;

    return config;
  });

export default apiClient;
