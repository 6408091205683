import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { FormItem, useFormContext } from '@phoenix-systems/react-form';
import { Form, InputNumber } from 'antd';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

import * as S from './dataGeneratorFormSc';

import * as F from 'components/_styled/formSc';
import useContainer from 'components/app/components/containerProvider/useContainer';
import DocsTooltip from 'components/docs/components/docsTooltip';
import TooltipLabel from 'components/docs/components/docsTooltip/tooltipLabel';
import EntityFieldSelector from 'components/domain/components/entityFieldSelector';
import Button from 'components/ui/button';
import ErrorInfo from 'components/ui/errorInfo';
import FormContainer from 'components/ui/formContainer';
import LoaderMessage from 'components/ui/loader/loaderMessage';
import MonacoEditor from 'components/ui/monacoEditor';
import routes from 'config/routes/routes';
import useDomainName from 'hooks/useDomainName';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';
import useNotification from 'hooks/useNotification';
import useOnDomainSchemaChange from 'hooks/useOnDomainSchemaChange';
import useSchemaName from 'hooks/useSchemaName';
import { AnyObject } from 'index.types';
import { DocId } from 'services/api/domain/hint';
import {
  api_generateTestData,
  TestDataGeneratorParams
} from 'services/api/domain/testDataGenerator';
import { log } from 'utils';

const MAX_AMOUNT = 1000000;
const MAX_RESULT_AMOUNT = 100;

const DEFAULT_VALUES = {
  amount: 1
};

const DataGeneratorForm: React.FC = () => {
  const domainName = useDomainName();
  const { formContainer } = useContainer();
  const [form] = Form.useForm<TestDataGeneratorParams>();
  const [formState, formActions] = useFormContext(form, DEFAULT_VALUES);
  const schema = useSchemaName();
  const [addNotification] = useNotification();
  const [responseData, setResponseData] = useState<AnyObject[]>();
  const goto = useGoToDomainRoute();

  const generateDataMutation = useMutation(
    (params: TestDataGeneratorParams) => api_generateTestData(params),
    {
      onSuccess: data => {
        const res: AnyObject[] = [];
        data.forEach((item, index) => {
          if (index <= MAX_RESULT_AMOUNT) {
            res.push(item);
          }
        });
        setResponseData(res);
        addNotification({
          type: 'success',
          message: i18n._(t`Successfully generated test data for domain "${domainName}".`)
        });
      },
      onError: (error: AxiosError) => {
        if (error.response?.data) {
          addNotification({
            type: 'error',
            message: i18n._(
              t`Failed to generate test data for domain "${domainName}". Reason: ${error.response.data}`
            )
          });
        } else {
          addNotification({
            type: 'error',
            message: i18n._(t`Failed to generate test data for domain "${domainName}".`)
          });
        }
      }
    }
  );

  const handleGenerateData = async () => {
    generateDataMutation.reset();
    setResponseData(undefined);

    try {
      let formData = await form.validateFields();
      formData = {
        ...formData,
        domainName,
        schema
      };
      generateDataMutation.mutate(formData);
    } catch (validationError) {
      log(validationError);
    }
  };

  const handleReset = () => {
    setResponseData(undefined);
    formActions.reset({ amount: 1, simpleClassName: undefined }, false);
    generateDataMutation.reset();
  };

  const handleGotoQuery = () => {
    goto(routes.domain.queryData.entityDocument.root, {
      query: { entity: form.getFieldValue('simpleClassName') }
    });
  };

  useOnDomainSchemaChange(handleReset);

  return (
    <>
      <FormContainer
        isStickyDisabled
        title={
          <>
            <FontAwesomeIcon icon={['fas', 'sync']} />
            <Trans>Genereate test data</Trans>
            <DocsTooltip docId={DocId.DOMAIN_TEST_DATA_GENERATOR} />
          </>
        }
        buttons={
          <>
            <>
              <Button
                action="reset"
                onClick={handleReset}
                disabled={
                  (generateDataMutation.isLoading || !formState.hasChanged) && !responseData
                }
              />
              {generateDataMutation.isSuccess && (
                <Button onClick={handleGotoQuery} icon={['fas', 'search']} type="primary">
                  <Trans>Query the full result</Trans>
                </Button>
              )}
              <Button
                action="save"
                onClick={handleGenerateData}
                icon={['fas', 'sync']}
                mutation={generateDataMutation}
                formState={formState}
              >
                <Trans>Generate test data</Trans>
              </Button>
            </>
          </>
        }
        messages={
          <>
            {generateDataMutation.isLoading && (
              <LoaderMessage>
                <Trans>
                  Generate test data in progress. Depending the size of the &quot;amount&quot; this
                  can take time...
                </Trans>
              </LoaderMessage>
            )}
            {generateDataMutation.isError && (
              <ErrorInfo
                message={i18n._(`Failed to generate test data for domain "${domainName}".`)}
                error={generateDataMutation.error}
                // reset={() => handleGenerateData}
              />
            )}
            {responseData && (
              <F.FormAlert
                type="success"
                message={
                  form.getFieldValue('amount') < MAX_RESULT_AMOUNT
                    ? i18n._(
                        t`Successfully generated ${form.getFieldValue(
                          'amount'
                        )} items of test data for domain "${domainName}".`
                      )
                    : i18n._(
                        `Successfully generated ${form.getFieldValue(
                          'amount'
                        )} items of test data for domain "${domainName}". The preview of JSON result is limited to 100 items.`
                      )
                }
              />
            )}
          </>
        }
      >
        <F.StyledForm
          $labelWidth={94}
          form={form}
          layout="horizontal"
          validateTrigger="onChange"
          name="generate-testdata-form"
          onValuesChange={formActions.onValuesChange}
          onFieldsChange={formActions.onFieldsChange}
          initialValues={DEFAULT_VALUES}
          colon={false}
        >
          <FormItem
            label={
              <TooltipLabel
                docId={DocId.DOMAIN_TESTDATA_GENERATOR_ENTITY}
                label={<Trans>Entity</Trans>}
              />
            }
            rules={[{ required: true }]}
            messageVariables={{ name: i18n._('Entity') }}
            name="simpleClassName"
            registerField={formActions.registerField}
          >
            <EntityFieldSelector
              treeDefaultExpandAll
              getPopupContainer={() => formContainer}
              onChangePrepareValue={nodeData => nodeData?.value}
            />
          </FormItem>
          <FormItem
            label={
              <TooltipLabel
                docId={DocId.DOMAIN_TESTDATA_GENERATOR_AMOUNT}
                label={<Trans>Amount</Trans>}
              />
            }
            rules={[{ required: true }]}
            messageVariables={{ name: i18n._('Amount') }}
            name="amount"
            registerField={formActions.registerField}
          >
            <InputNumber max={MAX_AMOUNT} />
          </FormItem>
        </F.StyledForm>
      </FormContainer>
      {responseData && (
        <S.GeneratedResult>
          <h4>
            <Trans>Generated data</Trans>
          </h4>
          <MonacoEditor readOnly height="calc(100vh - 565px)" value={responseData} mode="JSON" />
        </S.GeneratedResult>
      )}
    </>
  );
};

export default DataGeneratorForm;
