import React, { ReactNode, useState } from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Menu } from 'antd';
import { cloneDeep, omit } from 'lodash';
import { useDispatch } from 'react-redux';
import { useImmer } from 'use-immer';

import EntityFieldFormDialog from 'components/domain/components/domainDiagram/entityFieldFormDialog';
import * as S from 'components/domain/components/domainDiagram/entityNode/entityNodeSc';
import { EntityFieldFormData } from 'components/domain/components/domainDiagram/types';
import { prepareNodeData } from 'components/domain/components/domainDiagram/utils';
import { EntityNodeData } from 'services/api/domain/domainDiagram';
import {
  st_domainDiagram_setDialogOpen,
  st_domainDiagram_updateNodeData
} from 'services/store/domainDiagram/domainDiagram.actions';

type AddFieldActionState = {
  isDialogOpen: boolean;
};

type AddFieldActionProps = {
  data: EntityNodeData;
  nodeId: string;
  closeNavigation?: () => void;
  label?: string | ReactNode;
  position?: number;
  icon?: IconProp;
  type: 'CONTEXT_NAV' | 'ENTITY_NODE';
  visible?: boolean;
};

const AddFieldAction: React.FC<AddFieldActionProps> = props => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { data, nodeId, closeNavigation, label, position, icon, type, visible } = props;
  const pr = omit(props, ['nodeId', 'data', 'closeNavigation', 'poistion', 'label', 'icon']);
  const [state, setState] = useImmer<AddFieldActionState>({
    isDialogOpen: false
  });
  const dispatch = useDispatch();
  const [isAddBtnVisible, setAddBtnVisible] = useState(true);

  const handleEditEntity = () => {
    setAddBtnVisible(false);

    if (type === 'CONTEXT_NAV' && closeNavigation) {
      closeNavigation();
    }
    setState(draft => {
      draft.isDialogOpen = true;
    });
    dispatch(st_domainDiagram_setDialogOpen(true));
  };

  const handleDialogClose = () => {
    dispatch(st_domainDiagram_setDialogOpen(false));
    setState(draft => {
      draft.isDialogOpen = false;
    });
    setTimeout(() => setAddBtnVisible(true), 400);
  };

  const handleUpdateEntity = (formData: EntityFieldFormData | EntityFieldFormData[]) => {
    if (!Array.isArray(formData)) {
      formData.uniqueId = formData.uniqueId || false;
      const newData = cloneDeep(data);
      newData.fields.splice(position !== undefined ? position : data.fields.length, 0, formData);
      dispatch(st_domainDiagram_updateNodeData(prepareNodeData(newData), nodeId));
      handleDialogClose();
    }
  };

  return (
    <>
      {type === 'CONTEXT_NAV' && (
        <Menu.Item
          {...pr}
          key="insert-entity"
          icon={icon && <FontAwesomeIcon icon={icon} />}
          onClick={handleEditEntity}
        >
          {label}
        </Menu.Item>
      )}
      {type === 'ENTITY_NODE' && (
        <S.EntityFieldAdder visible={visible && isAddBtnVisible} onClick={handleEditEntity}>
          <FontAwesomeIcon icon={['far', 'plus-circle']} />
          <Trans>Add field</Trans>
        </S.EntityFieldAdder>
      )}

      <EntityFieldFormDialog
        onClose={handleDialogClose}
        mode="CREATE"
        isOpen={state.isDialogOpen}
        insertData={handleUpdateEntity}
      />
    </>
  );
};

export default AddFieldAction;
