import { Page } from '@phoenix-systems/react-layout';
import { Alert } from 'antd';
import tw, { styled, css } from 'twin.macro';

export const StyledPage = styled(Page)(() => [
  css`
    & .ant-layout-header {
      ${tw`px-mobileX md:px-desktopX py-8 md:py-16 bg-light-gray shadow-sm`};
    }

    & .layout-title {
      ${tw`font-700 text-primary text-28 whitespace-nowrap overflow-hidden`};
      text-overflow: ellipsis;
    }

    & .ant-layout-footer {
      ${tw`border-t border-ant-border-gray md:px-desktopX px-mobileX`};
    }

    & .layout-title-container {
      ${tw`flex flex-row`};
    }

    & .layout-content-inner {
      ${tw`px-mobileX md:px-desktopX md:pt-16`};
    }

    & .layout-title-elements {
      ${tw`ml-auto items-center h-full pl-32 flex flex-row whitespace-nowrap`}
    }

    &.domain-diagram-page .ant-layout-header {
      ${tw`pb-8`}
    }

    &.entiy-document-query-page .ant-layout-content {
      ${tw`h-full`}

      & .layout-content-inner {
        ${tw`h-full relative`}
      }
    }

    &.no-header-highlight .ant-layout-header {
      ${tw`bg-transparent`}
    }

    &.page-swagger {
      .ant-layout-content {
        ${tw`h-full`}

        & .layout-content-inner {
          ${tw`h-full relative p-0`}
        }
      }

      & .ant-layout-header {
        ${tw`pb-0`}
      }
    }

    &.no-scroll {
      ${tw`h-full`}

      .layout-content-inner.no-scroll {
        ${tw`h-full relative`}
      }
    }
  `
]);

export const PageSubTitle = styled.p(() => [
  css`
    ${tw`font-600 text-12 text-gray-600 mb-12 mt-4 leading-none`}

    & a {
      ${tw`font-600 text-12 text-gray-400 hover:text-primary hover:underline`}
    }
  `
]);

export const FooterContainer = styled.div(() => [
  css`
    ${tw`pt-12 pb-16 mt-4 flex flex-row`};
  `
]);

export const FooterButtons = styled.div(() => [
  css`
    ${tw`ml-auto flex flex-row`};

    & button {
      ${tw`ml-12`};
      min-width: 120px;
    }
  `
]);

export const FooterButtonSeparator = styled.div(() => [
  css`
    ${tw`w-1 border-l border-ant-border-gray h-full ml-24 mr-12`};
  `
]);

export const SimpleMessage = styled.p(() => [
  css`
    ${tw`mx-40 my-24 font-600`};

    & svg {
      ${tw`mr-12`};
    }

    & b {
      ${tw`text-secondary`};
    }

    a {
      ${tw`text-gray-400 text-13 inline-block mt-4`};
    }
  `
]);

export const HeaderButtons = styled.div(() => [
  css`
    & .ant-btn {
      ${tw`ml-12 h-34 mt-4`};
    }
  `
]);

export const NotFoundMessage = styled(Alert)(() => [
  css`
    ${tw`mx-40 mt-20 font-500 bg-light-gray-active`};

    & b {
      ${tw`font-700`};
    }

    & a {
      ${tw`font-700 block mt-12 `};

      & svg {
        ${tw`mr-6`};
      }
    }
  `
]);
