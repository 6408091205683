import { createSelector } from 'reselect';

import { DbImportsTableState, DomainDbImportTableSingleView } from './dbImportsTable.types';

import { TableDataAggregationParams } from 'index.types';

export const dbImportsTableState = (state: {
  dbImportsTable: DbImportsTableState;
}): DbImportsTableState => state.dbImportsTable;

export const st_dbImportsTable_getParams = createSelector(
  [dbImportsTableState],
  (dbImportsTable): TableDataAggregationParams => dbImportsTable.params
);

export const st_dbImportsTable_getSingleView = createSelector(
  [dbImportsTableState],
  (dbImportsTable): DomainDbImportTableSingleView => dbImportsTable.singleView
);

export const st_dbImportsTable_getActiveKey = createSelector(
  [dbImportsTableState],
  (dbImportsTable): string | undefined => dbImportsTable.activeKey
);
