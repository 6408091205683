import React from 'react';

import { Trans } from '@lingui/macro';

import * as P from 'components/_styled/pageSc';
import AppLayout from 'components/app/components/appLayout';
import DomainsTable from 'components/domain/components/domainsTable';
import Button from 'components/ui/button';
import routes from 'config/routes/routes';
import history from 'services/history';

const DashboardPage: React.FC = () => (
  <AppLayout>
    <P.StyledPage
      noScroll
      scrollableProps={{ scrollId: 'page-scroll' }}
      className="no-header-highlight"
      content={<DomainsTable />}
      title={<Trans>Domains</Trans>}
      titleElements={
        <Button action="create" onClick={() => history.push(routes.domain.create)}>
          <Trans>Create Domain</Trans>
        </Button>
      }
    />
  </AppLayout>
);

export default DashboardPage;
