import React from 'react';

import { i18n } from '@lingui/core';
import { Menu } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import clsx from 'clsx';
import { CSSProperties } from 'styled-components';

import CheckFtpConnectionAction from './checkFtpConnectionAction';
import CreateFtpConnectionAction from './createFtpConnectionAction';
import DeleteFtpConnectionAction from './deleteFtpConnectionAction';
import EditFtpConnectionAction from './editFtpConnectionAction';

import ContextNavigation from 'components/app/components/contextNavigation';
import Button from 'components/ui/button';
import useFtpConnectionName from 'hooks/useFtpConnectionName';
import { ActionMode } from 'index.types';
import { FTPConnection } from 'services/api/domain/ftpConnection';

type FtpConnectionActionsProps = {
  data: FTPConnection;
  style?: CSSProperties;
  className?: string;
  mode: ActionMode;
  popoverPlacement: TooltipPlacement;
};

const { Divider } = Menu;

const FtpConnectionActions: React.FC<FtpConnectionActionsProps> = ({
  data,
  style,
  mode,
  popoverPlacement,
  className
}) => {
  const ftpConnectionName = useFtpConnectionName();

  return (
    <>
      <ContextNavigation
        placement={popoverPlacement}
        /*  offset={mode === 'ICON_BUTTON' ? [4, -8] : undefined} */
        trigger={
          <Button
            isIconOnly={mode === 'ICON_BUTTON'}
            action="actions"
            hasMenu
            className={clsx(['ftp-connection-actions', className])}
            style={style}
            title={i18n._('FTP connection actions')}
          />
        }
      >
        {ftpConnectionName ? (
          <CreateFtpConnectionAction mode="LIST_ITEM" />
        ) : (
          <EditFtpConnectionAction data={data} mode="LIST_ITEM" />
        )}
        <Divider />
        <CheckFtpConnectionAction data={data} />
        <Divider />
        <DeleteFtpConnectionAction data={data} />
      </ContextNavigation>
    </>
  );
};

export default FtpConnectionActions;
