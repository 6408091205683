import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import { Menu } from 'antd';
import { omit } from 'lodash';
import { CSSProperties } from 'styled-components';
import { useImmer } from 'use-immer';

import DeleteObjectDialog from './deleteObjectDialog';

import Button from 'components/ui/button';
import { ButtonProps } from 'components/ui/button/button.types';
import { Domain } from 'services/api/domain/domain';

const { Item } = Menu;

type DeleteObjectActionProps = {
  data: Domain;
  mode: 'MENU_ITEM' | 'BUTTON' | 'ICON_BUTTON';
  buttonProps?: ButtonProps;
  style?: CSSProperties;
  onDelete?: () => void;
};

type DeleteObjectActionState = {
  isDialogOpen: boolean;
};

const DeleteObjectAction: React.FC<DeleteObjectActionProps> = props => {
  const antProps = omit(props, ['data', 'mode', 'buttonProps', 'onDelete', 'style']);
  const [state, setState] = useImmer<DeleteObjectActionState>({
    isDialogOpen: false
  });
  const { mode, style, data, buttonProps, onDelete } = props;

  const handleDeleteObjectRequest = () => {
    setState(draft => {
      draft.isDialogOpen = true;
    });
  };

  return (
    <>
      {mode === 'MENU_ITEM' && (
        <Item
          {...antProps}
          style={style}
          key={`activate-trigger_${data.id}`}
          icon={<FontAwesomeIcon icon={['fas', 'trash']} />}
          onClick={handleDeleteObjectRequest}
          className="delete"
        >
          <Trans>Delete object</Trans>
        </Item>
      )}
      {mode === 'BUTTON' && (
        <Button action="delete" {...buttonProps} style={style} onClick={handleDeleteObjectRequest}>
          <Trans>Delete object</Trans>
        </Button>
      )}

      {mode === 'ICON_BUTTON' && (
        <Button
          type="link"
          {...buttonProps}
          style={style}
          onClick={handleDeleteObjectRequest}
          title={i18n._('Delete object')}
          icon={['fas', 'trash']}
        />
      )}

      <DeleteObjectDialog
        isOpen={state.isDialogOpen}
        data={data}
        close={() => {
          if (onDelete) {
            onDelete();
          }
          setState(draft => {
            draft.isDialogOpen = false;
          });
        }}
      />
    </>
  );
};

export default DeleteObjectAction;
