import { i18n } from '@lingui/core';
import { NavigationConfig } from '@phoenix-systems/react-navigation';

export const getUserNavigationConfig = (logout: () => void): NavigationConfig => {
  const languageNav: NavigationConfig = {
    id: 'user-navigation',
    children: [
      {
        id: 'user-logout',
        title: i18n._('Sign out'),
        icon: ['fas', 'sign-out-alt'],
        onClick: () => logout()
      }
    ]
  };

  return languageNav;
};

export default getUserNavigationConfig;
