import React from 'react';

import { Select } from 'antd';
import { useSelector } from 'react-redux';

import { SchemaSelectorProps } from './schemaSelector.types';
import * as S from './schemaSelectorSc';

import useContainer from 'components/app/components/containerProvider/useContainer';
import DocsTooltip from 'components/docs/components/docsTooltip';
import useDomainFromCache from 'hooks/useDomainFromCache';
import useDomainName from 'hooks/useDomainName';
import useSchemaName from 'hooks/useSchemaName';
import { DocId } from 'services/api/domain/hint';
import history from 'services/history';
import {
  st_domain_getRoute,
  st_domain_getRouteParams
} from 'services/store/domain/domain.selectors';
import getDomainRoute from 'utils/getDomainRoute';

const { Option } = Select;

const SchemaSelector: React.FC<SchemaSelectorProps> = ({ onChange, readonly }) => {
  const schemaName = useSchemaName();
  const domain = useDomainFromCache();
  const { formContainer } = useContainer();
  const domainName = useDomainName();
  const route = useSelector(st_domain_getRoute);
  const routeParams = useSelector(st_domain_getRouteParams);

  const handleChange = (value: unknown) => {
    const schema = value as string;

    if (onChange) {
      onChange(schema);

      return;
    }

    if (route) {
      const path = getDomainRoute(route, domainName, schema, routeParams);
      history.push(path);
    }
  };

  if (!domain) {
    return null;
  }

  return (
    <S.SelectorContainer>
      <S.SelectInput
        open={readonly ? false : undefined}
        value={schemaName}
        loading={false}
        onChange={handleChange}
        getPopupContainer={() => formContainer}
        className={readonly ? 'readonly' : undefined}
      >
        {domain.schemes.map(scheme => (
          <Option key={scheme} value={scheme}>
            {scheme}
          </Option>
        ))}
      </S.SelectInput>
      <DocsTooltip docId={DocId.DOMAIN_SCHEMA} placement="bottomRight" />
    </S.SelectorContainer>
  );
};

export default SchemaSelector;
