import React from 'react';

import { Trans } from '@lingui/macro';
import { Col, Row } from 'antd';

import { getParsedHistoryDate } from '../documentHistory.utils';
import DocumentHistoryJsonDiff from '../documentHistoryJsonDiff';

import * as T from 'components/_styled/tableSc';
import DataItem from 'components/ui/dataItem';
import { ObjectHistory } from 'services/api/domain/objectHistory';

type DocumentHistoryTableOverviewProps = {
  data?: ObjectHistory;
};

const DocumentHistoryTableOverview: React.FC<DocumentHistoryTableOverviewProps> = ({ data }) => {
  if (!data) {
    return null;
  }

  return (
    <T.SingleContentContainer>
      <Row gutter={[24, 24]}>
        <Col xs={24} md={12}>
          <ul>
            <li>
              <DataItem
                hasCopy
                breakWord
                name={<Trans>Id</Trans>}
                value={data.id}
                copyValue={data.id}
              />
            </li>
            <li>
              <DataItem
                name={<Trans>Object domain id</Trans>}
                value={data.objectDomainId}
                hasCopy
                copyValue={data.id}
                breakWord
              />
            </li>
            <li>
              <DataItem
                name={<Trans>Date of change</Trans>}
                value={getParsedHistoryDate(data.dateOfChange)}
                copyValue={data.dateOfChange}
              />
            </li>
            <li>
              <DataItem
                name={<Trans>User id</Trans>}
                value={data.userid}
                hasCopy
                copyValue={data.dateOfChange}
              />
            </li>
            <li>
              <DataItem
                name={<Trans>Object type</Trans>}
                value={data.objectType}
                hasCopy
                copyValue={data.objectType}
              />
            </li>
            <li>
              <DataItem
                name={<Trans>Custom type</Trans>}
                value={data.customType}
                hasCopy
                copyValue={data.customType}
              />
            </li>
          </ul>
        </Col>
        <Col xs={24} md={12}>
          <ul>
            <li>
              <DataItem
                name={<Trans>JSON diff summary</Trans>}
                value={<DocumentHistoryJsonDiff size="default" data={data.parsedDiff} />}
                hasCopyTitle
                copyValue={JSON.stringify(data.parsedDiff)}
              />
            </li>
          </ul>
        </Col>
      </Row>
    </T.SingleContentContainer>
  );
};

export default DocumentHistoryTableOverview;
