import tw, { css, styled } from 'twin.macro';

export const TableMapperItem = styled.div(() => [
  css`
    & span {
      ${tw`text-12 text-primary inline-block font-600`};
      min-width: 5.8rem;
    }
  `
]);
