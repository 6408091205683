import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import Modal from 'antd/lib/modal/Modal';
import { useMutation, useQueryClient } from 'react-query';

import Button from 'components/ui/button/button';
import useDefaultModalProps from 'hooks/useDefaultModalProps';
import useDomainName from 'hooks/useDomainName';
import useNotification from 'hooks/useNotification';
import useSchemaName from 'hooks/useSchemaName';
import {
  api_deleteImportFromDb,
  DbImport,
  DeleteDbImportParams
} from 'services/api/domain/dataImport';

type DeleteDbImportDialogProps = {
  isOpen: boolean;
  data: DbImport;
  close: () => void;
};

const DeleteDbImportDialog: React.FC<DeleteDbImportDialogProps> = ({ data, isOpen, close }) => {
  const [addNotification] = useNotification();
  const queryClient = useQueryClient();
  const domainName = useDomainName();
  const schema = useSchemaName();
  const modalProps = useDefaultModalProps();

  const deleteMutation = useMutation(
    (params: DeleteDbImportParams) => api_deleteImportFromDb(params),
    {
      onSuccess: () => {
        addNotification({
          type: 'success',
          message: i18n._(t`Successfully deleted DB import "${data.name}".`)
        });

        queryClient.refetchQueries(['importsFromDb']);
        close();
      },
      onError: () => {
        addNotification({
          type: 'error',
          message: i18n._(t`Failed to delete DB import "${data.name}".`)
        });
      }
    }
  );

  const handleDelete = () => {
    deleteMutation.mutate({ domainName, schema, id: data.id });
  };

  return (
    <Modal
      {...modalProps}
      visible={isOpen}
      title={
        <>
          <FontAwesomeIcon icon={['fas', 'trash']} />
          <Trans>Delete Db Import {data.name}</Trans>
        </>
      }
      footer={
        <>
          <Button action="cancel" disabled={deleteMutation.isLoading} onClick={() => close()} />
          <Button action="delete" mutation={deleteMutation} onClick={handleDelete}>
            <Trans>Delete Db import</Trans>
          </Button>
        </>
      }
    >
      <p>
        Do you want to delete the DB import <b>{data.name}</b>?<br /> The attached tasks will also
        be deleted and no more executed.
      </p>
    </Modal>
  );
};

export default DeleteDbImportDialog;
