import React from 'react';

import { Scrollable } from '@phoenix-systems/react-layout';

import * as T from 'components/_styled/tableSc';
import TriggerAdder from 'components/trigger/components/triggerAdder';
import useDomainName from 'hooks/useDomainName';
import useSchemaName from 'hooks/useSchemaName';
import { DbImport } from 'services/api/domain/dataImport';
import { Trigger } from 'services/api/domain/trigger';

type DbImportTableSchedulerProps = {
  data: DbImport;
};

const DbImportTableScheduler: React.FC<DbImportTableSchedulerProps> = ({ data }) => {
  const domainName = useDomainName();
  const schema = useSchemaName();

  const handlePrepareTriggerData = () => {
    const triggerData: Partial<Trigger> = {
      taskDomain: domainName,
      taskScheme: schema,
      taskName: data.name,
      taskType: 'SQLDBImport',
      name: `${data.name}Trigger`,
      active: false,
      periodicity: 'NONE'
    };

    return triggerData;
  };

  if (!data) {
    return null;
  }

  return (
    <Scrollable scrollY>
      <T.SingleContentContainer style={{ marginBottom: 32 }}>
        <TriggerAdder
          getInitialData={handlePrepareTriggerData}
          taskName={data.name}
          isCreateTaskReadonly
        />
      </T.SingleContentContainer>
    </Scrollable>
  );
};

export default DbImportTableScheduler;
