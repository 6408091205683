import { Handle } from 'react-flow-renderer';
import tw, { styled, css } from 'twin.macro';

type NodeProps = {
  active: boolean;
};

export const Node = styled.div<NodeProps>(({ active }) => [
  css`
    ${tw`border border-primary  shadow-lg transition-all duration-200 rounded `};
    min-width: 200px;
  `,

  active &&
    css`
      ${tw`border-secondary hover:border-secondary`};
    `
]);

export const Head = styled.div(() => [
  css`
    ${tw`bg-primary px-12 py-8 border-b border-ant-border-gray relative `};

    & .react-flow__handle-right {
      ${tw`transition-all duration-200`};
    }

    &:hover .react-flow__handle-right {
      ${tw`bg-secondary transform scale-125`};
    }
  `
]);

export const Body = styled.div(() => [
  css`
    ${tw`bg-white`};
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  `
]);

export const Title = styled.h4(() => [
  css`
    ${tw`text-12 font-700 m-0 p-0 text-white`};
  `
]);

export const Label = styled.div(() => [
  css`
    ${tw`flex flex-row`};

    & span {
      ${tw`ml-auto pl-6 text-gray-500 font-mono text-11`};
    }
  `
]);

export const FieldsUl = styled.ul(() => [
  css`
    ${tw`text-12 font-700 m-0 p-0  `};

    & li:last-of-type {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  `
]);

type EdgeHandleProps = {
  active: boolean;
};

export const EdgeHandle = styled(Handle)<EdgeHandleProps>(({ active }) => [
  css`
    &.react-flow__handle {
      ${tw`bg-gray-300 hover:bg-gray-600 transition-all duration-200 transform scale-100 `};
      margin-top: -4px;
      width: 8px;
      height: 8px;
      transform-origin: center center;
    }
  `,

  active
    ? css`
        .react-flow__handle {
          ${tw`bg-primary hover:bg-primary`};
        }
      `
    : css`
        .react-flow__handle {
          ${tw`bg-gray-200 hover:bg-secondary`};
        }
      `
]);

export const PopupContainer = styled.div(() => [
  css`
    & .ant-popover {
      ${tw`pl-0`};
    }

    & .ant-popover.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow {
      ${tw`border-l border-b border-secondary`}
      left: -4px;
    }

    & .ant-popover .ant-popover-inner-content {
      ${tw`p-0`}
    }

    & .ant-popover .ant-popover-title {
      ${tw`font-700 text-white bg-secondary pt-8 pb-6 text-12`}
    }

    &
      .ant-menu-submenu-popup
      .ant-menu-vertical.ant-menu-sub
      > .ant-menu-submenu
      > .ant-menu-submenu-title {
      ${tw`h-36 my-0 text-12 font-700`}
      line-height: 36px;
    }

    &
      .ant-menu-submenu-popup
      .ant-menu-vertical.ant-menu-sub
      > .ant-menu-submenu
      > .ant-menu-submenu-title:hover {
      ${tw`hover:bg-light-gray`}
    }

    & .ant-menu-submenu-popup .ant-menu-vertical.ant-menu-sub .ant-menu-item {
      ${tw`h-36 my-0 text-12 font-700`}
      line-height: 36px;
    }

    & .ant-menu-submenu-popup .ant-menu-vertical.ant-menu-sub .ant-menu-item:hover {
      ${tw`hover:bg-light-gray`}
    }
  `
]);

type EntityFieldAdderProps = {
  visible?: boolean;
};
export const EntityFieldAdder = styled.div<EntityFieldAdderProps>(({ visible }) => [
  css`
    ${tw`transition-all duration-150 flex px-12 flex-row items-center bg-light-gray-active transform cursor-pointer hover:bg-light-gray-active hover:bg-primary hover:text-white font-600  text-12`};
    height: 0;
    overflow: hidden;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    & svg {
      ${tw`mr-6`};
    }
  `,

  visible &&
    css`
      ${tw`border-t border-ant-border-gray`};
      height: 34px;
    `
]);
