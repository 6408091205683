import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Divider } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

import * as D from 'components/_styled/dialogSc';
import TestFtpConnectionResponse from 'components/domain/components/testFtpConnection';
import BooleanIcon from 'components/ui/booleanIcon';
import Button from 'components/ui/button';
import DataItem from 'components/ui/dataItem';
import useDefaultModalProps from 'hooks/useDefaultModalProps';
import useDomainName from 'hooks/useDomainName';
import useSchemaName from 'hooks/useSchemaName';
import {
  api_checkFtpConnection,
  CheckFtPConnectionParams,
  FTPConnection
} from 'services/api/domain/ftpConnection';

type CheckFtpConnectionDialogProps = {
  isOpen: boolean;
  data: FTPConnection;
  close: () => void;
};

const CheckFtpConnectionDialog: React.FC<CheckFtpConnectionDialogProps> = ({
  data,
  isOpen,
  close
}) => {
  const schemaName = useSchemaName();
  const domainName = useDomainName();
  const modalProps = useDefaultModalProps();

  const checkConnectionMutation = useMutation<void, AxiosError, CheckFtPConnectionParams>(params =>
    api_checkFtpConnection(params)
  );

  const handleCheckConnection = () => {
    checkConnectionMutation.mutate({
      domainName,
      schemaName,
      payload: data
    });
  };

  const handleClose = () => {
    close();
    setTimeout(() => {
      checkConnectionMutation.reset();
    }, 500);
  };

  return (
    <Modal
      {...modalProps}
      visible={isOpen}
      title={
        <>
          <FontAwesomeIcon icon={['fas', 'exchange']} />
          <Trans>Test FTP connection</Trans>
        </>
      }
      footer={
        <>
          <Button action="close" onClick={handleClose} />
          <Button
            icon={['fas', 'exchange']}
            type="primary"
            onClick={() => handleCheckConnection()}
            disabled={checkConnectionMutation.isLoading}
          >
            <Trans>Test FTP connection</Trans>
          </Button>
        </>
      }
    >
      <p>
        <Trans>
          Check FTP connection <b>{data.name}</b>.
        </Trans>
      </p>
      <Divider />
      <ul>
        <li>
          <DataItem
            name={<Trans>Remote host</Trans>}
            value={data.remoteHost}
            hasCopy
            hasCopyTitle
            breakWord
          />
        </li>
        <li>
          <DataItem name={<Trans>Remote directory</Trans>} value={data.remoteDir} hasCopy />
        </li>
        <li>
          <DataItem
            name={<Trans>SFTP</Trans>}
            value={<BooleanIcon value={data.sftp} />}
            hasCopy
            hasCopyTitle
          />
        </li>
        <li>
          <DataItem name={<Trans>User name</Trans>} value={data.username} hasCopy />
        </li>
        <li>
          <DataItem name={<Trans>Password</Trans>} value={data.password} hasCopy />
        </li>
      </ul>
      <D.ResponseMessage>
        <TestFtpConnectionResponse query={checkConnectionMutation} />
      </D.ResponseMessage>
    </Modal>
  );
};

export default CheckFtpConnectionDialog;
