import { ListQueryParams } from '@phoenix-systems/react-table/dist/types/types';

export enum HistoryTableActionTypes {
  SET_PARAMS = 'historyTable/SET_PARAMS',
  SET_SINGLE_VIEW = 'historyTable/SET_SINGLE_VIEW',
  SET_SELECTED_KEY = 'historyTable/SET_SELECTED_KEY',
  RESET = 'historyTable/RESET'
}

export type HistoryTableSingleView = 'OVERVIEW' | 'JSON_DIFF';

export type HistoryTableState = Readonly<{
  selectedKey?: string;
  params: ListQueryParams;
  singleView: HistoryTableSingleView;
}>;
