import tw, { css, styled } from 'twin.macro';

type ContainerProps = {
  isWithoutPage?: boolean;
};

export const Container = styled.div<ContainerProps>(({ isWithoutPage }) => [
  css`
    ${tw`mb-8`}

    & .ant-breadcrumb {
      ${tw`font-600 mb-10 text-13`}

      & a {
        ${tw`text-gray-500`}
      }

      & > span:last-child a {
        ${tw`text-primary`}
      }
    }

    & .anticon.anticon-down svg {
      ${tw`text-10`}
      transform: translateY(-1px);
    }

    & .ant-breadcrumb-separator {
      ${tw`mx-6 text-gray-400 text-12`}
    }
  `,

  isWithoutPage &&
    css`
      ${tw`mx-40 mt-16`}
    `
]);
