import { LayoutConfig } from '@phoenix-systems/react-layout/dist/types/components/layoutProvider/store/layout.types';

import localStorageConfig from 'config/localStorage';

export const layoutConfig: LayoutConfig = {
  sidebarLeft: {
    width: 280,
    collapsedWidth: 60,
    storeCollapsed: {
      key: localStorageConfig.keys.sidebarCollapsed
    }
  },
  sidebarRight: {
    disabled: true,
    width: 0,
    collapsedWidth: 0
  },
  main: {},
  header: {
    height: 60
  },
  footer: {
    height: 0,
    disabled: true
  },
  mobileNavigation: {
    isCollapsed: true,
    fullHeight: true
  }
};

export default layoutConfig;
