import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Menu } from 'antd';
import { omit } from 'lodash';

import Button from 'components/ui/button';
import routes from 'config/routes/routes';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';
import { ActionMode } from 'index.types';
import { DbImport } from 'services/api/domain/dataImport';

const { Item } = Menu;

type EditDbImportActionProps = {
  data: DbImport;
  mode: ActionMode;
};

const EditDbImportAction: React.FC<EditDbImportActionProps> = props => {
  const antProps = omit(props, ['data', 'mode']);
  const { data, mode } = props;
  const goto = useGoToDomainRoute();

  const handleEditRequest = () => {
    goto(routes.domain.dataManagement.importFromDb.single, {
      path: data.name
    });
  };

  return (
    <>
      {mode === 'LIST_ITEM' && (
        <Item
          {...antProps}
          key={`edit-db-import_${data.id}`}
          icon={<FontAwesomeIcon icon={['fas', 'pen']} />}
          onClick={handleEditRequest}
        >
          <Trans>Edit DB Import</Trans>
        </Item>
      )}
      {mode === 'BUTTON' && (
        <Button action="edit" onClick={handleEditRequest}>
          <Trans>Edit DB import</Trans>
        </Button>
      )}
    </>
  );
};

export default EditDbImportAction;
