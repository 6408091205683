import { Menu } from 'antd';
import tw, { styled, css } from 'twin.macro';

type StyledContainerProps = {
  isVisible: boolean;
};

export const StyledContainer = styled.div<StyledContainerProps>(({ isVisible }) => [
  css`
    ${tw`font-sans`};
    //  z-index: 1000;

    & .ant-popover {
      ${tw`pl-0 `};

      & .ant-popover-arrow {
      }
    }

    & .ant-popver {
      margin-left: -2px;
    }

    & .ant-menu-sub {
      margin-left: -3px;
    }

    & .ant-popover.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow {
      left: -4px;
    }

    & .ant-popover .ant-popover-inner-content {
      ${tw`p-0`}
    }

    & .ant-popover .ant-popover-title {
      ${tw`font-600 text-primary py-8 text-13 border-b border-primary bg-light-gray-active`}
    }

    &
      .ant-menu-submenu-popup
      .ant-menu-vertical.ant-menu-sub
      > .ant-menu-submenu
      > .ant-menu-submenu-title {
      ${tw`h-36 my-0 text-13 font-600`}
      line-height: 36px;
    }

    &
      .ant-menu-submenu-popup
      .ant-menu-vertical.ant-menu-sub
      > .ant-menu-submenu
      > .ant-menu-submenu-title:hover {
      ${tw`hover:bg-light-gray`}
    }

    & .ant-menu-submenu-popup .ant-menu-vertical.ant-menu-sub .ant-menu-item {
      ${tw`h-36 my-0 text-13 font-600`}
      line-height: 36px;
    }

    & .ant-menu-submenu-popup .ant-menu-vertical.ant-menu-sub .ant-menu-item:hover {
      ${tw`hover:bg-light-gray`}
    }

    // button popover
    & .ant-menu-vertical {
      min-width: 200px;
      width: auto !important;
    }

    & .ant-menu-vertical > .ant-menu-item {
      ${tw`font-600 hover:bg-light-gray my-0`}
    }

    & .ant-menu-item-group-title {
      ${tw`font-600 text-10 uppercase mb-0 pb-2`}
    }

    & .ant-menu-item-group {
      ${tw`m-0`}
    }

    & .ant-divider.ant-divider-horizontal {
      ${tw`my-0`}
    }

    & .ant-menu-item-group-list .ant-menu-item {
      ${tw`pl-16`}
    }

    & .delete:hover {
      ${tw`text-red-delete`}
    }
  `,

  !isVisible &&
    css`
      ${tw`hidden`}
    `
]);

export const StyledMenu = styled(Menu)(() => [
  css`
    ${tw` font-600 text-13`}
    border-right: none;
    min-width: 25rem;

    &.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu {
      ${tw` font-600 text-13`}
    }

    &.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
    &.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:hover {
      ${tw`bg-light-gray-active`}
    }

    &.ant-menu-vertical .ant-menu-submenu > .ant-menu {
      ${tw``}
    }

    &.ant-menu-vertical .ant-menu-item:not(:last-child),
    &.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
      margin: 0;
      &:hover {
        ${tw`bg-light-gray`}
      }
    }

    &.ant-menu-vertical .ant-menu-item {
      ${tw`m-0 hover:bg-light-gray`}
      height: 36px;
      line-height: 36px;
    }

    & .direction-icon {
      ${tw`text-10 inline-block px-4`}
    }

    & a {
      color: inherit;
    }
  `
]);
