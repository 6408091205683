import { Button } from 'antd';
import tw, { css, styled } from 'twin.macro';

import { ButtonActionType } from './button.types';

type StyledButtonProps = {
  $action?: ButtonActionType;
};

export const StyledButton = styled(Button)<StyledButtonProps>(({ $action }) => [
  css`
    & .open-icon {
      ${tw`ml-8 mr-0`};
      margin-right: 0 !important;
    }
  `,

  $action === 'delete' &&
    css`
      &.ant-btn:not(.disabled) {
        ${tw`text-white bg-red-delete-300 border-red-delete-500`};

        &:hover,
        :active,
        :focus {
          ${tw`text-white bg-red-delete-500 border-red-delete-700 shadow`};
        }
      }
    `,

  $action === 'create' &&
    css`
      &.ant-btn:not(.disabled) {
        ${tw`text-white bg-blue-create-300 border-blue-create-400`};

        &:hover,
        :active,
        :focus {
          ${tw`text-white bg-blue-create-500 border-blue-create-800 shadow`};
        }
      }
    `,

  ($action === 'save' || $action === 'insert') &&
    css`
      &.ant-btn:not(.disabled) {
        ${tw`text-white bg-green-save-300 border-green-save-400`};

        &:hover,
        :active,
        :focus {
          ${tw`bg-green-save-500 border-green-save-800 shadow`};
        }
      }
    `
]);
