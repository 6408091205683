import { produce } from 'immer';
import { clone, merge } from 'lodash';

import { CsvImportsTableActionCreators } from './csvImportsTable.actions';
import {
  CsvImportsTableActionTypes,
  CsvImportsTableState,
  DB_IMPORT_LIST_DEFAULT_PAGE_SIZE
} from './csvImportsTable.types';

const initialState: CsvImportsTableState = Object.freeze<CsvImportsTableState>({
  params: {
    pageSize: DB_IMPORT_LIST_DEFAULT_PAGE_SIZE,
    currentPage: 1,
    sort: undefined,
    filter: undefined,
    searchText: undefined
  },
  singleView: 'OVERVIEW',
  activeKey: undefined
});

const csvImportsTableReducer = (
  state: CsvImportsTableState = initialState,
  action: CsvImportsTableActionCreators
): CsvImportsTableState =>
  produce(state, draft => {
    switch (action.type) {
      case CsvImportsTableActionTypes.SET_PARAMS:
        draft.params = merge(clone(draft.params), action.params);

        break;

      case CsvImportsTableActionTypes.SET_SINGLE_VIEW:
        draft.singleView = action.view;

        break;

      case CsvImportsTableActionTypes.RESET:
        draft.singleView = 'OVERVIEW';
        draft.params = {};

        break;

      case CsvImportsTableActionTypes.SET_ACTIVE_KEY:
        draft.activeKey = action.key;

        break;

      default:
        break;
    }
  });

export default csvImportsTableReducer;
