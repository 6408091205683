import { produce } from 'immer';
import { clone, merge } from 'lodash';

import { OrgUnitsTableActionCreators } from './orgUnitsTable.actions';
import { OrgUnitsTableActionTypes, OrgUnitsTableState } from './orgUnitsUnitsTable.types';

const initialState: OrgUnitsTableState = Object.freeze<OrgUnitsTableState>({
  params: {
    pageSize: 20,
    currentPage: 1
  },
  singleView: 'OVERVIEW',
  selectedKey: undefined
});

const orgUnitsTableReducer = (
  state: OrgUnitsTableState = initialState,
  action: OrgUnitsTableActionCreators
): OrgUnitsTableState =>
  produce(state, draft => {
    switch (action.type) {
      case OrgUnitsTableActionTypes.SET_PARAMS:
        draft.params = merge(clone(draft.params), action.params);

        break;

      case OrgUnitsTableActionTypes.SET_SINGLE_VIEW:
        draft.singleView = action.view;

        break;

      case OrgUnitsTableActionTypes.RESET:
        draft.singleView = 'OVERVIEW';
        draft.params = {};

        break;

      case OrgUnitsTableActionTypes.SET_SELECTED_KEY:
        draft.selectedKey = action.key;

        break;

      default:
        break;
    }
  });

export default orgUnitsTableReducer;
