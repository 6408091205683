import React, { useCallback, useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Menu } from 'antd';
import { omit } from 'lodash';

import CheckDbConnectionDialog from './checkDbConnectionDialog';

import { DbImport } from 'services/api/domain/dataImport';

const { Item } = Menu;

type DeleteTriggerActionProps = {
  data: DbImport;
};

const DeleteDbImportAction: React.FC<DeleteTriggerActionProps> = props => {
  const antProps = omit(props, ['data']);
  const { data } = props;
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isValidParams, setValidParams] = useState(false);

  const handleCheckRequest = () => {
    setDialogOpen(true);
  };

  const checkValidDbParams = useCallback(() => {
    if (!data) {
      return false;
    }

    if (
      data.dburl &&
      data.dburl !== '' &&
      data.driverclass &&
      data.driverclass !== '' &&
      data.dbuser &&
      data.dbuser !== '' &&
      data.dbpassword &&
      data.dbpassword !== ''
    ) {
      return true;
    }

    return false;
  }, [data]);

  useEffect(() => {
    const isValid = checkValidDbParams();
    setValidParams(isValid);
  }, [data, checkValidDbParams]);

  return (
    <>
      <Item
        {...antProps}
        disabled={!isValidParams}
        key={`test-db-connection_${data.id}`}
        icon={<FontAwesomeIcon icon={['fas', 'database']} />}
        onClick={handleCheckRequest}
      >
        <Trans>Test DB connection</Trans>
      </Item>

      <CheckDbConnectionDialog
        isOpen={isDialogOpen}
        data={data}
        close={() => setDialogOpen(false)}
      />
    </>
  );
};

export default DeleteDbImportAction;
