import { AppActionTypes } from './app.types';

import { Language } from 'config/langauge/languages.types';

// Action Definition

type SetSidebarInitialisedAction = {
  type: AppActionTypes.SET_SIDEBAR_INITIALISED;
  initialised: boolean;
};

type SetLanguageAction = {
  type: AppActionTypes.SET_LANGUAGE;
  language: Language;
};

type ResetAction = {
  type: AppActionTypes.RESET;
};

// Union Action Types
export type AppActionCreators = SetSidebarInitialisedAction | SetLanguageAction | ResetAction;

// Action Creators

export const st_app_setSidebarInitialised = (
  initialised: boolean
): SetSidebarInitialisedAction => ({
  type: AppActionTypes.SET_SIDEBAR_INITIALISED,
  initialised
});

export const st_app_setLanguage = (language: Language): SetLanguageAction => ({
  type: AppActionTypes.SET_LANGUAGE,
  language
});

export const st_app_reset = (): ResetAction => ({
  type: AppActionTypes.RESET
});
