import tw, { css, styled } from 'twin.macro';

export const DialogFildListItem = styled.div(() => [
  css`
    ${tw`flex flex-row pr-20`};

    & div {
      ${tw`ml-auto pl-6 `};
    }

    & span {
      ${tw`text-gray-500 font-mono text-12`};
      line-height: 24px;
    }

    & svg {
      ${tw`text-gray-600 ml-8`};
    }
  `
]);
