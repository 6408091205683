import tw, { css, styled } from 'twin.macro';

export const EdgeInfo = styled.div(() => [
  css`
    p {
      ${tw`text-12 m-0 p-0`};

      span {
        ${tw`text-primary inline-block`};
        min-width: 100px;
      }
    }
  `
]);
