import tw, { css, styled } from 'twin.macro';

const DEFAULT_BOX_CONTAINER_WIDTH = 1000;

type WrapperProps = {
  maxWidth?: number | string;
  isFullHeight?: boolean;
};
export const Wrapper = styled.div<WrapperProps>(({ maxWidth, isFullHeight }) => [
  css`
    max-width: ${DEFAULT_BOX_CONTAINER_WIDTH}px;

    & > div.spacer {
      height: 5.7rem;
      content: ' ';
    }
  `,

  maxWidth !== undefined &&
    css`
      max-width: ${typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth};
    `,

  isFullHeight &&
    css`
      ${tw`h-full w-full mb-12 flex flex-col`}

      & .form-container-content {
        height: calc(100% - 17.5rem);
        ${tw` relative `}

        & form {
          ${tw`h-full`}
        }
      }
    `
]);

export const Title = styled.h4(() => [
  css`
    ${tw`pt-8 text-primary font-600 top-0 text-16 border-b border-gray-400 pb-6 mb-32 flex items-center`};

    & svg {
      ${tw`mr-6 text-16`};
      transform: translateY(-1px);
    }

    & .info-btn {
      transform: translateY(-0.8rem);
      & svg {
        ${tw`text-12`};
      }
    }
  `
]);

export const Footer = styled.div(() => [
  css`
    ${tw`border-t border-gray-400 bg-white py-12`};

    & > div.messages {
      & .ant-alert,
      .loader-message {
        ${tw`mb-14`};
      }
    }

    & > div.buttons {
      ${tw`flex flex-row items-center `};

      & div.separator {
        ${tw`w-1 h-30  border-ant-border-gray ml-12 `};
      }

      & .ant-btn {
        ${tw`ml-12`};
        min-width: 12rem;
      }

      & .ant-btn:first-of-type {
        ${tw`ml-auto`};
      }
    }
  `
]);

type StickyContainerProps = { visible: boolean; width: number; left: number; height: number };

export const StickyContainer = styled.div<StickyContainerProps>(
  ({ visible, width, left, height }) => [
    css`
      ${tw` fixed overflow-hidden bottom-0 right-mobileX md:right-desktopX`};
      height: ${height}px;
      bottom: 0px;
      width: ${width}px;
      left: ${left}px;

      & > div {
        opacity: 0;
        transform: translateY(0);
        animation-iteration-count: 1;
        ${tw`transition-all`};

        & > div.fader {
          ${tw`h-32`};
          content: ' ';

          background: rgb(255, 255, 255);
          background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
        }
      }

      @keyframes slideOut {
        0% {
          transform: translateY(0);
        }
        99% {
          opacity: 1;
        }
        100% {
          opacity: 0;
          transform: translateY(${height}px);
        }
      }

      @keyframes slideIn {
        0% {
          transform: translateY(${height}px);
          opacity: 0;
        }
        1% {
          opacity: 1;
        }
        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }
    `,

    visible === false &&
      css`
        & > div {
          animation: slideOut 100ms ease-in-out;
        }
      `,

    visible === true &&
      css`
        & > div {
          opacity: 1;
          animation: slideIn 500ms ease-in-out;
        }
      `
  ]
);

export const Content = styled.h4(() => [
  css`
    ${tw`mb-32 relative`};
  `
]);
