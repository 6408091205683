/* eslint-disable  @typescript-eslint/naming-convention */

import { ParsedKeycloakClientConfig } from '@phoenix-systems/react-keycloak-auth/dist/types/components/auth/auth.types';

const getKeycloakClientConfigFromEnv = (): ParsedKeycloakClientConfig => {
  if (!window._env_.KEYCLOAK_REALM) {
    throw new Error('" window._env_.KEYCLOAK_REALM" param is missing ');
  }

  if (!window._env_.KEYCLOAK_AUTH_SERVER_URL) {
    throw new Error('" window._env_.KEYCLOAK_AUTH_SERVER_URL" param is missing ');
  }

  if (!window._env_.KEYCLOAK_CLIENT_ID) {
    throw new Error('" window._env_.KEYCLOAK_CLIENT_ID" param is missing ');
  }

  const config: ParsedKeycloakClientConfig = {
    'realm': window._env_.KEYCLOAK_REALM,
    'auth-server-url': window._env_.KEYCLOAK_AUTH_SERVER_URL,
    'resource': window._env_.KEYCLOAK_CLIENT_ID
  };

  return config;
};

export default getKeycloakClientConfigFromEnv;
