import history from 'services/history';

const clearSlashes = (path: string): string => {
  if (path) {
    return path.toString().replace(/\/$/, '').replace(/^\//, '');
  }

  return '';
};

const getUrlFragment = (index?: number): string => {
  let fragment = '';
  fragment = clearSlashes(decodeURI(history.location.pathname + history.location.search));
  fragment = fragment.replace(/\?(.*)$/, '');
  const fr = clearSlashes(fragment);

  if (index === undefined) {
    return fr;
  }

  return fr.split('/')[index];
};

export default getUrlFragment;
