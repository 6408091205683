import { DiagramElement, EntityEdge, EntityNode } from 'services/api/domain/domainDiagram';

export enum DomainDiagramActionTypes {
  SET_ELEMENTS = 'domainDiagram/SET_ELEMENTS',
  UPDATE_ELEMENTS = 'domainDiagram/UPDATE_ELEMENTS',
  ADD_ELEMENT = 'domainDiagram/ADD_ELEMENT',
  UPDATE_ELEMENT = 'domainDiagram/UPDATE_ELEMENT',
  REMOVE_ELEMENT = 'domainDiagram/REMOVE_ELEMENT',
  UPDATE_NODE_DATA = 'domainDiagram/UPDATE_NODE_DATA',
  UPDATE_EDGE_DATA = 'domainDiagram/UPDATE_EDGE_DATA',
  CLEANUP_ELEMENTS = 'domainDiagram/CLEANUP_ELEMENTS',
  SET_CONTEXTNAV_OPEN = 'domainDiagram/SET_CONTEXTNAV_OPEN',
  SET_DIALOG_OPEN = 'domainDiagram/SET_DIALOG_OPEN',
  REMOVE_EDGE = 'domainDiagram/REMOVE_EDGE',
  UPDATE_EDGE = 'domainDiagram/UPDATE_EDGE',
  RESET = 'domainDiagram/RESET',
  SET_ACTIVE_EDGE = 'domainDiagram/SET_ACTIVE_EDGE',
  SET_ACTIVE_NODE = 'domainDiagram/SET_ACTIVE_NODE',
  SET_ACTIVE_HANDLES = 'domainDiagram/SET_ACTIVE_HANDLES',
  SET_CONTEXT_EDGE = 'domainDiagram/SET_CONTEXT_EDGE',
  SET_HAS_CHANGED = 'domainDiagram/SET_HAS_CHANGED'
}

export type EntityNodeNameSpace = {
  className?: string;
  simpleClassName?: string;
  id: string;
};

export type EntityNodeFieldType = {
  name: string;
  className?: string;
  multipleName: boolean;
  isRelational: boolean;
};

export type DomainDiagramState = Readonly<{
  elements: DiagramElement[];
  nodes: EntityNode[];
  edges: EntityEdge[];
  hasChanged: boolean;
  contextNavIsOpen: boolean;
  dialogIsOpen: boolean;
  fieldTypes: EntityNodeFieldType[];
  activeEdge?: EntityEdge;
  contextEdge?: EntityEdge;
  contextAnchor?: { x: number; y: number };
  activeHandles: string[];
  activeNode?: string;
}>;
