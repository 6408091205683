import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { NavigationConfig } from '@phoenix-systems/react-navigation';
import { getRoute } from '@phoenix-systems/react-router';
import { concat } from 'lodash';

import routes from 'config/routes/routes';

export const getTopNavigationConfig = (
  domainName?: string,
  isSuperadmin?: boolean
): NavigationConfig => {
  let children: NavigationConfig[] = [
    {
      id: 'dashboard',
      title: i18n._('Dashboard'),
      icon: ['fas', 'tachometer-alt'],
      route: routes.dashboard.root,
      activeOnRouteMatch: [routes.dashboard.root]
    },
    {
      className: !domainName ? 'domain disabled' : 'domain',
      id: 'domain',
      title: i18n._(t`Domain ${domainName}`),
      icon: ['far', 'globe'],
      route: domainName ? getRoute(routes.domain.overview, domainName) : '/',
      activeOnRouteMatch: [routes.domain.root]
    }
  ];

  if (isSuperadmin) {
    children.push({
      id: 'user-admin',
      title: i18n._('User and Privileges'),
      icon: ['fas', 'user-friends'],
      route: routes.userAdmin.user.list,
      activeOnRouteMatch: [routes.userAdmin.root]
    });
  }

  children = concat(children, [
    {
      id: 'scheduler',
      title: i18n._('Scheduler'),
      icon: ['fas', 'clock'],
      route: routes.scheduler.list,
      activeOnRouteMatch: [
        routes.scheduler.listByDomain,
        routes.scheduler.single,
        routes.scheduler.calendar,
        routes.scheduler.calendarByDomain,
        routes.scheduler.create
      ]
    },
    /* {
      id: 'edit',
      title: i18n._('Edit'),
      icon: ['fas', 'pen'],
      route: routes.edit.root,
      isDisabled: !isMinDsVersion('2'),
      activeOnRouteMatch: [routes.edit.root]
    }, */
    /* {
      id: 'view',
      title: i18n._('View'),
      icon: ['far', 'eye'],
      route: routes.view.root,
      isDisabled: !isMinDsVersion('2'),
      activeOnRouteMatch: [routes.view.root]
    },
    {
      id: 'tools',
      title: i18n._('Tools'),
      icon: ['fas', 'wrench'],
      route: routes.tools.root,
      isDisabled: !isMinDsVersion('2'),
      activeOnRouteMatch: [routes.tools.root]
    }, */
    {
      id: 'docs',
      title: i18n._('Docs'),
      icon: ['far', 'question-circle'],
      route: routes.docs.root,
      activeOnRouteMatch: [routes.docs.root]
    }
  ]);

  return {
    id: 'top-navigation',
    route: '/',
    children
  };
};
