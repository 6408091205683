import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import * as T from 'components/_styled/tableSc';
import { FTPConnection } from 'services/api/domain/ftpConnection';
import { st_ftpConnectionsTable_setSingleView } from 'services/store/ftpConnectionsTable/ftpConnectionsTable.actions';
import { st_ftpConnectionsTable_getSingleView } from 'services/store/ftpConnectionsTable/triggersTable.selectors';
import { TriggersTableSingleView } from 'services/store/triggersTable/triggersTable.types';

const { TabPane } = Tabs;

type FtpConnectionsTableNavProps = {
  data: FTPConnection;
};

const FtpConnectionsTableNav: React.FC<FtpConnectionsTableNavProps> = () => {
  const view = useSelector(st_ftpConnectionsTable_getSingleView);
  const dispatch = useDispatch();
  const handleTabChange = (value: string) => {
    dispatch(st_ftpConnectionsTable_setSingleView(value as TriggersTableSingleView));
  };

  return (
    <T.SingleNav>
      <T.TabNavigation defaultActiveKey="1" onChange={handleTabChange} activeKey={view}>
        <TabPane
          tab={
            <>
              <FontAwesomeIcon icon={['fas', 'info-circle']} />
              <Trans>Overview</Trans>
            </>
          }
          key="OVERVIEW"
        />
        <TabPane
          tab={
            <>
              <FontAwesomeIcon icon={['fas', 'thumbtack']} />
              <Trans>Connected tasks</Trans>
            </>
          }
          key="CONNECTED_TASKS"
        />
      </T.TabNavigation>
    </T.SingleNav>
  );
};

export default FtpConnectionsTableNav;
