import tw, { css, styled } from 'twin.macro';

type EditableImageProps = {
  isActive: boolean;
};

export const EditableImage = styled.div<EditableImageProps>(({ isActive }) => [
  css`
    ${tw`relative`};

    & .overlay {
      ${tw`w-full h-full absolute top-0 left-0 hidden`};

      & > div {
        ${tw`absolute top-4 left-4`};

        & .ant-btn {
          ${tw`mr-4`};
        }
      }

      &.active {
        ${tw`block`};
      }
    }
  `,

  isActive &&
    css`
      ${tw` shadow`};

      & .overlay {
        background: rgba(0, 0, 0, 0.01);
      }
    `
]);
