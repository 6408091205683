import React from 'react';

import { Redirect } from 'react-router';

import routes from '../routes/routes';

import { ViewConfig } from './view.types';

import OrgUnitCreatePage from 'components/userAdmin/pages/orgUnitCreatePage';
import OrgUnitListPage from 'components/userAdmin/pages/orgUnitListPage';
import OrgUnitSinglePage from 'components/userAdmin/pages/orgUnitSinglePage';
import UserListPage from 'components/userAdmin/pages/userListPage';
import { default as UserSinglePage } from 'components/userAdmin/pages/userSinglePage';

const userAdminViewConfig: ViewConfig = {
  routes: [
    {
      path: routes.userAdmin.user.list,
      exact: true,
      component: <UserListPage />
    },
    {
      path: routes.userAdmin.user.single,
      exact: true,
      component: <UserSinglePage />
    },
    {
      path: routes.userAdmin.orgunit.list,
      exact: true,
      component: <OrgUnitListPage />
    },
    {
      path: routes.userAdmin.orgunit.single,
      exact: true,
      component: <OrgUnitSinglePage />
    },
    {
      path: routes.userAdmin.orgunit.create,
      exact: true,
      component: <OrgUnitCreatePage />
    },
    {
      path: routes.userAdmin.root,
      component: <Redirect to={routes.userAdmin.user.list} />
    }
  ]
};

export default userAdminViewConfig;
