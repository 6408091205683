import React from 'react';

import { Trans } from '@lingui/macro';

import AppLayout from 'components/app/components/appLayout';
import DomainPage from 'components/domain/components/domainPage';
import FtpConnectionForm from 'components/domain/components/ftpConnectionForm/ftpConnectionForm';
import Button from 'components/ui/button';
import routes from 'config/routes/routes';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';

const FtpConnectionCreatePage: React.FC = () => {
  const goto = useGoToDomainRoute();

  return (
    <AppLayout>
      <DomainPage
        route={routes.domain.connections.ftp.list}
        noScroll
        title={<Trans>Create FTP Connection</Trans>}
        content={<FtpConnectionForm mode="STANDALONE" />}
        headerButtons={
          <Button
            action="list"
            type="ghost"
            onClick={() => goto(routes.domain.connections.ftp.list)}
          >
            <Trans>Back to list</Trans>
          </Button>
        }
      />
    </AppLayout>
  );
};
export default FtpConnectionCreatePage;
