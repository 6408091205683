export type LanguagesConfig = {
  availableLanguages: LanguageConfig[];
  defaultLanguage: Language;
};

export enum Language {
  DE = 'de',
  EN = 'en',
  FR = 'fr',
  IT = 'it'
}

export type LanguageConfig = {
  id: Language;
  label: string;
};
