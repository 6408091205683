import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { NavigationConfig } from '@phoenix-systems/react-navigation';
import { getRoute } from '@phoenix-systems/react-router';
import memoize from 'fast-memoize';
import { concat } from 'lodash';

import { getDocsNavigation } from 'config/docs/docs.utils';
import { Language } from 'config/langauge/languages.types';
import routes from 'config/routes/routes';
import { Domain } from 'services/api/domain/domain';
import { User } from 'services/api/domain/user';
import { isMinDsVersion } from 'utils';

export type MainNavigationConfigParams = {
  basePath: string;
  users?: User[] | 'LOADING';
  domains?: Domain[] | 'LOADING';
  domainName?: string;
  schemaName?: string;
  domain?: Domain;
  language: Language;
  isSuperadmin?: boolean;
};

const getMainNavigationConfigFunc = (params: MainNavigationConfigParams): NavigationConfig => {
  let children: NavigationConfig[] = [];

  switch (params.basePath) {
    /**
     * DASHBOARD
     */
    case routes.dashboard.root: {
      const domains: NavigationConfig[] = [];

      if (params?.domains === 'LOADING' || params?.domains === undefined) {
        domains.push({
          id: 'domain_loader',
          type: 'loader'
        });
      } else {
        params.domains.forEach(domain => {
          domains.push({
            id: `domain_${domain.id}`,
            title: domain.domainName,
            icon: ['fas', 'globe'],
            route: getRoute(routes.domain.overview, domain.domainName)
          });
        });
      }
      children.push({
        id: 'dashboard-domains',
        title: i18n._(t`Domains`),
        icon: ['fas', 'globe'],
        route: routes.dashboard.domains
      });
      children.push({
        id: 'dashboard-add-domain',
        title: i18n._(t`Create Domain`),
        icon: ['fas', 'plus-circle'],
        route: routes.domain.create
      });
      children.push({
        id: 'statistics',
        title: i18n._(t`Statistics`),
        icon: ['fas', 'chart-pie'],
        route: routes.dashboard.statistics,
        isDisabled: !isMinDsVersion('2')
      });

      const users: NavigationConfig[] = [];

      /* if (params?.users === 'LOADING' || params?.users === undefined) {
        users.push({
          id: 'user_loader',
          route: 'user_loader',
          type: 'loader'
        });
      } else {
        params.users.forEach(user => {
          users.push({
            id: `user_${user.id}`,
            title: user.fullName,
            icon: ['fas', 'user'],
            route: getRoute(routes.user.singleRoot, user.id)
          });
        });
      } */

      if (params.isSuperadmin) {
        children.push({
          id: 'user-admin',
          title: i18n._(t`User and Privileges`),
          icon: ['fas', 'user-friends'],
          route: routes.userAdmin.root,
          noNav: true,
          children: users
        });
      }

      break;
    }

    /**
     * DOMAIN
     */
    case routes.domain.root: {
      if (!params.domainName || !params.schemaName) {
        break;
      }

      const queryDomainChildren: NavigationConfig[] = [
        {
          id: 'query-data-entity-document',
          title: i18n._(t`Entity/document`),
          icon: ['fas', 'search'],
          route: getRoute(
            routes.domain.queryData.entityDocument.root,
            params.domainName,
            params.schemaName
          ),
          activeOnRouteMatch: [
            routes.domain.queryData.entityDocument.rootEntity,
            routes.domain.queryData.entityDocument.list,
            routes.domain.queryData.entityDocument.single
          ]
        },
        {
          id: 'query-data-document-history',
          title: i18n._(t`Document history`),
          icon: ['fas', 'history'],
          route: getRoute(
            routes.domain.queryData.documentHistory,
            params.domainName,
            params.schemaName
          )
        },
        {
          id: 'query-data-metadata',
          title: i18n._(t`Metadata`),
          icon: ['fas', 'code'],
          route: getRoute(routes.domain.queryData.metaData, params.domainName, params.schemaName),
          activeOnRouteMatch: [
            routes.domain.queryData.metaDataCreate,
            routes.domain.queryData.metaDataEntity
          ]
        }
      ];

      if (params.domain?.uri) {
        queryDomainChildren.push({
          id: 'query-data-swagger',
          title: i18n._(t`Restful (Swagger)`),
          icon: ['fab', 'html5'],
          route: getRoute(routes.domain.queryData.swagger, params.domainName)
        });
      }

      children = [
        {
          id: 'domain-overview',
          title: i18n._(t`Domain overview`),
          icon: ['fas', 'home'],
          route: getRoute(routes.domain.overview, params.domainName)
        },
        {
          id: 'data-management',
          title: i18n._(t`Data management`),
          icon: ['fas', 'database'],
          route: getRoute(routes.domain.dataManagement.root, params.domainName),
          noNav: true,
          children: [
            {
              id: 'data-management-domain-administration',
              title: i18n._(t`Domain Administration`),
              icon: ['fas', 'tools'],
              route: getRoute(routes.domain.dataManagement.domainAdministration, params.domainName)
            },
            {
              id: 'data-management-json-import-export',
              title: i18n._(t`JSON Import/Export`),
              icon: ['fas', 'file-archive'],
              route: getRoute(
                routes.domain.dataManagement.jsonImportExport,
                params.domainName,
                params.schemaName
              )
            },
            {
              id: 'data-management-import-from-db',
              title: i18n._(t`Import from DB`),
              icon: ['fas', 'file-import'],
              route: getRoute(
                routes.domain.dataManagement.importFromDb.list,
                params.domainName,
                params.schemaName
              ),
              activeOnRouteMatch: [routes.domain.dataManagement.importFromDb.root]
            },
            {
              id: 'data-management-import-from-csv',
              title: i18n._(t`Import from CSV`),
              icon: ['fas', 'file-import'],
              route: getRoute(
                routes.domain.dataManagement.importFromCsv.list,
                params.domainName,
                params.schemaName
              ),
              activeOnRouteMatch: [routes.domain.dataManagement.importFromCsv.root]
            },
            {
              id: 'data-management-data-generator',
              title: i18n._(t`Test data generator`),
              icon: ['fas', 'sync'],
              route: getRoute(
                routes.domain.dataManagement.testDataGenerator,
                params.domainName,
                params.schemaName
              )
            }
          ]
        },
        {
          id: 'data-designer',
          title: i18n._(t`Data designer`),
          icon: ['fas', 'project-diagram'],
          route: getRoute(routes.domain.dataDesigner.root, params.domainName),
          noNav: true,
          children: [
            {
              id: 'data-designer-entity-document-diagram',
              title: i18n._(t`Entity/document diagram`),
              icon: ['fas', 'project-diagram'],
              route: getRoute(
                routes.domain.dataDesigner.entityDocumentDiagram,
                params.domainName,
                params.schemaName
              )
            },
            {
              id: 'data-designer-capture-structure',
              title: i18n._(t`Capture JSON structure`),
              icon: ['fas', 'camera-polaroid'],
              route: getRoute(
                routes.domain.dataDesigner.captureStructure,
                params.domainName,
                params.schemaName
              )
            }
          ]
        },
        {
          id: 'query-data',
          title: i18n._(t`Query data`),
          icon: ['fas', 'search'],
          route: getRoute(routes.domain.queryData.root, params.domainName),
          noNav: true,
          children: queryDomainChildren
        },
        {
          id: 'scheduler',
          title: i18n._(t`Scheduler`),
          icon: ['fas', 'clock'],
          route: getRoute(routes.domain.scheduler.root, params.domainName),
          noNav: true,
          children: [
            {
              id: 'scheduler-list',
              title: i18n._(t`Triggers`),
              icon: ['fas', 'clock'],
              route: getRoute(routes.domain.scheduler.list, params.domainName),
              activeOnRouteMatch: [routes.domain.scheduler.single, routes.domain.scheduler.create]
            },
            {
              id: 'scheduler-calendar',
              title: i18n._(t`Calendar`),
              icon: ['fas', 'calendar-alt'],
              route: getRoute(routes.domain.scheduler.calendar, params.domainName)
            }
          ]
        },
        {
          id: 'connections',
          title: i18n._(t`Connections`),
          icon: ['fas', 'sync'],
          route: getRoute(routes.domain.connections.root, params.domainName),
          noNav: true,
          children: [
            {
              id: 'fpt-connections',
              title: i18n._(t`FTP Connections`),
              icon: ['fas', 'exchange'],
              route: getRoute(
                routes.domain.connections.ftp.list,
                params.domainName,
                params.schemaName
              ),
              activeOnRouteMatch: [
                routes.domain.connections.ftp.create,
                routes.domain.connections.ftp.single
              ]
            }
          ]
        },
        {
          id: 'user-privileges',
          title: i18n._(t`User privileges`),
          icon: ['fas', 'user-friends'],
          route: getRoute(routes.domain.userPrivileges, params.domainName),
          isDisabled: !isMinDsVersion('2')
        },
        {
          id: 'statistics',
          title: i18n._(t`Statistics / performance`),
          icon: ['fas', 'chart-line'],
          route: getRoute(routes.domain.statistics, params.domainName),
          isDisabled: !isMinDsVersion('2')
        }
      ];

      break;
    }

    /**
     * DOCS
     */
    case routes.docs.root: {
      const docNav = getDocsNavigation(params.isSuperadmin);
      children = docNav.children || [];
      children = [
        {
          id: 'big-data-service',
          title: i18n._(t`Big data service`),
          icon: ['fas', 'globe'],
          route: routes.docs.root
        }
      ];

      const domainNav = docNav.children?.[0].children?.find(c => c.id === 'domain');

      if (domainNav?.children) {
        children.push({
          id: 'docs-separator-1',
          noNav: true,
          type: 'separator'
        });
        children = concat(children, domainNav.children);
      }

      const schedulerNav = docNav.children?.[0].children?.find(c => c.id === 'scheduler');

      if (schedulerNav?.children) {
        children.push({
          id: 'docs-separator-2',
          noNav: true,
          type: 'separator'
        });
        children = concat(children, schedulerNav.children);
      }

      const superadminNav = docNav.children?.[0].children?.find(c => c.id === 'useradmin');

      if (params.isSuperadmin && superadminNav?.children) {
        children.push({
          id: 'docs-separator-3',
          noNav: true,
          type: 'separator'
        });
        children = concat(children, superadminNav.children);
      }

      break;
    }

    /**
     * USER ADMIN
     */
    case routes.userAdmin.root: {
      children.push({
        id: 'users',
        title: i18n._(t`Users`),
        icon: ['fas', 'user-friends'],
        route: routes.userAdmin.user.list,
        activeOnRouteMatch: [routes.userAdmin.user.single]
        // children: users
      });

      children.push({
        id: 'orgunits',
        title: i18n._(t`Organisations`),
        icon: ['fas', 'building'],
        route: routes.userAdmin.orgunit.list,
        activeOnRouteMatch: [routes.userAdmin.orgunit.single, routes.userAdmin.orgunit.create]
        // children: users
      });

      break;
    }

    /**
     * SCHEDULER
     */
    case routes.scheduler.root: {
      children = [
        {
          id: 'scheduler-list',
          title: i18n._(t`Triggers list`),
          icon: ['fas', 'list'],
          route: routes.scheduler.list,
          activeOnRouteMatch: [routes.scheduler.listByDomain]
        },
        {
          id: 'schedular-calendar',
          title: i18n._(t`Triggers calendar`),
          icon: ['fas', 'calendar-alt'],
          route: routes.scheduler.calendar,
          activeOnRouteMatch: [routes.scheduler.calendarByDomain]
        },
        {
          id: 'sceduler-create',
          title: i18n._(t`Create trigger`),
          icon: ['fas', 'plus-circle'],
          route: routes.scheduler.create
        }
      ];

      break;
    }

    default:
      break;
  }

  return {
    id: 'main-navigation',
    route: '/',
    children
  };
};

const getMainNavigationConfig = memoize(getMainNavigationConfigFunc);

export default getMainNavigationConfig;
