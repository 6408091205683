import tw, { styled, css } from 'twin.macro';

type StyledComponentProps = {
  value?: boolean;
};

export const StyledIcon = styled.div<StyledComponentProps>(({ value }) => [
  value === false &&
    css`
      & svg {
        ${tw`text-red`};
      }
    `,

  value === true &&
    css`
      ${tw`text-green`};
    `
]);
