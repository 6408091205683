import React, { useEffect } from 'react';

import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import { CollectionEditorDialogProps, FormItem, useFormContext } from '@phoenix-systems/react-form';
import { Form, Input } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { clone } from 'lodash';

import EntityFieldSelector from '../entityFieldSelector';

import * as F from 'components/_styled/formSc';
import useContainer from 'components/app/components/containerProvider/useContainer';
import TooltipLabel from 'components/docs/components/docsTooltip/tooltipLabel';
import Button from 'components/ui/button';
import useDefaultModalProps from 'hooks/useDefaultModalProps';
import { DbImportTableMapper } from 'services/api/domain/dataImport';
import { DocId } from 'services/api/domain/hint';

type DbImportMapperDialogProps = CollectionEditorDialogProps<Partial<DbImportTableMapper>> & {
  selectedEntity?: string;
};

const DbImportMapperDialog: React.FC<DbImportMapperDialogProps> = ({
  data,
  isOpen,
  onClose,
  insertData,
  selectedEntity
}) => {
  const { dialogContainer } = useContainer();
  const [form] = Form.useForm<DbImportTableMapper>();
  const [formState, formActions] = useFormContext(form, data, data !== undefined);
  const modalProps = useDefaultModalProps();

  const handleClose = () => {
    onClose();

    const tm = setTimeout(() => {
      formActions.reset(data, data !== undefined);
    }, 500);

    return () => {
      clearTimeout(tm);
    };
  };

  const handleInsert = () => {
    form.validateFields().then(formData => {
      insertData(formData);
      handleClose();
    });
  };

  useEffect(() => {
    if (data) {
      formActions.reset(data, true);

      return;
    }

    formActions.reset();
  }, [data, formActions]);

  return (
    <Modal
      {...modalProps}
      title={
        !data ? <Trans>Add table entity mapper</Trans> : <Trans>Edit table entity mapper</Trans>
      }
      visible={isOpen}
      footer={
        <>
          <Button onClick={handleClose} action="cancel" />
          <Button action="save" onClick={handleInsert} formState={formState} />
        </>
      }
    >
      <F.StyledForm
        form={form}
        name="column-entity-mapper-form"
        onFieldsChange={formActions.onFieldsChange}
        onValuesChange={formActions.onValuesChange}
        $labelWidth={100}
        colon={false}
        initialValues={data}
      >
        <FormItem
          name="field"
          label={
            <TooltipLabel
              docId={DocId.DOMAIN_IMPORT_FROM_DB_TABLE_ENTITY_MAPPER_FIELD}
              label={<Trans>Field</Trans>}
            />
          }
          messageVariables={{ name: i18n._('Field') }}
          rules={[{ required: true }]}
          registerField={formActions.registerField}
        >
          <EntityFieldSelector
            entity={selectedEntity}
            getPopupContainer={() => dialogContainer}
            loadFields
            disableEntities
            hasPathInfo
            treeDefaultExpandAll
            onChangePrepareValue={nodeData => {
              if (nodeData) {
                const p = clone(nodeData.path);
                p.splice(0, 1);

                return p.join('.');
              }

              return undefined;
            }}
          />
        </FormItem>
        <FormItem
          label={
            <TooltipLabel
              docId={DocId.DOMAIN_IMPORT_FROM_DB_TABLE_ENTITY_MAPPER_COLUMN}
              label={<Trans>Column</Trans>}
            />
          }
          messageVariables={{ name: i18n._('Column') }}
          name="column"
          rules={[{ required: true }]}
          registerField={formActions.registerField}
        >
          <Input />
        </FormItem>
      </F.StyledForm>
    </Modal>
  );
};

export default DbImportMapperDialog;
