import React, { useCallback, useEffect } from 'react';

import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import { CollectionEditorDialogProps, FormItem, useFormContext } from '@phoenix-systems/react-form';
import { AutoComplete, Form, Input, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import Modal from 'antd/lib/modal/Modal';
import { RuleObject } from 'rc-field-form/lib/interface';
import Highlighter from 'react-highlight-words';
import { useSelector } from 'react-redux';
import { useImmer } from 'use-immer';
import { v4 as uuid } from 'uuid';

import { EntityFieldFormData, EntityFormData } from '../types';
import { isFieldType, isGenericType, isListType, isPrimitiveType } from '../utils';

import * as S from './entityFieldFormDialogSc';

import * as F from 'components/_styled/formSc';
import useContainer from 'components/app/components/containerProvider/useContainer';
import DocsTooltip from 'components/docs/components/docsTooltip';
import TooltipLabel from 'components/docs/components/docsTooltip/tooltipLabel';
import Button from 'components/ui/button';
import useDefaultModalProps from 'hooks/useDefaultModalProps';
import { AggregationType, RealationType } from 'services/api/domain/domainDiagram';
import { DocId } from 'services/api/domain/hint';
import {
  st_domainDiagram_getEdges,
  st_domainDiagram_getFieldTypes
} from 'services/store/domainDiagram/domainDiagram.selectors';
import { EntityNodeFieldType } from 'services/store/domainDiagram/domainDiagram.types';
import { log } from 'utils';

const DELIMITER = '___';
const { Option } = AutoComplete;

type FieldTypeOption = {
  value: string;
  fieldType: EntityNodeFieldType;
  label: JSX.Element;
  className?: string;
};

type EntityFieldFormDialogState = {
  fieldTypeOptions: FieldTypeOption[];
};

type EntityFieldFormDialogProps = CollectionEditorDialogProps<EntityFieldFormData> & {
  nodeData?: EntityFormData;
};

const EntityFieldFormDialog: React.FC<EntityFieldFormDialogProps> = ({
  data,
  isOpen,
  onClose,
  insertData,
  nodeData,
  mode
}) => {
  const fieldTypes = useSelector(st_domainDiagram_getFieldTypes);
  const edges = useSelector(st_domainDiagram_getEdges);
  const modalProps = useDefaultModalProps();

  const getEdgeOut = useCallback(() => {
    if (!data) {
      return;
    }

    // eslint-disable-next-line consistent-return
    return edges.find(e => e.data?.sourceField?.id === data?.id);
  }, [edges, data]);

  const getFieldTypeMatches = useCallback(
    (val?: string) => {
      const matches: FieldTypeOption[] = [];

      fieldTypes.forEach(type => {
        if (!val) {
          matches.push({
            value: type.className ? `${type.name}${DELIMITER}${type.className}` : type.name,
            fieldType: type,
            className: type.className,
            label: <Highlighter searchWords={['']} textToHighlight={type.name} autoEscape />
          });
        } else {
          const pattern = new RegExp(val, 'gi');

          if (type.name.search(pattern) !== -1) {
            matches.push({
              value: type.className ? `${type.name}${DELIMITER}${type.className}` : type.name,
              fieldType: type,
              className: type.className,
              label: <Highlighter searchWords={[val]} textToHighlight={type.name} autoEscape />
            });
          }
        }
      });

      return matches;
    },
    [fieldTypes]
  );

  const [state, setState] = useImmer<EntityFieldFormDialogState>({
    fieldTypeOptions: getFieldTypeMatches()
  });

  const getInitialData = useCallback(() => {
    if (!data) {
      return { id: uuid() };
    }

    const edgeOut = getEdgeOut();

    return {
      ...data,
      relationType:
        isGenericType(data.type) && isListType(data.type)
          ? RealationType.ONE_TO_MANY_RELATED
          : RealationType.ONE_TO_ONE_RELATED,
      aggregationType: edgeOut?.data?.aggregationType || AggregationType.AGGREGATION
    };
  }, [data, getEdgeOut]);

  const { dialogContainer } = useContainer();
  const [form] = Form.useForm<EntityFieldFormData>();
  const [formState, formActions] = useFormContext<EntityFieldFormData>(
    form,
    getInitialData(),
    data !== undefined
  );
  const fieldType = formState.data?.type ? formState.data?.type.split(DELIMITER)[0] : undefined;

  const handleClose = () => {
    onClose();
    formActions.reset();
  };

  const handleInsert = () => {
    form.validateFields().then(formData => {
      let { type } = formData;

      if (formData.type.search(DELIMITER)) {
        [type] = formData.type.split(DELIMITER);
      }

      if (fieldType) {
        insertData({
          ...formData,
          type
        });
      }
      handleClose();
      formActions.reset();
    });
  };

  const validateName = (rule: RuleObject, value: string) =>
    new Promise((resolve, reject) => {
      const fieldNames: string[] = [];
      nodeData?.fields.forEach(field => {
        if (field.id !== data?.id) {
          fieldNames.push(field.name);
        }
      });

      if (value !== data?.name && fieldNames.includes(value)) {
        reject(new Error('field name already in use'));

        return;
      }

      resolve(value);
    });

  const validateUniqueId = (rule: RuleObject, value: boolean) => {
    const formData = form.getFieldsValue();

    return new Promise((resolve, reject) => {
      if (
        formData.type &&
        formData.type !== '' &&
        !isPrimitiveType(formData.type) &&
        value === true
      ) {
        reject(new Error('unique id not allowed'));
      } else {
        resolve(value);
      }
    });
  };

  const handleUniqueIdChange = (event: CheckboxChangeEvent) => {
    log(event);
    form.validateFields();
  };

  const handleRelationTypeChange = (value: RealationType) => {
    if (fieldType && isGenericType(fieldType)) {
      let newType = fieldType;

      if (value === RealationType.ONE_TO_MANY_RELATED && !isListType(fieldType)) {
        newType = `List<${fieldType}>`;
      }

      if (value === RealationType.ONE_TO_ONE_RELATED && isListType(fieldType)) {
        newType = newType.replace('List<', '').replace('>', '');
      }

      form.setFieldsValue({ ...form.getFieldsValue(), type: newType });
    }
  };

  const handleFieldTypeChange = (_val: string) => {
    let val = _val;
    let className: string | undefined;

    if (_val.search(DELIMITER) !== -1) {
      [val, className] = val.split(DELIMITER);
    }

    setState(draft => {
      draft.fieldTypeOptions = getFieldTypeMatches();
    });

    if (isGenericType(val)) {
      if (isListType(val)) {
        form.setFieldsValue({
          ...form.getFieldsValue(),
          relationType: RealationType.ONE_TO_MANY_RELATED,
          aggregationType: AggregationType.AGGREGATION,
          className: className
        });

        return;
      }

      form.setFieldsValue({
        ...form.getFieldsValue(),
        relationType: RealationType.ONE_TO_ONE_RELATED,
        aggregationType: AggregationType.AGGREGATION,
        className: className
      });

      return;
    }

    form.setFieldsValue({
      ...form.getFieldsValue(),
      relationType: undefined,
      aggregationType: undefined,
      className: className
      // type: val
    });
  };

  const handleFieldTypeSearch = (val: string) => {
    setState(draft => {
      draft.fieldTypeOptions = getFieldTypeMatches(val);
    });
  };

  const handleFieldTypeBlur = () => {
    setState(draft => {
      draft.fieldTypeOptions = getFieldTypeMatches();
    });
  };

  useEffect(() => {
    if (data) {
      formActions.reset(getInitialData(), true);

      return;
    }

    formActions.reset(getInitialData(), false);
  }, [data, isOpen, formActions, getInitialData]);

  return (
    <Modal
      {...modalProps}
      title={!data ? <Trans>Add Field</Trans> : <Trans>Edit Field: {data.name}</Trans>}
      visible={isOpen}
      footer={
        <>
          <Button onClick={handleClose} action="cancel" />
          <Button
            action={mode === 'CREATE' ? 'insert' : 'save'}
            onClick={handleInsert}
            formState={formState}
          />
        </>
      }
    >
      <F.StyledForm
        $labelWidth={120}
        form={form}
        layout="horizontal"
        validateTrigger="onChange"
        onFieldsChange={formActions.onFieldsChange}
        onValuesChange={formActions.onValuesChange}
        name={`entity-field-form-${data?.id || 'create'}`}
        colon={false}
      >
        <FormItem name="id" hidden registerField={formActions.registerField}>
          <Input />
        </FormItem>
        <FormItem
          label={
            <TooltipLabel
              label={<Trans>Field name</Trans>}
              docId={DocId.DOMAIN_DIAGRAM_FIELD_NAME}
            />
          }
          messageVariables={{ name: i18n._('Field name') }}
          name="name"
          rules={[
            {
              validator: validateName,
              message: i18n._('Field name is already used by another field')
            },
            {
              pattern: /[a-z](.[a-z0-9_]+)+$/,
              message: i18n._('This is not a valid field name')
            },
            { required: true }
          ]}
          validateFirst
          registerField={formActions.registerField}
        >
          <Input />
        </FormItem>
        <FormItem
          label={
            <TooltipLabel
              label={<Trans>Data type</Trans>}
              docId={DocId.DOMAIN_DIAGRAM_FIELD_DATA_TYPE}
            />
          }
          messageVariables={{ name: i18n._('Data type') }}
          name="type"
          rules={[{ required: true }]}
          registerField={formActions.registerField}
        >
          <Select
            getPopupContainer={() => dialogContainer}
            showSearch
            onSearch={handleFieldTypeSearch}
            onChange={handleFieldTypeChange}
            onBlur={handleFieldTypeBlur}
            allowClear
          >
            {state.fieldTypeOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </FormItem>
        <FormItem name="className" hidden registerField={formActions.registerField}>
          <Input />
        </FormItem>
        <FormItem
          label={
            <TooltipLabel
              label={<Trans>Unique id</Trans>}
              docId={DocId.DOMAIN_DIAGRAM_FIELD_UNIQUE_ID}
            />
          }
          messageVariables={{ name: i18n._('Unique id') }}
          name="uniqueId"
          rules={[
            {
              validator: validateUniqueId,
              message: i18n._('A non primitive field type cannot be unique')
            }
          ]}
          valuePropName="checked"
          validateFirst
          registerField={formActions.registerField}
        >
          <Checkbox onChange={handleUniqueIdChange} />
        </FormItem>
        {fieldType && isGenericType(fieldType, true) && isFieldType(fieldType, fieldTypes) && (
          <>
            <S.ObjectRelation>
              <h4>
                <Trans>Object relation type</Trans>
                <DocsTooltip docId={DocId.DOMAIN_DIAGRAM_OBJECT_RELATION} />
              </h4>
              <FormItem
                label={
                  <TooltipLabel
                    label={<Trans>Relation</Trans>}
                    docId={DocId.DOMAIN_DIAGRAM_FIELD_RELATION_TYPE}
                  />
                }
                name="relationType"
                rules={[{ required: true }]}
                registerField={formActions.registerField}
              >
                <Select
                  getPopupContainer={() => dialogContainer}
                  onChange={handleRelationTypeChange}
                  dropdownStyle={{ zIndex: 2000 }}
                >
                  <Select.Option value={RealationType.ONE_TO_ONE_RELATED}>One to one</Select.Option>
                  <Select.Option value={RealationType.ONE_TO_MANY_RELATED}>
                    One to many
                  </Select.Option>
                </Select>
              </FormItem>
              <FormItem
                label={
                  <TooltipLabel
                    label={<Trans>Aggregation</Trans>}
                    docId={DocId.DOMAIN_DIAGRAM_FIELD_AGGREGATION_TYPE}
                  />
                }
                rules={[{ required: true }]}
                initialValue={AggregationType.AGGREGATION}
                name="aggregationType"
                registerField={formActions.registerField}
              >
                <Select getPopupContainer={() => dialogContainer} dropdownStyle={{ zIndex: 2000 }}>
                  <Select.Option value={AggregationType.COMPOSITION_EMBEDDED}>
                    Composition embedded
                  </Select.Option>
                  <Select.Option value={AggregationType.COMPOSITION_DETACHED}>
                    Composition detached
                  </Select.Option>
                  <Select.Option value={AggregationType.AGGREGATION}>Aggregation</Select.Option>
                </Select>
              </FormItem>
            </S.ObjectRelation>
          </>
        )}
        <S.FieldSelectorContainer />
      </F.StyledForm>
    </Modal>
  );
};

export default EntityFieldFormDialog;
