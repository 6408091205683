import { DomainActionTypes } from './domain.types';

import { DomainRouteParams } from 'index.types';

// Action Definition

type SetDomainNameAction = {
  type: DomainActionTypes.SET_DOMAINNAME;
  name?: string;
};

type SetRouteAction = {
  type: DomainActionTypes.SET_ROUTE;
  route: string;
  params?: DomainRouteParams;
};

type ResetAction = {
  type: DomainActionTypes.RESET;
};

// Union Action Types
export type DomainActionCreators = SetDomainNameAction | SetRouteAction | ResetAction;

// Action Creators -------------------------------------------------------------------------------

export const st_domain_setDomainName = (name?: string): SetDomainNameAction => ({
  type: DomainActionTypes.SET_DOMAINNAME,
  name
});

export const st_domain_setRoute = (route: string, params?: DomainRouteParams): SetRouteAction => ({
  type: DomainActionTypes.SET_ROUTE,
  route,
  params
});

export const st_domain_reset = (): ResetAction => ({
  type: DomainActionTypes.RESET
});
