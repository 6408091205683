import React from 'react';

import { Trans } from '@lingui/macro';

import DataGeneratorForm from '../../components/dataGeneratorForm/dataGeneratorForm';

import AppLayout from 'components/app/components/appLayout';
import DomainPage from 'components/domain/components/domainPage';
import routes from 'config/routes/routes';

const DataGeneratorPage: React.FC = () => (
  <AppLayout>
    <DomainPage
      route={routes.domain.dataManagement.testDataGenerator}
      schemaSelector
      title={<Trans>Test data generator</Trans>}
      content={<DataGeneratorForm />}
      noScroll
      className="data-generator"
    />
  </AppLayout>
);

export default DataGeneratorPage;
