import React from 'react';

import { Trans } from '@lingui/macro';
import { Scrollable } from '@phoenix-systems/react-layout';
import { useSelector } from 'react-redux';

import TriggerTableOverview from './singleViews/usersTableOverview';
import UsersTableNav from './usersTableNav';

import * as T from 'components/_styled/tableSc';
import UserActions from 'components/userAdmin/actions/userActions';
import { User } from 'services/api/domain/user';
import { st_triggersTable_getSingleView } from 'services/store/triggersTable/triggersTable.selectors';

export type DomianTableSingleView = 'OVERVIEW' | 'STATISTIC';

type UsersTableSingleProps = {
  data: User[];
};

const UsersTableSingle: React.FC<UsersTableSingleProps> = ({ data }) => {
  const view = useSelector(st_triggersTable_getSingleView);

  if (!data) {
    return null;
  }

  if (data.length === 1) {
    const [user] = data;

    return (
      <>
        <T.SingleTitle>
          <h4>
            <Trans>{user.username}</Trans>
          </h4>
          <T.SingleTitleRight>
            <UserActions data={user} mode="BUTTON" popoverPlacement="rightTop" />
          </T.SingleTitleRight>
        </T.SingleTitle>
        <UsersTableNav data={user} />
        <Scrollable scrollY>
          <T.SingleContent>
            {view === 'OVERVIEW' && <TriggerTableOverview data={user} />}
          </T.SingleContent>
        </Scrollable>
      </>
    );
  }

  return null;
};

export default UsersTableSingle;
