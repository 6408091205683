import { ListQueryParams } from '@phoenix-systems/react-table/dist/types/types';
import { createSelector } from 'reselect';

import { TriggersTableSingleView, TriggersTableState } from './triggersTable.types';

export const triggersTableState = (state: {
  triggersTable: TriggersTableState;
}): TriggersTableState => state.triggersTable;

export const st_triggersTable_getParams = createSelector(
  [triggersTableState],
  (triggersTable): ListQueryParams => triggersTable.params
);

export const st_triggersTable_getSingleView = createSelector(
  [triggersTableState],
  (triggersTable): TriggersTableSingleView => triggersTable.singleView
);

export const st_triggersTable_getSelectedKey = createSelector(
  [triggersTableState],
  (triggersTable): string | undefined => triggersTable.selectedKey
);
