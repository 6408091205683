import { EntityHierarchy, EntityHierarchyParams } from './hierarchy.types';

import apiClient from 'services/api/apiClient';

const BASE_PATH = 'hierarchy';

/**
 * GET /api/hierarchy/type/{domain}/{scheme}/{fields}
 * GET /api/hierarchy/type/{domain}/{scheme}/{fields}/{type}/{fields}
 */
export const api_getEntityHierarchy = async (
  params: EntityHierarchyParams
): Promise<EntityHierarchy[]> => {
  if (!params.entity) {
    const { data } = await apiClient.get(
      `/${BASE_PATH}/type/${params.domainName}/${params.schema}/${params.fields.toString()}`
    );

    return data;
  }

  const { data } = await apiClient.get(
    `/${BASE_PATH}/type/${params.domainName}/${params.schema}/${params.fields.toString()}/${
      params.entity
    }/${params.fields.toString()}`
  );

  return data;
};
