import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Popover } from 'antd';
import { Position, useStoreActions } from 'react-flow-renderer';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import FieldContextNavigation from '../contextNavigation/fieldContextNavigation';
import EdgeHandle from '../edgeHandle';
import { getHandleId, isGenericType } from '../utils';

import * as S from './entityFieldSc';

import useContainer from 'components/app/components/containerProvider/useContainer';
import DocsTooltip from 'components/docs/components/docsTooltip';
import { EntityNodeData, EntityNodeField, HandleType } from 'services/api/domain/domainDiagram';
import { DocId } from 'services/api/domain/hint';
import {
  st_domainDiagram_setActiveHandles,
  st_domainDiagram_setActiveNode,
  st_domainDiagram_setContextNavOpen
} from 'services/store/domainDiagram/domainDiagram.actions';
import {
  st_domainDiagram_getActiveHandles,
  st_domainDiagram_getContextNavIsOpen,
  st_domainDiagram_getEdges,
  st_domainDiagram_getElements
} from 'services/store/domainDiagram/domainDiagram.selectors';

type EntityFieldProps = {
  index: number;
  nodeData: EntityNodeData;
  nodeId: string;
  data: EntityNodeField;
};

const EntityField: React.FC<EntityFieldProps> = ({ data, nodeData, index, nodeId }) => {
  const { domainDiagramContainer } = useContainer();
  const [isNavVisible, setNavVisible] = useState(false);
  const dispatch = useDispatch();
  const isOpenContextNav = useSelector(st_domainDiagram_getContextNavIsOpen);
  const setSelectedElements = useStoreActions(actions => actions.setSelectedElements);
  const elements = useSelector(st_domainDiagram_getElements);
  const edgeOut = useSelector(st_domainDiagram_getEdges).find(
    edge => edge.sourceHandle === `fieldOut_${nodeData.className}_${data.name}`
  );
  const activeHandles = useSelector(st_domainDiagram_getActiveHandles);
  const handleOutId = getHandleId(HandleType.FIELD_OUT, nodeData.className, data.name) || uuid();
  const handleInId = getHandleId(HandleType.FIELD_IN, nodeData.className, data.name) || uuid();
  const edges = useSelector(st_domainDiagram_getEdges);
  const isGeneric = isGenericType(data.type);

  const handleVisibilityChange = (visible: boolean) => {
    setNavVisible(visible);
    dispatch(st_domainDiagram_setContextNavOpen(visible));

    if (visible) {
      setSelectedElements(elements.filter(el => el.id === nodeId));
    }
  };

  const handleMouseOver = () => {
    const activeHndl = [handleOutId];

    if (isGenericType(data.type)) {
      const [edge] = edges.filter(edg => edg.sourceHandle === handleOutId);

      if (edge?.targetHandle) {
        activeHndl.push(edge.targetHandle);
      }
    }
    dispatch(st_domainDiagram_setActiveNode(nodeId));
    dispatch(st_domainDiagram_setActiveHandles(activeHndl));
  };

  const handleMouseOut = () => {
    dispatch(st_domainDiagram_setActiveNode(undefined));
    dispatch(st_domainDiagram_setActiveHandles([]));
  };

  useEffect(() => {
    if (!isOpenContextNav) {
      dispatch(st_domainDiagram_setActiveNode(undefined));
      dispatch(st_domainDiagram_setActiveHandles([]));
      setNavVisible(false);
    }
  }, [isOpenContextNav, dispatch]);

  return (
    <Popover
      content={
        <FieldContextNavigation
          field={data}
          index={index}
          node={nodeData}
          nodeId={nodeId}
          setOpen={setNavVisible}
        />
      }
      getPopupContainer={() => domainDiagramContainer}
      placement="rightTop"
      title={
        <>
          <Trans>Field</Trans>:{data.name}
        </>
      }
      trigger="contextMenu"
      visible={isNavVisible}
      onVisibleChange={handleVisibilityChange}
    >
      <S.Field key={data.name} onMouseOut={handleMouseOut} onMouseOver={handleMouseOver}>
        <S.Label>
          <span>{data.name}</span>
          <div>
            <span>{data.type}</span>
            {data.uniqueId && <FontAwesomeIcon icon={['fas', 'key']} />}
            {isGeneric && !edgeOut && (
              <DocsTooltip
                docId={DocId.DOMAIN_DIAGRAM_FIELD_MISSING_EDGE_OUT}
                icon={['fas', 'exclamation-triangle']}
              />
            )}
          </div>
        </S.Label>
        <EdgeHandle
          active={activeHandles.includes(handleInId)}
          id={handleInId}
          position={Position.Left}
          type="target"
          hidden
        />
        <>
          <EdgeHandle
            active={activeHandles.includes(handleOutId)}
            hidden={!isGeneric}
            id={handleOutId}
            position={Position.Right}
            type="source"
          />
        </>
      </S.Field>
    </Popover>
  );
};

export default EntityField;
