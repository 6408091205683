import { Alert, Form } from 'antd';
import tw, { styled, css } from 'twin.macro';

import appStyles from 'styles/appStyles';
import { getRem } from 'utils';

const DEFAULT_MAX_WIDTH = 1400;
const DEFAULT_BOX_CONTAINER_WIDTH = 800;

type FormProps = {
  $labelWidth?: number;
  $maxWidth?: number;
};

export const StyledForm = styled(Form)<FormProps>(({ $maxWidth, $labelWidth }) => [
  css`
    max-width: ${getRem($maxWidth || DEFAULT_MAX_WIDTH)};

    & .ant-input,
    .ant-input-password {
      ${tw`bg-light-gray-50 hover:bg-light-gray-100 font-500`}
    }

    & .ant-input-group-addon {
      ${tw`px-0 relative`}

      button {
        ${tw`h-36 p-0 m-0 h-34 text-gray-500 hover:text-primary`};
        line-height: ${getRem(34)};
      }

      & .addon-divider {
        ${tw`inline-block h-34 w-1 absolute top-0 left-36 bg-ant-border-gray `}
      }
    }

    & .ant-select {
      ${tw` font-400`}
    }

    & .ant-form-item-label > label {
      ${tw`font-600 text-primary`}
      height: ${getRem(34)};

      width: ${$labelWidth ? getRem($labelWidth) : 'auto'};
    }

    & .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      ${tw`bg-light-gray-50 font-500`}
    }

    & .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      ${tw`bg-light-gray-50 font-500`}
    }

    & button:not(.ant-input-wrapper button) {
      ${tw`font-500 `}

      & .anticon {
        ${tw`mr-10`}
      }
    }

    & .ant-input-affix-wrapper-lg {
      ${tw`bg-light-gray-50`}
    }

    & .ant-input-prefix {
      ${tw`mr-10`}
    }

    & .ant-form-item-explain {
      ${tw`text-12 pb-10 font-400`}
    }

    &.ant-form-horizontal.data-display .ant-form-item-label {
      min-width: ${getRem(170)};
    }

    &.ant-form-horizontal.data-display .ant-form-item-label label {
      ${tw`mt-5`}
    }

    & .ant-select-arrow {
      ${tw`text-gray-800`}
    }

    & .ant-picker {
      ${tw`bg-light-gray-50 `}
    }

    & .ant-form-item.read-only {
      pointer-events: none;
    }

    & .ant-form-item {
      ${tw`mb-18`}
    }

    & .ant-select-multiple .ant-select-selection-item {
      ${tw`bg-light-gray text-black font-500`}
    }

    & .ant-select-multiple .ant-select-selection-item-remove > .anticon {
      ${tw`ml-4`}
      vertical-align: -0.01em;
    }

    & .ant-select-auto-complete {
      width: 100% !important;
    }

    /* &
      .ant-select:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-search-input {
      font-weight: 500;
    } */

    & .ant-form-item-has-success.ant-form-item-has-feedback .ant-form-item-children-icon {
      ${tw`hidden`}
    }

    & .otm-editor .otm-editor-list-item-crud .ant-btn.ant-btn-icon-only {
      ${tw`border-none ml-0 text-14 w-26`}

      & svg {
        ${tw` text-14`}
      }
    }

    & .otm-editor .ant-tag {
      ${tw`text-14 px-10`}
    }

    & .otm-editor.disabled {
      ${tw`pointer-events-none opacity-50`}
    }

    /*  & .otm-editor {
      ${tw`transition-all duration-200 rounded-2`}
      border-bottom:none
    } */

    & .otm-editor-list-item.not-editable .otm-editor-list-item-crud {
      & .edit-btn,
      & .remove-btn {
        ${tw`pointer-events-none opacity-20`}
      }
    }

    & .otm-editor .otm-editor-header {
      ${tw`py-2 px-12`}
      button {
        ${tw`border-none px-0 hover:text-secondary font-700`}
      }
    }

    & .otm-editor .otm-editor-list-item .list-item-inner {
      ${tw`py-0 min-h-36 border-transparent border box-border transition-all duration-150 bg-light-gray-50 rounded-2 font-500`}
      border-bottom: 1px solid ${appStyles.colors.antBorderGray};

      &:hover {
        ${tw` border-primary `};
      }

      &.sortable-ghost {
        ${tw`bg-light-gray-50 border-light-gray`}
      }
      &.sortable-choosen {
        ${tw`bg-white opacity-100 border-light-gray border-t border-l border-r`}
      }
    }

    & .ant-transfer-list-content-item {
      ${tw`font-500`}
    }

    & .ant-input-group-wrapper.has-button .ant-input-group-addon {
      ${tw`p-0`}

      & .ant-btn-icon-only {
        ${tw`m-0 p-0`}
      }
    }

    & .otm-data-transfer .ant-transfer-operation .ant-btn .anticon svg {
      margin-top: ${getRem(-5)};
      margin-left: ${getRem(5)};
    }

    & .ant-input-number {
      ${tw`w-full bg-light-gray-50 font-500`}

      & input {
        ${tw` font-500`}
      }
    }

    & .file-selector {
      .ant-upload.ant-upload-drag {
        ${tw`bg-light-gray-50`}
      }
      & .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
        ${tw`text-36 text-gray-400`}
      }

      & .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
        ${tw`mb-12`}
      }

      & .ant-upload.ant-upload-drag p.ant-upload-text {
        ${tw`text-14 font-500 text-gray-500`}
      }

      & .ant-upload.ant-upload-drag p.ant-upload-hint {
        ${tw`text-12`}
      }

      & .ant-upload-list-item-name {
        ${tw`font-500`}
      }

      & .file-item {
        ${tw`bg-light-gray-50`}

        & .file-info-content {
          ${tw`flex flex-row items-center`}

          & .file-icon {
            ${tw`text-32 text-gray-400 mr-16`}
          }

          & p {
            ${tw`font-500`}

            & span {
              ${tw`inline-block text-12 text-primary`}
              min-width: ${getRem(45)}
            }
          }
        }

        & .actions {
          .ant-btn {
            ${tw`opacity-40 hover:opacity-100`}
          }
        }
      }
    }

    & .ant-select.ant-tree-select .ant-select-selector {
      & .icon,
      .type {
        ${tw`hidden`}
      }
    }

    & .ReactCollapse--collapse {
      ${tw`transition-all`}
    }

    & .ReactCollapse--content {
      ${tw`pt-24`}
    }

    & .collection-editor {
      & .collection-editor-header {
        ${tw`py-4 px-8 bg-light-gray`};

        .ant-btn {
          ${tw` h-28 text-13 shadow-sm font-600 flex items-center flex-row`};

          &:hover {
            ${tw`shadow`};
          }
        }
        .add-btn:not([disabled]) {
          ${tw`text-white bg-blue-create-300 border-blue-create-400`};

          &:hover,
          :active,
          :focus {
            ${tw`text-white bg-blue-create-500 border-blue-create-800`};
          }
        }

        .remove-all-btn:not([disabled]) {
          ${tw`bg-white`};

          &:hover,
          :active,
          :focus {
            ${tw`text-red-delete  border-red-delete`};
          }
        }
      }

      & .collection-editor-list-item.list {
        & .list-item-inner {
          ${tw`py-4 px-8`};
        }
      }

      & .collection-editor-list-item-crud {
        & .ant-btn {
          ${tw`text-14 w-28 h-28 flex items-center justify-center`};

          & svg {
            ${tw`text-13`};
          }

          &.remove-btn:hover {
            ${tw`text-red-delete  border-red-delete`};
          }
        }
      }

      & .collection-editor-list-item {
        &.not-editable {
          background: pink;
          & .remove-btn,
          .edit-btn {
            ${tw`pointer-events-none opacity-40`};
          }
        }
      }
    }

    & .ant-transfer-list {
      ${tw`w-full`};
    }

    & .ant-transfer-operation {
      & .ant-btn .anticon {
        ${tw`m-0 p-0`};
      }
    }

    & .ant-transfer-list-content-item {
      & .item-icon {
        ${tw`mr-6 text-gray-600`};
      }

      &:hover {
        & .item-icon {
          ${tw`mr-6 text-primary`};
        }
      }

      &.ant-transfer-list-content-item-disabled {
        & .item-icon {
          ${tw`text-gray-300`};
        }

        &:hover {
          & .item-icon {
            ${tw`text-gray-300`};
          }
        }
      }
    }

    & .ant-transfer-list-header {
      ${tw`bg-light-gray`};

      & .ant-transfer-list-header-title {
        ${tw`text-primary font-600`};
      }
    }

    & .ant-transfer-list {
      min-height: ${getRem(280)};
    }
  `
]);

export const Title = styled.h3(() => [
  css`
    ${tw`font-600 text-primary text-16 mb-24 leading-relaxed`};
    line-height: 2rem;

    & svg {
      ${tw`mr-12`};
    }
  `
]);

type FormAlertProps = {
  margin?: string;
  padding?: string;
  left?: number;
};

export const FormAlert = styled(Alert)<FormAlertProps>(({ margin, padding, left }) => [
  css`
    & .ant-alert-message {
      ${tw`font-600 text-14`};
    }

    &.ant-alert-info {
      ${tw`bg-yellow-100 border-ant-border-gray`};
    }
  `,

  margin &&
    css`
      margin: ${margin};
    `,

  padding &&
    css`
      padding: ${padding};
    `,

  left &&
    css`
      margin-left: ${getRem(left)};
    `
]);

export const PopupContainer = styled.div(() => [
  css`
    & .ant-select-item-option-content {
      ${tw`font-500`};

      & svg {
        ${tw`mr-12`};
      }
    }

    & .ant-select-single .ant-select-selector .ant-select-selection-item svg {
      ${tw`mr-12`};
    }

    & .ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
      ${tw`bg-light-gray-active`}
    }

    &
      .ant-tree-select-dropdown
      .ant-select-tree-list-holder-inner
      .ant-select-tree-treenode
      .ant-select-tree-node-content-wrapper {
      ${tw`font-500`}
    }

    & .ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode {
      ${tw`pb-4`}

      & .icon {
        ${tw`mr-6`}
      }

      &.entity {
        ${tw`pt-12`}
      }

      &.entity:first-of-type {
        ${tw`pt-0`}
      }

      &.entity:last-of-type {
        ${tw`pb-12`}
      }

      &.entity .label {
        ${tw`font-700 text-primary`}
      }

      &.field .label {
        ${tw`font-500 text-gray-700`}
      }

      /*  &.disabled {
        ${tw`opacity-60`}
      } */

      /*      &.entity {
        ${tw`bg-light-gray-active`}
      } */

      .type {
        ${tw`font-mono text-12 text-gray-400 inline-block ml-4`}
      }
    }

    & .ant-select-dropdown.autocomplete-result {
      & .ant-select-item {
        ${tw`border-b border-ant-border-gray pb-4`};

        & h4 {
          ${tw`text-14 font-500`};
        }

        & p {
          ${tw`text-12 font-500`};

          & hl {
            ${tw`bg-yellow-200`};
          }
        }
      }

      & .ant-select-item:last-of-type {
        ${tw`border-none`};
      }
    }

    & .ant-popover.entity-document {
      & .ant-popover-content {
        min-width: 64rem;

        & .ant-popover-inner-content {
          ${tw`p-6 text-13 font-mono`};
        }
      }
    }

    & .editor-overlay .ant-popover-inner-content {
      ${tw`p-4`};
    }

    & .editor-toolbar-overlay .ant-popover-inner-content {
      ${tw`p-4`};

      & .ant-btn {
        ${tw`mr-6`};

        &:last-of-type {
          ${tw`mr-0`};
        }
      }
    }
  `
]);

type StyledFormItemProps = {
  $maxWidth?: number;
};
export const StyledFormItem = styled(Form.Item)<StyledFormItemProps>(({ $maxWidth }) => [
  $maxWidth !== undefined &&
    css`
      max-width: ${getRem($maxWidth)};
    `
]);

export const Separator = styled.div(() => [
  css`
    ${tw`mb-36`};
  `
]);

type BoxedFormContainerProps = {
  maxWidth?: number;
};
export const BoxedFormContainer = styled.div<BoxedFormContainerProps>(({ maxWidth }) => [
  css`
    ${tw` shadow p-16 border border-light-gray`};
    max-width: ${getRem(maxWidth || DEFAULT_BOX_CONTAINER_WIDTH)};
  `
]);

export const BoxedFormContainerHead = styled.h3(() => [
  css`
    ${tw` font-500 text-primary text-18 border-b border-primary pb-8 `};

    & svg {
      ${tw`mr-12`};
    }
  `
]);

export const BoxedFormContainerBody = styled.div(() => [
  css`
    ${tw` pt-24 pb-12 `};
  `
]);

export const BoxedFormContainerFooter = styled.div(() => [
  css`
    ${tw`flex flex-row pb-20`};

    & .ant-btn {
      ${tw`ml-12`};
      min-width: ${getRem(140)};

      &:first-of-type {
        ${tw`ml-auto`};
      }
    }
  `
]);

export const BoxedFormContainerMessage = styled.div(() => [
  css`
    ${tw`mb-12 mt-6`};
  `
]);

export type BoxedFormContainerSummaryProps = {
  labelWidth: number;
};
export const BoxedFormContainerSummary = styled.div<BoxedFormContainerSummaryProps>(
  ({ labelWidth }) => [
    css`
      ${tw`text-13 font-500`};

      & span {
        ${tw`text-primary mr-6 inline-block`};

        min-width: ${getRem(labelWidth)};
      }
    `
  ]
);

export const FileInfo = styled.div(() => [
  css`
    ${tw`mt-8`};
    & p {
      ${tw`font-500 text-12`};

      & span {
        ${tw`text-primary text-10 inline-block`};
        min-width: ${getRem(60)};
      }
    }
  `
]);

export const FormItemError = styled.div(() => [
  css`
    ${tw`mt-12`};
  `
]);
