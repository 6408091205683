import { AxiosError } from 'axios';
import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';

import { api_getTriggerById, api_getTriggerByName, api_getTriggers } from './trigger.queries';
import { GetTriggersParams, Trigger } from './trigger.types';

export const useTriggerById = (
  id: string,
  options?: UseQueryOptions<Trigger, AxiosError, Trigger>
): QueryObserverResult<Trigger, AxiosError> =>
  useQuery<Trigger, AxiosError>(['trigger', 'byId', id], () => api_getTriggerById(id), options);

export const useTriggerByName = (
  name: string,
  options?: UseQueryOptions<Trigger, AxiosError, Trigger>
): QueryObserverResult<Trigger, AxiosError> =>
  useQuery<Trigger, AxiosError>(
    ['trigger', 'byName', name],
    () => api_getTriggerByName(name),
    options
  );

export const useTriggers = (
  params: GetTriggersParams,
  options?: UseQueryOptions<Trigger[], AxiosError, Trigger[]>
): QueryObserverResult<Trigger[], AxiosError> =>
  useQuery<Trigger[], AxiosError>(['triggers', params], () => api_getTriggers(params), options);

export const useTriggersCheck = (
  params: GetTriggersParams,
  options?: UseQueryOptions<Trigger[], AxiosError, Trigger[]>
): QueryObserverResult<Trigger[], AxiosError> =>
  useQuery<Trigger[], AxiosError>(
    ['triggersCheck', params],
    () => api_getTriggers(params),
    options
  );
