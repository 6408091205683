import React, { useEffect } from 'react';

import { LayoutProvider } from '@phoenix-systems/react-layout';
import { NavigationsProvider } from '@phoenix-systems/react-navigation';
import { Router } from '@phoenix-systems/react-router';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useDispatch } from 'react-redux';
import * as ReactRouter from 'react-router-dom';

import AuthProvider from './components/authProvider';
import UserProvider from './components/userProvider';

import ContainerProvider from 'components/app/components/containerProvider';
import LanguageProvider from 'components/app/components/languageProvider';
import PushNotifications from 'components/app/components/pushNotifications';
import layoutConfig from 'config/layout';
import routesConfig from 'config/routes';
import queryClient from 'services/api/queryClient';
import history from 'services/history';
import mediaQueries from 'styles/mediaQueries';

const App: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const spSc: HTMLElement | null = document.querySelector('#splashscreen');

    if (spSc) {
      spSc.style.display = 'none';
    }
  }, [dispatch]);

  return (
    <ReactRouter.Router history={history}>
      <QueryClientProvider client={queryClient}>
        <LanguageProvider>
          <AuthProvider>
            <UserProvider>
              <LayoutProvider config={layoutConfig} mediaQueries={mediaQueries}>
                <NavigationsProvider>
                  <ContainerProvider>
                    <PushNotifications>
                      <Router routes={routesConfig} />
                    </PushNotifications>
                  </ContainerProvider>
                </NavigationsProvider>
              </LayoutProvider>
            </UserProvider>
          </AuthProvider>
        </LanguageProvider>
        {window._env_.ENVIRONMENT === 'local' && <ReactQueryDevtools position="bottom-right" />}
      </QueryClientProvider>
    </ReactRouter.Router>
  );
};

export default App;
