import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Menu } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { clone, omit } from 'lodash';
import { useDispatch } from 'react-redux';
import { useImmer } from 'use-immer';

import { prepareNodeData } from 'components/domain/components/domainDiagram/utils';
import Button from 'components/ui/button';
import useDefaultModalProps from 'hooks/useDefaultModalProps';
import { EntityNodeData, EntityNodeField } from 'services/api/domain/domainDiagram';
import {
  st_domainDiagram_setDialogOpen,
  st_domainDiagram_updateNodeData
} from 'services/store/domainDiagram/domainDiagram.actions';

type RemoveFieldActionProps = {
  data: EntityNodeField;
  nodeId: string;
  nodeData: EntityNodeData;
  closeNavigation: () => void;
  label: string | JSX.Element;
};

type RemoveFieldActionState = {
  isDialogOpen: boolean;
};

const RemoveFieldAction: React.FC<RemoveFieldActionProps> = props => {
  const pr = omit(props, ['nodeId', 'data', 'nodeData', 'closeNavigation', 'label']);
  const { data, nodeId, nodeData, closeNavigation, label } = props;
  const dispatch = useDispatch();
  const [state, setState] = useImmer<RemoveFieldActionState>({
    isDialogOpen: false
  });
  const modalProps = useDefaultModalProps();

  const handleRemoveFieldRequested = () => {
    closeNavigation();
    setState(draft => {
      draft.isDialogOpen = true;
    });
    dispatch(st_domainDiagram_setDialogOpen(true));
  };

  const handleDialogClose = () => {
    dispatch(st_domainDiagram_setDialogOpen(false));
    setState(draft => {
      draft.isDialogOpen = false;
    });
  };

  const handleRemoveField = () => {
    const newData = clone(nodeData);
    newData.fields = newData.fields.filter(field => field.id !== data.id);
    dispatch(st_domainDiagram_updateNodeData(prepareNodeData(newData), nodeId));
    handleDialogClose();
  };

  return (
    <>
      <Menu.Item
        {...pr}
        key="insert-entity"
        icon={<FontAwesomeIcon icon={['fas', 'trash']} />}
        onClick={handleRemoveFieldRequested}
      >
        {label}
      </Menu.Item>

      <Modal
        {...modalProps}
        title={<Trans>Remove field: {data.name}</Trans>}
        visible={state.isDialogOpen}
        footer={
          <>
            <Button action="cancel" onClick={handleDialogClose} />
            <Button action="delete" onClick={handleRemoveField}>
              <Trans>Remove field</Trans>
            </Button>
          </>
        }
      >
        <p>
          <Trans>
            Do you want to remove the field <b>{data.name}</b>?
          </Trans>
        </p>
      </Modal>
    </>
  );
};

export default RemoveFieldAction;
