import React from 'react';

import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';

import FtpConnectionsTableNav from './ftpConnectionsTableNav';
import TriggerTableOverview from './singleViews/ftpConnectionsTableOverview';
import FtpConnectionsTableTasks from './singleViews/ftpConnectionsTableTasks';

import * as T from 'components/_styled/tableSc';
import FtpConnectionActions from 'components/domain/actions/ftpConnectionActions';
import { FTPConnection } from 'services/api/domain/ftpConnection';
import { st_ftpConnectionsTable_getSingleView } from 'services/store/ftpConnectionsTable/triggersTable.selectors';

export type DomianTableSingleView = 'OVERVIEW' | 'STATISTIC';

type FtpConnectionTableSingleProps = {
  data: FTPConnection[];
};

const FtpConnectionTableSingle: React.FC<FtpConnectionTableSingleProps> = ({ data }) => {
  const view = useSelector(st_ftpConnectionsTable_getSingleView);

  if (!data) {
    return null;
  }

  if (data.length === 1) {
    const [item] = data;

    return (
      <>
        <T.SingleTitle>
          <h4>
            <Trans>{item.name}</Trans>
          </h4>
          <T.SingleTitleRight>
            <FtpConnectionActions
              data={item}
              mode="BUTTON"
              popoverPlacement="rightTop"
              style={{ marginRight: 12 }}
            />
          </T.SingleTitleRight>
        </T.SingleTitle>
        <FtpConnectionsTableNav data={item} />
        <T.SingleContent>
          {view === 'OVERVIEW' && <TriggerTableOverview data={item} />}
          {view === 'CONNECTED_TASKS' && <FtpConnectionsTableTasks data={item} />}
        </T.SingleContent>
      </>
    );
  }

  return null;
};

export default FtpConnectionTableSingle;
