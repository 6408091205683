import React from 'react';

import { Trans } from '@lingui/macro';
import { useStoreActions } from 'react-flow-renderer';

import Button from 'components/ui/button';

const ResetViewAction: React.FC = () => {
  const updateTransform = useStoreActions(actions => actions.updateTransform);

  const handleResetView = () => {
    updateTransform([0, 0, 1]);
  };

  return (
    <Button icon={['fas', 'border-outer']} onClick={handleResetView}>
      <Trans>Reset view</Trans>
    </Button>
  );
};

export default ResetViewAction;
