import { produce } from 'immer';

import { JsonEditorActionCreators } from './jsonEditor.actions';
import { JsonEditorActionTypes, JsonEditorState } from './jsonEditor.types';

const initialState: JsonEditorState = Object.freeze<JsonEditorState>({
  colorTheme: 'BRIGHT',
  fontSize: 'DEFAULT',
  indentSize: 'DEFAULT',
  hasMinimap: true
});

const jsonEditorReducer = (
  state: JsonEditorState = initialState,
  action: JsonEditorActionCreators
): JsonEditorState =>
  produce(state, draft => {
    switch (action.type) {
      case JsonEditorActionTypes.SET_COLOR_THEME:
        draft.colorTheme = action.colorTheme;

        break;

      case JsonEditorActionTypes.SET_FONT_SIZE:
        draft.fontSize = action.fontSize;

        break;

      case JsonEditorActionTypes.SET_LINE_INDENT:
        draft.indentSize = action.lineIndent;

        break;

      case JsonEditorActionTypes.SET_MINI_MAP:
        draft.hasMinimap = action.miniMap;

        break;

      case JsonEditorActionTypes.RESET:
        draft.colorTheme = 'BRIGHT';
        draft.fontSize = 'DEFAULT';
        draft.indentSize = 'DEFAULT';
        draft.hasMinimap = true;

        break;

      default:
        break;
    }
  });

export default jsonEditorReducer;
