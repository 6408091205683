import { AxiosError } from 'axios';
import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';

import { api_getObjectHistory } from './objectHistory.queries';
import { ObjectHistory, ObjectHistoryParameters } from './objectHistory.types';

// eslint-disable-next-line import/prefer-default-export
export const useObjectHistory = (
  params: ObjectHistoryParameters,
  options?: UseQueryOptions<ObjectHistory[], AxiosError, ObjectHistory[]>
): QueryObserverResult<ObjectHistory[], AxiosError> =>
  useQuery<ObjectHistory[], AxiosError>(
    ['obejctHistory', params],
    () => api_getObjectHistory(params),
    options
  );
