import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { getRoute } from '@phoenix-systems/react-router';
import { Col, Row } from 'antd';
import { useHistory } from 'react-router';

import * as S from './domainsTableSc';

import * as T from 'components/_styled/tableSc';
import DataItem from 'components/ui/dataItem';
import NoContent from 'components/ui/noContent';
import routes from 'config/routes/routes';
import { useIsSuperadmin } from 'hooks/useIsSuperAdmin';
import { Domain } from 'services/api/domain/domain';
import { useUsers } from 'services/api/domain/user';

type DomainsSingleOverviewProps = {
  data?: Domain;
};

const DomainsSingleUsers: React.FC<DomainsSingleOverviewProps> = ({ data }) => {
  const userQuery = useUsers();
  const isSuperadmin = useIsSuperadmin();
  const history = useHistory();

  const handleGotoUser = (username: string) => {
    history.push(getRoute(routes.userAdmin.user.single, username));
  };

  const getUser = (username: string) => {
    if (userQuery.data) {
      const user = userQuery.data.find(item => item.username === username);

      if (user) {
        return (
          <Col xs={24} md={12} flex={1}>
            <S.UserItem>
              <FontAwesomeIcon icon={['fas', 'user-circle']} />
              <div>
                <DataItem
                  name={<Trans>Id</Trans>}
                  value={user.id}
                  hasCopy
                  copyValue={user.id}
                  breakWord
                  hasCopyTitle
                />
                <DataItem
                  name={<Trans>Username</Trans>}
                  value={user.username}
                  hasCopy
                  copyValue={user.id}
                  onEdit={isSuperadmin ? () => handleGotoUser(user.username) : undefined}
                />
                {isSuperadmin ? (
                  <DataItem
                    name={<Trans>Organisations</Trans>}
                    value={
                      <>
                        {user.orgUnits?.map(orgUnit => (
                          <>
                            <S.StyledLink
                              key={orgUnit}
                              to={getRoute(routes.userAdmin.orgunit.single, orgUnit)}
                              style={{ marginRight: 10 }}
                            >
                              {orgUnit}
                              <FontAwesomeIcon icon={['fas', 'pen']} />
                            </S.StyledLink>
                          </>
                        ))}
                      </>
                    }
                  />
                ) : (
                  <DataItem name={<Trans>Organisations</Trans>} value={user.orgUnits?.join(', ')} />
                )}
                <DataItem name={<Trans>Groups</Trans>} value={user.groups?.join(', ')} />
              </div>
            </S.UserItem>
          </Col>
        );
      }

      return null;
    }

    return null;
  };

  if (!data || !userQuery.data) {
    return null;
  }

  return (
    <T.SingleContentContainer>
      <Row gutter={[12, 12]}>
        {data.admins && data.admins.length ? (
          <>
            {' '}
            {data.admins?.map(user => (
              <>{getUser(user)}</>
            ))}
          </>
        ) : (
          <NoContent style={{ width: '100%' }} />
        )}
      </Row>
    </T.SingleContentContainer>
  );
};

export default DomainsSingleUsers;
