import React from 'react';

import { Trans } from '@lingui/macro';
import { Scrollable } from '@phoenix-systems/react-layout';
import { useSelector } from 'react-redux';

import DocumentHistoryTableSingleNav from './documenHistoryTableSingleNav';
import { getParsedHistoryDate } from './documentHistory.utils';
import DocumentHistoryTableJsonDiff from './singleViews/documentHistoryTableJsonDiff';
import DocumentHistoryTableOverview from './singleViews/documentHistoryTableOverview';

import * as T from 'components/_styled/tableSc';
import { ObjectHistory } from 'services/api/domain/objectHistory';
import { st_historyTable_getSingleView } from 'services/store/historyTable/historyTable.selectors';

export type DomianTableSingleView = 'OVERVIEW' | 'STATISTIC';

type DocumentHistoryTableSingleProps = {
  data: ObjectHistory[];
};

const DocumentHistoryTableSingle: React.FC<DocumentHistoryTableSingleProps> = ({ data }) => {
  const view = useSelector(st_historyTable_getSingleView);

  if (!data) {
    return null;
  }

  if (data.length === 1) {
    const [item] = data;

    return (
      <>
        <T.SingleTitle>
          <h4>
            <Trans>
              Document history: {getParsedHistoryDate(item.dateOfChange)} / changed by {item.userid}
            </Trans>
          </h4>
        </T.SingleTitle>
        <DocumentHistoryTableSingleNav data={item} />
        {view === 'OVERVIEW' && (
          <Scrollable scrollY>
            <T.SingleContent>
              <DocumentHistoryTableOverview data={item} />
            </T.SingleContent>
          </Scrollable>
        )}
        {view === 'JSON_DIFF' && <DocumentHistoryTableJsonDiff data={item} />}
      </>
    );
  }

  return null;
};

export default DocumentHistoryTableSingle;
