import { FormItem } from '@phoenix-systems/react-form';
import { Row } from 'antd';
import tw, { css, styled } from 'twin.macro';

export const Generate = styled.div(() => [
  css`
    ${tw`flex items-center justify-center h-full`}
  `
]);

export const ContainerInner = styled.div(() => [
  css`
    ${tw`flex flex-col h-full `}

    & div.json-editor {
      ${tw`overflow-hidden h-full`}
    }
  `
]);

export const JsonFormItem = styled(FormItem)(() => [
  css`
    ${tw`block h-full mb-0 flex flex-col `}
    margin-bottom: 0!important;

    & .ant-form-item-label {
      ${tw`block h-auto`};
      height: auto !important;
    }

    & .ant-form-item-label > label {
      ${tw`w-full`}
    }

    & .ant-form-item-control {
      ${tw`h-full relative block`}

      & .ant-form-item-control-input {
        ${tw`h-full block`}

        & .ant-form-item-control-input-content {
          ${tw`h-full`}
        }
      }
    }
  `
]);

export const StyledRow = styled(Row)(() => [
  css`
    ${tw`h-full relative overflow-hidden`}

    & .ant-col {
      ${tw`relative h-full`}
    }

    & .form-container-content {
    }

    /*    & .form-container-footer {
      ${tw`absolute   `}
      bottom: 0rem;
      width: calc(100% - 3.6rem);
    } */
  `
]);
