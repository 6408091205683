import { Input } from 'antd';
import tw, { css, styled } from 'twin.macro';

export const PopoverFooter = styled.div(() => [
  css`
    ${tw`-ml-6 -mr-6 -mb-6 flex flex-row items-center border-t border-ant-border-gray mt-6 p-4`};

    & .ant-btn {
      ${tw`ml-auto bg-transparent border-none p-8 w-16 h-16 flex items-center justify-center`};

      & svg {
        ${tw`text-12`};
      }
    }
  `
]);

export const ItemValue = styled.div(() => [
  css`
    ${tw`font-mono text-13`};

    &.table {
      max-height: 120px !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      display: block !important;
    }

    &.popover {
      max-height: 90vh !important;
      overflow: auto;
      max-width: 800px !important;
    }
  `
]);

export const FieldInfo = styled.div(() => [
  css`
    ${tw`flex flex-row ml-4`};

    & p {
      & span {
        ${tw`text-primary font-sans text-11 font-600 inline-block mr-4`};
      }

      &:first-of-type {
        ${tw`mr-12`};
      }
    }
  `
]);

export const StyledTextareaCode = styled(Input.TextArea)(() => [
  css`
    ${tw` p-0 font-mono text-13 bg-transparent`};

    border: none !important;
    outline: none !important;
    box-shadow: none !important;

    &.table {
      max-height: 120px !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
    }

    &.popover {
      max-height: 90vh !important;
      overflow: auto;
    }
  `
]);

export const ListButtons = styled.div(() => [
  css`
    ${tw`h-64 w-16`};

    & .ant-btn {
      ${tw`text-gray-400 w-16 h-16 flex items-center justify-center p-0 mb-8`};

      &:hover {
        ${tw`text-primary`};
      }

      & {
        svg {
          ${tw`text-16`};
        }
      }

      &.delete:hover {
        ${tw`text-red-delete`};
      }
    }
  `
]);
