import React from 'react';

import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import './styles/tailwind.prod.css';
import 'antd/dist/antd.less';
import './styles/main.css';

import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from 'components/app';
import initI18n from 'services/i18n/i18n.init';
import initIcons from 'services/icons';
import store from 'services/store/store';

initI18n();
initIcons();

/**
 * NO STRICT MODE FOR THE MOMENT... :-)
 * 
 * <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
 */

render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

// reportWebVitals();
