import React from 'react';

import { useQueryClient } from 'react-query';

import useDomainName from 'hooks/useDomainName';
import { Domain } from 'services/api/domain/domain';

const DomainTitle: React.FC = ({ children }) => {
  const domainName = useDomainName();
  const queryClient = useQueryClient();
  const domain = queryClient.getQueryData<Domain>(['domain', domainName]);

  if (!domain) {
    return null;
  }

  return (
    <>
      {/*  {domain.domainName} - {children} */}
      {children}
    </>
  );
};

export default DomainTitle;
