import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Menu } from 'antd';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import useContainer from 'components/app/components/containerProvider/useContainer';
import * as C from 'components/app/components/contextNavigation/contextNavigationSc';
import AddFieldAction from 'components/domain/actions/domainDiagramActions/addFieldAction';
import EditEdgeAction from 'components/domain/actions/domainDiagramActions/editEdgeAction';
import EditFieldAction from 'components/domain/actions/domainDiagramActions/editFieldAction';
import RemoveEdgeAction from 'components/domain/actions/domainDiagramActions/removeEdgeAction';
import RemoveFieldAction from 'components/domain/actions/domainDiagramActions/removeFieldAction';
import { EntityNodeData, EntityNodeField } from 'services/api/domain/domainDiagram';
import {
  st_domainDiagram_setActiveHandles,
  st_domainDiagram_setActiveNode,
  st_domainDiagram_setContextNavOpen
} from 'services/store/domainDiagram/domainDiagram.actions';
import { st_domainDiagram_getEdges } from 'services/store/domainDiagram/domainDiagram.selectors';

const { SubMenu } = Menu;

type FieldContextNavigationProps = {
  index: number;
  node: EntityNodeData;
  field: EntityNodeField;
  setOpen: (open: boolean) => void;
  nodeId: string;
};

const FieldContextNavigation: React.FC<FieldContextNavigationProps> = ({
  index,
  node,
  field,
  nodeId,
  setOpen
}) => {
  const { domainDiagramContainer } = useContainer();
  const [edge] = useSelector(st_domainDiagram_getEdges).filter(
    edg => edg.source === nodeId && isEqual(edg.data?.sourceField, field)
  );
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(st_domainDiagram_setActiveNode(undefined));
    dispatch(st_domainDiagram_setActiveHandles([]));
    dispatch(st_domainDiagram_setContextNavOpen(false));
    setOpen(false);
  };

  return (
    <C.StyledMenu
      getPopupContainer={() => domainDiagramContainer}
      mode="vertical"
      style={{ width: 256 }}
      onMouseLeave={handleClose}
      forceSubMenuRender
    >
      <EditFieldAction
        closeNavigation={() => setOpen(false)}
        data={field}
        nodeData={node}
        nodeId={nodeId}
      />
      {edge && (
        <>
          <EditEdgeAction edge={edge} type="CONTEXT_NAV" />
          <RemoveEdgeAction edge={edge} type="CONTEXT_NAV" />
        </>
      )}
      <SubMenu
        key="add-field-nav"
        icon={<FontAwesomeIcon className="anticon" icon={['fas', 'arrow-alt-from-left']} />}
        title={<Trans>Insert field</Trans>}
      >
        <AddFieldAction
          key={`add-field_${index}`}
          closeNavigation={() => setOpen(false)}
          data={node}
          icon={['fas', 'arrow-to-top']}
          label={<Trans>Before</Trans>}
          nodeId={nodeId}
          position={index}
          type="CONTEXT_NAV"
        />
        <AddFieldAction
          key={`add-field_${index + 1}`}
          closeNavigation={() => setOpen(false)}
          data={node}
          icon={['fas', 'arrow-to-bottom']}
          label={<Trans>After</Trans>}
          nodeId={nodeId}
          position={index + 1}
          type="CONTEXT_NAV"
        />
        <AddFieldAction
          key="add-field-start"
          closeNavigation={() => setOpen(false)}
          data={node}
          icon={['fas', 'arrow-from-top']}
          label={<Trans>Start</Trans>}
          nodeId={nodeId}
          position={node.fields[0]?.name === 'id' ? 1 : 0}
          type="CONTEXT_NAV"
        />
        <AddFieldAction
          key="add-field-end"
          closeNavigation={() => setOpen(false)}
          data={node}
          icon={['fas', 'arrow-from-bottom']}
          label={<Trans>End</Trans>}
          nodeId={nodeId}
          position={node.fields.length}
          type="CONTEXT_NAV"
        />
      </SubMenu>
      <RemoveFieldAction
        closeNavigation={() => setOpen(false)}
        data={field}
        label={<Trans>Remove field</Trans>}
        nodeId={nodeId}
        nodeData={node}
      />
    </C.StyledMenu>
  );
};

export default FieldContextNavigation;
