import React, { useEffect, useRef } from 'react';

import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import setApiLanguageHeaderInterceptor from 'services/api/apiClient/languageInterceptor';
import { st_app_getLanguage } from 'services/store/app/app.selectors';

const LanguageProvider: React.FC = ({ children }) => {
  const language = useSelector(st_app_getLanguage);
  const queryClient = useQueryClient();
  const { current: queryClientRef } = useRef(queryClient);

  useEffect(() => {
    i18n.activate(language);
    setApiLanguageHeaderInterceptor(language);
    queryClientRef.refetchQueries();
  }, [language, queryClientRef]);

  return (
    <I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
      {children}
    </I18nProvider>
  );
};

export default LanguageProvider;
