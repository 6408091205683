import React from 'react';

import routes from '../routes/routes';

import { ViewConfig } from './view.types';

import ErrorPage from 'components/app/pages/errorPage';
import { ErrorPageType } from 'components/app/pages/errorPage/errorPage';

const errorViewConfig: ViewConfig = {
  routes: [
    {
      path: routes.error.notFound,
      exact: true,
      component: <ErrorPage type={ErrorPageType.NOT_FOUND} />
    },
    {
      path: routes.error.serverError,
      exact: true,
      component: <ErrorPage type={ErrorPageType.SERVER_ERROR} />
    }
  ]
};

export default errorViewConfig;
