import React, { useEffect } from 'react';

import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import { FormItem, useFormContext } from '@phoenix-systems/react-form';
import { RenderLinkDialogProps } from '@phoenix-systems/react-slate-editor';
import { Form, Input, Radio, Select, TreeSelect } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { SelectValue } from 'antd/lib/select';
import isUrl from 'is-url';
import { RuleObject } from 'rc-field-form/lib/interface';

import { LinkFormData, LinkType } from './editorLink.types';

import * as F from 'components/_styled/formSc';
import useContainer from 'components/app/components/containerProvider/useContainer';
import Button from 'components/ui/button';
import { getTreeSelectDataFromDocsConfig } from 'config/docs/docs.utils';
import useDefaultModalProps from 'hooks/useDefaultModalProps';
import { useIsSuperadmin } from 'hooks/useIsSuperAdmin';

const { Option } = Select;

const EditorLinkDialog: React.FC<RenderLinkDialogProps<LinkFormData>> = ({
  isDialogOpen,
  close,
  insert,
  data
}) => {
  const modalProps = useDefaultModalProps();
  const [form] = Form.useForm<LinkFormData>();
  const [formState, formActions] = useFormContext(form, data, data !== undefined);
  const { dialogContainer } = useContainer();
  const isSuperadmin = useIsSuperadmin();

  const handleInsert = () => {
    form.validateFields().then(formData => {
      insert(formData);
      close();
    });
  };

  const handleTypeChange = (value: SelectValue) => {
    const newVal: Partial<LinkFormData> = {
      type: value as LinkType,
      target: undefined,
      value: undefined
    };

    if (value === 'external') {
      newVal.target = '_blank';
    }

    if (value === 'internal') {
      newVal.target = '_self';
    }

    form.setFieldsValue(newVal);
  };

  const validateUrl = (rule: RuleObject, value: string) =>
    new Promise((resolve, reject) => {
      if (!isUrl(value)) {
        reject(new Error('No valid url'));
      } else {
        resolve(value);
      }
    });

  useEffect(() => {
    if (isDialogOpen) {
      formActions.reset(data, data !== undefined);
    }
  }, [isDialogOpen, data, formActions]);

  return (
    <Modal
      {...modalProps}
      visible={isDialogOpen}
      title={data ? <Trans>Edit Link</Trans> : <Trans>Add Link</Trans>}
      width={800}
      footer={
        <>
          <Button action="close" onClick={() => close()} />
          <Button action="insert" onClick={() => handleInsert()} disabled={!formState.isValid}>
            {data ? <Trans>Update</Trans> : <Trans>Insert</Trans>}
          </Button>
        </>
      }
    >
      <F.StyledForm
        form={form}
        onValuesChange={formActions.onValuesChange}
        onFieldsChange={formActions.onFieldsChange}
        layout="vertical"
        name="editor-link-form"
        initialValues={data}
      >
        <FormItem
          name="type"
          label={<Trans>Link type</Trans>}
          messageVariables={{ name: i18n._('Link type') }}
          registerField={formActions.registerField}
          rules={[{ required: true }]}
        >
          <Select onChange={handleTypeChange}>
            <Option value="internal">
              <Trans>Internal</Trans>
            </Option>
            <Option value="external">
              <Trans>External</Trans>
            </Option>
            <Option value="email">
              <Trans>Email</Trans>
            </Option>
            <Option value="phone">
              <Trans>Phone</Trans>
            </Option>
          </Select>
        </FormItem>
        {formState.data?.type === 'internal' && (
          <FormItem
            name="value"
            label={<Trans>Content</Trans>}
            messageVariables={{ name: i18n._('Content') }}
            registerField={formActions.registerField}
            rules={[{ required: true }]}
          >
            <TreeSelect
              showSearch
              style={{ width: '100%' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              placeholder={i18n._('Select doc id...')}
              allowClear
              listHeight={500}
              // treeDefaultExpandAll
              treeData={getTreeSelectDataFromDocsConfig(isSuperadmin)}
              getPopupContainer={() => dialogContainer}
            />
          </FormItem>
        )}

        {formState.data?.type === 'external' && (
          <FormItem
            name="value"
            label={<Trans>Url</Trans>}
            messageVariables={{ name: i18n._('Url') }}
            registerField={formActions.registerField}
            validateFirst
            rules={[
              { required: true },
              { validator: validateUrl, message: i18n._('This is not a valid url') }
            ]}
          >
            <Input />
          </FormItem>
        )}

        {formState.data?.type === 'email' && (
          <FormItem
            name="value"
            label={<Trans>Email address</Trans>}
            messageVariables={{ name: i18n._('Email address') }}
            registerField={formActions.registerField}
            validateFirst
            rules={[
              { required: true },
              {
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: i18n._('This is not a valid email address')
              }
            ]}
          >
            <Input />
          </FormItem>
        )}

        {formState.data?.type === 'phone' && (
          <FormItem
            name="value"
            label={<Trans>Phone number</Trans>}
            messageVariables={{ name: i18n._('Phone number') }}
            registerField={formActions.registerField}
            rules={[{ required: true }]}
          >
            <Input />
          </FormItem>
        )}

        {(formState.data?.type === 'external' || formState.data?.type === 'internal') && (
          <FormItem
            name="target"
            label={<Trans>Open link</Trans>}
            messageVariables={{ name: i18n._('Open link') }}
            registerField={formActions.registerField}
            rules={[{ required: true }]}
          >
            <Radio.Group>
              <Radio value="_self">
                <Trans>In same tab</Trans>
              </Radio>
              <Radio value="_blank">
                <Trans>In new tab</Trans>
              </Radio>
            </Radio.Group>
          </FormItem>
        )}

        <FormItem
          name="title"
          label={<Trans>Title attribute (showed on hover)</Trans>}
          messageVariables={{ name: i18n._('Title attribute') }}
          registerField={formActions.registerField}
        >
          <Input />
        </FormItem>
      </F.StyledForm>
    </Modal>
  );
};

export default EditorLinkDialog;
