import React from 'react';

import { Trans } from '@lingui/macro';
import { ReactFlowProvider } from 'react-flow-renderer';

import ReactFlowDiagram from '../reactFlowDiagram';

import * as S from './diagramFullScreenSc';

import ContainerProvider from 'components/app/components/containerProvider';
import useDomainName from 'hooks/useDomainName';

const DiagramFullScreen: React.FC = () => {
  const domainName = useDomainName();

  return (
    <ContainerProvider>
      <S.Container>
        <h4>
          {domainName} - <Trans>Entity/ Document diagram</Trans>
        </h4>
        <div>
          <ReactFlowProvider>
            <ReactFlowDiagram />
          </ReactFlowProvider>
        </div>
      </S.Container>
    </ContainerProvider>
  );
};

export default DiagramFullScreen;
