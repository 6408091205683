import React from 'react';

import { omit } from 'lodash';
import { Position } from 'react-flow-renderer';

import * as S from './edgeHandleSc';

export type EdgeHandleProps = {
  active: boolean;
  hidden?: boolean;
  type: 'source' | 'target';
  position: Position;
  id: string;
};

const EdgeHandle: React.FC<EdgeHandleProps> = props => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { active, hidden } = props;

  return <S.EdgeHandle {...omit(props, ['active', 'hidden'])} $active={active} hidden={hidden} />;
};

export default EdgeHandle;
