import React from 'react';

import * as P from 'components/_styled/pageSc';
import useDomainFromCache from 'hooks/useDomainFromCache';

const DomainSubTitle: React.FC = () => {
  const domain = useDomainFromCache();

  if (!domain) {
    return null;
  }

  return (
    <P.PageSubTitle>
      <a href={domain.uri} rel="noopener noreferrer" target="_blank">
        {domain.uri}
      </a>
    </P.PageSubTitle>
  );
};

export default DomainSubTitle;
