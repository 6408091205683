export enum JsonEditorActionTypes {
  SET_COLOR_THEME = 'jsonEditor/SET_COLOR_THEME',
  SET_FONT_SIZE = 'jsonEditor/SET_FONT_SIZE',
  SET_LINE_INDENT = 'jsonEditor/SET_LINE_INDENT',
  SET_MINI_MAP = 'jsonEditor/SET_MINI_MAP',
  RESET = 'jsonEditor/RESET'
}

export type JsonEditorColorTheme = 'BRIGHT' | 'DARK';
export type JsonEditorFontSize = 'BIG' | 'DEFAULT' | 'SMALL';
export type JsonEditorLineIndentSize = 'BIG' | 'DEFAULT' | 'SMALL';

export type JsonEditorState = Readonly<{
  colorTheme: JsonEditorColorTheme;
  fontSize: JsonEditorFontSize;
  indentSize: JsonEditorLineIndentSize;
  hasMinimap: boolean;
}>;
