import tw, { css, styled } from 'twin.macro';

export const SelectedInfo = styled.div(() => [
  css`
    ${tw`mt-6 font-500 flex flex-row`};

    p {
      ${tw`font-500`};

      &.path {
        ${tw`mr-12`};
      }
    }

    & span {
      ${tw`text-12 text-primary font-600 inline-block mr-6`};
    }
  `
]);

export const StyledErrorInfo = styled.div(() => [
  css`
    ${tw`mt-6`};
  `
]);
