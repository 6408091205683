import { ListQueryParams } from '@phoenix-systems/react-table/dist/types/types';

import { TriggersTableActionTypes, TriggersTableSingleView } from './triggersTable.types';

/* Action Definition */

type SetParamsAction = {
  type: TriggersTableActionTypes.SET_PARAMS;
  params?: Partial<ListQueryParams>;
};

type SetSingleViewAction = {
  type: TriggersTableActionTypes.SET_SINGLE_VIEW;
  view: TriggersTableSingleView;
};

type SetSelectedKeyAction = {
  type: TriggersTableActionTypes.SET_SELECTED_KEY;
  key?: string;
};

type ResetAction = {
  type: TriggersTableActionTypes.RESET;
};

/* Union Action Types */

export type TriggersTableActionCreators =
  | SetParamsAction
  | SetSingleViewAction
  | SetSelectedKeyAction
  | ResetAction;

/* Action Creators */

export const st_triggersTable_setParams = (params?: Partial<ListQueryParams>): SetParamsAction => ({
  type: TriggersTableActionTypes.SET_PARAMS,
  params
});

export const st_triggersTable_setSingleView = (
  view: TriggersTableSingleView
): SetSingleViewAction => ({
  type: TriggersTableActionTypes.SET_SINGLE_VIEW,
  view
});

export const st_triggersTable_setSelectedKey = (key?: string): SetSelectedKeyAction => ({
  type: TriggersTableActionTypes.SET_SELECTED_KEY,
  key
});

export const st_triggersTable_reset = (): ResetAction => ({
  type: TriggersTableActionTypes.RESET
});
