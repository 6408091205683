import React, { useCallback, useEffect, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { getRoute } from '@phoenix-systems/react-router';
import { Table } from '@phoenix-systems/react-table';
import {
  CustomColumnType,
  ListQueryParams,
  TableRef
} from '@phoenix-systems/react-table/dist/types/types';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { useDispatch, useSelector } from 'react-redux';
import { useImmer } from 'use-immer';

import TriggersTableSingle from './triggersTableSingle';

import * as T from 'components/_styled/tableSc';
import TriggerActions from 'components/trigger/actions';
import TriggerPlay from 'components/trigger/components/triggerPlay';
import Loader from 'components/ui/loader';
import dateFormat from 'config/dateTime';
import tableLocales from 'config/langauge/table.locales';
import routes from 'config/routes/routes';
import useDomainName from 'hooks/useDomainName';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';
import useIsDomain from 'hooks/useIsDomain';
import {
  Trigger,
  TriggerPeriodicity,
  TriggerTaskType,
  useTriggers
} from 'services/api/domain/trigger';
import history from 'services/history';
import {
  st_triggersTable_setParams,
  st_triggersTable_setSelectedKey
} from 'services/store/triggersTable/triggersTable.actions';
import {
  st_triggersTable_getParams,
  st_triggersTable_getSelectedKey
} from 'services/store/triggersTable/triggersTable.selectors';

type Filter = {
  text: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  value: any;
};

type TriggersTableState = {
  data: Trigger[];
  isInitialized: boolean;
};

const TriggersTable: React.FC = () => {
  const isDomain = useIsDomain();
  const tableRef = useRef<TableRef>(null);
  const domainName = useDomainName();

  const dispatch = useDispatch();
  const params = useSelector(st_triggersTable_getParams);
  const selectedKey = useSelector(st_triggersTable_getSelectedKey);
  const [state, setState] = useImmer<TriggersTableState>({
    data: [],
    isInitialized: false
  });
  const goto = useGoToDomainRoute();

  const triggersQuery = useTriggers({ domainName });

  const handleSelectChange = (selectedRowKeys: React.Key[]) => {
    dispatch(st_triggersTable_setSelectedKey(selectedRowKeys[0] as string));
  };

  const handleRemoveSelectedItem = () => {
    dispatch(st_triggersTable_setSelectedKey(undefined));
  };

  const handleGotoTask = useCallback((data: Trigger) => {
    if (data.taskDomain && data.taskScheme && data.taskName && data.taskType) {
      switch (data.taskType) {
        case 'SQLDBImport':
          history.push(
            getRoute(
              routes.domain.dataManagement.importFromDb.single,
              data.taskDomain,
              data.taskScheme,
              data.taskName
            )
          );

          // eslint-disable-next-line no-useless-return
          return;

        case 'CSVFileImport':
          history.push(
            getRoute(
              routes.domain.dataManagement.importFromCsv.single,
              data.taskDomain,
              data.taskScheme,
              data.taskName
            )
          );

          // eslint-disable-next-line no-useless-return
          return;

        default:
          // eslint-disable-next-line no-useless-return
          return;
      }
    }
  }, []);

  const handleGotoTrigger = useCallback(
    (triggerName: string) => {
      if (isDomain) {
        goto(routes.domain.scheduler.single, { path: triggerName });

        return;
      }

      history.push(getRoute(routes.scheduler.single, triggerName));
    },
    [isDomain, goto]
  );

  const getSchemaFilters = useCallback(() => {
    const schemes: Filter[] = [];
    triggersQuery.data?.forEach(trigger => {
      if (!schemes.find(s => s.value === trigger.taskScheme)) {
        schemes.push({
          text: trigger.taskScheme,
          value: trigger.taskScheme
        });
      }
    });

    return schemes;
  }, [triggersQuery.data]);

  const getDomainsFilter = useCallback(() => {
    const domains: Filter[] = [];
    triggersQuery.data?.forEach(trigger => {
      if (!domains.find(d => d.value === trigger.taskDomain)) {
        domains.push({
          text: trigger.taskDomain,
          value: trigger.taskDomain
        });
      }
    });

    return domains;
  }, [triggersQuery.data]);

  const getPeriodicityFilters = useCallback(() => {
    const ps: Filter[] = [];
    triggersQuery.data?.forEach(trigger => {
      if (!ps.find(d => d.value === trigger.periodicity)) {
        ps.push({
          text: trigger.periodicity.toLowerCase().replaceAll('_', ' '),
          value: trigger.periodicity
        });
      }
    });

    return ps;
  }, [triggersQuery.data]);

  const getColumns = useCallback(() => {
    const cls: CustomColumnType<Trigger>[] = [
      {
        title: i18n._('Name'),
        dataIndex: 'name',
        key: 'name',
        className: 'bold',
        sorter: (a, b) => a.name.localeCompare(b.name),
        // eslint-disable-next-line react/display-name
        render: (value: string) => (
          <>
            <Highlighter
              searchWords={params?.searchText ? [params.searchText] : []}
              textToHighlight={value}
              autoEscape
            />
            <T.EditBtn
              className="edit-btn"
              type="link"
              icon={
                <FontAwesomeIcon icon={['fas', 'pen']} onClick={() => handleGotoTrigger(value)} />
              }
            />
          </>
        )
      },
      {
        title: i18n._('Id'),
        dataIndex: 'id',
        key: 'id',
        disabled: true,
        ellipsis: true,
        sorter: (a, b) => a.id.localeCompare(b.id)
      },
      {
        title: i18n._('Task domain'),
        dataIndex: 'taskDomain',
        key: 'taskDomain',
        disabled: isDomain,
        ellipsis: true,
        sorter: !isDomain ? (a, b) => a.taskDomain.localeCompare(b.taskDomain) : undefined,
        filters: !isDomain ? getDomainsFilter() : undefined
      },
      {
        title: i18n._('Task name'),
        dataIndex: 'taskName',
        key: 'taskName',
        ellipsis: true,
        responsiveDisabled: {
          max: 700
        },
        sorter: (a, b) => a.taskName.localeCompare(b.taskName),
        // eslint-disable-next-line react/display-name
        render: (value, record) => {
          if (!value) {
            return null;
          }

          return (
            <>
              {value.toLowerCase().replaceAll('_', ' ')}
              <T.EditBtn
                className="edit-btn"
                icon={<FontAwesomeIcon icon={['fas', 'pen']} />}
                onClick={() => handleGotoTask(record)}
              />
            </>
          );
        }
      },
      {
        title: i18n._('Task schema'),
        dataIndex: 'taskScheme',
        key: 'taskScheme',
        ellipsis: true,
        sorter: (a, b) => a.taskScheme.localeCompare(b.taskScheme),
        filters: getSchemaFilters(),
        responsiveDisabled: {
          max: 1000
        }
      },
      {
        title: i18n._('Task type'),
        dataIndex: 'taskType',
        key: 'taskType',
        render: (value: TriggerTaskType) => {
          if (!value) {
            return null;
          }

          if (value === 'NONE') {
            return 'none';
          }

          if (value === 'SQLDBImport') {
            return 'SQL db import';
          }

          if (value === 'CSVFileImport') {
            return 'CSV file import';
          }

          return null;
        },
        responsiveDisabled: {
          max: 700
        },
        filters: [
          { text: 'none', value: 'NONE' },
          { text: 'SQL db import', value: 'SQLDBImport' }
        ],
        sorter: (a, b) => a.taskType?.localeCompare(b.taskType)
      },
      {
        title: i18n._('Periodicity'),
        dataIndex: 'periodicity',
        key: 'periodicity',
        width: 125,
        disabled: true,
        render: (value: TriggerPeriodicity) => {
          if (!value) {
            return null;
          }

          return value.toLowerCase().replaceAll('_', ' ');
        },
        sorter: (a, b) => a.periodicity.localeCompare(b.periodicity),
        filters: getPeriodicityFilters()
      },
      {
        title: i18n._('Time value'),
        dataIndex: 'timeValue',
        key: 'timeValue',
        width: 115,
        disabled: true,
        sorter: (a, b) => a.periodicity.localeCompare(b.periodicity)
      },
      {
        title: i18n._('Start time'),
        dataIndex: 'startTime',
        key: 'startTime',
        disabled: true,
        // eslint-disable-next-line react/display-name
        render: (value: string) => {
          if (!value) {
            return null;
          }

          return moment(value).format(dateFormat.dateTime);
        },
        sorter: (a, b) => a.active?.toString().localeCompare(b.active?.toString())
      },
      {
        title: i18n._('Last execution'),
        dataIndex: 'lastTime',
        key: 'lastTime',
        // eslint-disable-next-line react/display-name
        render: (value: string) => {
          if (!value) {
            return null;
          }

          return moment(value).fromNow();
        },
        responsiveDisabled: {
          max: 1000
        },
        sorter: (a, b) => a.active?.toString().localeCompare(b.active?.toString())
      },
      {
        title: i18n._('Active'),
        dataIndex: 'active',
        key: 'active',
        ellipsis: true,
        width: 100,
        // eslint-disable-next-line react/display-name
        render: (value: boolean, record) => <TriggerPlay active={value} data={record} />,
        sorter: (a, b) => a.active?.toString().localeCompare(b.active?.toString()),
        filters: [
          { text: 'true', value: true },
          { text: 'false', value: false }
        ]
      },
      {
        title: <FontAwesomeIcon icon={['fas', 'cog']} className="setting-icon" />,
        key: 'actions',
        width: 50,
        className: 'actions',
        // eslint-disable-next-line react/display-name
        render: (value: string, record) => (
          <TriggerActions
            mode="ICON_BUTTON"
            data={record as Trigger}
            popoverPlacement="leftTop"
            className="edit-btn"
          />
        ),
        hiddenInConfig: true
      }
    ];

    return cls;
  }, [
    params?.searchText,
    isDomain,
    getDomainsFilter,
    getPeriodicityFilters,
    getSchemaFilters,
    handleGotoTask,
    handleGotoTrigger
  ]);

  useEffect(() => {
    if (tableRef.current && params && !state.isInitialized) {
      tableRef.current.setQueryParams(params);
      setState(draft => {
        draft.isInitialized = true;
      });
    }
  }, [params, state.isInitialized, setState]);

  useEffect(() => {
    if (params?.searchText && params.searchText !== '') {
      const matches = triggersQuery.data?.filter(
        d => d.name.search(new RegExp(params.searchText || '', 'gi')) !== -1
      );

      if (matches) {
        setState(draft => {
          draft.data = matches || [];
        });

        return;
      }
      setState(draft => {
        draft.data = triggersQuery.data || [];
      });
    } else {
      setState(draft => {
        draft.data = triggersQuery.data || [];
      });
    }
  }, [triggersQuery.data, params?.searchText, setState]);

  return (
    <>
      <T.StyledTable className="domain">
        <div>
          <Table
            ref={tableRef}
            table={{
              dataSource: state.data,
              loading: triggersQuery.isFetching,
              size: 'small',
              rowKey: 'name',
              columns: getColumns(),
              rowSelection: {
                type: 'radio',
                onChange: handleSelectChange,
                selectedRowKeys: selectedKey !== undefined ? [selectedKey] : []
              },
              onRow: (record: Trigger) => ({
                onClick: e => {
                  const isEditBtn =
                    (e.target as HTMLElement).closest('.edit-btn') !== null ||
                    (e.target as HTMLElement).classList.contains('edit-btn') ||
                    (e.target as HTMLElement).closest('.ant-popover') !== null;

                  if (!isEditBtn) {
                    dispatch(st_triggersTable_setSelectedKey(record.name));
                  }
                },
                onDoubleClick: () => {
                  handleGotoTrigger(record.name);
                }
              }),

              pagination: {
                pageSizeOptions: ['10', '20', '50'],
                pageSize: params?.pageSize || 20,
                current: params?.currentPage || 1,
                showSizeChanger: true
              }
            }}
            defaultTableContainerHeight="calc(100% - 440px)"
            fillHeight
            layout="single-split"
            topNavElements={{
              search: {
                value: params?.searchText,
                placeholder: i18n._('Search triggers...')
              },
              totalCount: {
                icon: <FontAwesomeIcon icon={['fas', 'clock']} />,
                loader: <Loader size={12} />
              },
              reload: {
                onChange: () => {
                  triggersQuery.refetch();
                }
              },
              resetParams: true
            }}
            onRemoveSelectedItem={handleRemoveSelectedItem}
            locales={{
              '1 item selected': i18n._('1 trigger selected'),
              'items selected': i18n._('triggers selected'),
              'Nothing selected': i18n._('No trigger selected'),
              ...tableLocales
            }}
            renderSingle={(selectedItems: Trigger[]) => (
              <>
                <TriggersTableSingle data={selectedItems} />
              </>
            )}
            onQueryParamsChange={(currentParams: Partial<ListQueryParams>) => {
              dispatch(st_triggersTable_setParams(currentParams));
            }}
            activeFilters={{ enabled: true, placement: 'default' }}
          />
        </div>
      </T.StyledTable>
    </>
  );
};

export default TriggersTable;
