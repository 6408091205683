import { Button, Divider } from 'antd';
import tw, { css, styled } from 'twin.macro';

import {
  QueryDocumentsLayout,
  QueryDocumentsQueryMode
} from 'services/store/entityDocument/entityDocument.types';

export const Container = styled.div(() => [
  css`
    ${tw`h-full flex flex-col flex-1 overflow-hidden`};
    height: calc(100% - 10px);
  `
]);

export const LoaderContainer = styled.div(() => [
  css`
    ${tw`flex flex-col h-full absolute left-0 right-0`};
    left: 40px;
  `
]);

export const SelectEntityWrapper = styled.div(() => [
  css`
    ${tw`flex flex-row items-center h-42`};
  `
]);

export const EntitySelector = styled.div(() => [
  css`
    ${tw`w-full h-full`};
    max-width: 600px;
  `
]);

export const LayoutSwitch = styled.div(() => [
  css`
    ${tw`ml-auto bg-light-gray-active-200 p-4 rounded-6 pl-12`};

    & .label {
      ${tw`font-600 mr-12`};
    }

    & svg {
      ${tw`mr-8`};
    }
  `
]);

type HeaderButtonProps = {
  isList?: boolean;
};
export const HeaderButton = styled.div<HeaderButtonProps>(({ isList }) => [
  css`
    ${tw`bg-light-gray-active-200 h-30 rounded-6 px-6 ml-12 flex flex-row items-center justify-center`};

    & .ant-btn {
      ${tw`m-0 p-0 w-18 h-20 `};
      transition: none !important;

      & svg {
        ${tw`flex items-center justify-center`};
      }
    }

    &:hover {
      ${tw`bg-primary text-white`};

      & .ant-btn,
      .ant-btn:hover {
        ${tw`bg-primary text-white`};
      }
    }
  `,

  isList &&
    css`
      & .ant-btn {
        ${tw`w-auto block `};

        & svg {
          ${tw`inline-block`};
        }
      }
    `
]);

export const StyledDivider = styled(Divider)(() => [
  css`
    ${tw`mb-8 mt-4`};
  `
]);

type QueryContainerProps = {
  layout: QueryDocumentsLayout;
  queryMode?: QueryDocumentsQueryMode;
};

export const QueryContainer = styled.div<QueryContainerProps>(({ layout }) => [
  layout === 'VERTICAL' &&
    css`
      ${tw`flex flex-row h-full`};
    `,

  layout === 'HORIZONTAL' &&
    css`
      ${tw`flex flex-col h-full`};
    `
]);

type QueryContainerInnerProps = {
  layout: QueryDocumentsLayout;
  isCollapsed?: boolean;
};
export const QueryContainerInner = styled.div<QueryContainerInnerProps>(
  ({ layout, isCollapsed }) => [
    layout === 'VERTICAL' &&
      css`
        &.query {
          ${tw`flex-none relative flex flex-col`};
          width: ${isCollapsed ? '3rem' : 'calc(50% - 20px)'};

          & .json-editor {
            ${tw`h-full`};
          }
        }

        &.query-btn {
          ${tw`flex-none flex items-center justify-center`};
          width: 40px;
        }

        &.response {
          ${tw`flex flex-col`};
          width: ${isCollapsed ? 'calc(100% - 20px)' : 'calc(50% - 20px)'};

          & .json-editor {
            ${tw`h-full relative`};
          }
        }
      `,

    layout === 'HORIZONTAL' &&
      css`
        ${tw`h-full `};

        &.query {
          ${tw`flex-none flex flex-col`};
          height: ${isCollapsed ? '3rem' : 'calc(50% - 20px)'};

          & .json-editor {
            ${tw`h-full`};
          }
        }

        &.query-btn {
          ${tw`flex-none flex items-center justify-center`};
          height: 40px;
        }

        &.response {
          ${tw`relative flex flex-col`};

          height: ${!isCollapsed ? 'calc(50% - 20px)' : ' calc(100% - 20px)'};

          & .json-editor {
            ${tw`h-full relative`};
          }
        }
      `,

    isCollapsed &&
      css`
        & .query-label,
        .switch {
          ${tw`hidden`};
        }

        & .collapse {
          ${tw`ml-0`};
        }

        & .content {
          ${tw`bg-light-gray-200 cursor-pointer hover:bg-light-gray-active-200 transition-all`};

          & > * {
            ${tw`hidden`};
          }
        }
      `,

    isCollapsed &&
      layout === 'HORIZONTAL' &&
      css`
        &.query {
          & .content {
            ${tw`hidden`};
          }

          & .collapse {
            ${tw`w-full cursor-pointer`};
          }
        }
      `
  ]
);

export const Header = styled.div(() => [
  css`
    ${tw`flex flex-row items-center h-42 mb-12`};
  `
]);

export const QueryInfo = styled.span(() => [
  css`
    ${tw` ml-12 text-primary `};
  `
]);

export const StyledLabel = styled.div(() => [
  css`
    ${tw`font-600 my-6`};
  `
]);

export const QueryForm = styled.div(() => [
  css`
    ${tw`p-12 h-full border border-ant-border-gray `};
  `
]);

export const QueryWrapper = styled.div(() => [
  css`
    ${tw`pb-30 overflow-hidden flex flex-col flex-1`};

    & > div.query {
      ${tw`h-full relative overflow-hidden`};

      & .input-json {
        ${tw`h-full relative`};
      }
    }

    & > div.result {
      ${tw`h-full relative overflow-hidden`};

      & .input-json {
        ${tw`h-full relative`};
      }
    }

    & > div.buttons {
      ${tw`my-24`};
    }
  `
]);

export const ListWrapper = styled.div(() => [
  css`
    ${tw`h-full relative overflow-hidden border border-ant-border-gray`};

    & > div {
      ${tw`absolute left-0 right-0 bottom-0 top-0`};
    }

    & table td {
      ${tw`text-13 font-mono align-top `};

      & .ant-input {
        ${tw` p-0 font-mono text-13 bg-transparent`};

        border: none !important;
        outline: none !important;
        box-shadow: none !important;
      }
    }

    & .top-nav-btn.table-config {
      ${tw`mr-6`};
    }
  `
]);

export const ResultInfo = styled.div(() => [
  css`
    & p {
      & span {
        ${tw`text-primary text-12 font-600 inline-block`};
        min-width: 12rem;
      }
    }
  `
]);

export const ResultInfoBtn = styled(Button)(() => [
  css`
    ${tw`h-28 px-6 py-0`};
  `
]);
