import { Drawer } from 'antd';
import tw, { css, styled } from 'twin.macro';

export const StyledDrawer = styled(Drawer)(() => [
  css`
    & .ant-drawer-header {
      ${tw`p-0 `};
    }

    & .ant-drawer-close {
      ${tw`focus:outline-none outline-none text-20 text-white hover:text-white`};
      top: -12px;
    }

    & .ant-drawer-content-wrapper {
      ${tw`border-l border-primary-200`};
      max-width: 900px;
    }

    & .ant-drawer-body {
      ${tw`p-0`};
    }

    & .ant-drawer-footer {
      ${tw`bg-light-gray border-t border-ant-border-gray py-12 px-24`};

      & .ant-btn {
        ${tw`mr-12 hover:text-primary `};
      }
    }
  `
]);

export const DrawerTop = styled.div(() => [
  css`
    ${tw`bg-light-gray text-primary font-700 px-24 py-10 text-13 border-b border-ant-border-gray `};

    & a:hover {
      ${tw`text-secondary`};
    }
  `
]);

export const DrawerHead = styled.div(() => [
  css`
    ${tw`flex flex-col`};
  `
]);

export const DrawerMain = styled.div(() => [
  css`
    ${tw`h-full`};
  `
]);

export const DrawerMainInner = styled.div(() => [
  css`
    ${tw`px-32 py-24 pl-40`};
  `
]);

export const DrawerTitle = styled.div(() => [
  css`
    ${tw`flex flex-row  relative items-center`};

    & .title {
      ${tw`w-full`};

      & h2 {
        ${tw`font-700 text-24 text-primary py-20`};
      }
    }
  `
]);

export const NavButton = styled.div(() => [
  css`
    ${tw`flex items-center justify-center h-full p-4`};

    & .ant-btn.ant-btn-icon-only {
      ${tw` opacity-50 hover:opacity-100  `};

      &[disabled] {
        visibility: hidden;
      }

      & svg {
        ${tw`text-24`};
      }
    }
  `
]);
