import React, { ChangeEvent, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import {
  CollectionEditor,
  CollectionEditorDataItem,
  FormItem,
  useFormContext
} from '@phoenix-systems/react-form';
import { Form, Input } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { last } from 'lodash';

import EntityFieldFormDialog from '../entityFieldFormDialog';

import * as S from './entityNodeFormDialogSc';

import * as F from 'components/_styled/formSc';
import DocsTooltip from 'components/docs/components/docsTooltip';
import { EntityFormData } from 'components/domain/components/domainDiagram/types';
import Button from 'components/ui/button';
import collectionEditorLocales from 'config/langauge/collectionEditor.locales';
import useDefaultModalProps from 'hooks/useDefaultModalProps';
import { EntityNodeField } from 'services/api/domain/domainDiagram';
import { DocId } from 'services/api/domain/hint';

type EntityNodeFormDialogProps = {
  data: EntityFormData;
  mode: 'CREATE' | 'EDIT';
  isOpen: boolean;
  insert: (data: EntityFormData) => void;
  close: () => void;
};

const EntityNodeFormDialog: React.FC<EntityNodeFormDialogProps> = ({
  mode,
  data,
  isOpen,
  insert,
  close
}) => {
  const [form] = Form.useForm<EntityFormData>();
  const [formState, formActions] = useFormContext<EntityFormData>(form, data, data !== undefined);
  const modalProps = useDefaultModalProps();

  const handleClassNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const parts = event.target.value.split('.');
    form.setFieldsValue({ ...form.getFieldsValue(), simpleClassName: last(parts) });
  };

  const handleInsert = () => {
    form.validateFields().then(formData => {
      insert(formData);
    });
  };

  const handleClose = () => {
    close();
    formActions.reset();
  };

  useEffect(() => {
    if (data) {
      formActions.reset(data, true);

      return;
    }

    formActions.reset(data, false);
  }, [data, isOpen, formActions]);

  return (
    <Modal
      {...modalProps}
      title={
        mode === 'CREATE' ? (
          <Trans>Add Entity</Trans>
        ) : (
          <Trans>Edit Entity: {data.simpleClassName}</Trans>
        )
      }
      visible={isOpen}
      width={850}
      footer={
        <>
          <Button onClick={handleClose} action="cancel" />
          <Button
            action={mode === 'CREATE' ? 'insert' : 'save'}
            onClick={handleInsert}
            formState={formState}
          />
        </>
      }
    >
      <F.StyledForm
        $labelWidth={168}
        form={form}
        layout="horizontal"
        onFieldsChange={formActions.onFieldsChange}
        onValuesChange={formActions.onValuesChange}
        name={`entity-form-${data?.className || 'create'}`}
        colon={false}
      >
        <FormItem
          label={
            <>
              <Trans>Full class name</Trans>
              <DocsTooltip docId={DocId.DOMAIN_DIAGRAM_ENTITY_FULL_CLASS_NAME} />
            </>
          }
          messageVariables={{ name: i18n._(t`Full class name`) }}
          name="className"
          rules={[
            { required: true },
            {
              pattern: /^([a-z]{2,3}(.[a-zA-Z][a-zA-Z_$0-9]*)*).([A-Z][a-zA-Z_$0-9]*)$/,
              message: i18n._(t`This is not a fully qualified class name`)
            }
          ]}
          registerField={formActions.registerField}
        >
          <Input onChange={handleClassNameChange} />
        </FormItem>
        <FormItem
          help={i18n._('Read only: The "simple class name" is derived from "full class name"')}
          label={
            <>
              <Trans>Simple class name</Trans>
              <DocsTooltip docId={DocId.DOMAIN_DIAGRAM_ENTITY_SIMPLE_CLASS_NAME} />
            </>
          }
          messageVariables={{ name: i18n._(t`Simple class name`) }}
          name="simpleClassName"
          rules={[{ required: true }]}
          registerField={formActions.registerField}
        >
          <Input readOnly />
        </FormItem>

        <FormItem
          label={
            <>
              <Trans>Fields</Trans>
              <DocsTooltip docId={DocId.DOMAIN_DIAGRAM_FIELD} />
            </>
          }
          name="fields"
          registerField={formActions.registerField}
        >
          <CollectionEditor
            locales={{ ...collectionEditorLocales, 'Add item': i18n._('Add field') }}
            renderDialog={params => <EntityFieldFormDialog {...params} nodeData={data} />}
            renderItem={(item: CollectionEditorDataItem<EntityNodeField>) => (
              <S.DialogFildListItem>
                {item.value.name}
                <div>
                  <span>{item.value.type}</span>
                  {item.value.uniqueId && <FontAwesomeIcon icon={['fas', 'key']} />}
                </div>
              </S.DialogFildListItem>
            )}
          />
        </FormItem>
      </F.StyledForm>
    </Modal>
  );
};

export default EntityNodeFormDialog;
