import { Popover } from 'antd';
import tw, { css, styled } from 'twin.macro';

const DEFAULT_TRANSLATE_Y = -10;
const DEFAULT_TRANSLATE_X = 0;

export const InfoPopover = styled(Popover)(() => [
  css`
    ${tw`text-14 ml-4 mt-4 font-700 text-gray-600 transition-all duration-300 hover:text-secondary focus: outline-none`};
  `
]);

type InfoButtonProps = {
  translateX?: number;
  translateY?: number;
};
export const InfoButton = styled.button<InfoButtonProps>(({ translateX, translateY }) => [
  css`
    ${tw`focus:outline-none outline-none text-12 active:outline-none opacity-80 hover:opacity-100 hover:shadow`};

    transform: translateX(${translateX !== undefined ? translateX : DEFAULT_TRANSLATE_X}px);
    transform: translateY(${translateY !== undefined ? translateY : DEFAULT_TRANSLATE_Y}px);
  `
]);

export const PopupContainer = styled.div(() => [
  css`
    & .ant-popover .ant-popover-content {
      ${tw`p-0 border border-primary-300 shadow-lg rounded-2 overflow-hidden bg-white`};
      width: 360px;
    }

    & .ant-popover-inner-content {
      ${tw`p-0`};
    }
  `
]);

export const StyledDocsTooltipContent = styled.div(() => [
  css`
    ${tw``};
  `
]);

export const DocsTooltipTitle = styled.h4(() => [
  css`
    ${tw`text-13 p-0 m-0 font-700 px-12 pt-10 text-primary`};
  `
]);

export const DocsTooltipMessage = styled.p(() => [
  css`
    ${tw`text-13 text-black p-0 m-0  px-12 py-8`};

    & b {
      ${tw`text-primary`};
    }
  `
]);

export const DocsTooltipButtons = styled.div(() => [
  css`
    ${tw`px-12 pb-8 `};

    & button {
      ${tw`text-11 p-0 pb-4 mr-6 hover:text-secondary h-24 text-primary`};
    }
  `
]);
