import React from 'react';

import { Menu } from 'antd';
import { useSelector } from 'react-redux';

import DeleteDomainAction from './deleteDomainAction';
import DomainActionItem from './domainActionItem';

import ContextNavigation from 'components/app/components/contextNavigation';
import Button from 'components/ui/button';
import getMainNavigationConfig from 'config/navigation/mainNavigation.config';
import routes from 'config/routes/routes';
import { Domain } from 'services/api/domain/domain';
import { st_app_getLanguage } from 'services/store/app/app.selectors';

type DomainActionsProps = {
  data: Domain;
  mode: 'BUTTON' | 'ICON_BUTTON';
};

const DomainActions: React.FC<DomainActionsProps> = ({ data, mode }) => {
  const language = useSelector(st_app_getLanguage);

  const navConfig = getMainNavigationConfig({
    domainName: data.domainName,
    schemaName: data.defaultSchema,
    basePath: routes.domain.root,
    language
  });

  return (
    <>
      <ContextNavigation
        trigger={<Button action="actions" isIconOnly={mode === 'ICON_BUTTON'} />}
        placement="rightTop"
      >
        {navConfig.children?.map(item => (
          <DomainActionItem config={item} domain={data} key={item.id} />
        ))}
        {data.deletable && (
          <>
            <Menu.Divider />
            <DeleteDomainAction mode="MENU_ITEM" data={data} />
          </>
        )}
      </ContextNavigation>
    </>
  );
};

export default DomainActions;
