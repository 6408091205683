import React, { memo, useState } from 'react';

import { Trans } from '@lingui/macro';
import { Popover } from 'antd';
import { Position, useStoreActions } from 'react-flow-renderer';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import EntityContextNavigation from '../contextNavigation/entityContextNavigation';
import EdgeHandle from '../edgeHandle';
import EntityField from '../entityField/entityField';

import * as S from './entityNodeSc';

import useContainer from 'components/app/components/containerProvider/useContainer';
import AddFieldAction from 'components/domain/actions/domainDiagramActions/addFieldAction';
import { getHandleId } from 'components/domain/components/domainDiagram/utils';
import { EntityNodeData, HandleType } from 'services/api/domain/domainDiagram';
import {
  st_domainDiagram_setActiveHandles,
  st_domainDiagram_setActiveNode,
  st_domainDiagram_setContextNavOpen
} from 'services/store/domainDiagram/domainDiagram.actions';
import {
  st_domainDiagram_getActiveHandles,
  st_domainDiagram_getActiveNode,
  st_domainDiagram_getDialogIsOpen,
  st_domainDiagram_getElements
} from 'services/store/domainDiagram/domainDiagram.selectors';

export type EntityNodeProps = {
  data: EntityNodeData;
  selected: boolean;
  id: string;
  isConnectable: boolean;
  isDragging: boolean;
  type: 'entityNode';
  xPos: number;
  yPos: number;
  sourcePosition?: Position;
  targetPosition?: Position;
};

// eslint-disable-next-line react/display-name
const EntityNode: React.FC<EntityNodeProps> = memo(props => {
  const { domainDiagramContainer } = useContainer();
  const [isVisibleNav, setNavVisible] = useState(false);
  const [isHovered, setHovered] = useState(false);
  const dispatch = useDispatch();
  const setSelectedElements = useStoreActions(actions => actions.setSelectedElements);
  const elements = useSelector(st_domainDiagram_getElements);
  const isOpenDialog = useSelector(st_domainDiagram_getDialogIsOpen);
  const activeHandles = useSelector(st_domainDiagram_getActiveHandles);
  const handleOutId = getHandleId(HandleType.ENTITY_OUT, props.data.className) || uuid();
  const handleInId = getHandleId(HandleType.ENTITY_IN, props.data.className) || uuid();
  const activeNode = useSelector(st_domainDiagram_getActiveNode);

  if (!props.data) {
    return null;
  }

  const handleVisibilityChange = (visible: boolean) => {
    setNavVisible(visible);
    dispatch(st_domainDiagram_setContextNavOpen(visible));

    if (visible) {
      setSelectedElements(elements.filter(el => el.id === props.id));
    }
  };

  const handleMouseOver = () => {
    dispatch(st_domainDiagram_setActiveNode(props.id));
    dispatch(st_domainDiagram_setActiveHandles([handleOutId]));
  };

  const handleMouseOut = () => {
    dispatch(st_domainDiagram_setActiveNode(undefined));
    dispatch(st_domainDiagram_setActiveHandles([]));
  };

  return (
    <>
      <S.Node
        active={props.selected || props.isDragging ? true : false || activeNode === props.id}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <Popover
          content={
            <EntityContextNavigation data={props.data} nodeId={props.id} setOpen={setNavVisible} />
          }
          getPopupContainer={() => domainDiagramContainer}
          placement="rightTop"
          title={
            <>
              <Trans>Entity</Trans>:{props.data.simpleClassName}
            </>
          }
          trigger="contextMenu"
          visible={isVisibleNav}
          onVisibleChange={handleVisibilityChange}
        >
          <S.Head onMouseOut={handleMouseOut} onMouseOver={handleMouseOver}>
            <S.Title title={props.data.className}>{props.data.simpleClassName}</S.Title>
            <EdgeHandle
              active={activeHandles.includes(handleInId)}
              id={handleInId}
              position={Position.Left}
              type="target"
            />
            <EdgeHandle
              active={activeHandles.includes(handleOutId)}
              id={handleOutId}
              position={Position.Right}
              type="source"
            />
          </S.Head>
        </Popover>
        <S.Body>
          <S.FieldsUl>
            {props.data.fields.map((field, index) => (
              <EntityField
                key={field.name}
                data={field}
                index={index}
                nodeData={props.data}
                nodeId={props.id}
              />
            ))}
          </S.FieldsUl>
          <AddFieldAction
            data={props.data}
            nodeId={props.id}
            type="ENTITY_NODE"
            visible={isHovered && !isOpenDialog}
          />
        </S.Body>
      </S.Node>
    </>
  );
});

export default EntityNode;
