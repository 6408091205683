import { i18n } from '@lingui/core';
import { ModalProps } from 'antd';

import useContainer from 'components/app/components/containerProvider/useContainer';

const useDefaultModalProps = (): ModalProps => {
  const { dialogContainer } = useContainer();

  return {
    centered: true,
    width: 750,
    closable: false,
    okText: i18n._('Ok'),
    cancelText: i18n._('Cancel'),
    getContainer: () => dialogContainer
  };
};

export default useDefaultModalProps;
