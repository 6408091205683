import apiClient from '../../apiClient';
import { User } from '../user';

import { OrgUnit, UpdateOrgunitParams } from './orgUnit.types';

const BASE_PATH = 'orgunit';

/**
 * GET /api/orgunit/{orgunitId}
 */
export const api_getOrgUnitById = async (id: string): Promise<OrgUnit> => {
  const { data } = await apiClient.get(`/${BASE_PATH}/${id}`);

  return data;
};

/**
 * GET /api/orgunit/byname/{orgunitName}
 */
export const api_getOrgUnitByName = async (orgUnitName: string): Promise<OrgUnit> => {
  const { data } = await apiClient.get(`/${BASE_PATH}/byname/${orgUnitName}`);

  return data;
};

/**
 * GET /api/orgunit
 */
export const api_getOrgUnits = async (): Promise<OrgUnit[]> => {
  const { data } = await apiClient.get(`/${BASE_PATH}`);

  return data;
};

/**
 * PUT /api/orgunit/add/{userid}/{orgunitId}
 */
export const api_updateOrgUnit = async (params: UpdateOrgunitParams): Promise<OrgUnit> => {
  const { data } = await apiClient.put(`/${BASE_PATH}`, params.data);

  for (let i = 0; i < params.adminsAdded.length; i++) {
    // Get num of each fruit
    await apiClient.put(`/${BASE_PATH}/add/${params.adminsAdded[i]}/${data.name}`);
  }

  for (let i = 0; i < params.adminsRemoved.length; i++) {
    // Get num of each fruit
    await apiClient.put(`/${BASE_PATH}/remove/${params.adminsRemoved[i]}/${data.name}`);
  }

  return data;
};

/**
 * POST /api/orgunit
 */
export const api_createOrgUnit = async (payload: OrgUnit): Promise<OrgUnit> => {
  const { data } = await apiClient.post(`/${BASE_PATH}`, payload);

  return data;
};

/**
 * DELETE /api/orgunit/{orgunitName}
 */
export const api_deleteOrgUnitByName = async (orgunitName: string): Promise<void> => {
  const { data } = await apiClient.delete(`/${BASE_PATH}/${orgunitName}`);

  return data;
};

/**
 * GET /api/orgunit/users/byname/{orgunit}
 */
export const api_getAdminsByOrgUnit = async (orgunitName: string): Promise<User[]> => {
  const { data } = await apiClient.get(`/${BASE_PATH}/users/byname/${orgunitName}`);

  return data;
};
