import addIconsFab from './icons.fab';
import addIconsFal from './icons.fal';
import addIconsFar from './icons.far';
import addIconsFas from './icons.fas';
// import * as fab  from '@fortawesome/free-brands-svg-icons';
// import { fad } from '@fortawesome/pro-duotone-svg-icons';

const initIcons = (): void => {
  addIconsFas();
  addIconsFal();
  addIconsFar();
  addIconsFab();
};

export default initIcons;
