import React, { useCallback, useEffect, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { Table } from '@phoenix-systems/react-table';
import {
  CustomColumnType,
  ListQueryParams,
  TableRef
} from '@phoenix-systems/react-table/dist/types/types';
import Highlighter from 'react-highlight-words';
import { useDispatch, useSelector } from 'react-redux';
import { useImmer } from 'use-immer';

import FtpConnectionsTableSingle from './ftpConnectionsTableSingle';

import * as T from 'components/_styled/tableSc';
import FtpConnectionActions from 'components/domain/actions/ftpConnectionActions';
import BooleanIcon from 'components/ui/booleanIcon';
import Loader from 'components/ui/loader';
import PasswordInfo from 'components/ui/passwordInfo';
import tableLocales from 'config/langauge/table.locales';
import routes from 'config/routes/routes';
import useDomainName from 'hooks/useDomainName';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';
import useSchemaName from 'hooks/useSchemaName';
import { FTPConnection, useFTPConnections } from 'services/api/domain/ftpConnection';
import {
  st_ftpConnectionsTable_setParams,
  st_ftpConnectionsTable_setSelectedKey
} from 'services/store/ftpConnectionsTable/ftpConnectionsTable.actions';
import {
  st_ftpConnectionsTable_getParams,
  st_ftpConnectionsTable_getSelectedKey
} from 'services/store/ftpConnectionsTable/triggersTable.selectors';

type FtpConnectionsTableState = {
  data: FTPConnection[];
  isInitialized: boolean;
};

const FtpConnectionsTable: React.FC = () => {
  const tableRef = useRef<TableRef>(null);
  const domainName = useDomainName();
  const schemaName = useSchemaName();

  const dispatch = useDispatch();
  const params = useSelector(st_ftpConnectionsTable_getParams);
  const selectedKey = useSelector(st_ftpConnectionsTable_getSelectedKey);
  const [state, setState] = useImmer<FtpConnectionsTableState>({
    data: [],
    isInitialized: false
  });
  const goto = useGoToDomainRoute();

  const listQuery = useFTPConnections({ domainName, schemaName }, { retry: false });

  const handleSelectChange = (selectedRowKeys: React.Key[]) => {
    dispatch(st_ftpConnectionsTable_setSelectedKey(selectedRowKeys[0] as string));
  };

  const handleRemoveSelectedItem = () => {
    dispatch(st_ftpConnectionsTable_setSelectedKey(undefined));
  };

  const handleGotoFtpConnection = useCallback(
    (ftpConnectionName: string) => {
      goto(routes.domain.connections.ftp.single, { path: ftpConnectionName });
    },
    [goto]
  );

  const getColumns = useCallback(() => {
    const cls: CustomColumnType<FTPConnection>[] = [
      {
        title: i18n._('Name'),
        dataIndex: 'name',
        key: 'name',
        className: 'bold',
        sorter: (a, b) => a.name.localeCompare(b.name),
        // eslint-disable-next-line react/display-name
        render: (value: string) => (
          <>
            <Highlighter
              searchWords={params?.searchText ? [params.searchText] : []}
              textToHighlight={value}
              autoEscape
            />
            <T.EditBtn
              className="edit-btn"
              type="link"
              icon={
                <FontAwesomeIcon
                  icon={['fas', 'pen']}
                  onClick={() => handleGotoFtpConnection(value)}
                />
              }
            />
          </>
        )
      },
      {
        title: i18n._('Id'),
        dataIndex: 'id',
        key: 'id',
        disabled: true,
        ellipsis: true,
        sorter: (a, b) => a.id.localeCompare(b.id)
      },
      {
        title: i18n._('Remote host'),
        dataIndex: 'remoteHost',
        key: 'remoteHost',
        ellipsis: true,
        sorter: (a, b) => a.remoteHost.localeCompare(b.remoteHost)
      },
      {
        title: i18n._('Remote directory'),
        dataIndex: 'remoteDir',
        key: 'remoteDir',
        ellipsis: true,
        sorter: (a, b) => a.remoteDir.localeCompare(b.remoteDir)
      },
      {
        title: i18n._('SFTP'),
        dataIndex: 'sftp',
        key: 'sftp',
        ellipsis: true,
        render: value => <BooleanIcon value={value} />,
        sorter: (a, b) => a.sftp?.toString().localeCompare(b.sftp?.toString()),
        filters: [
          { text: 'true', value: true },
          { text: 'false', value: false }
        ],
        getDisplayedFilterValue: value => value.toString()
      },
      {
        title: i18n._('User name'),
        dataIndex: 'username',
        key: 'username',
        ellipsis: true,
        disabled: true,
        sorter: (a, b) => a.username.localeCompare(b.username)
      },
      {
        title: i18n._('Password'),
        dataIndex: 'password',
        key: 'password',
        ellipsis: true,
        disabled: true,
        render: value => <PasswordInfo value={value} />
      },
      {
        title: <FontAwesomeIcon icon={['fas', 'cog']} className="setting-icon" />,
        key: 'actions',
        width: 50,
        className: 'actions',
        // eslint-disable-next-line react/display-name
        render: (value, record) => (
          <FtpConnectionActions
            data={record}
            popoverPlacement="leftTop"
            className="edit-btn"
            mode="ICON_BUTTON"
          />
        ),
        hiddenInConfig: true
      }
    ];

    return cls;
  }, [params?.searchText, handleGotoFtpConnection]);

  useEffect(() => {
    if (tableRef.current && params && !state.isInitialized) {
      tableRef.current.setQueryParams(params);
      setState(draft => {
        draft.isInitialized = true;
      });
    }
  }, [params, state.isInitialized, setState]);

  useEffect(() => {
    if (params?.searchText && params.searchText !== '') {
      const matches = listQuery.data?.filter(
        d => d.name.search(new RegExp(params.searchText || '', 'gi')) !== -1
      );

      if (matches) {
        setState(draft => {
          draft.data = matches || [];
        });

        return;
      }
      setState(draft => {
        draft.data = listQuery.data || [];
      });
    } else {
      setState(draft => {
        draft.data = listQuery.data || [];
      });
    }
  }, [listQuery.data, params?.searchText, setState]);

  return (
    <>
      <T.StyledTable className="domain">
        <div>
          <Table
            ref={tableRef}
            table={{
              dataSource: state.data,
              loading: listQuery.isFetching,
              size: 'small',
              rowKey: 'name',
              columns: getColumns(),
              rowSelection: {
                type: 'radio',
                onChange: handleSelectChange,
                selectedRowKeys: selectedKey !== undefined ? [selectedKey] : []
              },
              onRow: record => ({
                onClick: e => {
                  const isEditBtn =
                    (e.target as HTMLElement).closest('.edit-btn') !== null ||
                    (e.target as HTMLElement).classList.contains('edit-btn') ||
                    (e.target as HTMLElement).closest('.ant-popover') !== null;

                  if (!isEditBtn) {
                    dispatch(st_ftpConnectionsTable_setSelectedKey(record.name));
                  }
                },
                onDoubleClick: () => {
                  handleGotoFtpConnection(record.name);
                }
              }),

              pagination: {
                pageSizeOptions: ['10', '20', '50'],
                pageSize: params?.pageSize || 20,
                current: params?.currentPage || 1,
                showSizeChanger: true
              }
            }}
            defaultTableContainerHeight="calc(100% - 440px)"
            fillHeight
            layout="single-split"
            topNavElements={{
              search: {
                value: params?.searchText,
                placeholder: i18n._('Search FTP connection...')
              },
              totalCount: {
                icon: <FontAwesomeIcon icon={['fas', 'exchange']} />,
                loader: <Loader size={12} />
              },
              reload: {
                onChange: () => {
                  listQuery.refetch();
                }
              },
              resetParams: true
            }}
            onRemoveSelectedItem={handleRemoveSelectedItem}
            locales={{
              '1 item selected': i18n._('1 FTP connection selected'),
              'items selected': i18n._('FTP connections selected'),
              'Nothing selected': i18n._('No FTP connection selected'),
              ...tableLocales
            }}
            renderSingle={(selectedItems: FTPConnection[]) => (
              <>
                <FtpConnectionsTableSingle data={selectedItems} />
              </>
            )}
            onQueryParamsChange={(currentParams: Partial<ListQueryParams>) => {
              dispatch(st_ftpConnectionsTable_setParams(currentParams));
            }}
            activeFilters={{ enabled: true, placement: 'default' }}
          />
        </div>
      </T.StyledTable>
    </>
  );
};

export default FtpConnectionsTable;
