/*  eslint-disable   @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios';
import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';

import {
  api_getDBDriver,
  api_getImportFromDbById,
  api_getImportFromDbByName,
  api_getImportFromDbResults,
  api_getImportsFromDb,
  api_testDBConnection
} from './dataImport.queries';
import {
  DbImport,
  GetDBDRiverParams,
  GetDBImportByIdParams,
  GetDBImportByNameParams,
  GetDBImportResultsParams,
  GetDBImportsParams,
  TestDBConnectionParams
} from './dataImport.types';

export const useDbImports = (
  params: GetDBImportsParams,
  options?: UseQueryOptions<DbImport[], AxiosError, DbImport[]>
): QueryObserverResult<DbImport[], AxiosError> =>
  useQuery<DbImport[], AxiosError>(
    ['importsFromDb', params],
    () => api_getImportsFromDb(params),
    options
  );

export const useDbImportById = (
  params: GetDBImportByIdParams,
  options?: UseQueryOptions<DbImport, AxiosError, DbImport>
): QueryObserverResult<DbImport, AxiosError> =>
  useQuery<DbImport, AxiosError>(
    ['importFromDb', 'byId', params],
    () => api_getImportFromDbById(params),
    options
  );

export const useDbImportByName = (
  params: GetDBImportByNameParams,
  options?: UseQueryOptions<DbImport, AxiosError, DbImport>
): QueryObserverResult<DbImport, AxiosError> =>
  useQuery<DbImport, AxiosError>(
    ['importFromDb', 'byName', params],
    () => api_getImportFromDbByName(params),
    options
  );

export const useDbDriver = (
  params: GetDBDRiverParams,
  options?: UseQueryOptions<string[], AxiosError, string[]>
): QueryObserverResult<string[], AxiosError> =>
  useQuery<string[], AxiosError>(['dbDriver', params], () => api_getDBDriver(params), options);

export const useTestDbConnection = (
  params: TestDBConnectionParams,
  options?: UseQueryOptions<any, AxiosError, any>
): QueryObserverResult<any, AxiosError> =>
  useQuery<any, AxiosError>(
    ['dbTestConnection', params],
    () => api_testDBConnection(params),
    options
  );

export const useDbImportResults = (
  params: GetDBImportResultsParams,
  options?: UseQueryOptions<any, AxiosError, any>
): QueryObserverResult<any, AxiosError> =>
  useQuery<any, AxiosError>(
    ['importFromDbResults', params],
    () => api_getImportFromDbResults(params),
    options
  );
