import { AnyObject } from '@phoenix-systems/react-form/dist/types/index.types';
import { clone } from 'lodash';

import { ObjectHistory, ObjectHistoryParameters } from './objectHistory.types';

import apiClient from 'services/api/apiClient';
import { log } from 'utils';

const BASE_PATH = 'history';

/**
 * GET /api/history/{domain}/{scheme}/{id}/{depth}/{fieldname}
 * GET /api/history/{domain}/{scheme}/{id}/{depth}
 *
 * apiKey required
 */
export const api_getObjectHistory = async (
  params: ObjectHistoryParameters
): Promise<ObjectHistory[]> => {
  const { data } = await apiClient.get(
    `/${BASE_PATH}/${params.domainName}/${params.schema}/${params.id}/${params.depth}`,
    { headers: { APIKEY: params.apiKey } }
  );

  if (data) {
    const parsedData: ObjectHistory[] = [];
    data.forEach((item: ObjectHistory, index: number) => {
      const parsed: ObjectHistory = clone(item);

      let jsonDiff;

      if (parsed.diffJsonArray) {
        try {
          jsonDiff = JSON.parse(parsed.diffJsonArray);
        } catch (error) {
          log(error);
        }
      }
      parsed.parsedDiff = jsonDiff;

      let parseOldJsonObject;

      if (parsed.oldJsonObject) {
        try {
          parseOldJsonObject = JSON.parse(parsed.oldJsonObject);
        } catch (err) {
          log(err);
        }
      }
      parsed.parsedOldJsonObject = parseOldJsonObject;

      const next = data[index + 1];
      let prevParsedOldJsonObject: AnyObject | undefined;

      if (next) {
        try {
          prevParsedOldJsonObject = JSON.parse(next.oldJsonObject);
        } catch (err) {
          log(err);
        }
      }

      parsed.prevParsedOldJsonObj = prevParsedOldJsonObject;

      parsedData.push(parsed);
    });

    return parsedData;
  }

  return data;
};
