import { ListQueryParams } from '@phoenix-systems/react-table/dist/types/types';

import {
  FtpConnectionsTableActionTypes,
  FtpConnectionsTableSingleView
} from './ftpConnectionsTable.types';

/* Action Definition */

type SetParamsAction = {
  type: FtpConnectionsTableActionTypes.SET_PARAMS;
  params?: Partial<ListQueryParams>;
};

type SetSingleViewAction = {
  type: FtpConnectionsTableActionTypes.SET_SINGLE_VIEW;
  view: FtpConnectionsTableSingleView;
};

type SetSelectedKeyAction = {
  type: FtpConnectionsTableActionTypes.SET_SELECTED_KEY;
  key?: string;
};

type ResetAction = {
  type: FtpConnectionsTableActionTypes.RESET;
};

/* Union Action Types */

export type FtpConnectionsTableActionCreators =
  | SetParamsAction
  | SetSingleViewAction
  | SetSelectedKeyAction
  | ResetAction;

/* Action Creators */

export const st_ftpConnectionsTable_setParams = (
  params?: Partial<ListQueryParams>
): SetParamsAction => ({
  type: FtpConnectionsTableActionTypes.SET_PARAMS,
  params
});

export const st_ftpConnectionsTable_setSingleView = (
  view: FtpConnectionsTableSingleView
): SetSingleViewAction => ({
  type: FtpConnectionsTableActionTypes.SET_SINGLE_VIEW,
  view
});

export const st_ftpConnectionsTable_setSelectedKey = (key?: string): SetSelectedKeyAction => ({
  type: FtpConnectionsTableActionTypes.SET_SELECTED_KEY,
  key
});

export const st_ftpConnectionsTable_reset = (): ResetAction => ({
  type: FtpConnectionsTableActionTypes.RESET
});
