import React from 'react';

import { FormProvider } from '@phoenix-systems/react-form';

import MobileNavigation from '../mobileNavigation';

import * as S from './appLayoutSc';

import Header from 'components/app/components/header';
import SidebarLeft from 'components/app/components/sidebarLeft';
import DocsDrawer from 'components/docs/components/docsDrawer';

const AppLayout: React.FC = ({ children }) => (
  <>
    <S.StyledLayout
      header={<Header />}
      main={<FormProvider>{children}</FormProvider>}
      mobileNavigation={<MobileNavigation />}
      sidebarLeft={<SidebarLeft />}
    />
    <DocsDrawer />
  </>
);

export default AppLayout;
