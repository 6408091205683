import React from 'react';

import { i18n } from '@lingui/core';

import { MonacoEditorMode } from './monacoEditor.types';

import Button from 'components/ui/button';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { AnyObject } from 'index.types';

type MonacoEditorCopyToClipBoardBtnProps = {
  data?: AnyObject | Array<AnyObject> | string;
  type: MonacoEditorMode;
};

const MonacoEditorCopyToClipBoardBtn: React.FC<MonacoEditorCopyToClipBoardBtnProps> = ({
  data,
  type
}) => {
  const copy = useCopyToClipboard();

  return (
    <Button
      action="copy"
      onClick={() => copy(type)}
      type="link"
      title={i18n._('Copy to clip board')}
      disabled={!data}
    />
  );
};

export default MonacoEditorCopyToClipBoardBtn;
