import { Navigation } from '@phoenix-systems/react-navigation';
import { Menu } from 'antd';
import { MenuProps } from 'antd/lib/menu';
import tw, { styled, css } from 'twin.macro';

import appStyles from 'styles/appStyles';

export const MainNavigationItem = styled(Menu.Item)(() => [
  css`
    ${tw`bg-gray-200`}
    background: transparent;

    & a {
      color: inherit;
    }
  `
]);

export const ToggleItem = styled(Menu.Item)(() => [
  css`
    ${tw`hover:bg-light-gray-600`}
    line-height: 44px !important;
    height: 44px !important;

    &.ant-menu-item-selected::after {
      ${tw`bg-light-gray-active`}
      border-right: none !important;
    }

    &.ant-menu-item-active {
      ${tw`bg-light-gray-active`}
    }

    &.ant-menu-item-selected:not(.ant-menu-item-active) {
      background-color: ${appStyles.colors.lightGray} !important;
    }
  `
]);

export const PopupContainer = styled.div(() => [
  css`
    ${tw``};
  `
]);

type ExtendedMenuProps = MenuProps & { collapsedWidth?: number };

export const StyledNavigation = styled(Navigation)<ExtendedMenuProps>(({ collapsedWidth }) => [
  css`
    &.no-transition * {
      transition: none !important;
    }

    & .ant-menu-submenu-title {
      ${tw`bg-light-gray-active-50 my-0`}
    }

    ${tw`bg-light-gray font-700 text-14`}

    &.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu {
      ${tw`bg-light-gray font-700 text-14`}
    }

    &.ant-menu-inline-collapsed {
      width: ${typeof collapsedWidth === 'number' ? `${collapsedWidth}px` : 'auto'} !important;
    }

    &.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title,
    &.ant-menu-inline-collapsed > .ant-menu-item {
      padding: 0 ${typeof collapsedWidth === 'number' ? `${collapsedWidth - 38}px` : 'auto'};
      margin: 0;
    }

    &.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
    &.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:hover {
      ${tw`bg-light-gray-active`}
      &:after {
        border: none;
      }
    }

    & .ant-menu-submenu > .ant-menu {
      ${tw`bg-light-gray`}
    }

    &.ant-menu-inline .ant-menu-item:not(:last-child),
    &.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
      margin: 0;
    }

    &.ant-menu > .ant-menu-item-divider {
      ${tw`bg-gray-500`}
    }

    &.ant-menu-inline .ant-menu-item {
      ${tw`m-0`}
      height: 38px;
      line-height: 38px;

      &.doc-item {
        ${tw`font-600`}
        height: 28px;
        line-height: 28px;

        & svg {
          ${tw`mr-6 text-gray-500 text-14`}
        }
      }
    }

    & a {
      color: inherit;
    }

    & i.icon-before {
      ${tw`mr-6`}
    }

    & .ant-menu-submenu-expand-icon {
      ${tw`hover:text-secondary transition-all duration-150 w-26 h-26 p-6`}
      right: 8px;

      transform-origin: center;
    }

    & .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon {
      transform: rotate(180deg) translateY(13px);
    }

    &.docs .ant-menu-item:not(#\/docs) {
      height: 3.2rem !important;
      line-height: 3.2rem !important;
    }
  `
]);

export const StyledToggleNav = styled(Menu)<ExtendedMenuProps>(() => [
  css`
    ${tw`bg-light-gray font-700 text-14`}

    &.ant-menu-inline .ant-menu-item {
      ${tw`m-0`}
      height: 44px;
      line-height: 44px;

      &.toggle-nav-item {
        ${tw`hover:bg-light-gray-600`}
        line-height: 44px !important;
        height: 44px !important;

        &.ant-menu-item-selected::after {
          ${tw`bg-light-gray-active`}
          border-right: none !important;
        }

        &.ant-menu-item-active {
          ${tw`bg-light-gray-active`}
        }

        &.ant-menu-item-selected:not(.ant-menu-item-active) {
          background-color: ${appStyles.colors.lightGray} !important;
        }
      }
    }
  `
]);
