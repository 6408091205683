import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import * as T from 'components/_styled/tableSc';
import useIsMinDsVersion from 'hooks/useIsMinDsVersion';
import { Domain } from 'services/api/domain/domain';
import { st_domainsTable_setSingleView } from 'services/store/domainsTable/domainsTable.actions';
import { st_domainsTable_getSingleView } from 'services/store/domainsTable/domainsTable.selectors';
import { DomainsTableSingleView } from 'services/store/domainsTable/domainsTable.types';

const { TabPane } = Tabs;

type DomainsTableSingleNavProps = {
  data: Domain;
};

const DomainsTableSingleNav: React.FC<DomainsTableSingleNavProps> = () => {
  const hasStatisticsTab = useIsMinDsVersion('2');
  const view = useSelector(st_domainsTable_getSingleView);
  const dispatch = useDispatch();
  const handleTabChange = (value: string) => {
    dispatch(st_domainsTable_setSingleView(value as DomainsTableSingleView));
  };

  return (
    <T.SingleNav>
      <T.TabNavigation defaultActiveKey="1" onChange={handleTabChange} activeKey={view}>
        <TabPane
          tab={
            <>
              <FontAwesomeIcon icon={['fas', 'info-circle']} />
              <Trans>Overview</Trans>
            </>
          }
          key="OVERVIEW"
        />
        <TabPane
          tab={
            <>
              <FontAwesomeIcon icon={['fas', 'users']} />
              <Trans>Administrators</Trans>
            </>
          }
          key="USERS"
        />

        {hasStatisticsTab && (
          <TabPane
            tab={
              <>
                <FontAwesomeIcon icon={['fas', 'chart-pie']} />
                <Trans>Statistic</Trans>
              </>
            }
            key="STATISTIC"
          />
        )}
      </T.TabNavigation>
    </T.SingleNav>
  );
};

export default DomainsTableSingleNav;
