import React from 'react';

import { Trans } from '@lingui/macro';

import AppLayout from 'components/app/components/appLayout';
import CreateDomainAction from 'components/domain/actions/domainActions/createDomainAction';
import DeleteDomainAction from 'components/domain/actions/domainActions/deleteDomainAction';
import DomainForm from 'components/domain/components/domainForm';
import DomainPage from 'components/domain/components/domainPage';
import routes from 'config/routes/routes';
import useDomainFromCache from 'hooks/useDomainFromCache';

const DomainOverviewPage: React.FC = () => {
  const domain = useDomainFromCache();

  return (
    <AppLayout>
      <DomainPage
        route={routes.domain.overview}
        content={
          <>
            <DomainForm />
          </>
        }
        title={<Trans>Overview</Trans>}
        headerButtons={
          <>
            {domain && <DeleteDomainAction mode="BUTTON" data={domain} />}
            <CreateDomainAction mode="BUTTON" buttonProps={{ type: 'primary' }} />
          </>
        }
      />
    </AppLayout>
  );
};

export default DomainOverviewPage;
