import React from 'react';

import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';

import FtpConnectionsTableOverview from '../ftpConnectionsTable/singleViews/ftpConnectionsTableOverview';

import CsvImportTableNav from './csvImportTableNav';
import CsvImportColumnMappers from './singleViews/csvImportColumnMappers';
import DbImportTableLogs from './singleViews/csvImportTableLogs';
import DbImportTableOverview from './singleViews/csvImportTableOverview';
import DbImportTableScheduler from './singleViews/csvImportTableScheduler';

import * as T from 'components/_styled/tableSc';
import CsvImportActions from 'components/domain/actions/csvImportActions';
import { CsvImport } from 'services/api/domain/csvImport';
import { st_csvImportsTable_getSingleView } from 'services/store/csvImportsTable/csvImportsTable.selectors';

type CsvImportTableSingleProps = {
  data?: CsvImport;
};

const CsvImportTableSingle: React.FC<CsvImportTableSingleProps> = ({ data }) => {
  const view = useSelector(st_csvImportsTable_getSingleView);

  if (!data) {
    return null;
  }

  return (
    <>
      <T.SingleTitle>
        <h4>
          <Trans>CSV Import: {data.name}</Trans>
        </h4>
        <T.SingleTitleRight>
          <CsvImportActions
            data={data}
            style={{ marginRight: 12 }}
            mode="BUTTON"
            popoverPlacement="leftTop"
          />
        </T.SingleTitleRight>
      </T.SingleTitle>
      <CsvImportTableNav data={data} />

      <T.SingleContent>
        {view === 'OVERVIEW' && <DbImportTableOverview data={data} />}
        {view === 'FTP_CONNECTION' && <FtpConnectionsTableOverview data={data.ftpConnection} />}
        {view === 'MAPPERS' && <CsvImportColumnMappers data={data} />}
        {view === 'SCHEDULERS' && <DbImportTableScheduler data={data} />}
        {view === 'LOGS' && <DbImportTableLogs data={data} />}
      </T.SingleContent>
    </>
  );
};

export default CsvImportTableSingle;
