import { AxiosError } from 'axios';
import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';

import { api_queryDomainObject, api_queryDomainObjects } from './object.queries';
import {
  DomainObjectResult,
  QueryDomainObjectParms,
  QueryDomainObjectsParms
} from './object.types';

export const useQueryDomainObjects = (
  params: QueryDomainObjectsParms,
  options?: UseQueryOptions<DomainObjectResult, AxiosError, DomainObjectResult>
): QueryObserverResult<DomainObjectResult, AxiosError> =>
  useQuery<DomainObjectResult, AxiosError>(
    ['queryDomainObjects', params],
    () => api_queryDomainObjects(params),
    options
  );

export const useQueryDomainObject = (
  params: QueryDomainObjectParms,
  options?: UseQueryOptions<DomainObjectResult, AxiosError, DomainObjectResult>
): QueryObserverResult<DomainObjectResult, AxiosError> =>
  useQuery<DomainObjectResult, AxiosError>(
    ['queryDomainObject', params],
    () => api_queryDomainObject(params),
    options
  );
