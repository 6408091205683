import { produce } from 'immer';
import { last } from 'lodash';

import { DocsActionCreators } from './docs.actions';
import { DocsActionTypes, DocsState } from './docs.types';

const initialState: DocsState = Object.freeze<DocsState>({
  drawerCurrentId: undefined,
  drawerHistory: [],
  isDrawerOpen: false,
  isBackEnabled: false,
  isForwardEnabled: false,
  drawerHistoryIndex: 0,
  editorMode: 'read'
});

const docsReducer = (state: DocsState = initialState, action: DocsActionCreators): DocsState =>
  produce(state, draft => {
    switch (action.type) {
      case DocsActionTypes.OPEN_DRAWER: {
        const newHistory = [...state.drawerHistory];

        if (last(state.drawerHistory) !== action.docId) {
          newHistory.push(action.docId);
        }
        draft.isDrawerOpen = true;
        draft.drawerHistory = newHistory;
        draft.drawerCurrentId = action.docId;
        draft.isBackEnabled = newHistory.length > 1;
        draft.drawerHistoryIndex = newHistory.length - 1;
        draft.isForwardEnabled = false;

        break;
      }

      case DocsActionTypes.CLOSE_DRAWER:
        draft.isDrawerOpen = false;

        break;

      case DocsActionTypes.DRAWER_BACK:
        if (!draft.isBackEnabled) {
          break;
        }
        draft.drawerCurrentId = state.drawerHistory[state.drawerHistoryIndex - 1];
        draft.isForwardEnabled = true;
        draft.isBackEnabled = state.drawerHistory[state.drawerHistoryIndex - 2] !== undefined;
        draft.drawerHistoryIndex = state.drawerHistoryIndex - 1;

        break;

      case DocsActionTypes.DRAWER_FORWARD:
        if (!draft.isForwardEnabled) {
          break;
        }
        draft.drawerCurrentId = state.drawerHistory[state.drawerHistoryIndex + 1];
        draft.isForwardEnabled = state.drawerHistory[state.drawerHistoryIndex + 2] !== undefined;
        draft.isBackEnabled = true;
        draft.drawerHistoryIndex = state.drawerHistoryIndex + 1;

        break;

      case DocsActionTypes.SET_EDITOR_MODE:
        draft.editorMode = action.mode;

        break;

      default:
        break;
    }
  });

export default docsReducer;
