import { Button } from 'antd';
import { Collapse } from 'react-collapse';
import tw, { styled, css } from 'twin.macro';

export const Wrapper = styled.div(() => [
  css`
    ${tw`border border-ant-border-gray rounded-4 overflow-hidden`};
  `
]);

export const Header = styled.div(() => [
  css`
    ${tw`border-b border-ant-border-gray bg-light-gray px-12 py-6 font-600 flex flex-row items-center`};

    & svg {
      ${tw`mr-12`};
    }
  `
]);

export const EditButton = styled(Button)(() => [
  css`
    ${tw`ml-2 text-gray-400`};

    & svg {
      ${tw`text-14`};
    }
  `
]);

type TriggerHeaderProps = {
  isOpen: boolean;
};
export const TriggerHeader = styled.div<TriggerHeaderProps>(({ isOpen }) => [
  css`
    ${tw`flex flex-row hover:bg-light-gray pl-4 pr-12 py-12 items-center`};

    & h4 {
      ${tw`text-14 font-600`};
    }

    & .toggle-btn {
      ${tw`p-0 m-0 flex items-center w-32 h-32 justify-center hover:text-primary`};
      & svg {
        ${tw`text-14 transition-all`};
      }
    }

    & > div {
      ${tw`ml-auto flex flex-row items-center`};
    }

    & > .actions {
      & .trigger-actions {
        ${tw`bg-light-gray-active text-black mr-0`};
      }
    }
  `,

  isOpen &&
    css`
      & .toggle-btn svg {
        ${tw`transform rotate-90`};
      }
    `
]);

export const StyledCollapse = styled(Collapse)(() => [
  css`
    ${tw`flex flex-row`};

    & > .actions {
      ${tw`flex flex-row items-center`};

      & .trigger-actions {
        ${tw`bg-light-gray-active text-black mr-0`};
      }
    }
  `
]);

export const TriggersList = styled.ul(() => [
  css`
    ${tw`m-0 p-0`};

    & > li {
      ${tw`border-b border-ant-border-gray`};

      &:last-of-type {
        ${tw`border-none`};
      }
    }
  `
]);

export const TriggerTitle = styled.ul(() => [
  css`
    p {
      ${tw`font-500`};

      & span {
        ${tw`font-600 text-12 text-primary inline-block`};
        width: 10rem;
      }

      & b {
        ${tw`font-600 text-primary`};
      }
    }

    & > .actions {
      ${tw`flex flex-row items-center`};

      & .trigger-actions {
        ${tw`bg-light-gray-active text-black mr-0`};
      }
    }
  `
]);
