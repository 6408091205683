import compareVersions from 'compare-versions';

const isMinDsVersion = (desiredVersion: string): boolean => {
  if (!window._env_.API_VERSION) {
    throw new Error(
      'Envorionment variable "API_VERSION" was not fount. please check your .env files'
    );
  }

  return compareVersions.compare(window._env_.API_VERSION, desiredVersion, '>=');
};

export default isMinDsVersion;
