import React from 'react';

import { Scrollable } from '@phoenix-systems/react-layout';

import * as T from 'components/_styled/tableSc';
import { FTPConnection } from 'services/api/domain/ftpConnection';

type FtpConnectionsTableTasksProps = {
  data?: FTPConnection;
};

const FtpConnectionsTableTasks: React.FC<FtpConnectionsTableTasksProps> = ({ data }) => {
  if (!data) {
    return null;
  }

  /*  const lastTime = data.lastTime ? moment(data.lastTime, 'YYYY-MM-DD HH:mm:ss') : undefined;
  const startTime = data.startTime ? moment(data.startTime, 'YYYY-MM-DD HH:mm:ss') : undefined; */

  return (
    <Scrollable scrollY>
      <T.SingleContentContainer>
        <p>connected tasks...</p>
      </T.SingleContentContainer>
    </Scrollable>
  );
};

export default FtpConnectionsTableTasks;
