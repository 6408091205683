import React, { useEffect, useRef, useState, createContext } from 'react';

import { useSelector } from 'react-redux';

import getContainer from './getContainer';

import * as D from 'components/_styled/dialogSc';
import * as F from 'components/_styled/formSc';
import * as C from 'components/app/components/contextNavigation/contextNavigationSc';
import * as H from 'components/app/components/header/headerSc';
import * as N from 'components/app/components/pushNotifications/pushNotificationSc';
import * as S from 'components/app/components/sidebarLeft/sidebarLeftSc';
import * as I from 'components/docs/components/docsTooltip/docsTooltipSc';
import { st_domainDiagram_getContextNavIsOpen } from 'services/store/domainDiagram/domainDiagram.selectors';

export type ContainerContextState = {
  dialogContainer: HTMLElement;
  notificationContainer: HTMLElement;
  mainNavigationContainer: HTMLElement;
  headerContainer: HTMLElement;
  domainDiagramContainer: HTMLElement;
  infoContainer: HTMLElement;
  docsContainer: HTMLElement;
  formContainer: HTMLElement;
  contextNavContainer: HTMLElement;
};

export const ContainerContext = createContext({} as ContainerContextState);

const ContainerProvider: React.FC = ({ children }) => {
  const notificationContainer = useRef(null);
  const dialogContainer = useRef(null);
  const mainNavigationContainer = useRef(null);
  const headerContainer = useRef(null);
  const domainDiagramContainer = useRef(null);
  const infoContainer = useRef(null);
  const docsContainer = useRef(null);
  const formContainer = useRef(null);
  const contextNavContainer = useRef(null);
  const isDdContextNavOpen = useSelector(st_domainDiagram_getContextNavIsOpen);

  const [state, setState] = useState<ContainerContextState>({
    dialogContainer: getContainer(dialogContainer),
    notificationContainer: getContainer(notificationContainer),
    mainNavigationContainer: getContainer(mainNavigationContainer),
    headerContainer: getContainer(headerContainer),
    domainDiagramContainer: getContainer(domainDiagramContainer),
    infoContainer: getContainer(infoContainer),
    docsContainer: getContainer(docsContainer),
    formContainer: getContainer(formContainer),
    contextNavContainer: getContainer(contextNavContainer)
  });

  useEffect(() => {
    setState({
      dialogContainer: getContainer(dialogContainer),
      notificationContainer: getContainer(notificationContainer),
      mainNavigationContainer: getContainer(mainNavigationContainer),
      headerContainer: getContainer(headerContainer),
      domainDiagramContainer: getContainer(domainDiagramContainer),
      infoContainer: getContainer(infoContainer),
      docsContainer: getContainer(docsContainer),
      formContainer: getContainer(formContainer),
      contextNavContainer: getContainer(contextNavContainer)
    });
  }, [notificationContainer, dialogContainer]);

  return (
    <ContainerContext.Provider value={state}>
      {children}
      <F.PopupContainer ref={formContainer} />
      <N.NotificationContainer ref={notificationContainer} />
      <S.MainNavigationContainer ref={mainNavigationContainer} />
      <H.NavPopupContainer ref={headerContainer} />
      <C.StyledContainer ref={domainDiagramContainer} isVisible={isDdContextNavOpen} />
      <C.StyledContainer ref={contextNavContainer} isVisible />
      <D.DialogContainer ref={dialogContainer} />
      <I.PopupContainer ref={infoContainer} />
      <div ref={docsContainer} />
    </ContainerContext.Provider>
  );
};

export default ContainerProvider;
