import { createSelector } from 'reselect';

import { AppState } from './app.types';

import { Language } from 'config/langauge/languages.types';

export const appState = (state: { app: AppState }): AppState => state.app;

export const st_app_getSidebarInitialised = createSelector(
  [appState],
  (app): boolean => app.isSidebarInitialised
);

export const st_app_getLanguage = createSelector([appState], (app): Language => app.language);
