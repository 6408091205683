import tw, { styled, css } from 'twin.macro';

export const Wrapper = styled.div(() => [
  css`
    /* ${tw`border`}; */
  `
]);

export const Header = styled.div(() => [
  css`
    ${tw`flex flex-row hover:bg-light-gray pl-4 pr-12 py-12 items-center transition-all`};

    & h4 {
      ${tw`text-14 font-600`};
    }

    & .toggle-btn {
      ${tw`p-0 m-0 flex items-center w-32 h-32 justify-center hover:text-primary`};
      & svg {
        ${tw`text-14 transition-all`};
      }
    }

    & > div {
      ${tw`ml-auto flex flex-row items-center`};
    }

    & > .actions {
      & .trigger-actions {
        ${tw`bg-light-gray-active text-black mr-0`};
      }
    }

    &.open {
      ${tw`bg-light-gray-300 hover:bg-light-gray-500`};
      & .toggle-btn svg {
        ${tw`transform rotate-90`};
      }
    }
  `
]);

export const Body = styled.div(() => [
  css`
    & .ReactCollapse--collapse {
      ${tw`transition-all`};

      & .ReactCollapse--content {
        ${tw`p-12`};
      }
    }
  `
]);
