import React from 'react';

import { i18n } from '@lingui/core';
import { Menu } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import clsx from 'clsx';
import { CSSProperties } from 'styled-components';

import DeleteObjectAction from './deleteObjectAction';
import EditObjectAction from './editObjectAction';
import GotoObjectHistoryAction from './gotoObjecgtHistoryAction';

import ContextNavigation from 'components/app/components/contextNavigation';
import Button from 'components/ui/button';
import { ActionMode } from 'index.types';

type ObjectActionsProps = {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  data: any;
  style?: CSSProperties;
  className?: string;
  mode: ActionMode;
  popoverPlacement: TooltipPlacement;
  onDelete?: () => void;
};

const { Divider } = Menu;

const ObjectActions: React.FC<ObjectActionsProps> = ({
  data,
  style,
  mode,
  popoverPlacement,
  className
}) => (
  <ContextNavigation
    placement={popoverPlacement}
    trigger={
      <Button
        isIconOnly={mode === 'ICON_BUTTON'}
        action="actions"
        className={clsx(['trigger-actions', className])}
        style={style}
        title={i18n._('Object actions')}
      />
    }
  >
    <GotoObjectHistoryAction mode="MENU_ITEM" data={data} />
    <Divider />
    <EditObjectAction mode="MENU_ITEM" data={data} />
    <DeleteObjectAction mode="MENU_ITEM" data={data} />
  </ContextNavigation>
);

export default ObjectActions;
