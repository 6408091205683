import React from 'react';

import { Trans } from '@lingui/macro';
import { getRoute } from '@phoenix-systems/react-router';
import { Col, Row } from 'antd';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import * as S from '../triggersTableSc';

import * as T from 'components/_styled/tableSc';
import DataItem from 'components/ui/dataItem';
import dateFormat from 'config/dateTime';
import routes from 'config/routes/routes';
import useGoToDomainRoute from 'hooks/useGoToDomainRoute';
import useIsDomain from 'hooks/useIsDomain';
import { Trigger } from 'services/api/domain/trigger';
import history from 'services/history';
import { st_triggersTable_setParams } from 'services/store/triggersTable/triggersTable.actions';

type TriggerTableOverviewProps = {
  data?: Trigger;
  isTriggerAdder?: boolean;
};

const TriggerTableOverview: React.FC<TriggerTableOverviewProps> = ({ data, isTriggerAdder }) => {
  const isDomain = useIsDomain();
  const triggerRoute = getRoute(routes.domain.overview, data?.name || '');
  const dispatch = useDispatch();
  const goto = useGoToDomainRoute();

  const handleEditTrigger = () => {
    if (data?.name) {
      if (isDomain) {
        goto(routes.domain.scheduler.single, { path: data.name });
      } else {
        history.push(getRoute(routes.scheduler.single, data.name));
      }
    }
  };

  const handleGotoDomain = () => {
    if (data?.taskDomain && data?.taskScheme) {
      dispatch(st_triggersTable_setParams(undefined));
      history.push(getRoute(routes.domain.scheduler.list, data.taskDomain, data.taskScheme));
    }
  };

  if (!data) {
    return null;
  }

  const lastTime = data.lastTime ? moment(data.lastTime, 'YYYY-MM-DD HH:mm:ss') : undefined;
  const startTime = data.startTime ? moment(data.startTime, 'YYYY-MM-DD HH:mm:ss') : undefined;

  return (
    <T.SingleContentContainer>
      <Row gutter={[12, 12]}>
        <Col xs={12}>
          <ul>
            {!isTriggerAdder && (
              <li>
                <DataItem
                  name={<Trans>Name</Trans>}
                  value={<S.DomainLink to={triggerRoute}>{data.name}</S.DomainLink>}
                  hasCopy
                  copyValue={data.name}
                  onEdit={handleEditTrigger}
                />
              </li>
            )}

            <li>
              <DataItem name={<Trans>Active</Trans>} value={data.active?.toString()} />
            </li>
            <li>
              <DataItem
                name={<Trans>Task type</Trans>}
                value={data.taskType?.toLowerCase().replaceAll('_', ' ')}
              />
            </li>
            <li>
              <DataItem
                name={<Trans>Last execution</Trans>}
                value={
                  lastTime ? lastTime.format(dateFormat.dateTime) : <Trans>Never triggered</Trans>
                }
              />
            </li>
            <li>
              <DataItem name={<Trans>Id</Trans>} value={data.id} hasCopy breakWord />
            </li>
          </ul>
        </Col>
        <Col xs={6}>
          <ul>
            <li>
              <DataItem
                name={<Trans>Task domain</Trans>}
                value={data.taskDomain}
                hasCopy
                onView={!isDomain ? handleGotoDomain : undefined}
              />
            </li>
            <li>
              <DataItem name={<Trans>Task scheme</Trans>} value={data.taskScheme} hasCopy />
            </li>
            <li>
              <DataItem name={<Trans>Task name</Trans>} value={data.taskName} hasCopy />
            </li>
          </ul>
        </Col>
        <Col xs={6}>
          <ul>
            <li>
              <DataItem
                name={<Trans>Periodicity</Trans>}
                value={data.periodicity?.toLowerCase().replace('_', ' ')}
              />
            </li>
            <li>
              <DataItem name={<Trans>Time value</Trans>} value={data.timeValue?.toString()} />
            </li>
            <li>
              <DataItem
                name={<Trans>Start time</Trans>}
                value={startTime ? startTime.format(dateFormat.dateTime) : '-'}
              />
            </li>
          </ul>
        </Col>
      </Row>
    </T.SingleContentContainer>
  );
};

export default TriggerTableOverview;
