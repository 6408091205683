import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPlusCircle,
  faGlobe,
  faTrash,
  faPen,
  faUser,
  faCompressArrowsAlt,
  faList,
  faUndo,
  faLocation,
  faLocationSlash,
  faBorderOuter,
  faObjectUngroup,
  faVectorSquare,
  faArrowFromTop,
  faArrowFromBottom,
  faArrowToTop,
  faArrowToBottom,
  faArrowRight,
  faKey,
  faExclamationTriangle,
  faSync,
  faArrowsAlt,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faGlobeAmericas,
  faServer,
  faCheck,
  faUserFriends,
  faHome,
  faDatabase,
  faTools,
  faFileImport,
  faFileExport,
  faProjectDiagram,
  faHistory,
  faCode,
  faChartLine,
  faWrench,
  faTachometerAlt,
  faCog,
  faSignOutAlt,
  faCameraPolaroid,
  faSearch,
  faUserPlus,
  faArrowDown,
  faArrowUp,
  faBold,
  faItalic,
  faUnderline,
  faStrikethrough,
  faSuperscript,
  faSubscript,
  faHighlighter,
  faQuoteRight,
  faListUl,
  faListOl,
  faLink,
  faInfo,
  faCopy,
  faH1,
  faH2,
  faH3,
  faParagraph,
  faAngleDown,
  faSave,
  faExclamationCircle,
  faFileArchive,
  faDownload,
  faPaperclip,
  faEye,
  faUnlink,
  faCompress,
  faAngleRight,
  faCrosshairs,
  faArrowAltFromLeft,
  faInfoCircle,
  faImage,
  faWindowMaximize,
  faWindowMinimize,
  faUsers,
  faChartPie,
  faTimes,
  faExclamation,
  faClock,
  faCalendarAlt,
  faBan,
  faPlay,
  faStop,
  faBracketsCurly,
  faQuestionSquare,
  faSignInAlt,
  faFileUpload,
  faFolderOpen,
  faExchange,
  faThumbtack,
  faTimesCircle,
  faEyeSlash,
  faAnchor,
  faUpload,
  faUserAltSlash,
  faUserCircle,
  faBuilding
} from '@fortawesome/pro-solid-svg-icons';

const addIconsFas = (): void => {
  library.add(
    faPlusCircle,
    faGlobe,
    faTrash,
    faPen,
    faUser,
    faCompressArrowsAlt,
    faList,
    faUndo,
    faLocation,
    faLocationSlash,
    faBorderOuter,
    faObjectUngroup,
    faVectorSquare,
    faArrowFromTop,
    faArrowFromBottom,
    faArrowToTop,
    faArrowToBottom,
    faArrowRight,
    faKey,
    faExclamationTriangle,
    faSync,
    faArrowsAlt,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faGlobeAmericas,
    faServer,
    faCheck,
    faUserFriends,
    faHome,
    faDatabase,
    faTools,
    faFileImport,
    faFileExport,
    faProjectDiagram,
    faHistory,
    faCode,
    faChartLine,
    faWrench,
    faTachometerAlt,
    faCog,
    faSignOutAlt,
    faCameraPolaroid,
    faSearch,
    faUserPlus,
    faArrowDown,
    faArrowUp,
    faBold,
    faItalic,
    faUnderline,
    faStrikethrough,
    faSuperscript,
    faSubscript,
    faHighlighter,
    faQuoteRight,
    faListUl,
    faListOl,
    faLink,
    faInfo,
    faCopy,
    faH1,
    faH2,
    faH3,
    faParagraph,
    faAngleDown,
    faSave,
    faExclamationCircle,
    faFileArchive,
    faDownload,
    faPaperclip,
    faEye,
    faUnlink,
    faCompress,
    faAngleRight,
    faCrosshairs,
    faArrowAltFromLeft,
    faInfoCircle,
    faImage,
    faWindowMaximize,
    faWindowMinimize,
    faUsers,
    faChartPie,
    faTimes,
    faExclamation,
    faClock,
    faHistory,
    faCalendarAlt,
    faBan,
    faPlay,
    faStop,
    faBracketsCurly,
    faQuestionSquare,
    faSignInAlt,
    faFileUpload,
    faFolderOpen,
    faExchange,
    faThumbtack,
    faTimesCircle,
    faEyeSlash,
    faAnchor,
    faUpload,
    faUserAltSlash,
    faUserCircle,
    faBuilding
  );
};

export default addIconsFas;
