import { parse } from 'qs';
import { useLocation } from 'react-router-dom';

const useEntityName = (): string | undefined => {
  const { search } = useLocation();
  const queryParams = parse(search.replace('?', ''));

  if (queryParams.entity && typeof queryParams.entity === 'string') {
    return queryParams.entity;
  }

  return undefined;
};

export default useEntityName;
