import React, { useEffect } from 'react';

import { BreadcrumbConfig, BreadcrumbNavigation } from '@phoenix-systems/react-navigation';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';
import { useImmer } from 'use-immer';

import * as S from './breadcrumbsSc';

import useContainer from 'components/app/components/containerProvider/useContainer';
import { getDocsNavigation } from 'config/docs/docs.utils';
import getBreadcrumbConfig from 'config/navigation/bradcrumbsNavigation.config';
import getMainNavigationConfig from 'config/navigation/mainNavigation.config';
import routes from 'config/routes/routes';
import { useIsSuperadmin } from 'hooks/useIsSuperAdmin';
import useSchemaName from 'hooks/useSchemaName';
import { useDomains } from 'services/api/domain/domain';
import { st_app_getLanguage } from 'services/store/app/app.selectors';
import { st_domain_getDomainName } from 'services/store/domain/domain.selectors';

type BreadcrumbsProps = {
  isWithoutPage?: boolean;
  docsPath?: string;
};

type BreadcrumbsState = {
  app: BreadcrumbConfig[];
  docs: BreadcrumbConfig[];
};

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ isWithoutPage, docsPath }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const domainsQuery = useDomains();
  const domainName = useSelector(st_domain_getDomainName);
  const schemaName = useSchemaName();
  const isSuperadmin = useIsSuperadmin();
  const { mainNavigationContainer } = useContainer();
  const match = useRouteMatch([
    routes.dashboard.root,
    routes.domain.root,
    routes.docs.root,
    routes.scheduler.root,
    routes.userAdmin.root
  ]);
  const language = useSelector(st_app_getLanguage);

  const [config, setConfig] = useImmer<BreadcrumbsState>({
    app: [],
    docs: []
  });

  useEffect(() => {
    if (docsPath) {
      const docsNav = getDocsNavigation().children?.[0];

      if (docsNav) {
        const brConfig = getBreadcrumbConfig({
          pathname: docsPath,
          mainNavConfig: docsNav,
          domainName,
          isDocsDrawer: true,
          schemaName,
          isSuperadmin,
          dispatch
        });

        if (brConfig) {
          setConfig(draft => {
            draft.docs = brConfig;
          });
        }
      }

      return;
    }

    if (match?.path) {
      const mainNavConfig = getMainNavigationConfig({
        basePath: match?.path,
        domainName,
        schemaName,
        language,
        isSuperadmin
      });

      if (mainNavConfig) {
        const brConfig = getBreadcrumbConfig({
          pathname,
          mainNavConfig,
          domains: domainsQuery.data,
          domainName,
          schemaName,
          isSuperadmin
        });

        if (brConfig) {
          setConfig(draft => {
            draft.app = brConfig;
          });
        }
      }
    }
  }, [
    pathname,
    language,
    dispatch,
    setConfig,
    domainsQuery.data,
    domainName,
    schemaName,
    docsPath,
    isSuperadmin,
    match?.path
  ]);

  return (
    <S.Container isWithoutPage={isWithoutPage}>
      <BreadcrumbNavigation
        config={docsPath ? config.docs : config.app}
        getPopupContainer={() => mainNavigationContainer}
      />
    </S.Container>
  );
};

export default Breadcrumbs;
