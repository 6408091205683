import React, { useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Menu } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { omit } from 'lodash';
import { removeElements } from 'react-flow-renderer';
import { useDispatch, useSelector } from 'react-redux';
import { useImmer } from 'use-immer';

import Button from 'components/ui/button';
import useDefaultModalProps from 'hooks/useDefaultModalProps';
import { EntityNodeData } from 'services/api/domain/domainDiagram';
import {
  st_domainDiagram_setDialogOpen,
  st_domainDiagram_updateElements
} from 'services/store/domainDiagram/domainDiagram.actions';
import { st_domainDiagram_getElements } from 'services/store/domainDiagram/domainDiagram.selectors';

type DeleteEntityActionState = {
  isDialogOpen: boolean;
};

type DeleteEntityActionProps = {
  data?: EntityNodeData;
  nodeId?: string;
  closeNavigation: () => void;
  isMenu?: boolean;
  dialogIsOpen?: boolean;
};

const DeleteEntityAction: React.FC<DeleteEntityActionProps> = props => {
  const pr = omit(props, ['nodeId', 'data', 'closeNavigation', 'isMenu', 'dialogIsOpen']);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { data, nodeId, closeNavigation, isMenu, dialogIsOpen } = props;
  const dispatch = useDispatch();
  const elements = useSelector(st_domainDiagram_getElements);
  const [state, setState] = useImmer<DeleteEntityActionState>({
    isDialogOpen: false
  });
  const modalProps = useDefaultModalProps();

  const handleDeleteEntityRequest = () => {
    closeNavigation();
    setState(draft => {
      draft.isDialogOpen = true;
    });
    dispatch(st_domainDiagram_setDialogOpen(true));
  };

  const handleDialogClose = () => {
    dispatch(st_domainDiagram_setDialogOpen(false));
    setState(draft => {
      draft.isDialogOpen = false;
    });
  };

  const handleDeleteEntity = () => {
    const newElements = removeElements(
      elements.filter(el => el.id === nodeId),
      elements
    );
    dispatch(st_domainDiagram_updateElements(newElements));
    handleDialogClose();
  };

  useEffect(() => {
    if (dialogIsOpen !== undefined) {
      setState(draft => {
        draft.isDialogOpen = dialogIsOpen;
      });
    }
  }, [dialogIsOpen, setState]);

  if (!data || !nodeId) {
    return null;
  }

  return (
    <>
      {isMenu && (
        <Menu.Item
          {...pr}
          key="insert-entity"
          icon={<FontAwesomeIcon icon={['fas', 'trash']} />}
          onClick={handleDeleteEntityRequest}
        >
          <Trans>Delete entity</Trans>
        </Menu.Item>
      )}

      <Modal
        {...modalProps}
        title={
          <>
            <Trans>Delete entity: {data.simpleClassName}</Trans>
          </>
        }
        visible={state.isDialogOpen}
        footer={
          <>
            <Button action="cancel" onClick={handleDialogClose} />
            <Button action="delete" onClick={handleDeleteEntity}>
              <Trans>Delete entity</Trans>
            </Button>
          </>
        }
      >
        <p>
          <Trans>
            Are you sure, you want to delete the entity <b>{data.simpleClassName}</b> and all
            generic types of the related fields?
          </Trans>
        </p>
      </Modal>
    </>
  );
};

export default DeleteEntityAction;
