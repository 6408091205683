import React, { useCallback, useEffect, useRef } from 'react';

import { Trans } from '@lingui/macro';
import { getRoute } from '@phoenix-systems/react-router';
import { useDispatch, useSelector } from 'react-redux';

import SchemaSelector from '../schemaSelector';
import { SchemaSelectorProps } from '../schemaSelector/schemaSelector.types';

import DomainSubTitle from './domainSubtitle';
import DomainTitle from './domainTitle';

import { ExceptionProps } from 'components/ui/exception/exception';
import HeaderButtons from 'components/ui/headerButtons';
import Page from 'components/ui/page';
import routes from 'config/routes/routes';
import useDomainName from 'hooks/useDomainName';
import useNotFound from 'hooks/useNotFound';
import useOnDomainSchemaChange from 'hooks/useOnDomainSchemaChange';
import useSchemaName from 'hooks/useSchemaName';
import useUnautorizedReq from 'hooks/useUnautorizedReq';
import { DomainRouteParams } from 'index.types';
import { useDomain } from 'services/api/domain/domain';
import history from 'services/history';
import { st_domain_setDomainName, st_domain_setRoute } from 'services/store/domain/domain.actions';
import { st_domain_getDomainName } from 'services/store/domain/domain.selectors';
import getDomainRoute from 'utils/getDomainRoute';

type DomainPageProps = {
  route: string;
  routeParams?: DomainRouteParams;
  content: JSX.Element;
  footerButtons?: JSX.Element;
  footer?: JSX.Element;
  title?: JSX.Element;
  noScroll?: boolean;
  schemaSelector?: boolean | SchemaSelectorProps;
  headerButtons?: JSX.Element;
  className?: string;
  isLoading?: boolean;
  withoutProvider?: boolean;
};

const DomainPage: React.FC<DomainPageProps> = ({
  title,
  content,
  noScroll,
  schemaSelector,
  headerButtons,
  className,
  withoutProvider,
  footerButtons,
  isLoading,
  route,
  routeParams
}) => {
  const domainName = useDomainName();
  const schemaName = useSchemaName();
  const dispatch = useDispatch();
  const domainQuery = useDomain(domainName, {
    retry: false,
    enabled: false,
    onSuccess: data => {
      if (data.schemes.includes(schemaName) === false) {
        const rt = getDomainRoute(route, domainName, data.defaultSchema, routeParams);
        history.replace(getRoute(rt));
      }
    }
  });
  const { current: domainQueryRef } = useRef(domainQuery);
  const isNotFound = useNotFound(domainQuery);
  const storedDomainName = useSelector(st_domain_getDomainName);
  const isUnautorized = useUnautorizedReq(domainQuery);

  const getException = useCallback((): ExceptionProps | false => {
    if (isNotFound) {
      return {
        code: 404,
        message: (
          <Trans>
            The domain <b>{domainName}</b> was not found.
          </Trans>
        )
      };
    }

    if (isUnautorized) {
      return {
        code: 403,
        title: <Trans>Access denied</Trans>,
        message: (
          <Trans>
            You are not authorized to access the requested domain <b>{domainName}</b>.
          </Trans>
        )
      };
    }

    return false;
  }, [isNotFound, isUnautorized, domainName]);

  useEffect(() => {
    dispatch(st_domain_setDomainName(domainName));
  }, [domainName, dispatch]);

  useEffect(() => {
    if (!domainName && history.location.pathname === routes.domain.root && storedDomainName) {
      history.push(getRoute(routes.domain.overview));

      return;
    }

    if (!domainName) {
      history.push(getRoute(routes.dashboard.root));
    }
  }, [domainName, storedDomainName]);

  useEffect(() => {
    if (domainName !== undefined && domainName !== '') {
      domainQueryRef.refetch();
    }
  }, [domainName, domainQueryRef]);

  useEffect(() => {
    dispatch(st_domain_setRoute(route, routeParams));
  }, [dispatch, route, routeParams]);

  useOnDomainSchemaChange(() => {
    // dispatch(st_entityDocument_reset());
  });

  return (
    <>
      <Page
        error={
          domainQuery.isError &&
          !isNotFound &&
          !isUnautorized && {
            message: <Trans>Failed to load domain &quot;{domainName}&quot;</Trans>,
            error: domainQuery.error
          }
        }
        isLoading={domainQuery.isLoading || isLoading}
        exception={getException()}
        noProvider={withoutProvider}
        className={className}
        content={content}
        noScroll={noScroll}
        title={<DomainTitle>{title}</DomainTitle>}
        titleBottom={domainQuery.data?.uri ? <DomainSubTitle /> : undefined}
        scrollableProps={{ scrollId: 'page-scroll' }}
        titleElements={
          <>
            {headerButtons && <HeaderButtons>{headerButtons}</HeaderButtons>}
            {schemaSelector && typeof schemaSelector === 'object' && (
              <SchemaSelector {...schemaSelector} />
            )}
            {schemaSelector && typeof schemaSelector === 'boolean' && <SchemaSelector />}
          </>
        }
        footerButtons={footerButtons}
      />
    </>
  );
};

export default DomainPage;
