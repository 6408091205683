import tw, { css, styled } from 'twin.macro';

export const SearchContainer = styled.div(() => [
  css`
    ${tw`w-full `};
    & .ant-select {
      ${tw`w-full mb-12`};
      max-width: 920px;
    }
  `
]);
