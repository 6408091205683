import React from 'react';

import { Trans } from '@lingui/macro';

import AppLayout from 'components/app/components/appLayout';
import DomainPage from 'components/domain/components/domainPage';
import routes from 'config/routes/routes';

const DomainUserPage: React.FC = () => (
  <AppLayout>
    <DomainPage
      route={routes.domain.userPrivileges}
      content={<>user privileges...</>}
      title={<Trans>User privileges</Trans>}
    />
  </AppLayout>
);

export default DomainUserPage;
