import tw, { css, styled } from 'twin.macro';

type StyledToolbarProps = {
  isHovering: boolean;
};

export const StyledToolbar = styled.div<StyledToolbarProps>(({ isHovering }) => [
  css`
    ${tw`w-full flex flex-row  flex items-center`};

    & .toolbar-separator {
      ${tw`border-gray-600 h-24`};
    }

    & .ant-btn {
      ${tw`bg-transparent border-none opacity-40 hover:opacity-100`};
    }

    & .hovering-toolbar-arrow {
      border-top: 6px solid #111827;
    }

    & .ant-switch,
    .ant-switch.checked {
      ${tw`ml-auto mr-6 bg-transparent text-black bg-gray-300`};

      & .ant-switch-inner {
        ${tw`text-black`};
      }
    }
  `,

  isHovering &&
    css`
      ${tw`rounded bg-gray-900 text-white duration-300`};

      & .ant-btn {
        ${tw`text-white`};
      }

      & .toolbar-separator {
        ${tw`border-gray-200 `};
      }

      & .ant-switch,
      .ant-switch.checked {
        background: rgba(255, 255, 255, 0.35);

        & .ant-switch-inner {
          ${tw`text-white`};
        }
      }
    `
]);
