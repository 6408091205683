import tw, { css, styled } from 'twin.macro';

export const SwitchContainer = styled.div(() => [
  css`
    ${tw`flex flex-row items-center h-36`};

    & span {
      ${tw`font-700 inline-block mr-8 whitespace-nowrap `};
    }
  `
]);
